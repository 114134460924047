import React, { useState, useEffect } from "react";
import { Row, Col, Form, Modal } from "react-bootstrap";
import { MdAddCircle } from "react-icons/md";
import { Link, useParams, useHistory } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import SubHeader from "../../../../components/partials/dashboard/HeaderStyle/sub-header";
import InvoicePreview from "./invoice-preview";
import ActivityBar from "../../../../components/partials/components/activitybar";
import { FiEye } from "react-icons/fi";
import { getAllClients } from "../Hook/clientHooks";
import { getAllFirms, getFirmById } from "../Hook/firmHooks";
import TaxModal from "./tax-modal";
import './styles/invoice.css';
import RecurringModal from "./recurring-modal";
import InvoiceTable from "./invoice-table";
import { createInvoice, getAllInvoices, getInvoiceById, getLastInvoiceNumber } from "../Hook/invoiceHooks";
import { getAllTaxes, getGlobalTaxes } from "../Hook/taxHooks";
import axios from "axios";
import { API } from "../../../../backend";
import Notification from "../notification/notification";
import Addclientmodal from "./addclient-modal";
import Addfirmmodal from "./addfirm-modal";
import './styles/invoice.css';
import CreateProducts from "../products/create-products";
import { getAllProducts } from "../Hook/productHooks";
import { ErrorToast, SuccessToast } from "../../components/toast";
import SetReminderModal from "./setReminder-modal";
import useFullPageLoader from "../Hook/useFullPageLoader";
import { ExchangeRates } from "../Hook/exchangeHooks";
import { addRecentActivity } from "../Hook/recentActivityHooks";

const InvoiceAdd = () => {
  const allCurrencies = [
    {
      id: 0,
      name: "INR",
      value: "₹",
    },
    {
      id: 1,
      name: "USD",
      value: "$",
    },
    {
      id: 2,
      name: "EUR",
      value: "€",
    },
    {
      id: 3,
      name: "AUD",
      value: "$",
    },
    {
      id: 4,
      name: "CAD",
      value: "$",
    },
  ];

  const { id } = useParams();
  const { editId } = useParams();

  // const navigate = useNavigate();
  const orgId = localStorage.getItem("orgID");
  const orgName = localStorage.getItem("orgName");

  const [form, setForm] = useState({});
  const [allProducts, setAllProducts] = useState([]);
  const [showTaxColumn, setShowTaxColumn] = useState(false);
  const [showDiscountColumn, setShowDiscountColumn] = useState(false);
  const [showHSNColumn, setShowHSNColumn] = useState(false);
  const [showSACColumn, setShowSACColumn] = useState(false);
  const [items, setItems] = useState([
    {
      // id: Date.now().toString(),
      itemName: "",
      desc: "",
      taxRate: 0,
      discount: 0,
      ...((showHSNColumn === true) ? { hsn: '' } : null),
      ...(showSACColumn && { sac: '' }),
      // ...(showDiscountColumn && { discount: 0 }),
      baseprice: 0,
      amount: 0,
      quantity: 0,
      unitPrice: 0,
    }
  ]);

  const [curr, setCurr] = useState("₹");
  const [curConvert, setCurConvert] = useState(0);
  const [client, setClient] = useState([]);
  const [allClients, setAllClients] = useState({});
  const [recurring, setRecurring] = useState(false);
  const [showTaxNDiscount, setShowTaxNDiscount] = useState(false);
  const [showCreateProducts, setShowCreateProducts] = useState(false);
  const [showRecurring, setShowRecurring] = useState(false);
  const [showDraft, setShowDraft] = useState(false);
  const [draft, setDraft] = useState(false);
  const [details, setDetails] = useState([]);
  const selectedFirm = "";
  const selectedClient = "";

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const breakPoint = 970;

  const [show, setShow] = useState(false);
  const [showTax, setShowTax] = useState(false);
  const [partialPay, setPartialPay] = useState(false);
  const [showAddReminder, setShowAddReminder] = useState(false);
  const [setReminder, setSetReminder] = useState(false);
  const [includeTax, setIncludeTax] = useState(false);
  const [isOpenItems, setIsOpenItems] = useState(false);

  const [selectedClientId, setSelectedClientId] = useState('');
  const [singleClient, setSingleClient] = useState([]);
  const [clientAdd, setClientAdd] = useState([]);
  const [clientDetails, setClientDetails] = useState({});

  const [allFirms, setAllFirms] = useState([]);
  const [firmAdd, setFirmAdd] = useState({});
  const [firmId, setFirmId] = useState('');
  const [firmDetails, setFirmDetails] = useState({});
  const [validateFirm, setValidateFirm] = useState(false);
  const [taxApplied, setTaxApplied] = useState(false);
  const [terms, setTerms] = useState([]);
  const [firmTaxes, setFirmTaxes] = useState([]);
  const [globaltaxes, setAllGlobalTaxes] = useState([]);
  const [addedtax, setAddedtax] = useState([]);

  const [allTaxes, setAllTaxes] = useState([]);
  const [itemTaxrate, setitemtaxrate] = useState(0);
  const [allRates, setAllRates] = useState(0);
  const [taxAmt, setTaxAmt] = useState([]);
  const [createdDate, setCreatedDate] = useState();
  const [endDate, setEndDate] = useState();

  const [invoicePrefix, setInvoicePrefix] = useState('');
  const [lastInvoice, setLastInvoice] = useState('');
  const [newInvoNumber, setNewInvoNumber] = useState(0);
  const [invoiceNumber, setinvoiceNumber] = useState('');
  const [defaultValue, setDefaultValue] = useState(0);
  const [allInvoiceNumbers, setAllInvoiceNumbers] = useState([]);
  const [editedInvoiceNumber, setEditedInvoiceNumber] = useState(0);
  const [finalNo, setFinalNo] = useState('');

  const [newState, setNewState] = useState([]);
  const [invoiceId, setInvoiceId] = useState("");
  const [notificationAdded, setNotificationAdded] = useState(['']);

  const [taxRates, setTaxRates] = useState(0);
  const [allSelectedTaxes, setAllSelectedTaxes] = useState([]);

  const [copiedData, setCopiedData] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const CopiedInvoiceId = localStorage.getItem('CopiedInvoiceId');
  const history = useHistory();
  const userToken = localStorage.getItem('user_token');

  const datetimeString = new Date();
  const dateObject = new Date(datetimeString);

  const formattedDate = `${dateObject.getDate()}/${dateObject.getMonth() + 1}/${dateObject.getFullYear()}`;
  const formattedTime = dateObject.toLocaleTimeString();


  useEffect(() => {
    getInvoiceById(CopiedInvoiceId).then((response) => {
      if (response.status === 200) {
        setCopiedData(response.data.data[0]);
      } else {
        setCopiedData([]);
      }
    }).catch(() => {
      setCopiedData([]);
    })
  }, []);

  useEffect(() => {
    getAllProducts(firmId).then((response) => {
      if (response.status === 200) {
        setAllProducts(response.data.data);
      } else {
        setAllProducts([]);
      }
    }).catch((err) => {
      setAllProducts([]);
    })
  }, [firmId]);

  useEffect(() => {
    const testVar = addedtax.reduce((prev, curr) => prev + parseFloat(Object.values(curr)), 0);
    setTaxRates(testVar);
  }, [addedtax]);

  const subTotal = items && items.reduce((prev, curr) => prev + curr.quantity * curr.unitPrice, 0);

  let testArr = [];

  useEffect(() => {
    const test = items.reduce((prev, curr) => prev + parseFloat(curr.taxRate), 0);
    testArr.push(test);
    setitemtaxrate(test)
    setAllRates(taxRates + test)
  }, [taxRates, items]);

  let discountedPrice = items.reduce((prev, curr) => prev +
    parseFloat(
      parseFloat(parseFloat(
        includeTax === true
          ? (curr.quantity * curr.unitPrice)
          : (parseFloat(curr.quantity * curr.unitPrice) + (parseFloat(curr.quantity * curr.unitPrice) * ((curr.taxRate ? parseFloat(curr.taxRate) : 0) / 100))).toFixed(2)
      )
        - (parseFloat(
          includeTax === true
            ? (curr.quantity * curr.unitPrice)
            : (parseFloat(curr.quantity * curr.unitPrice) + (parseFloat(curr.quantity * curr.unitPrice) * ((curr.taxRate ? parseFloat(curr.taxRate) : 0) / 100))).toFixed(2)
        ) * ((curr.discount ? parseFloat(curr.discount) : 0) / 100)))
    ), 0);


  // let discountedPrice = items.reduce((prev, curr) => prev + parseFloat(parseFloat(curr.amount) - (parseFloat(curr.amount) * ((curr.discount ? parseFloat(curr.discount) : 0) / 100))), 0)
  let basePrice = includeTax === true ? (discountedPrice) / (1 + (taxRates / 100)) : (discountedPrice);
  let itemstaxTotal = 0;
  const taxTotal = addedtax.reduce((acc, tax) => parseFloat((acc + (basePrice * Object.values(tax)) / 100).toString().match(/^-?\d+(?:\.\d{0,2})?/)), 0);
  let total = 0;

  let roundoff = 0;
  let grandTotal = 0;
  let roundOperation = '';

  if (includeTax === true) {
    itemstaxTotal = items.reduce((prev, curr) => prev + (parseFloat(curr.taxRate) / 100) * parseFloat((parseFloat(curr.quantity) * parseFloat(curr.unitPrice)) / (1 + (parseFloat(curr.taxRate) / 100))), 0);
    total = basePrice + itemstaxTotal + taxTotal + (basePrice - discountedPrice);
    grandTotal = Math.round(basePrice + itemstaxTotal + taxTotal + (basePrice - discountedPrice));
    roundoff = grandTotal > total
      ? grandTotal - parseFloat(total.toFixed(2))
      : parseFloat(total.toFixed(2)) - grandTotal
    roundOperation = grandTotal >= parseFloat(total.toFixed(2)) ? '' : '-';
  } else {
    itemstaxTotal = items.reduce((prev, curr) => prev + ((((curr.quantity * curr.unitPrice) * (curr.taxRate ? parseFloat(curr.taxRate) : 0)) / 100)), 0);
    total = discountedPrice + taxTotal + itemstaxTotal + (basePrice - discountedPrice);
    grandTotal = Math.round(basePrice + itemstaxTotal + taxTotal + (basePrice - discountedPrice));
    roundoff = grandTotal > total
      ? grandTotal - parseFloat(total.toFixed(2))
      : parseFloat(total.toFixed(2)) - grandTotal
    roundOperation = grandTotal >= parseFloat(total.toFixed(2)) ? '' : '-';
  }

  useEffect(() => {
    endDate < createdDate && ErrorToast("Due date can not be before of Invoice date");
  }, [endDate]);

  useEffect(() => {
    const arr1 = [];
    addedtax.map((data, index) => {
      const testArr = data;
      arr1.push({ [`${Object.keys(testArr)}${Object.values(testArr)}`]: ((Object.values(testArr) / 100) * subTotal).toFixed(2) });
      setTaxAmt(arr1);
    });
  }, [addedtax, items, form]);

  // const to = 'INR';
  const [from, setFrom] = useState('INR');
  const amt = 1;

  useEffect(() => {
    ExchangeRates(from).then((response) => {
      if (response.status === 200) {
        const conversion = parseFloat((response.data.conversion_rate).toFixed(2));
        setForm({
          ...form,
          curConvert: conversion
        });
        setCurConvert((response.data.conversion_rate).toFixed(2));
      }
    }).catch(() => {
      console.log("unable to convert");
    })
  }, [from, curr]);

  useEffect(() => {
    // handleChange();
  }, [curConvert]);

  useEffect(() => {
    setForm({
      ...form,
      recurringInvoice: recurring
    });

    if (!recurring) {
      setForm({
        ...form,
        recurringInvoiceObj: {}
      });
    }
  }, [recurring]);


  // useEffect(() => {
  //   setForm({
  //     ...form,
  //     taxAmt: newArr,
  //   })
  // }, [addedtax, items]);


  useEffect(() => {
    if (lastInvoice === 0) {
      setNewInvoNumber(lastInvoice);
    }
    else {
      setNewInvoNumber(lastInvoice.split('-'));
    }
  }, [lastInvoice]);

  useEffect(() => {
    newInvoNumber === 0 ? setinvoiceNumber(`${invoicePrefix}-${lastInvoice + 1}`) : setinvoiceNumber(`${newInvoNumber[0]}-${parseInt(newInvoNumber[1]) + 1}`)
  });

  useEffect(() => {
    newInvoNumber === 0 ? setDefaultValue(lastInvoice + 1) : setDefaultValue(parseInt(newInvoNumber[1]) + 1)
  });

  useEffect(() => {
    setEditedInvoiceNumber(defaultValue)
  }, [invoiceNumber]);

  const handleChangeInvoiceNumber = (e) => {
    setEditedInvoiceNumber(e.target.value);
    const matched = allInvoiceNumbers.filter((data) => data === `${invoicePrefix}-${e.target.value}`);
    matched.length > 0 && ErrorToast("Invoice no. already exist", {
      duration: 700
    });
    setFinalNo(matched.length > 0 ? matched : `${invoicePrefix}-${e.target.value}`);
  };

  const handleKeyDown = (event) => {
    if (
      editedInvoiceNumber === defaultValue &&
      (event.keyCode === 8 || event.keyCode === 46)
    ) {
      setEditedInvoiceNumber("");
    }
  };

  const calculateTax = () => {
    setTaxApplied(true)
    setShowTax(true)
  }

  const [testBase, setTestBase] = useState(null);
  useEffect(() => {
    const testVar = addedtax.reduce((prev, curr) => prev + (total - (subTotal / (1 + ((Object.values(curr) / 100))))), 0);
    setTestBase(testVar);
  })

  const [showClient, setShowClient] = useState(false);
  const [showFirm, setShowFirm] = useState(false);
  const [validation, setValidation] = useState(false);
  const [validated, setValidated] = useState(false);
  const [taxValues, setTaxValues] = useState([]);

  useEffect(() => {
    addedtax.map((data, index) => {
      setTaxValues([(Object.values(data) / 100) * subTotal])
    })
  }, [items]);

  const getFirms = () => {
    getAllFirms(setAllFirms);
  }

  useEffect(() => {
    getFirms();
  }, []);

  const firmById = async (id) => {
    getFirmById(id).then((response) => {
      if (response.status === 200) {
        setInvoicePrefix(response.data.data[0].invoicePrefix);
        setFirmAdd(response.data.data[0].add);
        setValidateFirm(false);
        console.log("response", response);
        setFirmDetails({
          name: (response.data.data[0].registeredFirmName === undefined || response.data.data[0].registeredFirmName === '') ? response.data.data[0].name : response.data.data[0].registeredFirmName,
          phone: response.data.data[0].phone,
          taxId: response.data.data[0].gst_no,
          email: response.data.data[0].email,
          address: response.data.data[0].add,
          logo: response.data.data[0].logo,
          firmID: response.data.data[0]._id
        });
      } else {
        setInvoicePrefix('');
        setFirmAdd({});
        setFirmDetails({});
      }
    }).catch(() => {
      setInvoicePrefix('');
      setFirmAdd({});
      setFirmDetails({});
    });
  }

  const getFirmTaxes = (id) => {
    getAllTaxes(id).then((response) => {
      if (response.status === 200) {
        response.data.data.length > 0 ? setFirmTaxes(response.data.data[0].taxRates) : setFirmTaxes([]);
      } else {
        setFirmTaxes([]);
      }
    }).catch(() => {
      setFirmTaxes([]);
    });
  };

  const getInvoiceNumberList = (id) => {
    getLastInvoiceNumber(id).then((response) => {
      if (response.data.data === 0) {
        setLastInvoice(response.data.data);
        setAllInvoiceNumbers([response.data.data])
      } else {
        setLastInvoice(response.data.data[0]);
        setAllInvoiceNumbers(response.data.data)
      }
    }).catch(() => {
      setLastInvoice(0);
      setAllInvoiceNumbers([]);
    })
  };

  const getSingleFirm = (id) => {
    setFirmId(id);
    firmById(id);
    getFirmTaxes(id);
    getInvoiceNumberList(id);
  };

  useEffect(() => {
    getGlobalTaxes().then((response) => {
      if (response.status === 200) response.data.data.length > 0 ? setAllGlobalTaxes(response.data.data[0].taxRates) : setAllGlobalTaxes([])
      else setAllGlobalTaxes([]);
    });
  }, []);

  let extraElements = [];

  useEffect(() => {
    setAllTaxes([...firmTaxes, ...globaltaxes]);
  }, [firmTaxes, globaltaxes]);

  useEffect(() => {
    const missingElements = [];
    allTaxes.forEach((element) => {
      if (!allSelectedTaxes.includes(element)) {
        missingElements.push(element);
      }
    });
    return missingElements;
  }, []);


  const [test, setTest] = useState([]);
  const testing = () => {
    details.map((data, index) => {
      setTest([...test, data])
    })
  }

  useEffect(() => {
    testing();
  }, [])

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  })

  const getClients = () => {
    getAllClients().then((response) => {
      if (response.status === 200) {
        setClient(response.data.data);
        setAllClients(response.data.data);
      } else {
        setAllClients([]);
      }
    }).catch(() => {
      setAllClients([]);
    })
    // getAllClients(setClient, setAllClients);
  }

  useEffect(() => {
    getClients();
  }, []);


  const handleCurrency = (id) => {
    allCurrencies.map((data, i) => {
      if (i == id) {
        setCurr(data.value);
        setFrom(data.name);
      }
    })
  }

  const handleRecurring = () => {
    setRecurring(!recurring)
    if (form.invoiceDate) {
      !recurring && setShowRecurring(true);
    } else {
      setRecurring(false);
      ErrorToast('Select Invoice Date first');
    }
  }

  const handleTerms = (e) => {
    setTerms(e.target.value.split('\n'));
  }

  const handleShowDraft = () => {
    setDraft(true);
    setShowDraft(true);
  }

  const handleShowSetReminder = () => {
    !setReminder && setShowAddReminder(!showAddReminder);
    !setReminder && setForm({ ...form, reminder: {} })
    setSetReminder(!setReminder);
  };

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
  };

  const handleDateChange = (event) => {
    const inputDate = event.target.value;
    const formattedDate = formatDate(inputDate);
    setCreatedDate(formattedDate);
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
      items: items,
      client: clientDetails,
      dueAmount: grandTotal,
      amountPaid: 0,
      firm: firmDetails,
      currency: curr,
      subTotal: parseFloat(subTotal.toFixed(2)),
      // recurringInvoice: false,
      // recurringInvoiceObj: {},
      // subTotal: includeTax ? parseFloat((total - addedtax.reduce((prev, curr) => prev + (total - (subTotal / (1 + ((Object.values(curr) / 100))))), 0))) : subTotal,
      total: total,
      roundOff: (roundoff).toFixed(2),
      status: "Pending",
      termsNcondition: terms,
      partialPay: partialPay,
      incluTax: includeTax,
      invoiceNumber: finalNo.length > 2 ? finalNo : invoiceNumber,
      draft: false,
      cancel: false,
      delete: false,
      tax: taxApplied ? addedtax : [],
      taxAmt: taxAmt,
      orgId: orgId
    });
  }

  const handleSaveDraft = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
      items: items,
      client: clientDetails,
      dueAmount: grandTotal,
      amountPaid: 0,
      firm: firmDetails,
      currency: curr,
      subTotal: parseFloat(subTotal),
      // subTotal: includeTax ? parseFloat((total - addedtax.reduce((prev, curr) => prev + (total - (subTotal / (1 + ((Object.values(curr) / 100))))), 0))) : subTotal,
      total: grandTotal,
      roundOff: (roundoff).toFixed(2),
      status: "Draft",
      termsNcondition: terms,
      partialPay: partialPay,
      incluTax: includeTax,
      invoiceNumber: finalNo.length > 2 ? finalNo : invoiceNumber,
      draft: true,
      cancel: false,
      delete: false,
      tax: taxApplied ? addedtax : [],
      taxAmt: taxAmt,
      orgId: orgId
    })
  }

  const saveDraft = () => {
    createInvoice(form).then((response) => {
      if (response.status === 201) {
        SuccessToast("Draft Saved");
        setTimeout(() => {
          history.push(`/${orgName}/dashboard/invoice/invoice-details/${response.data.data._id}`)
        }, 1000);
      }
      else {
        ErrorToast("something went wrong");
      }
    });
    setShowDraft(false);
  }

  const handleValidation = () => {
    if (!form.invoiceNumber) {
      setValidation(true);
    }
    else if (!firmDetails.name) {
      setValidation(true);
    }
    else if (!singleClient.clientFirmName || !singleClient.firstName) {
      setValidation(true);
    }
    else if (form.items.unitPrice === 0) {
      setValidation(true);
    }
  };

  console.log("form", form);

  const handleSave = async (e) => {
    e.preventDefault();
    const testForm = e.currentTarget;
    if (testForm.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    };

    setValidated(true);
    showLoader();

    if (form.invoiceDate && form.firm.name && form.client.email && form.subTotal > 0 && createdDate <= endDate) {
      createInvoice(form).then((response) => {
        if (response.status === 201) {
          SuccessToast("Invoice created");

          const activityForm = {
            module: ["invoice", "firm", 'client'],
            entityId: [response.data.data._id, firmId, selectedClientId],
            activity: "Invoice Created",
            activityDesc: `Invoice from ${form?.firm?.name} to ${form?.client?.clientFirmName ? form?.client?.clientFirmName : form?.client?.firstName} created`,
            createdDate: formattedDate,
            createdTime: formattedTime,
            userId: localStorage.getItem('userId')
          };

          addRecentActivity(activityForm);

          hideLoader();
          setTimeout(() => {
            history.push(`/${orgName}/dashboard/invoice/invoice-details/${response.data.data._id}`)
          }, 1000);
        } else {
          ErrorToast("Unable to create invoice");
          hideLoader();
        }
      }).catch(() => {
        ErrorToast("something went wrong");
        hideLoader();
      })
    } else {
      window.scrollTo(0, 0);
      ErrorToast("Fill all the reqiured fields");
      hideLoader();
    }

    getAllInvoices().then((response) => {
      if (response.status === 200) {
        setInvoiceId(response.data.data[0]._id);
        setNotificationAdded([...notificationAdded, 'New invoice created', 'Notif 2'])
      } else {
        setInvoiceId('');
      }
    }).catch(() => {
      setInvoiceId('');
    });
  };

  const invoiceOptions = () => {
    return (
      <>
        <Row className="mx-3 text-white bg-primary py-2 ps-2 pe-3 my-2" onMouseOver={handleChange} onClick={() => setShow(true)} style={{ cursor: "pointer", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
          <Col sm={9}>Preview</Col>
          <Col sm={1} className="text-end">
            <Tooltip title="Preview Invoice">
              <Link to="#" className="py-1 px-3 rounded  text-white me-2">
                <FiEye style={{ fontSize: "1rem", cursor: "pointer" }} />
              </Link>
            </Tooltip>
          </Col>
        </Row>

        <Row className="text-white bg-primary mx-3 py-2 ps-2 pe-3 my-2" onClick={() => handleShowDraft()} style={{ cursor: "pointer", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
          <Col sm={12}>Save Draft</Col>
        </Row>

        {/* <Row className="text-white bg-primary mx-3 py-2 ps-2 pe-3 my-2" style={{ cursor: "pointer", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
          <Col sm={12}>Send Reminder</Col>
        </Row> */}

        <Row className="text-dark mx-3 py-2 ps-2 pe-3 my-2" style={{ cursor: "pointer", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
          <Col sm={6} md={6}>Select Currency</Col>
          <Col sm={6} md={6}>
            <select className="w-100 rounded bg-white py-1 px-3 m-0" name="currency" onMouseOver={handleChange} onChange={(e) => handleCurrency(e.target.value)} style={{ border: "1px solid #c8c9ca80" }}>
              {
                allCurrencies.map((data, index) => (
                  <option key={index} value={data.id}>{data.name}</option>
                ))
              }
            </select>
          </Col>
        </Row>
        <Row className="text-dark mx-3 py-2 ps-2 pe-3 my-2" onClick={() => handleRecurring()} style={{ cursor: "pointer", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
          <Col sm={1} className="">
            <Form.Check.Input type="checkbox" onChange={() => setRecurring(!recurring)} checked={recurring} />
          </Col>
          <Col sm>Recurring Invoice</Col>
        </Row>
        <Row className="text-dark mx-3 py-2 ps-2 pe-3 my-2" onClick={() => setIncludeTax(!includeTax)} style={{ cursor: "pointer", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
          <Col sm={1}>
            <Form.Check.Input type="checkbox" onChange={() => setIncludeTax(!includeTax)} style={{ cursor: "pointer" }} checked={includeTax} />
          </Col>
          <Col sm>Price Including Tax</Col>
        </Row>
        <Row className="text-dark mx-3 py-2 ps-2 pe-3 my-2" onClick={() => setPartialPay(!partialPay)} style={{ cursor: "pointer", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
          <Col sm={1}>
            <Form.Check.Input type="checkbox" onChange={() => setPartialPay(!partialPay)} style={{ cursor: "pointer" }} checked={partialPay} />
          </Col>
          <Col sm>Allow Parital Payment</Col>
        </Row>
        <Row className="text-dark mx-3 py-2 ps-2 pe-3 my-2" onClick={handleShowSetReminder} style={{ cursor: "pointer", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
          <Col sm={1}>
            <Form.Check.Input type="checkbox" onChange={handleShowSetReminder} style={{ cursor: "pointer" }} checked={setReminder} />
          </Col>
          <Col sm>Add Reminder</Col>
        </Row>

        {/* <Row className="text-dark mx-3 py-2 ps-2 pe-3 my-2" onClick={() => setAdjustPayment(!adjustPayment)} style={{ cursor: "pointer", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
          <Col sm={1}>
            <Form.Check.Input type="checkbox" onChange={() => setAdjustPayment(!adjustPayment)} style={{ cursor: "pointer" }} checked={adjustPayment === true} />
          </Col>
          <Col sm>Adjust Payment</Col>
        </Row> */}
        {/* <Row className="text-dark mx-3 py-2 ps-2 pe-3 my-2" onClick={() => setTip(!tip)} style={{ cursor: "pointer", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
          <Col sm={1}>
            <Form.Check.Input type="checkbox" onChange={() => setTip(!tip)} style={{ cursor: "pointer" }} checked={tip} />
          </Col>
          <Col sm>Allow Tip</Col>
        </Row> */}
      </>
    )
  }

  const getClientById = async (clientId) => {
    id ? setSelectedClientId(id) : setSelectedClientId(clientId)
    let url = id ? `${API}/client/singleUser/${id}` : `${API}/client/singleUser/${clientId}`;
    const config = {
      method: "GET",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
      },
    }
    try {
      const response = await axios(config);
      setSingleClient(response.data.data);
      setClientAdd(response.data.data.add)
      setClientDetails({
        clientFirmName: response.data.data.clientFirmName === undefined ? '' : response.data.data.clientFirmName,
        firstName: response.data.data.firstName === undefined ? '' : response.data.data.firstName,
        lastName: response.data.data.lastName === undefined ? '' : response.data.data.lastName,
        taxId: response.data.data.taxId,
        phone: response.data.data.phone,
        email: response.data.data.email,
        address: response.data.data.add,
        client_id: response.data.data._id,
        clientFirmName: response.data.data.clientFirmName,
      })
    }
    catch {
      setSingleClient([]);
    }
  }

  useEffect(() => {
    getClientById();
  }, [])

  const addNewItem = () => {
    const newItems = {
      itemName: "",
      desc: "",
      discount: 0,
      ...(showHSNColumn && { hsn: '' }),
      ...(showSACColumn && { sac: '' }),
      // ...(showDiscountColumn && { discount: 0 }),
      taxRate: 0,
      amount: 0,
      quantity: 0,
      unitPrice: 0,
    };
    setItems([...items, newItems]);
  }

  const onInputChange = (ind, itemValue) => {
    const newItems = [...items];
    const index = newItems.findIndex((r, i) => i === ind);
    newItems[index] = itemValue;
    setItems(newItems);
  }

  const handleDeleteRow = (index) => {
    setItems(items.filter((data, i) => i != index))
  }

  const handleShowHSN = () => {
    setShowSACColumn(false);
    setShowHSNColumn(true);
  }

  const handleShowSAC = () => {
    setShowHSNColumn(false);
    setShowSACColumn(true);
  }

  const [dropIndex, setDropIndex] = useState(null);

  console.log("form", recurring);

  return (
    <div className="m-0 p-0" onClick={() => isOpenItems && setIsOpenItems(false)}>
      <SubHeader title="Create Invoice" />
      <div className="mx-3 p-0">
        <div className="row mx-2 mb-5" style={{ marginTop: screenWidth >= breakPoint ? "-1%" : "-3%", fontSize: "0.8rem" }}>
          <Col sm={8} className=" py-3 px-2 bg-white rounded" style={{ width: screenWidth >= breakPoint ? '75%' : '95%', }}>
            <Form noValidate validated={validated} onSubmit={handleSave}>
              <div>
                <span className="fs-5 ms-3 fw-semibold">Invoice Details</span>
                <Row className="w-100 my-2 mx-3">
                  <Col sm={3} className="py-2 px-2" style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                    <Form.Group>
                      <span className="m-0 p-0 d-inline">Invoice Date: </span>
                      {/* <Form.Control type="date" name="invoiceDate" className={`m-0 p-0 ps-2 py-1`}
                        onInput={(e) => setCreatedDate(e.target.value)} 
                        onChange={handleChange} required /> */}
                      <Form.Control type="date" name="invoiceDate" className={`m-0 p-0 ps-2 py-1`}
                        onInput={(e) => setCreatedDate(e.target.value)}
                        onChange={handleChange}
                        required />
                      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col sm={3} className="py-2 px-2 mx-2" style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                    <p className="m-0 p-0">Due Date: </p>
                    <Form.Control type="date" name="dueDate"
                      value={createdDate > endDate ? createdDate : endDate}
                      onChange={handleChange}
                      onInput={(e) => setEndDate(e.target.value)}
                      className="m-0 p-0 ps-2 py-1"
                    />
                    {
                      createdDate > endDate && (
                        <p className="m-0 p-0 text-danger">Due date can not be before of Invoice date</p>
                      )
                    }
                  </Col>
                  <Col sm={2} className="m-0 p-0"></Col>
                  <Col sm={3} className="text-end">
                    <span className="m-0 p-0 w-100 text-end fs-3">
                      Invoice:
                      {
                        firmDetails?.name ? (
                          <span className="m-0 p-0 text-primary text-end fs-4" >
                            {
                              invoicePrefix
                            }-
                            <input type="text" className="text-primary invoiceNumberInput"
                              value={editedInvoiceNumber}
                              onChange={handleChangeInvoiceNumber}
                              onKeyDown={handleKeyDown}
                              style={{ border: "none", borderBottom: "1px solid #8080805c", width: "50px" }}
                            />
                          </span>
                        ) : (
                          <span className="m-0 p-0 text-primary text-end fs-4" ></span>
                        )
                      }
                    </span>
                  </Col>
                </Row>
              </div>
              <div className="row my-3 mx-1 pb-4" style={{ borderBottom: "1px solid #c8c9ca" }}>
                <Col sm={6}>
                  <Form>
                    <Form.Group>
                      <Form.Label className="fw-semibold m-0 p-0">Invoice From*</Form.Label>
                      <Form.Control as="select" type="select" className={`${validateFirm && 'border border-danger'} mb-2`} onClick={handleChange} onChange={(e) => getSingleFirm(e.target.value)} style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }}
                        placeholder="Select Firm"
                        required
                      >
                        <option value={selectedFirm}>Select Firm</option>
                        {
                          allFirms.map((data, index) => (
                            <option onClick={handleChange} key={index} value={data._id}>{data.name}</option>
                          ))
                        }
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>
                      <Link to="#" onClick={() => setShowFirm(true)}>
                        <span className="py-1 px-2 m-0 mt-2 bg-primary text-white" style={{ cursor: "pointer", borderRadius: "5px" }}>Add new Firm</span>
                      </Link>
                    </Form.Group>
                  </Form>
                </Col>
                {
                  !id && !editId && (
                    <Col sm={6}>
                      <Form>
                        <Form.Group>
                          <Form.Label className="fw-semibold m-0 p-0">Invoice To*</Form.Label>
                          <Form.Control as="select" type="select" className="mb-2" name='clientName' onChange={(e) => getClientById(e.target.value)} style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }} required>
                            <option value={selectedClient}>Select Client</option>
                            {
                              client.map((data, index) => (
                                data.clientFirmName ? (
                                  <option key={index} value={data._id}>{data.clientFirmName}</option>
                                ) : (
                                  <option key={index} value={data._id}>
                                    {
                                      `${data.firstName === undefined ? ' ' : data.firstName} ${data.lastName === undefined ? ' ' : data.lastName}`
                                    }
                                  </option>
                                )
                              ))
                            }
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>
                          <Link to="#" onClick={() => setShowClient(true)}>
                            <span className="py-1 px-2 m-0 mt-2 bg-primary text-white" style={{ cursor: "pointer", borderRadius: "5px" }}>Add new client</span>
                          </Link>
                        </Form.Group>
                      </Form>
                    </Col>
                  )
                }
              </div>
              <div className="row ms-4 my-3 pb-4" style={{ borderBottom: "1px solid #c8c9ca" }}>
                <Col md={6} sm={12}>
                  <div className="my-2">
                    <Row>
                      <span className="m-0 p-0 fw-semibold" style={{ fontSize: "0.8rem" }}>From: </span>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col sm={11} md={6} className="p-2" style={{ border: "1px solid #e5e5e5", borderRadius: "5px" }}>
                        <span className="m-0 p-0 fw-semibold">Name: </span>
                        <div className="m-0 p- d-inline" onChange={handleChange} name="firmName">{`${firmDetails === undefined || firmDetails.name === undefined ? '' : firmDetails.name}`}</div>
                      </Col>
                      <Col sm={11} md={5} className="p-2 ms-2" style={{ border: "1px solid #e5e5e5", borderRadius: "5px" }}>
                        <span className="m-0 p-0 fw-semibold">Phone No: </span>
                        <div className="m-0 p-0 d-inline" name="firmphone" onChange={handleChange}>{firmDetails.phone === undefined ? '' : firmDetails.phone}</div>
                      </Col>
                      <Col sm={12} className="p-2 my-2" style={{ width: "93%", maxWidth: "93%", border: "1px solid #e5e5e5", borderRadius: "5px" }}>
                        <span className="m-0 p-0 fw-semibold">Email: </span>
                        <div className="m-0 p-0 d-inline" name="firmEmail" onChange={handleChange}>{firmDetails.email === undefined ? '' : firmDetails.email}</div>
                      </Col>
                      <Col sm={12} className="p-2 my-2" style={{ width: "93%", maxWidth: "93%", border: "1px solid #e5e5e5", borderRadius: "5px" }}>
                        <span className="m-0 p-0 fw-semibold">GST: </span>
                        <div className="m-0 p-0 d-inline" name="firmGst" onChange={handleChange} style={{ textTransform: "uppercase" }}>{firmDetails.taxId === undefined ? '' : firmDetails.taxId}</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} md={11} className="p-2" style={{ width: "93%", maxWidth: "93%", border: "1px solid #e5e5e5", borderRadius: "5px" }}>
                        <span className="m-0 p-0 fw-semibold">Address: </span>
                        <div className="m-0 mt-1 p-0" name="firmAddress">
                          <p className="m-0 p-0">{firmAdd === undefined || firmAdd.address1 === undefined ? '' : firmAdd.address1}</p>
                          <p className="m-0 p-0">{firmAdd === undefined || firmAdd.address2 === undefined ? '' : firmAdd.address2}</p>
                          <p className="m-0 p-0">
                            {
                              `${firmAdd === undefined || firmAdd.city === undefined ? '' : `${firmAdd.city}, `} 
                               ${firmAdd === undefined || firmAdd.state === undefined ? '' : `${firmAdd.state}, `} 
                               ${firmAdd === undefined || firmAdd.country === undefined ? '' : `${firmAdd.country}, `} 
                               ${firmAdd === undefined || firmAdd.pinCode === undefined ? '' : `${firmAdd.pinCode}, `}`
                            }
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={6} sm={12}>
                  <div className="my-2">
                    <Row>
                      <span className="m-0 p-0 fw-semibold" style={{ fontSize: "0.8rem" }}>To: </span>
                    </Row>
                  </div>
                  <Row>
                    <Col sm={11} md={6} className="p-2" style={{ border: "1px solid #e5e5e5", borderRadius: "5px" }}>
                      <span className="m-0 p-0 fw-semibold">Name: </span>
                      {
                        singleClient.clientFirmName ? (
                          <div className="m-0 p-0 d-inline ms-1" name="name" onChange={handleChange}>{`${singleClient.clientFirmName === undefined ? '' : singleClient.clientFirmName}`}</div>
                        ) : (
                          <div className="m-0 p-0 d-inline ms-1" name="name" onChange={handleChange}>
                            {`${singleClient.firstName === undefined ? '' : singleClient.firstName + ' ' + singleClient.lastName}`}
                          </div>
                        )
                      }
                      {/* <div className="m-0 p-0 d-inline ms-1" name="name" onChange={handleChange}>{`${singleClient.firstName === undefined ? '' : singleClient.firstName + ' ' + singleClient.lastName}`}</div> */}
                    </Col>
                    <Col sm={11} md={5} className="p-2 ms-2" style={{ border: "1px solid #e5e5e5", borderRadius: "5px" }}>
                      <span className="m-0 p-0 fw-semibold">Phone No: </span>
                      <div className="m-0 p-0 d-inline ms-1" name="phone" onChange={handleChange}>{singleClient.phone === undefined ? '' : singleClient.phone}</div>
                    </Col>
                    <Col className="p-2 my-2" style={{ width: "93%", maxWidth: "93%", border: "1px solid #e5e5e5", borderRadius: "5px" }}>
                      <span className="m-0 p-0 fw-semibold">Email: </span>
                      <div className="m-0 p-0 d-inline ms-1" name="email" onChange={handleChange}>{singleClient.email === undefined ? '' : singleClient.email}</div>
                    </Col>
                    <Col sm={12} className="p-2 my-2" style={{ width: "93%", maxWidth: "93%", border: "1px solid #e5e5e5", borderRadius: "5px" }}>
                      <span className="m-0 p-0 fw-semibold">GST: </span>
                      <div className="m-0 p-0 d-inline ms-1" name="gst" onChange={handleChange} style={{ textTransform: "uppercase" }}>{singleClient.taxId === undefined ? '' : singleClient.taxId}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={11} className="p-2" style={{ width: "93%", maxWidth: "93%", border: "1px solid #e5e5e5", borderRadius: "5px" }}>
                      <span className="m-0 p-0 fw-semibold">Address: </span>
                      <div className="m-0 mt-1 p-0" name="address">
                        <p className="m-0 p-0">{clientAdd.address1 === undefined ? '' : clientAdd.address1}</p>
                        <p className="m-0 p-0">{clientAdd.address2 === undefined ? '' : clientAdd.address2}</p>
                        <p className="m-0 p-0">
                          {
                            `${clientAdd.city === undefined ? '' : `${clientAdd.city}, `} 
                               ${clientAdd.state === undefined ? '' : `${clientAdd.state}, `} 
                               ${clientAdd.country === undefined ? '' : `${clientAdd.country}, `} 
                               ${clientAdd.pinCode === undefined ? '' : `${clientAdd.pinCode}, `}`
                          }
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </div>
              <Col sm={3} className="ms-4 py-3 px-2 mx-2" style={{ position: "relative", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                <span className="m-0 p-0 fw-semibold">Tax:  </span>
                <span className="m-0 ms-3 my-2 p-0 py-2 px-4 bg-primary text-white" onClick={calculateTax} style={{ borderRadius: "5px", cursor: "pointer" }}>
                  <span className="m-0 p-0">Apply Taxes</span>
                </span>
              </Col>
              <div className="row mx-4 mt-4 pb-3" style={{ borderBottom: "1px solid #c8c9ca" }}>
                <Row className="m-0 mb-2 p-0">
                  <Col sm={9} className="m-0 p-0">
                    <span className="m-0 mb-3 p-0 fw-semibold" style={{ fontSize: "0.8rem" }}>Item Details*</span>
                    {(validation && (
                      <>
                        {(form.items[items.length - 1].unitPrice < 1) ? (<p className="m-0 p-0 text-danger" style={{ fontSize: "0.7rem" }}>reqiured</p>) : (<p style={{ display: "none" }}>iojoijojio </p>)}
                      </>
                    ))}
                  </Col>
                  <Col className="m-0 p-0 pt-2 text-primary text-end" onClick={() => setShowTaxNDiscount(true)} style={{ cursor: "pointer" }}>
                    <span className="m-0 py-1 px-3 rounded bg-primary text-white fw-semibold">Customize</span>
                  </Col>
                </Row>
                <div className="m-0 p-0 table-responsive">
                  <table className="w-100">
                    <thead className="bg-primary p-2 text-white" style={{ borderBottom: "1px solid #c8c9ca", borderRadius: "10px 0 10px 0" }}>
                      <th className="ps-2" style={{ borderRadius: "10px 0 0 0" }}>Item</th>
                      {showHSNColumn ? <th className="text-center ps-2">HSN</th> : null}
                      {showSACColumn ? <th className="text-center ps-2">SAC</th> : null}
                      <th className="text-center">Qty</th>
                      <th className="text-start">Price</th>
                      {showTaxColumn ? <th className="text-center">Tax</th> : null}
                      {showDiscountColumn ? <th className="text-center">Discount (%)</th> : null}
                      <th className="text-start ps-3">Amount</th>
                      <th className="pe-2" style={{ borderRadius: "0 10px 0 0" }}>Actions</th>
                    </thead>
                    <tbody>
                      {
                        items.map((data, i) => {
                          return (
                            <InvoiceTable
                              itemId={data.id}
                              index={i} firmId={firmId} setAllProducts={setAllProducts} allProducts={allProducts}
                              dropIndex={dropIndex} validateFirm={validateFirm} setValidated={setValidateFirm}
                              setDropIndex={setDropIndex}
                              setShowCreateProducts={setShowCreateProducts} allItems={items} setValidateFirm={setValidateFirm}
                              includeTax={includeTax} taxApplied={taxApplied} taxTotal={taxRates} items={data} key={data.id} handleChange={handleChange}
                              showHSNColumn={showHSNColumn} showTaxColumn={showTaxColumn} showDiscountColumn={showDiscountColumn}
                              showSACColumn={showSACColumn} isOpenItems={isOpenItems} setIsOpenItems={setIsOpenItems}
                              onInputChange={onInputChange} handleDeleteRow={handleDeleteRow} {...data} allTaxes={allTaxes}
                            />
                          )
                        })
                      }
                      <div>
                        <Tooltip title="Add new row">
                          <Link to="#" onClick={handleChange}>
                            <MdAddCircle className="fs-3 mt-1 text-primary" onClick={addNewItem} style={{ cursor: "pointer" }} />
                          </Link>
                        </Tooltip>
                      </div>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="mx-4 my-3 p-0 row">
                <Col className="text-start py-2 px-0" style={{ width: "40%" }}>
                  <span className="m-0 p-0 fw-semibold">Add Remark</span>
                  <div className="m-0 my-2" style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                    <div className="">
                      <Form.Control type="text" name="remark" as="textarea" rows={1} onChange={handleChange} placeholder="Add Remark" className="border-0" />
                    </div>
                  </div>
                  <span className="m-0 p-0 fw-semibold">Add Note</span>
                  <div className="m-0 my-2" style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                    <div className="">
                      <Form.Control type="text" name="notes" as="textarea" rows={1} onChange={handleChange} placeholder="Add Note" className="border-0" />
                    </div>
                  </div>
                  <span className="m-0 p-0 fw-semibold">Terms & Conditions</span>
                  <div className="m-0 my-2" style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                    <div className="">
                      <Form.Control type="text" name="termsNcondition" as="textarea" rows={5} onInput={handleChange} onChange={handleTerms}
                        placeholder="Add Terms & Conditions" className="border-0" />
                    </div>
                  </div>
                </Col>
                <Col className="text-start m-0 ms-5 py-2 px-0" style={{ width: "50%" }}>
                  <span className="m-0 p-0 me-3 fw-semibold">Total</span>
                  <div className="m-0 my-2 py-3 px-0" style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                    <div className="mt-2 p-0" style={{ fontSize: "0.8rem" }}>
                      <Row className="m-0 px-0 text-end">
                        <Col sm={8} className="text-end">Subtotal: </Col>
                        <Col sm={4} className="text-end" name="subTotal" style={{ maxWidth: "100%", overflowX: "scroll" }}>
                          {curr}
                          {subTotal}
                          {/* {basePrice.toFixed(2)} */}
                        </Col>
                      </Row>
                      {
                        showDiscountColumn ? (
                          <Row className="m-0 px-0 text-end">
                            <Col sm={8} className="text-end">Discount: </Col>
                            <Col sm={4} className="text-end" name="subTotal" style={{ maxWidth: "100%", overflowX: "scroll" }}>
                              {`${curr} ${(subTotal - discountedPrice).toFixed(2)}`}
                            </Col>
                          </Row>
                        ) : null
                      }
                      {
                        itemstaxTotal > 0 && (
                          <Row className="m-0 px-0 text-end">
                            <Col sm={8} className="text-end">Tax: </Col>
                            <Col sm={4} className="text-end" name="subTotal" style={{ maxWidth: "100%", overflowX: "scroll" }}>
                              {curr} {itemstaxTotal.toFixed(2)}
                            </Col>
                          </Row>
                        )
                      }
                      {
                        addedtax.map((data, index) => (
                          (
                            <Row className="m-0 p-0" style={{ display: !taxApplied && "none" }} key={index}>
                              <Col sm={8} className="text-end">{Object.keys(data)}:</Col>
                              <Col sm={4} className="text-end" name="tax" style={{ maxWidth: "100%", overflowX: "scroll" }}>{`${curr} `}
                                {parseFloat(Object.values(data) / 100 * basePrice).toFixed(2)}
                              </Col>
                            </Row>
                          )
                        ))
                      }
                      <Row className="m-0 px-0 text-end">
                        <Col sm={8} className="text-end">Roundoff: </Col>
                        <Col sm={4} className="text-end" name="subTotal" style={{ maxWidth: "100%", overflowX: "scroll" }}>{roundOperation} {curr} {(roundoff).toFixed(2)}</Col>
                      </Row>
                      <Row className="m-0 p-0 text-primary py-1 bg-soft-primary fw-semibold">
                        <Col sm={8} className="text-end">Total: </Col>
                        <Col sm={4} className="text-end" name="total" style={{ maxWidth: "100%", overflowX: "scroll" }}>{curr} {grandTotal.toFixed(2)}</Col>
                      </Row>
                    </div>
                  </div>
                  <Row className="mt-5">
                    <Col sm={6}></Col>
                    <Col sm={6} className="mt-5" style={{ alignItems: "end", display: "flex", justifyContent: "flex-end" }}>
                      <button type="submit" className="m-0 p-0 border border-none bg-white">
                        <span className="ms-2 py-2 px-3 rounded bg-primary text-white" onClick={handleValidation} onMouseOver={handleChange} style={{ cursor: "pointer" }}>Save Invoice</span>
                      </button>
                    </Col>
                  </Row>
                </Col>
              </div>
            </Form>
          </Col>
          <ActivityBar options={invoiceOptions} hide={true} />
        </div>
        <InvoicePreview addedtax={addedtax} setShow={setShow} show={show} firmDetails={firmDetails} firmAdd={firmAdd} clientDetails={clientDetails} clientAdd={clientAdd} items={items} form={form} currency={curr} terms={terms} />

        <TaxModal getAllTaxes={getAllTaxes} setFirmTaxes={setFirmTaxes} getGlobalTaxes={getGlobalTaxes} setAllGlobalTaxes={setAllGlobalTaxes}
          getSingleFirm={getSingleFirm} globaltaxes={globaltaxes} firmId={firmId} saveDraft={saveDraft} setDraft={setDraft} newState={newState}
          setNewState={setNewState} taxes={firmTaxes} addedTax={addedtax} setAddedtax={setAddedtax} showTax={showTax} setShowTax={setShowTax}
          showDraft={showDraft} setShowDraft={setShowDraft} draft={setDraft} handleSaveDraft={handleSaveDraft} calculateTax={calculateTax} setAllSelectedTaxes={setAllSelectedTaxes}
        />

        <RecurringModal setRecurring={setRecurring} form={form} setForm={setForm} setDueDate={setEndDate} invoiceDate={createdDate} showRecurring={showRecurring} setShowRecurring={setShowRecurring} />
        <Addclientmodal getClients={getClients} showClient={showClient} setShowClient={setShowClient} />
        <Addfirmmodal getFirms={getFirms} showFirm={showFirm} setShowFirm={setShowFirm} />
        <div className="d-none">
          <Notification notifs={notificationAdded} />
        </div>
        <CreateProducts firmId={firmId} allFirms={allFirms} showCreateProducts={showCreateProducts} setShowCreateProducts={setShowCreateProducts} firmTaxes={firmTaxes} />
        <SetReminderModal showAddReminder={showAddReminder} setShowAddReminder={setShowAddReminder} form={form} setForm={setForm} />
        <Modal centered show={showTaxNDiscount} onHide={() => setShowTaxNDiscount(false)}>
          <Modal.Body className="my-2">
            <Row className="mb-2 ms-1">
              {/* <Col sm={12} className=" text-center px-2 m-0 p-0 fs-3">Delete Invoice</Col> */}
              <Col sm={12} className="text-center px-2 m-0 p-0 fs-3">
                Add Taxes and Discounts to Items
              </Col>
            </Row>
            <div className="ms-2 w-100" >
              <Row className="mx-5">
                <Col sm={2} className="text-end" style={{ cursor: "pointer" }} onClick={() => setShowTaxColumn(!showTaxColumn)}>
                  <Form.Check.Input type="checkbox" name="taxColumn" onChange={() => setShowTaxColumn(!showTaxColumn)} checked={showTaxColumn} style={{ cursor: "pointer" }} />
                </Col>
                <Col sm={2} onClick={() => setShowTaxColumn(!showTaxColumn)} style={{ cursor: "pointer" }}>
                  <label style={{ fontSize: '0.8rem', cursor: "pointer" }}>Tax</label>
                </Col>
              </Row>

              <Row className="mx-5">
                <Col sm={2} onClick={() => setShowDiscountColumn(!showDiscountColumn)} className="text-end" style={{ cursor: "pointer" }}>
                  <Form.Check.Input type="checkbox" name="discountColumn" onChange={() => setShowDiscountColumn(!showDiscountColumn)} checked={showDiscountColumn} style={{ cursor: "pointer" }} />
                </Col>
                <Col sm={2} onClick={() => setShowDiscountColumn(!showDiscountColumn)}>
                  <label style={{ fontSize: '0.8rem', cursor: "pointer" }}>Discounts</label>
                </Col>
              </Row>

              <Row className="mx-5">
                <Col sm={2} onClick={() => setShowHSNColumn(!showHSNColumn)} className="text-end" style={{ cursor: "pointer" }}>
                  <Form.Check.Input type="checkbox" name="discountColumn" onChange={() => setShowHSNColumn(!showHSNColumn)} checked={showHSNColumn} style={{ cursor: "pointer" }} />
                </Col>
                <Col sm={2} onClick={() => setShowHSNColumn(!showHSNColumn)}>
                  <label style={{ fontSize: '0.8rem', cursor: "pointer" }}>HSN</label>
                </Col>
              </Row>

              <Row className="mx-5">
                <Col sm={2} onClick={() => setShowSACColumn(!showSACColumn)} className="text-end" style={{ cursor: "pointer" }}>
                  <Form.Check.Input type="checkbox" name="discountColumn" onChange={() => setShowSACColumn(!showSACColumn)} checked={showSACColumn} style={{ cursor: "pointer" }} />
                </Col>
                <Col sm={2} onClick={() => setShowSACColumn(!showSACColumn)}>
                  <label style={{ fontSize: '0.8rem', cursor: "pointer" }}>SAC</label>
                </Col>
              </Row>

              {/* <Row className="mx-5">
                <Col sm={2} onClick={handleShowHSN} className="text-end" style={{ cursor: "pointer" }}>
                  <input type="radio" name="radio" onChange={handleShowHSN} checked={showHSNColumn} style={{ cursor: "pointer" }} />
                </Col>
                <Col sm={2} onClick={handleShowHSN}>
                  <label style={{ fontSize: '0.8rem', cursor: "pointer" }}>HSN</label>
                </Col>
              </Row>

              <Row className="mx-5">
                <Col sm={2} onClick={handleShowSAC} className="text-end" style={{ cursor: "pointer" }}>
                  <input type="radio" name="radio" onChange={handleShowSAC} checked={showSACColumn} style={{ cursor: "pointer" }} />
                </Col>
                <Col sm={2} onClick={handleShowSAC}>
                  <label style={{ fontSize: '0.8rem', cursor: "pointer" }}>SAC</label>
                </Col>
              </Row> */}
            </div>
          </Modal.Body>
        </Modal >
        {loader}
      </div>
    </div>
  );
};

export default InvoiceAdd;
