import { Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  BsChevronDown,
  BsChevronUp,
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
} from "react-icons/bs";
import { Link } from "react-router-dom";
import Activity from "../dashboard/MainStyle/Activity";

const ActivityBar = ({ list, options, hide, path, lead, allRecentActivities }) => {

  const [showOptions, setShowOptions] = useState(true);
  const [showActivity, setShowActivity] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const breakPoint = 1200;

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  });


  return (
    <div className="m-0 p-0" style={{ width: screenWidth > breakPoint ? "25%" : "5%" }}>
      {screenWidth >= breakPoint ? (
        <Row className="ms-2" style={{ width: "95%", maxWidth: "95%", height: "10vh", maxHeight: "100%", }}>
          <div className="bg-white  py-2 px-0 rounded m-0 mb-2" style={{ display: list ? 'none' : 'block', }}>
            <div className="w-100 row m-0 p-0" onClick={() => setShowOptions(!showOptions)}>
              <Col sm={10} className="fs-5 text-primary fw-semibold text-start">Options</Col>
              <Col sm={1} className="text-end">
                {showOptions === false ? (
                  <BsChevronDown className="fs-4" onClick={() => setShowOptions(true)} style={{ cursor: "pointer" }} />
                ) : (
                  <BsChevronUp className="fs-4" onClick={() => setShowOptions(false)} style={{ cursor: "pointer" }} />
                )}
              </Col>
            </div>
            {showOptions === true && <div>{options()}</div>}
          </div>
          {
            lead ? null : (
              <div
                className="m-0 p-0"
                style={{ maxHeight: "70vh", overflowX: "hidden", overflowY: "scroll", scrollBehavior: "smooth", display: hide && 'none' }}>
                <Activity title="Recent Activities" allRecentActivities={allRecentActivities} showActivity={showActivity} setShowActivity={setShowActivity} />
              </div>
            )
          }
        </Row>
      ) : (
        <Row sm={1} className="mx-2 w-100" style={{ minHeight: "100vh", width: "2rem" }}>
          <div className="activity-bar">
            <Tooltip title={hide ? 'Options' : 'Activities'} key="activities">
              <Link to="#">
                <BsFillArrowLeftCircleFill className={path ? 'text-primary fs-2' : 'text-white fs-2'}
                  onClick={() => setShowSidebar(!showSidebar)} style={{ cursor: "pointer" }} />
              </Link>
            </Tooltip>
          </div>
          <nav className={showSidebar ? "activity-menu active" : "activity-menu"} style={{ minHeight: "100vh" }}>
            <div className="activity-menu-items">
              <div className="activitybar-toggle h-1">
                <Tooltip title={hide ? 'Close Options' : 'Close Activities'} key="clsActivity">
                  <Link to="#">
                    <BsFillArrowRightCircleFill className="text-primary fs-2" onClick={() => setShowSidebar(!showSidebar)} style={{ cursor: "pointer" }} />
                  </Link>
                </Tooltip>
              </div>
              <div style={{ width: "50%" }}>
                <div className="w-75 bg-white py-2 rounded m-0 mb-2 me-5"
                  style={{ display: list && "none", boxShadow: "3px 3px 10px #00000030", borderTop: "1px solid #c8c9ca", borderLeft: "1px solid #c8c9ca" }}
                >
                  <Row className="mx-2" onClick={() => setShowOptions(!showOptions)}>
                    <Col sm={9}><h4 className="text-primary">Options</h4></Col>
                    <Col sm={1}>
                      {showOptions === false ? (
                        <BsChevronDown className="fs-4" onClick={() => setShowOptions(true)} style={{ cursor: "pointer" }} />
                      ) : (
                        <BsChevronUp className="fs-4" onClick={() => setShowOptions(false)} style={{ cursor: "pointer" }} />
                      )}
                    </Col>
                  </Row>
                  {showOptions === true && <div>{options()}</div>}
                </div>
                {
                  lead ? null : (
                    <div
                      className="m-0 p-0"
                      style={{ maxHeight: "100vh", overflowX: "hidden", overflowY: "scroll", scrollBehavior: "smooth", display: hide && 'none' }}>
                      <Activity title="Recent Activities" allRecentActivities={allRecentActivities} showActivity={showActivity} setShowActivity={setShowActivity} />
                    </div>
                  )
                }
              </div>
            </div>
          </nav>
        </Row>
      )}
    </div>
  );
};

export default ActivityBar;
