import { Button, Card, Col, Form, Radio, Row, Select, Checkbox, Tag, Typography } from "antd";

import { Fragment, useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
// import PageTitle from "../page-header/PageHeader";
// import { addPermission, loadPermission } from "./roleApis";
// import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import AntLoader from "../../../components/loader/AntLoader";
import { addPermission, loadPermission } from "../../Hook/roleHooks";

function PermissionList(props) {
	const permissionNames = props.permissionNames;
	const { selectedPermission, setSelectedPermission } = props;

	// const onChange = (e) => {
	// 	const { value } = e.target;
	// };

	const permissionElements = permissionNames.map((item) => (
		<Fragment key={item._id}>
			<Checkbox
				value={item._id}
				onChange={() => {
					setSelectedPermission((prev) => {
						return {
							...prev,
							[item._id]: !prev[item._id],
						};
					});
				}}
				checked={selectedPermission[item._id]}>
				{item.name}
			</Checkbox>
		</Fragment>
	));

	const rows = [];
	for (let i = 0; i < permissionElements.length; i += 5) {
		rows.push(
			<div
				key={i}
				className='d-flex justify-content-between align-items-center m-4 border-2 border-indigo-100 px-4 py-3'>
				{permissionElements.slice(i, i + 5)}
				<br />
			</div>
		);
	}
	return <div>{rows}</div>;
}

const AddPermission = () => {
	const location = useHistory();
	const { id } = useParams();
	const { Option } = Select;
	const [permissions, setPermissions] = useState([]);
	const [selectedPermission, setSelectedPermission] = useState({});
	//Loading Old data from URL
	// const location = useLocation();
	const data = {
		name: "",
		rolePermissions: []
	};
	// const { data } = location.state;
	const roleName = data.name;
	const rolePermissions = data.rolePermissions;

	const { Title } = Typography;
	const [form] = Form.useForm();
	const [loader, setLoader] = useState(false);

	useEffect(() => {
		loadPermission().then((d) => {
			setPermissions(d);
			const permissions = d.reduce((acc, item) => {
				acc[item._id] = rolePermissions.some((i) => i._id === item._id);
				return acc;
			}, {});
			setSelectedPermission(permissions);
		});
	}, [id]);

	const permisionIds = Object.entries(selectedPermission).reduce(
		(acc, [key, value]) => {
			if (value) {
				acc.push(key);
			}
			return acc;
		},
		[]
	);

	const onFinish = async (values) => {
		setLoader(true);
		try {
			const data = {
				role: id,
				permission: permisionIds,
			};

			const resp = await addPermission(data); //permision func

			if (resp.message === "success") {
				location(-1);
				setLoader(false);
				form.resetFields();
			}
			if (resp.message === "error") {
				toast.error("Error at giving permission, Try again");
				setLoader(false);
				form.resetFields();
			}

			form.resetFields();
		} catch (error) {
			console.log(error.message);
			setLoader(false);
		}
	};

	const isLogged = Boolean(localStorage.getItem("isLogged"));

	// if (!isLogged) {
	// 	return <location to={"/admin/auth/login"} replace={true} />;
	// }

	return (
		<div className="mx-4">
			{/* <PageTitle title={"Back"} /> */}
			{/* <UserPrivateComponent permission={"create-rolePermission"}> */}
			<Row className='mr-top' justify={"center"}>
				<Col
					xs={24}
					sm={24}
					md={24}
					lg={24}
					xl={24}
					className='border rounded column-design'>
					<Card bordered={false} className='criclebox h-full'>
						<Title level={3} className='m-3 text-center mb-5'>
							Add Permission :{" "}
							<span className='text-primary'>{roleName}</span>
						</Title>

						{permissions.length > 0 ? (
							<>
								<PermissionList
									permissionNames={permissions}
									hasPermissions={rolePermissions}
									setSelectedPermission={setSelectedPermission}
									selectedPermission={selectedPermission}
								/>

								<div className='text-center'>
									<Button
										className='m-3 w-80 '
										onClick={onFinish}
										type='primary'
										htmlType='submit'
										size='large'
										shape='round'
										loading={loader}>
										Permit Now
									</Button>
								</div>
							</>
						) : (
							<AntLoader />
							// <Loader />
						)}
					</Card>
				</Col>
			</Row>
			{/* </UserPrivateComponent> */}
		</div>
	);
};

export default AddPermission;
