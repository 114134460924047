import React, { useEffect, useState } from "react";
import Card from "../../../../../components/Card";
import { Button, Col, Form, Row } from "react-bootstrap";
import SubHeader from "../../../../../components/partials/dashboard/HeaderStyle/sub-header";
import { updateUser, userById } from "../../Hook/userHooks";
import { useParams } from "react-router-dom";

const Editemployee = () => {
  const [form, setForm] = useState({});
  const [bankDetails, setBankDetails] = useState({});
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [singleUser, setSingleUser] = useState([]);
  const formData = new FormData();

  const { id } = useParams();

  const getUser = () => {
    userById(setSingleUser, id)
  }

  useEffect(() => {
    getUser();
  }, [])

  console.log("single user data", singleUser)

  const [allData, setAllData] = useState({});

  const update = (e) => {
    e.preventDefault();
    // formData.append("gender", form.gender);
    // formData.append("dob", form.dob);
    // formData.append("dob", form.gender);
    // formData.append("doj", form.doj);
    // formData.append("dol", form.dol);
    // formData.append("designation", form.designation);
    // formData.append("panno", form.panno);
    // formData.append("panno", form.gender);
    setForm({
      ...form,
      [e.target.name]: e.target.value,
      bankDetails: bankDetails,
    });

    updateUser(form, id).then((response) => {
      console.log("all data", response.data);
    });
  };

  console.log("user id", id);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleBankDetails = (e) => {
    setBankDetails({
      ...bankDetails,
      [e.target.name]: e.target.value,
    });
  };

  console.log("form data", form);
  console.log("bank details", bankDetails);

  const handleSubmit = (e) => {
    e.preventDefault();
    setForm({
      ...form,
      [e.target.name]: e.target.vlaue,
      bankDetails: bankDetails,
    });
    // setBankDetails({
    //   ...bankDetails,
    //   [e.target.name]: e.target.vlaue
    // });
  };

  useEffect(() => {
    const handleResize = setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <>
      <SubHeader title="Edit Employee Details" />
      <div
        className="mx-3"
        style={{
          marginTop: screenWidth >= 1200 ? "-2%" : "-3%",
        }}
      >
        <Row className="mb-5">
          <Col sm={3} style={{ fontSize: "0.8rem" }}>
            <div className="row m-0 p-2 bg-white rounded">
              <Col
                sm={12}
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src="https://s.yimg.com/fz/api/res/1.2/l7iLbEU.4eQ9goxc6WUknQ--~C/YXBwaWQ9c3JjaGRkO2ZpPWZpbGw7aD0zODQ7cT04MDtzbT0xO3c9NTEy/https://media.zenfs.com/en/aol_the_independent_uk_548/1f6e24c8423bcd4d38ea2b9f75247f42"
                  alt="profileImage"
                  className="rounded-circle"
                  style={{ width: "50px", height: "50px" }}
                />
              </Col>
              <Col
                sm={12}
                className="d-flex"
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <span className="mt-2 p-0 fw-semibold fs-5">{`${singleUser.firstName} ${singleUser.lastName}`}</span>
              </Col>
              <Col
                sm={12}
                className="d-flex"
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <span className="text-secondary fs-6">Associate Developer</span>
              </Col>
            </div>

            <div className="row m-0 my-2 py-2 px-0 bg-white rounded">
              <Col sm={12} className="p-0 m-0">
                <span className="mt-2 mb-2 p-0 fw-semibold fs-5 text-primary">
                  Contact Details
                </span>
              </Col>
              <Col sm={12}>
                <Row>
                  <Col className="fw-semibold" md={12} lg={3}>
                    Phone:{" "}
                  </Col>
                  <Col>{singleUser.phone}</Col>
                </Row>
                <Row className="py-1">
                  <Col className="fw-semibold" md={12} lg={3}>
                    Email:{" "}
                  </Col>
                  <Col>{singleUser.email}</Col>
                </Row>
                <Row>
                  <Col className="fw-semibold" md={12} lg={3}>
                    Address:{" "}
                  </Col>
                  <Col>123, Shipra path, masarovar, jaipur</Col>
                </Row>
              </Col>
            </div>

            <div className="row m-0 py-2 px-0 bg-white rounded">
              <Col sm={12} className="p-0 m-0">
                <span className="mt-2 p-0 fw-semibold fs-5 text-primary">
                  Other Details
                </span>
              </Col>
              <Col sm={12}>
                <Row>
                  <Col className="fw-semibold" md={12} lg={3}>
                    Pipeline:{" "}
                  </Col>
                  <Col>{singleUser.department}</Col>
                </Row>
                <Row>
                  <Col className="fw-semibold" md={12} lg={3}>
                    Role:{" "}
                  </Col>
                  <Col>{singleUser.role}</Col>
                </Row>
              </Col>
            </div>
          </Col>
          <Col>
            <form onSubmit={handleSubmit}>
              <div className="bg-white rounded px-5 py-3">
                <div>
                  <div className="header-title">
                    <p
                      className="text-primary fw-semibold"
                      style={{ fontSize: "1.2rem" }}
                    >
                      Basic Details
                    </p>
                  </div>
                </div>
                <div>
                  <div className="row">
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="firstName"
                          className="border-primary"
                          onChange={handleChange}
                          placeholder={singleUser.firstName ? singleUser.firstName : 'First Name' }
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="lastName"
                          className="border-primary"
                          onChange={handleChange}
                          placeholder={singleUser.lastName ? singleUser.lastName : 'Last Name' }
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6} className="mt-2">
                      <Form.Group>
                        <Form.Label>Phone No</Form.Label>
                        <Form.Control
                          type="tel"
                          name="phone"
                          className="border-primary"
                          onChange={handleChange}
                          placeholder={singleUser.phone ? singleUser.phone : 'Mobile No.' }
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6} className="mt-2">
                      <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          className="border-primary"
                          onChange={handleChange}
                          placeholder={singleUser.email ? singleUser.email : 'Email' }
                        />
                      </Form.Group>
                    </Col>
                  </div>
                </div>
              </div>

              <div
                style={{
                  borderLeft: "2px solid red",
                  minHeight: "50px",
                  marginLeft: "50%",
                }}
              ></div>

              <div className="bg-white rounded px-5 py-3">
                <div>
                  <div className="header-title">
                    <p
                      className="text-primary fw-semibold"
                      style={{ fontSize: "1.2rem" }}
                    >
                      Address
                    </p>
                  </div>
                </div>
                <div>
                  <div className="row">
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>Street 1</Form.Label>
                        <Form.Control
                          type="text"
                          name="address1"
                          className="border-primary"
                          onChange={handleChange}
                          placeholder="Phone No."
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>Street 2</Form.Label>
                        <Form.Control
                          type="text"
                          name="address2"
                          className="border-primary"
                          onChange={handleChange}
                          placeholder="Email"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6} className="mt-2">
                      <Form.Group>
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          type="text"
                          name="City"
                          className="border-primary"
                          onChange={handleChange}
                          placeholder="City"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6} className="mt-2">
                      <Form.Group>
                        <Form.Label>State</Form.Label>
                        <Form.Control
                          type="text"
                          name="state"
                          className="border-primary"
                          onChange={handleChange}
                          placeholder="State"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6} className="mt-2">
                      <Form.Group>
                        <Form.Label>Pincode</Form.Label>
                        <Form.Control
                          type="number"
                          name="pincode"
                          className="border-primary"
                          onChange={handleChange}
                          placeholder="Pincode"
                        />
                      </Form.Group>
                    </Col>
                  </div>
                </div>
              </div>

              <div
                style={{
                  borderLeft: "2px solid red",
                  minHeight: "50px",
                  marginLeft: "50%",
                }}
              ></div>

              <div className="bg-white rounded px-5 py-3">
                <div>
                  <div className="header-title">
                    <p
                      className="text-primary fw-semibold"
                      style={{ fontSize: "1.2rem" }}
                    >
                      Position Information
                    </p>
                  </div>
                </div>
                <div>
                  <div className="row">
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>Date of Birth</Form.Label>
                        <Form.Control
                          type="date"
                          name="dob"
                          className="border-primary"
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6} className="mt-2">
                      <Form.Group>
                        <Form.Label>Date of Joining</Form.Label>
                        <Form.Control
                          type="date"
                          name="doj"
                          className="border-primary"
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6} className="mt-2">
                      <Form.Group>
                        <Form.Label>Designation</Form.Label>
                        <Form.Control
                          type="text"
                          name="designation"
                          className="border-primary"
                          onChange={handleChange}
                          placeholder={singleUser.designation ? singleUser.designation : 'Designation' }
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6} className="mt-2">
                      <Form.Group>
                        <Form.Label>Pipeline</Form.Label>
                        <Form.Control
                          type="text"
                          name="department"
                          className="border-primary"
                          onChange={handleChange}
                          placeholder={singleUser.department ? singleUser.department : 'Pipeline' }
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6} className="mt-2">
                      <Form.Group>
                        <Form.Label>Role</Form.Label>
                        <Form.Control
                          type="text"
                          name="role"
                          className="border-primary"
                          onChange={handleChange}
                          placeholder={singleUser.role ? singleUser.role : 'Role' }
                        />
                      </Form.Group>
                    </Col>
                  </div>
                  <Row className="mt-3">
                    <Col sm={3}>
                      <Button type="submit" style={{cursor: "pointer"}}>Update</Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </form>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Editemployee;
