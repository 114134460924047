import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import Pagination from "../../../app/pagination/pagination";
import SubHeader from "../../../../../components/partials/dashboard/HeaderStyle/sub-header";
import { getAllUsers, getUserById, getUsersList, inviteUser } from "../../Hook/userHooks";
import ActivityBar from "../../../../../components/partials/components/activitybar";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { AiOutlineSend } from "react-icons/ai";
import { ErrorToast, SuccessToast } from "../../../components/toast";
import { MdClear } from "react-icons/md";
import { FiEye } from "react-icons/fi";

const AllUsers = () => {
  const [userPermissions, setUserPermissions] = useState([]);
  const [updatePermission, setUpdatePermission] = useState(false);
  const [createPermissions, setCreatePermission] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [user, setUser] = useState('');
  const [form, setForm] = useState({});
  const [singleData, setSingleData] = useState([]);
  const [viewOptions, setViewOptions] = useState(false);
  const [show, setShow] = useState(false);
  const [showInvitationForm, setShowInvitationForm] = useState(false);
  const [inviteHovered, setInviteHovered] = useState(false);
  const [isOpenUsersList, setIsOpenUsersList] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);

  const showPerPage = 10;
  const [pagination, setPagination] = useState({ start: 0, end: showPerPage, });

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const breakPoint = 970;

  const orgName = localStorage.getItem('orgName');
  const orgId = localStorage.getItem('orgID');
  const orgEmail = localStorage.getItem('orgEmail');
  const loggedInUserId = localStorage.getItem('userId');

  useEffect(() => {
    getUserById(loggedInUserId).then((response) => {
      if (response.status === 200) setUserPermissions(response?.data?.data?.permissions);
      else setUserPermissions([]);
    }).catch(() => setUserPermissions([]));
  }, [loggedInUserId]);

  useEffect(() => {
    const create = userPermissions.length > 0 ? userPermissions.some((permission) => permission.module === 'user' && permission.action.includes('Create')) : false;
    const update = userPermissions.length > 0 ? userPermissions.some((permission) => permission.module === 'user' && permission.action.includes('Update')) : false;

    setCreatePermission(create);
    setUpdatePermission(update);
  }, [userPermissions]);

  useEffect(() => {
    getUsersList().then((response) => {
      if (response.status === 200) setUsersList(response.data.data)
      else setUsersList([]);
    }).catch(() => setUsersList([]));
  }, []);

  useEffect(() => {
    !showInvitationForm && setUser('');
  }, [showInvitationForm]);

  useEffect(() => {
    getAllUsers().then((response) => {
      if (response.status === 200) {
        setAllUsers(response.data.data);
        response.data.data[0]?._id && getSingleUser(response.data.data[0]?._id);
      } else {
        setAllUsers([]);
      }
    }).catch(() => {
      setAllUsers([]);
    })
  }, []);

  const getSingleUser = async (data) => {
    getUserById(data).then((response) => {
      if (response.status === 200) {
        setSingleData(response.data.data);
      } else {
        setSingleData({});
      }
    }).catch(() => setSingleData({}));
  };

  const singlUserData = (e) => {
    setSingleData(e);
  };

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  const onPaginationChange = (start, end) => {
    setPagination({
      start: start,
      end: end,
    });
  };

  const handleChangeInvitation = (e) => {
    setUser(e.target.value);
    setIsOpenUsersList(true);
    setForm({
      ...form,
      to: e.target.value,
      from: orgEmail,
      userName: orgName,
      link: `${window.location.origin}/login/${orgId}`
    });
  };

  const filteredUsersOptions = usersList.filter((option) => (`${option?.email}`).toLowerCase().includes(user.toLowerCase()));

  const handleOptionClickUser = (data) => {
    setUser(`${data?.email}`);
    setForm({
      ...form,
      to: data?.email,
      from: orgEmail,
      userName: orgName,
      link: `${window.location.origin}/login/${orgId}`
    });
    setIsOpenUsersList(false);
  };

  const sendInvitation = (e) => {
    e.preventDefault();
    setBtnDisabled(true);
    inviteUser(form).then((response) => {
      if (response.status === 201) {
        setUser('');
        setShowInvitationForm(false);
        SuccessToast("Invitation sent");
        setBtnDisabled(false);
      } else {
        setBtnDisabled(false);
        ErrorToast("Unbale to send invitation");
      }
    }).catch(() => {
      setBtnDisabled(false);
      ErrorToast("Unbale to send invitation");
    })
  };

  const handleSelect = (e) => {
    singlUserData(e);
  };

  const userOptions = () => {
    return (
      <>
        {
          createPermissions ? (
            <div className="pe-5 ps-3 mt-2">
              <div
                className={`w-75 d-flex justify-content-between align-items-center border border-primary ${inviteHovered ? 'bg-primary' : 'bg-white'} px-3 py-2 cursor-pointer`}
                onMouseOver={() => setInviteHovered(true)}
                onMouseOut={() => setInviteHovered(false)}
                onClick={() => setShowInvitationForm(true)}
                style={{ borderRadius: "5px" }}>
                <h5 className={`${inviteHovered ? 'text-white' : 'text-primary'} cursor-pointer`}>Send Invitation</h5>
                <AiOutlineSend className={`${inviteHovered ? 'text-white' : 'text-primary'} fs-3 ms-3`} />
              </div>
            </div>
          ) : null
        }
      </>
    );
  };

  const userDetails = [
    {
      title: 'Name',
      desc: `${singleData?.firstName === undefined ? '' : singleData?.firstName} ${singleData?.lastName === undefined ? '' : singleData?.lastName}`
    },
    {
      title: 'Phone',
      desc: singleData?.phone === undefined ? '' : singleData?.phone
    },
    {
      title: 'Email',
      desc: singleData?.email === undefined ? '' : singleData?.email
    },
    {
      title: 'Pipeline',
      desc: singleData?.department === undefined ? '' :
        singleData?.department.map((data, index) => {
          return `${data} ${index < singleData?.department.length - 1 ? ', ' : ''}`
        })
    },
    {
      title: 'Role',
      desc: singleData?.subRole ? singleData?.subRole : singleData?.role
    }
  ];

  return (
    <div onClick={() => viewOptions === true && setViewOptions(false)}>
      <SubHeader title="All Users" btnLink={`/${orgName}/dashboard/user/add-user`} btnTitle="Add User" />
      <div
        className="d-flex text-dark"
        style={{ marginTop: screenWidth >= 1200 ? "-1%" : "-3%", flexDirection: "row" }}
      >
        <Row className="w-25 rounded mx-2" style={{ height: "100%", marginRight: "10%" }}>
          <Col sm={12} className="rounded bg-white">
            <Row className="py-2">
              <h5 className="text-primary" style={{ fontWeight: "550" }}>
                About
              </h5>
            </Row>
            {
              userDetails.map((data, index) => (
                <Row className="py-1" key={index}>
                  <Col md={4} sm={12}>
                    <div style={{ fontWeight: "550" }}>{data?.title}: </div>
                  </Col>
                  <Col>{data?.desc}</Col>
                </Row>
              ))
            }
          </Col>
        </Row>

        <Row
          className={`h-100 bg-white rounded mx-2 mb-5`}
          style={{ minWidth: screenWidth >= breakPoint ? "50%" : "50%", width: "70%", maxWidth: "80%", }}
        >
          <div className="table-responsive mt-2" style={{ minHeight: "50vh" }}>
            <table className="table-responsive" style={{ width: "100%" }}>
              <thead className="fs-5 text-primary">
                <tr>
                  <th></th>
                  <th className="ps-3">Name</th>
                  <th>Email</th>
                  <th>Phone No.</th>
                  <th>Role</th>
                  <th className="w-5">Actions</th>
                </tr>
              </thead>
              <tbody>
                {allUsers
                  .slice(pagination.start, pagination.end)
                  .map((data, index) => (
                    <tr key={index} style={{ borderBottom: "1px solid rgb(227 227 227)" }}>
                      <td className="py-3">
                        <Form.Check type="radio" name="selectUser" className="cursor-pointer" value={data} onChange={() => handleSelect(data)} defaultChecked={index === 0} />
                      </td>
                      <td>
                        <span className="text-dark">{`${data?.firstName} ${data?.lastName !== undefined ? data?.lastName : ''}`}</span>
                      </td>
                      <td style={{ textAlign: "left" }}>{data?.email}</td>
                      <td>{data?.phone}</td>
                      {/* <td>{data.department}</td> */}
                      <td>{data?.subRole ? data?.subRole : data?.role}</td>
                      <td>
                        <div className="flex align-items-left">
                          <Tooltip title="Profile" key="Profile">
                            <Link to={`/${orgName}/dashboard/user/profile/${data?._id}`} className="mx-2">
                              <FiEye id="profile" className="text-primary cursor-pointer" style={{ fontSize: "0.9rem" }} />
                            </Link>
                          </Tooltip>
                          {
                            updatePermission ? (
                              <Tooltip title="Update" key="Update">
                                <Link to={`/${orgName}/dashboard/user/update-profile/${data?._id}`}>
                                  <HiOutlinePencilAlt id="editUser" className="text-success cursor-pointer" style={{ fontSize: "0.9rem" }} />
                                </Link>
                              </Tooltip>
                            ) : null
                          }
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {
              allUsers.length === 0 && (
                <div className='mt-5 fs-4 text-center text-secondary'>
                  No Users To Show
                </div>
              )
            }
          </div>
          <div className="my-5">
            <Pagination
              showPerPage={showPerPage}
              onPaginationChange={onPaginationChange}
              total={allUsers.length}
            />
          </div>
        </Row>
        <ActivityBar options={userOptions} />

        <Modal size="md" centered show={showInvitationForm} onHide={() => setShowInvitationForm(false)}>
          <Modal.Header className="bg-primary">
            <Col sm={11} className="text-white">
              <h4 className="text-white">Send Invitation</h4>
            </Col>
            <Col sm={1} className="text-end text-white">
              <MdClear className="fs-4" onClick={() => setShowInvitationForm(false)} style={{ cursor: "pointer" }} />
            </Col>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={sendInvitation}>
              <Form.Group className="d-flex justify-content-between align-items-center w-100">
                <Form.Control type="input" className="border border-primary me-2 w-75 position-relative" name="email" value={user}
                  onClick={() => setIsOpenUsersList(true)} onChange={handleChangeInvitation}
                  autoComplete='off' placeholder="Enter email" required
                />
                {(isOpenUsersList && (user.length > 0)) && (
                  <div style={{ maxHeight: "15rem", top: '4rem', width: '68%', zIndex: '999', border: "black", overflowY: "scroll" }} className="bg-white position-absolute border px-3 py-2 border-2 d-flex flex-column">
                    {
                      usersList.length > 0 ? (
                        ((filteredUsersOptions.length > 0)) ? (
                          filteredUsersOptions.map((option, i) => (
                            <div className='mt-2 cursor-pointer' onClick={() => handleOptionClickUser(option)} key={i}>
                              {option?.email}
                            </div>
                          ))
                        ) : (
                          <div className="mt-2">No match found</div>
                        )
                      ) : (
                        <div className="mt-2">No user found </div>
                      )
                    }
                  </div>
                )}
                <Button className="bg-primary text-white p-2 w-25 m-0 cursor-pointer" type="submit" disabled={btnDisabled} style={{ borderRadius: "5px" }}>Send</Button>
              </Form.Group>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal show={show} onHide={() => setShow(false)} backdrop="static" keyboard={false} closeButton>
          <Modal.Header closeButton>
            <Modal.Title className="text-primary">Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span>Do you want to delete the user ? </span>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default AllUsers;
