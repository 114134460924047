import { Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BsChevronDown, BsChevronUp, BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import Activity from "../dashboard/MainStyle/Activity";

const LeadActivityBar = ({ list, options, hide, path, lead, details }) => {

    const [showOptions, setShowOptions] = useState(true);
    const [showActivity, setShowActivity] = useState(false);
    const [showSidebar, setShowSidebar] = useState(false);
    const [label, setLabel] = useState('');
    const [managerLabel, setManagerLabel] = useState('');
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const breakPoint = 1200;

    const orgName = localStorage.getItem('orgName');

    useEffect(() => {
        let splittedName = [];
        splittedName = details?.assignedTo?.userName.split(' ');
        let firstLetters = [];

        if (splittedName) firstLetters = splittedName.map(data => data.charAt(0).toUpperCase());
        else firstLetters = [];

        setLabel(firstLetters.join(''));

        let splittedManagerName = [];
        splittedManagerName = details?.leadManager?.managerName.split(' ');
        let managerFirstLetter = [];

        if (splittedManagerName) managerFirstLetter = splittedManagerName.map(data => data.charAt(0).toUpperCase());
        else managerFirstLetter = [];

        setManagerLabel(managerFirstLetter.join(''));
    }, [details]);

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        }
    });

    const styleHandle = (status) => {
        if (status === "Hold") {
            return { background: "#FFB802" };
        } else if (status === "Won") {
            return { background: "#10813B" };
        } else if (status === "Lost") {
            return { background: "#A70200" };
        } else if (status === "In Process") {
            return { background: "blue" };
        }
    };

    return (
        <div className="m-0 p-0" style={{ width: screenWidth > breakPoint ? "26%" : "5%" }}>
            {screenWidth >= breakPoint ? (
                <Row className="ms-2" style={{ width: "95%", maxWidth: "95%", height: "10vh", maxHeight: "100%", }}>
                    <div className="py-2 ps-4 rounded my-2 rounded-5"
                    // style={{ boxShadow: "0px 5px 32px -23px #4b4b4bbf" }}
                    >
                        <div className="ps-2 w-75 d-flex justify-content-between align-items-start">
                            <div>
                                <h5 className="fw-bold">{details?.title}</h5>
                                <p className="m-0 p-0 fs-5 ps-1">{details?.clientFName} {details?.clientLName}</p>
                            </div>
                            <div>
                                <p className="m-0 p-0 fs-5 ps-1">{details?.stage}</p>
                                <p className="m-0 px-1 fs-5 ps-1 text-center rounded-pill text-white fw-bold" style={styleHandle(details?.status)}>{details?.status}</p>
                            </div>
                        </div>

                        <div className="mt-3 mb-2 ps-2 w-75">
                            <h5 className="m-0 p-0 fw-bold">Manager</h5>
                            <Link to={`/${orgName}/dashboard/user/update-profile/${details?.leadManager?.id}`}>
                                <div className="d-flex justify-content-start align-items-center mt-2">
                                    {
                                        (managerLabel && managerLabel.length > 0) ? (
                                            <span className={`${managerLabel.length === 1 ? 'px-2' : 'px-1'} py-1 bg-dark text-white fw-bold rounded-circle me-2 cursor-pointer d-flex justify-content-center align-items-center m-0`}>{managerLabel}</span>
                                        ) : null
                                    }
                                    <span className="m-0 p-0 ps-2 fs-5 cursor-pointer text-dark">{details?.leadManager?.managerName}</span>
                                </div>
                            </Link>
                        </div>

                        <div className="mt-4 mb-2 ps-2 w-75">
                            <h5 className="m-0 p-0 fw-bold">Assigned To</h5>
                            <Link to={`/${orgName}/dashboard/user/update-profile/${details?.assignedTo?.userId}`}>
                                <div className="d-flex justify-content-start align-items-center mt-2">
                                    {
                                        (label && label.length > 0) ? (
                                            <span className={`${label.length === 1 ? 'px-2' : 'px-1'} py-1 bg-warning text-white fw-bold rounded-circle me-2 cursor-pointer d-flex justify-content-center align-items-center m-0`}>{label}</span>
                                        ) : null
                                    }
                                    <span className="m-0 p-0 ps-2 fs-5 cursor-pointer text-dark">{details?.assignedTo?.userName}</span>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <hr />
                    <div className={`py-2 px-0 rounded m-0 mb-2 ${list && 'd-none'}`}>{showOptions && <div>{options()}</div>}</div>
                    {
                        lead ? null : (
                            <div
                                className="m-0 p-0"
                                style={{ maxHeight: "100vh", overflowX: "hidden", overflowY: "scroll", scrollBehavior: "smooth", display: hide && 'none' }}>
                                <Activity title="Recent Activities" showActivity={showActivity} setShowActivity={setShowActivity} details={details} />
                            </div>
                        )
                    }
                </Row>
            ) : (
                <Row sm={1} className="mx-2 w-100" style={{ minHeight: "100vh", width: "2rem" }}>
                    <div className="activity-bar">
                        <Tooltip title={hide ? 'Options' : 'Activities'} key="activities">
                            <Link to="#">
                                <BsFillArrowLeftCircleFill className={path ? 'text-primary fs-2' : 'text-white fs-2'}
                                    onClick={() => setShowSidebar(!showSidebar)} style={{ cursor: "pointer" }} />
                            </Link>
                        </Tooltip>
                    </div>
                    <nav className={showSidebar ? "activity-menu active" : "activity-menu"} style={{ minHeight: "100vh" }}>
                        <div className="activity-menu-items">
                            <div className="activitybar-toggle h-1">
                                <Tooltip title={hide ? 'Close Options' : 'Close Activities'} key="clsActivity">
                                    <Link to="#">
                                        <BsFillArrowRightCircleFill className="text-primary fs-2" onClick={() => setShowSidebar(!showSidebar)} style={{ cursor: "pointer" }} />
                                    </Link>
                                </Tooltip>
                            </div>
                            <div style={{ width: "50%" }}>
                                <div className="w-75 bg-white py-2 rounded m-0 mb-2 me-5"
                                    style={{ display: list && "none", boxShadow: "3px 3px 10px #00000030", borderTop: "1px solid #c8c9ca", borderLeft: "1px solid #c8c9ca" }}
                                >
                                    {/* <Row className="mx-2" onClick={() => setShowOptions(!showOptions)}>
                                        <Col sm={9}><h4 className="text-primary">Options</h4></Col>
                                        <Col sm={1}>
                                            {showOptions === false ? (
                                                <BsChevronDown className="fs-4" onClick={() => setShowOptions(true)} style={{ cursor: "pointer" }} />
                                            ) : (
                                                <BsChevronUp className="fs-4" onClick={() => setShowOptions(false)} style={{ cursor: "pointer" }} />
                                            )}
                                        </Col>
                                    </Row> */}
                                    {showOptions === true && <div>{options()}</div>}
                                </div>
                                {
                                    lead ? null : (
                                        <Col sm={12} className="mb-3 bg-white rounded"
                                            style={{ position: "sticky", boxShadow: "3px 3px 10px #00000030", width: screenWidth < breakPoint && "75%", display: hide && 'none' }}
                                        >
                                            <Row className="p-3">
                                                <Col sm={9} onClick={() => setShowActivity(!showActivity)}>
                                                    <h5 className="text-primary" style={{ fontSize: "1", fontWeight: "550" }}>Recent Activities</h5>
                                                </Col>
                                                <Col sm={1}>
                                                    {showActivity === false ? (
                                                        <BsChevronDown className="fs-4 fw-bold" style={{ cursor: "pointer" }} onClick={() => setShowActivity(true)} />
                                                    ) : (
                                                        <BsChevronUp className="fs-4 fw-bold" style={{ cursor: "pointer" }} onClick={() => setShowActivity(false)} />
                                                    )}
                                                </Col>
                                            </Row>
                                        </Col>
                                    )
                                }
                                {/* <div style={{ maxHeight: "100vh", overflowX: "hidden", overflowY: "scroll", scrollBehavior: "smooth", display: hide && 'none'}}>
                  <Activity title="Recent Activities" showActivity={showActivity} setShowActivity={setShowActivity}/>
                </div> */}
                            </div>
                        </div>
                    </nav>
                </Row>
            )}
        </div>
    );
};

export default LeadActivityBar;
