import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { ErrorToast, ProcessToast, SuccessToast, WarningToast } from '../../components/toast';
import { MdClear } from 'react-icons/md';
import { updateInvoice } from '../Hook/invoiceHooks';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useFullPageLoader from '../Hook/useFullPageLoader';

const SetReminderModal = ({ showAddReminder, setShowAddReminder, getInvoiceData, form, setForm }) => {

    const [disabledBtn, setDisabledBtn] = useState(false);
    const [reminder, setReminder] = useState('');
    // const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [loader, showLoader, hideLoader] = useFullPageLoader();

    let defaultDate = new Date()
    defaultDate.setDate(defaultDate.getDate())

    const [date, setDate] = useState(defaultDate)

    const { id } = useParams();
    const location = useLocation();
    const orgName = localStorage.getItem('orgName');

    const allReminders = [
        'test reminder 1',
        'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis, inventore Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis, inventore Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis, inventoreLorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis, inventore.',
        'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis, inventore.',
        'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis, inventore.',
        'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis, inventore.',
        'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis, inventore.',
        'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis, inventore.',
        'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis, inventore.',
        'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis, inventore.',

    ];

    const handleAddReminder = (e) => {
        e.preventDefault();
        setForm({
            ...form,
            reminder: {
                desc: reminder,
                date: date,
                time: time
            }
        });
        setShowAddReminder(false);
    };

    const handleUpdateReminder = (e) => {
        e.preventDefault();
        setDisabledBtn(true);
        showLoader();
        const form = {
            reminder: reminder,
            date: date,
            time: time
        }
        !disabledBtn ? updateInvoice(id, form).then((response) => {
            if (response.status === 201) {
                setDisabledBtn(false);
                SuccessToast('Reminder added successfully');
                getInvoiceData();
                setShowAddReminder(false);
                setReminder('');
                hideLoader();
            } else {
                setDisabledBtn(false);
                ErrorToast('Unable to add reminder');
            }
        }).catch(() => {
            setDisabledBtn(false);
            ErrorToast("Something went wrong");
        }) : ProcessToast('Wait while adding reminder . . . . ')
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (location.pathname === `/${orgName}/dashboard/invoice/add-invoice`) {
            setForm({
                ...form,
                reminder: {
                    desc: reminder,
                    date: date,
                    time: time
                }
            });
            setShowAddReminder(false);
        } else {
            setDisabledBtn(true);
            showLoader();
            const form = {
                reminder: reminder,
                date: date,
                time: time
            }
            !disabledBtn ? updateInvoice(id, form).then((response) => {
                if (response.status === 201) {
                    setDisabledBtn(false);
                    SuccessToast('Reminder added successfully');
                    getInvoiceData();
                    setShowAddReminder(false);
                    setReminder('');
                    hideLoader();
                } else {
                    setDisabledBtn(false);
                    ErrorToast('Unable to add reminder');
                    hideLoader();
                }
            }).catch((err) => {
                err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast("Something went wrong");
                setDisabledBtn(false);
                hideLoader();
            }) : ProcessToast('Wait while adding reminder . . . . ')
        }
    };


    return (
        <Modal centered size="lg" show={showAddReminder} onHide={() => setShowAddReminder(false)}>
            <Modal.Header className="bg-primary">
                <Col sm={11} className="text-white">
                    <h4 className="text-white">Reminders</h4>
                </Col>
                <Col sm={1} className="text-end text-white">
                    <MdClear className="fs-4" onClick={() => setShowAddReminder(false)} style={{ cursor: "pointer" }} />
                </Col>
            </Modal.Header>
            <Modal.Body className="my-1">
                <Form onSubmit={handleSubmit}>
                    <Row className='w-100'>
                        <Col sm={9}>
                            <Form.Control as='textarea' name="reminder" cols={3} rows={4} placeholder="Reminder" className="border border-primary"
                                value={reminder} onChange={(e) => setReminder(e.target.value)} required
                            />
                        </Col>
                        <Col sm={3} className='m-0 p-0'>
                            <Form.Control type='date' name='date' value={date} onChange={(e) => setDate(e.target.value)} className='w-100 m-0 d-block border border-primary' />
                            <Form.Control type='time' name='time' value={time} onChange={(e) => setTime(e.target.value)} className='w-100 m-0 d-block border border-primary mt-3' />
                        </Col>
                    </Row>
                    <div className="d-flex justify-content-center align-items-center pe-3">
                        <Button type='submit' variant={disabledBtn ? 'secondary' : 'primary'} className="mt-3 fs-5 w-100"
                            style={{ cursor: disabledBtn ? 'not-allowed' : 'pointer' }}
                        >Add</Button>
                    </div>
                </Form>
                {
                    location.pathname !== `/${orgName}/dashboard/invoice/add-invoice` ? (
                        <div className='m-0 p-0 mt-3'>
                            <hr />
                            <div style={{ maxHeight: "16rem", overflowY: "scroll", scrollBehavior: 'smooth', position: 'relative' }}>
                                {
                                    allReminders.length > 0 ? (
                                        <>
                                            <ul>
                                                {
                                                    allReminders.map((data, index) => (
                                                        <li key={index} className="pt-3 pb-1" style={{ borderBottom: "1px solid #c8c9ca" }}>{data}</li>
                                                    ))
                                                }
                                            </ul>
                                        </>
                                    ) : (
                                        <div className="my-4 text-secondary text-center fs-4">No Reminders Found</div>
                                    )
                                }
                            </div>
                        </div>
                    ) : null
                }
            </Modal.Body>
            {loader}
        </Modal>
    )
}

export default SetReminderModal
