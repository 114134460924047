import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { MdClear, MdRemoveCircle } from "react-icons/md";
import { API } from "../../../../backend";
import { ErrorToast, SuccessToast, WarningToast } from "../../components/toast";
import { addFirmTax, addGlobalTax } from "../Hook/taxHooks";

const TaxModal = ({
  getAllTaxes, setFirmTaxes, getGlobalTaxes, setAllGlobalTaxes, getSingleFirm, globaltaxes, setDraft, saveDraft, firmId, newState, setNewState,
  taxes, addedTax, setAddedtax, showTax, setShowTax, showDraft, setShowDraft, draft, handleSaveDraft, calculateTax, setAllSelectedTaxes
}) => {
  const [showAlltaxes, setShowAllTaxes] = useState(false);
  const [gstin, setGstin] = useState("");
  const [showNewTaxes, setShowNewTaxes] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [taxType, setTaxType] = useState("gst");
  const [addTaxType, setAddTaxType] = useState("gst");
  const [allTaxes, setAllTaxes] = useState([]);
  const [customTaxes, setcustomTaxes] = useState({});
  const [taxIndex, setTaxIndex] = useState();
  // const [firmtaxes, setFirmTaxes] = useState([]);
  const [testObj, setTestObj] = useState({});
  const [selected, setSelected] = useState([]);
  const [newtaxesType, setNewTaxesType] = useState('Global');
  const [selectedValues, setSelectedValues] = useState([]);

  const [itemTaxes, setItemTaxes] = useState([]);
  const [selectedItemTaxes, setSelectedItemTaxes] = useState([]);

  // useEffect(() => {
  //   selectedValues.push(addedTax)
  // }, [])
  const [testarr, settestarr] = useState([]);

  const handleCheckboxChange = (newValues) => {
    setSelectedValues(newValues);
    settestarr(newValues);
  };


  const handleItemTaxCheckboxChange = (newValues) => {
    setSelectedItemTaxes(newValues);
  };

  const [customRate, setCustomRate] = useState();
  const [customTax, setCustomTax] = useState();
  const orgId = localStorage.getItem("orgID");

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const handletaxChange = (e) => {
    setcustomTaxes({
      ...customTaxes,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange = (value) => {
    const currentIndex = selectedValues.indexOf(value);
    const newValues = [...selectedValues];
    if (currentIndex === -1) {
      newValues.push(value);
    } else {
      newValues.splice(currentIndex, 1);
    }
    handleCheckboxChange(newValues);
  }

  const handleTestCheckBoxChange = (value) => {
    settestarr(value);
  }

  const handleOnItemTaxChange = (value) => {
    const currentIndex = selectedItemTaxes.indexOf(value);
    const mainTaxIndex = testarr.indexOf(value);

    const newItemValues = [...selectedItemTaxes];
    const testArrValues = [...testarr];

    if (currentIndex === -1) {
      newItemValues.push(value);
    } else {
      newItemValues.splice(currentIndex, 1);
    }

    if (mainTaxIndex === -1) {
      testArrValues.push(value);
    } else {
      testArrValues.splice(mainTaxIndex, 1);
    }

    const testnewvalue = testarr.filter((data, index) => (data != value))
    settestarr(testnewvalue);
    // handleCheckboxChange(newValues);
    handleItemTaxCheckboxChange(newItemValues);
    handleTestCheckBoxChange(testArrValues);
  }

  const addSelectedTaxes = () => {
    setAddedtax(testarr);
    setShowTax(false);
    setAllSelectedTaxes(selectedValues);
    // calculateTax();
  }

  const handleChangeGst = (e) => {
    setCustomTax(e.target.name)
    setCustomRate(e.target.value);
  }

  const handleSubmit = (e) => {
    setAllTaxes([...allTaxes, customTaxes]);
  };

  const removeTax = (index) => {
    setAllTaxes(allTaxes.filter((data, i) => index !== i));
  };

  const [addedTaxes, setAddedTaxes] = useState([{}]);

  useEffect(() => {
    setNewState([...newState, addedTax]);
  }, []);

  const removeAddedtax = (index) => {
    setAddedTaxes(addedTaxes.filter((data, i) => i !== index));
  }

  const handleShowNewTaxes = () => {
    setShowTax(false);
    setShowNewTaxes(true);
  }

  const handleCloseNewTaxes = () => {
    setShowNewTaxes(false);
    setShowTax(true);
  }

  const getFirmTaxes = () => {
    getAllTaxes(firmId).then((response) => {
      if (response.status === 200) {
        response.data.data.length > 0 ? setFirmTaxes(response.data.data[0].taxRates) : setFirmTaxes([]);
      }
      else {
        setFirmTaxes([]);
      }
    }).catch(() => {
      setFirmTaxes([]);
    });
  }

  const handleAdd = () => {
    const form = {
      firmId: firmId,
      taxRates: [{ [customTax]: customRate }],
      orgId: orgId,
      globalTax: false
    }
    addFirmTax(form).then((response) => {
      if (response.status === 201) {
        SuccessToast("Tax added")
        setShowNewTaxes(false);
        getFirmTaxes();
        // setFirmTaxes(response.data.data[0].taxRates);
        setShowTax(true);
      } else {
        ErrorToast("Unable to add tax");
      }
    }).catch((err) => {
      err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast("Something went wrong");
    });
  };

  const getAllGlobalTaxes = () => {
    getGlobalTaxes().then((response) => {
      if (response.status === 200) response.data.data.length > 0 ? setAllGlobalTaxes(response.data.data[0].taxRates) : setAllGlobalTaxes([])
      else setAllGlobalTaxes([]);
    });
  }

  const handleGlobalAdd = () => {

    const form = {
      taxRates: [{ [customTax]: customRate }],
      orgId: orgId,
      globalTax: true
    };

    addGlobalTax(form).then((response) => {
      if (response.status === 201) {
        SuccessToast("Tax added")
        setShowNewTaxes(false);
        getAllGlobalTaxes();
        setShowTax(true);
      } else {
        ErrorToast('Unable to add tax');
      }
    }).catch((err) => {
      err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast("Something went wrong");
    });
  };

  const handleCloseDraft = () => {
    setDraft(false);
    setShowDraft(false);
  }

  return (
    <>
      <Modal centered size="lg" show={showTax} onHide={() => setShowTax(false)} animation={false}>
        <Modal.Header className="bg-primary">
          <Col sm={11} className="text-white">
            <h4 className="text-white">Taxes</h4>
          </Col>
          <Col sm={1} className="text-end text-white">
            <MdClear className="fs-4" onClick={() => setShowTax(false)} style={{ cursor: "pointer" }} />
          </Col>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={6}>
              <div className="my-2 w-100 py-2">
                <h4 className="text-primary mb-2">Firm Taxes</h4>
                <table className="w-100">
                  <thead className="bg-primary text-white">
                    <th className="ps-2 py-2 w-25">Select</th>
                    <th>Name</th>
                    <th>Rate(%)</th>
                    <th>On Item</th>
                  </thead>
                  <tbody>
                    {taxes.map((data, i) => (
                      <tr style={{ borderBottom: "1px solid #c8c9ca" }} key={i}>
                        <td className="w-25 ps-2 py-2">
                          <Form.Check.Input type="checkbox" name={Object.keys(data)} value={Object.values(data)}
                            onChange={() => handleChange(data)}
                            checked={selectedValues.includes(data)}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                        <td>{Object.keys(data)}</td>
                        {/* <td>{Object.values(data)}</td> */}
                        <td>{Object.values(data)}</td>
                        <td>
                          <Form.Check.Input type="checkbox" name={Object.keys(data)} value={Object.values(data)}
                            onChange={() => handleOnItemTaxChange(data)}
                            checked={selectedItemTaxes.includes(data)}
                          />
                        </td>
                      </tr>
                    )
                    )}
                  </tbody>
                </table>
                {
                  taxes.length === 0 && (
                    <div className='mt-5 fs-4 text-center text-secondary'>
                      No Firm Taxes Found
                    </div>
                  )
                }
              </div>
            </Col>
            <Col sm={6} style={{ borderLeft: "1px solid #c8c9ca" }}>
              <div className="my-2 w-100 py-2">
                <h4 className="text-primary mb-2">Global Taxes</h4>
                <div style={{ maxHeight: "50vh", overflowY: "scroll", position: "relative" }}>
                  <table className="w-100">
                    <thead className="bg-primary text-white" style={{ position: "sticky", top: "0" }}>
                      <th className="ps-2 py-2 w-25">Select</th>
                      <th>Name</th>
                      <th>Rate(%)</th>
                      <th>On Item</th>
                    </thead>
                    <tbody>
                      {globaltaxes.map((data, i) => (
                        <tr style={{ borderBottom: "1px solid #c8c9ca" }} key={i}>
                          <td className="w-25 ps-2 py-2">
                            <Form.Check.Input type="checkbox" name={Object.keys(data)} value={Object.values(data)}
                              onChange={() => handleChange(data)}
                              checked={selectedValues.includes(data)}
                              style={{ cursor: "pointer" }}
                            // checked={() => {
                            //   addedTax.filter((tax, index) => index === i)
                            // }} 
                            />
                          </td>
                          <td>{Object.keys(data)}</td>
                          {/* <td>{Object.values(data)}</td> */}
                          <td>{Object.values(data)}</td>
                          <td>
                            <Form.Check.Input type="checkbox" name={Object.keys(data)} value={Object.values(data)}
                              onChange={() => handleOnItemTaxChange(data)}
                              checked={selectedItemTaxes.includes(data)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {
                    globaltaxes.length === 0 && (
                      <div className='mt-5 fs-4 text-center text-secondary'>
                        No Global Taxes Found
                      </div>
                    )
                  }
                </div>
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col sm={6}>
              <span className="py-2 px-3 fs-5 bg-primary text-white" onClick={() => handleShowNewTaxes()} style={{ cursor: "pointer", borderRadius: "5px" }}>
                Add new taxes
              </span>
            </Col>
            <Col sm={6} className="d-flex" style={{ justifyContent: "flex-end" }}>
              <span className="py-2 px-3 fs-5 bg-primary text-white" onClick={() => addSelectedTaxes()} style={{ cursor: "pointer", borderRadius: "5px" }}>
                Add Selected Taxes
              </span>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal centered size="lg" show={showNewTaxes} onHide={() => setShowNewTaxes()} animation={false}>
        <Modal.Header className="bg-primary">
          <Col sm={11} className="text-white">
            <h4 className="text-white">Add new taxes</h4>
          </Col>
          <Col sm={1} className="text-end text-white">
            <MdClear className="fs-4" onClick={() => setShowNewTaxes(false)} style={{ cursor: "pointer" }} />
          </Col>
        </Modal.Header>
        <Modal.Body>
          <div className="w-100">
            <span
              className={`${newtaxesType === "Global"
                ? "bg-primary text-white"
                : "bg-white text-primary"
                } border border-primary ms-0 rounded py-2 px-3`}
              onClick={() => setNewTaxesType("Global")}
              style={{ cursor: "pointer" }}
            >
              Global Tax
            </span>
            <span
              className={`${newtaxesType === "Firm" ? "bg-primary text-white" : "bg-white text-primary"} border border-primary rounded py-2 px-3`}
              onClick={() => setNewTaxesType("Firm")} style={{ cursor: "pointer" }}
            >
              Firm Tax
            </span>
          </div>
          <hr />
          <div className="w-100 mt-4">
            <span
              className={`${addTaxType === "gst"
                ? "bg-primary text-white"
                : "bg-white text-primary"
                } border border-primary ms-0 rounded py-2 px-3`}
              onClick={() => setAddTaxType("gst")}
              style={{ cursor: "pointer" }}
            >
              GST
            </span>
            <span
              className={`${addTaxType === "custom" ? "bg-primary text-white" : "bg-white text-primary"} border border-primary rounded py-2 px-3`}
              onClick={() => setAddTaxType("custom")} style={{ cursor: "pointer" }}
            >
              Custom Tax
            </span>
          </div>
          {addTaxType === "gst" ? (
            <div
              className="my-3 py-2"
              style={{ borderTop: "1px solid #c8c9ca", borderBottom: "1px solid #c8c9ca" }} >
              {/* <Row className="mb-3"> */}
              {/* <Col sm={2}>
                  <span className="fs-5 ms-3 mt-2 fw-semibold">GSTIN: </span>
                </Col> */}
              {/* <Col className="ms-2">
                  <input onChange={(e) => setGstin(e.target.value)} type="text" name="gstn" className="ps-2" placeholder="GSTIN "
                    style={{ width: "65%", border: "1px solid #c8c9ca", borderRadius: "5px" }}
                  />
                </Col> */}
              {/* </Row> */}
              <Row className="w-100 my-2 mx-2">
                <Col sm={4} className="py-2 px-2 mx-2" style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                  <span className="m-0 me-2 p-0">GST: </span>
                  <input type="number" name="GST" onChange={handleChangeGst} className="w-50 ps-2" placeholder="%" style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }} />
                </Col>
                {/* <Col sm={4} className="py-2 px-2 mx-2" style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                  <span className="m-0 me-2 p-0">CGST: </span>
                  <input type="number" name="CGST" onChange={handleChangeGst} className="w-50 ps-2" placeholder="%" style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }} />
                </Col>
                <Col sm={4} className="mt-2 py-2 px-2 mx-2" style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                  <span className="m-0 me-2 p-0">SGST: </span>
                  <input type="number" name="SGST" onChange={handleChangeGst} className="w-50 ps-2" placeholder="%" style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }} />
                </Col>
                <Col sm={4} className="mt-2 py-2 px-2 mx-2" style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                  <span className="m-0 me-2 p-0">IGST: </span>
                  <input type="number" name="IGST" onChange={handleChangeGst} className="w-50 ps-2" placeholder="%" style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }} />
                </Col> */}
              </Row>
              {/* <Row>
                <span className="text-dark" style={{ fontSize: "0.8rem" }}>* Please Fill atleast one field</span>
              </Row> */}
            </div>
          ) : (
            <div
              className="my-3 py-2"
              style={{ maxHeight: "50vh", overflowY: "scroll", overflowX: "hidden", borderTop: "1px solid #c8c9ca", borderBottom: "1px solid #c8c9ca" }}
            >
              {/* <span className="fs-5 ms-3 mt-2 fw-semibold">Custom Tax: </span> */}
              <Row className="w-100 mx-2">
                <Col sm={6} className="m-0 p-0 ps-2 fw-semibold">
                  Name
                </Col>
                <Col sm={3} className="text-center fw-semibold">
                  Rate (%)
                </Col>
              </Row>
              <form>
                <Row className="w-100 my-2 mx-2">
                  <Col sm={6} className=" px-2 mx-2" style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                    <input type="text" className="w-100 my-2 ps-2" placeholder="Name" onChange={(e) => setCustomTax(e.target.value)} required autoComplete="off"
                      style={{ border: "none", borderBottom: "1px solid #c8c9ca", }}
                    />
                  </Col>
                  <Col sm={2} className=" px-2 mx-2" style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                    <input type="number" className="w-100 my-2 ps-2" name="rate" placeholder="%" onChange={(e) => setCustomRate(e.target.value)} required
                      style={{ border: "none", borderBottom: "1px solid #c8c9ca", }}
                    />
                  </Col>
                  {/* <Col sm={1}>
                    <MdAddCircle
                      className="fs-3 mt-2 text-center text-primary"
                      onClick={() => { customTaxes.taxName.length > 0 && customTaxes.rate.length > 0 && handleSubmit() }}
                      style={{ cursor: "pointer" }}
                    />
                  </Col> */}
                </Row>
                {allTaxes.map((data, index) => (
                  <Row className="w-100 my-2 mx-2" key={index}>
                    <Col sm={6} className=" px-2 mx-2" style={{ border: "1px solid #c8c9ca", borderRadius: "5px", }}>
                      <input type="text" className="w-100 my-2 ps-2" value={data.taxName} name="taxName" placeholder="Name" onChange={handletaxChange}
                        style={{ border: "none", borderBottom: "1px solid #fff" }}
                      />
                    </Col>
                    <Col sm={2} className=" px-2 mx-2" style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }} >
                      <input type="number" className="w-100 my-2 ps-2" value={data.percent} name="percent" placeholder="%" onChange={handletaxChange}
                        style={{ border: "none", borderBottom: "1px solid #fff" }}
                      />
                    </Col>
                    <Col sm={1}>
                      <MdRemoveCircle onClick={() => removeTax(index)} className="fs-3 mt-2 text-center text-primary" style={{ cursor: "pointer" }} />
                    </Col>
                  </Row>
                ))}
              </form>
            </div>
          )}
          <Row>
            <Col sm={3}>
              <span className="py-2 px-3 fs-5 bg-primary text-white" onClick={() => {
                newtaxesType === 'Global' ? handleGlobalAdd() : handleAdd()
              }} style={{ cursor: "pointer", borderRadius: "5px" }}>Add</span>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal centered show={showDraft} onHide={() => handleCloseDraft()}>
        <Modal.Body className="my-2">
          <Row className="mb-2 ms-1">
            <Col sm={12} className=" text-center px-2 m-0 p-0 fs-3">Save As Draft</Col>
            <Col sm={12} className="text-secondary text-center px-2 m-0 p-0">Are you sure you want to save ?</Col>
          </Row>
          <div className="ms-2 w-100" style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }} >
            <div
              className="bg-white py-1 px-5 me-2 bg-primary text-center text-white border border-primary"
              style={{ borderRadius: "4px", cursor: "pointer" }} onClickCapture={handleSaveDraft} onClick={saveDraft}
            >
              Save
            </div>
            <div className="bg-white py-1 px-5 border border-primary text-center" style={{ borderRadius: "5px", cursor: "pointer" }} onClick={() => handleCloseDraft()}>
              Cancel
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TaxModal;
