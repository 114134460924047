import React, { useEffect, useState } from "react";
import { Row, Col, Image, Form, Button, ListGroup } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Card from "../../../components/Card";
import { API } from "../../../backend";
import { toast } from 'react-hot-toast';
// img
// import facebook    from     '../../../assets/images/brands/fb.svg'
// import google    from     '../../../assets/images/brands/gm.svg'
// import instagram    from     '../../../assets/images/brands/im.svg'
// import linkedin  from     '../../../assets/images/brands/li.svg';
import auth1 from "../../../assets/images/auth/01.png";
import auth5 from "../../../assets/images/auth/05.png";
import { createUser } from "../app/Hook/userHooks";
import { ErrorToast, SuccessToast } from "../components/toast";
import { cubicle2 } from "../../../assets/images";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const SignUp = () => {
  const [form, setForm] = useState({});
  const [inputType, setInputType] = useState("password");
  const [disable, setDisable] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const location = useHistory();

  const orgId = localStorage.getItem('orgID');

  useEffect(() => {
    if (orgId !== undefined && orgId !== null && orgId.length > 0) {
      setForm({
        ...form,
        orgId
      })
    }
  }, [orgId]);

  const handleChange = (e) => {
    e.preventDefault();
    setForm({
      ...form,
      [e.target.name]: e.target.value,
      role: "User",
      // permissions: [
      //   {
      //     module: "firm",
      //     action: ["Create", "Read", "Update", "Delete"]
      //   },
      //   {
      //     module: "client",
      //     action: ["Create", "Read", "Update", "Delete"]
      //   },
      //   {
      //     module: "tax",
      //     action: ["Create", "Read", "Update", "Delete"]
      //   },
      //   {
      //     module: "invoice",
      //     action: ["Create", "Read", "Update", "Delete"]
      //   },
      //   {
      //     module: "lead",
      //     action: ["Create", "Read", "Update", "Delete"]
      //   },
      //   {
      //     module: "purchase",
      //     action: ["Create", "Read", "Update", "Delete"]
      //   },
      //   {
      //     module: "product",
      //     action: ["Create", "Read", "Update", "Delete"]
      //   }
      // ]
    });
  };

  console.log("form", form);

  const register = (e) => {
    e.preventDefault();
    setDisable(true);
    createUser(form).then((response) => {
      if (response.status === 201) {
        const res = response.data;
        localStorage.setItem("AdminEmail", res.email);
        SuccessToast("Registered successfully");
        setDisable(false);
        location.push('/admin-register')
      } else {
        ErrorToast("Unable to register");
      }
    }).catch((err) => {
      ErrorToast(err.response.data.message);
      setDisable(false);
    })
  };

  return (
    <>
      <section className="login-content">
        <Row className="m-0 align-items-center bg-white vh-100">
          <Col md={6} className="d-flex justify-content-center align-items-center p-0 mt-n1 vh-100 overflow-hidden" >
            <div className="row ">
              <div className="col-xl-12 col-lg-8 pt-5">
                <div className="d-flex justfy-content-center align-items-center orbit" style={{ marginLeft: "30vw" }}>
                  <div className="scrollme start-or wow zoomIn" data-wow-delay=".2s">
                    <div className=" animateme" data-when="exit" data-from="0" data-to=".9" data-scale="0" data-opacity="0">
                      <div className='banner-orbit-main-container '>
                        <div className="main-orbit">
                          {/* <span className="sm-circle"></span> */}
                          <img src={cubicle2} alt="logo" style={{ width: "30rem", height: "30rem" }} />
                          {/* <span className="mid-circle"></span> */}
                          {/* <span className="outer-circle"></span> */}
                          <div className="outer_text scrollme clock-spin">
                            <span className="scrollme clock-spin">
                              <span className="circle_child animateme" data-when="exit" data-from="0" data-to=".4" data-scale="0" data-opacity="0"></span>
                            </span>
                            <span className="scrollme">
                              <span className="circle_child animateme" data-when="exit" data-from="0" data-to=".4" data-scale=".6" data-opacity="0"></span></span>
                            <span className="scrollme">
                              <span className="circle_child animateme" data-when="exit" data-from="0" data-to=".4" data-scale=".6" data-opacity="0"></span>
                            </span>
                            <span className="scrollme">
                              <span className="circle_child animateme" data-when="exit" data-from="0" data-to=".4" data-scale=".6" data-opacity="0"></span>
                            </span>
                            <span className="scrollme">
                              <span className="circle_child animateme" data-when="exit" data-from="0" data-to=".4" data-scale=".6" data-opacity="0"></span>
                            </span>
                            <span className="scrollme">
                              <span className="circle_child animateme" data-when="exit" data-from="0" data-to=".4" data-scale=".6" data-opacity="0"></span>
                            </span>
                            <span className="scrollme">
                              <span className="circle_child animateme" data-when="exit" data-from="0" data-to=".4" data-scale=".6" data-opacity="0"></span>
                            </span>
                            <span className="scrollme">
                              <span className="circle_child animateme" data-when="exit" data-from="0" data-to=".4" data-scale=".6" data-opacity="0"></span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} className="d-flex justify-content-center align-items-center p-0 vh-100" style={{ position: "relative" }}>
            <Image src={auth1} className="Image-fuild gradient-main" alt="images" />
            <Row className="justify-content-center" style={{ position: "absolute" }}>
              <Col md="10">
                <Card>
                  <Card.Body>
                    <h1 className="text-center fw-bold" style={{ fontSize: "3rem" }}>Cubicle</h1>
                  </Card.Body>
                </Card>
                <Card className="card-transparent shadow-none d-flex justify-content-center mb-0 auth-card">
                  <Card.Body>
                    <div className="navbar-brand d-flex justify-content-center align-items-center mb-3">
                    </div>
                    <h2 className="mb-2 text-center">Sign Up</h2>
                    <p className="text-center">Create your account.</p>
                    <form onSubmit={register} method="POST">
                      <Row>
                        <Col lg="6">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="firstName">First Name</Form.Label>
                            <Form.Control type="firstName" className="border border-primary" id="firstName" aria-describedby="firstName" name="firstName"
                              onChange={handleChange} placeholder="Enter first name" required
                            />
                          </Form.Group>
                        </Col>

                        <Col lg="6">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="lastName">Last Name</Form.Label>
                            <Form.Control type="lastName" className="border border-primary" id="lastName" aria-describedby="lastName" name="lastName"
                              onChange={handleChange} placeholder="Enter Last name"
                            />
                          </Form.Group>
                        </Col>

                        <Col lg="12">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="email">Email</Form.Label>
                            <Form.Control type="email" className="border border-primary" id="email" aria-describedby="email" name="email"
                              onChange={handleChange} placeholder="Enter email"
                            />
                          </Form.Group>
                        </Col>

                        {/* <Col lg="6">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="phone">Phone No.</Form.Label>
                            <Form.Control type="phone" className="border border-primary" id="phone" aria-describedby="phone" name="phone"
                              onChange={handleChange} placeholder="Enter phone no."
                            />
                          </Form.Group>
                        </Col> */}
                        <Col lg="6" style={{ position: "relative" }}>
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="password">Password</Form.Label>
                            <Form.Control type={inputType} className="border border-primary" id="password" aria-describedby="password" name="password"
                              onChange={handleChange} placeholder="Enter password" required
                            />
                          </Form.Group>
                          {
                            inputType === "password" ? (
                              <AiFillEyeInvisible className="fs-4 text-primary" onClick={() => setInputType("text")} style={{ display: !form.password && "none", cursor: "pointer", position: "absolute", top: 35, right: 30 }} />
                            ) : (
                              <AiFillEye className="fs-4 text-primary" onClick={() => setInputType("password")} style={{ cursor: "pointer", position: "absolute", top: 35, right: 30 }} />
                            )
                          }
                        </Col>

                        <Col lg="6" style={{ position: "relative" }}>
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="confirmPassword">Confirm Password</Form.Label>
                            <Form.Control type={showConfirm ? 'text' : 'password'} className="border border-primary" id="confirmPassword" aria-describedby="confirmPassword" name="confirmPassword"
                              onChange={handleChange} placeholder="Confirm password" required
                            />
                          </Form.Group>
                          {
                            !showConfirm ? (
                              <AiFillEyeInvisible className="fs-4 text-primary" onClick={() => setShowConfirm(true)} style={{ display: !form.confirmPassword && "none", cursor: "pointer", position: "absolute", top: 35, right: 30 }} />
                            ) : (
                              <AiFillEye className="fs-4 text-primary" onClick={() => setShowConfirm(false)} style={{ cursor: "pointer", position: "absolute", top: 35, right: 30 }} />
                            )
                          }
                        </Col>

                        {/* <Col lg="12" className="d-flex justify-content-center">
                          <Form.Check className="mb-3 form-check">
                            <Form.Check.Input
                              type="checkbox"
                              id="customCheck1"
                            />
                            <Form.Check.Label htmlFor="customCheck1">
                              I agree with the terms of use
                            </Form.Check.Label>
                          </Form.Check>
                        </Col> */}
                        {/* <Col
                              lg="12"
                              className="d-flex my-3 justify-content-between"
                      login-content      >
                              <Form.Check className="form-check mb-3">
                                            <Form.Check.Input type="checkbox"  id="customCheck1"/>
                                            <Form.Check.Label htmlFor="customCheck1">Remember Me</Form.Check.Label>
                                        </Form.Check>
                              <Link to="/auth/forgot">Forgot Password?</Link>
                            </Col> */}
                      </Row>
                      <div className="d-flex justify-content-center" style={{ cursor: disable === true ? 'not-allowed' : 'pointer' }}>
                        <Button disabled={disable === true} type="submit" variant="btn btn-primary">Sign Up</Button>
                      </div>
                      {/* <p className="text-center my-3">or sign in with other accounts?</p>
                                  <div className="d-flex justify-content-center">
                                      <ListGroup as="ul" className="list-group-horizontal list-group-flush">
                                        <ListGroup.Item as="li"  className="border-0 pb-0">
                                            <Link to="#"><Image src={facebook} alt="fb"/></Link>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li"  className="border-0 pb-0">
                                            <Link to="#"><Image src={google} alt="gm"/></Link>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li"  className="border-0 pb-0">
                                            <Link to="#"><Image src={instagram} alt="im"/></Link>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li"  className="border-0 pb-0">
                                            <Link to="#"><Image src={linkedin} alt="li"/></Link>
                                        </ListGroup.Item>
                                      </ListGroup>
                                  </div> */}
                      <p className="mt-3 text-center">
                        Already have an Account{" "}
                        <Link to="/" className="text-underline">
                          Sign In
                        </Link>
                      </p>
                    </form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default SignUp;
