import React from "react";
import topHeader from "../../../../assets/images/dashboard/top-header.png";
import { Row, Col, Container, Form } from "react-bootstrap";
import { BsDownload } from "react-icons/bs";
import "./payroll.css";
import { Link } from "react-router-dom";
import SubHeader from "../../../../components/partials/dashboard/HeaderStyle/sub-header";

const Form16 = () => {
  return (
    <>
    <SubHeader title="Form 16"/>
      <div className="h-100" style={{ minHeight: "90vh" }}>
        <div
          className="d-flex text-dark ms-5"
          style={{ marginTop: "-3%", flexDirection: "row" }}
        >
          <Row
            sm={7}
            className={`h-100 bg-white rounded mx-2 mb-5`}
            style={{
              minWidth: "50%",
              width: "75%",
              maxWidth: "80%",
            }}
          >
            <div
              className="table-responsive mt-2"
              style={{ minHeight: "50vh" }}
            >
              <form>
                <Form.Group></Form.Group>
              </form>
            </div>
          </Row>
          <Row
            sm={2}
            className="ms-2 mb-"
            style={{
              width: "25%",
              height: "100%",
              maxHeight: "90vh",
            }}
          ></Row>
        </div>
      </div>
    </>
  );
};

export default Form16;
