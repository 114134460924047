import React, { useState, useEffect} from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { API } from "../../../../../backend";
import SubHeader from "../../../../../components/partials/dashboard/HeaderStyle/sub-header";
import Activity from "../../../../../components/partials/dashboard/MainStyle/Activity";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { ErrorToast, SuccessToast } from "../../../components/toast";

const Reimbursment = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [formData, setFormData] = useState();
  const [showActivity, setShowActivity] = useState(false);

  const requestReimbursment = async (e) => {
    e.preventDefault();
    await fetch(`${API}/`, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 200) {
          setFormData({
            ...formData,
            [e.target.name]: e.target.value,
          });
          SuccessToast("Request sent successfully");
        } else {
          ErrorToast("Something went wrong");
        }
      })
      .catch((err) => ErrorToast(err.message));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  console.log("form data", formData);

  useEffect(() => {
    const handleResize = setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <>
      <SubHeader title="Claim a Reimbursment" />
      <div>
        <div
          className="d-flex text-dark me-5 mb-5"
          style={{
            marginTop: screenWidth >= 1200 ? "-2%" : "-3%",
            flexDirection: "row",
            maxHeight: "600px",
          }}
        >
          <Col
            sm={9}
            className="bg-white rounded ms-3 py-2"
            style={{ maxHeight: "500px", maxHeight: "500px" }}
          >
            <Col sm={6} style={{margin: "0 25%"}}>
              <h4 className="text-primary fw-bold mb-2">
                Request for a Reimbursment
              </h4>
              <form onSubmit={requestReimbursment}>
                <Form.Label className="mt-2">Type of Reimbursment</Form.Label>
                <Form.Select
                  name="type"
                  className="border border-primary"
                  onChange={handleChange}
                  required
                >
                  <option>Select a type of Reimbursment</option>
                  <option>Travel</option>
                  <option>Hotel & Accomodation</option>
                  <option>Food</option>
                  <option>Medical</option>
                  <option>Telephone</option>
                  <option>Fuel</option>
                  <option>Imprest</option>
                  <option>Other</option>
                </Form.Select>

                <Form.Label className="mt-2">Expense Date</Form.Label>
                <Form.Control
                  type="date"
                  name="date"
                  className="border border-primary"
                  onChange={handleChange}
                  required
                />

                <Form.Label className="mt-2">Amount</Form.Label>
                <Form.Control
                  type="number"
                  name="amount"
                  className="border border-primary"
                  onChange={handleChange}
                  required
                />

                <Form.Label className="mt-2">Details</Form.Label>
                <Form.Control
                  type="text"
                  as="textarea"
                  rows="6"
                  name="details"
                  className="border border-primary"
                  onChange={handleChange}
                />

                <Button type="submit" className="bg-primary mt-3">
                  Request Reimbursment
                </Button>
              </form>
            </Col>
          </Col>
          <Col sm={3} className="mx-3">
            <Link to="/dashboard/app/payroll/reimbursment/all-reimbursments">
              <Col sm={12} className="bg-white rounded m-0 py-2 px-3">
                <h5 className="">View past requests</h5>
                <p className="m-0 p-0">
                  See the status of all your past reimbursment requests
                </p>
              </Col>
            </Link>
            <Col
              sm={12}
              className="mt-2"
              style={{
                maxHeight: "90%",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              <Activity
                title="Recent Activities"
                showActivity={showActivity}
                setShowActivity={setShowActivity}
              />
            </Col>
          </Col>
        </div>
      </div>
    </>
  );
};

export default Reimbursment;
