import { Button, Card, Col, Form, Input, Row, Table, TimePicker, Typography } from "antd";

import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
// import ViewBtn from "../Buttons/ViewBtn";
// import ColVisibilityDropdown from "../Shared/ColVisibilityDropdown";
// import { CsvLinkBtn } from "../UI/CsvLinkBtn";
import { useDispatch, useSelector } from "react-redux";
import {
	addShift,
	loadAllShift,
} from "../../../redux/rtk/features/shift/shiftSlice";
import ViewBtn from "../../../components/Buttons/ViewBtn";
import ColVisibilityDropdown from "../../../components/Shared/ColVisibilityDropdown";
import { CsvLinkBtn } from "../../../components/UI/Button/CsvLinkBtn";
// import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";

function CustomTable({ list }) {
	const [columnsToShow, setColumnsToShow] = useState([]);
	const orgName = localStorage.getItem('orgName');
	const columns = [
		{
			id: 1,
			title: "ID",
			dataIndex: "id",
			key: "id",
		},
		{
			id: 2,
			title: "Name",
			dataIndex: "name",
			key: "name",
		},

		{
			id: 3,
			title: "Start Time",
			dataIndex: "startTime",
			key: "startTime",
			render: (startTime) => dayjs(startTime).format("hh:mm A"),
		},

		{
			id: 4,
			title: "End Time",
			dataIndex: "endTime",
			key: "endTime",
			render: (endTime) => dayjs(endTime).format("hh:mm A"),
		},
		{
			id: 5,
			title: "Action",
			dataIndex: "_id",
			key: "action",
			render: (id) => <ViewBtn path={`/${orgName}/dashboard/shift/${id}`} />,
		},
	];

	useEffect(() => {
		setColumnsToShow(columns);
	}, []);

	const columnsToShowHandler = (val) => {
		setColumnsToShow(val);
	};

	const addKeys = (arr) => arr.map((i) => ({ ...i, key: i.id }));

	return (
		<Card>
			<div className='text-center my-2 d-flex justify-content-between align-items-center'>
				<h5 className='department-list-title text-color-2 text-xl mb-2'>Shift List</h5>
				{list && (
					<div>
						<CsvLinkBtn>
							<CSVLink
								data={list}
								className='btn btn-dark btn-sm mb-1'
								filename='shift'>
								Download CSV
							</CSVLink>
						</CsvLinkBtn>
					</div>
				)}
			</div>

			{list && (
				<div style={{ marginBottom: "30px" }}>
					<ColVisibilityDropdown
						options={columns}
						columns={columns}
						columnsToShowHandler={columnsToShowHandler}
					/>
				</div>
			)}

			<Table
				scroll={{ x: true }}
				loading={!list}
				columns={columnsToShow}
				dataSource={list ? addKeys(list) : []}
			/>
		</Card>
	);
}

const AddShift = ({ drawer }) => {
	const [loader, setLoader] = useState(false);
	const shift = useSelector((state) => state.shift.list);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(loadAllShift());
	}, []);

	const { Title } = Typography;
	const [form] = Form.useForm();

	const onFinish = async (values) => {
		const shiftData = {
			name: values.name,
			startTime: dayjs(values.startTime).format(),
			endTime: dayjs(values.endTime).format(),
		};

		setLoader(true);
		const resp = await dispatch(addShift(values));

		if (resp.payload.message === "success") {
			setLoader(false);
			form.resetFields();
			dispatch(loadAllShift());
		} else {
			setLoader(false);
		}
	};

	const onFinishFailed = (errorInfo) => {
		toast.warning("Failed at adding shift");
		setLoader(false);
	};
	return (
		<div className="mx-4" bordered={false}>
			{/* <UserPrivateComponent permission={"create-shift"}> */}
			<Row className='mr-top' justify={drawer ? "center" : "space-between"}>
				<Col
					xs={24}
					sm={24}
					md={24}
					lg={drawer ? 22 : 16}
					xl={drawer ? 22 : 12}
					className='column-design border rounded card-custom'>
					<Title level={4} className='m-2 mt-5 mb-5 text-center'>
						Add shift
					</Title>
					<Form
						form={form}
						style={{ marginBottom: "40px" }}
						eventKey='shift-form'
						name='basic'
						labelCol={{
							span: 6,
						}}
						wrapperCol={{
							span: 12,
						}}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						autoComplete='off'>
						<div>
							<Form.Item
								style={{ marginBottom: "10px" }}
								label='Name'
								name='name'
								rules={[
									{
										required: true,
										message: "Please input your shift!",
									},
								]}>
								<Input />
							</Form.Item>

							<Form.Item
								style={{ marginBottom: "10px" }}
								label='Start Time'
								name='startTime'
								rules={[
									{
										required: true,
										message: "Please input your shift!",
									},
								]}>
								<TimePicker />
							</Form.Item>

							<Form.Item
								style={{ marginBottom: "20px" }}
								label='End Time'
								name='endTime'
								rules={[
									{
										required: true,
										message: "Please input your shift!",
									},
								]}>
								<TimePicker />
							</Form.Item>

							<Form.Item
								style={{ marginBottom: "10px" }}
								wrapperCol={{
									offset: 6,
									span: 12,
								}}>
								<Button
									onClick={() => setLoader(true)}
									type='primary'
									size='large'
									htmlType='submit'
									block
									loading={loader}>
									Add New Shift
								</Button>
							</Form.Item>
						</div>
					</Form>
				</Col>
			</Row>
			{/* </UserPrivateComponent> */}
			<hr />
			{/* <UserPrivateComponent permission={"readAll-shift"}> */}
			{drawer || <CustomTable list={shift} />}
			{/* </UserPrivateComponent> */}
		</div>
	);
};

export default AddShift;
