import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap'

const ContactAdmin = ({ show, setShow }) => {
    return (
        <Modal show={show} onHide={() => setShow(false)} size='md'>
            <Modal.Body className="my-2">
                <Row className="mb-2 ms-1">
                    <Col sm={12} className="text-danger text-center px-2 m-0 p-0 fs-3">Access Denied</Col>
                    <Col sm={12} className="text-dark text-center px-2 m-0 p-0">
                        <span className='m-0 p-0 text-primary fw-bold'>Contact the Admin </span>
                        to get access of this module
                    </Col>
                </Row>
            </Modal.Body>
        </Modal >
    )
}

export default ContactAdmin
