import React, { useState } from 'react'

const ColoredTabs = ({ menuItems, setSelectedTab }) => {

    const [active, setActive] = useState(1);

    const handleSelectTab = (data, index) => {
        setSelectedTab(data);
        setActive(index);
    };

    return (
        <div className='w-100 border-primary' style={{borderBottom: "3px solid"}}>
            <div className='d-flex justify-content-start align-items-center'>
                {
                    menuItems.map((data, index) => (
                        <div className='m-0 p-0 position-relative' onClick={() => handleSelectTab(data, index)} key={index}>
                            <div className={`${index === active ? 'active-tab' : 'inactive-tab'}  cursor-pointer`}></div>
                            <span className={`fs-5 ${index === active ? 'text-white fw-bold' : 'text-dark'} cursor-pointer`} style={{ position: "absolute", top: 7, left: 20 }}>{data}</span>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default ColoredTabs
