import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { API } from "../../../../../backend";
import toast from "react-hot-toast";
import { MdClear } from "react-icons/md";
import { ErrorToast, SuccessToast } from "../../../components/toast";

const EditReimbursment = ({showEdit, setShowEdit}) => {
  const [formData, setFormData] = useState({});

  const editRequest = async (e) => {
    e.preventDefault();
    await fetch(`${API}/`, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 200) {
          setFormData({
            ...formData,
            [e.target.name]: e.target.value,
          });
          SuccessToast("Request sent successfully");
        } else {
          ErrorToast("Something went wrong");
        }
      })
      .catch((err) => ErrorToast(err.message));
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <Modal centered show={showEdit} onHide={() => setShowEdit(false)}>
        <Modal.Header className="bg-primary">
          <Col sm={11}>
            <h4 className="text-white">Edit Reimbursment Request</h4>
          </Col>
          <Col sm={1} className="text-end">
            <MdClear
              className="fs-4 text-white"
              style={{ cursor: "pointer" }}
              onClick={() => setShowEdit(false)}
            />
          </Col>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={editRequest}>
            <Form.Label className="mt-2">Type of Reimbursment</Form.Label>
            <Form.Select
              name="type"
              className="border border-primary"
              onChange={handleChange}
              required
            >
              <option>Select a type of Reimbursment</option>
              <option>Travel</option>
              <option>Hotel & Accomodation</option>
              <option>Food</option>
              <option>Medical</option>
              <option>Telephone</option>
              <option>Fuel</option>
              <option>Imprest</option>
              <option>Other</option>
            </Form.Select>

            <Form.Label className="mt-2">Expense Date</Form.Label>
            <Form.Control
              type="date"
              name="date"
              className="border border-primary"
              onChange={handleChange}
              required
            />

            <Form.Label className="mt-2">Amount</Form.Label>
            <Form.Control
              type="number"
              name="amount"
              className="border border-primary"
              onChange={handleChange}
              required
            />

            <Form.Label className="mt-2">Details</Form.Label>
            <Form.Control
              type="text"
              as="textarea"
              rows="6"
              name="details"
              className="border border-primary"
              onChange={handleChange}
            />

            <Button type="submit" className="bg-primary mt-3">
              Edit Request
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditReimbursment;
