import React, { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import './styles/invoice.css';
import { Col, Form, Row } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { GrAdd } from 'react-icons/gr';
import { MdAdd } from 'react-icons/md';
import { getAllProducts } from "../Hook/productHooks";
import { ErrorToast } from "../../components/toast";

const InvoiceTable = (props) => {
  const [amount, setAmount] = useState(0);
  const [taxAmt, setTaxAmt] = useState(0);
  const [baseprice, setbaseprice] = useState(0);

  const { onInputChange, handleDeleteRow, items } = props;
  const { id, itemName, desc, unitPrice } = props;
  const [showSelect, setShowSelect] = useState(true);
  const [allSelectedTaxes, setAllSelectedTaxes] = useState([]);
  const [itemIndex, setItemIndex] = useState();
  let all = [];
  const [itemPrice, setItemPrice] = useState(0);
  const [index, setIndex] = useState(0);
  const [pricevalue, setpricevalue] = useState(0);
  const [selectedItem, setSelectedItem] = useState('');
  const [item, setItem] = useState(selectedItem);
  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [taxRate, setTaxRate] = useState(0);
  const [hsn, setHsn] = useState('');
  const [sac, setSac] = useState('');
  const [description, setDescription] = useState('');
  const [discount, setDiscount] = useState(0);

  const grossAmount = props.includeTax === true
    ? (items.quantity * items.unitPrice)
    : (parseFloat(items.quantity * items.unitPrice) + (parseFloat(items.quantity * items.unitPrice) * ((items.taxRate ? parseFloat(items.taxRate) : 0) / 100))).toFixed(2)

  let testAmt = (parseFloat(grossAmount) - (parseFloat(grossAmount) * ((items.discount ? parseFloat(items.discount) : 0) / 100))).toFixed(2);

  const [discountApplied, setDiscountApplied] = useState(0);

  let filteredItemsOptions = props.allProducts.filter((option) =>
    (option.name).toLowerCase().includes(item.toLowerCase())
  );

  useEffect(() => {
    let filteredItem = [];
    filteredItemsOptions.length > 0 ? filteredItem = props.allProducts.filter((data, index) => data.name === filteredItemsOptions[0].name) : filteredItem = [];
    setSelectedItem((filteredItemsOptions.length === 0 || filteredItem[0].name === undefined) ? '' : filteredItem[0].name)
    setPrice(filteredItemsOptions.length === 0 || filteredItem[0].price === undefined ? '' : filteredItem[0].price);
    setTaxRate(filteredItemsOptions.length === 0 || filteredItem[0].tax === undefined || !props.showTaxColumn ? 0 : filteredItem[0].tax);
    setHsn(filteredItemsOptions.length === 0 || filteredItem[0].hsn === undefined ? '' : filteredItem[0].hsn);
    setSac(filteredItemsOptions.length === 0 || filteredItem[0].sac === undefined ? '' : filteredItem[0].sac);
    setDescription(filteredItemsOptions.length === 0 || filteredItem[0].desc === undefined ? '' : filteredItem[0].desc);
    setDiscount(filteredItemsOptions.length === 0 || filteredItem[0].discount === undefined ? 0 : filteredItem[0].discount);
  }, [item]);

  useEffect(() => {
    let filteredItem = [];
    filteredItemsOptions.length > 0 ? filteredItem = props.allProducts.filter((data, index) => data.name === filteredItemsOptions[0].name) : filteredItem = [];
    setTaxRate(filteredItemsOptions.length === 0 || filteredItem[0].tax === undefined || !props.showTaxColumn ? 0 : filteredItem[0].tax);
  }, [props.showTaxColumn]);

  useEffect(() => {
    let filteredItem = [];
    filteredItemsOptions.length > 0 ? filteredItem = props.allProducts.filter((data, index) => data.name === filteredItemsOptions[0].name) : filteredItem = [];
    setDiscount(filteredItemsOptions.length === 0 || filteredItem[0].discount === undefined || !props.showTaxColumn ? 0 : filteredItem[0].discount);
  }, [props.showDiscountColumn])

  const [showProducts, setShowProducts] = useState(false);

  const handleInputChangeItem = (e) => {
    setSelectedItem(e.target.value);
    setItem(e.target.value);
    props.allItems.map((data, i) => data.id === props.itemId ? setShowProducts(true) : setShowProducts(false));
    props.setIsOpenItems(true);

    if (props.firmId.length === 0) {
      ErrorToast("Select Firm");
      props.setValidated(true);
      setItem('');
    } else {
      props.setValidated(false);
    }
  };

  const handleOptionClickItem = (i) => {
    setSelectedItem(i);
    setItem(i);
    props.setIsOpenItems(false);
  };

  useEffect(() => {
    const testPrice = props.includeTax === true
      ? ((parseFloat(items.quantity * itemPrice) / (1 + (parseFloat(items.taxRate) / 100))).toFixed(2)) / (items.quantity)
      : parseFloat(itemPrice)
    setpricevalue(testPrice);
  });

  const handleAmount = (e) => {
    setAmount(e.target.value)
  }

  const handleChange = () => {
    onInputChange(props.index, {
      ...items,
      itemName: item,
      quantity: parseInt(quantity),
      unitPrice: price,
      taxRate: taxRate,
      ...(props.showHSNColumn ? { hsn: hsn } : { hsn: '' }),
      ...(props.showSACColumn ? { sac: sac } : { sac: '' }),
      // amount: testAmt,
      amount: props.includeTax === true
        ? (parseFloat(quantity) * parseFloat(price))
        : (parseFloat(parseFloat(quantity) * parseFloat(price)) + (parseFloat(parseFloat(quantity) * parseFloat(price)) * ((taxRate ? parseFloat(items.taxRate) : 0) / 100))).toFixed(2),
      desc: description,
      discount: parseFloat(discount)
    });
  };

  useEffect(() => {
    handleChange()
  }, [item, quantity, price, taxRate, hsn, sac, props.showHSNColumn, props.showSACColumn, description, discount]);

  useEffect(() => {
    setTimeout(() => {
      props.allTaxes.map((data, i) => i == index && setAllSelectedTaxes(data));
    }, 2000);
  }, [index]);

  const dropdownhandleIndex = (id) => {
    props.setDropIndex(id)
    props.setIsOpenItems(true)
  }

  const handleShowProduct = () => {
    if (props.firmId.length === 0) {
      window.scrollTo(0, 0);
      props.setValidated(true);
      ErrorToast("Select Firm");
    } else {
      props.setShowCreateProducts(true);
      props.setIsOpenItems(true);
    }
  }

  return (
    <>
      <tr key={props.index}>
        <td>
          <input type="text" name="itemName"
            className="invoiceInput my-2"
            value={item}
            onChange={handleInputChangeItem}
            onInput={(e) => setItem(e.target.value)}
            onClick={() => dropdownhandleIndex(props.index)}
            placeholder={(items === undefined || items.itemName === undefined || items.itemName === '') ? 'Item Name' : items.itemName}
            autoComplete="off"
            style={{ minWidth: "30rem", border: "none", borderBottom: "none" }}
            required
          />
          {props.dropIndex === props.index ? props.isOpenItems && (
            <>
              <div
                style={{
                  display: 'none',
                  maxHeight: "15rem", width: "35%", position: 'absolute', background: 'white',
                  overflowY: "scroll", border: "1px solid #c8c9ca"
                }}
                className="rounded border px-3 py-2 border-2 d-flex flex-column">
                <div className='text-primary mt-2 pb-2'
                  style={{ cursor: 'pointer', borderBottom: "1px solid #c8c9ca" }}
                >
                  <MdAdd className="text-primary fw-bold fs-4" onClick={() => handleShowProduct()} style={{ fontWeight: "900" }} /> <span className="m-0 p-0 me-1" onClick={() => handleShowProduct()}>Create New Item</span>
                  {
                    props.validateFirm ? (
                      <span className="fw-semibold me-1 text-danger" onClick={() => window.scrollTo(0, 0)}>Select Firm</span>
                    ) : null
                  }
                </div>
                {
                  props.allProducts.length > 0 ? (
                    filteredItemsOptions.length > 0 ? (
                      filteredItemsOptions.map((option, i) => (
                        <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleOptionClickItem(option.name)}>
                          {option.name}
                        </div>
                      ))
                    ) : (<div className="mt-2">No match found</div>)
                  ) : (<div className="mt-2">No products found </div>)
                }
              </div>
            </>
          ) : null}
        </td>

        {
          props.showHSNColumn
            ? (
              <td className="text-center">
                <input type="text" name="hsn" maxLength={8}
                  value={hsn}
                  className="invoiceInput text-center my-2" onChange={props.handleChange} onInput={(e) => setHsn(e.target.value)}
                  placeholder={items === undefined || items.hsn === undefined ? 'Enter HSN' : items.hsn}
                  style={{ maxWidth: "7rem", minWidth: "7rem", border: "none", borderBottom: "1px solid #fff" }}
                />
              </td>
            ) : null
        }

        {
          props.showSACColumn
            ? (
              <td className="text-center">
                <input type="text" name="sac" maxLength={8}
                  className="invoiceInput text-center my-2" onChange={props.handleChange} onInput={(e) => setSac(e.target.value)}
                  placeholder="Enter SAC"
                  style={{ maxWidth: "7rem", minWidth: "7rem", border: "none", borderBottom: "1px solid #fff" }}
                />
              </td>
            ) : null
        }

        <td className="text-center">
          <input type="text" name="quantity"
            className="invoiceInput text-center my-2"
            onChange={props.handleChange}
            onInput={(e) => setQuantity(e.target.value)}
            placeholder={items === undefined || items.quantity === undefined ? '' : items.quantity}
            style={{ maxWidth: "5rem", minWidth: "5rem", border: "none", borderBottom: "1px solid #fff" }} required
          />
        </td>
        <td className="text-start">
          <input type="number" name="unitPrice"
            className="invoiceInput text-start my-2"
            onChange={props.handleChange}
            value={price}
            onInput={(e) => setPrice(parseFloat(props.includeTax === true
              ? ((parseFloat(parseInt(quantity) * parseFloat(e.target.value)) / (1 + (parseFloat(taxRate) / 100))).toFixed(2)) / (parseInt(quantity))
              : parseFloat(e.target.value)))}
            placeholder="0"
            style={{ maxWidth: "6rem", minWidth: "6rem", border: "none", borderBottom: "1px solid #fff" }}
            required
          />
        </td>


        {
          props.showTaxColumn ? (
            showSelect === false ? (
              <td>
                <Form.Select name="taxRate"
                  onChange={() => setShowSelect(true)}
                  onInput={(e) => setTaxRate(e.target.value)}
                  style={{ maxWidth: "7rem", minWidth: "7rem" }}
                >
                  <option value={0}>Select Tax</option>
                  <option value={0}>Remove</option>
                  {
                    props.allTaxes.map((data, index) => (
                      <option value={Object.values(data)} key={index}>
                        {Object.keys(data)} {Object.values(data)}
                      </option>
                    ))
                  }
                </Form.Select>
              </td>
            ) : (
              <td className="text-center" style={{ maxWidth: "7rem", minWidth: "7rem" }}>
                <p className="pt-3" onClick={() => setShowSelect(false)}>{items.taxRate}</p>
              </td>
            )
          ) : null
        }

        {
          props.showDiscountColumn &&
          (<td>
            <input
              type="number" name="discount"
              className="invoiceInput text-center my-2"
              onChange={(e) => setDiscount(e.target.value)}
              onInput={props.handleChange}
              placeholder={items === undefined || items.discount === undefined ? 0 : items.discount}
              style={{ maxWidth: "5rem", minWidth: "5rem", border: "none", borderBottom: "1px solid #fff" }}
            // required
            />
          </td>)
        }
        {/* <td className="text-start">
          <input type="number" name="amount"
            value={props.includeTax === true
              ? (items.quantity * items.unitPrice)
              : (parseFloat(items.quantity * items.unitPrice) + (parseFloat(items.quantity * items.unitPrice) * ((items.taxRate ? parseFloat(items.taxRate) : 0) / 100))).toFixed(2)
            }
            onChange={handleAmount} className="invoiceInput text-start ps-3 w-75 my-2"
            style={{ maxWidth: "10rem", minWidth: "10rem", border: "none", borderBottom: "1px solid #fff" }} />
        </td> */}

        <td className="text-start">
          <input type="number" name="amount"
            // value={props.includeTax === true
            //   ? (items.quantity * items.unitPrice)
            //   : (parseFloat(items.quantity * items.unitPrice) + (parseFloat(items.quantity * items.unitPrice) * ((items.taxRate ? parseFloat(items.taxRate) : 0) / 100))).toFixed(2)
            // }
            value={testAmt}
            onChange={handleAmount} className="invoiceInput text-start ps-3 w-75 my-2"
            style={{ maxWidth: "10rem", minWidth: "10rem", border: "none", borderBottom: "1px solid #fff" }} />
        </td>

        <td className=" text-center">
          <AiOutlineDelete className="fs-3 text-danger" onClick={() => handleDeleteRow(props.index)} style={{ cursor: "pointer" }} />
        </td>
      </tr >
      <tr className="pb-2" key={props.index} style={{ borderBottom: "1px solid #c8c9ca" }}>
        <input type="text" name="desc" className="invoiceInput mb-2 w-75"
          value={description}
          onChange={props.handleChange} onInput={(e) => setDescription(e.target.value)}
          placeholder={items === undefined || items.desc === undefined || description.length === 0 ? 'Item Description' : items.desc}
          style={{ border: "none", borderBottom: "1px solid #fff" }} />
      </tr>
    </>
  )
}

export default InvoiceTable;