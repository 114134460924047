import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Row, Col, Form, Button } from "react-bootstrap";
import Card from "../../../../components/Card";
import SubHeader from "../../../../components/partials/dashboard/HeaderStyle/sub-header";
import TimezoneSelect from 'react-timezone-select'
import { getLeadById, updateLead } from "../Hook/leadHooks";
import { getCity, getCountries, getState } from "../Hook/CustomHooks";
import { getFirmList } from "../Hook/firmHooks";
import { getOrgDept, getOrgDetails } from "../Hook/orgHooks";
import { getAllUsers, getUserById, getUsersByDept } from "../Hook/userHooks";
import { ErrorToast, SuccessToast, WarningToast } from "../../components/toast";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

const UpdateLead = () => {
  const { id } = useParams();
  const initData = Object.freeze({});

  const [form, setForm] = useState([initData]);
  const [singleLead, setSinglLead] = useState({});
  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [allStages, setAllStages] = useState([]);

  const [allFirms, setAllFirms] = useState([]);
  const [orgDept, setOrgDept] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [Orgcountries, setOrgCountries] = useState([]);
  const [orgStates, setOrgStates] = useState([]);
  const [orgCities, setOrgCities] = useState([]);

  const initialClientAddress = Object.freeze({
    country: "",
    state: "",
    city: "",
  });
  const [clientAddress, setClientAddress] = useState(initialClientAddress);

  const [clientLineOne, setClientLineOne] = useState('');
  const [clientLineTwo, setClientLineTwo] = useState('');
  const [clientPinCode, setClientPinCode] = useState('');
  const [clientCountry, setClientCountry] = useState('');
  const [isOpenCountry, setIsOpenCountry] = useState(false);
  const [clientState, setClientState] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [clientCity, setClientCity] = useState('');
  const [isOpenClientCity, setIsOpenClientCity] = useState(false);

  // const initialOrgAddress = Object.freeze({
  //   orgCountry: "",
  //   orgState: "",
  //   orgCity: "",
  // })
  const [organizationName, setOrganizationName] = useState('');
  const [orgEmail, setOrgEmail] = useState('');
  const [orgPhone, setOrgPhone] = useState('');
  const [orgLineOne, setOrgLineOne] = useState('');
  const [orgLineTwo, setOrgLineTwo] = useState('');
  const [orgPinCode, setOrgPinCode] = useState('');
  const [orgCountry, setOrgCountry] = useState('');
  const [isOpenOrgCountry, setIsOpenOrgCountry] = useState(false);
  const [orgState, setOrgState] = useState('');
  const [isOpenOrgState, setIsOpenOrgState] = useState(false);
  const [orgCity, setOrgCity] = useState('');
  const [isOpenOrgCity, setIsOpenOrgCity] = useState(false);

  const [assignedTo, setAssignedTo] = useState('');
  const [department, setDepartment] = useState('');
  const [userType, setUserType] = useState('');
  const [isOpenAssigneeList, setIsOpenAssigneeList] = useState(false);

  const [allAssignees, setAllAssignees] = useState([]);
  const [formStep, setFormStep] = useState(1);

  const [disabledBtn, setDisabledBtn] = useState(false);

  const [showAbout, setShowAbout] = useState(true);
  const [showClient, setShowClient] = useState(false);
  const [showOrg, setShowOrg] = useState(false);

  const allCurrencies = [
    {
      id: 0,
      name: "INR",
      value: "₹",
    },
    {
      id: 1,
      name: "USD",
      value: "$",
    },
    {
      id: 2,
      name: "EUR",
      value: "€",
    },
    {
      id: 3,
      name: "AUD",
      value: "$",
    },
    {
      id: 4,
      name: "CAD",
      value: "$",
    },
  ];

  const orgName = localStorage.getItem('orgName');
  const history = useHistory();


  const departmenRef = useRef(null);
  const userTypeRef = useRef(null);

  let pipelineObj = {
    department: '',
    userType: ''
  };

  useEffect(() => {
    getLeadById(id).then((response) => {
      if (response.status === 200) {
        setSinglLead(response.data.data);
        const stages = response?.data?.data?.stageHistory;

        stages === undefined ? setAllStages([]) : setAllStages(stages)
      } else {
        setSinglLead({});
      }
    }).catch(() => setSinglLead({}))
  }, [id]);

  useEffect(() => {
    getFirmList().then((response) => {
      if (response.status === 200) setAllFirms(response.data.data)
      else setAllFirms([])
    }).catch(() => setAllFirms([]));
  }, []);

  useEffect(() => {
    getOrgDept().then((response) => {
      if (response.status === 200) {
        setOrgDept(response.data.data);
      }
    })
  }, []);

  useEffect(() => {
    getOrgDetails().then((response) => {
      if (response.status === 200) {
        // setAllStages(response.data.data.orgLeadStages);
        // setAllPipelines(response.data.data.orgDept);
      }
    })
  }, []);

  useEffect(() => {
    getAllUsers().then((response) => {
      if (response.status === 200) setAllAssignees(response.data.data)
      else setAllAssignees([]);
    }).catch(() => setAllAssignees([]));
  }, []);

  console.log('all users', allAssignees);

  useEffect(() => {
    getCountries().then((res) => {
      setCountries(res);
    });
  }, []);

  useEffect(() => {
    setAssignedTo(singleLead?.assignedTo?.userName)
    // setAssignedTo(singleLead === undefined || singleLead.pipeline === undefined || singleLead.pipeline.assignTo === undefined ? '' : singleLead.pipeline.assignTo);
    setDepartment(singleLead === undefined || singleLead.pipeline === undefined || singleLead.pipeline.department === undefined ? '' : singleLead.pipeline.department);
    setUserType(singleLead === undefined || singleLead.pipeline === undefined || singleLead.pipeline.userType === undefined ? '' : singleLead.pipeline.userType);
    // client
    setClientCountry(singleLead.clientAddress === undefined || singleLead.clientAddress.country === undefined ? "" : singleLead.clientAddress.country);
    setClientState(singleLead.clientAddress === undefined || singleLead.clientAddress.state === undefined ? "" : singleLead.clientAddress.state);
    setClientCity(singleLead.clientAddress === undefined || singleLead.clientAddress.city === undefined ? "" : singleLead.clientAddress.city);
    setClientLineOne(singleLead.clientAddress === undefined || singleLead.clientAddress.lineOne === undefined ? "" : singleLead.clientAddress.lineOne);
    setClientLineTwo(singleLead.clientAddress === undefined || singleLead.clientAddress.lineTwo === undefined ? "" : singleLead.clientAddress.lineTwo);
    setClientPinCode(singleLead.clientAddress === undefined || singleLead.clientAddress.code === undefined ? "" : singleLead.clientAddress.code);

    // org
    setOrganizationName(singleLead.orgDetails === undefined || singleLead.orgDetails.orgName === undefined ? '' : singleLead.orgDetails.orgName);
    setOrgEmail(singleLead.orgDetails === undefined || singleLead.orgDetails.orgEmail === undefined ? '' : singleLead.orgDetails.orgEmail);
    setOrgPhone(singleLead.orgDetails === undefined || singleLead.orgDetails.orgPhone === undefined ? '' : singleLead.orgDetails.orgPhone)
    setOrgCountry(singleLead.orgDetails === undefined || singleLead.orgDetails.orgAddress === undefined || singleLead.orgDetails.orgAddress.orgCountry === undefined ? '' : singleLead.orgDetails.orgAddress.orgCountry);
    setOrgState(singleLead.orgDetails === undefined || singleLead.orgDetails.orgAddress === undefined || singleLead.orgDetails.orgAddress.orgState === undefined ? '' : singleLead.orgDetails.orgAddress.orgState);
    setOrgCity(singleLead.orgDetails === undefined || singleLead.orgDetails.orgAddress === undefined || singleLead.orgDetails.orgAddress.orgCity === undefined ? '' : singleLead.orgDetails.orgAddress.orgCity);
    setOrgLineOne(singleLead.orgDetails === undefined || singleLead.orgDetails.orgAddress === undefined || singleLead.orgDetails.orgAddress.orgLineOne === undefined ? '' : singleLead.orgDetails.orgAddress.orgLineOne);
    setOrgLineTwo(singleLead.orgDetails === undefined || singleLead.orgDetails.orgAddress === undefined || singleLead.orgDetails.orgAddress.orgLineTwo === undefined ? '' : singleLead.orgDetails.orgAddress.orgLineTwo);
    setOrgPinCode(singleLead.orgDetails === undefined || singleLead.orgDetails.orgAddress === undefined || singleLead.orgDetails.orgAddress.orgCode === undefined ? '' : singleLead.orgDetails.orgAddress.orgCode);
  }, [singleLead]);

  useEffect(() => {
    if (form.clientCountry) {
      getState(form.clientCountry).then((res) => {
        setStates(res);
      });
    }
  }, [form.clientCountry]);

  useEffect(() => {
    if (form.clientState) {
      getCity(form.clientState).then((res) => {
        setCities(res);
      });
    }
  }, [form.clientState]);

  useEffect(() => {
    getCountries().then((res) => {
      setOrgCountries(res);
    });
  }, []);

  useEffect(() => {
    if (form.orgCountry) {
      getState(form.orgCountry).then((res) => {
        setOrgStates(res);
      });
    }
  }, [form.orgCountry]);

  useEffect(() => {
    if (form.orgState) {
      getCity(form.orgState).then((res) => {
        setOrgCities(res);
      });
    }
  }, [form.orgState]);

  const handleInputChangeCountry = (e) => {
    setClientCountry(e.target.value);

    setIsOpenCountry(true);
    setClientAddress({
      ...clientAddress,
      country: e.target.value
    })
  };

  const handleOptionClickCountry = (i) => {
    setClientCountry(i)
    setClientAddress({
      ...clientAddress,
      country: i
    })
    setIsOpenCountry(false);
  };

  const handleInputChange = (e) => {
    setClientState(e.target.value);

    setIsOpen(true);
    setClientAddress({
      ...clientAddress,
      state: e.target.value
    })
  };

  const handleOptionClick1 = (i) => {
    setClientState(i)
    setClientAddress({
      ...clientAddress,
      state: i
    })
    setIsOpen(false);
  };

  const handleInputChangeCity = (e) => {
    setClientCity(e.target.value);

    setIsOpenClientCity(true);
    setClientAddress({
      ...clientAddress,
      city: e.target.value
    })
  };

  const handleOptionClickCity = (i) => {
    setClientCity(i)
    setClientAddress({
      ...clientAddress,
      city: i
    })
    setIsOpenClientCity(false);
  };

  const filteredCountryOptions = countries.filter((option) =>
    (option.country_name).toLowerCase().includes(clientCountry.toLowerCase())
  );

  const filteredOptions = states.filter((option) =>
    (option.state_name).toLowerCase().includes(clientState.toLowerCase())
  );

  const filteredCityOptions = cities.filter((option) =>
    (option.city_name).toLowerCase().includes(clientCity.toLowerCase())
  );

  const handleInputChangeOrgCountry = (e) => {
    setOrgCountry(e.target.value);

    setIsOpenOrgCountry(true);
    setForm({
      ...form,
      orgCountry: e.target.value
    })
  };

  const handleOptionClickOrgCountry = (i) => {
    setOrgCountry(i)
    setForm({
      ...form,
      orgCountry: i
    })
    setIsOpenOrgCountry(false);
  };

  const handleInputChangeOrgState = (e) => {
    setOrgState(e.target.value);

    setIsOpenOrgState(true);
    setForm({
      ...form,
      orgState: e.target.value
    })
  };

  const handleOptionClickOrgState = (i) => {
    setOrgState(i)
    setForm({
      ...form,
      orgState: i
    })
    setIsOpenOrgState(false);
  };

  const handleInputChangeOrgCity = (e) => {
    setOrgCity(e.target.value);

    setIsOpenOrgCity(true);
    setForm({
      ...form,
      orgDistrict: e.target.value
    })
  };

  const handleOptionClickOrgCity = (i) => {
    setOrgCity(i)
    setForm({
      ...form,
      orgDistrict: i
    })
    setIsOpenOrgCity(false);
  };

  const filteredOrgCountryOptions = Orgcountries.filter((option) =>
    (option.country_name).toLowerCase().includes(orgCountry.toLowerCase())
  );

  const filteredStateOptions = orgStates.filter((option) =>
    (option.state_name).toLowerCase().includes(orgState.toLowerCase())
  );

  const filteredOrgCityOptions = orgCities.filter((option) =>
    (option.city_name).toLowerCase().includes(orgCity.toLowerCase())
  );

  const handleInputChangeAssignee = (e) => {
    setAssignedTo(e.target.value);
    setIsOpenAssigneeList(true);
  };

  const handleOptionClickAssignee = (i) => {
    const name = `${i?.firstName} ${i?.lastName}`
    setAssignedTo(name);
    setForm({
      ...form,
      assignedTo: {
        userId: i?._id,
        userName: name
      }
    })
    setIsOpenAssigneeList(false);
  };

  const filteredAssigneeOptions = allAssignees.filter((option) =>
    (`${option?.firstName} ${option?.lastName}`).toLowerCase().includes(assignedTo?.toLowerCase())
  );


  const handleOrgPinCode = (e) => {
    if ((e.target.value > orgCountry) === 'United States' ? 7 : 6) {
      e.target.value = e.target.value.slice(0, orgCountry === 'United States' ? 7 : 6)
      setOrgPinCode(e.target.value);
    }
  }

  const handleChangePipeline = () => {
    console.log("departmentref", departmenRef.current.value);
    console.log("userref", userTypeRef.current.value);

    pipelineObj.department = departmenRef.current.value;
    pipelineObj.userType = userTypeRef.current.value;

    setForm({
      ...form,
      pipeline: pipelineObj
    })

  };

  const handleChange = (e) => {
    e.preventDefault();

    if (e.target.name === 'clientPhone') {
      e.target.value = e.target.value.slice(0, 10);
      setForm({
        ...form,
        clientPhone: e.target.value
      })
    }

    setForm({
      ...form,
      [e.target.name]: e.target.value,
      // timezone: selectedTimezone.label,
      // pipeline: {
      //   department: department,
      //   assignTo: assignedTo,
      //   userType: userType,
      // },
      clientAddress: {
        lineOne: clientLineOne,
        lineTwo: clientLineTwo,
        country: clientCountry,
        state: clientState,
        city: clientCity,
        code: clientPinCode
      },
      orgDetails: {
        orgName: organizationName,
        orgEmail: orgEmail,
        orgPhone: orgPhone.slice(0, 10),
        orgAddress: {
          orgLineOne: orgLineOne,
          orgLineTwo: orgLineTwo,
          orgCountry: orgCountry,
          orgState: orgState,
          orgCity: orgCity,
          orgCode: orgPinCode,
        }
      }
    });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    setDisabledBtn(true);

    updateLead(id, form).then((response) => {
      if (response.status === 200) {
        SuccessToast("Lead updated successfully");
        setDisabledBtn(false);
        setTimeout(() => {
          history.push(`/${orgName}/dashboard/lead/lead-details/${id}`);
        }, 2000);
      } else {
        ErrorToast('Unable to update lead');
        setDisabledBtn(false);
      }
    }).catch((err) => {
      err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Something went wrong');
      setDisabledBtn(false);
    })
  };


  const leadDetails = [
    {
      title: 'About Lead',
      details: [
        {
          title: 'Title',
          desc: singleLead?.title
        },
        {
          title: 'Closure Date',
          desc: singleLead?.closureDate
        },
        {
          title: 'Created Date',
          desc: singleLead?.createdAt
        },
        {
          title: 'Stage',
          desc: singleLead.stage
        },
        {
          title: 'Estimated Worth',
          desc: singleLead?.estimatedWorth
        }
      ]
    },
    {
      title: 'About Client',
      details: [
        {
          title: 'Name',
          desc: `${singleLead?.clientFName} ${singleLead?.clientLName}`
        },
        {
          title: 'Email',
          desc: singleLead?.clientEmail
        },
        {
          title: 'Phone',
          desc: singleLead?.clientPhone
        }
      ]
    },
    {
      title: 'About Organizatioin',
      details: [
        {
          title: 'Name',
          desc: singleLead?.orgDetails?.orgName
        },
        {
          title: 'Email',
          desc: singleLead?.orgDetails?.orgEmail
        },
        {
          title: 'Phone',
          desc: singleLead?.orgDetails?.orgPhone
        }
      ]
    }
  ];

  return (
    <div className="m-0 p-0" onClick={() => {
      (isOpenCountry && setIsOpenCountry(false)) || (isOpen && setIsOpen(false)) || (isOpenClientCity && setIsOpenClientCity(false)) ||
        (isOpenOrgCountry && setIsOpenOrgCountry(false)) || (isOpenOrgState && setIsOpenOrgState(false)) || (isOpenOrgCity && setIsOpenOrgCity(false))
    }}>
      <SubHeader title="Update Lead" />
      <div>
        <Row className="text-dark" style={{ marginTop: "-3%" }}>
          <Col sm={4} className="w-25 rounded h-100 ms-3">
            {
              leadDetails.map((data, index) => (
                <Col sm={12} className="px-3 rounded bg-white mb-3" key={index}>
                  <Row className="py-2">
                    <h5 className="text-primary" style={{ fontWeight: "550", textAlign: "left" }}>{data.title}</h5>
                  </Row>
                  {
                    data?.details?.map((detail, i) => (
                      <Row className="py-1" key={i}>
                        <Col md={4} sm={12}>
                          <div style={{ fontWeight: "550" }}>{detail?.title}: </div>
                        </Col>
                        <Col>{detail?.desc}</Col>
                      </Row>
                    ))
                  }
                </Col>
              ))
            }
          </Col>
          <Col className="text-dark me-3">
            <form onSubmit={handleUpdate}>
              <Col>
                <Card className="">
                  <Card.Header className="bg-white">
                    <div className="header-title w-100 d-flex justify-content-between align-items-center cursor-pointer" onClick={() => setShowAbout(!showAbout)}>
                      <h4 className="card-title text-primary fw-bold">About Lead</h4>
                      {
                        showAbout ? <BsChevronUp className="text-dark fs-2" onClick={() => setShowAbout(false)} />
                          : <BsChevronDown className="text-dark fs-2" onClick={() => setShowAbout(true)} />
                      }
                    </div>
                  </Card.Header>

                  <Card.Body className={`${!showAbout && 'm-0 py-2'}`}>
                    {
                      showAbout ? (
                        <>
                          <Row>
                            <Col className="mt-3" sm={12} md={6} lg={4}>
                              <Form.Label htmlFor="title">Title</Form.Label>
                              <Form.Control type="text" className="border border-primary" defaultValue={singleLead.title} name="title" onChange={handleChange} />
                            </Col>
                            <Col className="mt-3" sm={12} md={6} lg={4}>
                              <Form.Group>
                                <Form.Label htmlFor="firm">Firm</Form.Label>
                                <Form.Select name="firm" onChange={handleChange} className="w-100 p-2 bg-white border border-primary" style={{ borderRadius: "5px" }}>
                                  <option>Select Firm</option>
                                  {
                                    allFirms.map((data, index) => (
                                      <option value={data._id} key={index}>{data.name}</option>
                                    ))
                                  }
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            <Col className="mt-3" sm={12} md={6} lg={4}>
                              <Form.Group>
                                <Form.Label>Pipeline</Form.Label>
                                <Form.Select defaultValue={department} ref={departmenRef} onChange={handleChangePipeline} className="w-100 p-2 bg-white border border-primary" style={{ borderRadius: "5px" }}>
                                  <option>Select Pipeline</option>
                                  {
                                    orgDept.length > 0 ? orgDept.map((data, index) =>
                                      data.length > 0 ? (
                                        <option key={index}>{data}</option>
                                      ) : null
                                    ) : (
                                      <option>No Pipeline Found</option>
                                    )
                                  }
                                </Form.Select>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="mt-3" sm={12} md={6} lg={4}>
                              <Form.Group style={{ position: 'relative' }}>
                                <Form.Label htmlFor="assignTo">Assign To</Form.Label>
                                <input type="text" className="mt-1 p-2 w-100 border border-primary" value={assignedTo} placeholder="Select Assignee"
                                  onChange={handleInputChangeAssignee} onClick={() => setIsOpenAssigneeList(true)} autoComplete='off' style={{ borderRadius: '5px' }}
                                />
                                {isOpenAssigneeList && (
                                  <>
                                    <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                      {
                                        allAssignees.length > 0 ? (
                                          filteredAssigneeOptions.length > 0 ? (
                                            filteredAssigneeOptions.map((option, i) => (
                                              <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleOptionClickAssignee(option)}>
                                                {`${option?.firstName} ${option?.lastName}`}
                                              </div>
                                            ))
                                          ) : (
                                            <div className="mt-2">No match found</div>
                                          )
                                        ) : (
                                          <div className="mt-2">Loading . . . . </div>
                                        )
                                      }
                                    </div>
                                  </>
                                )}
                              </Form.Group>
                            </Col>
                            <Col className="mt-3" sm={12} md={6} lg={4}>
                              <Form.Group>
                                <Form.Label htmlFor="stage">stage</Form.Label>
                                <Form.Select name="stage" onChange={handleChange} defaultValue={singleLead.stage} className="border border-primary">
                                  <option>{singleLead.stage}</option>
                                  {
                                    allStages.map((data, index) => (
                                      <option key={index} style={{ display: data?.stageName === "" && 'none' }}>{data?.stageName}</option>
                                    ))
                                  }</Form.Select>
                              </Form.Group>
                            </Col>

                            <Col className="mt-3" sm={12} md={6} lg={4}>
                              <Form.Group>
                                <Form.Label htmlFor="estimatedWorth">Currency
                                </Form.Label>
                                <Form.Select name="currency" defaultValue={singleLead.currency} onChange={handleChange} className="border border-primary">
                                  {/* <option>Select Currency</option> */}
                                  {
                                    allCurrencies.map((data, index) => (
                                      <option value={data.value} key={index}>{data.name}</option>
                                    ))
                                  }
                                </Form.Select>
                                {/* <Form.Control type="text" name="estimatedWorth" defaultValue={singleLead.estimatedWorth} className="border border-primary" onChange={handleChange} /> */}
                              </Form.Group>
                            </Col>

                            <Col className="mt-3" sm={12} md={6} lg={4}>
                              <Form.Group>
                                <Form.Label htmlFor="estimatedWorth">Estimated Worth
                                </Form.Label>
                                <Form.Control type="text" name="estimatedWorth" defaultValue={singleLead.estimatedWorth} className="border border-primary" onChange={handleChange} />
                              </Form.Group>
                            </Col>
                            <Col className="mt-3" sm={12} md={6} lg={4}>
                              <Form.Group>
                                <Form.Label htmlFor="createdDate">Created Date</Form.Label>
                                <Form.Control type="date" name="createdDate" defaultValue={singleLead.createdDate} className="border border-primary" onChange={handleChange} />
                              </Form.Group>
                            </Col>
                            <Col className="mt-3" sm={12} md={6} lg={4}>
                              <Form.Label htmlFor="closureDate">Closure Date</Form.Label>
                              <Form.Control type="date" className="border border-primary" defaultValue={singleLead.closureDate} name="closureDate" onChange={handleChange} />
                            </Col>
                          </Row>
                          <Row className="mt-3 ">
                            <Form.Group>
                              <Form.Label>Description</Form.Label>
                              <Form.Control as="textarea" rows="5" name="description" defaultValue={singleLead.description} className="border border-primary" onChange={handleChange} />
                            </Form.Group>
                          </Row>
                        </>
                      ) : null
                    }
                  </Card.Body>
                </Card>
                <div className="" style={{ marginTop: "-5%", marginLeft: "50%", borderLeft: "3px solid red", height: "4rem", }}>{" "}</div>

                <Card className="">
                  <Card.Header className="bg-white">
                    <div className="header-title w-100 d-flex justify-content-between align-items-center cursor-pointer" onClick={() => setShowClient(!showClient)}>
                      <h4 className="card-title text-primary fw-bold">Client</h4>
                      {
                        showClient ? <BsChevronUp className="text-dark fs-2" onClick={() => setShowClient(false)} />
                          : <BsChevronDown className="text-dark fs-2" onClick={() => setShowClient(true)} />
                      }
                    </div>
                  </Card.Header>
                  <Card.Body className={`${!showClient && 'm-0 py-2'}`}>
                    {
                      showClient ? (
                        <>
                          <Row>
                            <Col className="mt-3" sm={12} md={6} lg={3}>
                              <Form.Group>
                                <Form.Label htmlFor="clientFName">Client First Name
                                </Form.Label>
                                <Form.Control type="text" name="clientFName" defaultValue={singleLead.clientFName} className="border border-primary" onChange={handleChange} />
                              </Form.Group>
                            </Col>
                            <Col className="mt-3" sm={12} md={6} lg={3}>
                              <Form.Group>
                                <Form.Label htmlFor="clientLName">Client Last Name
                                </Form.Label>
                                <Form.Control type="text" name="clientLName" defaultValue={singleLead.clientLName} className="border border-primary" onChange={handleChange} />
                              </Form.Group>
                            </Col>
                            <Col className="mt-3" sm={12} md={6} lg={3}>
                              <Form.Group>
                                <Form.Label htmlFor="clientEmail">Client Email
                                </Form.Label>
                                <Form.Control type="email" name="clientEmail" defaultValue={singleLead.clientEmail} className="border border-primary" onChange={handleChange} />
                              </Form.Group>
                            </Col>
                            <Col className="mt-3" sm={12} md={6} lg={3}>
                              <Form.Group>
                                <Form.Label htmlFor="clientPhone">Client Phone
                                </Form.Label>
                                <Form.Control type="text" name="clientPhone" defaultValue={singleLead.clientPhone} className="border border-primary" onChange={handleChange} />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row sm={12}>
                            <Col className="mt-3" sm={12} md={6} lg={4}>
                              <Form.Group>
                                <Form.Label htmlFor="timezone">Time Zone</Form.Label>
                                <TimezoneSelect defaultInputValue={singleLead.timezone} value={selectedTimezone} onChange={setSelectedTimezone} className="border border-primary rounded" />
                              </Form.Group>
                            </Col>
                            <Col className="mt-3" sm={12} md={6} lg={4}>
                              <Form.Group>
                                <Form.Label>Address Line 1</Form.Label>
                                <input type="text" name="lineOne" value={clientLineOne} onChange={(e) => setClientLineOne(e.target.value)} className="border border-primary p-2 w-100" placeholder="Address Line 1" style={{ borderRadius: '5px' }} />
                              </Form.Group>
                            </Col>

                            <Col className="mt-3" sm={12} md={6} lg={4}>
                              <Form.Group>
                                <Form.Label>Address Line 2</Form.Label>
                                <input type="text" name="lineTwo" value={clientLineTwo} onChange={(e) => setClientLineTwo(e.target.value)} className="border border-primary p-2 w-100" placeholder="Address Line 2" style={{ borderRadius: '5px' }} />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="mt-3" sm={12} md={6} lg={3}>
                              <Form.Group style={{ position: 'relative' }}>
                                <Form.Label htmlFor="clientCountry">Select Country</Form.Label>
                                <Form.Control type="text" id="autocomplete-state" className="border border-primary" value={clientCountry} name="clientCountry" placeholder="Select Country"
                                  onChange={handleInputChangeCountry} onClick={() => setIsOpenCountry(true)} autoComplete='off'
                                />
                                {isOpenCountry && (
                                  <>
                                    <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                      {
                                        countries.length > 0 ? (
                                          filteredCountryOptions.length > 0 ? (
                                            filteredCountryOptions.map((option, i) => (
                                              <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleOptionClickCountry(option.country_name)}>
                                                {option.country_name}
                                              </div>
                                            ))
                                          ) : (
                                            <div className="mt-2">No match found</div>
                                          )
                                        ) : (
                                          <div className="mt-2">Loading . . . . </div>
                                        )
                                      }
                                    </div>
                                  </>
                                )}
                              </Form.Group>
                            </Col>
                            <Col className="mt-3" sm={12} md={6} lg={3}>
                              <Form.Group style={{ position: 'relative' }}>
                                <Form.Label htmlFor="clientState">Select State</Form.Label>
                                <Form.Control type="text" id="autocomplete-state" className="border border-primary" value={clientState} name="clientState" placeholder="Select State"
                                  onChange={handleInputChange} onClick={() => setIsOpen(true)} autoComplete='off'
                                />
                                {isOpen && (
                                  <>
                                    <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                      {
                                        states.length > 0 ? (
                                          filteredOptions.length > 0 ? (
                                            filteredOptions.map((option, i) => (
                                              <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleOptionClick1(option.state_name)}>
                                                {option.state_name}
                                              </div>
                                            ))
                                          ) : (
                                            <div className="mt-2">No match found</div>
                                          )
                                        ) : (
                                          <div className="mt-2">Select country first </div>
                                        )
                                      }
                                    </div>
                                  </>
                                )}
                              </Form.Group>
                            </Col>
                            <Col className="mt-3" sm={12} md={6} lg={3}>
                              <Form.Group style={{ position: 'relative' }}>
                                <Form.Label htmlFor="clientCity">Select City</Form.Label>
                                <Form.Control type="text" id="autocomplete-state" className="border border-primary" value={clientCity} name="clientCity" placeholder="Select City"
                                  onChange={handleInputChangeCity} onClick={() => setIsOpenClientCity(true)} autoComplete='off'
                                />
                                {isOpenClientCity && (
                                  <>
                                    <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                      {
                                        cities.length > 0 ? (
                                          filteredCityOptions.length > 0 ? (
                                            filteredCityOptions.map((option, i) => (
                                              <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleOptionClickCity(option.city_name)}>
                                                {option.city_name}
                                              </div>
                                            ))
                                          ) : (
                                            <div className="mt-2">No match found</div>
                                          )
                                        ) : (
                                          <div className="mt-2">Select state first </div>
                                        )
                                      }
                                    </div>
                                  </>
                                )}
                              </Form.Group>
                            </Col>
                            <Col className="mt-3" sm={12} md={6} lg={3}>
                              <Form.Group>
                                <Form.Label>Pincode / Zipcode</Form.Label>
                                <input type="text" name="code" value={clientPinCode} onChange={(e) => setClientPinCode(e.target.value)} className="border border-primary p-2 w-100" placeholder="Pincode / Zipcode" style={{ borderRadius: '5px' }} />
                              </Form.Group>
                            </Col>
                          </Row>
                        </>
                      ) : null
                    }
                  </Card.Body>
                </Card>
                <div className="" style={{ marginTop: "-5%", marginLeft: "50%", borderLeft: "3px solid red", height: "4rem", }}>{" "}</div>

                <Card className="">
                  <Card.Header className="bg-white">
                    <div className="header-title w-100 d-flex justify-content-between align-items-center cursor-pointer" onClick={() => setShowOrg(!showOrg)}>
                      <h4 className="card-title text-primary fw-bold">Organization</h4>
                      {
                        showOrg ? <BsChevronUp className="text-dark fs-2" onClick={() => setShowOrg(false)} />
                          : <BsChevronDown className="text-dark fs-2" onClick={() => setShowOrg(true)} />
                      }
                    </div>
                  </Card.Header>
                  <Card.Body className={`${!showOrg && 'm-0 py-2'}`}>
                    {
                      showOrg ? (
                        <>
                          <Row>
                            <Col className="mt-3" sm={12} md={6} lg={4}>
                              <Form.Group>
                                <Form.Label htmlFor="orgName">
                                  Organization Name
                                </Form.Label>
                                <input type="text" onChange={(e) => setOrganizationName(e.target.value)} className="border border-primary w-100 p-2"
                                  value={organizationName} placeholder="NA" style={{ borderRadius: "5px" }}
                                />
                              </Form.Group>
                            </Col>
                            <Col className="mt-3" sm={12} md={6} lg={4}>
                              <Form.Group>
                                <Form.Label htmlFor="orgEmail">Email</Form.Label>
                                <input type="text" value={orgEmail} onChange={(e) => setOrgEmail(e.target.value)} className="border border-primary w-100 p-2"
                                  placeholder="Email" style={{ borderRadius: "5px" }}
                                />
                              </Form.Group>
                            </Col>
                            <Col className="mt-3" sm={12} md={6} lg={4}>
                              <Form.Group>
                                <Form.Label htmlFor="orgPhone">Phone No.</Form.Label>
                                <input type="number" value={orgPhone} onChange={(e) => setOrgPhone(e.target.value)} className="border border-primary w-100 p-2"
                                  placeholder="Phone No." style={{ borderRadius: "5px" }}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="mt-3" sm={12} md={6} lg={4}>
                              <Form.Group>
                                <Form.Label>Address Line 1</Form.Label>
                                <input type="text" value={orgLineOne} onChange={(e) => setOrgLineOne(e.target.value)} className="border border-primary w-100 p-2"
                                  placeholder="Address Line 1" style={{ borderRadius: "5px" }}
                                />
                              </Form.Group>
                            </Col>
                            <Col className="mt-3" sm={12} md={6} lg={4}>
                              <Form.Group>
                                <Form.Label htmlFor="address2">Address Line 2</Form.Label>
                                <input type="text" value={orgLineTwo} onChange={(e) => setOrgLineTwo(e.target.value)} className="border border-primary w-100 p-2"
                                  placeholder="Address Line 2" style={{ borderRadius: "5px" }}
                                />
                              </Form.Group>
                            </Col>
                            {/* <Row> */}
                            <Col className="mt-3" sm={12} md={6} lg={4}>
                              <Form.Group style={{ position: 'relative' }}>
                                <Form.Label htmlFor="clientCountry">Select Country</Form.Label>
                                <Form.Control type="text" value={orgCountry} className="border border-primary w-100 p-2" placeholder="Select Country"
                                  onChange={handleInputChangeOrgCountry} onClick={() => setIsOpenOrgCountry(true)} autoComplete='off'
                                />
                                {isOpenOrgCountry && (
                                  <>
                                    <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                      {
                                        Orgcountries.length > 0 ? (
                                          filteredOrgCountryOptions.length > 0 ? (
                                            filteredOrgCountryOptions.map((option, i) => (
                                              <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleOptionClickOrgCountry(option.country_name)}>
                                                {option.country_name}
                                              </div>
                                            ))
                                          ) : (
                                            <div className="mt-2">No match found</div>
                                          )
                                        ) : (
                                          <div className="mt-2">Loading . . . . </div>
                                        )
                                      }
                                    </div>
                                  </>
                                )}
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="mt-3" sm={12} md={6} lg={4}>
                              <Form.Group style={{ position: 'relative' }}>
                                <Form.Label htmlFor="clientState">Select State</Form.Label>
                                <Form.Control type="text" value={orgState} placeholder="Select State" className="border border-primary w-100 p-2"
                                  onChange={handleInputChangeOrgState} onClick={() => setIsOpenOrgState(true)} autoComplete='off'
                                />
                                {isOpenOrgState && (
                                  <>
                                    <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                      {
                                        orgStates.length > 0 ? (
                                          filteredStateOptions.length > 0 ? (
                                            filteredStateOptions.map((option, i) => (
                                              <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleOptionClickOrgState(option.state_name)}>
                                                {option.state_name}
                                              </div>
                                            ))
                                          ) : (
                                            <div className="mt-2">No match found</div>
                                          )
                                        ) : (
                                          <div className="mt-2">Select country first </div>
                                        )
                                      }
                                    </div>
                                  </>
                                )}
                              </Form.Group>
                            </Col>
                            <Col className="mt-3" sm={12} md={6} lg={4}>
                              <Form.Group style={{ position: 'relative' }}>
                                <Form.Label htmlFor="clientCity">Select City</Form.Label>
                                <Form.Control type="text" value={orgCity} placeholder="Select City" className="border border-primary w-100 p-2"
                                  onChange={handleInputChangeOrgCity} onClick={() => setIsOpenOrgCity(true)} autoComplete='off'
                                />
                                {isOpenOrgCity && (
                                  <>
                                    <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                      {
                                        orgCities.length > 0 ? (
                                          filteredOrgCityOptions.length > 0 ? (
                                            filteredOrgCityOptions.map((option, i) => (
                                              <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleOptionClickOrgCity(option.city_name)}>
                                                {option.city_name}
                                              </div>
                                            ))
                                          ) : (
                                            <div className="mt-2">No match found</div>
                                          )
                                        ) : (
                                          <div className="mt-2">Select state first </div>
                                        )
                                      }
                                    </div>
                                  </>
                                )}
                              </Form.Group>
                            </Col>
                            <Col className="mt-3" sm={12} md={6} lg={4}>
                              <Form.Group>
                                <Form.Label htmlFor="pinCode">Pincode / Zipcode</Form.Label>
                                <input type="number" value={orgPinCode} onChange={handleOrgPinCode} className="border border-primary w-100 p-2"
                                  placeholder="Pincode / Zipcode" style={{ borderRadius: "5px" }}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </>
                      ) : null
                    }
                    <div className="mt-3 ms-0 p-0" style={{ cursor: disabledBtn ? 'not-allowed' : 'pointer' }}>
                      <Button type="submit" variant={disabledBtn ? 'secondary' : 'primary'} className="text-white fs-5 px-4" disabled={disabledBtn ? true : false} style={{ padding: '3px' }}>Update</Button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default UpdateLead;
