import { Tooltip } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { BsPrinterFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import ReactToPrint from "react-to-print";
import Preview from "./preview";

const InvoicePreview = ({ addedtax, show, setShow, form, firmDetails, firmAdd, clientDetails, clientAdd, items, currency, terms }) => {

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const breakPoint = 3000;
  const componentRef = useRef();

  useEffect(() => { 
    const handleResize = setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  });

  const dummyArray = [
    {
      itemName: "Item 1",
      quantity: 12,
      unitPrice: 12,
      description: "item 1 desc"
    },
    {
      itemName: "Item 2",
      quantity: 12,
      unitPrice: 12,
      description: "item 2 desc"
    },
    {
      itemName: "Item 3",
      quantity: 12,
      unitPrice: 12,
      description: "item 3 desc"
    },
    {
      itemName: "Item 4",
      quantity: 12,
      unitPrice: 12,
      description: "item 4 desc"
    },
    {
      itemName: "Item 5",
      quantity: 12,
      unitPrice: 12,
      description: "item 5 desc"
    },
    {
      itemName: "Item 6",
      quantity: 12,
      unitPrice: 12,
      description: "item 6 desc"
    },
    {
      itemName: "Item 7",
      quantity: 12,
      unitPrice: 12,
      description: "item 7 desc"
    },
    {
      itemName: "Item 8",
      quantity: 12,
      unitPrice: 12,
      description: "item 8 desc"
    },
  ]

  return (
    <>
      <Modal centered size={screenWidth < breakPoint ? 'lg' : 'xl'} show={show} onHide={() => setShow(false)}>
        {/* <Modal.Header>
        <ReactToPrint
          trigger={() => (
            <Row className="bg-primary rounded mx-3 py-2 ps-2 pe-3 my-2 text-white" style={{cursor: "pointer"}}>
              <Col sm={9}>Print Invoice</Col>
              <Col sm={1} className="text-end">
                <Tooltip title="Print">
                  <Link to="#" className="py-1 px-3 text-white rounded me-2">
                    <BsPrinterFill style={{ fontSize: "1rem", cursor: "pointer" }}/>
                  </Link>
                </Tooltip>
              </Col>
            </Row>
          )}
          content={() => componentRef.current}
        />
        </Modal.Header> */}
        <Modal.Body ref={componentRef}>
          <Preview addedtax={addedtax} form={form} firmDetails={firmDetails} firmAdd={firmAdd} clientDetails={clientDetails} clientAdd={clientAdd} items={items} currency={currency} terms={terms} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InvoicePreview;