import React, { useState } from 'react';
import LoaderSpiner from '../../components/loader/loader';

const useFullPageLoader = () => {
    const [loading, setLoading] = useState(false);
    return [
        loading ? <LoaderSpiner /> : null,
        () => setLoading(true),
        () => setLoading(false)
    ];
};
export default useFullPageLoader