//router
import IndexRouters from "./router/index";
import "bootstrap-icons/font/bootstrap-icons.css";
import './app.css';

//scss
import "./assets/scss/hope-ui.scss";
import "./assets/scss/dark.scss";
import "./assets/scss/rtl.scss";
import "./assets/scss/custom.scss";
import "./assets/scss/customizer.scss";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <div className="App h-100vh">
      <Toaster />
      <IndexRouters />
    </div>
  );
}

export default App;
