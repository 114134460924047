import React, { useState, useEffect } from "react";
import { Col, Row, Modal, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "../pagination/pagination";
import { MdDeleteOutline } from "react-icons/md";
import { Tooltip } from "@material-ui/core";

import About from "./about";
import SubHeader from "../../../../components/partials/dashboard/HeaderStyle/sub-header";
import ActivityBar from "../../../../components/partials/components/activitybar";
import { deleteClient, getAllClients, getClientById } from "../Hook/clientHooks";
import { CgProfile } from "react-icons/cg";
import { FiEye } from "react-icons/fi";
import { ErrorToast, SuccessToast, WarningToast } from "../../components/toast";
import { addRecentActivity, getRecentActivities } from "../Hook/recentActivityHooks";
import { getUserById } from "../Hook/userHooks";
import ContactAdmin from "../../components/contact-admin";

const ClientList = () => {
  const initData = Object.freeze({
    address1: "",
    address2: "",
    country: "",
    state: "",
    city: "",
    pinCode: "",
  });
  const [details, setDetails] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]);
  const [createPermission, setCreatePermission] = useState(true);
  const [deletePermission, setDeletePermission] = useState(true);
  const [clientName, setClientName] = useState('');
  const [allRecentActivities, setAllRecetActivities] = useState([]);
  const [arr, setArr] = useState([]);
  const [clientInfo, setClientInfo] = useState({ initData });
  const [showActivity, setShowActivity] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showAbout, setShowAbout] = useState(false);
  const [clientId, setClientId] = useState('')
  const [showDelete, setShowDelete] = useState(false);
  const [showContactAdmin, setShowContactAdmin] = useState(false);
  const breakPoint = 970;
  const orgName = localStorage.getItem("orgName");
  const userId = localStorage.getItem("userId");

  const datetimeString = new Date();
  const dateObject = new Date(datetimeString);

  const formattedDate = `${dateObject.getDate()}/${dateObject.getMonth() + 1}/${dateObject.getFullYear()}`;
  const formattedTime = dateObject.toLocaleTimeString();

  useEffect(() => {
    getUserById(userId).then((response) => {
      if (response.status === 200) setUserPermissions(response?.data?.data?.permissions);
      else setUserPermissions([]);
    }).catch(() => setUserPermissions([]));
  }, [userId]);

  useEffect(() => {
    const create = userPermissions.length > 0 && userPermissions.some((permission) => permission.module === 'client' && permission.action.includes('Create'));
    const deletePermit = userPermissions.length > 0 && userPermissions.some((permission) => permission.module === 'client' && permission.action.includes('Delete'));

    setCreatePermission(create);
    setDeletePermission(deletePermit);
  }, [userPermissions]);

  const getDetails = async () => {
    getAllClients().then((response) => {
      if (response.status === 200) {
        setDetails(response.data.data);
        getData(response.data.data[0]._id);
      } else {
        setDetails([]);
        getData('');
      }
    }).catch((err) => {
      err?.response?.data?.message && setShowContactAdmin(true);
      // err?.response?.data?.message && WarningToast(err?.response?.data?.message);
      setDetails([]);
      getData('');
    })
  };

  useEffect(() => {
    getAllClients().then((response) => {
      if (response.status === 200) {
        setDetails(response.data.data);
        getData(response.data.data[0]._id);
      } else {
        setDetails([]);
        getData('');
      }
    }).catch((err) => {
      err?.response?.data?.message && setShowContactAdmin(true);
      // err?.response?.data?.message && WarningToast(err?.response?.data?.message);
      setDetails([]);
      getData('');
    })
  }, []);

  const getAllRecentActivities = () => {
    getRecentActivities("client").then((response) => {
      response.status === 200 ? setAllRecetActivities(response?.data?.data) : setAllRecetActivities([]);
    }).catch(() => setAllRecetActivities([]));
  };

  useEffect(() => {
    getAllRecentActivities();
  }, []);


  const getData = async (id) => {
    getClientById(id).then((response) => {
      if (response.status === 200) {
        setArr(response.data.data);
        setClientInfo(response.data.data.add);
        return response.data;
      } else {
        setArr([]);
        setClientInfo({});
      }
    }).catch(() => {
      setArr([]);
      setClientInfo({});
    });
  };

  const handleDeleteClient = (e) => {
    setClientId(e);
    setShowDelete(true);
  };

  useEffect(() => {
    getClientById(clientId).then((response) => {
      if (response.status === 200) {
        const info = response.data.data;
        info?.clientFirmName ? setClientName(info?.clientFirmName) : setClientName(`${info?.firstName} ${info?.lastName}`);
      } else {
        setClientName('');
      }
    }).catch(() => setClientName(''));
  }, [clientId]);

  const handleDelete = () => {
    deleteClient(clientId).then((response) => {
      if (response.status === 200) {

        const activityForm = {
          module: ['client'],
          activity: 'Client Deleted',
          activityDesc: `Client ${clientName} Deleted`,
          createdDate: formattedDate,
          createdTime: formattedTime,
          userId: localStorage.getItem('userId')
        };

        addRecentActivity(activityForm).then((res) => res.status === 201 && getAllRecentActivities());

        SuccessToast("Client deleted");
        setShowDelete(false);
        getDetails();
      } else {
        ErrorToast("Unable to delete the client");
      }
    }).catch((err) => {
      err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast("Something went wrong");
      setShowDelete(false);
    })
  };

  const showPerPage = 10;
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  const handleRadio = (e) => {
    getData(e);
  };

  const options = () => {
    return (
      <>
      </>
    )
  }

  return (
    <div onClick={() => { showActivity === true && setShowActivity(false) }}>
      <ContactAdmin show={showContactAdmin} setShow={setShowContactAdmin} />
      {
        createPermission ? (
          <SubHeader title="Clients" btnTitle="Add Client" btnLink={`/${orgName}/dashboard/client/add-client`} />
        ) : (
          <SubHeader title="Clients" />
        )
      }
      <div>
        <div className="d-flex text-dark" style={{ marginTop: screenWidth >= 1200 ? "-1%" : "-3%", flexDirection: "row" }}>
          <Row sm={3} className="w-25 rounded mx-2" style={{ height: "100%", marginRight: "10%", fontSize: "0.8rem" }}>
            <Col sm={12} className="bg-white rounded">
              <Row className="py-2">
                <h5 className="text-primary" style={{ fontWeight: "550" }}> About </h5>
              </Row>
              <Row>
                <Col sm={12} style={{ textAlign: "center", height: "100%" }}>
                  <CgProfile className="text-secondary" style={{ height: "25%", width: "25%" }} />
                </Col>
                {
                  arr.clientFirmName ? (
                    <Col className="my-2 text-dark text-center" style={{ fontWeight: "550", fontSize: "1rem", textTransform: "uppercase" }}>
                      {arr === undefined || arr.clientFirmName === undefined ? '' : arr.clientFirmName}
                    </Col>
                  ) : (
                    <Col className="my-2 text-dark text-center" style={{ fontWeight: "550", fontSize: "1rem", textTransform: "uppercase" }}>
                      {arr === undefined || arr.firstName === undefined ? '' : arr.firstName} {arr === undefined || arr.lastName === undefined ? '' : arr.lastName}
                    </Col>
                  )
                }
              </Row>
            </Col>
            <Col sm={12} className="mt-2 py-2 rounded bg-white">
              <Row
                className="py-2"
              // style={{ borderBottom: "1px solid rgb(125 125 125)" }}
              >
                <h5 className="text-primary" style={{ fontWeight: "550" }}> Contact </h5>
              </Row>
              <Row className="py-1">
                <Col md={3} sm={12}>
                  <div style={{ fontWeight: "550" }}>Phone: </div>
                </Col>
                <Col>{arr.phone}</Col>
              </Row>
              <Row className="py-1">
                <Col md={3} sm={12}>
                  <div style={{ fontWeight: "550" }}>Email: </div>
                </Col>
                <Col>{arr.email}</Col>
              </Row>
              <Row className="py-1">
                <Col md={3} sm={12}>
                  <div style={{ fontWeight: "550" }}>Address: </div>
                </Col>
                <Col>{clientInfo.address1}</Col>
              </Row>
            </Col>
          </Row>
          <Row
            sm={7}
            className={`h-100 bg-white rounded mx-2 mb-5`}
            style={{ minWidth: screenWidth >= breakPoint ? "50%" : "50%", width: "70%", maxWidth: "80%" }}
          >
            <div className="table-responsive mt-2" style={{ minHeight: "50vh" }}>
              <table className="" style={{ width: "100%" }}>
                <thead>
                  <tr className="h-20 text-primary" style={{ borderBottom: "1px solid #a09fa3" }}>
                    <th className="fw-bold" style={{ fontSize: "0.9rem" }}></th>
                    <th className="fw-bold" style={{ fontSize: "0.9rem" }}>Name</th>
                    <th className="fw-bold" style={{ fontSize: "0.9rem" }}>Email</th>
                    <th className="fw-bold" style={{ fontSize: "0.9rem" }}>Phone</th>
                    <th className="fw-bold" style={{ textAlign: "right", fontSize: "0.9rem" }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {details.slice(pagination.start, pagination.end).map((data, index) => (
                    <tr style={{ borderBottom: "1px solid rgb(227 227 227)" }} key={index}>
                      <td className="py-3" style={{ width: "", height: "30px", borderBottom: "1px solid rgb(227 227 227)" }}>
                        <Form.Check.Input type="radio" name="radiobtn" className="" value={data._id} onChange={() => handleRadio(data._id)} defaultChecked={index === 0} style={{ cursor: "pointer" }} />
                      </td>
                      <td className="py-3" style={{ width: "30%", height: "30px" }}>
                        {
                          data.clientFirmName ? (
                            <span className="ms-0 text-dark">
                              {data.clientFirmName}
                            </span>
                          ) : (
                            <span className="ms-0 text-dark">
                              {data.firstName} {data.lastName}
                            </span>
                          )
                        }
                      </td>
                      <td className="w-5 py-3" style={{ width: "25%" }}>{data.email}</td>
                      <td className="w-5 py-3">{data.phone}</td>
                      <td className="py-3 text-end">
                        <div className="d-flex justify-content-end align-items-end">
                          <Tooltip title="View" key="view">
                            <Link to={`/${orgName}/dashboard/client/about-client/${data._id}`}>
                              <FiEye onClick={() => setShowAbout(true)} id="viewLead" className="text-primary mx-2" style={{ fontSize: "0.9rem", cursor: "pointer", }} />
                            </Link>
                          </Tooltip>
                          {
                            deletePermission ? (
                              <Tooltip title="Delete" key="delete">
                                <Link to='#'>
                                  <MdDeleteOutline id="delete" className="text-danger" onClick={() => handleDeleteClient(data._id)} style={{ fontSize: "0.9rem", cursor: "pointer", }} />
                                </Link>
                              </Tooltip>
                            ) : null
                          }
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {
                details.length === 0 && (
                  <div className='mt-5 fs-4 text-center text-secondary'>
                    No Clients To Show
                  </div>
                )
              }
            </div>
            <div className="my-5">
              <Pagination showPerPage={showPerPage} onPaginationChange={onPaginationChange} total={details.length} />
            </div>
          </Row>
          <ActivityBar options={options} list="list" allRecentActivities={allRecentActivities} />
        </div>
        <About title="About Client" showAbout={showAbout} setShowAbout={setShowAbout} phone={arr.phone} email={arr.email} name={`${arr.firstName} ${arr.lastName}`} address={clientInfo} />
        <Modal centered show={showDelete} onHide={() => setShowDelete(false)}>
          <Modal.Body className="my-2">
            <Row className="mb-2 ms-1">
              <Col sm={12} className=" text-center px-2 m-0 p-0 fs-3">Delete Client</Col>
              <Col sm={12} className="text-secondary text-center px-2 m-0 p-0">You want to delete the client ?</Col>
            </Row>
            <div className="ms-2 w-100" style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }} >
              <div
                className="py-1 px-5 me-2 bg-danger text-white text-center text-p border border-danger"
                onClick={() => handleDelete()}
                style={{ borderRadius: "4px", cursor: "pointer" }}
              >
                Yes
              </div>
              <div className="bg-white text-primary py-1 px-5 border border-primary text-center"
                onClick={() => setShowDelete(false)}
                style={{ borderRadius: "5px", cursor: "pointer" }}
              >
                No
              </div>
            </div>
          </Modal.Body>
        </Modal >
      </div >
    </div >
  );
};

export default ClientList;
