// import { Navigate } from "react-router-dom";
// import PageTitle from "../page-header/PageHeader";

import AddPublicHoliday from "./AddPublicHoliday";

const PublicHoliday = (props) => {
	const isLogged = Boolean(localStorage.getItem("isLogged"));

	// if (!isLogged) {
	// 	return <Navigate to={"/admin/auth/login"} replace={true} />;
	// }
	return (
		<div className="px-4">
			{/* <PageTitle title='Back' /> */}
			<AddPublicHoliday />
		</div>
	);
};

export default PublicHoliday;
