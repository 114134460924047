import {
	MailOutlined,
	PhoneOutlined,
	ThunderboltOutlined,
	TeamOutlined,
	TrophyOutlined,
	UserOutlined,
	CalendarOutlined,
	ClockCircleOutlined,
	HomeOutlined,
} from "@ant-design/icons";
import { Col, Row, Alert } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import tw from "tailwind-styled-components";
import {
	clearUser,
	deleteStaff,
	loadSingleStaff,
} from "../../../redux/rtk/features/user/userSlice";
import DesignationEditPopup from '../../../components/UI/PopUp/DesignationEditPopup';
// import Loader from "../loader/loader";
// import PageTitle from "../page-header/PageHeader";
// import BtnDeleteSvg from "../UI/Button/btnDeleteSvg";

// import EmployeeDesignation from "../UI/EmployeeDesignation";
// import EmployeeTimeline from "../UI/EmployeeTimeline";
// import DesignationEditPopup from "../UI/PopUp/DesignationEditPopup";
// import EducaitonEditPopup from "../UI/PopUp/EducaitonEditPopup";
// import ProfileEditPopup from "../UI/PopUp/ProfileEditPopup";

// import SalaryEditPopup from "../UI/PopUp/SalaryEditPopup";
// import EmployeeSalary from "../UI/EmployeeSalary";

// import EmployeeAward from "../UI/EmployeeAward";

// import AwardHistoryEditPopup from "../UI/PopUp/AwardHistoryEditPopup";
// import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import { loadAllDesignation } from "../../../redux/rtk/features/designation/designationSlice";
import BtnDeleteSvg from "../../../components/UI/Button/btnDeleteSvg";
import AntLoader from "../../../components/loader/AntLoader";
import EmployeeDesignation from "../../../components/UI/EmployeeDesignation";
import EducaitonEditPopup from "../../../components/UI/PopUp/EducaitonEditPopup";
import EmployeeTimeline from "../../../components/UI/EmployeeTimeline";
import SalaryEditPopup from "../../../components/UI/PopUp/SalaryEditPopup";
import AwardHistoryEditPopup from "../../../components/UI/PopUp/AwardHistoryEditPopup";
import EmployeeSalary from "../../../components/UI/EmployeeSalary";

//PopUp

const DetailStaff = () => {
	const { id } = useParams();
	let navigate = useHistory();

	//dispatch
	const dispatch = useDispatch();
	const user = useSelector((state) => state.users.user);
	const error = useSelector((state) => state.users.error);
	const designation = useSelector((state) => state.designations.list);


	const personalInfo = [
		{
			title: "Department",
			icon: <TeamOutlined className='mr-3' style={{ fontSize: "15px" }} />,
			desc: user?.department?.name
		},
		{
			title: "Employment Status",
			icon: <TrophyOutlined className='mr-3' style={{ fontSize: "15px" }} />,
			desc: user?.employmentStatus?.name
		},
		{
			title: "Join Date",
			icon: <CalendarOutlined className='mr-3' style={{ fontSize: "15px" }} />,
			desc: dayjs(user?.joinDate).format("DD/MM/YYYY")
		},
		{
			title: "Leave Date",
			icon: <UserOutlined className='mr-3' style={{ fontSize: "15px" }} />,
			desc: user?.leaveDate ? dayjs(user?.leaveDate).format("DD/MM/YYYY") : "PRESENT"
		},
		{
			title: "Role",
			icon: <UserOutlined className='mr-3' style={{ fontSize: "15px" }} />,
			desc: user?.role?.name || "No Role"
		},
		{
			title: "Leave Policy",
			icon: <CalendarOutlined className='mr-3' style={{ fontSize: "15px" }} />,
			desc: user?.leavePolicy?.name
		},
		{
			title: "Weekly Holiday",
			icon: <CalendarOutlined className='mr-3' style={{ fontSize: "15px" }} />,
			desc: user?.weeklyHoliday?.name
		},
		{
			title: "Department",
			icon: <TeamOutlined className='mr-3' style={{ fontSize: "15px" }} />,
			desc: user?.department?.name
		},
	];

	const contactInfo = [
		{
			title: 'Email',
			icon: <MailOutlined className='mr-3' style={{ fontSize: "15px" }} />,
			desc: user?.email || "No Email"
		},
		{
			title: 'Phone',
			icon: <PhoneOutlined className='mr-3' style={{ fontSize: "15px" }} />,
			desc: user?.phone || "No Phone"
		},
		{
			title: 'Address',
			icon: <HomeOutlined className='mr-3' style={{ fontSize: "15px" }} />,
			address: [
				{
					title: "Street",
					desc: user?.street || "No Address"
				},
				{
					title: "City",
					desc: user?.city || "No Address"
				},
				{
					title: "State",
					desc: user?.state || "No Address"
				},
				{
					title: "Country",
					desc: user?.country || "No Address"
				},
				{
					title: "Zip Code",
					desc: user?.zipCode || "No Address"
				}
			]
		},
		{
			title: "Blood Group",
			icon: <ThunderboltOutlined className='mr-3' style={{ fontSize: "15px" }} />,
			desc: user?.bloodGroup || "No Phone"
		}

	]
	//Delete Supplier
	const onDelete = () => {
		try {
			dispatch(deleteStaff(id));

			setVisible(false);
			toast.warning(`User Name : ${user.username} is removed `);
			return navigate("/admin/hr/staffs");
		} catch (error) {
			console.log(error.message);
		}
	};
	// Delete Supplier PopUp
	const [visible, setVisible] = useState(false);

	const handleVisibleChange = (newVisible) => {
		setVisible(newVisible);
	};

	useEffect(() => {
		dispatch(loadSingleStaff(id));
		return () => {
			dispatch(clearUser());
		};
	}, [id]);

	useEffect(() => {
		dispatch(loadAllDesignation());
	}, [dispatch]);

	const isLogged = Boolean(localStorage.getItem("isLogged"));

	// if (!isLogged) {
	// 	return <Navigate to={"/admin/auth/login"} replace={true} />;
	// }

	return (
		<div className="mx-4">
			{/* <UserPrivateComponent permission={"readSingle-user"}> */}
			{/* <PageTitle title=' Back  ' /> */}

			{/* {user ? ( */}
			<div className='mr-top'>
				<section>
					<div className='d-flex justify-content-between ant-card rounded h-auto mr-8'>
						{/* Profile Card */}

						<div className='flex justify-start'>
							{/*	<img
										src='https://cdn-icons-png.flaticon.com/512/2202/2202112.png'
										alt='profile'
										className='rounded-full h-40 w-40 m-5'
									/> */}
							<div class='d-flex justify-content-center align-items-center py-8 px-4 mt-4 ml-4'>
								<div class='flex flex-col items-around'>
									<h1 class='text-2xl font-bold txt-color-2 mb-2 fs-4 fw-bold'>
										{(user?.firstName + " " + user?.lastName).toUpperCase()}
									</h1>
									<h2 class='text-base font-medium txt-color-secondary mb-1 fs-5'>
										{user?.designationHistory[0]?.designation?.name ||
											"No Designation"}
									</h2>
									<h3 class='text-base font-medium txt-color-secondary my-2 fs-5'>
										{user?.department?.name || "No Department"}
									</h3>
									<h3 class='text-base font-medium txt-color-secondary fs-5'>
										{user?.employeeId || "No Employee ID"}
									</h3>
								</div>
							</div>
						</div>

						{/* Leave Status */}
						<div className=''>
							<div className='d-flex justify-content-center align-items-center py-8 px-4 mt-4'>
								<div className='flex flex-col items-around'>
									<h1 className='text-2xl font-bold txt-color-2 mb-2 fs-4 fw-bold'>
										Leave Status
									</h1>

									<h3 className='text-base font-medium txt-color-secondary fs-5 '>
										Leaves :{" "}
										<span className='text-base font-medium txt-color-2 fs-5'>
											Paid - {user?.leavePolicy?.paidLeaveCount} | U.Paid -{" "}
											{user?.leavePolicy?.unpaidLeaveCount}
										</span>
									</h3>
									<h3 className='text-base font-medium txt-color-secondary my-2 fs-5'>
										Taken :{" "}
										<span className='text-base font-medium txt-color-2 fs-5'>
											Paid -{" "}
											{user?.leavePolicy?.paidLeaveCount -
												user?.leftPaidLeaveDays}{" "}
											| U.Paid -{" "}
											{user?.leavePolicy?.unpaidLeaveCount -
												user?.leftUnpaidLeaveDays}
										</span>
									</h3>
									<h3 className='text-base font-medium txt-color-secondary fs-5'>
										Balance :{" "}
										<span className='text-base font-medium txt-color-2 fs-5'>
											Paid - {user?.leftPaidLeaveDays} | U.Paid -{" "}
											{user?.leftUnpaidLeaveDays}
										</span>
									</h3>
								</div>
							</div>
						</div>

						{/*Clock In and Out Time*/}

						<div className='d-flex justify-content-center align-items-center py-8 px-4 mt-4'>
							<div className='flex flex-col items-around'>
								<h1 className='text-2xl font-bold txt-color-2 mb-2 fs-4 fw-bold'>
									Clock In & Out
								</h1>

								<h3 className='text-base font-medium txt-color-secondary my-2 fs-5'>
									In Time :{" "}
									<span className='text-base font-medium txt-color-2 fs-5'>
										{dayjs(user?.attendance.inTime).format("hh:mm A")}
									</span>
								</h3>

								<h3 className='text-base font-medium txt-color-secondary fs-5'>
									Out Time :{" "}
									<span className='text-base font-medium txt-color-2 fs-5'>
										{dayjs(user?.attendance.outTime).format("hh:mm A")}
									</span>
								</h3>
							</div>
						</div>

						{/* Action Buttons */}

						<div className=''>
							<div className='d-flex justify-content-center align-items-center py-5 px-4 me-4'>
								{/* <UserPrivateComponent permission='update-user'> */}
								{/* <button className='mr-2 mt-2'>
									{user && <ProfileEditPopup data={user} />}
								</button> */}
								{/* </UserPrivateComponent> */}
								{/* <UserPrivateComponent permission='delete-user'> */}
								<button onClick={onDelete} style={{ border: "none", background: "#fff" }}>
									<BtnDeleteSvg size={30} />
								</button>
								{/* </UserPrivateComponent> */}
							</div>
						</div>
					</div>
				</section>
				<Row
					className='mt-5'
					gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 32 }}>
					<Col xs={24} sm={12} md={12} lg={11} xl={11} className='new-card rounded h-auto m-2'>
						<ProfileCardText className='text-start'> Personal Information </ProfileCardText>

						<Hr />

						<div className='px-0'>
							<ul className='m-0 p-0' style={{ listStyle: "none" }}>
								{
									personalInfo.map((data, index) => (
										<li className='py-2' key={index}>
											{data.icon}
											<span className='d-inline txt-color-2 font-medium'>{data.title}:</span>
											<p className='d-inline ps-2 txt-color-secondary ml-2'>{data.desc}</p>
										</li>
									))
								}
								<li className='flex items-center'>
									<ClockCircleOutlined className='mr-3' style={{ fontSize: "15px" }} />
									<span className='d-inline txt-color-2 font-medium'>Shift :</span>
									<p className='d-inline ps-2 txt-color-secondary ml-2'>
										{user?.shift?.name || "No Shift"} (
										{dayjs(user?.shift?.startTime).format("LT")} -{" "}
										{dayjs(user?.shift?.endTime).format("LT")})
									</p>
								</li>
							</ul>
						</div>
					</Col>
					<Col xs={24} sm={12} md={12} lg={12} xl={12} className='new-card rounded h-auto m-2'>
						<ProfileCardText className='text-start'> Contact Information </ProfileCardText>

						<Hr />
						<div className=''>
							<ul className='px-0' style={{ listStyleType: "none" }}>
								{
									contactInfo.map((data, index) => (
										<li className='flex items-center py-2' key={index}>
											{data.icon}
											<span className='d-inline txt-color-2 font-medium'>{data.title}:</span>
											<p className='d-inline ps-2 txt-color-secondary  ml-2'>
												{data.desc === undefined ? '' : data.desc}
											</p>
											{
												data.address ? (
													<div className="ps-5">
														{data.address.map((addr, i) => (
															<li className='ps-5 txt-color-secondary ml-2' key={i}>
																{addr.title} : <span className="m-0 ps-2">{addr.desc}</span>
															</li>
														))}
													</div>
												) : null
											}
										</li>
									))
								}
							</ul>
						</div>
					</Col>
					<Col xs={24} sm={12} md={12} lg={11} xl={11} className='new-card rounded h-auto m-2'>
						<div className='d-flex justify-content-between align-items-center'>
							<ProfileCardText className='text-start'> Designation History </ProfileCardText>

							{/* <UserPrivateComponent */}
							{/* permission={"update-designationHistory"}> */}
							{user?.designationHistory && (
								<DesignationEditPopup data={user?.designationHistory} />
							)}
							{/* </UserPrivateComponent> */}
						</div>
						<Hr />
						<div className='flex justify-start ml-10'>
							{user?.designationHistory.length !== 0 ? (
								// <></>
								<EmployeeDesignation list={user?.designationHistory} />
							) : (
								<div className='mb-10'>
									<p className='text-center mt-3 mb-3'>
										No Designation History Found
									</p>
									<Alert
										message='Not found , Click on edit button to add new'
										type='info'
										showIcon
									/>
								</div>
							)}
						</div>
					</Col>

					<Col xs={24} sm={12} md={12} lg={11} xl={12} className='new-card rounded h-auto m-2 '>
						<div className='d-flex justify-content-between align-items-center'>
							<ProfileCardText className='text-start'> Educaiton History </ProfileCardText>
							{/* <UserPrivateComponent permission={"update-education"}> */}
							{user?.educations && (
								<EducaitonEditPopup data={user?.educations} />
							)}
							{/* </UserPrivateComponent> */}
						</div>
						<Hr />
						<div className='flex justify-start ml-10'>
							{user?.educations.length !== 0 ? (
								// <></>
								<EmployeeTimeline list={user?.educations} />
							) : (
								<div className='mb-10'>
									<p className='text-center mt-3 mb-3'>
										No Education History Found
									</p>
									<Alert
										message='Not found , Click on edit button to add new'
										type='info'
										showIcon
									/>
								</div>
							)}
						</div>
					</Col>

					<Col xs={24} sm={12} md={12} lg={11} xl={11} className='new-card rounded h-auto m-2 '>
						<div className='d-flex justify-content-between align-items-center'>
							<ProfileCardText className='text-start'> Salary History </ProfileCardText>
							{/* <UserPrivateComponent permission={"update-salaryHistory"}> */}
							{user?.salaryHistory && (
								<SalaryEditPopup data={user?.salaryHistory} />
							)}
							{/* </UserPrivateComponent> */}
						</div>
						<Hr />
						<div className='flex justify-start ml-10'>
							{user?.salaryHistory.length !== 0 ? (
								<EmployeeSalary list={user?.salaryHistory} />
							) : (
								<div className='mb-10'>
									<p className='text-center mt-3 mb-3 '> No Education History Found </p>
									<Alert message='Not found , Click on edit button to add new' type='info' showIcon />
								</div>
							)}
						</div>
					</Col>

					<Col xs={24} sm={12} md={12} lg={11} xl={12} className='new-card rounded h-auto m-2 '>
						<div className='d-flex justify-content-between align-items-center'>
							<ProfileCardText className='text-start'> Award History </ProfileCardText>

							{/* <UserPrivateComponent permission={"update-awardHistory"}> */}
							{user?.awardHistory && (
								<AwardHistoryEditPopup data={user?.awardHistory} />
							)}
							{/* </UserPrivateComponent> */}
						</div>
						<Hr />
						<div className='flex justify-start ml-10'>
							{user?.awardHistory.length !== 0 ? (
								<></>
								// <EmployeeAward list={user?.awardHistory} />
							) : (
								<div className='mb-10'>
									<p className='text-center mt-3 mb-3 '>
										No Award History Found
									</p>
									<Alert
										message='Not found , Click on edit button to add new'
										type='info'
										showIcon
									/>
								</div>
							)}
						</div>
					</Col>
				</Row>
			</div>
			{/* ) : ( */}
			<div className='mt-10'>
				<AntLoader />
				{/* <Loader /> */}
				{error && <p className='text-center mt-3 mb-3'>No Data Found</p>}
			</div>
			{/* )} */}
			{/* </UserPrivateComponent> */}
		</div>
	);
};

const ProfileCardText = tw.div`txt-color-2 text-xl fs-4 text-center mt-5 `;

const Hr = tw.hr`mt-3 mb-3 new-hr`;

const Information = tw.div`m-5`;

export default DetailStaff;
