import axios from "axios";

const apiKey = "anAf5U0S1BVAbRh2nHmV57euPe7bQ05B";
// const testApiKey1 = "DYXKH6KEHDaFCXheoFHNalAUVXKuZKRe" //test api key
const testApiKey2 = "ogBO06FwbhdJI4EjQ471BcMLPtxg1IL3"   //test api key

// export const ExchangeRates = (to, from) => {
//     var myHeaders = new Headers();
//     myHeaders.append("apikey", "DYXKH6KEHDaFCXheoFHNalAUVXKuZKRe");

//     var requestOptions = {
//         method: 'GET',
//         redirect: 'follow',
//         headers: myHeaders
//     };

//     fetch(`https://api.apilayer.com/exchangerates_data/convert?to=INR&from=USD&amount=1`, requestOptions)
//         .then((response) => {
//             response.json();
//             console.log("response text", response);
//         })
//         .then((result) => {
//             console.log(result);
//             console.log("result", result);
//         })
//         .catch(error => console.log('error', error));
// }

// export const ExchangeRates = async (to, from) => {
//     console.log("from", from, 'to', to);
//     const url = `https://api.apilayer.com/exchangerates_data/convert?to=${to}&from=${from}&amount=1`;
//     const config = {
//         method: "GET",
//         url: url,
//         headers: {
//             "Content-Type": "application/json",
//             apiKey: "anAf5U0S1BVAbRh2nHmV57euPe7bQ05B"
//         }
//     }
//     const response = await axios(config);
//     return response;
// }

export const ExchangeRates = async (from) => {
    // console.log("from", from, 'to', to);
    const url = `https://v6.exchangerate-api.com/v6/4956c224144e81b419b7705d/pair/${from}/INR`;
    const config = {
        method: "GET",
        url: url,
        headers: { "Content-Type": "application/json", }
    }
    const response = await axios(config);
    return response;
}