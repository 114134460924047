import React, { useState, useEffect } from "react";
import { Row, Col, Container} from "react-bootstrap";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
//img
import topHeader from "../../../../assets/images/dashboard/top-header.png";
import topHeader1 from "../../../../assets/images/dashboard/top-header1.png";
import topHeader2 from "../../../../assets/images/dashboard/top-header2.png";
import topHeader3 from "../../../../assets/images/dashboard/top-header3.png";
import topHeader4 from "../../../../assets/images/dashboard/top-header4.png";
import topHeader5 from "../../../../assets/images/dashboard/top-header5.png";

// store
import {
  NavbarstyleAction,
  getDirMode,
  SchemeDirAction,
  getNavbarStyleMode,
  getSidebarActiveMode,
  SidebarActiveStyleAction,
  getDarkMode,
  ModeAction,
  SidebarColorAction,
  getSidebarColorMode,
  getSidebarTypeMode,
} from "../../../../store/setting/setting";
import { connect } from "react-redux";

const orgName = localStorage.getItem('orgName');

const mapStateToProps = (state) => {
  return {
    darkMode: getDarkMode(state),
    schemeDirMode: getDirMode(state),
    sidebarcolorMode: getSidebarColorMode(state),
    sidebarTypeMode: getSidebarTypeMode(state),
    sidebaractivestyleMode: getSidebarActiveMode(state),
    navbarstylemode: getNavbarStyleMode(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      ModeAction,
      SchemeDirAction,
      SidebarColorAction,
      SidebarActiveStyleAction,
      NavbarstyleAction,
    },
    dispatch
  ),
});

const SubHeader = (props) => {
  //   const screenWidth = window.innerWidth;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    // navbarstylemode
    const navbarstyleMode = sessionStorage.getItem("Navbarstyle-mode");
    props.NavbarstyleAction(navbarstyleMode);
  });

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div
        className="iq-navbar-header"
        style={{ maxHeight: "215px" }}
      >
        <Container
          // fluid
          className="mx-2"
          style={{
            position: "absolute",
            // height: "100px",
            // maxHeight: "100px",
            width: "100vw",
            minWidth: "95vw",
            // marginTop: screenWidth >= 1200 ? "-1%" : "-3%",
            marginTop: "3rem"
            // marginTop: "-2%",
          }}
        >
          <Row className="me-3">
            <Col md="12">
              <div className="d-flex justify-content-between flex-wrap">
                <div>
                  <h1 className="text-white">{props.title}</h1>
                </div>
                <div
                  className="align-items-center"
                  style={{
                    display: !props.btnLink && "none",
                  }}
                >
                  {
                    props.title === 'Taxes' ? (
                      <Link to={"#"} onClick={() => props.setShowAdd(true)} className="btn btn-add">
                        {props.btnTitle}
                      </Link>
                    ) : (
                      <Link to={props.btnLink} className="btn btn-add">
                        {props.btnTitle}
                      </Link>
                    )
                  }
                </div>
              </div>
            </Col>
          </Row>
          {/* {props.handleSelected && (
            <Row>
              <Col sm={3}>
                <select
                  onChange={props.handleSelected}
                  className="py-2 px-2 rounded border border-primary"
                >
                  <option>All</option>
                  <option>Hold</option>
                  <option>Lost</option>
                  <option>Deleted</option>
                </select>
              </Col>
            </Row>
          )} */}
        </Container>
        {/* {{!-- rounded-bottom if not using animation --}} */}
        <div>
          <img
            src={topHeader}
            alt="header"
            className="theme-color-default-img"
            style={{ height: "180px", minWidth: "100%", maxWidth: "100%" }}
          />
          <img
            src={topHeader1}
            alt="header"
            className=" theme-color-purple-img"
            style={{ height: "180px", minWidth: "100%", maxWidth: "100%" }}
          />
          <img
            src={topHeader2}
            alt="header"
            className="theme-color-blue-img"
            style={{ height: "180px", minWidth: "100%", maxWidth: "100%" }}
          />
          <img
            src={topHeader3}
            alt="header"
            className="theme-color-green-img"
            style={{ height: "180px", minWidth: "100%", maxWidth: "100%" }}
          />
          <img
            src={topHeader4}
            alt="header"
            className="theme-color-yellow-img"
            style={{ height: "180px", minWidth: "100%", maxWidth: "100%" }}
          />
          <img
            src={topHeader5}
            alt="header"
            className="theme-color-pink-img"
            style={{ height: "180px", minWidth: "100%", maxWidth: "100%" }}
          />
        </div>
      </div >
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SubHeader);
