import React, { useState, useEffect } from "react";
import { Row, Col, Form, Modal } from "react-bootstrap";
import { MdAdd, MdAddCircle } from "react-icons/md";
import { Link, useParams, useHistory } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import { toast } from 'react-hot-toast';
import SubHeader from "../../../../components/partials/dashboard/HeaderStyle/sub-header";
import InvoicePreview from "./invoice-preview";
import ActivityBar from "../../../../components/partials/components/activitybar";
import { FiEye } from "react-icons/fi";
import { getAllClients } from "../Hook/clientHooks";
import { getAllFirms } from "../Hook/firmHooks";
import TaxModal from "./tax-modal";
import './styles/invoice.css';
import RecurringModal from "./recurring-modal";
import { getAllInvoices, getInvoiceById, getLastInvoiceNumber, updateDraft, updateInvoice } from "../Hook/invoiceHooks";
import { getAllTaxes, getGlobalTaxes } from "../Hook/taxHooks";
import axios from "axios";
import { API } from "../../../../backend";
import Notification from "../notification/notification";
import Addclientmodal from "./addclient-modal";
import Addfirmmodal from "./addfirm-modal";
import { getAllProducts } from "../Hook/productHooks";
import CreateProducts from "../products/create-products";
import { AiOutlineDelete } from "react-icons/ai";
import './styles/invoice.css';
import { ErrorToast, SuccessToast, WarningToast } from "../../components/toast";
import useFullPageLoader from "../Hook/useFullPageLoader";

const orgId = localStorage.getItem("orgID");
const orgName = localStorage.getItem("orgName");

const EditInvoice = () => {
    const history = useHistory();

    const allCurrencies = [
        {
            name: "INR",
            value: "₹",
        },
        {
            name: "USD",
            value: "$",
        },
        {
            name: "EUR",
            value: "€",
        },
        {
            name: "AUD",
            value: "$",
        },
        {
            name: "CAD",
            value: "$",
        },
    ];

    // const navigate = useNavigate();

    const [form, setForm] = useState({});
    const [allProducts, setAllProducts] = useState([]);
    const [items, setitems] = useState([
        {
            // id: Date.now().toString(),
            itemName: "",
            desc: "",
            taxRate: null,
            discount: 0,
            hsn: '',
            sac: '',
            baseprice: null,
            amount: 0,
            quantity: null,
            unitPrice: null,
        }
    ]);

    const [curr, setCurr] = useState("₹");
    const [curConvert, setCurConvert] = useState(0);
    const [client, setClient] = useState([]);
    const [allClients, setAllClients] = useState({});
    const [recurring, setRecurring] = useState(false);
    const [showTaxNDiscount, setShowTaxNDiscount] = useState(false);
    const [showCreateProducts, setShowCreateProducts] = useState(false);
    const [showRecurring, setShowRecurring] = useState(false);
    const [showDraft, setShowDraft] = useState(false);
    const [showInvoice, setShowInvoice] = useState(false);
    const [draft, setDraft] = useState(false);
    const [details, setDetails] = useState([]);
    const [newState, setNewState] = useState([]);

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const breakPoint = 970;

    const [invoice, setData] = useState([]);
    const [remark, setRemark] = useState('');
    const [note, setNote] = useState('');
    const [show, setShow] = useState(false);

    const [showSelect, setShowSelect] = useState(true);
    const [showTaxColumn, setShowTaxColumn] = useState(false);
    const [showDiscountColumn, setShowDiscountColumn] = useState(false);
    const [showHSNColumn, setShowHSNColumn] = useState();
    const [showSACColumn, setShowSACColumn] = useState();
    const [includeTax, setIncludeTax] = useState(false);
    const [isOpenItems, setIsOpenItems] = useState(false);
    const [showTax, setShowTax] = useState(false);
    const [partialPay, setPartialPay] = useState(false);

    const [singleClient, setSingleClient] = useState([]);
    const [clientAdd, setClientAdd] = useState([]);
    const [clientDetails, setClientDetails] = useState({});

    const [allFirms, setAllFirms] = useState([]);
    const [singleFirm, setSingleFirm] = useState([]);
    const [firmAdd, setFirmAdd] = useState({});
    const [firstFirm, setFirstFirm] = useState('');
    const [firmId, setFirmId] = useState('');
    const [selectedFirmId, setSelectedFirmId] = useState('');
    const [firmDetails, setFirmDetails] = useState({});
    const [validateFirm, setValidateFirm] = useState(false);
    const [taxApplied, setTaxApplied] = useState(false);
    const [terms, setTerms] = useState([]);
    const [firmTaxes, setFirmTaxes] = useState([]);
    const [globaltaxes, setAllGlobalTaxes] = useState([]);
    const [taxName, setTaxName] = useState("");
    const [addedtax, setAddedTax] = useState([]);
    const [allTaxes, setAllTaxes] = useState([]);
    const [taxAmt, setTaxAmt] = useState([]);
    const [createdDate, setCreatedDate] = useState();
    const [endDate, setEndDate] = useState();
    const selectedName = (Object.keys(newState))
    const selectedRate = (Object.values(newState));
    const [showClient, setShowClient] = useState(false);
    const [showFirm, setShowFirm] = useState(false);
    const [currentInvoiceNumber, setCurrentInvoiceNumber] = useState('');
    const [invoicePrefix, setInvoicePrefix] = useState('');
    const [lastInvoice, setLastInvoice] = useState('');
    const [newInvoNumber, setNewInvoNumber] = useState(0);
    const [invoiceNumber, setinvoiceNumber] = useState('');
    const splittedNumber = currentInvoiceNumber.split('-');
    const [defaultValue, setDefaultValue] = useState(splittedNumber);
    const [allInvoiceNumbers, setAllInvoiceNumbers] = useState([]);
    const [editedInvoiceNumber, setEditedInvoiceNumber] = useState(0);
    const [finalNo, setFinalNo] = useState('');

    const [selectedItem, setSelectedItem] = useState('');
    const [item, setItem] = useState('User');

    const [loader, showLoader, hideLoader] = useFullPageLoader();

    let filteredItemsOptions = allProducts.filter((option) =>
        (option.name).toLowerCase().includes(item.toLowerCase())
    );

    const { id } = useParams();
    const { editId } = useParams();
    const [itemTaxrate, setitemtaxrate] = useState(0);
    const [allRates, setAllRates] = useState(0);

    const [invoiceId, setInvoiceId] = useState("");
    const [notificationAdded, setNotificationAdded] = useState(['']);
    const [load, setLoad] = useState(false);

    const [taxRates, setTaxRates] = useState(0);
    const [dropIndex, setDropIndex] = useState(null);

    const userToken = localStorage.getItem('user_token');

    const subTotal = items && items.reduce((prev, curr) => prev + curr.quantity * curr.unitPrice, 0);

    useEffect(() => {
        const test = items.reduce((prev, curr) => prev + parseFloat(curr.taxRate), 0);
        testArr.push(test);
        setitemtaxrate(test);
        setAllRates(taxRates + test);
    }, [taxRates, items]);

    let discountedPrice = items.reduce((prev, curr) => prev +
        parseFloat(
            parseFloat(parseFloat(
                includeTax === true
                    ? (curr.quantity * curr.unitPrice)
                    : (parseFloat(curr.quantity * curr.unitPrice) + (parseFloat(curr.quantity * curr.unitPrice) * ((curr.taxRate ? parseFloat(curr.taxRate) : 0) / 100))).toFixed(2)
            )
                - (parseFloat(
                    includeTax === true
                        ? (curr.quantity * curr.unitPrice)
                        : (parseFloat(curr.quantity * curr.unitPrice) + (parseFloat(curr.quantity * curr.unitPrice) * ((curr.taxRate ? parseFloat(curr.taxRate) : 0) / 100))).toFixed(2)
                ) * ((curr.discount ? parseFloat(curr.discount) : 0) / 100)))
        ), 0);

    let basePrice = form.incluTax === true ? (discountedPrice) / (1 + (taxRates / 100)) : (discountedPrice);

    const taxTotal = addedtax.reduce((acc, tax) => parseFloat((acc + (basePrice * Object.values(tax)) / 100).toString().match(/^-?\d+(?:\.\d{0,2})?/)), 0);

    let tax = form.incluTax === true ? subTotal * (taxRates / 100) : subTotal * (taxRates / 100);

    let total = form.total;
    let roundoff = 0;
    let roundOperation = '';
    let grandTotal = 0;

    let itemstaxTotal = 0;
    if (form.incluTax === true) {
        itemstaxTotal = items.reduce((prev, curr) => prev + (parseFloat(curr.taxRate) / 100) * parseFloat((parseFloat(curr.quantity) * parseFloat(curr.unitPrice)) / (1 + (parseFloat(curr.taxRate) / 100))), 0);
        total = basePrice + itemstaxTotal + taxTotal + (basePrice - discountedPrice);
        grandTotal = Math.round(basePrice + itemstaxTotal + taxTotal + (basePrice - discountedPrice));
        roundoff = grandTotal > total
            ? grandTotal - parseFloat(total.toFixed(2))
            : parseFloat(total.toFixed(2)) - grandTotal
        roundOperation = grandTotal >= parseFloat(total.toFixed(2)) ? '' : '-';
    } else {
        itemstaxTotal = items.reduce((prev, curr) => prev + ((((curr.quantity * curr.unitPrice) * (curr.taxRate ? parseFloat(curr.taxRate) : 0)) / 100)), 0);
        total = discountedPrice + taxTotal + itemstaxTotal + (basePrice - discountedPrice);
        grandTotal = Math.round(basePrice + itemstaxTotal + taxTotal + (basePrice - discountedPrice));
        roundoff = grandTotal > total
            ? grandTotal - parseFloat(total.toFixed(2))
            : parseFloat(total.toFixed(2)) - grandTotal
        roundOperation = grandTotal >= parseFloat(total.toFixed(2)) ? '' : '-';
    }

    const calculateTax = () => {
        setTaxApplied(true)
        setShowTax(true)
    };

    const DiscountKeyExists = (key) => {
        const testVar = items.filter((data) => data.discount);
        testVar.length > 0 ? setShowDiscountColumn(true) : setShowDiscountColumn(false);
    };

    useEffect(() => {
        DiscountKeyExists();
    });

    useEffect(() => {
        endDate < createdDate && ErrorToast("Due date can not be before of Invoice date");
    }, [endDate]);

    useEffect(() => {
        firmId === '' && setInvoicePrefix(splittedNumber[0]);
        firmId === '' && setEditedInvoiceNumber(splittedNumber[1]);
    });

    useEffect(() => {
        const arr1 = [];
        addedtax.map((data, index) => {
            const testArr = data;
            arr1.push({ [`${Object.keys(testArr)}${Object.values(testArr)}`]: ((Object.values(testArr) / 100) * subTotal).toFixed(2) });
            setTaxAmt(arr1);
        });
    }, [addedtax, items, form]);

    useEffect(() => {
        getAllProducts(selectedFirmId).then((response) => {
            if (response.status === 200) {
                setAllProducts(response.data.data);
            } else {
                setAllProducts([]);
            }
        }).catch((err) => {
            setAllProducts([]);
        })
    }, [selectedFirmId]);

    useEffect(() => {
        const testVar = addedtax.reduce((prev, curr) => prev + parseFloat(Object.values(curr)), 0);
        setTaxRates(testVar);
    }, [addedtax]);

    useEffect(() => {
        if (lastInvoice === 0) {
            setNewInvoNumber(lastInvoice);
        }
        else {
            setNewInvoNumber(lastInvoice.split('-'));
        }
    }, [lastInvoice]);

    useEffect(() => {
        newInvoNumber === 0 ? setinvoiceNumber(`${invoicePrefix}-${lastInvoice + 1}`) : setinvoiceNumber(`${newInvoNumber[0]}-${parseInt(newInvoNumber[1]) + 1}`)
    });

    useEffect(() => {
        parseInt(splittedNumber[1]) > 0 && setDefaultValue(parseInt(splittedNumber[1]))
    });

    useEffect(() => {
        newInvoNumber === 0 ? setDefaultValue(lastInvoice + 1) : setDefaultValue(parseInt(newInvoNumber[1]) + 1)
    });

    useEffect(() => {
        setEditedInvoiceNumber(defaultValue)
    }, [invoiceNumber]);

    // useEffect(() => {
    //     let filteredItem = [];
    //     filteredItemsOptions.length > 0 ? filteredItem = allProducts.filter((data, index) => data.name === filteredItemsOptions[0].name) : filteredItem = [];
    //     setSelectedItem((filteredItemsOptions.length === 0 || filteredItem[0].name === undefined) ? '' : filteredItem[0].name)
    //     setPrice(filteredItemsOptions.length === 0 || filteredItem[0].price === undefined ? '' : filteredItem[0].price);
    //     setTaxRate(filteredItemsOptions.length === 0 || filteredItem[0].tax === undefined || !showTaxColumn ? 0 : filteredItem[0].tax);
    //     setHsn(filteredItemsOptions.length === 0 || filteredItem[0].hsn === undefined ? '' : filteredItem[0].hsn);
    //     setSac(filteredItemsOptions.length === 0 || filteredItem[0].sac === undefined ? '' : filteredItem[0].sac);
    //     setDescription(filteredItemsOptions.length === 0 || filteredItem[0].desc === undefined ? '' : filteredItem[0].desc);
    // }, [selectedItem]);

    // useEffect(() => {
    //     let filteredItem = [];
    //     filteredItemsOptions.length > 0 ? filteredItem = allProducts.filter((data, index) => data.name === filteredItemsOptions[0].name) : filteredItem = [];
    //     setTaxRate(filteredItemsOptions.length === 0 || filteredItem[0].tax === undefined || !showTaxColumn ? 0 : filteredItem[0].tax);
    // }, [showTaxColumn]);

    // useEffect(() => {
    //     item.length === 0 && setItem(items.itemName);
    //     price.length === 0 && setPrice(items.unitPrice);
    //     quantity.length === 0 && setQuantity(items.quantity);
    //     sac === '' && setSac(items.sac);
    //     hsn === '' && setHsn(items.hsn);
    //     description.length === 0 && setDescription(items.desc);
    // }, []); 

    const handleInputChangeItem = (e) => {
        setItem(e.target.value);
        if (!selectedFirmId) {
            ErrorToast("Select Firm");
            setValidateFirm(true);
            setItem('');
        } else {
            setValidateFirm(false);
        }
    };

    const handleOptionClickItem = (i, index, Arrindex) => {
        const Obj = allProducts.filter((data, ind) => ind === index).map((data) => { return data })
        const ObjData = Obj[0];
        const Arr = items.map((data, inde) => {
            if (inde === Arrindex) {
                return { ...data, itemName: ObjData.name, unitPrice: ObjData.price, hsn: ObjData.hsn ? ObjData.hsn : '', sac: ObjData.sac ? ObjData.sac : '', taxRate: ObjData.tax, desc: ObjData.desc }
            }
            else {
                return data
            }
        })
        setitems(Arr);
        setSelectedItem(i);
        setItem(i);
        setIsOpenItems(false);
    };

    const dropdownhandleIndex = (id) => {
        setDropIndex(id);
        setIsOpenItems(true);
    }

    const handleShowProduct = () => {
        if (selectedFirmId.length === 0) {
            window.scrollTo(0, 0);
            setValidateFirm(true);
            ErrorToast("Select Firm");
        } else {
            setShowCreateProducts(true);
            setIsOpenItems(true);
        }
    }

    const handleChangeInvoiceNumber = (e) => {
        setEditedInvoiceNumber(e.target.value);
        const matched = allInvoiceNumbers.filter((data) => data === `${invoicePrefix}-${e.target.value}`);
        matched.length > 0 && ErrorToast("Invoice no. already exist", {
            duration: 700
        });
        setFinalNo(matched.length > 0 ? matched : `${invoicePrefix}-${e.target.value}`);
    };

    const handleKeyDown = (event) => {
        if (
            editedInvoiceNumber === defaultValue &&
            (event.keyCode === 8 || event.keyCode === 46)
        ) {
            setEditedInvoiceNumber("");
        }
    };

    // useEffect(() => {
    //     getSingleFirm(selectedFirmId);
    // }, []);

    useEffect(() => {
        setinvoiceNumber(invoice.invoiceNumber)
    }, [firmDetails]);


    const [taxValues, setTaxValues] = useState([]);

    const invoiceDetails = () => {
        getInvoiceById(id).then((response) => {
            if (response.status === 200) {
                setTimeout(() => {
                    setLoad(false);
                    // setSelectedFirmId(response.data.data[0].firm.firmID);
                    setData(response.data.data[0]);
                    setFirmDetails(response.data.data[0].firm);
                    setSelectedFirmId(response.data.data[0].firm.firmID);
                    setFirmAdd(response.data.data[0].firm.address);
                    setClientDetails(response.data.data[0].client);
                    setClientAdd(response.data.data[0].client.address);
                    setAddedTax(response.data.data[0].tax);
                    setitems(response.data.data[0].items);
                    setTerms(response.data.data[0].termsNcondition);
                    setCurrentInvoiceNumber(response.data.data[0].invoiceNumber);
                }, 2000);
            } else {
                setData({});
            }
        }).catch(() => {
            setData({});
        })
    };

    useEffect(() => {
        invoiceDetails();
    }, []);

    // let testHSN;
    // let testSAC;

    // useEffect(() => {
    // }, [invoice]);

    useEffect(() => {
        setRemark(invoice.remark);
        setNote(invoice.notes);
        setCreatedDate(invoice.invoiceDate);
        setEndDate(invoice.dueDate);
        setIncludeTax(invoice.incluTax);

    }, [invoice]);

    useEffect(() => {
        const testHSN = items.filter((data) => (data.hsn != undefined) && ((data.hsn).length > 0));
        const testSAC = items.filter((data) => (data.sac != undefined) && ((data.sac).length > 0));

        testHSN.length > 0 ? setShowHSNColumn(true) : setShowHSNColumn(false);
        testSAC.length > 0 ? setShowSACColumn(true) : setShowSACColumn(false);
        itemstaxTotal > 0 ? setShowTaxColumn(true) : setShowTaxColumn(false);
    }, [currentInvoiceNumber]);

    useEffect(() => {
        addedtax.length > 0 && setTaxApplied(true);
    }, [addedtax]);

    useEffect(() => {
        addedtax.map((data, index) => {
            setTaxValues([(Object.values(data) / 100) * subTotal])
        })
    }, [items]);

    const getFirms = () => {
        getAllFirms(setAllFirms);
    };

    useEffect(() => {
        getFirms();
    }, []);

    var sum;

    useEffect(() => {
        for (let i = 0; i < addedtax.length; i++) {
            sum += addedtax[i];
        }
    }, []);

    const getFirmById = async (id) => {
        let url = `${API}/firm/getFirm/${orgId}/${id}`;
        const config = {
            method: "GET",
            url: url,
            headers: {
                "Content-Type": "application/json",
            },
        }
        try {
            const response = await axios(config);
            setSingleFirm(response.data.data[0]);
            setInvoicePrefix(response.data.data[0].invoicePrefix);
            setFirmAdd(response.data.data[0].add);
            setSelectedFirmId(response.data.data[0]._id);
            setValidateFirm(false);
            setFirmDetails({
                name: (response.data.data[0].registeredFirmName === undefined || response.data.data[0].registeredFirmName === '') ? response.data.data[0].name : response.data.data[0].registeredFirmName,
                phone: response.data.data[0].phone,
                taxId: response.data.data[0].gst_no,
                email: response.data.data[0].email,
                address: response.data.data[0].add,
                logo: response.data.data[0].logo,
            });
        }
        catch (err) {
            setSingleFirm([]);
        }
    }

    const getFirmTaxes = () => {
        getAllTaxes(id).then((response) => {
            if (response.status === 200) {
                response.data.data.length > 0 ? setFirmTaxes(response.data.data[0].taxRates) : setFirmTaxes([]);
            }
            else {
                setFirmTaxes([]);
            }
        }).catch(() => {
            setFirmTaxes([]);
        });
    };

    const getInvoiceNumberList = () => {
        getLastInvoiceNumber(id).then((response) => {
            if (response.data.data === 0) {
                setLastInvoice(response.data.data);
                setAllInvoiceNumbers([response.data.data])
            } else {
                setLastInvoice(response.data.data[0]);
                setAllInvoiceNumbers(response.data.data)
            }
        }).catch(() => {
            setLastInvoice(0);
            setAllInvoiceNumbers([]);
        })
    };


    const getSingleFirm = (id) => {
        setFirmId(id);
        getFirmById(id);
        getFirmTaxes();
        getInvoiceNumberList();
    };

    useEffect(() => {
        getGlobalTaxes().then((response) => {
            if (response.status === 200) response.data.data.length > 0 ? setAllGlobalTaxes(response.data.data[0].taxRates) : setAllGlobalTaxes([])
            else setAllGlobalTaxes([]);
        });
    }, []);

    useEffect(() => {
        setAllTaxes([...firmTaxes, ...globaltaxes]);
    }, [firmTaxes, globaltaxes]);

    const [test, setTest] = useState([]);
    const testing = () => {
        details.map((data, index) => {
            setTest([...test, data])
        })
    }

    useEffect(() => {
        testing();
    }, [])

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        }
    })
    // const handleFirmGet = (e) => {
    //     setFirmId(e.target.value)
    //   }

    const getClients = () => {
        getAllClients().then((response) => {
            if (response.status === 200) {
                setClient(response.data.data);
                setAllClients(response.data.data);
            } else {
                setAllClients([]);
            }
        }).catch(() => {
            setAllClients([]);
        })
        // getAllClients(setClient, setAllClients);
    }

    useEffect(() => {
        getClients();
    }, []);

    const handleCurrency = (e) => {
        setCurr(e.target.value)
    }

    const handleRecurring = () => {
        setRecurring(!recurring)
        !recurring && setShowRecurring(true)
    }

    const handleTerms = (e) => {
        setTerms(e.target.value.split('\n'));
    }

    const handleShowDraft = () => {
        setDraft(true);
        setShowDraft(true);
    }

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
            invoiceDate: createdDate,
            dueDate: endDate,
            items: items,
            client: clientDetails,
            dueAmount: grandTotal,
            amountPaid: 0,
            firm: firmDetails,
            currency: curr,
            curConvert: curConvert,
            remark: remark,
            notes: note,
            subTotal: parseFloat(subTotal),
            // subTotal: includeTax ? parseFloat((total - addedtax.reduce((prev, curr) => prev + (total - (subTotal / (1 + ((Object.values(curr) / 100))))), 0))) : subTotal,
            total: grandTotal,
            roundOff: (roundoff).toFixed(2),
            status: "Pending",
            termsNcondition: terms,
            partialPay: partialPay,
            incluTax: includeTax,
            // invoiceNumber: invoice.InvoiceNumber,
            invoiceNumber: finalNo.length === 0 ? currentInvoiceNumber : (finalNo.length > 2 ? finalNo : invoiceNumber),
            draft: false,
            cancel: false,
            delete: false,
            tax: taxApplied ? addedtax : [],
            taxAmt: taxAmt,
            orgId: orgId
        })
    }

    const handleSaveDraft = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
            invoiceDate: createdDate,
            dueDate: endDate,
            items: items,
            client: clientDetails,
            dueAmount: grandTotal,
            amountPaid: 0,
            firm: firmDetails,
            currency: curr,
            curConvert: curConvert,
            remark: remark,
            notes: note,
            subTotal: parseFloat(subTotal),
            // subTotal: includeTax ? parseFloat((total - addedtax.reduce((prev, curr) => prev + (total - (subTotal / (1 + ((Object.values(curr) / 100))))), 0))) : subTotal,
            total: grandTotal,
            roundOff: (roundoff).toFixed(2),
            status: "Draft",
            termsNcondition: terms,
            partialPay: partialPay,
            incluTax: includeTax,
            // invoiceNumber: invoice.InvoiceNumber,
            invoiceNumber: finalNo.length === 0 ? currentInvoiceNumber : (finalNo.length > 2 ? finalNo : invoiceNumber),
            // invoiceNumber: finalNo.length > 2 ? finalNo : invoiceNumber,
            draft: true,
            cancel: false,
            delete: false,
            tax: taxApplied ? addedtax : [],
            taxAmt: taxAmt,
            orgId: orgId
        })
    }

    const saveDraft = () => {
        setShowDraft(false);
        showLoader();
        updateDraft(editId, form).then((response) => {
            if (response.status === 200) {
                SuccessToast("Draft updated");
                hideLoader();
                setTimeout(() => {
                    history.push(`/${orgName}/dashboard/invoice/invoice-details/${editId}`);
                }, 1000)
            }
            else {
                ErrorToast("Something went wrong");
            }
        }).catch((err) => {
            err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast("Somthing went wrong");
        })
    }

    const handleSave = async (e) => {
        e.preventDefault();
        setShowInvoice(false);
        showLoader();

        {
            invoice.draft ? (
                updateDraft(editId, form).then((response) => {
                    if (response.status === 201) {

                        const activityForm = {
                            module: ['invoice', 'firm', 'client'],
                            entityId: [editId, , 'client'],
                        }

                        setShowInvoice(false);
                        SuccessToast("Saved As Invoice");
                        hideLoader();
                        history.push(`/${orgName}/dashboard/invoice/invoice-details/${editId}`);
                    }
                    else {
                        ErrorToast("Something went wrong");
                        hideLoader();
                    }
                }).catch((err) => {
                    err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast("Something went wrong");
                    hideLoader();
                })
            ) : (
                updateInvoice(editId, form).then((response) => {
                    if (response.status === 200) {
                        setShowInvoice(false);
                        SuccessToast("Invoice Updated Successfully");
                        showLoader();
                        history.push(`/${orgName}/dashboard/invoice/invoice-details/${editId}`);
                    }
                    else {
                        ErrorToast("Something went wrong");
                        hideLoader();
                    }
                }).catch((err) => {
                    err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast("Something went wrong");
                    hideLoader();
                })
            )
        }

        getAllInvoices().then((response) => {
            if (response.status === 200) {
                setInvoiceId(response.data.data[0]._id);
                setNotificationAdded([...notificationAdded, 'New invoice created', 'Notif 2'])
            } else {
                setInvoiceId('');
            }

        }).catch(() => {
            setInvoiceId('');
        });
    }

    const invoiceOptions = () => {
        return (
            <>
                <Row className="mx-3 text-white bg-primary py-2 ps-2 pe-3 my-2" onMouseOver={handleChange} onClick={() => setShow(true)} style={{ cursor: "pointer", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                    <Col sm={9}>Preview</Col>
                    <Col sm={1} className="text-end">
                        <Tooltip title="Preview Invoice">
                            <Link to="#" className="py-1 px-3 rounded  text-white me-2">
                                <FiEye style={{ fontSize: "1rem", cursor: "pointer" }} />
                            </Link>
                        </Tooltip>
                    </Col>
                </Row>

                {/* <Row className="text-white bg-primary mx-3 py-2 ps-2 pe-3 my-2" onClick={() => handleShowDraft()} style={{ cursor: "pointer", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                    <Col sm={12}>Save Draft</Col>
                </Row> */}

                {/* <Row className="text-white bg-primary mx-3 py-2 ps-2 pe-3 my-2" style={{ cursor: "pointer", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
          <Col sm={12}>Send Reminder</Col>
        </Row> */}

                <Row className="text-dark mx-3 py-2 ps-2 pe-3 my-2" style={{ cursor: "pointer", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                    <Col sm={6} md={6}>Select Currency</Col>
                    <Col sm={6} md={6}>
                        <select className="w-100 rounded bg-white py-1 px-3 m-0" name="currency" onMouseOver={handleChange} onChange={handleCurrency} style={{ border: "1px solid #c8c9ca80" }}>
                            {
                                allCurrencies.map((data, index) => (
                                    <option key={index} value={data.value}>{data.name}</option>
                                ))
                            }
                        </select>
                    </Col>
                </Row>
                <Row className="text-dark mx-3 py-2 ps-2 pe-3 my-2" onClick={() => handleRecurring()} style={{ cursor: "pointer", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                    <Col sm={1}>
                        <Form.Check.Input type="checkbox" onChange={() => setRecurring(!recurring)} style={{ cursor: "pointer" }} checked={recurring} />
                    </Col>
                    <Col sm>Recurring Invoice</Col>
                </Row>
                <Row className="text-dark mx-3 py-2 ps-2 pe-3 my-2" onClick={() => setIncludeTax(!includeTax)} style={{ cursor: "pointer", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                    <Col sm={1}>
                        <Form.Check.Input type="checkbox" onChange={() => setIncludeTax(!includeTax)} style={{ cursor: "pointer" }} checked={includeTax} />
                    </Col>
                    <Col sm>Price Including Tax</Col>
                </Row>
                <Row className="text-dark mx-3 py-2 ps-2 pe-3 my-2" onClick={() => setPartialPay(!partialPay)} style={{ cursor: "pointer", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                    <Col sm={1}>
                        <Form.Check.Input type="checkbox" onChange={() => setPartialPay(!partialPay)} style={{ cursor: "pointer" }} checked={partialPay} />
                    </Col>
                    <Col sm>Allow Parital Payment</Col>
                </Row>
                {/* <Row className="text-dark mx-3 py-2 ps-2 pe-3 my-2" onClick={() => setTip(!tip)} style={{ cursor: "pointer", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
          <Col sm={1}>
            <Form.Check.Input type="checkbox" onChange={() => setTip(!tip)} style={{ cursor: "pointer" }} checked={tip} />
          </Col>
          <Col sm>Allow Tip</Col>
        </Row> */}
            </>
        )
    }

    const getClientById = async (clientId) => {
        let url = id ? `${API}/client/singleUser/${id}` : `${API}/client/singleUser/${clientId}`;
        const config = {
            method: "GET",
            url: url,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
            },
        }
        try {
            const response = await axios(config);
            setSingleClient(response.data.data);
            setClientAdd(response.data.data.add)
            setClientDetails({
                firstName: response.data.data.firstName,
                lastName: response.data.data.lastName,
                taxId: response.data.data.taxId,
                phone: response.data.data.phone,
                email: response.data.data.email,
                address: response.data.data.add,
            })
        }
        catch (err) {
            setSingleClient([]);
        }
    }

    useEffect(() => {
        getClientById();
    }, []);

    const addNewItem = () => {
        const newItems = {
            // id: Date.now().toString(),
            itemName: "",
            desc: "",
            discount: 0,
            hsn: '',
            sac: '',
            taxRate: null,
            amount: 0,
            quantity: null,
            unitPrice: null,
        };

        setitems([...items, newItems]);
    };

    const onInputChange = (ind, itemValue) => {
        const newItems = [...items];
        const index = newItems.findIndex((r, i) => i === ind);
        newItems[index] = itemValue;
        setitems(newItems);
    };

    const handleFieldChange = (e, field, id) => {
        handleInputChangeItem(e);
        field === 'itemName' ? setIsOpenItems(true) : setIsOpenItems(false)
        const updatedData = items.map((item, index) => {
            if (index == id) {
                return { ...item, [field]: e.target.value };
            }
            return item;
        });
        setitems(updatedData);
    };

    const handleDeleteRow = (index) => {
        setitems(items.filter((data, i) => i != index))
    };

    const [testArr, setTestArr] = useState([]);

    return (
        <div className="m-0 p-0" onClick={() => isOpenItems && setIsOpenItems(false)}>
            <SubHeader title={invoice.draft ? 'Edit Draft' : 'Edit Invoice'} />
            <div className="mx-3 p-0">
                <div className="row mx-2 mb-5"
                    style={{ marginTop: screenWidth >= breakPoint ? "-1%" : "-3%", fontSize: "0.8rem" }}
                >
                    <Col sm={8} className=" py-3 px-2 bg-white rounded" style={{
                        width: screenWidth >= breakPoint ? '75%' : '95%',
                    }}>
                        <Form>
                            <div>
                                <span className="fs-5 ms-3 fw-semibold">Invoice Details</span>
                                <Row className="w-100 my-2 mx-3">
                                    <Col sm={3} className="py-2 px-2" style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                                        Invoice Date:
                                        <input type="date" name="invoiceDate" defaultValue={invoice.invoiceDate} onChange={handleChange} onInput={(e) => setCreatedDate(e.target.value)} placeholder="100-mm-yyyy" className="ms-1 px-1" required style={{ border: "1px solid #e5e5e5", borderRadius: "5px" }} />
                                    </Col>
                                    <Col sm={3} className="py-2 px-2 mx-2" style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                                        <span className="m-0 p-0">Due date: </span>
                                        <input type="date" name="dueDate" defaultValue={invoice.dueDate} onChange={handleChange} onInput={(e) => setEndDate(e.target.value)} className="ms-1 px-1" style={{ border: "1px solid #e5e5e5", borderRadius: "5px" }} />
                                        {
                                            createdDate > endDate && (
                                                <p className="m-0 p-0 text-danger">Due date can not be before of Invoice date</p>
                                            )
                                        }
                                    </Col>
                                    <Col sm={2} className="m-0 p-0"></Col>
                                    <Col sm={3} className="text-end">
                                        <span className="m-0 p-0 w-100 text-end fs-3">
                                            Invoice:
                                            {
                                                firmDetails.name ? (
                                                    <span className="m-0 p-0 text-primary text-end fs-4" >
                                                        {
                                                            invoicePrefix
                                                        }-
                                                        <input type="text" className="text-primary invoiceNumberInput"
                                                            defaultValue={editedInvoiceNumber}
                                                            onChange={handleChangeInvoiceNumber}
                                                            onKeyDonw={handleKeyDown}
                                                            style={{ border: "none", borderBottom: "1px solid #8080805c", width: "50px" }}
                                                        />
                                                    </span>
                                                ) : (
                                                    <span className="m-0 p-0 text-primary text-end fs-4" ></span>
                                                )
                                            }
                                        </span>
                                    </Col>
                                </Row>
                            </div>
                            <div className="row my-3 mx-1 pb-4" style={{ borderBottom: "1px solid #c8c9ca" }}>
                                <Col sm={6}>
                                    <Form>
                                        <Form.Group>
                                            <Form.Label className="fw-semibold">Invoice From</Form.Label>
                                            <Form.Select className="mb-2" onClick={handleChange} onChange={(e) => getSingleFirm(e.target.value)} style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }} required>
                                                {/* <option> {firmDetails === undefined || firmDetails.name === undefined ? 'Select Firm' : firmDetails.name}</option> */}
                                                <option> {'Select Firm'}</option>
                                                {
                                                    allFirms.map((data, index) => (
                                                        <option onClick={handleChange} key={index} value={data._id}>{data.name}</option>
                                                    ))
                                                }
                                            </Form.Select>
                                            <Link to="#" onClick={() => setShowFirm(true)}>
                                                <span className="py-1 px-2 m-0 mt-2 bg-primary text-white" style={{ cursor: "pointer", borderRadius: "5px" }}>Add new Firm</span>
                                            </Link>
                                        </Form.Group>
                                    </Form>
                                </Col>
                                {
                                    !id && (
                                        <Col sm={6}>
                                            <Form>
                                                <Form.Group>
                                                    <Form.Label className="fw-semibold">Invoice To</Form.Label>
                                                    <Form.Select className="mb-2" name='clientName' onChange={(e) => getClientById(e.target.value)} style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }} required>
                                                        <option> Select Client</option>
                                                        {
                                                            client.map((data, index) => (
                                                                data.clientFirmName ? (
                                                                    <option key={index} value={data._id}>{data.clientFirmName}</option>
                                                                ) : (
                                                                    <option key={index} value={data._id}>
                                                                        {
                                                                            `${data.firstName === undefined ? ' ' : data.firstName} ${data.lastName === undefined ? ' ' : data.lastName}`
                                                                        }
                                                                    </option>
                                                                )
                                                            ))
                                                        }
                                                    </Form.Select>
                                                    <Link to="#" onClick={() => setShowClient(true)}>
                                                        <span className="py-1 px-2 m-0 mt-2 bg-primary text-white" style={{ cursor: "pointer", borderRadius: "5px" }}>Add new client</span>
                                                    </Link>
                                                </Form.Group>
                                            </Form>
                                        </Col>
                                    )
                                }
                            </div>
                            <div className="row ms-4 my-3 pb-4" style={{ borderBottom: "1px solid #c8c9ca" }}>
                                <Col md={6} sm={12}>
                                    <div className="my-2">
                                        <Row>
                                            <span className="m-0 p-0 fw-semibold" style={{ fontSize: "0.8rem" }}>From: </span>
                                        </Row>
                                    </div>
                                    <div>
                                        <Row>
                                            <Col sm={11} md={6} className="p-2" style={{ border: "1px solid #e5e5e5", borderRadius: "5px" }}>
                                                <span className="m-0 p-0 fw-semibold">Name: </span>
                                                <div className="m-0 p- d-inline" onChange={handleChange} name="firmName">{`${firmDetails === undefined || firmDetails.name === undefined ? '' : firmDetails.name}`}</div>
                                            </Col>
                                            <Col sm={11} md={5} className="p-2 ms-2" style={{ border: "1px solid #e5e5e5", borderRadius: "5px" }}>
                                                <span className="m-0 p-0 fw-semibold">Phone No: </span>
                                                <div className="m-0 p-0 d-inline" name="firmphone" onChange={handleChange}>{firmDetails.phone === undefined ? '' : firmDetails.phone}</div>
                                            </Col>
                                            <Col sm={12} className="p-2 my-2" style={{ width: "93%", maxWidth: "93%", border: "1px solid #e5e5e5", borderRadius: "5px" }}>
                                                <span className="m-0 p-0 fw-semibold">Email: </span>
                                                <div className="m-0 p-0 d-inline" name="firmEmail" onChange={handleChange}>{firmDetails.email === undefined ? '' : firmDetails.email}</div>
                                            </Col>
                                            <Col sm={12} className="p-2 my-2" style={{ width: "93%", maxWidth: "93%", border: "1px solid #e5e5e5", borderRadius: "5px" }}>
                                                <span className="m-0 p-0 fw-semibold">GST: </span>
                                                <div className="m-0 p-0 d-inline" name="firmGst" onChange={handleChange} style={{ textTransform: "uppercase" }}>{firmDetails.taxId === undefined ? '' : firmDetails.taxId}</div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={11} className="p-2" style={{ width: "93%", maxWidth: "93%", border: "1px solid #e5e5e5", borderRadius: "5px" }}>
                                                <span className="m-0 p-0 fw-semibold">Address: </span>
                                                <div className="m-0 mt-1 p-0" name="firmAddress">
                                                    <p className="m-0 p-0">{firmAdd === undefined || firmAdd.address1 === undefined ? '' : firmAdd.address1}</p>
                                                    <p className="m-0 p-0">{firmAdd === undefined || firmAdd.address2 === undefined ? '' : firmAdd.address2}</p>
                                                    <p className="m-0 p-0">
                                                        {
                                                            `${firmAdd === undefined || firmAdd.city === undefined ? '' : `${firmAdd.city}, `} 
                                                            ${firmAdd === undefined || firmAdd.state === undefined ? '' : `${firmAdd.state}, `} 
                                                            ${firmAdd === undefined || firmAdd.country === undefined ? '' : `${firmAdd.country}, `} 
                                                            ${firmAdd === undefined || firmAdd.pinCode === undefined ? '' : `${firmAdd.pinCode}, `}`
                                                        }
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col md={6} sm={12}>
                                    <div className="my-2">
                                        <Row>
                                            <span className="m-0 p-0 fw-semibold" style={{ fontSize: "0.8rem" }}>To: </span>
                                        </Row>
                                    </div>
                                    <div>
                                        <Row>
                                            <Col sm={11} md={6} className="p-2" style={{ border: "1px solid #e5e5e5", borderRadius: "5px" }}>
                                                <span className="m-0 p-0 fw-semibold">Name: </span>
                                                <div className="m-0 p-0 d-inline ms-1" name="name" onChange={handleChange}>
                                                    {
                                                        clientDetails.clientFirmName ? (
                                                            <div className="m-0 p-0 d-inline ms-1" name="name" onChange={handleChange}>{`${clientDetails.clientFirmName === undefined ? '' : clientDetails.clientFirmName}`}</div>
                                                        ) : (
                                                            <div className="m-0 p-0 d-inline ms-1" name="name" onChange={handleChange}>{`${clientDetails.firstName === undefined ? '' : clientDetails.firstName + ' ' + clientDetails.lastName === undefined ? '' : clientDetails.lastName}`}</div>
                                                        )
                                                    }
                                                    {/* {`${clientDetails.firstName === undefined ? '' : clientDetails.firstName + ' ' + clientDetails.lastName}`} */}
                                                </div>
                                            </Col>
                                            <Col sm={11} md={5} className="p-2 ms-2" style={{ border: "1px solid #e5e5e5", borderRadius: "5px" }}>
                                                <span className="m-0 p-0 fw-semibold">Phone No: </span>
                                                <div className="m-0 p-0 d-inline ms-1" name="phone" onChange={handleChange}>{clientDetails.phone === undefined ? '' : clientDetails.phone}</div>
                                            </Col>
                                            <Col className="p-2 my-2" style={{ width: "93%", maxWidth: "93%", border: "1px solid #e5e5e5", borderRadius: "5px" }}>
                                                <span className="m-0 p-0 fw-semibold">Email: </span>
                                                <div className="m-0 p-0 d-inline ms-1" name="email" onChange={handleChange}>{clientDetails.email === undefined ? '' : clientDetails.email}</div>
                                            </Col>
                                            <Col sm={12} className="p-2 my-2" style={{ width: "93%", maxWidth: "93%", border: "1px solid #e5e5e5", borderRadius: "5px" }}>
                                                <span className="m-0 p-0 fw-semibold">GST: </span>
                                                <div className="m-0 p-0 d-inline ms-1" name="gst" onChange={handleChange} style={{ textTransform: "uppercase" }}>{clientDetails.taxId === undefined ? '' : clientDetails.taxId}</div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={11} className="p-2" style={{ width: "93%", maxWidth: "93%", border: "1px solid #e5e5e5", borderRadius: "5px" }}>
                                                <span className="m-0 p-0 fw-semibold">Address: </span>
                                                <div className="m-0 mt-1 p-0" name="address">
                                                    <p className="m-0 p-0">{clientAdd.address1 === undefined ? '' : clientAdd.address1}</p>
                                                    <p className="m-0 p-0">{clientAdd.address2 === undefined ? '' : clientAdd.address2}</p>
                                                    <p className="m-0 p-0">
                                                        {
                                                            `${clientAdd.city === undefined ? '' : `${clientAdd.city}, `} 
                                                            ${clientAdd.state === undefined ? '' : `${clientAdd.state}, `} 
                                                            ${clientAdd.country === undefined ? '' : `${clientAdd.country}, `} 
                                                            ${clientAdd.pinCode === undefined ? '' : `${clientAdd.pinCode}, `}`
                                                        }
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </div>
                            <Col sm={3} className="ms-4 py-3 px-2 mx-2" style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                                <span className="m-0 p-0 fw-semibold">Tax:  </span>
                                <span className="m-0 ms-3 my-2 p-0 py-2 px-4 bg-primary text-white"
                                    onClick={calculateTax}
                                    style={{ borderRadius: "5px", cursor: "pointer" }}
                                >Apply Taxes</span>
                            </Col>
                            <div className="row mx-4 mt-4 pb-3 table-responsive" style={{ borderBottom: "1px solid #c8c9ca" }}>
                                <Row className="m-0 mb-2 p-0">
                                    <Col className="m-0 p-0">
                                        <span className="m-0 mb-3 p-0 fw-semibold" style={{ fontSize: "0.8rem" }}>Item Details</span>
                                    </Col>
                                    <Col className="m-0 p-0 pt-2 text-primary text-end" onClick={() => setShowTaxNDiscount(true)} style={{ cursor: "pointer" }}>
                                        <span className="m-0 py-1 px-3 rounded bg-primary text-white fw-semibold">Customize</span>
                                    </Col>
                                </Row>
                                <table className="w-100">
                                    <thead className="bg-primary p-2 text-white" style={{ borderBottom: "1px solid #c8c9ca", borderRadius: "10px 0 10px 0" }}>
                                        <th className="ps-2" style={{ borderRadius: "10px 0 0 0" }}>Item</th>
                                        {/* <th className="text-center ps-2">HSN</th> */}
                                        {showHSNColumn ? <th className="text-center ps-2" style={{}}>HSN</th> : null}
                                        {showSACColumn ? <th className="text-center ps-2" style={{}}>SAC</th> : null}
                                        <th className="text-center">Qty</th>
                                        <th className="text-start">Price</th>
                                        {/* <th className="text-center">Tax</th> */}
                                        {showTaxColumn ? <th className="text-center">Tax</th> : null}
                                        {showDiscountColumn ? <th className="text-center">Discount</th> : null}
                                        <th className="text-start ps-3">Amount</th>
                                        <th className="pe-2" style={{ borderRadius: "0 10px 0 0" }}>Actions</th>
                                    </thead>
                                    <tbody>
                                        {
                                            items.map((data, index) => (
                                                <>
                                                    <tr key={index}>
                                                        <td>
                                                            <input
                                                                type='text' name='itemName' className='my-2' style={{ minWidth: "30rem", border: "none", borderBottom: "none" }}
                                                                value={data.itemName}
                                                                onInput={handleInputChangeItem}
                                                                onInputCapture={() => setIsOpenItems(true)}
                                                                onClick={() => dropdownhandleIndex(index)}
                                                                onChange={(e) => handleFieldChange(e, 'itemName', index)}
                                                                placeholder="Item Name"
                                                            />
                                                            {dropIndex === index ? isOpenItems && (
                                                                <>
                                                                    <div
                                                                        style={{
                                                                            display: 'none',
                                                                            maxHeight: "15rem", width: "35%", position: 'absolute', background: 'white',
                                                                            overflowY: "scroll", border: "1px solid #c8c9ca"
                                                                        }}
                                                                        className="rounded border px-3 py-2 border-2 d-flex flex-column">
                                                                        <div className='text-primary mt-2 pb-2'
                                                                            style={{ cursor: 'pointer', borderBottom: "1px solid #c8c9ca" }}
                                                                        >
                                                                            <MdAdd className="text-primary fw-bold fs-4" onClick={() => handleShowProduct()} style={{ fontWeight: "900" }} /> <span className="m-0 p-0 me-1" onClick={() => handleShowProduct()}>Create New Item</span>
                                                                            {
                                                                                validateFirm ? (
                                                                                    <span className="fw-semibold me-1 text-danger" onClick={() => window.scrollTo(0, 0)}>Select Firm</span>
                                                                                ) : null
                                                                            }
                                                                        </div>
                                                                        {
                                                                            allProducts.length > 0 ? (
                                                                                filteredItemsOptions.length > 0 ? (
                                                                                    filteredItemsOptions.map((option, i) => (
                                                                                        <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleOptionClickItem(option.name, i, index)}>
                                                                                            {option.name}
                                                                                        </div>
                                                                                    ))
                                                                                ) : (<div className="mt-2">No match found</div>)
                                                                            ) : (<div className="mt-2">No products found </div>)
                                                                        }
                                                                    </div>
                                                                </>
                                                            ) : null}
                                                        </td>
                                                        {
                                                            showHSNColumn ? (
                                                                <td className="text-center">
                                                                    <input
                                                                        type='text' name='hsn' className='text-center my-2' style={{ maxWidth: "7rem", minWidth: "7rem", border: "none", borderBottom: "1px solid #fff" }}
                                                                        value={data.hsn}
                                                                        maxLength={8}
                                                                        onChange={e => handleFieldChange(e, 'hsn', index)}
                                                                        placeholder={data.hsn === undefined || data.hsn === '' && 'Enter HSN'}
                                                                    />
                                                                </td>
                                                            ) : null
                                                        }

                                                        {
                                                            showSACColumn ? (
                                                                <td className="text-center">
                                                                    <input
                                                                        type='text' name='sac' className='text-center my-2' style={{ maxWidth: "7rem", minWidth: "7rem", border: "none", borderBottom: "1px solid #fff" }}
                                                                        value={data.sac}
                                                                        maxLength={8}
                                                                        onChange={e => handleFieldChange(e, 'sac', index)}
                                                                        placeholder={data.sac === undefined || data.sac === '' && 'Enter SAC'}
                                                                    />
                                                                </td>
                                                            ) : null
                                                        }

                                                        <td className="text-center">
                                                            <input
                                                                type='number' name='quantity' className='text-center my-2' style={{ maxWidth: "5rem", minWidth: "5rem", border: "none", borderBottom: "1px solid #fff" }}
                                                                value={data.quantity}
                                                                onChange={e => handleFieldChange(e, 'quantity', index)}
                                                                placeholder='0'
                                                                required
                                                            />
                                                        </td>
                                                        <td className="text-start">
                                                            <input
                                                                type='number' name='unitPrice' className='text-start my-2' style={{ maxWidth: "6rem", minWidth: "6rem", border: "none", borderBottom: "1px solid #fff" }}
                                                                value={includeTax === true
                                                                    ? ((parseFloat(parseInt(data.quantity) * parseFloat(data.unitPrice)) / (1 + (parseFloat(data.taxRate) / 100))).toFixed(2)) / (parseInt(data.quantity))
                                                                    : parseFloat(data.unitPrice)}
                                                                onChange={e => handleFieldChange(e, 'unitPrice', index)}
                                                                placeholder='0'
                                                                required
                                                            />
                                                        </td>
                                                        {
                                                            showTaxColumn ? (
                                                                showSelect === false ? (
                                                                    <td>
                                                                        <Form.Select name="taxRate" style={{ maxWidth: "7rem", minWidth: "7rem" }}
                                                                            value={data.taxRate}
                                                                            onInput={e => handleFieldChange(e, 'taxRate', index)}
                                                                            onChange={() => setShowSelect(true)}
                                                                        >
                                                                            <option value={0}>Select Tax</option>
                                                                            <option value={0}>Remove</option>
                                                                            {
                                                                                allTaxes.map((data, index) => (
                                                                                    <option value={Object.values(data)} key={index}>
                                                                                        {Object.keys(data)} {Object.values(data)}
                                                                                    </option>
                                                                                ))
                                                                            }
                                                                        </Form.Select>
                                                                    </td>
                                                                ) : (
                                                                    <td onClick={() => setShowSelect(false)}>
                                                                        {data.taxRate === null ? 'Select Tax' : data.taxRate}
                                                                    </td>
                                                                )
                                                            ) : null
                                                        }
                                                        {
                                                            showDiscountColumn &&
                                                            (<td>
                                                                <input
                                                                    type="number" name="discount"
                                                                    className="invoiceInput text-center my-2"
                                                                    onChange={e => handleFieldChange(e, 'discount', index)}
                                                                    placeholder={data === undefined || data.discount === undefined ? 0 : data.discount}
                                                                    style={{ maxWidth: "5rem", minWidth: "5rem", border: "none", borderBottom: "1px solid #fff" }}
                                                                // required
                                                                />
                                                            </td>)
                                                        }
                                                        {/* <td>
                                                            <Form.Select name="taxRate" style={{ maxWidth: "7rem", minWidth: "7rem" }}
                                                                value={data.taxRate}
                                                                onChange={e => handleFieldChange(e, 'taxRate', index)}
                                                            >
                                                                <option value={0}>Select Tax</option>
                                                                <option value={0}>Remove</option>
                                                                {
                                                                    allTaxes.map((data, index) => (
                                                                        <option
                                                                            value={Object.values(data)}
                                                                        >
                                                                            {Object.keys(data)} {Object.values(data)}
                                                                        </option>
                                                                    ))
                                                                }
                                                            </Form.Select>
                                                        </td> */}
                                                        {/* {
                                                            showTaxColumn ? (
                                                                showSelect === false ? (
                                                                    <td>
                                                                        <Form.Select name="taxRate" style={{ maxWidth: "7rem", minWidth: "7rem" }}
                                                                            value={data.taxRate}
                                                                            onChange={e => handleFieldChange(e, 'taxRate', index)}
                                                                            onInput={() => setShowSelect(true)}
                                                                        >
                                                                            <option value={0}>Select Tax</option>
                                                                            <option value={0}>Remove</option>
                                                                            {
                                                                                allTaxes.map((data, index) => (
                                                                                    <option
                                                                                        value={Object.values(data)}
                                                                                    >
                                                                                        {Object.keys(data)} {Object.values(data)}
                                                                                    </option>
                                                                                ))
                                                                            }
                                                                        </Form.Select>
                                                                    </td>
                                                                ) : (
                                                                    <td className="text-center" style={{ maxWidth: "7rem", minWidth: "7rem" }}>
                                                                        <p className="pt-3" onClick={() => setShowSelect(false)}>{data.taxRate}</p>
                                                                    </td>
                                                                )
                                                            ) : null
                                                        } */}

                                                        <td className="text-start">
                                                            <input
                                                                type='number' name='amount' className='text-start ps-3 w-75 my-2' style={{ maxWidth: "7rem", minWidth: "7rem", border: "none", borderBottom: "1px solid #fff" }}
                                                                value={
                                                                    parseFloat(
                                                                        form.incluTax === true
                                                                            ? (data.quantity * data.unitPrice)
                                                                            : (parseFloat(data.quantity * data.unitPrice) + (parseFloat(data.quantity * data.unitPrice) * ((data.taxRate ? parseFloat(data.taxRate) : 0) / 100))).toFixed(2)
                                                                    ) - (
                                                                        parseFloat(
                                                                            form.incluTax === true
                                                                                ? (data.quantity * data.unitPrice)
                                                                                : (parseFloat(data.quantity * data.unitPrice) + (parseFloat(data.quantity * data.unitPrice) * ((data.taxRate ? parseFloat(data.taxRate) : 0) / 100))).toFixed(2)
                                                                        ) * ((data.discount ? parseFloat(data.discount) : 0) / 100)
                                                                    )
                                                                }
                                                                defaultValue={
                                                                    parseFloat(
                                                                        form.incluTax === true
                                                                            ? (data.quantity * data.unitPrice)
                                                                            : (parseFloat(data.quantity * data.unitPrice) + (parseFloat(data.quantity * data.unitPrice) * ((data.taxRate ? parseFloat(data.taxRate) : 0) / 100))).toFixed(2)
                                                                    ) - (
                                                                        parseFloat(
                                                                            form.incluTax === true
                                                                                ? (data.quantity * data.unitPrice)
                                                                                : (parseFloat(data.quantity * data.unitPrice) + (parseFloat(data.quantity * data.unitPrice) * ((data.taxRate ? parseFloat(data.taxRate) : 0) / 100))).toFixed(2)
                                                                        ) * ((data.discount ? parseFloat(data.discount) : 0) / 100)
                                                                    )
                                                                }

                                                                onChange={e => handleFieldChange(e, 'amount', index)}
                                                            />
                                                        </td>
                                                        <td className=" text-center">
                                                            <AiOutlineDelete className="fs-3 text-danger" onClick={() => handleDeleteRow(index)} style={{ cursor: "pointer" }} />
                                                        </td>
                                                    </tr>
                                                    <tr key={index}>
                                                        <input type="text" name="desc" className='mb-2 w-75' style={{ border: "none", borderBottom: "1px solid #fff" }}
                                                            value={data.desc}
                                                            onChange={e => handleFieldChange(e, 'desc', index)}
                                                            placeholder="Item Description"
                                                        />
                                                    </tr>
                                                </>
                                            ))
                                        }
                                        <div>
                                            <Tooltip title="Add new row">
                                                <Link to="#">
                                                    <MdAddCircle className="fs-3 mt-1 text-primary" onClick={addNewItem} style={{ cursor: "pointer" }} />
                                                </Link>
                                            </Tooltip>
                                        </div>
                                    </tbody>
                                </table>
                            </div>
                            <div className="mx-4 my-3 p-0 row">
                                <Col className="text-start py-2 px-0" style={{ width: "40%" }}>
                                    <span className="m-0 p-0 fw-semibold">Add Remark</span>
                                    <div className="m-0 my-2" style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                                        <div className="">
                                            <input type="text" name="remark" as="textarea" defaultValue={invoice.remark} rows={1} onChange={(e) => setRemark(e.target.value)} placeholder="Add Remark" className="w-100 p-2 border-0" />
                                        </div>
                                    </div>
                                    <span className="m-0 p-0 fw-semibold">Add Note</span>
                                    <div className="m-0 my-2" style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                                        <div className="">
                                            <input type="text" name="notes" as="textarea" rows={1} defaultValue={invoice.notes} onChange={(e) => setNote(e.target.value)} placeholder="Add Note" className="w-100 p-2 border-0" />
                                        </div>
                                    </div>
                                    <span className="m-0 p-0 fw-semibold">Terms & Conditions</span>
                                    <div className="m-0 my-2" style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                                        <div className="">
                                            <textarea type="text" name="termsNcondition" as="textarea" rows={5}
                                                onInput={handleChange}
                                                onChange={handleTerms}
                                                placeholder="Add Add Terms & Conditions"
                                                defaultValue={`${terms.join('\n')}`}
                                                // defaultValue={terms.map((data, index) => `${terms.join('')}\n`)}
                                                // defaultValue={terms.map((data, index) => `${data.split(',')}\n`)} 
                                                // defaultValue={
                                                //     terms.length < 1 ? "" : terms.map((data) => Object.values(data))
                                                // } 
                                                // defaultValue={terms.length < 1 ? ("")
                                                //     : (terms.map((i, index) => {
                                                //         return (
                                                //             <li>{toString(Object.values(i))}</li>
                                                //         )
                                                //     })
                                                //     )}
                                                className="w-100 p-2 border-0">
                                            </textarea>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="text-start m-0 ms-5 py-2 px-0" style={{ width: "50%" }}>
                                    <span className="m-0 p-0 me-3 fw-semibold">Total</span>
                                    <div className="m-0 my-2 py-3 px-0" style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                                        <div className="mt-2 p-0" style={{ fontSize: "0.8rem" }}>
                                            <Row className="m-0 px-0 text-end">
                                                <Col sm={8} className="text-end">Subtotal: </Col>
                                                <Col sm={4} className="text-end" name="subTotal" style={{ maxWidth: "100%", overflowX: "scroll" }}>
                                                    {curr} {subTotal}
                                                </Col>
                                            </Row>
                                            {
                                                showDiscountColumn ? (
                                                    <Row className="m-0 px-0 text-end">
                                                        <Col sm={8} className="text-end">Discount: </Col>
                                                        <Col sm={4} className="text-end" name="subTotal" style={{ maxWidth: "100%", overflowX: "scroll" }}>
                                                            {`${curr} ${(subTotal - discountedPrice).toFixed(2)}`}
                                                        </Col>
                                                    </Row>
                                                ) : null
                                            }
                                            {/* {
                                                itemstaxTotal > 0 && (
                                                    <Row className="m-0 px-0 text-end">
                                                        <Col sm={8} className="text-end">Tax: </Col>
                                                        <Col sm={4} className="text-end" name="subTotal" style={{ maxWidth: "100%", overflowX: "scroll" }}>
                                                            {curr}
                                                            {itemstaxTotal.toFixed(2)}
                                                        </Col>
                                                    </Row>
                                                )
                                            } */}
                                            {
                                                itemstaxTotal > 0 && (
                                                    <Row className="m-0 px-0 text-end">
                                                        <Col sm={8} className="text-end">Tax: </Col>
                                                        <Col sm={4} className="text-end" name="subTotal" style={{ maxWidth: "100%", overflowX: "scroll" }}>
                                                            {curr} {itemstaxTotal.toFixed(2)}
                                                        </Col>
                                                    </Row>
                                                )
                                            }

                                            {
                                                addedtax.map((data, index) => (
                                                    (
                                                        <Row className="m-0 p-0" style={{ display: !taxApplied && "none" }} key={index}>
                                                            <Col sm={8} className="text-end">{Object.keys(data)}:</Col>
                                                            <Col sm={4} className="text-end" name="tax" style={{ maxWidth: "100%", overflowX: "scroll" }}>{`${curr} `}
                                                                {((Object.values(data) / 100) * subTotal).toFixed(2)}
                                                            </Col>
                                                        </Row>
                                                    )
                                                ))
                                            }

                                            <Row className="m-0 px-0 text-end">
                                                <Col sm={8} className="text-end">Roundoff: </Col>
                                                <Col sm={4} className="text-end" name="subTotal" style={{ maxWidth: "100%", overflowX: "scroll" }}>{curr} {(roundoff).toFixed(2)}</Col>
                                            </Row>
                                            <Row className="m-0 p-0 text-primary py-1 bg-soft-primary fw-semibold">
                                                <Col sm={8} className="text-end">Total: </Col>
                                                <Col sm={4} className="text-end" name="total" style={{ maxWidth: "100%", overflowX: "scroll" }}>{curr} {grandTotal}</Col>
                                            </Row>
                                        </div>
                                    </div>
                                    {
                                        invoice.draft ? (
                                            <Row className="mt-5">
                                                <Col sm={2}></Col>
                                                <Col sm={9} className="mt-5" style={{ alignItems: "end", display: "flex", justifyContent: "flex-end" }}>
                                                    <button className="m-0 p-0 border border-none bg-white">
                                                        <span className="ms-2 py-2 px-3 rounded bg-primary text-white" onMouseOver={handleChange} onClick={() => handleShowDraft()} style={{ cursor: "pointer" }}>Save Draft</span>
                                                    </button>
                                                    <div className="m-0 p-0 border border-none bg-white">
                                                        <span className="ms-2 py-2 px-3 rounded bg-primary text-white" onMouseOver={handleChange} onClick={() => setShowInvoice(true)} style={{ cursor: "pointer" }}>Save Invoice</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        ) : (
                                            <Row className="mt-5">
                                                <Col sm={2}></Col>
                                                <Col sm={9} className="mt-5" style={{ alignItems: "end", display: "flex", justifyContent: "flex-end" }}>
                                                    <div className="m-0 p-0 border border-none bg-white">
                                                        <span className="ms-2 py-2 px-3 rounded bg-primary text-white" onMouseOver={handleChange} onClick={handleSave} style={{ cursor: "pointer" }}>Update Invoice</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        )
                                    }
                                </Col>
                            </div>
                        </Form>
                    </Col>
                    <ActivityBar options={invoiceOptions} hide={true} />
                </div>
                <InvoicePreview addedtax={addedtax} setShow={setShow} show={show} firmDetails={firmDetails} firmAdd={firmAdd} clientDetails={clientDetails} clientAdd={clientAdd} items={items} form={form} currency={curr} terms={terms} />
                <TaxModal getAllTaxes={getAllTaxes} setFirmTaxes={setFirmTaxes} getGlobalTaxes={getGlobalTaxes} setAllGlobalTaxes={setAllGlobalTaxes}
                    getSingleFirm={getSingleFirm} globaltaxes={globaltaxes} firmId={firmId} saveDraft={saveDraft} setDraft={setDraft}
                    newState={newState} setNewState={setNewState} taxes={firmTaxes} addedTax={addedtax} setTaxName={setTaxName}
                    setTaxRate={setTaxName} setAddedtax={setAddedTax} showTax={showTax} setShowTax={setShowTax} showDraft={showDraft}
                    setShowDraft={setShowDraft} draft={setDraft} handleSaveDraft={handleSaveDraft} calculateTax={calculateTax}
                />
                <RecurringModal showRecurring={showRecurring} setShowRecurring={setShowRecurring} />
                <Addclientmodal getClients={getClients} showClient={showClient} setShowClient={setShowClient} />
                <Addfirmmodal getFirms={getFirms} showFirm={showFirm} setShowFirm={setShowFirm} />
                <div className="d-none">
                    <Notification notifs={notificationAdded} />
                </div>
            </div>
            <CreateProducts firmId={selectedFirmId} allFirms={allFirms} showCreateProducts={showCreateProducts} setShowCreateProducts={setShowCreateProducts} firmTaxes={firmTaxes} />
            <Modal centered show={showInvoice} onHide={() => setShowInvoice(false)} >
                <Modal.Body className="my-2">
                    <Row className="mb-2 ms-1">
                        <Col sm={12} className=" text-center px-2 m-0 p-0 fs-3">Save As Invoice</Col>
                        <Col sm={12} className="text-secondary text-center px-2 m-0 p-0">Are you sure you want to save ?</Col>
                    </Row>
                    <div className="ms-2 w-100" style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }} >
                        <div
                            className="bg-white py-1 px-5 me-2 bg-primary text-center text-white border border-primary"
                            style={{ borderRadius: "4px", cursor: "pointer" }} onClickCapture={handleChange} onClick={handleSave}
                        >
                            Save
                        </div>
                        <div className="bg-white py-1 px-5 border border-primary text-center" style={{ borderRadius: "5px", cursor: "pointer" }} onClick={() => setShowInvoice(false)}>
                            Cancel
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal centered show={showTaxNDiscount} onHide={() => setShowTaxNDiscount(false)}>
                <Modal.Body className="my-2">
                    <Row className="mb-2 ms-1">
                        {/* <Col sm={12} className=" text-center px-2 m-0 p-0 fs-3">Delete Invoice</Col> */}
                        <Col sm={12} className="text-center px-2 m-0 p-0 fs-3">
                            Add Taxes and Discounts to Items
                        </Col>
                    </Row>
                    <div className="ms-2 w-100" style={{}} >
                        <Row className="mx-5">
                            <Col sm={2} className="text-end" style={{ cursor: "pointer" }} onClick={() => setShowTaxColumn(!showTaxColumn)}>
                                <Form.Check.Input type="checkbox" name="taxColumn" onChange={() => setShowTaxColumn(!showTaxColumn)} checked={showTaxColumn} style={{ cursor: "pointer" }} />
                            </Col>
                            <Col sm={2} onClick={() => setShowTaxColumn(!showTaxColumn)} style={{ cursor: "pointer" }}>
                                <label style={{ fontSize: '0.8rem', cursor: "pointer" }}>Tax</label>
                            </Col>
                        </Row>

                        <Row className="mx-5">
                            <Col sm={2} onClick={() => setShowDiscountColumn(!showDiscountColumn)} className="text-end" style={{ cursor: "pointer" }}>
                                <Form.Check.Input type="checkbox" name="discountColumn" onChange={() => setShowDiscountColumn(!showDiscountColumn)} checked={showDiscountColumn} style={{ cursor: "pointer" }} />
                            </Col>
                            <Col sm={2} onClick={() => setShowDiscountColumn(!showDiscountColumn)}>
                                <label style={{ fontSize: '0.8rem', cursor: "pointer" }}>Discounts</label>
                            </Col>
                        </Row>

                        <Row className="mx-5">
                            <Col sm={2} onClick={() => setShowHSNColumn(!showHSNColumn)} className="text-end" style={{ cursor: "pointer" }}>
                                <Form.Check.Input type="checkbox" name="discountColumn" onChange={() => setShowHSNColumn(!showHSNColumn)} checked={showHSNColumn} style={{ cursor: "pointer" }} />
                            </Col>
                            <Col sm={2} onClick={() => setShowHSNColumn(!showHSNColumn)}>
                                <label style={{ fontSize: '0.8rem', cursor: "pointer" }}>HSN</label>
                            </Col>
                        </Row>

                        <Row className="mx-5">
                            <Col sm={2} onClick={() => setShowSACColumn(!showSACColumn)} className="text-end" style={{ cursor: "pointer" }}>
                                <Form.Check.Input type="checkbox" name="discountColumn" onChange={() => setShowSACColumn(!showSACColumn)} checked={showSACColumn} style={{ cursor: "pointer" }} />
                            </Col>
                            <Col sm={2} onClick={() => setShowSACColumn(!showSACColumn)}>
                                <label style={{ fontSize: '0.8rem', cursor: "pointer" }}>SAC</label>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
            {loader}
        </div>
    );
};

export default EditInvoice;