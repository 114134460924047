import React, { useEffect, useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Link } from "react-router-dom";
import VerticalNav from "./vertical-nav";
import cubicleweb from '../../../../assets/images/Cubicleweb2.png'
import cubileFullLogo from '../../../../assets/images/cubileFullLogo.png';

const SidebarSmall = () => {

  const [hovered, setHovered] = useState(false);

  const minisidebar = () => {
    document.getElementsByTagName("ASIDE")[0].classList.toggle("sidebar-mini");
  };

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  })

  useEffect(() => {
    screenWidth < 1200 && setHovered(true);
  }, [screenWidth]);

  return (
    <>
      <aside className="sidebar sidebar-mini sidebar-default sidebar-hover" onMouseOver={() => setHovered(true)} onMouseOut={() => screenWidth > 1200 ? setHovered(false) : setHovered(true)}>
        <div className="sidebar-header d-flex align-items-center justify-content-start">
          <Link className="navbar-brand m-0 p-0" to="#">
            {
              hovered ? (
                <img src={cubileFullLogo} alt="logo" width={150} height={50.84} className="logo-title m-0 mt-2 ms-2 p-0" />
              ) : (
                <img src={cubicleweb} alt="logo" style={{ width: "3.9rem", height: "3.9rem" }} className="main-logo m-0 p-0" />
              )
            }
          </Link>
          {screenWidth < 1200 && (
            <div
              className="sidebar-toggle"
              data-toggle="sidebar"
              data-active="true"
              onClick={minisidebar}
            >
              <i className="icon">
                <AiOutlineArrowLeft className="fs-2 sidebar-toggle text-white" />
              </i>
            </div>
          )}
        </div>
        <div
          className="pt-0 sidebar-body data-scrollbar"
          data-scroll="1"
          id="my-scrollbar"
        >
          {/* sidebar-list class to be added after replace css */}
          <div className="ms-2 sidebar-list navbar-collapse" id="sidebar">
            <VerticalNav />
          </div>
        </div>
        <div className="sidebar-footer"></div>
      </aside>
    </>
  );
};

export default SidebarSmall;
