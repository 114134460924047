import { Button, Form, Input, Modal, TimePicker } from "antd";

import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

// import BtnEditSvg from "../Button/btnEditSvg";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
// import { updateEmploymentStatus } from "../../../redux/rtk/features/employemntStatus/employmentStatusSlice";
import BtnEditSvg from "../Button/btnEditSvg";
import { ErrorToast, SuccessToast } from "../../toast";
import { updateEmploymentStatus } from "../../../app/Hook/employmentHooks";

const EmploymentStatusEditPopup = ({ data }) => {
	const [statusName, setStatusName] = useState('');
	const [description, setDescription] = useState('');
	const [colorValue, setColorValue] = useState('');

	const [isModalOpen, setIsModalOpen] = useState(false);
	const { id } = useParams();

	const dispatch = useDispatch();
	// const [form] = Form.useForm();
	// const { loading } = useSelector((state) => state.employmentStatus);
	const location = useHistory();

	useEffect(() => {
		setStatusName(data.name);
		setDescription(data.description);
		setColorValue(data.colourValue)
	}, [data]);

	const onFinish = (values) => {
		// dispatch(updateEmploymentStatus({ id: id, values: values }));
		setIsModalOpen(false);
		location.push(-1);
	};

	const handleUpdate = (e) => {

		e.preventDefault();
		const formData = {
			name: statusName,
			description: description,
			colourValue: colorValue
		};
		updateEmploymentStatus(formData, id).then((response) => {
			if (response.status === 200) {
				SuccessToast("Employement status updated successfully ");
			} else {
				ErrorToast("Unable to updated employment status");
			}
		}).catch(() => {
			ErrorToast("Unable to updated employment status");
		})
		// dispatch(updateEmploymentStatus({ id: id, values: values }));
		setIsModalOpen(false);
		location.push(-1);
	}

	const [initialValues, setInitialValues] = useState({
		name: data?.name || "",
		colourValue: data?.colourValue,
		description: data?.description,
	});

	const onFinishFailed = (errorInfo) => {
		toast.warning("Failed at adding department");
	};
	const showModal = () => {
		setIsModalOpen(true);
	};
	const handleOk = () => {
		setIsModalOpen(false);
	};
	const handleCancel = () => {
		setIsModalOpen(false);
	};
	return (
		<>
			<button onClick={showModal}>
				<BtnEditSvg size={30} />
			</button>
			<Modal
				title='Edit Employment Status'
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}>
				<form
					// form={form}
					style={{ marginBottom: "40px" }}
					eventKey='shift-form'
					name='basic'
					initialValues={initialValues}
					labelCol={{
						span: 6,
					}}
					wrapperCol={{
						span: 12,
					}}
					onSubmit={onFinish}
					// onSubmit={handleUpdate}
					// onFinish={onFinish}
					// onFinishFailed={onFinishFailed}
					autoComplete='off'>
					<div>
						<Form.Item
							style={{ marginBottom: "10px" }}
							label='Name'
							name='name'
							rules={[
								{
									required: true,
									message: "Please input your shift!",
								},
							]}>
							<Input defaultValue={statusName} onChange={(e) => setStatusName(e.target.value)} placeholder='Parmanet' />
						</Form.Item>

						<Form.Item
							style={{ marginBottom: "10px" }}
							label='Color Code'
							name='colourValue'
							rules={[
								{
									required: true,
									message: "Please input your shift!",
								},
							]}>
							<Input defaultValue={colorValue} onChange={(e) => setColorValue(e.target.value)} placeholder='#00FF00' />
						</Form.Item>

						<Form.Item
							style={{ marginBottom: "20px" }}
							label='Description'
							name={"description"}>
							<Input.TextArea defaultValue={description} onChange={(e) => setDescription(e.target.value)} placeholder='Description' />
						</Form.Item>

						<Form.Item
							style={{ marginBottom: "10px" }}
							wrapperCol={{
								offset: 6,
								span: 12,
							}}>
							<Button
								type='primary'
								size='small'
								htmlType='submit'
								block
							// loading={loading}
							>
								Update Now
							</Button>
						</Form.Item>
					</div>
				</form>
			</Modal>
		</>
	);
};
export default EmploymentStatusEditPopup;
