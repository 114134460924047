import axios from "axios";
import { API } from "../../../../backend"

const baseUrl = `${API}/Reminder`

// POST

export const createReminder = async (form) => {
    const url = `${baseUrl}/createReminder`;
    const config = {
        method: "POST",
        url: url,
        data: form,
        headers: {
            "Contetn-Type": "application/json"
        }
    }

    const response = await axios(config);
    return response;
}

// GET

export const getRemindersList = async () => {
    const url = `${baseUrl}/getAllReminders`;
    const config = {
        method: "GET",
        url: url,
        headers: {
            "Content-Type": "application/json"
        }
    }
    const response = await axios(config);
    return response;
}