import axios from 'axios';
import React, { useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { toast } from 'react-hot-toast';
import { MdClear } from 'react-icons/md'
import { useParams } from 'react-router-dom';
import { API } from '../../../../backend';
import { ErrorToast, SuccessToast, WarningToast } from '../../components/toast';
import { addFirmTax } from '../Hook/taxHooks';

const AddNewTaxes = ({ showNewTaxes, setShowNewTaxes, allTaxes }) => {

    const { id } = useParams();
    const firmId = id;
    const [customTax, setCustomTax] = useState();
    const [customRate, setCustomRate] = useState();
    const [addTaxType, setAddTaxType] = useState('gst');
    // const [allTaxes, setAllTaxes] = useState([]);

    const orgId = localStorage.getItem('orgId');

    const handleChangeGst = (e) => {
        setCustomTax(e.target.name)
        setCustomRate(e.target.value);
    };

    const handleAdd = (e) => {
        e.preventDefault();
        const form = {
            firmId: firmId,
            taxRates: [{ [customTax]: customRate }],
            orgId: orgId,
            globalTax: false
        };

        addFirmTax(form).then((response) => {
            if (response.status === 201) {
                SuccessToast("Tax added");
                setShowNewTaxes(false);
                allTaxes();
            }
            else {
                ErrorToast("Unable to add tax");
            }
        }).catch((err) => {
            err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast("Something went wrong");
        });
    };

    return (
        <Modal centered size="lg" show={showNewTaxes} onHide={() => setShowNewTaxes()} animation={false}>
            <Modal.Header className="bg-primary">
                <Col sm={11} className="text-white">
                    <h4 className="text-white">Add new taxes</h4>
                </Col>
                <Col sm={1} className="text-end text-white">
                    <MdClear className="fs-4" onClick={() => setShowNewTaxes(false)} style={{ cursor: "pointer" }} />
                </Col>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleAdd}>
                    <div className="w-100 mt-4">
                        <span
                            className={`${addTaxType === "gst"
                                ? "bg-primary text-white"
                                : "bg-white text-primary"
                                } border border-primary ms-0 rounded py-2 px-3`}
                            onClick={() => setAddTaxType("gst")}
                            style={{ cursor: "pointer" }}
                        >
                            GST
                        </span>
                        <span
                            className={`${addTaxType === "custom" ? "bg-primary text-white" : "bg-white text-primary"} border border-primary rounded py-2 px-3`}
                            onClick={() => setAddTaxType("custom")} style={{ cursor: "pointer" }}
                        >
                            Custom Tax
                        </span>
                    </div>
                    {addTaxType === "gst" ? (
                        <div
                            className="my-3 py-2"
                            style={{ borderTop: "1px solid #c8c9ca", borderBottom: "1px solid #c8c9ca" }} >
                            <Row className="w-100 my-2 mx-2">
                                <Col sm={4} className="py-2 px-2 mx-2" style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                                    <span className="m-0 me-2 p-0">GST: </span>
                                    <input type="number" name="GST" onChange={handleChangeGst} className="w-50 ps-2" placeholder="%" style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }} required />
                                </Col>
                            </Row>
                        </div>
                    ) : (
                        <div
                            className="my-3 py-2"
                            style={{ maxHeight: "50vh", overflowY: "scroll", overflowX: "hidden", borderTop: "1px solid #c8c9ca", borderBottom: "1px solid #c8c9ca" }}
                        >
                            <Row className="w-100 mx-2">
                                <Col sm={6} className="m-0 p-0 ps-2 fw-semibold">
                                    Name
                                </Col>
                                <Col sm={3} className="text-center fw-semibold">
                                    Rate (%)
                                </Col>
                            </Row>
                            {/* <form> */}
                            <Row className="w-100 my-2 mx-2">
                                <Col sm={6} className=" px-2 mx-2" style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                                    <input type="text" className="w-100 my-2 ps-2" placeholder="Name" onChange={(e) => setCustomTax(e.target.value)} required autoComplete="off"
                                        style={{ border: "none", borderBottom: "1px solid #c8c9ca", }}
                                    />
                                </Col>
                                <Col sm={2} className=" px-2 mx-2" style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                                    <input type="number" className="w-100 my-2 ps-2" name="rate" placeholder="%" onChange={(e) => setCustomRate(e.target.value)} required
                                        style={{ border: "none", borderBottom: "1px solid #c8c9ca", }}
                                    />
                                </Col>
                            </Row>
                            {/* </form> */}
                        </div>
                    )}
                    <Row>
                        <Col sm={3}>
                            <button type="submit" className='m-0 p-0 border border-none bg-white'>
                                <span className="py-2 px-3 m-0 fs-5 bg-primary text-white" style={{ cursor: "pointer", borderRadius: "5px" }}>Add</span>
                            </button>
                        </Col>
                    </Row>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default AddNewTaxes
