import { Tooltip } from "@material-ui/core";
import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { MdDeleteOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import SubHeader from "../../../../../components/partials/dashboard/HeaderStyle/sub-header";
import Pagination from "../../pagination/pagination";
import EditReimbursment from "./editReimbursment";

const PastReimburs = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showEdit, setShowEdit] = useState(false);
  const [selected, setSelected] = useState([]);

  const [showPerPage, setShowPerPage] = useState(10);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  useEffect(() => {
    const handleResize = setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const dummyData = [
    {
      id: 1,
      date: "23/01/2023",
      type: "Travel",
      reason: "Bussiness Tour",
      amount: 2000,
      status: "pending",
    },
    {
      id: 2,
      date: "23/01/2023",
      type: "Travel",
      reason: "Bussiness Tour",
      amount: 2000,
      status: "pending",
    },
    {
      id: 3,
      date: "23/01/2023",
      type: "Travel",
      reason: "Bussiness Tour",
      amount: 2000,
      status: "pending",
    },
    {
      id: 4,
      date: "23/01/2023",
      type: "Travel",
      reason: "Bussiness Tour",
      amount: 2000,
      status: "pending",
    },
    {
      id: 5,
      date: "23/01/2023",
      type: "Travel",
      reason: "Bussiness Tour",
      amount: 2000,
      status: "pending",
    },
    {
      id: 6,
      date: "23/01/2023",
      type: "Travel",
      reason: "Bussiness Tour",
      amount: 2000,
      status: "pending",
    },
    {
      id: 7,
      date: "23/01/2023",
      type: "Travel",
      reason: "Bussiness Tour",
      amount: 2000,
      status: "pending",
    },
    {
      id: 8,
      date: "23/01/2023",
      type: "Travel",
      reason: "Bussiness Tour",
      amount: 2000,
      status: "pending",
    },
    {
      id: 9,
      date: "23/01/2023",
      type: "Travel",
      reason: "Bussiness Tour",
      amount: 2000,
      status: "pending",
    },
    {
      id: 10,
      date: "23/01/2023",
      type: "Travel",
      reason: "Bussiness Tour",
      amount: 2000,
      status: "pending",
    },
  ];

  return (
    <>
      <SubHeader title="All Reimbursments" />
      <div>
        <div
          className="d-flex text-dark me-5 mb-5"
          style={{
            marginTop: screenWidth >= 1200 ? "-2%" : "-3%",
            flexDirection: "row",
            maxHeight: "550px",
          }}
        >
          <Col
            sm={9}
            className="bg-white rounded ms-3 py-2 px-3"
            style={{ minHeight: "500px" }}
          >
            <table className="w-100">
              <thead className="text-primary fw-bold fs-5">
                <th>
                  <Form.Check.Input type="checkbox" style={{ cursor: "pointer" }} />
                </th>
                <th>Date</th>
                <th>Type</th>
                <th>Reason</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Actions</th>
              </thead>
              <tbody>
                {dummyData
                  .slice(pagination.start, pagination.end)
                  .map((data) => (
                    <tr
                      style={{
                        fontSize: "0.8rem",
                        borderBottom: "1px solid #000",
                      }}
                      key={data.id}
                    >
                      <td className="py-2">
                        <Form.Check.Input type="checkbox" style={{ cursor: "pointer" }} />
                      </td>
                      <td>{data.date}</td>
                      <td>{data.type}</td>
                      <td>{data.reason}</td>
                      <td>{data.amount}</td>
                      <td>{data.status}</td>
                      <td className="d-flex flex-row pt-2">
                        <Tooltip title="Edit Reimbursment">
                          <Link to="#">
                            <HiOutlinePencilAlt
                              className="text-success me-2 fs-4"
                              style={{ cursor: "pointer" }}
                              onClick={() => setShowEdit(true)}
                            />
                          </Link>
                        </Tooltip>
                        <Tooltip title="Edit Reimbursment">
                          <Link to="#">
                            <MdDeleteOutline
                              className="text-danger fs-4"
                              style={{ cursor: "pointer" }}
                            />
                          </Link>
                        </Tooltip>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="mt-3">
              <Button type="">Approve</Button>
              <Button className="btn-outline ms-2">Reject</Button>
            </div>
            <div className="mt-5 mb-2">
              <Pagination
                showPerPage={showPerPage}
                onPaginationChange={onPaginationChange}
                total={dummyData.length}
              />
            </div>
          </Col>
          <EditReimbursment showEdit={showEdit} setShowEdit={setShowEdit} />
        </div>
      </div>
    </>
  );
};

export default PastReimburs;
