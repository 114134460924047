import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Navbar, Container, Nav, Dropdown, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import CustomToggle from "../../../dropdowns";
import { bindActionCreators } from "redux";
import { MdAdd } from "react-icons/md";

// store
import {
  NavbarstyleAction, getDirMode, SchemeDirAction, getNavbarStyleMode, getSidebarActiveMode,
  SidebarActiveStyleAction, getDarkMode, ModeAction, SidebarColorAction, getSidebarColorMode, getSidebarTypeMode,
} from "../../../../store/setting/setting";
import { connect } from "react-redux";
import Notification from "../../../../views/dashboard/app/notification/notification";
import Breadcrumbs from "../../../../views/dashboard/components/Breadcrumbs";
import { getUserById } from "../../../../views/dashboard/app/Hook/userHooks";

const mapStateToProps = (state) => {
  return {
    darkMode: getDarkMode(state),
    schemeDirMode: getDirMode(state),
    sidebarcolorMode: getSidebarColorMode(state),
    sidebarTypeMode: getSidebarTypeMode(state),
    sidebaractivestyleMode: getSidebarActiveMode(state),
    navbarstylemode: getNavbarStyleMode(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      ModeAction,
      SchemeDirAction,
      SidebarColorAction,
      SidebarActiveStyleAction,
      NavbarstyleAction,
    },
    dispatch
  ),
});
const Header = (props) => {
  const [data, setData] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]);
  const [filter, setFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [dropOpen, setDropOpen] = useState(false);

  const id = localStorage.getItem("orgID");
  const orgName = localStorage.getItem("orgName");
  const userId = localStorage.getItem("userId");

  const history = useHistory();
  // const navigate = useNavigate();
  // console.log(id);

  const searchItems = (e) => {
    setSearch(e);
    if (search !== "") {
      const filterData = data.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(search.toLowerCase());
      });
      setFilter(filterData);
      // console.log(filterData);
    } else {
      setFilter(data);
      // console.log(data);
    }
  };


  useEffect(() => {
    getUserById(userId).then((response) => {
      if (response.status === 200) setUserPermissions(response?.data?.data?.permissions);
      else setUserPermissions([]);
    }).catch(() => setUserPermissions([]));
  }, [userId]);

  useEffect(() => {
    // navbarstylemode
    const navbarstyleMode1 = sessionStorage.getItem("Navbarstyle-mode");
    if (navbarstyleMode1 === null) {
      props.NavbarstyleAction(props.navbarstylemode);
    } else {
      props.NavbarstyleAction(navbarstyleMode1);
    }
  });
  const minisidebar = () => {
    document.getElementsByTagName("ASIDE")[0].classList.toggle("sidebar-mini");
  };

  const createList = [
    {
      title: 'Create Invoice',
      module: 'invoice',
      path: `/${orgName}/dashboard/invoice/add-invoice`,
    },
    {
      title: 'Create Firm',
      module: 'firm',
      path: `/${orgName}/dashboard/firm/add-firm`,
    },
    {
      title: 'Create Client',
      module: 'client',
      path: `/${orgName}/dashboard/client/add-client`,
    },
    {
      title: 'Create Lead',
      module: 'lead',
      path: `/${orgName}/dashboard/lead/add-lead`,
    },
    {
      title: 'Create Vendor',
      module: 'vendor',
      path: `/${orgName}/dashboard/vendor/create-vendor`,
    },
    {
      title: 'Create Purchase',
      module: 'purchase',
      path: `/${orgName}/dashboard/purchase/create-purchase`,
    },
  ];

  const permit = (module) => {
    const permission = userPermissions.length > 0 ? userPermissions.some(permission => permission.module === module && permission.action.includes('Create')) : false;
    return permission;
  };

  return (
    <div onClick={() => setDropOpen(true)}>
      <Navbar
        expand="lg"
        variant="light"
        className="nav iq-navbar d-flex col-sm-3 col-md-6 col-lg-12"
      >
        <Container fluid className="navbar-inner">
          {/* <Link to="/dashboard" className="navbar-brand">
            <Logo color={true} />
            <h4 className="logo-title">CRM </h4>
          </Link> */}
          <div
            className="sidebar-toggle"
            data-toggle="sidebar"
            data-active="true"
            onClick={minisidebar}
          >
            <i className="icon">
              <svg width="20px" height="20px" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"
                />
              </svg>
            </i>
          </div>
          {/* <div className="input-group w-25 rounded">
            <span className="input-group-text" id="search-input">
              <svg
                width="18"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="11.7669"
                  cy="11.7666"
                  r="8.98856"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></circle>
                <path
                  d="M18.0186 18.4851L21.5426 22"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </span>
            <input
              type="search"
              className="border border-none"
              placeholder="Search..."
              onChange={(e) => searchItems(e.target.value)}
              style={{
                borderRadius: "0 5px 5px 0",
              }}
            />
            <div
              className="w-100 mt-5 rounded bg-white"
              style={{
                textAlign: "left",
                position: "absolute",
                cursor: "pointer",
                boxShadow: "rgb(154 156 161) 0px 2px 10px 2px",
                // height: "15rem",
                // overflow: "scroll"
              }}
            >
              {search.length > 0
                ? dropOpen &&
                filter.map((item) => (
                  // <Link to="/dashboard/app/client/client-list">
                  <>
                    <li
                      key={item._id}
                      className="my-2 mx-3 text-dark"
                      style={{ listStyle: "none" }}
                      value={item._id}
                    >
                      {item.firstName}
                    </li>
                    <div
                      className="mx-3"
                      style={{ borderBottom: "1px solid rgb(220 220 220)" }}
                    ></div>
                  </>
                  // </Link>
                ))
                : null}
            </div>
          </div> */}
          {/* <div className="w-50 d-flex justify-content-center align-items-center">
            <Breadcrumbs />
          </div> */}
          <Navbar.Toggle aria-controls="navbarSupportedContent">
            <span className="navbar-toggler-icon">
              <span className="mt-2 navbar-toggler-bar bar1"></span>
              <span className="navbar-toggler-bar bar2"></span>
              <span className="navbar-toggler-bar bar3"></span>
            </span>
          </Navbar.Toggle>
          <Navbar.Collapse id="navbarSupportedContent">
            <Nav as="ul" className="mb-2 ms-auto navbar-list mb-lg-0">
              <Notification />
              <Dropdown as="li" className="nav-item">
                <Dropdown.Toggle
                  as={CustomToggle}
                  variant="search-toggle nav-link"
                >
                  <Button variant="outline-primary">
                    <span className="m-0 p-0">Create</span> <MdAdd className="fw-bold fs-4" style={{ fontWeight: "900" }} />
                  </Button>
                </Dropdown.Toggle>
                <Dropdown.Menu className="p-0 sub-drop dropdown-menu-end">
                  <div className="m-0 border-0 shadow-none card">
                    <div className="p-0 ">
                      <ul className="list-group list-group-flush" style={{ listStyleType: "none" }}>
                        {
                          createList.map((data, index) => (
                            <div className="m-0 p-0" key={index}>
                              {
                                permit(data?.module) ? (
                                  <Link to={data.path} className="iq-sub-card list-group-item" key={index}>
                                    <li>{data.title}</li>
                                  </Link>
                                ) : null
                              }
                            </div>
                          ))
                        }
                      </ul>
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown as="li" className="nav-item">
                {/* <Dropdown.Toggle
                  as={CustomToggle}
                  href="#"
                  variant="nav-link"
                  id="mail-drop"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <svg
                    width="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M22 15.94C22 18.73 19.76 20.99 16.97 21H16.96H7.05C4.27 21 2 18.75 2 15.96V15.95C2 15.95 2.006 11.524 2.014 9.298C2.015 8.88 2.495 8.646 2.822 8.906C5.198 10.791 9.447 14.228 9.5 14.273C10.21 14.842 11.11 15.163 12.03 15.163C12.95 15.163 13.85 14.842 14.56 14.262C14.613 14.227 18.767 10.893 21.179 8.977C21.507 8.716 21.989 8.95 21.99 9.367C22 11.576 22 15.94 22 15.94Z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M21.4759 5.67351C20.6099 4.04151 18.9059 2.99951 17.0299 2.99951H7.04988C5.17388 2.99951 3.46988 4.04151 2.60388 5.67351C2.40988 6.03851 2.50188 6.49351 2.82488 6.75151L10.2499 12.6905C10.7699 13.1105 11.3999 13.3195 12.0299 13.3195C12.0339 13.3195 12.0369 13.3195 12.0399 13.3195C12.0429 13.3195 12.0469 13.3195 12.0499 13.3195C12.6799 13.3195 13.3099 13.1105 13.8299 12.6905L21.2549 6.75151C21.5779 6.49351 21.6699 6.03851 21.4759 5.67351Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  <span className="bg-primary count-mail"></span>
                </Dropdown.Toggle> */}
                {/* <Dropdown.Menu
                  className="p-0 sub-drop dropdown-menu-end"
                  aria-labelledby="mail-drop"
                >
                  <div className="m-0 shadow-none card">
                    <div className="py-3 card-header d-flex justify-content-between bg-primary">
                      <div className="header-title">
                        <h5 className="mb-0 text-white">All Message</h5>
                      </div>
                    </div>
                    <div className="p-0 card-body ">
                      <Link to="#" className="iq-sub-card">
                        <div className="d-flex align-items-center">
                          <div>
                            <img
                              className="p-1 avatar-40 rounded-pill bg-soft-primary"
                              src={shapes1}
                              alt=""
                            />
                          </div>
                          <div className=" w-100 ms-3">
                            <h6 className="mb-0 ">Bni Emma Watson</h6>
                            <small className="float-left font-size-12">
                              13 Jun
                            </small>
                          </div>
                        </div>
                      </Link>
                      <Link to="#" className="iq-sub-card">
                        <div className="d-flex align-items-center">
                          <div>
                            <img
                              className="p-1 avatar-40 rounded-pill bg-soft-primary"
                              src={shapes2}
                              alt=""
                            />
                          </div>
                          <div className="ms-3">
                            <h6 className="mb-0 ">Lorem Ipsum Watson</h6>
                            <small className="float-left font-size-12">
                              20 Apr
                            </small>
                          </div>
                        </div>
                      </Link>
                      <Link to="#" className="iq-sub-card">
                        <div className="d-flex align-items-center">
                          <div>
                            <img
                              className="p-1 avatar-40 rounded-pill bg-soft-primary"
                              src={shapes3}
                              alt=""
                            />
                          </div>
                          <div className="ms-3">
                            <h6 className="mb-0 ">Why do we use it?</h6>
                            <small className="float-left font-size-12">
                              30 Jun
                            </small>
                          </div>
                        </div>
                      </Link>
                      <Link to="#" className="iq-sub-card">
                        <div className="d-flex align-items-center">
                          <div>
                            <img
                              className="p-1 avatar-40 rounded-pill bg-soft-primary"
                              src={shapes4}
                              alt=""
                            />
                          </div>
                          <div className="ms-3">
                            <h6 className="mb-0 ">Variations Passages</h6>
                            <small className="float-left font-size-12">
                              12 Sep
                            </small>
                          </div>
                        </div>
                      </Link>
                      <Link to="#" className="iq-sub-card">
                        <div className="d-flex align-items-center">
                          <div>
                            <img
                              className="p-1 avatar-40 rounded-pill bg-soft-primary"
                              src={shapes5}
                              alt=""
                            />
                          </div>
                          <div className="ms-3">
                            <h6 className="mb-0 ">Lorem Ipsum generators</h6>
                            <small className="float-left font-size-12">
                              5 Dec
                            </small>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </Dropdown.Menu> */}
              </Dropdown>
              <Dropdown as="li" className="nav-item">
                {/* <Dropdown.Toggle
                  as={CustomToggle}
                  variant="search-toggle nav-link"
                >
                  <img
                    src="https://images.pexels.com/photos/1337386/pexels-photo-1337386.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                    alt="Organization Profile"
                    width="35rem"
                    height="35rem"
                    className="rounded-circle"
                    style={{ marginTop: "-10%" }}
                  />
                  <span className="bg-primary"></span>
                </Dropdown.Toggle> */}
                <Dropdown.Menu className="p-0 sub-drop dropdown-menu-end">
                  <div className="m-0 border-0 shadow-none card">
                    <div className="p-0 ">
                      <ul
                        className="list-group list-group-flush"
                        style={{ listStyleType: "none" }}
                      >
                        {/* <Link
                          to={`/${orgName}/dashboard/settings/organization/organization-profile/${id}`}
                          // onClick={() => handleProfile()} 
                          className="iq-sub-card list-group-item"
                        >
                          <li>Profile</li>
                        </Link> */}
                        {/* <li
                          className="iq-sub-card list-group-item"
                          onClick={handleLogOut}
                        >
                          Log Out
                        </li> */}
                        {/* <li className="iq-sub-card list-group-item">
                          <Link className="p-0" to="#">
                            <img
                              src={flag4}
                              alt="img-flaf"
                              className="img-fluid me-2"
                              style={{
                                width: "15px",
                                height: "15px",
                                minWidth: "15px",
                              }}
                            />
                            French
                          </Link>
                        </li>
                        <li className="iq-sub-card list-group-item">
                          <Link className="p-0" to="#">
                            <img
                              src={flag2}
                              alt="img-flaf"
                              className="img-fluid me-2"
                              style={{
                                width: "15px",
                                height: "15px",
                                minWidth: "15px",
                              }}
                            />
                            German
                          </Link>
                        </li>
                        <li className="iq-sub-card list-group-item">
                          <Link className="p-0" to="#">
                            <img
                              src={flag5}
                              alt="img-flaf"
                              className="img-fluid me-2"
                              style={{
                                width: "15px",
                                height: "15px",
                                minWidth: "15px",
                              }}
                            />
                            Spanish
                          </Link>
                        </li>
                        <li className="iq-sub-card list-group-item">
                          <Link className="p-0" to="#">
                            <img
                              src={flag6}
                              alt="img-flaf"
                              className="img-fluid me-2"
                              style={{
                                width: "15px",
                                height: "15px",
                                minWidth: "15px",
                              }}
                            />
                            Japanese
                          </Link>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              {/* <Dropdown as="li" className="nav-item">
                                <Dropdown.Toggle as={CustomToggle} variant=" nav-link py-0 d-flex align-items-center" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src={avatars1} alt="User-Profile" className="theme-color-default-img img-fluid avatar avatar-50 avatar-rounded"/>
                                    <img src={avatars2} alt="User-Profile" className="theme-color-purple-img img-fluid avatar avatar-50 avatar-rounded"/>
                                    <img src={avatars3} alt="User-Profile" className="theme-color-blue-img img-fluid avatar avatar-50 avatar-rounded"/>
                                    <img src={avatars5} alt="User-Profile" className="theme-color-green-img img-fluid avatar avatar-50 avatar-rounded"/>
                                    <img src={avatars6}alt="User-Profile" className="theme-color-yellow-img img-fluid avatar avatar-50 avatar-rounded"/>
                                    <img src={avatars4} alt="User-Profile" className="theme-color-pink-img img-fluid avatar avatar-50 avatar-rounded"/>
                                    <div className="caption ms-3 d-none d-md-block ">
                                        <h6 className="mb-0 caption-title">Austin Robertson</h6>
                                        <p className="mb-0 caption-sub-title">Marketing Administrator</p>
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu  className="dropdown-menu-end" aria-labelledby="navbarDropdown">
                                    <Dropdown.Item href="https://templates.iqonic.design./dashboard/app/user-profile">Profile</Dropdown.Item>
                                    <Dropdown.Item href="https://templates.iqonic.design./dashboard/app/user-privacy-setting">Privacy Setting</Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item href="https://templates.iqonic.design./auth/sign-in">Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
