import React, { useState, useEffect } from "react";
import topHeader from "../../../../../assets/images/dashboard/top-header.png";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import Card from "../../../../../components/Card";
import { API } from "../../../../../backend";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { toast, Toaster } from "react-hot-toast";
import SubHeader from "../../../../../components/partials/dashboard/HeaderStyle/sub-header";
import { ErrorToast, SuccessToast } from "../../../components/toast";

const Roles = () => {
  const [formData, setFormData] = useState([]);
  const [isChecked, setIsChecked] = useState([]);
  const [showUser, setShowUser] = useState(true);
  const [showLead, setShowLead] = useState(false);
  const [showInvoice, setShowInvoice] = useState(false);
  const [userRole, setUserRole] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [role, setRole] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(`${API}/`, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then((response) => {
        console.log("Role added");
        if (response.status === 200) {
          setFormData({
            ...formData,
            [e.target.name]: e.target.value,
          });
          SuccessToast("Role added successfully");
        } else {
          ErrorToast("Something went wrong");
        }
      })
      .catch((err) => ErrorToast(err.message));
  };


  const addRole = async (e) => {
    e.preventDefault();
      await fetch(`${API}/role/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          role: role,
          permission: userRole
        })
      })
      .then((res) => res.json())
      .then((response) => {
        if(response.status === 201){
        console.log("role added", response);
        }
        else{
          console.log("else block");
        }
      })
      .catch((err) => console.log(err.message))
  }

  const handleChange = (e) => {
    setRole(e.target.value);
  };

  const handleSelected = (e, data) => {
    if (e.target.checked) {
      setUserRole([...userRole, e.target.value]);
    } else {
      setUserRole(userRole.filter((perm) => perm.id !== data.id));
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
      permissions: userRole,
    });
  };

  const permissions = [
    {
      id: 1,
      name: "Read",
    },
    {
      id: 2,
      name: "Write",
    },
    {
      id: 3,
      name: "Delete",
    },
  ];

  const [allPermissions, setAllPermissions] = useState([]);

  useEffect(() => {
    const handleResize = setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  //   console.log("checked", userRole);
  console.log("form data", formData);

  return (
    <div>
      <SubHeader title="Roles & Permissions" />
      <Row
        className="text-dark pb-5"
        style={{
          width: "70%",
          marginLeft: "15%",
          minHeight: "70vh",
          marginTop: screenWidth >= 1200 ? "-1%" : "-3%",
        }}
      >
        <Col>
          <form onSubmit={addRole}>
            <Col>
              <div className="bg-white mb-2 px-3 rounded text-dark w-100">
                <Card.Header style={{ width: "100%" }}>
                  <div
                    sm={6}
                    className="w-100"
                    // onClick={() => setShowUser(!showUser)}
                    style={{ cursor: "pointer" }}
                  >
                    <h5 className=" text-primary">Role</h5>
                  </div>
                </Card.Header>
                <div className="px-4 pb-3">
                  <Row>
                    <Col sm={12} md={6}>
                      <Form.Label>Enter Role</Form.Label>
                      <Form.Control
                        type="text"
                        name="role"
                        placeholder="Enter Role"
                        className="text-dark border border-primary"
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </Col>

                    <Row className="mt-5">
                      <h5 className="text-primary">Permissions</h5>
                    </Row>

                    <Row sm={12} className="my-2">
                      <Col sm={1} className="fs-5 mt-1 me-2">
                        User:{" "}
                      </Col>
                      <Col sm={9}>
                        {/* {permissions.map((data) => (
                          <Col sm={6} md={4} lg={3}>
                            <input
                              type="checkbox"
                              className="mt-2 border border-primary"
                              name="permission"
                              value={data.name}
                              style={{ cursor: "pointer" }}
                              // checked={isChecked}
                              onChange={(e) => {
                                handleSelected(e, data);
                              }}
                            />
                            <span>{data.name}</span>
                          </Col>
                        ))} */}
                        {/* <Col> */}
                        <Row>
                          <Col sm={6} md={4} lg={3}>
                            <input
                              type="checkbox"
                              className="mt-2 border border-primary"
                              name="permission"
                              value="U1"
                              style={{ cursor: "pointer" }}
                              // checked={isChecked}
                              onChange={(e) => {
                                handleSelected(e);
                              }}
                            />
                            <span>Read</span>
                          </Col>
                          <Col sm={6} md={4} lg={3}>
                            <input
                              type="checkbox"
                              className="mt-2 border border-primary"
                              name="permission"
                              value="U2"
                              style={{ cursor: "pointer" }}
                              // checked={isChecked}
                              onChange={(e) => {
                                handleSelected(e);
                              }}
                            />
                            <span>Write</span>
                          </Col>
                          <Col sm={6} md={4} lg={3}>
                            <input
                              type="checkbox"
                              className="mt-2 border border-primary"
                              name="permission"
                              value="U3"
                              style={{ cursor: "pointer" }}
                              // checked={isChecked}
                              onChange={(e) => {
                                handleSelected(e);
                              }}
                            />
                            <span>Delete</span>
                          </Col>
                        </Row>
                        {/* </Col> */}
                      </Col>
                    </Row>

                    <Row sm={12} className="my-2">
                      <Col sm={1} className="fs-5 mt-1 me-2">
                        Lead:{" "}
                      </Col>
                      <Col sm={9}>
                        <Row>
                          <Col sm={6} md={4} lg={3}>
                            <input
                              type="checkbox"
                              className="mt-2 border border-primary"
                              name="permission"
                              value="L1"
                              style={{ cursor: "pointer" }}
                              // checked={isChecked}
                              onChange={(e) => {
                                handleSelected(e);
                              }}
                            />
                            <span>Read</span>
                          </Col>
                          <Col sm={6} md={4} lg={3}>
                            <input
                              type="checkbox"
                              className="mt-2 border border-primary"
                              name="permission"
                              value="L2"
                              style={{ cursor: "pointer" }}
                              // checked={isChecked}
                              onChange={(e) => {
                                handleSelected(e);
                              }}
                            />
                            <span>Write</span>
                          </Col>
                          <Col sm={6} md={4} lg={3}>
                            <input
                              type="checkbox"
                              className="mt-2 border border-primary"
                              name="permission"
                              value="L3"
                              style={{ cursor: "pointer" }}
                              // checked={isChecked}
                              onChange={(e) => {
                                handleSelected(e);
                              }}
                            />
                            <span>Delete</span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row sm={12} className="my-2">
                      <Col sm={1} className="fs-5 mt-1 me-2">
                        Invoice:{" "}
                      </Col>
                      <Col sm={9}>
                        <Row>
                          <Col sm={6} md={4} lg={3}>
                            <input
                              type="checkbox"
                              className="mt-2 border border-primary"
                              name="permission"
                              value="I1"
                              style={{ cursor: "pointer" }}
                              // checked={isChecked}
                              onChange={(e) => {
                                handleSelected(e);
                              }}
                            />
                            <span>Read</span>
                          </Col>
                          <Col sm={6} md={4} lg={3}>
                            <input
                              type="checkbox"
                              className="mt-2 border border-primary"
                              name="permission"
                              value="I2"
                              style={{ cursor: "pointer" }}
                              // checked={isChecked}
                              onChange={(e) => {
                                handleSelected(e);
                              }}
                            />
                            <span>Write</span>
                          </Col>
                          <Col sm={6} md={4} lg={3}>
                            <input
                              type="checkbox"
                              className="mt-2 border border-primary"
                              name="permission"
                              value="I3"
                              style={{ cursor: "pointer" }}
                              // checked={isChecked}
                              onChange={(e) => {
                                handleSelected(e);
                              }}
                            />
                            <span>Delete</span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col sm={3}>
                        <Button type="Submit">Add</Button>
                      </Col>
                    </Row>
                  </Row>
                </div>
              </div>

              {/* <div className="bg-white mb-2 px-3 rounded text-dark w-100">
                <Card.Header style={{ width: "100%" }}>
                  <div
                    sm={6}
                    className="w-100"
                    onClick={() => setShowLead(!showLead)}
                    style={{ cursor: "pointer" }}
                  >
                    <h4 className=" text-primary fw-bold">Lead</h4>
                  </div>
                  <div sm={1}>
                    {showLead === false ? (
                      <BsChevronDown
                        className="fs-4 fw-bold"
                        onClick={() => setShowLead(true)}
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <BsChevronUp
                        className="fs-4 fw-bold"
                        onClick={() => setShowLead(false)}
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </div>
                </Card.Header>
                {showLead === true && (
                  <div className="px-4">
                    <Row>
                      <Col sm={12} md={6}>
                        <Form.Label>Enter Role</Form.Label>
                        <Form.Control
                          type="text"
                          name="role"
                          placeholder="Enter Role"
                          className="text-dark border border-primary"
                          onChange={handleChange}
                          autoComplete="off"
                        />
                      </Col>

                      <Row sm={12} className="my-3">
                        {permissions.map((data) => (
                          <Col sm={6} md={4} lg={3}>
                            <input
                              type="checkbox"
                              className="mt-2 border border-primary"
                              name="permission"
                              value={data.name}
                              style={{ cursor: "pointer" }}
                              // checked={isChecked}
                              onChange={(e) => {
                                handleSelected(e, data);
                              }}
                            />
                            <span>{data.name}</span>
                          </Col>
                        ))}
                      </Row>
                    </Row>
                  </div>
                )}
              </div> */}

              {/* <div className="bg-white mb-2 px-3 rounded text-dark w-100">
                <Card.Header style={{ width: "100%" }}>
                  <div
                    sm={6}
                    className="w-100"
                    onClick={() => setShowInvoice(!showInvoice)}
                    style={{ cursor: "pointer" }}
                  >
                    <h4 className=" text-primary fw-bold">Invoice</h4>
                  </div>
                  <div sm={1}>
                    {showInvoice === false ? (
                      <BsChevronDown
                        className="fs-4 fw-bold"
                        onClick={() => setShowInvoice(true)}
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <BsChevronUp
                        className="fs-4 fw-bold"
                        onClick={() => setShowInvoice(false)}
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </div>
                </Card.Header>
                {showInvoice === true && (
                  <div className="px-4">
                    <Row>
                      <Col sm={12} md={6}>
                        <Form.Label>Enter Role</Form.Label>
                        <Form.Control
                          type="text"
                          name="role"
                          placeholder="Enter Role"
                          className="text-dark border border-primary"
                          onChange={handleChange}
                          autoComplete="off"
                        />
                      </Col>

                      <Row sm={12} className="my-3">
                        {permissions.map((data) => (
                          <Col sm={6} md={4} lg={3}>
                            <input
                              type="checkbox"
                              className="mt-2 border border-primary"
                              name="permission"
                              value={data.name}
                              style={{ cursor: "pointer" }}
                              // checked={isChecked}
                              onChange={(e) => {
                                handleSelected(e, data);
                              }}
                            />
                            <span>{data.name}</span>
                          </Col>
                        ))}
                      </Row>
                    </Row>
                  </div>
                )}
                <Row className="mx-2 pb-3">
                  <Col sm={3}>
                    <Button type="submit">Add</Button>
                  </Col>
                </Row>
              </div> */}
            </Col>
          </form>
        </Col>
      </Row>
    </div>
  );
};

export default Roles;
