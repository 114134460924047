import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Pagination, Row } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import { getAllOrgTaxes, getClientTax, getInvoiceByTax } from '../Hook/taxHooks'
import { FiEye } from 'react-icons/fi'
import { Tooltip } from '@material-ui/core'
import { API } from '../../../../backend';
import axios from 'axios';
import SubHeader from '../../../../components/partials/dashboard/HeaderStyle/sub-header';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import Card from '../../../../components/Card';
import { HiOutlineReceiptTax } from 'react-icons/hi';
import { FaRegClock } from 'react-icons/fa';
import useFullPageLoader from '../Hook/useFullPageLoader';
import ActivityBar from '../../../../components/partials/components/activitybar';
import { getAllInvoices } from '../Hook/invoiceHooks';

const TaxDetails = () => {

    const [invoiceData, setInvoiceData] = useState([]);

    const [firmDetails, setFirmDetails] = useState({});
    const [allClients, setAllClients] = useState([]);
    const [allInvoices, setAllInvoices] = useState([]);
    const [taxDetails, setTaxDetails] = useState([]);
    const [singleTaxDetails, setSingleTaxDetails] = useState([]);
    const [collectedGlobaleTax, setCollectedGlobaleTax] = useState(0);
    const [pendingGlobalTax, setpendingGlobalTax] = useState(0);
    const [totalPendingCount, setTotalPendingcount] = useState(0);
    const [taxAmount, setTaxAmount] = useState([]);
    const [invoiceTaxAmount, setInvoiceTaxAmount] = useState([]);
    const [taxAmt, setTaxAmt] = useState('');
    const [taxName, settaxName] = useState({});
    const [showInvoice, setShowInvoice] = useState(true);
    const [showClient, setShowClient] = useState(false);

    const [loader, showLoader, hideLoader] = useFullPageLoader();

    const intiData = Object.freeze({
        global: true,
        taxRates: [
            {
                GST: 10,
            }
        ]
    });
    const [allDataofOrgTaxes, setAllDataofOrgTaxes] = useState([intiData]);
    const showPerPage = 10;
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });


    const orgId = localStorage.getItem('orgID');
    const orgName = localStorage.getItem('orgName');

    useEffect(() => {
        getAllOrgTaxes().then((response) => {
            showLoader();
            if (response.status === 200) {
                setAllDataofOrgTaxes(response.data.data);
                hideLoader();
            } else {
                setAllDataofOrgTaxes([]);
                hideLoader();
            }
        }).catch(() => {
            setAllDataofOrgTaxes([]);
            hideLoader();
        })
    }, []);

    useEffect(() => {
        getInvoiceByTax(invoiceTaxAmount).then((response) => {
            if (response.status === 200) {
                setAllInvoices(response.data.data);
            } else {
                setAllInvoices([]);
            }
        }).catch(() => {
            setAllInvoices([]);
        })
    }, [invoiceTaxAmount]);

    const allInvoicesList = async () => {
        getAllInvoices().then((response) => {
            if (response.status === 200) {
                setInvoiceData(response.data.data);
            } else {
                setInvoiceData('');
            }
        }).catch(() => {
            setInvoiceData('');
        });
    }

    useEffect(() => {
        allInvoicesList();
    }, []);


    useEffect(() => {
        setTaxAmount({
            orgId: orgId,
            tax: taxName
        })
    }, [taxName]);

    useEffect(() => {
        setInvoiceTaxAmount({
            orgId: orgId,
            tax: taxName,
            taxAmt: taxAmt
        })
    }, [taxAmt]);

    useEffect(() => {
        getClientTax(taxAmount).then((response) => {
            if (response.status === 200) {
                setAllClients(response.data.data);
            } else {
                setAllClients([]);
            }
        }).catch(() => {
            setAllClients([]);
        })
    }, [taxAmount]);

    useEffect(() => {
        getInvoiceByTax(invoiceTaxAmount).then((response) => {
            if (response.status === 200) {
                setAllInvoices(response.data.data);
            } else {
                setAllInvoices([]);
            }
        }).catch(() => {
            setAllInvoices([]);
        })
    }, [invoiceTaxAmount]);

    const value = sessionStorage.getItem('mainId');
    const taxIndex = sessionStorage.getItem('taxId');

    const GetSingleFirmDetails = (value, taxIndex) => {
        {
            value ? allDataofOrgTaxes.filter((data, index) => index === parseInt(value)).map((data) => setFirmDetails(data.firmId))
                : allDataofOrgTaxes.filter((data, index) => index === 0).map((data) => setFirmDetails(data.firmId))
        }
        {
            value ? allDataofOrgTaxes.filter((data, index) => index === parseInt(value)).map((data) => setTaxDetails(data.taxRates)) :
                allDataofOrgTaxes.filter((data, index) => index === 0).map((data) => (data) => setTaxDetails(data.taxRates))
        }

        const newArr = [];

        allDataofOrgTaxes.filter((data, index) => index === parseInt(value)).map((data) => newArr.push(data.taxRates));
        setTimeout(() => {
            newArr[0].filter((data, id) => id === parseInt(taxIndex)).map((data) => setSingleTaxDetails(data));
        }, 100)
        const testArr = [];
        setTimeout(() => {
            newArr[0].filter((data, id) => id === parseInt(taxIndex)).map((data) => testArr.push(data));
        }, 100);

        const totalPendig = invoiceData.filter((data) => data.status === 'Pending');
        const totalPaid = invoiceData.filter((data) => data.status === 'Paid' || data.status === 'Partial Paid');

        const subtotal = totalPaid.reduce((prev, curr) => prev + parseFloat(curr.total), 0);
        const pendingSubTotal = totalPendig.reduce((prev, curr) => prev + parseFloat(curr.total), 0);

        setTotalPendingcount(totalPendig.length);

        setTimeout(() => {
            setCollectedGlobaleTax((Object.values(testArr[0]) / 100) * subtotal);
            setpendingGlobalTax((Object.values(testArr[0]) / 100) * pendingSubTotal);
        }, 100);
    }

    useEffect(() => {
        GetSingleFirmDetails(value, taxIndex);
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };

    const handleShowInvoice = () => {
        setShowInvoice(!showInvoice);
        setShowClient(false);
    };

    const handleShowClients = () => {
        setShowClient(!showClient);
        setShowInvoice(false);
    };

    const summary = [
        {
            id: 1,
            amount: collectedGlobaleTax,
            title: "TAX COLLECTED",
            logo: <HiOutlineReceiptTax />,
            count: '',
            desc: ""
        },
        // {
        //     id: 2,
        //     amount: 200,
        //     title: "PAID",
        //     logo: <FiCheckSquare />,
        //     count: 2,
        //     desc: "Paid invoices"
        // },
        {
            id: 3,
            amount: pendingGlobalTax,
            title: "PENDING",
            logo: <FaRegClock />,
            count: totalPendingCount,
            desc: "Pending invoices"
        },
    ];

    const taxOptions = () => {
        return (
            <>
            </>
        )
    };

    return (
        <div>
            <SubHeader title='Tax Details' />
            <div className='d-flex text-dark' style={{ marginTop: "-3%", flexDirection: "row" }}>
                <Row className='w-25 rounded mx-2' style={{ height: "100%", marginRight: "10%", fontSize: "0.8rem" }}>
                    <Col sm={12} className="bg-white rounded">
                        <Row className="py-2">
                            <h5 className="text-primary" style={{ fontWeight: "550" }}> About </h5>
                        </Row>
                        <Row>
                            {
                                firmDetails != undefined ? (
                                    <>
                                        <Col style={{ fontWeight: 550 }} sm={5}>
                                            Firm Name:
                                        </Col>
                                        <Col>{firmDetails === undefined || firmDetails.name === undefined ? " " : firmDetails.name}</Col>
                                    </>
                                ) : (
                                    <>
                                        <Col style={{ fontWeight: 550 }} sm={5}>
                                            Global Tax
                                        </Col>
                                    </>
                                )
                            }
                        </Row>
                        <Row className="my-2">
                            <Col style={{ fontWeight: 550 }} sm={5}>
                                Tax Name:
                            </Col>
                            <Col>{singleTaxDetails === undefined ? "" : Object.keys(singleTaxDetails)}</Col>
                        </Row>
                        <Row>
                            <Col style={{ fontWeight: 550 }} sm={5}>
                                Rate (%):
                            </Col>
                            <Col>{singleTaxDetails === undefined ? "" : Object.values(singleTaxDetails)}</Col>
                        </Row>
                        <Row className="my-2">
                            <Col style={{ fontWeight: 550 }} sm={5}>
                                Collected Tax:
                            </Col>
                            <Col sm> ₹ {collectedGlobaleTax.toFixed(2)}</Col>
                        </Row>
                    </Col>
                </Row>
                <Row className={`h-100 rounded mx-2 mb-5`}
                    style={{ minWidth: "50%", width: "70%", maxWidth: "80%" }}
                >
                    <div className='m-0 p-0'>
                        <div className='m-0 p-0 mb-4 d-flex justify-content-between align-items-start'>
                            {/* <Row className='w-100 m-0 p-0'> */}
                            {
                                summary.map((data, index) => (
                                    <Col className='w-25 bg-white m-0 mx-2 border border-white px-2 py-2 rounded summaryCard' style={{ boxShadow: "0px 0px 10px #c8c9ca" }} key={index}>
                                        <Row className='w-100 m-0 p-0'>
                                            <Col sm={8}>
                                                <div className='fs-3'>{"₹"} {data.amount.toFixed(2)}</div>
                                                <div className='text-secondary fs-5'>{data.title}</div>
                                            </Col>
                                            <Col sm={4} className='text-end'>
                                                <span className='px-3 py-3 m-0 rounded rounded-circle text-primary fs-2 text-center'>
                                                    {data.logo}
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row className='w-100 m-0 mt-3 p-0'>
                                            <Col sm={12}>
                                                <span
                                                    className={`m-0 py-1 px-3 text-white rounded fw-semibold`}
                                                    style={{ background: data.title === "PAID" ? 'green' : data.title === "PENDING" ? '#FFB802' : '#fff' }}
                                                >{data.count}</span>
                                                <span className='m-0 ms-2 p-0 text-secondary' style={{ fontSize: "0.8rem" }}>{data.desc}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                ))
                            }
                            {/* </Row> */}
                        </div>

                        <Row className='m-0 p-0 rounded bg-white'>
                            <Card.Header className="bg-primary px-0 ps-4" style={{ width: "100%" }}>
                                <Row className='w-100'>
                                    <Col sm={11} style={{ cursor: "pointer" }} onClick={() => handleShowInvoice()}>
                                        <h4 className=" text-white">Invoices</h4>
                                    </Col>
                                    <Col sm={1} className="m-0 p-0 text-end">
                                        {showInvoice === false ? (
                                            <BsChevronDown className="fs-4 fw-bold text-white" onClick={() => handleShowInvoice()} style={{ cursor: "pointer" }} />
                                        ) : (
                                            <BsChevronUp className="fs-4 fw-bold text-white" onClick={() => setShowInvoice(false)} style={{ cursor: "pointer" }} />
                                        )}
                                    </Col>
                                </Row>
                            </Card.Header>
                            {
                                showInvoice && (
                                    <div>
                                        <div className="table-responsive mt-2" style={{ minHeight: "40vh" }}>
                                            <table className="" style={{ width: "100%" }}>
                                                <thead>
                                                    <tr className="h-20 text-primary" style={{ borderBottom: "1px solid #a09fa3" }}>
                                                        {/* <th className="fw-bold" style={{ fontSize: "0.9rem" }}></th> */}
                                                        <th className="fw-bold w-5" style={{ fontSize: "0.9rem" }}>Invoice No.</th>
                                                        <th className="fw-bold w-25" style={{ fontSize: "0.9rem" }}>Firm Name</th>
                                                        {/* <th className="fw-bold w-25" style={{ fontSize: "0.9rem" }}>Client Name</th> */}
                                                        <th className="fw-bold w-25" style={{ fontSize: "0.9rem" }}>Tax Collected</th>
                                                        <th className="fw-bold w-20 text-end" style={{ fontSize: "0.9rem" }}>View</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        allInvoices
                                                            .slice(pagination.start, pagination.end)
                                                            .map((data, index) => (
                                                                <tr style={{ borderBottom: "1px solid #c8c9ca" }} key={index}>
                                                                    <td className='py-1 ps-2'>{index + 1}</td>
                                                                    <td className='w-50 ps-3'>{
                                                                        data.invoiceNo === undefined ? '' : data.invoiceNo
                                                                    }</td>
                                                                    <td>{Object.values(data.tax) === undefined ? '' : Object.values(data.tax)}</td>
                                                                    <td className='text-center'>
                                                                        <Tooltip title="View" key="invoice">
                                                                            <Link to={`/${orgName}/dashboard/invoice/invoice-details/${data.invoiceId}`}>
                                                                                <FiEye className='text-primary fs-5' style={{ cursor: "pointer" }} />
                                                                            </Link>
                                                                        </Tooltip>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                    }
                                                </tbody>
                                            </table>
                                            {
                                                allInvoices.length === 0 && (
                                                    <div className='mt-4 fs-4 text-center text-secondary'>
                                                        No Invoices To Show
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="my-5">
                                            <Pagination
                                                showPerPage={showPerPage}
                                                onPaginationChange={onPaginationChange}
                                                total={allInvoices.length}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                        </Row>

                        <Row className='m-0 p-0 mt-3 rounded bg-white'>
                            <Card.Header className="bg-primary px-0 ps-4" style={{ width: "100%" }}>
                                <Row className='w-100'>
                                    <Col sm={11} style={{ cursor: "pointer" }} onClick={() => handleShowClients()}>
                                        <h4 className=" text-white">Clients</h4>
                                    </Col>
                                    <Col sm={1} className="m-0 p-0 text-end">
                                        {showClient === false ? (
                                            <BsChevronDown className="fs-4 fw-bold text-white" onClick={() => handleShowClients()} style={{ cursor: "pointer" }} />
                                        ) : (
                                            <BsChevronUp className="fs-4 fw-bold text-white" onClick={() => setShowClient(false)} style={{ cursor: "pointer" }} />
                                        )}
                                    </Col>
                                </Row>
                            </Card.Header>
                            {
                                showClient && (
                                    <div>
                                        <div className="table-responsive mt-2" style={{ minHeight: "40vh" }}>
                                            <table className="" style={{ width: "100%" }}>
                                                <thead>
                                                    <tr className="h-20 text-primary" style={{ borderBottom: "1px solid #a09fa3" }}>
                                                        <th className="fw-bold w-5" style={{ fontSize: "0.9rem" }}>S No.</th>
                                                        <th className="fw-bold w-50" style={{ fontSize: "0.9rem" }}>Client Name</th>
                                                        <th className="fw-bold w-25" style={{ fontSize: "0.9rem" }}>Tax Collected</th>
                                                        <th className="fw-bold w-20 text-end" style={{ fontSize: "0.9rem" }}>View</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        allClients
                                                            .slice(pagination.start, pagination.end)
                                                            .map((data, index) => (
                                                                <tr style={{ borderBottom: "1px solid #c8c9ca" }} key={index}>
                                                                    <td className='py-1 ps-2'>{index + 1}</td>
                                                                    <td className='w-50 ps-3'>{
                                                                        data.clientFirmName ? data.clientFirmName : `${data.firstName === undefined ? '' : data.firstName} ${data.lastName === undefined ? '' : data.lastName}`
                                                                    }</td>
                                                                    <td>{data.sum}</td>
                                                                    <td className='text-center'>
                                                                        <Tooltip title="View" key="invoice">
                                                                            <Link to={`/${orgName}/dashboard/client/about-client/${data.client_id}`}>
                                                                                <FiEye className='text-primary fs-5' style={{ cursor: "pointer" }} />
                                                                            </Link>
                                                                        </Tooltip>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                    }
                                                </tbody>
                                            </table>
                                            {
                                                allClients.length === 0 && (
                                                    <div className='mt-4 fs-4 text-center text-secondary'>
                                                        No Clients To Show
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="my-5">
                                            <Pagination
                                                showPerPage={showPerPage}
                                                onPaginationChange={onPaginationChange}
                                                total={allInvoices.length}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                        </Row>
                    </div>
                </Row>
                <ActivityBar options={taxOptions} list={true} />
            </div>
            {loader}
        </div>
    )
}

export default TaxDetails
