import { Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { AiOutlineDelete } from 'react-icons/ai'
import { MdAddCircle, MdClear } from 'react-icons/md'
import { Link, useParams } from 'react-router-dom'
import { getAllTaxes, getGlobalTaxes } from '../Hook/taxHooks'
import { createNewProduct, getFirmById } from '../Hook/firmHooks'
import { toast } from 'react-hot-toast'
import { createProduct, getAllProducts } from '../Hook/productHooks'
import { ErrorToast, SuccessToast, WarningToast } from '../../components/toast'
import { addRecentActivity } from '../Hook/recentActivityHooks'

const CreateProducts = ({ getProducts, allFirms, showCreateProducts, setShowCreateProducts, firmTaxes, firmId, getAllRecentActivities }) => {

    const [form, setForm] = useState([]);
    const [allGlobalTaxes, setAllGlobalTaxes] = useState([]);
    const [validate, setValidate] = useState(false);
    const [showSelect, setShowSelect] = useState(false);
    const [showCustomize, setShowCustomize] = useState(false);
    const [showTaxField, setShowTaxField] = useState(false);
    const [showHSNField, setShowHSNField] = useState(false);
    const [showSACField, setShowSACField] = useState(false);
    const [showDiscontField, setShowDiscountField] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [firmid, setFirmId] = useState('');
    const [firmtaxes, setFirmTaxes] = useState([]);
    const [addProductInInventory, setAddProductInInventory] = useState(false);
    const allTaxes = [...firmtaxes, ...allGlobalTaxes];
    const [firmName, setFirmName] = useState('');
    const orgId = localStorage.getItem('orgID');

    const datetimeString = new Date();
    const dateObject = new Date(datetimeString);

    const formattedDate = `${dateObject.getDate()}/${dateObject.getMonth() + 1}/${dateObject.getFullYear()}`;
    const formattedTime = dateObject.toLocaleTimeString();

    useEffect(() => {
        getFirmById(firmId).then((response) => {
            if (response.status === 200) {
                const data = response.data.data[0];
                data?.registeredFirmName ? setFirmName(data?.registeredFirmName) : setFirmName(data?.name)
            } else setFirmName('');
        }).catch(() => setFirmName(''));
    }, [firmId]);

    useEffect(() => {
        setTimeout(() => {
            setFirmId(firmId.length > 0 ? firmId : '');
        }, 2000)
    }, [firmId]);

    const getFirmTaxes = () => {
        getAllTaxes(firmId).then((response) => {
            if (response.status === 200) {
                response.data.data.length > 0 ? setFirmTaxes(response.data.data[0].taxRates) : setFirmTaxes([]);
            }
            else {
                setFirmTaxes([]);
            }
        }).catch(() => {
            setFirmTaxes([]);
        });
    }

    const getSingleFirm = (id) => {
        setFirmId(id);
        getFirmById(id);
        getFirmTaxes();
        // getLastInvoiceNumber(id, setLastInvoice, setAllInvoiceNumbers);
    }

    // const id = useParams();
    // const firmId = id;

    useEffect(() => {
        getGlobalTaxes().then((response) => {
            if (response.status === 200) response.data.data.length > 0 ? setAllGlobalTaxes(response.data.data[0].taxRates) : setAllGlobalTaxes([])
            else setAllGlobalTaxes([]);
        });
    }, []);

    const handleShowHSN = () => {
        setShowHSNField(!showHSNField);
        setShowSACField(false);
    }

    const handleShowSAC = () => {
        setShowSACField(!showSACField);
        setShowHSNField(false);
    }

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
            delete: false,
            firmId: firmid,
            orgId: orgId
        })
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setBtnDisabled(true);
        const testForm = e.currentTarget;
        if (testForm.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        };
        setValidate(true);
        if (form.name && form.price) {
            createProduct(form).then((response) => {
                if (response.status === 201) {

                    const activityForm = {
                        module: ['firm'],
                        entityId: [firmId],
                        activity: 'Product Added',
                        activityDesc: `New product ${form?.name} added in firm ${firmName}`,
                        createdDate: formattedDate,
                        createdTime: formattedTime,
                        userId: localStorage.getItem('userId')
                    };

                    addRecentActivity(activityForm).then((response) => response.status === 201 && getAllRecentActivities());

                    SuccessToast("Product Created Successfully");
                    setShowCreateProducts(false);
                    setValidate(false);
                    setBtnDisabled(false);
                    getProducts();
                }
                else {
                    ErrorToast("Unable to create product");
                    setBtnDisabled(false);
                }
            }).catch((err) => {
                err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast("Something went wrong");
            })
        } else {
            ErrorToast("Fill all the required fileds");
            setBtnDisabled(false);
        }
    };

    const handleSelectHSNorSAC = (e) => {
        if (e.target.value === 'hsn') {
            setShowHSNField(true);
            setShowSACField(false);
        } else if (e.target.value === 'sac') {
            setShowSACField(true);
            setShowHSNField(false);
        }
    };

    return (
        <>
            <Modal centered size='lg' show={showCreateProducts} onHide={() => setShowCreateProducts(false)}>
                <Modal.Header className="bg-primary">
                    <Col sm={11} className="text-white">
                        <h4 className="text-white">Create Product</h4>
                    </Col>
                    <Col sm={1} className="text-end text-white">
                        <MdClear className="fs-4" onClick={() => setShowCreateProducts(false)} style={{ cursor: "pointer" }} />
                    </Col>
                </Modal.Header>
                <Form noValidate validated={validate} onSubmit={handleSubmit}>
                    <Modal.Body className="my-2">
                        <Row className='mb-3'>
                            {
                                firmId.length === 0 ? (
                                    <Col sm={6} className='text-start'>
                                        <Form.Label>Firm Name</Form.Label>
                                        <Form.Select className='border border-primary w-50' onChange={(e) => getSingleFirm(e.target.value)}>
                                            <option>Select Firm</option>
                                            {
                                                allFirms.map((data, index) => (
                                                    <option key={index} value={data._id}>{data.name}</option>
                                                ))
                                            }
                                        </Form.Select>
                                    </Col>

                                ) : null
                            }
                            <Col sm={6} className='d-flex justify-content-between align-items-start'>
                                <Form.Group className='w-25'>
                                    <Form.Label className={`d-block`}>Add in Inventory</Form.Label>
                                    <Form.Check.Input type="checkbox" name="inventory"
                                        onChange={() => setAddProductInInventory(!addProductInInventory)}
                                        className={`${!validate && 'border border-primary'} rounded`}
                                        checked={addProductInInventory}
                                    />
                                </Form.Group>
                                {
                                    addProductInInventory ? (
                                        <Form.Group className='w-75 ps-3'>
                                            <Form.Label className='d-block'>Add Quantity</Form.Label>
                                            <input type="number" name="quantity" placeholder='0' className='p-2 w-100 rounded border border-primary' defaultValue={1} onChange={handleChange} />
                                        </Form.Group>
                                    ) : null
                                }

                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Form.Group className='col-md-6 col-sm-6'>
                                <Form.Label className={(validate && !form.name) && 'text-danger'}>Product Name*</Form.Label>
                                <Form.Control type="text" name="name" onChange={handleChange} className={`${!validate && 'border border-primary'} rounded`} placeholder='Product Name' required />
                                <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className='col-md-6'>
                                <Form.Label className={(validate && !form.price) && 'text-danger'}>Price*</Form.Label>
                                <Form.Control type="number" name="price" onChange={handleChange} className={`${!validate && 'border border-primary'} rounded`} placeholder='0' required />
                                <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className='col-sm-6 mt-2'>
                                <Form.Label>Product Type</Form.Label>
                                <input type="text" name="type" onChange={handleChange}
                                    className='w-100 p-2 border border-primary rounded' style={{ borderRadius: "5px" }} placeholder='Product Type' required />
                            </Form.Group>
                            <Form.Group className='col-sm-6 mt-2'>
                                <Form.Label>Category</Form.Label>
                                <input type="text" name="cat" onChange={handleChange}
                                    className='w-100 p-2 border border-primary rounded' style={{ borderRadius: "5px" }} placeholder='Category' required />
                            </Form.Group>
                            {/* {
                                showTaxField && (
                                    )
                            } */}

                            <Form.Group className='col-md-4 mt-2'>
                                <Form.Label>Tax Rate</Form.Label>
                                {
                                    showSelect === false ? (
                                        <select name="tax"
                                            onChange={() => setShowSelect(true)}
                                            onInput={handleChange}
                                            className='w-100 p-2 border border-primary bg-white rounded' style={{ borderRadius: "5px" }}
                                        >
                                            <option value={0}>Select Tax</option>
                                            <option value={0}>Remove</option>
                                            {
                                                allTaxes.map((data, index) => (
                                                    <option value={Object.values(data)} key={index}>
                                                        {Object.keys(data)} {Object.values(data)}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    ) : (
                                        <input type="number" name="taxRate" defaultValue={form.tax} onClick={() => setShowSelect(false)}
                                            className='w-100 p-2 border border-primary rounded' style={{ borderRadius: "5px" }} placeholder='0'
                                        />
                                    )
                                }
                            </Form.Group>
                            {/* {
                                showHSNField && (
                                    )
                                } */}

                            {
                                <Form.Group className='col-md-4 mt-2'>
                                    <Form.Label>HSN / SAC</Form.Label>
                                    <Form.Select className='border border-primary' onChange={handleSelectHSNorSAC}>
                                        <option>Select</option>
                                        <option value="hsn">HSN</option>
                                        <option value="sac">SAC</option>
                                    </Form.Select>
                                </Form.Group>
                            }

                            {
                                showHSNField ? (
                                    <Form.Group className='col-md-4 mt-2'>
                                        <Form.Label>HSN</Form.Label>
                                        <input type="text" name="hsn" onChange={handleChange} maxLength={8}
                                            className='w-100 p-2 border border-primary rounded' style={{ borderRadius: "5px" }} placeholder='HSN'
                                        />
                                    </Form.Group>
                                ) : null
                            }

                            {
                                showSACField ? (
                                    <Form.Group className='col-md-4 mt-2'>
                                        <Form.Label>SAC</Form.Label>
                                        <input type="text" name="sac" onChange={handleChange} maxLength={8}
                                            className='w-100 p-2 border border-primary rounded' style={{ borderRadius: "5px" }} placeholder='SAC'
                                        />
                                    </Form.Group>
                                ) : null
                            }

                            <Form.Group className='col-sm-12 mt-2'>
                                <Form.Label>Product Description</Form.Label>
                                <input type="text" name="desc" onChange={handleChange}
                                    className='w-100 p-2 border border-primary rounded' style={{ borderRadius: "5px" }} placeholder='Product Description' />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Col sm={2} className='mt-4 mx-0 px-0'>
                                <Button type='submit' className='bg-white m-0 p-0 border border-white' onMouseOver={handleChange}>
                                    <span className='w-25 mt-3 bg-primary text-white fs-5 py-2 px-4 rounded'
                                        style={{ cursro: "pointer", display: btnDisabled ? 'not-allowed' : 'pointer' }}>Add</span>
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Form>
            </Modal>
            {/* <Modal size='sm' show={showCustomize} onHide={() => setShowCustomize(false)} style={{ boxShadow: "0px 0px 10px #c8c9ca" }}>
                <Modal.Header className="bg-primary">
                    <Col sm={11} className="text-white">
                        <h4 className="text-white">Customize Fields</h4>
                    </Col>
                    <Col sm={1} className="text-end text-white">
                        <MdClear className="fs-4" onClick={() => setShowCustomize(false)} style={{ cursor: "pointer" }} />
                    </Col>
                </Modal.Header>
                <Modal.Body className="my-2">
                    <div className="ms-2 w-100" style={{}} >
                        <Row className="mx-5">
                            <Col sm={2} className="text-end" style={{ cursor: "pointer" }} onClick={() => setShowTaxField(!showTaxField)}>
                                <Form.Check.Input type="checkbox" name="taxColumn" onChange={() => setShowTaxField(!showTaxField)} checked={showTaxField} style={{ cursor: "pointer" }} />
                            </Col>
                            <Col sm={2} onClick={() => setShowTaxField(!showTaxField)} style={{ cursor: "pointer" }}>
                                <label style={{ fontSize: '0.8rem', cursor: "pointer" }}>Tax</label>
                            </Col>
                        </Row>

                        <Row className="mx-5">
                            <Col sm={2} onClick={() => setShowDiscountField(!showDiscontField)} className="text-end" style={{ cursor: "pointer" }}>
                                <Form.Check.Input type="checkbox" name="discountColumn" onChange={() => setShowDiscountField(!showDiscontField)} checked={showDiscontField} style={{ cursor: "pointer" }} />
                            </Col>
                            <Col sm={2} onClick={() => setShowDiscountField(!showDiscontField)}>
                                <label style={{ fontSize: '0.8rem', cursor: "pointer" }}>Discounts</label>
                            </Col>
                        </Row>

                        <Row className="mx-5">
                            <Col sm={2} onClick={handleShowHSN} className="text-end" style={{ cursor: "pointer" }}>
                                <Form.Check.Input type="checkbox" name="discountColumn" onChange={handleShowHSN} checked={showHSNField} style={{ cursor: "pointer" }} />
                            </Col>
                            <Col sm={2} onClick={handleShowHSN}>
                                <label style={{ fontSize: '0.8rem', cursor: "pointer" }}>HSN</label>
                            </Col>
                        </Row>

                        <Row className="mx-5">
                            <Col sm={2} onClick={handleShowSAC} className="text-end" style={{ cursor: "pointer" }}>
                                <Form.Check.Input type="checkbox" name="discountColumn" onChange={handleShowSAC} checked={showSACField} style={{ cursor: "pointer" }} />
                            </Col>
                            <Col sm={2} onClick={handleShowSAC}>
                                <label style={{ fontSize: '0.8rem', cursor: "pointer" }}>SAC</label>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal> */}
        </>
    )
}

export default CreateProducts