
import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { Row, Col, Form, Modal } from "react-bootstrap";

import LeadActivities from "./leadActivities";
import ActivityForm from "./lead-main/lead-forms/activity-form";
import EmailForm from "./lead-main/lead-forms/email-form";

import { getOrgDetails } from "../Hook/orgHooks";
import { ErrorToast, SuccessToast, WarningToast } from "../../components/toast";
import { getActivitiesByType, getAllActivities, getLeadById, updateLead, updateLeadStage } from "../Hook/leadHooks";

import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { MdClear, MdDeleteOutline } from "react-icons/md";

import Loader from "../../../../components/Loader";
import LeadSubheader from "../../../../components/partials/dashboard/HeaderStyle/lead-subheader";
import LeadActivityBar from "../../../../components/partials/components/LeadActivybar";
import ColoredTabs from "../../components/tabs";
import { getUserById } from "../Hook/userHooks";
import { DetailsOutlined } from "@mui/icons-material";

const LeadDetails = () => {
  const { id } = useParams();
  const [details, setDetails] = useState({});
  const [userPermissions, setUserPermissions] = useState([]);
  const [deletePermission, setDeletePermission] = useState(true);
  const [updatePermission, setUpdatePermission] = useState(true);
  const [status, setStatus] = useState("");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showDrop, setShowDrop] = useState(false);
  const [showStagesDropdown, setShowStagesDropdown] = useState(false);
  const [load, setLoad] = useState(true);
  const [allActivities, setAllActivities] = useState([]);
  const [type, setType] = useState('All');

  const [isSelecting, setIsSelecting] = useState(false);
  const [showUpdateStatus, setShowUpdateStatus] = useState(false);
  const [showUpdateStage, setShowUpdateStage] = useState(false);
  const [showChangePipeline, setShowChangePipeline] = useState(false);
  const [showTransfer, setShowTransfer] = useState(false);
  const [assignedTo, setAssignedTo] = useState("");
  const [isOpenAssigneeList, setIsOpenAssigneeList] = useState(false);
  const [transferForm, setTransferForm] = useState([]);
  const [showDelete, setShowDelete] = useState(false);

  const [activeStep, setActiveStep] = useState(1);
  const [allPipelines, setAllPipelines] = useState([]);
  const [allStages, setAllStages] = useState([]);
  const [pipeline, setPipeline] = useState('');
  const [showPipelineDropdown, setShowPipelineDropdown] = useState(false);
  const [selectedTab, setSelectedTab] = useState('Activity');
  const [showActivityForm, setShowActivityForm] = useState(false);

  const allTabs = ['About', 'Activity', 'Email'];
  const allStatuses = ['In Process', 'Won', 'Hold', 'Lost'];

  const breakPoint = 970;
  const history = useHistory();
  const orgId = localStorage.getItem('orgID');
  const orgName = localStorage.getItem("orgName");
  const userId = localStorage.getItem("userId");

  const allAssignees = [
    { id: 1, name: 'Name 1' },
    { id: 2, name: 'Name 2' },
    { id: 3, name: 'Name 3' },
    { id: 4, name: 'Name 4' },
    { id: 5, name: 'Name 5' },
  ];

  useEffect(() => {
    getUserById(userId).then((response) => {
      if (response.status === 200) setUserPermissions(response?.data?.data?.permissions);
      else setUserPermissions([]);
    }).catch(() => setUserPermissions([]));
  }, [userId]);

  useEffect(() => {
    const update = userPermissions.length > 0 && userPermissions.some((permission) => permission.module === 'lead' && permission.action.includes('Update'));
    const deletePermit = userPermissions.length > 0 && userPermissions.some((permission) => permission.module === 'lead' && permission.action.includes('Delete'));

    setUpdatePermission(update);
    setDeletePermission(deletePermit);
  }, [userPermissions]);


  useEffect(() => {
    getLeadById(id).then((response) => {
      if (response.status === 200) {
        // setTimeout(() => {
        setLoad(false);
        setDetails(response?.data?.data);
        setStatus(response?.data?.data?.status);
        setPipeline(response?.data?.data?.pipeline?.department);
        response?.data?.data?.stageHistory ? setAllStages(response?.data?.data?.stageHistory) : setAllStages([]);
        // }, 500);
      } else {
        setLoad(false);
        // setDetails({});
        setStatus('');
        setPipeline('');
      }
    }).catch(() => {
      setLoad(false);
      // setDetails({});
      setStatus('');
      setPipeline('');
    });
  }, [id]);

  useEffect(() => {
    getAllActivities(id).then((response) => {
      if (response.status === 200) {
        setAllActivities(response.data.data);
      } else {
        setAllActivities([]);
      }
    }).catch(() => {
      setAllActivities([]);
    })
  }, [id]);

  useEffect(() => {
    const escKeyHandler = (event) => {
      event.keyCode === 27 && setShowActivityForm(false);
    };
    document.addEventListener('keydown', escKeyHandler);
    return () => {
      document.removeEventListener('keydown', escKeyHandler)
    };
  }, []);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getLeadDetails = () => {
    getLeadById(id).then((response) => {
      if (response.status === 200) {
        // setTimeout(() => {
        setLoad(false);
        setDetails(response?.data?.data);
        setStatus(response?.data?.data?.status);
        setPipeline(response?.data?.data?.pipeline?.department)
        // }, 500);
      } else {
        setLoad(false);
        setDetails({});
        setStatus('');
        setPipeline('');
      }
    }).catch(() => {
      setLoad(false);
      setDetails({});
      setStatus('');
      setPipeline('');
    });
  };

  const leadDetails = [
    { title: 'Title', about: details?.title },
    { title: 'Assigned To', about: details?.pipeline?.assignTo },
    { title: 'Closure Date', about: details?.closureDate },
    { title: "Created Date", about: details?.createdDat },
    { title: "Stage", about: details?.stag },
    { title: "Estimated Worth", about: details?.estimatedWort }
  ];

  const clientDetails = [
    { title: "Name", about: `${details?.clientFName} ${details?.clientLName}` },
    { title: "Email", about: details?.clientEmail },
    { title: "Phone", about: details?.clientPhone }
  ];

  const orgDetails = [
    { title: "Name", about: details?.orgDetails?.orgName },
    { title: "Email", about: details?.orgDetails?.orgEmail },
    { title: "Phone", about: details?.orgDetails?.orgPhone }
  ];

  let testStagesVar = [];

  const styleHandle = (Selected) => {
    console.log("selected", Selected === status);
    if (Selected === "In Process") {
      return {
        width: "1.5rem",
        height: "1.5rem",
        border: '1px solid blue',
        background: status === Selected ? 'blue' : '#fff',
        cursor: status === Selected ? "not-allowed" : "pointer",
      };
    } else if (Selected === "Won") {
      return {
        width: "1.5rem",
        height: "1.5rem",
        border: '1px solid #10813B',
        background: status === Selected ? '#10813B' : '#fff',
        cursor: status === Selected ? "not-allowed" : "pointer",
      };
    } else if (Selected === "Hold") {
      return {
        width: "1.5rem",
        height: "1.5rem",
        border: '1px solid #FFE600',
        background: status === Selected ? '#FFE600' : '#fff',
        cursor: status === Selected ? "not-allowed" : "pointer",
      };
    } else if (Selected === "Lost") {
      return {
        width: "1.5rem",
        height: "1.5rem",
        border: '1px solid #A70200',
        background: status === Selected ? '#A70200' : '#fff',
        cursor: status === Selected ? "not-allowed" : "pointer",
      };
    }
  };

  useEffect(() => {
    getOrgDetails().then((response) => {
      if (response.status === 200) {
        const allData = response.data.data;

        console.log("response", response.data.data);

        const testStatges = allData.orgLeadStages;
        // testStagesVar = testStatges.filter(data => data.length > 0);
        // setAllStages(...allStages, testStagesVar);

        // setAllStages(testStatges)

        const testPipelines = allData.orgDept;
        let testPipelinesVar = [];
        testPipelinesVar = testPipelines.filter(data => data.length > 0);
        setAllPipelines(...allPipelines, testPipelinesVar);
      } else setAllStages([])
    }).catch(() => setAllStages([]));
  }, []);

  const getLeadActivites = () => {
    getAllActivities(id).then((response) => {
      if (response.status === 200) {
        setAllActivities(response.data.data);
      } else {
        setAllActivities([]);
      }
    }).catch(() => {
      setAllActivities([]);
    })
  };

  const leadActivitiesByType = (type) => {
    setType(type);
    getActivitiesByType(type, id).then((response) => {
      if (response.status === 200) {
        setAllActivities(response.data.data);
        type === 'All' && getLeadActivites();
      } else setAllActivities([]);
    }).catch(() => setAllActivities([]));
  };

  const updateStatus = async (updatedStatus) => {
    const form = { status: updatedStatus };
    updateLead(id, form).then((response) => {
      if (response.status === 200) {
        SuccessToast('Lead status updated successfully');
        getLeadDetails();
      } else ErrorToast('Something went wrong');
    }).catch(() => ErrorToast('Something went wrong'));
  };

  const updateStage = (step, index) => {
    const currentStage = step;
    const previousStage = allStages.filter((data, i) => i === index - 1);

    if (step.stageName === details.stage) {
      ErrorToast("Stage Already Been Initialized");
    } else {
      const startDate = new Date(previousStage[0].startDate);
      const endDate = new Date();

      const timeDifference = endDate.getTime() - startDate.getTime();
      const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const totalDays = daysDifference === 0 ? 1 : daysDifference;

      const form = {
        orgId: orgId,
        stage: step.stageName,
        stageHistory: [
          {
            stageName: previousStage[0].stageName,
            days: totalDays,
            startDate: previousStage[0].startDate,
            endDate: new Date()
          },
          {
            stageName: currentStage.stageName,
            days: 0,
            startDate: new Date(),
            endDate: ""
          }]
      };

      updateLeadStage(id, form).then((response) => {
        if (response.status === 200) {
          SuccessToast("Stage Updated Successfully");
          getLeadDetails();
        } else ErrorToast("Unalble to update stage");
      });
    }
  };

  const changePipeline = (newPipeline) => {
    if (newPipeline === pipeline) {
      ErrorToast('Pipeline already been initialized');
    } else {
      const form = { orgId, pipeline: { department: newPipeline } }
      updateLead(id, form).then((response) => {
        if (response.status === 200) {
          SuccessToast('Pipeline updated successfully');
          setShowPipelineDropdown(false);
          getLeadDetails();
        } else ErrorToast('Unable to update pipeline');
      }).catch((err) => err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Something went wrong'));
    }
  };

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const handleInputChangeAssignee = (e) => {
    setAssignedTo(e.target.value);
    setIsOpenAssigneeList(true);
    setTransferForm({
      ...transferForm,
      assignTo: e.target.value
    })
  };

  const handleOptionClickAssignee = (i) => {
    setAssignedTo(i);
    setTransferForm({ ...transferForm, assignTo: i });
    setIsOpenAssigneeList(false);
  };

  const filteredAssigneeOptions = allAssignees.filter((option) => (option.name).toLowerCase().includes(assignedTo.toLowerCase()));

  const submitTransferLead = (e) => {
    e.preventDefault();
    const form = { pipeline: { assignTo: assignedTo } };
    updateLead(id, form).then((response) => {
      if (response.status === 200) {
        SuccessToast('Lead transferred successfully');
        setAssignedTo('');
        setIsOpenAssigneeList(false);
        setShowTransfer(false);
        getLeadDetails();
      } else ErrorToast('Unable to transfer lead');
    }).catch((err) => err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Something went wrong'));
  };

  const handleDeleteLead = () => {
    const form = { delete: true };
    updateLead(id, form).then((response) => {
      if (response.status === 200) {
        SuccessToast('Lead Deleted Successfully');
        setShowDelete(false);
        setTimeout(() => {
          history.push(`/${orgName}/dashboard/lead/lead-list`);
        }, 1000);
      } else ErrorToast('Unable to delete the lead')
    }).catch((err) => err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Something went wrong'));
  };

  const handleCloseOptions = () => {
    showDrop && setShowDrop(false);
    (!isSelecting && (showUpdateStage && setShowUpdateStage(false)));
    (!isSelecting && (showChangePipeline && setShowChangePipeline(false)));
    (!isSelecting && (showUpdateStatus && setShowUpdateStatus(false)));
    isOpenAssigneeList && setIsOpenAssigneeList(false);
    showStagesDropdown && setShowStagesDropdown(false);
    showPipelineDropdown && setShowPipelineDropdown(false);
  };

  const leadOptions = () => {
    return (
      <div style={{ fontSize: "0.8rem" }}>
        {
          updatePermission ? (
            <>
              <Row className="text-dark mx-3 py-2 ps-2 pe-4 my-2" style={{ position: 'relative', cursor: "pointer" }}>
                <Col sm={9} onClick={() => setShowUpdateStatus(!showUpdateStatus)} className="" style={{ fontWeight: "550", fontSize: "0.9rem" }}>Update Status </Col>
                <Col sm={2} onClick={() => setShowUpdateStatus(!showUpdateStatus)} className="m-0 p-0  pe-2  fs-4 text-end" style={{ fontWeight: "550", fontSize: "0.9rem" }}>+</Col>
                {
                  showUpdateStatus ? (
                    <Col className="bg-white d-flex justify-content-center align-items-center py-3" style={{ zIndex: 9, width: "90%", top: 40, position: "absolute", boxShadow: "0px 5px 32px -23px #4b4b4bbf" }}>
                      <div onMouseOver={() => setIsSelecting(true)} onMouseOut={() => setIsSelecting(false)} className="ms-2 w-100" style={{ overflow: "hidden" }}>
                        <div className="d-flex justify-content-between align-items-center">
                          {
                            allStatuses.map((data, index) => (
                              <div key={index}>
                                <div className="text-center text-dark rounded-circle"
                                  onClick={() => status !== data ? updateStatus(data) : ErrorToast("Status Already been initialized")}
                                  style={styleHandle(data)}
                                >
                                </div>
                                <span className="m-0 p-0 fw-semibold" style={{ fontSize: "0.7rem" }}>{data}</span>
                              </div>
                            ))
                          }
                        </div>
                      </div >
                    </Col>
                  ) : null
                }
              </Row >

              <Row className="mx-3 my-2" style={{ position: "relative" }}>
                <Col sm={12} className="ps-4 m-0 p-0 py-2" onClick={() => setShowUpdateStage(!showUpdateStage)} style={{ cursor: "pointer", borderRadius: "5px" }}>
                  <div className="m-0 p-0 row">
                    <Col sm={9} className="px-0 " style={{ fontWeight: "550", fontSize: "0.9rem" }}>Update Stage</Col>
                    <Col sm={2} className="m-0 p-0  pe-2  fs-4 text-center" style={{ fontWeight: "550", fontSize: "0.9rem" }}>+</Col>
                  </div>
                </Col>
                {
                  showUpdateStage ? (
                    <Col className="bg-white d-flex justify-content-center align-items-center py-3 flex-column" style={{ zIndex: 9, top: 40, position: "absolute", boxShadow: "0px 5px 32px -23px #4b4b4bbf" }}>
                      <div className="w-100 p-2 fs-6 text-dark border border-primary rounded-5" onMouseOver={() => setIsSelecting(true)} onMouseOut={() => setIsSelecting(false)} onClick={() => setShowStagesDropdown(true)} style={{ cursor: "pointer" }}>
                        <Row className="ps-3 pe-4">
                          <Col sm={9}>{details.stage}</Col>
                          <Col sm={2} className="text-end m-0 p-0 text-dark">
                            {
                              showStagesDropdown ? (
                                <BsChevronUp className="fs-5 text-end text-dark" style={{ cursor: "pointer" }} />
                              ) : (
                                <BsChevronDown className="fs-5 text-end text-dark" style={{ cursor: "pointer" }} />
                              )
                            }
                          </Col>
                        </Row>
                      </div>
                      {
                        showStagesDropdown ? (
                          <ul className="mt-2 py-2 px-0 bg-white rounded-5 w-100" style={{ zIndex: 9, listStyle: 'none', boxShadow: 'rgb(133 133 133 / 75%) 0px 4px 35px -14px' }}>
                            {
                              allStages.map((data, index) => (
                                <li className={`${data.stageName === details.stage ? 'bg-primary text-white cursor-not-allowed' : 'cursor-pointer'} ${data.stageName === "" && 'd-none'} py-1 px-2 fs-6`} onClick={() => updateStage(data.stageName)} key={index}>{data?.stageName}</li>
                              ))
                            }
                          </ul>
                        ) : null
                      }
                    </Col>
                  ) : null
                }

              </Row>

              <Row className="mx-3 my-2" style={{ position: "relative" }}>
                <Col sm={12} className="ps-4 m-0 p-0 py-2" onClick={() => setShowChangePipeline(!showChangePipeline)} style={{ cursor: "pointer", borderRadius: "5px" }}>
                  <div className="m-0 p-0 row">
                    <Col sm={9} className="px-0 " style={{ fontWeight: "550", fontSize: "0.9rem" }}>Change Pipeline</Col>
                    <Col sm={2} className="m-0 p-0  pe-2  fs-4 text-center" style={{ fontWeight: "550", fontSize: "0.9rem" }}>+</Col>
                  </div>
                </Col>
                {
                  showChangePipeline ? (
                    <Col className="bg-white d-flex justify-content-center align-items-center py-3 flex-column" style={{ zIndex: 9, top: 40, position: "absolute", boxShadow: "0px 5px 32px -23px #4b4b4bbf" }}>
                      <div className="w-100 p-2 fs-6 text-dark border border-primary rounded-5" onMouseOver={() => setIsSelecting(true)} onMouseOut={() => setIsSelecting(false)} onClick={() => setShowPipelineDropdown(true)} style={{ cursor: "pointer" }}>
                        <Row className="ps-3 pe-4">
                          <Col sm={9}>{pipeline === "" ? 'Select Pipeline' : pipeline}</Col>
                          <Col sm={2} className="text-end m-0 p-0 text-dark">
                            {
                              showPipelineDropdown ? (
                                <BsChevronUp className="fs-5 text-end text-dark" style={{ cursor: "pointer" }} />
                              ) : (
                                <BsChevronDown className="fs-5 text-end text-dark" style={{ cursor: "pointer" }} />
                              )
                            }
                          </Col>
                        </Row>
                      </div>
                      {
                        showPipelineDropdown ? (
                          <ul className="w-100 mt-1 py-2 px-0 bg-white rounded" style={{ zIndex: 9, listStyle: 'none', boxShadow: 'rgb(133 133 133 / 75%) 0px 4px 35px -14px' }}>
                            {
                              allPipelines.map((data, index) => (
                                <li key={index} className={`${data === pipeline ? 'bg-primary text-white' : ''} py-1 px-2 fs-6`} onClick={() => changePipeline(data)}
                                  style={{ cursor: data === pipeline ? 'not-allowed' : 'pointer' }}>
                                  {data}
                                </li>
                              ))
                            }
                          </ul>
                        ) : null
                      }
                    </Col>
                  ) : null
                }
              </Row>

              <Link to={`/${orgName}/dashboard/lead/update-lead/${id}`}>
                <Row className="text-dark mx-3 py-2 ps-2 pe-3 my-2" style={{ cursor: "pointer" }}>
                  <Col sm={9} className="" style={{ fontWeight: "550", fontSize: "0.9rem" }}>Update Lead</Col>
                  <Col sm={2} className="m-0 p-0  pe-2  fs-4 text-end" style={{ fontWeight: "550", fontSize: "0.9rem" }}>
                    <HiOutlinePencilAlt className="text-success" />
                  </Col>
                </Row>
              </Link>
            </>
          ) : null
        }

        {
          deletePermission ? (
            <Row className="mx-3 my-2">
              <Col sm={12} className="text-dark ps-4 m-0 p-0 py-2" onClick={() => setShowDelete(!showDelete)} style={{ cursor: "pointer", borderRadius: "5px" }}>
                <div className="m-0 p-0 row">
                  <Col sm={9} className="px-0 text-danger" style={{ fontWeight: "550", fontSize: "0.9rem" }}>Delete Lead</Col>
                  <Col sm={2} className="m-0 p-0  pe-2  fs-4 text-center" style={{ fontWeight: "550", fontSize: "0.9rem" }}>
                    <MdDeleteOutline className="text-danger" />
                  </Col>
                </div>
              </Col>
            </Row>
          ) : null
        }

        {/* <Row className="mx-3 text-white bg-primary py-2 ps-2 pe-3 my-2" onClick={() => setShowTransfer(true)} style={{ cursor: "pointer", border: "1px solid #c8c9ca", borderRadius: "5px" }}>
          <Col sm={9}>Transfer Lead</Col>
          <Col sm={1} className="text-end">
            <Tooltip title="Transfer Lead">
              <Link to="#" className="py-1 px-3 rounded  text-white me-2">
                <BiTransfer className="fs-3" style={{ fontSize: "1rem", cursor: "pointer" }} />
              </Link>
            </Tooltip>
          </Col>
        </Row> */}

        <Modal centered size="md" show={showTransfer} onHide={() => setShowTransfer(false)}>
          <Modal.Header className="bg-primary">
            <Col sm={11}>
              <h4 className="text-white">Transfer Lead</h4>
            </Col>
            <Col sm={1} className="text-end">
              <MdClear className="fs-4 text-white" style={{ cursor: "pointer" }} onClick={() => setShowTransfer(false)} />
            </Col>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={submitTransferLead}>
              <span className="m-0 p-0 fw-semibold">Transfer Lead To:</span>
              <div style={{ zIndex: 999, position: "relative" }}>
                <Form.Group style={{ position: 'relative' }}>
                  <input type="text" id="autocomplete-state" className="mt-1 p-2 w-100 border border-primary" value={assignedTo} name="assignTo" placeholder="Select Assignee"
                    onChange={handleInputChangeAssignee} onClick={() => setIsOpenAssigneeList(true)} autoComplete='off' style={{ borderRadius: '5px' }} required
                  />
                  {isOpenAssigneeList && (
                    <>
                      <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                        {
                          allAssignees.length > 0 ? (
                            filteredAssigneeOptions.length > 0 ? (
                              filteredAssigneeOptions.map((option, i) => (
                                <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleOptionClickAssignee(option.name)}>
                                  {option.name}
                                </div>
                              ))
                            ) : (
                              <div className="mt-2">No match found</div>
                            )
                          ) : (
                            <div className="mt-2">Loading . . . . </div>
                          )
                        }
                      </div>
                    </>
                  )}
                </Form.Group>
              </div>
              <Row className="pt-2">
                <Col sm={3}>
                  <button type="submit" className="m-0 p-0 border border-none bg-white">
                    <span
                      className="m-0 py-1 px-3 rounded bg-primary text-white"
                      style={{ cursor: "pointer" }}
                    >
                      Assign
                    </span>
                  </button>
                </Col>
              </Row>
            </form>
          </Modal.Body>
        </Modal>

        <Modal centered show={showDelete} onHide={() => setShowDelete(false)}>
          <Modal.Body className="my-2">
            <Row className="mb-2 ms-1">
              <Col sm={12} className=" text-center px-2 m-0 p-0 fs-3">Delete Lead</Col>
              <Col sm={12} className="text-secondary text-center px-2 m-0 p-0">
                You want to delete lead ?
              </Col>
            </Row>
            <div className="ms-2 w-100" style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }} >
              <div
                className="py-1 px-5 me-2 bg-danger text-white text-center text-p border border-danger"
                onClick={() => handleDeleteLead()}
                style={{ borderRadius: "4px", cursor: "pointer" }}
              >
                Yes
              </div>
              <div className="bg-white text-primary py-1 px-5 border border-primary text-center"
                onClick={() => setShowDelete(false)}
                style={{ borderRadius: "5px", cursor: "pointer" }}
              >
                No
              </div>
            </div>
          </Modal.Body>
        </Modal >
      </div >
    );
  };

  return (
    <div onClick={handleCloseOptions}>
      {
        load ? (<Loader />) : (
          <div className="d-flex text-dark">
            <Row sm={7}
              className={`px-5 d-flex justify-content-center align-items-start bg-white me-0  mb-5`}
              style={{
                minWidth: screenWidth >= breakPoint ? "75%" : "75%",
                width: "70%",
                maxWidth: "80%",
                height: "100%",
                minHeight: "100vh"
              }}>
              <div className="m-0 p-0 h-100 px-5" style={{ width: "80%" }}>

                <div className="leadSubHeader w-100 py-4 px-0 d-flex justify-content-between align-items-center">
                  <div className="m-0 p-0">
                    <h2 className="">{details.title === undefined ? '' : details.title}</h2>
                    <h5 className="fw-lighter">{details?.firm?.firmName}</h5>
                  </div>
                  <select className="border border-none px-2 fs-5 bg-soft-success" style={{ background: "#091E420F", borderRadius: "5px" }}>
                    <option className="fs-5 bg-white text-dark">Select</option>
                    <option className="fs-5 bg-white text-dark">Status 1</option>
                    <option className="fs-5 bg-white text-dark">Status 2</option>
                  </select>
                </div>
                <hr />
                {/* Lead Stages */}
                <div className="d-flex justify-content-start aling-items-start flex-column mb-0">
                  <h5 className="d-block">Lead Stage</h5>
                  <LeadSubheader details={details} updateStatus={updateStatus} updateStage={updateStage} setActiveStep={setActiveStep} activeStep={activeStep} />
                  <hr />
                </div>


                {/* Main Section */}
                <ColoredTabs menuItems={allTabs} setSelectedTab={setSelectedTab} />
                {
                  selectedTab === 'About' ? (
                    <Row className="ms-1 w-100 rounded" style={{ height: "100%", marginRight: "10%", fontSize: "0.8rem" }}>
                      <div className="bg-white rounded my-2">
                        <Row className="text-primary fw-bold  ps-3  pt-2" style={{ fontSize: "1rem" }}>
                          Description
                        </Row>
                        <Row className="ps-3  pb-2" style={{ cursor: "pointer" }}>{details === undefined || details.description === undefined ? " " : details.description}</Row>
                      </div>
                      <Col sm={6} className="m-0 p-0">
                        <div className="p-2 rounded bg-white">
                          <Row className="py-2">
                            <h5 className="text-primary" style={{ fontWeight: "550" }}>About Lead</h5>
                          </Row>
                          {
                            leadDetails.map((data, index) => (
                              <Row className="py-1" key={index}>
                                <Col md={3} sm={12}>
                                  <div style={{ fontWeight: "550" }}>{data.title}: </div>
                                </Col>
                                <Col>{data.about}</Col>
                              </Row>
                            ))
                          }
                        </div>
                      </Col>

                      <Col sm={6} className="ps-2 pe-0 m-0 ">
                        <div className="m-0 h-100 p-2 rounded bg-white">
                          <Row className="py-2">
                            <h5 className="text-primary" style={{ fontWeight: "550" }}>About Client</h5>
                          </Row>
                          {
                            clientDetails.map((data, index) => (
                              <Row className="py-1" key={index}>
                                <Col md={3} sm={12}>
                                  <div style={{ fontWeight: "550" }}>{data.title}: </div>
                                </Col>
                                <Col>{data.about}</Col>
                              </Row>
                            ))
                          }
                        </div>
                      </Col>

                      <Col sm={12} className="mt-2 rounded bg-white">
                        <Row className="py-2">
                          <h5 className="text-primary" style={{ fontWeight: "550" }}>About Organization</h5>
                        </Row>
                        {
                          orgDetails.map((data, index) => (
                            <Row className="py-1" key={index}>
                              <Col md={3} sm={12}>
                                <div style={{ fontWeight: "550" }}>{data.title}: </div>
                              </Col>
                              <Col>{data.about}</Col>
                            </Row>
                          ))
                        }
                      </Col>
                    </Row>
                  ) : (
                    <>
                      {
                        selectedTab === 'Email' ? (
                          <div className="px-3 bg-white rounded">
                            <EmailForm getLeadActivites={getLeadActivites} leadActivitiesByType={leadActivitiesByType} leadId={id} />
                          </div>
                        ) : (
                          <div className="" style={{ overflowX: "hidden" }}>
                            <div className="">
                              <div className="text-primary fw-bold" style={{ fontSize: "1rem" }}>
                                <div className="py-3 mt-2 mb-3" style={{ borderBottom: "3px solid #000" }}>
                                  <h5>Add Activity</h5>
                                </div>
                                <div className="py-3 ps-3 w-100 text-secondary" onClick={() => setShowActivityForm(!showActivityForm)}
                                  style={{ borderBottom: "1px solid #00000050", borderTop: "1px solid #00000010", borderLeft: "1px solid #00000010", borderRight: "1px solid #00000010", borderRadius: "5px", cursor: "pointer" }}>
                                  <span style={{ fontSize: "1rem", fontWeight: "400" }}>Add Activity</span>
                                </div>
                                {
                                  showActivityForm ? (
                                    <>
                                      <div onClick={() => setShowActivityForm(false)} style={{ position: 'absolute', minHeight: "120vh", top: 0, right: 0, width: "100vw", background: "#091E420F" }}></div>
                                      {
                                        showActivityForm ? (
                                          <ActivityForm show={showActivityForm} setShow={setShowActivityForm} getLeadActivites={getLeadActivites} leadActivitiesByType={leadActivitiesByType} leadId={id} />
                                        ) : null
                                      }
                                    </>
                                  ) : null
                                }
                              </div>
                            </div>
                            <div className="mt-3 pb-5">
                              <h5>Activity History</h5>
                              <hr />
                              <LeadActivities allActivities={allActivities} setAllActivities={setAllActivities} leadActivitiesByType={leadActivitiesByType} type={type} leadId={details._id} />
                            </div>
                          </div>
                        )
                      }
                    </>
                  )
                }
              </div>
            </Row>
            <LeadActivityBar options={leadOptions} lead={true} details={details} />
          </div>
        )
      }
    </div>
  );
};

export default LeadDetails;

