import React, { useState } from 'react';
import shapes1 from "../../../../assets/images/shapes/01.png";
import shapes2 from "../../../../assets/images/shapes/02.png";
import shapes3 from "../../../../assets/images/shapes/03.png";
import shapes4 from "../../../../assets/images/shapes/04.png";
import { Col, Modal, Row } from "react-bootstrap";
import { MdClear } from 'react-icons/md';

const AllNotifications = ({ showAllNotifs, setShowAllNotifs, setNotifsViewed, dummyNotifs, }) => {

    const [viewedNotifs, setViwedNotifs] = useState([]);

    const handleViewed = (notifId) => {
        const removedItem = dummyNotifs.filter((data) => data.id === notifId);

        let testArr = [];

        setViwedNotifs([...viewedNotifs, removedItem[0]]);
    };


    return (
        <div>
            <Modal centered size="md" show={showAllNotifs} onHide={() => setShowAllNotifs(false)}>
                <Modal.Header className='bg-primary'>
                    <Col sm={11} className="text-white">
                        <h4 className="text-white">All Notifications</h4>
                    </Col>
                    <Col sm={1} className="text-end text-white">
                        <MdClear className="fs-4" onClick={() => setShowAllNotifs(false)} style={{ cursor: "pointer" }} />
                    </Col>
                </Modal.Header>
                <Modal.Body className='pb-0 py-0' style={{ maxHeight: "80vh", overflowY: "scroll" }}>
                    {
                        dummyNotifs.map((notif, index) => (
                            <Row className={`${viewedNotifs.includes(notif) ? 'bg-white ' : 'bg-soft-primary'} py-3 iq-sub-card`}
                                onClick={() => handleViewed(notif.id)}
                                style={{ cursor: viewedNotifs.includes(notif) ? '' : 'pointer', fontSize: '0.8rem', borderBottom: "1px solid #c8c9ca" }} key={index}
                            >
                                <Col sm={2}>
                                    <img src={notif.img} className="p-1 avatar-40 rounded-pill bg-soft-primary" />
                                </Col>
                                <Col sm={7}>
                                    <div className='w-100 m-0 p-0'>{notif.title}</div>
                                    <div className='w-100 m-0 p-0'>{notif.description}</div>
                                </Col>
                                <Col sm={3}>
                                    <div className='w-100 m-0 p-0'>
                                        <span></span>
                                    </div>
                                    <div className='w-100 m-0 p-0' style={{ fontSize: "0.7rem" }}>{notif.time}</div>
                                </Col>
                            </Row>
                        ))
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AllNotifications
