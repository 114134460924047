
import axios from "axios";
import { HRMAPI } from "../../../../backend";

// Get status
export const getAllEmploymentStatus = async () => {
    const url = `${HRMAPI}/employment-status`;
    const config = {
        method: "GET",
        url: url,
        headers: {
            "content-type": "application/json"
        }
    }
    const response = await axios(config);
    return response;
}

// Get status by id
export const getAllEmploymentStatusById = async (id) => {
    const url = `${HRMAPI}/employment-status/${id}`;
    const config = {
        method: "GET",
        url: url,
        headers: {
            "content-type": "application/json"
        }
    }
    const response = await axios(config);
    return response;
}

// Create employment status
export const addEmploymentStatus = async (form) => {
    const url = `${HRMAPI}/employment-status`;
    const config = {
        method: "POST",
        url: url,
        data: form,
        headers: {
            "content-type": "application/json"
        }
    }
    const response = await axios(config);
    return response;
}

// Update employment status details
export const updateEmploymentStatus = async (form, id) => {
    const url = `${HRMAPI}/employment-status/${id}`;
    const config = {
        method: "PUT",
        url: url,
        data: form,
        headers: {
            "content-type": "application/json"
        }
    }
    const response = await axios(config);
    return response;
}

// Delete status
export const deleteEmploymentStatus = async (form, id) => {
    const url = `${HRMAPI}/employment-status/${id}`;
    const config = {
        method: "PUT",
        url: url,
        data: form,
        headers: {
            "Content-Type": "application/json"
        }
    }

    const response = await axios(config);
    return response;
}