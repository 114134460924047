import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";

const About = ({ taxes, title, name, email, address, phone, showAbout, setShowAbout }) => {

  const dummyArray = [
    {
      id: 1,
      taxName: "gst",
      taxRate: 10,
    },
    {
      id: 2,
      taxName: "service tax",
      taxRate: 10,
    },
    {
      id: 3,
      taxName: "transport tax",
      taxRate: 10,
    },
  ]

  return (
    <div>
      <Modal size="md" centered show={showAbout} onHide={() => setShowAbout(false)} className="text-dark">
        <Modal.Header closeButton>
          <h3 className="text-primary">{title}</h3>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <img
                src="https://tse4.mm.bing.net/th?id=OIP.Ix6XjMbuCvoq3EQNgJoyEQHaFj&pid=Api&P=0"
                style={{ width: "5rem", height: "5rem", borderRadius: "50%", marginLeft: "45%", }}
              />
            </Col>
            <Col sm={12} className="text-center mt-2"> <h4>{name}</h4> </Col>
          </Row>
          <hr />
          <div>
            <Row className="mt-1">
              <Col className="fs-bold" style={{ fontWeight: "550" }} sm={12} md={5} lg={2}>Phone:</Col>
              <Col sm={12} md={12} lg={10}>{phone}</Col>
            </Row>
            <Row className="mt-1">
              <Col className="fs-bold" style={{ fontWeight: "550" }} sm={12} md={6} lg={2}>Email:</Col>
              <Col sm={12} md={12} lg={10}>{email}</Col>
            </Row>
            <Row className="mt-1">
              <Col className="fs-bold" style={{ fontWeight: "550" }} sm={12} md={12} lg={2}>Address:</Col>
              <Col sm={12} md={12} lg={10}>
                <p className="m-0 p-0">{address === undefined || address.address1 === undefined ? '' : address.address1}</p>
                <p className="m-0 p-0">{address === undefined || address.address2 === undefined ? '' : address.address2}</p>
                <p className="m-0 p-0">
                  {
                    `${address === undefined || address.city === undefined ? '' : `${address.city}, `} 
                     ${address === undefined || address.state === undefined ? '' : `${address.state}, `} 
                     ${address === undefined || address.country === undefined ? '' : `${address.country}, `} 
                     ${address === undefined || address.pinCode === undefined ? '' : `${address.pinCode}, `}`
                  }
                </p>
              </Col>
            </Row>
            {
              taxes && (
                <Row className="mx-1 mt-3 w-100">
                  <div className="m-0 mb-2 p-0 fw-semibold">Taxes:</div>
                  <div className="m-0 p-0" style={{maxHeight: "50vh", position: "relative", overflowY: "scroll"}}>
                    <table className="w-100">
                      <thead className="bg-primary text-white" style={{position: "sticky", top: 0}}>
                        <th className="ps-2 py-2">S.No.</th>
                        <th className="">Tax Name</th>
                        <th className="py-1 text-center">{`Rate (%)`}</th>
                      </thead>
                      <tbody>
                        {
                          taxes.map((data, index) => (
                            <tr style={{ borderBottom: "1px solid #c8c9ca" }} key={index}>
                              <td className="ps-2 py-2">{index + 1}</td>
                              <td className="">{Object.keys(data)}</td>
                              <td className="text-center">{Object.values(data)}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                </Row>
              )
            }
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default About;
