import { Card } from "antd";
// import Loader from "../loader/loader";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
	clearLeaveApplication,
	loadSingelLeaveApplication,
} from "../../../redux/rtk/features/leave/leaveSlice";
import tw from "tailwind-styled-components";
import { useParams } from "react-router-dom";
// import PageTitle from "../page-header/PageHeader";
import dayjs from "dayjs";
import AntLoader from "../../../components/loader/AntLoader";
import ReviewLeavePopup from "../../../components/UI/PopUp/ReviewLeavePopup";
// import ReviewLeavePopup from "../UI/PopUp/ReviewLeavePopup";
// import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";

const DetailLeave = () => {
	const { id } = useParams();
	const leave = useSelector((state) => state.leave.leave);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(loadSingelLeaveApplication(id));
		return () => {
			dispatch(clearLeaveApplication());
		};
	}, []);

	const leaveDetails = [
		{
			title: "Name",
			desc: (leave?.user?.firstName + " " + leave?.user?.lastName).toUpperCase()
		},
		{
			title: "Leave Type",
			desc: leave?.leaveType
		},
		{
			title: "Leave From",
			desc: dayjs(leave?.leaveFrom).format("DD-MM-YYYY")
		},
		{
			title: "Leave To",
			desc: dayjs(leave?.leaveTo).format("DD-MM-YYYY")
		},
		{
			title: "Leave Duration",
			desc: leave?.leaveDuration
		},
		{
			title: "Leave Reason",
			desc: leave?.reason || "No reason"
		},
		{
			title: "Leave Status",
			desc: leave?.status === "pending" ? (
				<span className='text-yellow-500'>
					{leave?.status.toUpperCase()}
				</span>
			) : leave?.status === "accepted" ? (
				<span className='text-green-500'>
					{leave?.status.toUpperCase()}
				</span>
			) : (
				<span className='text-red-500'>
					{leave?.status.toUpperCase()}
				</span>
			)

		},
		{
			title: "Leave Acceted From",
			desc: leave?.acceptLeaveFrom ? dayjs(leave?.acceptLeaveFrom).format("DD-MM-YYYY") : "ON REVIEW"
		},
		{
			title: "Leave Acceted To",
			desc: leave?.acceptLeaveTo ? dayjs(leave?.acceptLeaveTo).format("DD-MM-YYY") : "ON REVIEW"
		},
		{
			title: "Leave Acceted By",
			desc: (leave?.acceptLeaveBy?.firstName || "ON") + " " + (leave?.acceptLeaveBy?.lastName || "REVIEW")
		},
		{
			title: "Review Comment",
			desc: leave?.reviewComment || "No comment"
		},
		{
			title: "Attachment",
			desc: leave?.attachment ? (
				<a href={leave?.attachment} target='_blank' rel='noreferrer' className='text-blue-500'> Download </a>
			) : (
				"No Attachment"
			)
		}
	];

	return (
		<div className="mx-4">
			{/* <PageTitle title='Back' /> */}
			{/* <UserPrivateComponent permission={"readSingle-leaveApplication"}> */}
			<Card className='mt-4'>
				<div className='text-center mb-4'>
					{" "}
					<h2 className='text-2xl font-semibold text-gray-600'>
						Leave Application 
						{/* {leave?._id}{" "} */}
					</h2>
				</div>
				{leave ? (
					<div className='d-flex justify-content-center align-items-center '>
						<ul className='w-25 border-2 border-inherit rounded p-3 rounded border border-secondary' style={{ listStyle: "none" }}>
							{
								leaveDetails.map((data, index) => (
									<ListItem className="d-flex justify-content-start align-items-center text-dark bg-soft-primary mb-2" key={index}>
										{data.title} :
										<span className="ms-2 fw-bold text-dark">{data.desc}</span>
									</ListItem>
								))
							}
						</ul>
					</div>
				) : (
					<AntLoader />
					// <Loader />
				)}
				{/* <UserPrivateComponent permission={"update-leaveApplication"}> */}
				{leave?.status === "PENDING" && (
					<div className='d-flex justify-content-center align-items-center'>
						<ReviewLeavePopup />
					</div>
				)}
				{leave?.status === "REJECTED" && (
					<div className='d-flex justify-content-center align-items-center'>
						<ReviewLeavePopup />
					</div>
				)}
				{/* </UserPrivateComponent> */}
			</Card>
			{/* </UserPrivateComponent> */}
		</div>
	);

	// "reviewComment": null,
	// "attachment": null,
};

const ListItem = tw.li`
text-sm
text-gray-600
font-semibold
py-2
px-4
bg-gray-100
mb-1.5
rounded
w-96
flex
justify-start
`;

const TextInside = tw.p`
ml-2
text-sm
text-gray-900
`;
export default DetailLeave;
