import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import "./activity.css";

const Activity = ({ allRecentActivities, title, showActivity, setShowActivity, details }) => {

  const activities = [
    {
      module: "Activity",
      activity: "Test",
      createdAt: "2023/11/09"
    },
  ];

  let allActivities = !allRecentActivities ? activities : allRecentActivities;

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const breakPoint = 970;

  const format = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    const handleResize = setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  })

  return (
    <div className="m-0 p-0" style={{ position: 'relative' }}>
      <Col sm={12} className="mb-3 bg-white rounded"
        style={{ position: "sticky", top: 0, boxShadow: "3px 3px 10px #00000030", width: screenWidth < breakPoint && "75%", display: screenWidth < breakPoint && "none" }}
      >
        <Row className="p-3">
          <Col sm={9} onClick={() => setShowActivity(!showActivity)}>
            <h5 className="text-primary" style={{ fontSize: "1", fontWeight: "550" }}>{title}</h5>
          </Col>
          <Col sm={1}>
            {showActivity === false ? (
              <BsChevronDown className="fs-4 fw-bold" style={{ cursor: "pointer" }} onClick={() => setShowActivity(true)} />
            ) : (
              <BsChevronUp className="fs-4 fw-bold" style={{ cursor: "pointer" }} onClick={() => setShowActivity(false)} />
            )}
          </Col>
        </Row>
      </Col>
      {
        allActivities.length > 0 ? (
          <>
            {allActivities.map((data, index) =>
              showActivity === true && (
                <div className="m-0 p-0" key={index}>
                  <Col sm={12} className={`${screenWidth >= breakPoint ? 'bg-white' : 'bg-soft-primary'} mb-2 rounded text-dark px-2`}
                    style={{ width: screenWidth >= breakPoint ? "100%" : "75%", padding: screenWidth >= breakPoint ? "auto" : "1rem", boxShadow: "3px 3px 10px #00000030" }}
                  >
                    <Row className="py-2">
                      <Col sm={6}>
                        <h5 className="text-primary" style={{ fontWeight: "550" }}>{data?.activity}</h5>
                      </Col>
                      <Col sm={6} className="text-end text-primary d-flex justify-content-end align-items-start flex-row">
                        <span className="m-0 p-0 text-dark">{data?.createdDate} </span>
                        <span className={`m-0 p-0 text-secondary ms-1 ${!data?.createdTime && 'd-none'}`}>({data?.createdTime})</span>
                      </Col>
                    </Row>
                    <Row>
                      <div className="mb-2">{data?.activityDesc}</div>
                    </Row>
                  </Col>
                  <div
                    style={{ marginTop: "-2.5%", marginLeft: screenWidth >= breakPoint ? "50%" : "40%", borderLeft: "3px solid rgb(250 65 65)", height: "25px", display: allActivities.length - 1 === index && "none" }}
                  ></div>
                </div>
              )
            )}   </>
        ) : (
          <>
            {
              showActivity && (
                <div className="m-0 p-0">
                  <Col sm={12} className={`${screenWidth >= breakPoint ? 'bg-white' : 'bg-soft-primary'} mb-2 rounded text-dark px-2`}
                    style={{ width: screenWidth >= breakPoint ? "100%" : "75%", padding: screenWidth >= breakPoint ? "auto" : "1rem", boxShadow: "3px 3px 10px #00000030" }}
                  >
                    <div className="py-2 mb-2 w-100 text-center text-secondary fw-bold">No activity found</div>
                  </Col>
                </div>
              )
            }
          </>
        )
      }
    </div>
  );
};

export default Activity;
