import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import Card from "../../../../components/Card";
import { API } from "../../../../backend";
import { getCity, getCountries, getState } from "../Hook/CustomHooks";
import { useHistory } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import "../btnstyle.css";
import SubHeader from "../../../../components/partials/dashboard/HeaderStyle/sub-header";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { addNewFirm } from "../Hook/firmHooks";
import axios from "axios";
import { ErrorToast, SuccessToast } from "../../components/toast";
import { addRecentActivity } from "../Hook/recentActivityHooks";

const AddFirm = () => {
  const [form, setForm] = useState({});
  const [firmId, setFirmId] = useState("");
  const initData = Object.freeze({
    country: " ",
    state: " ",
    city: " "
  })
  const [address, setAddress] = useState(initData);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [clientCountry, setClientCountry] = useState('');
  const [isOpenCountry, setIsOpenCountry] = useState(false);
  const [clientState, setClientState] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [clientCity, setClientCity] = useState('');
  const [isOpenClientCity, setIsOpenClientCity] = useState(false);

  const [personCity, setPersonCity] = useState('');
  const [isOpenPersonCity, setIsOpenPersonCity] = useState(false);
  const [personState, setPersonState] = useState('');
  const [isOpenPersonState, setIsOpenPersonState] = useState(false);
  const [personCountry, setPersonCountry] = useState('');
  const [isOpenPersonCountry, setIsOpenPersonCountry] = useState(false);

  const [personCountries, setPersonCountries] = useState([]);
  const [personStates, setPersonStates] = useState([]);
  const [personCities, setPersonCities] = useState([]);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [showBasic, setShowBasic] = useState(true);
  const [showContact, setShowContact] = useState(false);
  const [contactPerson, setContactPerson] = useState(false);
  const [person, setPerson] = useState({});
  const [validated, setValidated] = useState(false);

  const orgId = localStorage.getItem("orgID");
  const orgName = localStorage.getItem("orgName");
  const [logo, setLogo] = useState("");
  const [img, setImg] = useState('');

  const formData = new FormData();
  const history = useHistory();

  const datetimeString = new Date();
  const dateObject = new Date(datetimeString);

  const formattedDate = `${dateObject.getDate()}/${dateObject.getMonth() + 1}/${dateObject.getFullYear()}`;
  const formattedTime = dateObject.toLocaleTimeString();

  const handlePerson = (e) => {
    e.preventDefault();


    setPerson({
      ...person,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "pinCode") {
      if ((e.target.value > personCountry) === "United States" ? 7 : 6) {
        e.target.value = e.target.value.slice(0, personCountry === "United States" ? 7 : 6)
        setPerson({
          ...person,
          pinCode: e.target.value
        })
      }
    }

    if (e.target.name === "phone" || e.target.name === 'altPhone' || e.target.name === 'mobile' || e.target.name === 'altMobile') {
      e.target.value = e.target.value.slice(0, 10)
      setPerson({
        ...person,
        [e.target.name]: e.target.value
      })
    }

    setForm({
      ...form,
      add: { ...address },
      contectPerson: person
    });
  };

  const handleChange = (e) => {

    if (e.target.name === 'phone') {
      e.target.value = e.target.value.slice(0, 10);
      setForm({
        ...form,
        phone: e.target.value
      })
    }

    setForm({
      ...form,
      [e.target.name]: e.target.value,
      orgId: orgId,
    });
  };

  const handleLogo = (e) => {
    setLogo(e.target.files[0]);
    setImg(URL.createObjectURL(e.target.files[0]));
  };


  const handleAddress = (e) => {
    setAddress({
      ...address,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "pinCode") {
      if ((e.target.value > clientCountry) === "United States" ? 7 : 6) {
        e.target.value = e.target.value.slice(0, clientCountry === "United States" ? 7 : 6)
        setAddress({
          ...address,
          pinCode: e.target.value
        })
      }
    }
    setForm({
      ...form,
      add: { ...address }
    });
  };

  useEffect(() => {
    getCountries().then((res) => {
      setCountries(res);
    });
  }, []);

  useEffect(() => {
    if (address.country) {
      getState(address.country).then((res) => {
        setStates(res);
      });
    }
  }, [address.country]);

  useEffect(() => {
    if (address.state) {
      getCity(address.state).then((res) => {
        setCities(res);
      });
    }
  }, [address.state]);

  useEffect(() => {
    getCountries().then((res) => {
      setPersonCountries(res);
    });
  }, []);

  useEffect(() => {
    if (person.country) {
      getState(person.country).then((res) => {
        setPersonStates(res);
      });
    }
  }, [person.country]);

  useEffect(() => {
    if (person.state) {
      getCity(person.state).then((res) => {
        setPersonCities(res);
      });
    }
  }, [person.state]);

  const handleInputChangeCountry = (e) => {
    setClientCountry(e.target.value);

    setIsOpenCountry(true);
    setAddress({
      ...address,
      country: e.target.value
    })
  };

  const handleOptionClickCountry = (i) => {
    setClientCountry(i)
    setAddress({
      ...address,
      country: i
    })
    setIsOpenCountry(false);
  };

  const handleInputChange = (e) => {
    setClientState(e.target.value);

    setIsOpen(true);
    setAddress({
      ...address,
      state: e.target.value
    })
  };

  const handleOptionClick1 = (i) => {
    setClientState(i)
    setAddress({
      ...address,
      state: i
    })
    setIsOpen(false);
  };

  const handleInputChangeCity = (e) => {
    setClientCity(e.target.value);

    setIsOpenClientCity(true);
    setAddress({
      ...address,
      city: e.target.value
    })
  };

  const handleOptionClickCity = (i) => {
    setClientCity(i)
    setAddress({
      ...address,
      city: i
    })
    setIsOpenClientCity(false);
  };

  const filteredCountryOptions = countries.filter((option) =>
    (option.country_name).toLowerCase().includes(clientCountry.toLowerCase())
  );

  const filteredOptions = states.filter((option) =>
    (option.state_name).toLowerCase().includes(clientState.toLowerCase())
  );

  const filteredCityOptions = cities.filter((option) =>
    (option.city_name).toLowerCase().includes(clientCity.toLowerCase())
  );



  const handleChangePersonCountry = (e) => {
    setPersonCountry(e.target.value);

    setIsOpenPersonCountry(true);
    setPerson({
      ...person,
      country: e.target.value
    })
  };

  const handleClickPersonCountry = (i) => {
    setPersonCountry(i);
    setPerson({
      ...person,
      country: i
    })
    setIsOpenPersonCountry(false);
  };

  const handleChangePersonState = (e) => {
    setPersonState(e.target.value);

    setIsOpenPersonState(true);
    setPerson({
      ...person,
      state: e.target.value
    })
  };

  const handleClickPersonState = (i) => {
    setPersonState(i)
    setPerson({
      ...person,
      state: i
    })
    setIsOpenPersonState(false);
  };

  const handlechangePersonCity = (e) => {
    setPersonCity(e.target.value);

    setIsOpenPersonCity(true);
    setPerson({
      ...person,
      city: e.target.value
    })
  };

  const handleClickPersonCity = (i) => {
    setPersonCity(i)
    setPerson({
      ...person,
      city: i
    })
    setIsOpenPersonCity(false);
  };

  const filteredPersonCountries = personCountries.filter((option) =>
    (option.country_name).toLowerCase().includes(personCountry.toLowerCase())
  );

  const filteredPersonStates = personStates.filter((option) =>
    (option.state_name).toLowerCase().includes(personState.toLowerCase())
  );

  const filteredPersonCities = personCities.filter((option) =>
    (option.city_name).toLowerCase().includes(personCity.toLowerCase())
  );

  const handleSubmitLogo = async (id) => {
    formData.append("logo", logo);
    const url = `${API}/firm/insertlogo/${id}`;
    const config = {
      method: "patch",
      url: url,
      data: formData,
      headers: {
        "Content-Type": "application/json"
      }
    }
    try {
      await axios(config);
    }
    catch (err) {
      console.log(err.message);
    }
  }

  const addFirm = async (e) => {
    e.preventDefault();
    const testForm = e.currentTarget;
    if (testForm.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);

    if (form.name && form.email && form.phone) {
      addNewFirm(form).then((response) => {
        if (response.status === 201) {

          const activityForm = {
            module: "firm",
            activity: "Firm Created",
            activityDesc: `New firm ${form?.name} is created with email ${form?.email}`,
            createdDate: formattedDate,
            createdTime: formattedTime,
            userId: localStorage.getItem('userId')
          };

          addRecentActivity(activityForm);

          setFirmId(response.data.data._id);
          SuccessToast("Firm created");
          setTimeout(() => {
            history.push(`/${orgName}/dashboard/firm/firm-list`);
          }, 1000);
        }
        else if (response.status === 400) {
          ErrorToast('Unable to add firm');
        }
      }).catch(() => {
        ErrorToast('Something went wrong');
      })
    } else {
      window.scrollTo(0, 0);
      ErrorToast("Fill reqiured fields")
    }
  };


  useEffect(() => {
    (firmId.length > 0) && handleSubmitLogo(firmId);
  }, [firmId]);


  useEffect(() => {
    const handleResize = setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <div
      onClick={() => {
        (isOpen && setIsOpen(false)) || (isOpenClientCity && setIsOpenClientCity(false)) || (isOpenCountry && setIsOpenCountry(false)) ||
          (isOpenPersonCity && setIsOpenPersonCity(false)) || (isOpenPersonState && setIsOpenPersonState(false)) || (isOpenCountry && setIsOpenCountry(false))
      }}
    >
      <Toaster />
      <SubHeader title={"Create Firm"} />
      <Row className="text-dark mx-3 mb-5" style={{ height: "100%", minHeight: "70vh", marginTop: screenWidth >= 1200 ? "-1%" : "-3%", }}>
        <Form noValidate validated={validated} onSubmit={addFirm}>
          <div className="bg-white mb-2 px-3 rounded text-dark" style={{ minHeight: "3rem" }}>
            <Card.Header className="w-100 bg-white">
              <Row className="w-100" onClick={() => setShowBasic(!showBasic)}>
                <Col sm={11} style={{ cursor: "pointer" }}>
                  <h4 className=" text-primary">Basic Details</h4>
                </Col>
                <Col sm={1} className="text-end">
                  {showBasic === false ? (
                    <BsChevronDown className="fs-4 fw-bold text-dark" onClick={() => setShowBasic(true)} style={{ cursor: "pointer" }} />
                  ) : (
                    <BsChevronUp className="fs-4 fw-bold text-dark" onClick={() => setShowBasic(false)} style={{ cursor: "pointer" }} />
                  )}
                </Col>
              </Row>
            </Card.Header>
            {
              showBasic && (
                <div>
                  <Row className="pb-3 px-4">
                    <Col className="mt-3" sm={6}>
                      <Form.Group>
                        <Form.Label htmlFor="name">Name</Form.Label>
                        <Form.Control type="text" name="name" placeholder={"Name"} onChange={handleChange} className="border-primary" required />
                        <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col className="mt-3" sm={6}>
                      <Form.Group>
                        <Form.Label htmlFor="name">Registered Firm Name</Form.Label>
                        <Form.Control type="text" name="registeredFirmName" placeholder={"Registered Firm Name"} onChange={handleChange} className="border-primary" required />
                        <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col className="mt-3" sm={6}>
                      <Form.Label>Website</Form.Label>
                      <input type="url" name="website"
                        placeholder={"Website"}
                        onChange={handleChange} className="w-100 p-2 border border-primary"
                        style={{ borderRadius: "5px" }}
                        required
                      />
                    </Col>
                    <Col className="" sm={6}>
                      <Form.Label className="w-100 pt-3">Logo</Form.Label>
                      <input type="file" name="logo" onChange={handleLogo} className="w-75 p-2 border border-primary" style={{ borderRadius: "5px" }} />
                      <img src={img} alt="logo" width="70px" height="70px" className="ms-5 mt-0 rounded-circle" />
                    </Col>
                    <Col className="mt-3" sm={6}>
                      <Form.Group>
                        <Form.Label>GST / Tax Id</Form.Label>
                        <input type="text" name="gst_no" placeholder="GST / Tax Id" onChange={handleChange} className="w-100 p-2 border border-primary" required style={{ borderRadius: "5px" }} />
                      </Form.Group>
                    </Col>
                    <Col className="mt-3" sm={6}>
                      <Form.Group>
                        <Form.Label>Invoice Prefix</Form.Label>
                        <Form.Control type="text" name="invoicePrefix" placeholder="Invoice Prefix" onChange={handleChange} className="border-primary" required />
                        <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              )
            }
          </div>

          {/* <div className="bg-white mt-3 px-3 rounded text-dark">
            <Card.Header className="w-100 bg-white">
              <Row className="w-100" onClick={() => setShowContact(!showContact)}>
                <Col sm={11} style={{ cursor: "pointer" }}>
                  <h4 className=" text-primary">Pipelines</h4>
                </Col>
                <Col sm={1} className="text-end">
                  {showContact === false ? (
                    <BsChevronDown className="fs-4 fw-bold text-dark" onClick={() => setShowContact(true)} style={{ cursor: "pointer" }} />
                  ) : (
                    <BsChevronUp className="fs-4 fw-bold text-dark" onClick={() => setShowContact(false)} style={{ cursor: "pointer" }} />
                  )}
                </Col>
              </Row>
            </Card.Header>
          </div> */}

          <div className="bg-white my-3 px-3 rounded text-dark">
            <Card.Header className="w-100 bg-white">
              <Row className="w-100" onClick={() => setShowContact(!showContact)}>
                <Col sm={11} style={{ cursor: "pointer" }}>
                  <h4 className=" text-primary">Contact</h4>
                </Col>
                <Col sm={1} className="text-end">
                  {showContact === false ? (
                    <BsChevronDown className="fs-4 fw-bold text-dark" onClick={() => setShowContact(true)} style={{ cursor: "pointer" }} />
                  ) : (
                    <BsChevronUp className="fs-4 fw-bold text-dark" onClick={() => setShowContact(false)} style={{ cursor: "pointer" }} />
                  )}
                </Col>
              </Row>
            </Card.Header>
            {
              showContact && (
                <Row className="pb-3 px-4">
                  <Col className="mt-3" sm={6}>
                    <Form.Group>
                      <Form.Label htmlFor="phone">Phone</Form.Label>
                      <Form.Control type="number" name="phone" pattern="\d{10}" maxLength="10" placeholder={"Phone"}
                        onChange={handleChange} className="text-dark border-primary" required
                      />
                      <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>
                    </Form.Group>
                    {/* <PhoneInput
                      placeholder="Phone Number"
                      // country="US"
                      international
                      defaultCountry="IN"
                      value={value}
                      onChange={setValue}
                      // onChange={(e) => {
                      //   if (e.target.value.length > 4) e.target.value = e.target.value.slice(0, 4)
                      // }}
                      className="text-dark px-2 py-2 border-primary" required
                      style={{ border: "1px solid", borderRadius: "5px" }}
                      limitMaxLength={10}
                    /> */}
                  </Col>
                  <Col sm={6} className="mt-3">
                    <Form.Group>
                      <Form.Label htmlFor="phone">Email</Form.Label>
                      <Form.Control type="email" name="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                        placeholder={"Email"}
                        onChange={handleChange} className="border-primary" required
                      />
                      <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col sm={6} className="mt-3">
                    <Form.Group>
                      <Form.Label htmlFor="address">Address 1</Form.Label>
                      <Form.Control type="text" name="address1" onChange={handleAddress}
                        placeholder={"Address"}
                        className="border-primary" required
                      />
                      <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col sm={6} className="mt-3">
                    <Form.Label htmlFor="address">Address 2</Form.Label>
                    <input type="text" name="address2" onChange={handleAddress}
                      placeholder={"Address"}
                      className="w-100 p-2 border border-primary"
                      style={{ borderRadius: "5px" }}
                    />
                  </Col>
                  <Form.Group style={{ position: 'relative' }} className="col-sm-6 form-group">
                    <Form.Label>Select Country</Form.Label>
                    <Form.Control
                      type="text"
                      id="autocomplete-state"
                      className="border border-primary"
                      value={clientCountry}
                      name="state"
                      placeholder="Select Country"
                      onChange={handleInputChangeCountry}
                      onClick={() => setIsOpenCountry(true)}
                      // onBlur={() => setIsOpen(false)}
                      required
                    />
                    <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>

                    {isOpenCountry && (
                      <>
                        <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                          {
                            countries.length > 0 ? (
                              filteredCountryOptions.length > 0 ? (
                                filteredCountryOptions.map((option, i) => (
                                  <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleOptionClickCountry(option.country_name)}>
                                    {option.country_name}
                                  </div>
                                ))
                              ) : (
                                <div className="mt-2">No match found</div>
                              )
                            ) : (
                              <div className="mt-2">Loading . . . . </div>
                            )
                          }
                        </div>
                      </>
                    )}
                  </Form.Group>

                  <Form.Group style={{ position: 'relative' }} className="col-sm-6 form-group">
                    <Form.Label>Select State</Form.Label>
                    <Form.Control
                      type="text"
                      id="autocomplete-state"
                      className="border border-primary"
                      value={clientState}
                      name="state"
                      placeholder="Select State"
                      onChange={handleInputChange}
                      onClick={() => setIsOpen(true)}
                      // onBlur={() => setIsOpen(false)}
                      required
                    />
                    <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>

                    {isOpen && (
                      <>
                        <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                          {
                            states.length > 0 ? (
                              filteredOptions.length > 0 ? (
                                filteredOptions.map((option, i) => (
                                  <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleOptionClick1(option.state_name)}>
                                    {option.state_name}
                                  </div>
                                ))
                              ) : (
                                <div className="mt-2">No match found</div>
                              )
                            ) : (
                              <div className="mt-2">Select country first </div>
                            )
                          }
                        </div>
                      </>
                    )}
                  </Form.Group>

                  <Form.Group style={{ position: 'relative' }} className="col-sm-6 form-group">
                    <Form.Label>Select City</Form.Label>
                    <Form.Control
                      type="text"
                      id="autocomplete-state"
                      className="border border-primary"
                      value={clientCity}
                      name="city"
                      placeholder="Select City"
                      onChange={handleInputChangeCity}
                      onClick={() => setIsOpenClientCity(true)}
                      // onBlur={() => setIsOpen(false)}
                      required
                    />
                    <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>

                    {isOpenClientCity && (
                      <>
                        <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                          {
                            cities.length > 0 ? (
                              filteredCityOptions.length > 0 ? (
                                filteredCityOptions.map((option, i) => (
                                  <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleOptionClickCity(option.city_name)}>
                                    {option.city_name}
                                  </div>
                                ))
                              ) : (
                                <div className="mt-2">No match found</div>
                              )
                            ) : (
                              <div className="mt-2">Select state first </div>
                            )
                          }
                        </div>
                      </>
                    )}
                  </Form.Group>
                  <Col sm={6} className="">
                    <Form.Group>
                      <Form.Label htmlFor="address">Pincode / Zipcode</Form.Label>
                      <Form.Control type="number" name="pinCode"
                        placeholder={"Pincode/Zipcode"} onChange={handleAddress} className="border-primary"
                      />
                      <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <div className="my-3">
                    <hr />
                    <h4 className="text-dark">Contact Person</h4>
                    <input
                      type="radio"
                      name="contactperson"
                      className="border border-primary"
                      // value="link"
                      onClick={() => setContactPerson(true)}
                      style={{ cursor: "pointer" }}
                      defaultChecked
                      checked={contactPerson === true}
                    />
                    <label className="fs-5 text-primary ms-1" onClick={() => setContactPerson(true)} style={{ cursor: "pointer", fontSize: "0.7rem" }}>
                      Yes
                    </label>

                    <input
                      type="radio"
                      name="contactperson"
                      className="border border-primary ms-5"
                      // value="link"
                      onClick={() => setContactPerson(false)}
                      style={{ cursor: "pointer" }}
                      checked={contactPerson === false}
                    />
                    <label className="fs-5 text-primary ms-1" onClick={() => setContactPerson(false)} style={{ cursor: "pointer", fontSize: "0.7rem" }}>
                      No
                    </label>
                    {
                      contactPerson && (
                        <Row>
                          <Col sm={6}>
                            <Form.Label>Name</Form.Label>
                            <input type="text" className="w-100 p-2 border border-primary" name="name" style={{ borderRadius: "5px" }} onChange={handlePerson} placeholder="Name" />
                          </Col>
                          <Col sm={6}>
                            <Form.Label>Email</Form.Label>
                            <input type="email" className="w-100 p-2 border border-primary" name="email" onChange={handlePerson} style={{ borderRadius: "5px" }} placeholder="Email" />
                          </Col>
                          <Col sm={6} className="mt-2">
                            <Form.Label>Phone</Form.Label>
                            <input type="number" className='w-100 p-2 border border border-primary' pattern="\d{10}" maxLength={10} name="phone" onChange={handlePerson} style={{ borderRadius: "5px" }} placeholder="Phone No." />
                            {/* <PhoneInput
                                placeholder="Phone Number"
                                // country="US"
                                international
                                defaultCountry="IN"
                                value={contactPersonValue}
                                onChange={setContactPersonValue}
                                className="text-dark px-2 py-2 border-primary"
                                style={{ border: "1px solid", borderRadius: "5px" }}
                                limitMaxLength={10}
                              /> */}
                          </Col>
                          <Col sm={6} className="mt-2">
                            <Form.Label>Alternative Phone</Form.Label>
                            <input type="number" className='w-100 p-2 border border-primary' pattern="\d{10}" maxLength="10" name="altPhone" onChange={handlePerson} style={{ borderRadius: "5px" }} placeholder="Alternative Phone No." />
                            {/* <PhoneInput
                                placeholder="Phone Number"
                                // country="US"
                                international
                                defaultCountry="IN"
                                value={contactPersonAlterValue}
                                onChange={setContactPersonAlterValue}
                                className="text-dark px-2 py-2 border-primary"
                                style={{ border: "1px solid", borderRadius: "5px" }}
                                limitMaxLength={10}
                              /> */}
                          </Col>
                          <Col sm={6} className="mt-2">
                            <Form.Label>Mobile</Form.Label>
                            <input type="number" className='w-100 p-2 border border-primary' pattern="\d{10}" maxLength={10} name="mobile" onChange={handlePerson} style={{ borderRadius: "5px" }} placeholder="Mobile No." />
                            {/* <PhoneInput
                                placeholder="Mobile Number"
                                // country="US"
                                international
                                defaultCountry="IN"
                                value={contactPersonMobile}
                                onChange={setContactPersonMobile}
                                className="text-dark px-2 py-2 border-primary"
                                style={{ border: "1px solid", borderRadius: "5px" }}
                                limitMaxLength={10}
                              /> */}
                          </Col>
                          <Col sm={6} className="mt-2">
                            <Form.Label>Alternative Mobile</Form.Label>
                            <input type="number" className='w-100 p-2 border border-primary' pattern="\d{10}" maxLength="10" name="altMobile" onChange={handlePerson} style={{ borderRadius: "5px" }} placeholder="Alternative Mobile No." />
                            {/* <PhoneInput
                                placeholder="Alternative Mobile Number"
                                // country="US"
                                international
                                defaultCountry="IN"
                                value={contactPersonAlterMobile}
                                onChange={setContactPersonAlterMobile}
                                className="text-dark px-2 py-2 border-primary"
                                style={{ border: "1px solid", borderRadius: "5px" }}
                                limitMaxLength={10}
                              /> */}
                          </Col>
                          <Col sm={6} className="mt-2">
                            <Form.Label>Address 1</Form.Label>
                            <input type="text" className="w-100 p-2 border border-primary" name="address1" onChange={handlePerson} placeholder="Addresss 1" style={{ borderRadius: "5px" }} />
                          </Col>
                          <Col sm={6} className="mt-2">
                            <Form.Label>Address 2</Form.Label>
                            <input type="text" className="w-100 p-2 border border-primary" name="address2" onChange={handlePerson} placeholder="Address 2" style={{ borderRadius: "5px" }} />
                          </Col>
                          <Col sm={6} className="mt-3">
                            <Form.Group style={{ position: 'relative' }}>
                              <Form.Label>Select Country</Form.Label>
                              <input
                                type="text"
                                id="autocomplete-state"
                                className="w-100 p-2 border border-primary"
                                value={personCountry}
                                name="personCountry"
                                placeholder="Select Country"
                                onChange={handleChangePersonCountry}
                                onClick={() => setIsOpenPersonCountry(true)}
                                style={{ borderRadius: "5px" }}
                              />

                              {isOpenPersonCountry && (
                                <>
                                  <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                    {
                                      personCountries.length > 0 ? (
                                        filteredPersonCountries.length > 0 ? (
                                          filteredPersonCountries.map((option, i) => (
                                            <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleClickPersonCountry(option.country_name)}>
                                              {option.country_name}
                                            </div>
                                          ))
                                        ) : (
                                          <div className="mt-2">No match found</div>
                                        )
                                      ) : (
                                        <div className="mt-2">Loading . . . . </div>
                                      )
                                    }
                                  </div>
                                </>
                              )}
                            </Form.Group>
                          </Col>

                          <Col sm={6} className="mt-3">
                            <Form.Group style={{ position: 'relative' }}>
                              <Form.Label>Select State</Form.Label>
                              <input
                                type="text"
                                id="autocomplete-state"
                                className="w-100 m-0 p-2 border border-primary"
                                value={personState}
                                name="personState"
                                placeholder="Select State"
                                onChange={handleChangePersonState}
                                onClick={() => setIsOpenPersonState(true)}
                                style={{ borderRadius: "5px" }}
                              />

                              {isOpenPersonState && (
                                <>
                                  <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                    {
                                      personStates.length > 0 ? (
                                        filteredPersonStates.length > 0 ? (
                                          filteredPersonStates.map((option, i) => (
                                            <>
                                              <div className='mt-2' style={{ cursor: 'pointer' }} key={option} onClick={() => handleClickPersonState(option.state_name)}>
                                                {option.state_name}
                                              </div>
                                            </>
                                          ))
                                        ) : (
                                          <div className="mt-2">No match found</div>
                                        )
                                      ) : (
                                        <div className="mt-2">Select country first </div>
                                      )
                                    }
                                  </div>
                                </>
                              )}
                            </Form.Group>
                          </Col>

                          <Col sm={6} className="mt-3">
                            <Form.Group style={{ position: 'relative' }}>
                              <Form.Label>Select City</Form.Label>
                              <input
                                type="text"
                                id="autocomplete-state"
                                className="w-100  p-2 border border-primary"
                                value={personCity}
                                name="personCountry"
                                placeholder="Select City"
                                onChange={handlechangePersonCity}
                                onClick={() => setIsOpenPersonCity(true)}
                                style={{ borderRadius: "5px" }}
                              />

                              {isOpenPersonCity && (
                                <>
                                  <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                    {
                                      personCities.length > 0 ? (
                                        filteredPersonCities.length > 0 ? (
                                          filteredPersonCities.map((option, i) => (
                                            <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleClickPersonCity(option.city_name)}>
                                              {option.city_name}
                                            </div>
                                          ))
                                        ) : (
                                          <div className="mt-2">No match found</div>
                                        )
                                      ) : (
                                        <div className="mt-2">Select state first </div>
                                      )
                                    }
                                  </div>
                                </>
                              )}
                            </Form.Group>
                          </Col>
                          <Col sm={6} className="mt-3">
                            <Form.Label htmlFor="address">Pinocde/Zipcode</Form.Label>
                            <Form.Control type="number" name="pinCode"
                              placeholder={"Pincode/Zipcode"} onChange={handlePerson} className="border-primary"
                            />
                          </Col>
                        </Row>
                      )
                    }
                  </div>
                </Row>
              )
            }
          </div>
          <div className="bg-white mb-2 px-3 py-2 rounded text-dark" style={{ minHeight: "3rem" }}>
            <Row className="mb-3">
              <Col sm={1} className="px-4 mt-4">
                <button type="submit" className="p-0 bg-none border-0 ms-3">
                  <span className="m-0 p-0 bg-primary py-2 px-4 text-white" onMouseEnter={handleAddress} style={{ borderRadius: "5px" }}>Add</span>
                </button>
              </Col>
            </Row>
          </div>
        </Form>
      </Row>
    </div>
  );
};

export default AddFirm;
