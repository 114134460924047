import { Card, Table } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
// import PageTitle from "../page-header/PageHeader";
import { CSVLink } from "react-csv";

// import LeavePolicyEdit from "../UI/PopUp/LeavePolicyEditPopup";
import {
	clearWeeklyHoliday,
	deleteWeeklyHoliday,
	loadSingleWeeklyHoliday,
} from "../../../redux/rtk/features/weeklyHoliday/weeklyHolidaySlice";
// import WeeklyHolidayEdit from "../UI/PopUp/WeeklyHolidayEditPopup";
// import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import BtnLoader from "../../../components/loader/BtnLoader";
import AntLoader from "../../../components/loader/AntLoader";
import ViewBtn from "../../../components/Buttons/ViewBtn";
import { CsvLinkBtn } from "../../../components/UI/Button/CsvLinkBtn";
import BtnDeleteSvg from "../../../components/UI/Button/btnDeleteSvg";
import ColVisibilityDropdown from "../../../components/Shared/ColVisibilityDropdown";
import WeeklyHolidayEdit from "../../../components/UI/PopUp/WeeklyHolidayEditPopup";

//PopUp

const CustomTable = ({ list, data }) => {
	const [columnsToShow, setColumnsToShow] = useState([]);

	const columns = [
		{
			id: 1,
			title: "ID",
			dataIndex: "id",
			key: "id",
		},

		{
			id: 2,
			title: " Name",
			key: "name",

			render: ({ firstName, lastName }) => firstName + " " + lastName,
		},

		{
			id: 6,
			title: "Start Day",
			key: "startDay",
			render: () => data?.startDay,
		},

		{
			id: 6,
			title: "End Day",
			key: "endDay",
			render: () => data?.endDay,
		},

		{
			id: 4,
			title: "Action",
			dataIndex: "id",
			key: "action",
			render: (id) => (
				// <UserPrivateComponent permission={"readSingle-user"}>
				<ViewBtn path={`/admin/hr/staffs/${id}/`} />
				// </UserPrivateComponent>
			),
		},
	];

	useEffect(() => {
		setColumnsToShow(columns);
	}, []);

	const columnsToShowHandler = (val) => {
		setColumnsToShow(val);
	};

	const addKeys = (arr) => arr.map((i) => ({ ...i, key: i._id }));

	return (
		<div>
			<div className='text-center w-100 my-2 d-flex justify-content-between align-items-center'>
				<h5 className='award-list-title text-color-2 text-xl mb-2'>
					Employee List
				</h5>

				{list && (
					<div>
						<CsvLinkBtn>
							<CSVLink className='btn btn-dark btn-sm fs-5 fw-bold f' data={list} filename='user_award'>
								Download CSV
							</CSVLink>
						</CsvLinkBtn>
					</div>
				)}
			</div>
			{list && (
				<div style={{ marginBottom: "30px" }}>
					<ColVisibilityDropdown
						options={columns}
						columns={columns}
						columnsToShowHandler={columnsToShowHandler}
					/>
				</div>
			)}
			<Table
				loading={!list}
				columns={columnsToShow}
				dataSource={addKeys(list)}
				pagination={{ pageSize: 5 }}
				scroll={{ x: 720 }}
			/>
		</div>
	);
};

const DetailWeeklyHoliday = () => {
	const { id } = useParams();
	let navigate = useHistory();
	const { weeklyHoliday, loading } = useSelector(
		(state) => state.weeklyHoliday
	);

	//dispatch
	const dispatch = useDispatch();

	//Delete Supplier
	const onDelete = async () => {
		try {
			const resp = await dispatch(deleteWeeklyHoliday(id));
			if (resp.payload.message === "success") {
				return navigate(-1);
			}
		} catch (error) {
			console.log(error.message);
		}
	};

	useEffect(() => {
		dispatch(loadSingleWeeklyHoliday(id));

		return () => {
			dispatch(clearWeeklyHoliday());
		};
	}, []);

	const isLogged = Boolean(localStorage.getItem("isLogged"));

	// if (!isLogged) {
	// 	return <Navigate to={"/admin/auth/login"} replace={true} />;
	// }

	return (
		<div className="px-4">
			{/* <PageTitle title=' Back  ' /> */}

			{/* <UserPrivateComponent permission={"readSingle-weeklyHoliday"}> */}
			<Card className='mr-top mt-5'>
				{weeklyHoliday ? (
					<Fragment key={weeklyHoliday.id}>
						<div>
							<div className='d-flex justify-content-between align-tiems-center '>
								<h3 className={"text-xl"}>
									ID : {weeklyHoliday.id} | {weeklyHoliday.name}
								</h3>
								<div className='d-flex justify-content-end align-tiems-center'>
									{/* <UserPrivateComponent permission={"update-weeklyHoliday"}> */}
									<WeeklyHolidayEdit data={weeklyHoliday} />
									{/* </UserPrivateComponent> */}
									{!loading ? (
										// <UserPrivateComponent permission={"delete-weeklyHoliday"}>
										<button className='ml-4' onClick={onDelete}>
											<BtnDeleteSvg size={30} />
										</button>
										// </UserPrivateComponent>
									) : (
										<BtnLoader />
									)}
								</div>
							</div>
							<CustomTable list={weeklyHoliday.users} data={weeklyHoliday} />
						</div>
					</Fragment>
				) : (
					<AntLoader />
				)}
			</Card>
			{/* </UserPrivateComponent> */}
		</div >
	);
};

export default DetailWeeklyHoliday;
