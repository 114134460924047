import axios from "axios";
import { API } from "../../../../backend";
import { toast } from "react-hot-toast";
import { ErrorToast } from "../../components/toast";
const orgId = localStorage.getItem("orgID");
const orgName = localStorage.getItem("orgName");
const userToken = localStorage.getItem('user_token');

export const getAllClients = async () => {
    let url = `${API}/client/${orgId}`;
    const config = {
        method: 'get',
        url: url,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        }
    };
    const response = await axios(config);
    return response;
}

export const getClientById = async (id) => {
    let url = `${API}/client/singleUser/${id}`;
    const config = {
        method: "GET",
        url: url,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        },
    };

    const response = await axios(config);
    return response;
}

export const deleteClient = async (id) => {
    const url = `${API}/client/delete/${id}`;
    const config = {
        method: "DELETE",
        url: url,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        }
    }
    const response = await axios(config);
    return response;
}

export const addClient = async (formData) => {
    const url = `${API}/client/create`;
    const config = {
        method: "POST",
        url: url,
        data: formData,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        }
    }
    const response = await axios(config);
    return response;
}

export const updateClient = async (formData, id) => {
    const url = `${API}/client/update/${id}`;
    const config = {
        method: 'PATCH',
        url: url,
        data: formData,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        }
    };

    const response = await axios(config);
    return response;
};