import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { deleteVendorParmanently, getVendorById, updateVendor } from '../Hook/vendorHooks'
import { ErrorToast, SuccessToast, WarningToast } from '../../components/toast'
import useFullPageLoader from '../Hook/useFullPageLoader'
import { useHistory, useLocation } from 'react-router-dom'
import { addRecentActivity } from '../Hook/recentActivityHooks'

const DeleteVendorModal = ({ showDelete, setShowDelete, id, deleted, getVendorList }) => {

    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const location = useLocation();
    const history = useHistory();
    const orgName = localStorage.getItem('orgName');
    const [vendorName, setVendorName] = useState('');

    const datetimeString = new Date();
    const dateObject = new Date(datetimeString);

    const formattedDate = `${dateObject.getDate()}/${dateObject.getMonth() + 1}/${dateObject.getFullYear()}`;
    const formattedTime = dateObject.toLocaleTimeString();

    useEffect(() => {
        getVendorById(id).then((response) => (response.status === 200) ? setVendorName(response?.data?.data?.name) : setVendorName('')).catch(() => setVendorName(''));
    }, [id]);

    const deleteVendor = () => {
        showLoader();
        if (deleted === false) {
            const form = { delete: true }
            updateVendor(id, form).then((response) => {
                if (response.status === 201) {

                    const activityForm = {
                        module: ['vendor'],
                        activity: 'Vendor Deleted',
                        activityDesc: `Vendor ${vendorName} Deleted`,
                        createdDate: formattedDate,
                        createdTime: formattedTime,
                        userId: localStorage.getItem('userId')
                    };

                    addRecentActivity(activityForm);

                    SuccessToast("Vendor deleted successfully");
                    if (location.pathname.includes(`/update-vendor`)) {
                        history.push(`/${orgName}/dashboard/vendor/all-vendors`);
                    } else {
                        getVendorList();
                    }
                    hideLoader();
                    setShowDelete(false);
                } else {
                    ErrorToast('Unable to delete vendor');
                    hideLoader();
                }
            }).catch((err) => {
                err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Something went wrong');
                hideLoader();
            });
        } else {
            deleteVendorParmanently(id).then((response) => {
                if (response.status === 204) {
                    SuccessToast("Vendor deleted permanenlty");
                    getVendorList();
                    hideLoader();
                    setShowDelete(false);
                } else {
                    ErrorToast('Unable to delete vendor');
                    hideLoader();
                }
            }).catch((err) => {
                err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Something went wrong');
                ErrorToast('Something went wrong');
                hideLoader();
            });
        }
    };

    return (
        <Modal centered show={showDelete} onHide={() => setShowDelete(false)}>
            <Modal.Body className="my-2">
                <Row className="mb-2 ms-1">
                    <Col sm={12} className=" text-center px-2 m-0 p-0 fs-3">Delete Vendor</Col>
                    <Col sm={12} className="text-secondary text-center px-2 m-0 p-0">
                        {!deleted ? `You want to delete the vendor ?` : `You want to delete the vendor parmamently ?`}
                    </Col>
                </Row>
                <div className="ms-2 w-100 d-flex justify-content-center align-items-center" style={{ flexDirection: "row" }} >
                    <div
                        className="py-1 px-5 me-2 bg-danger text-white text-center text-p border border-danger"
                        onClick={() => deleteVendor()}
                        style={{ borderRadius: "4px", cursor: "pointer" }}
                    >
                        Yes
                    </div>
                    <div className="bg-white text-primary py-1 px-5 border border-primary text-center"
                        onClick={() => setShowDelete(false)}
                        style={{ borderRadius: "5px", cursor: "pointer" }}
                    >
                        No
                    </div>
                </div>
            </Modal.Body>
            {loader}
        </Modal >
    )
}

export default DeleteVendorModal
