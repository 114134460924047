import React, { useState, useEffect } from 'react'
import { Row, Col, Modal } from 'react-bootstrap'
import { MdClear } from 'react-icons/md';
import { getAllFirms } from '../Hook/firmHooks';
import { Form } from 'react-bootstrap';
import { API } from '../../../../backend';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { ErrorToast, SuccessToast, WarningToast } from '../../components/toast';
import { addFirmTax, addGlobalTax, getAllTaxes } from '../Hook/taxHooks';

const AddtaxModal = ({ showAdd, setShowAdd }) => {

    const [taxType, setTaxType] = useState('GST');
    const [selectedType, setSelectedType] = useState('Global');
    const [allFirms, setAllFirms] = useState([]);
    const [firmId, setFirmId] = useState();
    const [firmTaxes, setFirmTaxes] = useState([]);
    const [customTax, setCustomTax] = useState();
    const [customRate, setCustomRate] = useState();

    const orgId = localStorage.getItem("orgID");

    useEffect(() => {
        getAllFirms(setAllFirms);
    }, []);

    const getFirmTaxes = (fid) => {
        setFirmId(fid);
        getAllTaxes(fid).then((response) => {
            if (response.status === 200) {
                setFirmTaxes([])
                response.data.data.length > 0 ? setFirmTaxes(response.data.data[0].taxRates) : setFirmTaxes([]);
            }
            else {
                setFirmTaxes([]);
            }
        }).catch(() => {
            setFirmTaxes([]);
        });
    }


    const handleChangeGst = (e) => {
        setCustomTax(e.target.name)
        setCustomRate(e.target.value);
    }

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     axios
    //         .post(
    //             `${API}/taxRates/addTaxInFirm`,
    //             {
    //                 firmId: firmId,
    //                 taxRates: [{ [customTax]: customRate }],
    //                 orgId: orgId,
    //                 globalTax: false
    //             }
    //         )
    //         .then((response) => {
    //             if (response.status === 201) {
    //                 SuccessToast("Tax Added");
    //                 getAllTaxes(firmId);
    //             }
    //             else {
    //                 ErrorToast("Something went wrong");
    //             }
    //         });
    // };

    const handleSubmitGlobalTaxes = (e) => {
        e.preventDefault();

        const form = {
            taxRates: [{ [customTax]: customRate }],
            orgId: orgId,
            globalTax: true
        };

        addGlobalTax(form).then((response) => {
            if (response.status === 201) {
                SuccessToast("Tax Added");
                setCustomRate('');
                setCustomTax('');
            }
            else {
                ErrorToast("Something went wrong");
            }
        }).catch((err) => {
            err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast("Something went wrong");
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = {
            firmId: firmId,
            taxRates: [{ [customTax]: customRate }],
            orgId: orgId,
            globalTax: false
        };

        addFirmTax(form).then((response) => {
            if (response.status === 201) {
                SuccessToast("Tax Added");
                getFirmTaxes(firmId);
                setCustomRate('');
                setCustomTax('');
            } else {
                ErrorToast("Unable to add tax");
            }
        }).catch((err) => {
            err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast("Something went wrong")
        });
    }

    return (
        <div>
            <Modal centered show={showAdd} onHide={setShowAdd}>
                <Modal.Header className="bg-primary">
                    <Col sm={11} className="text-white">
                        <h4 className="text-white">Taxes</h4>
                    </Col>
                    <Col sm={1} className="text-end text-white">
                        <MdClear className="fs-4" onClick={() => setShowAdd(false)} style={{ cursor: "pointer" }} />
                    </Col>
                </Modal.Header>
                <Modal.Body>
                    <Col className="bg-white rounded mx-2">
                        <div className="m-0 py-2" style={{ borderBottom: "1px solid #c8c9ca" }}>
                            <span className="classname text-primary fs-4 fw-semibold">Add Taxes</span>
                        </div>
                        <div className=" m-3">
                            <div>
                                <Form.Check.Input
                                    type="radio"
                                    name='global'
                                    className="border border-primary cursor-pointer mt-2"
                                    value="Global"
                                    onClick={() => setSelectedType("Global")}
                                    defaultChecked
                                    checked={selectedType === "Global"}
                                />
                                <label className="fs-3 text-primary ms-1" onClick={() => setSelectedType('Global')} style={{ cursor: "pointer", fontSize: "0.7rem" }}>
                                    Global Taxes
                                </label>

                                <Form.Check.Input
                                    type="radio"
                                    name='firm'
                                    value="Firm"
                                    className="border border-primary ms-5 cursor-pointer mt-2"
                                    onClick={() => setSelectedType("Firm")}
                                    checked={selectedType === "Firm"}
                                />
                                <label className="fs-3 text-primary ms-1" onClick={() => setSelectedType('Firm')} style={{ cursor: "pointer", fontSize: "0.7rem" }}>
                                    Firm Taxes
                                </label>
                            </div>
                        </div>
                        {
                            selectedType === "Firm" ? (
                                <div className="mb-2 px-3 py-2 text-dark" style={{ minHeight: "3rem" }}>
                                    <Row className="w-100">
                                        <h5 className=" text-primary ms-2 mb-2">Select Firm</h5>
                                    </Row>
                                    <Row>
                                        <Col sm={8} style={{ height: "50px", overflowY: "scroll" }}>
                                            <Form.Select
                                                name="firm_id"
                                                onChange={(e) => getFirmTaxes(e.target.value)}
                                                style={{ width: "200px" }}
                                                className="border border-primary w-100"
                                            >
                                                <option>Select Firm</option>
                                                {allFirms.map((data, index) => (
                                                    <option
                                                        key={index}
                                                        value={data._id}
                                                        onClick={(e) => setFirmId(e.target.value)}
                                                    >
                                                        {data.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    {
                                        firmId && (
                                            <>
                                                <hr />
                                                <div className="mt-3 mb-5">
                                                    <div className="m-0 p-0" style={{ maxHeight: "20vh", overflowY: "scroll", position: "relative" }}>
                                                        <table className="w-100 m-0 px-2">
                                                            <thead className="text-primary bg-white fw-bold" style={{ borderBottom: "1px solid #c8c9ca", position: "sticky", top: 0 }}>
                                                                <th className="fw-semibold px-2 py-2 text-start ps-5" style={{ fontSize: "0.9rem" }}>Tax</th>
                                                                <th className="fw-semibold px-2 text-start" style={{ fontSize: "0.9rem" }}>{`Rate(%)`}</th>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    firmTaxes.map((data, index) => (
                                                                        <tr style={{ borderBottom: "1px solid rgb(227 227 227)", fontSize: "0.8rem" }} key={index}>
                                                                            <td className="py-2 text-start ps-5">{Object.keys(data)}</td>
                                                                            <td className="text-start ps-2" style={{ fontSize: "0.8rem" }}>{Object.values(data)}</td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div>
                                                    <div
                                                        className="py-2 row"
                                                    >
                                                        <div className="m-0 p-0 px-3 pb-2">
                                                            <Form.Check.Input
                                                                type="radio"
                                                                name="taxtype"
                                                                className="border border-primary"
                                                                // value="link"
                                                                onClick={() => setTaxType("GST")}
                                                                style={{ cursor: "pointer" }}
                                                                defaultChecked
                                                                checked={taxType === "GST"}
                                                            />
                                                            <label className="fs-5 ms-1" onClick={() => setTaxType('GST')} style={{ cursor: "pointer", fontSize: "0.7rem" }}>
                                                                GST
                                                            </label>

                                                            <Form.Check.Input
                                                                type="radio"
                                                                name="taxtype"
                                                                className="border border-primary ms-5"
                                                                // value="link"
                                                                onClick={() => setTaxType("Custom")}
                                                                style={{ cursor: "pointer" }}
                                                                checked={taxType === "Custom"}
                                                            />
                                                            <label className="fs-5 ms-1" onClick={() => setTaxType('Custom')} style={{ cursor: "pointer", fontSize: "0.7rem" }}>
                                                                Custom taxes
                                                            </label>
                                                        </div>
                                                        {
                                                            taxType === "GST" ? (
                                                                <div className="m-0 p-0" style={{ borderTop: "1px solid #c8c9ca" }}>
                                                                    <form onSubmit={handleSubmit}>
                                                                        <Col sm={2}>
                                                                            <h4 className="text-primary fw-semibold ms-3 my-3">GST</h4>
                                                                        </Col>
                                                                        <Col
                                                                            sm={3}
                                                                            className="mt-2 py-2 px-2 mx-2"
                                                                            style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }}
                                                                        >
                                                                            <span className="m-0 me-2 p-0">GST: </span>
                                                                            <input
                                                                                type="number"
                                                                                name="GST"
                                                                                className="w-50 ps-2"
                                                                                onChange={handleChangeGst}
                                                                                placeholder="%"
                                                                                style={{
                                                                                    border: "1px solid #c8c9ca",
                                                                                    borderRadius: "5px",
                                                                                }}
                                                                                required
                                                                            />
                                                                        </Col>
                                                                        <Row className="w-100 ps-3 mt-3">
                                                                            <Col sm={6} className=" mt-2">
                                                                                <button type="submit" className="m-0 py-1 px-3 rounded-5 border border-none bg-primary text-white fs-5 cursor-pointer">
                                                                                    Add tax
                                                                                </button>
                                                                            </Col>
                                                                        </Row>
                                                                    </form>
                                                                </div>
                                                            ) : (
                                                                <div className="m-0 p-0" style={{ borderTop: "1px solid #c8c9ca" }}>
                                                                    <h4 className="text-primary fw-semibold mt-3 ms-3 my-3">
                                                                        Custom tax
                                                                    </h4>
                                                                    <div
                                                                        className="mb-3 py-2"
                                                                        style={{
                                                                            maxHeight: "50vh",
                                                                            overflowY: "scroll",
                                                                            overflowX: "hidden",
                                                                        }}
                                                                    >
                                                                        <Row className="w-100 mx-2">
                                                                            <Col sm={6} className="m-0 p-0 ps-2 fw-semibold">
                                                                                Name
                                                                            </Col>
                                                                            <Col sm={3} className="text-center fw-semibold">
                                                                                Rate (%)
                                                                            </Col>
                                                                        </Row>
                                                                        <form onSubmit={handleSubmit}>
                                                                            <Row className="w-100 my-2 mx-2">
                                                                                <Col
                                                                                    sm={6}
                                                                                    className=" px-2 mx-2"
                                                                                    style={{
                                                                                        border: "1px solid #c8c9ca",
                                                                                        borderRadius: "5px",
                                                                                    }}
                                                                                >
                                                                                    <input
                                                                                        type="text"
                                                                                        className="w-100 my-2 ps-2"
                                                                                        placeholder="Name"
                                                                                        required
                                                                                        onChange={(e) => setCustomTax(e.target.value)}
                                                                                        autoComplete="off"
                                                                                        style={{
                                                                                            border: "none",
                                                                                            borderBottom: "1px solid #c8c9ca",
                                                                                        }}
                                                                                    />
                                                                                </Col>
                                                                                <Col
                                                                                    sm={2}
                                                                                    className=" px-2 mx-2"
                                                                                    style={{
                                                                                        border: "1px solid #c8c9ca",
                                                                                        borderRadius: "5px",
                                                                                    }}
                                                                                >
                                                                                    <input
                                                                                        type="number"
                                                                                        className="w-100 my-2 ps-2"
                                                                                        name="rate"
                                                                                        placeholder="%"
                                                                                        onChange={(e) => setCustomRate(e.target.value)}
                                                                                        required
                                                                                        style={{
                                                                                            border: "none",
                                                                                            borderBottom: "1px solid #c8c9ca",
                                                                                        }}
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                            <Row className="w-100 ps-3 mt-3">
                                                                                <Col sm={6} className=" mt-2">
                                                                                    <button type="submit" className="m-0 py-1 px-3 rounded-5 border border-none bg-primary text-white fs-5 cursor-pointer">
                                                                                        Add tax
                                                                                    </button>
                                                                                </Col>
                                                                            </Row>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }

                                                        <Row className="w-100 my-2 mx-2">
                                                        </Row>

                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            ) : (
                                <div className="mb-2 pb-2 text-dark" style={{ minHeight: "3rem" }}>
                                    <div className="m-0 p-0 px-3 pb-2 d-flex justify-content-start align-items-center">
                                        <div className='m-0 p-0 d-flex justify-content-center align-items-center'>
                                            <Form.Check.Input
                                                type="radio"
                                                name="taxtype"
                                                className="border border-primary"
                                                // value="link"
                                                onClick={() => setTaxType("GST")}
                                                style={{ cursor: "pointer" }}
                                                defaultChecked
                                                checked={taxType === "GST"}
                                            />
                                            <label className="fs-5 ms-1 mt-1" onClick={() => setTaxType('GST')} style={{ cursor: "pointer", fontSize: "0.7rem" }}>
                                                GST
                                            </label>
                                        </div>
                                        <div className='d-flex justify-content-start align-items-center'>
                                            <Form.Check.Input
                                                type="radio"
                                                name="taxtype"
                                                className="border border-primary ms-5 cursor-pointer"
                                                // value="link"
                                                onClick={() => setTaxType("Custom")}
                                                // style={{ marginTop: "0.35rem" }}
                                                checked={taxType === "Custom"}
                                            />
                                            <label className="fs-5 ms-1 mt-1" onClick={() => setTaxType('Custom')} style={{ cursor: "pointer", fontSize: "0.7rem" }}>
                                                Custom taxes
                                            </label>
                                        </div>
                                    </div>
                                    {
                                        taxType === "GST" ? (
                                            <div className="m-0 p-0" style={{ borderTop: "1px solid #c8c9ca" }}>
                                                <form onSubmit={handleSubmitGlobalTaxes}>
                                                    <Col sm={2}>
                                                        <h4 className="text-primary fw-semibold ms-3 my-3">GST</h4>
                                                    </Col>
                                                    <Col
                                                        sm={3}
                                                        className="mt-2 py-2 px-2 mx-2"
                                                        style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }}
                                                    >
                                                        <span className="m-0 me-2 p-0">GST: </span>
                                                        <input
                                                            type="number"
                                                            name="GST"
                                                            className="w-50 ps-2"
                                                            onChange={handleChangeGst}
                                                            placeholder="%"
                                                            style={{
                                                                border: "1px solid #c8c9ca",
                                                                borderRadius: "5px",
                                                            }}
                                                            required
                                                        />
                                                    </Col>
                                                    <Row className="w-100 ps-3 mt-3">
                                                        <Col sm={6} className=" mt-2">
                                                            <button type="submit" className="m-0 py-1 px-3 rounded-5 border border-none bg-primary text-white fs-5 cursor-pointer">
                                                                Add tax
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                </form>
                                            </div>
                                        ) : (
                                            <div className="m-0 p-0" style={{ borderTop: "1px solid #c8c9ca" }}>
                                                <h4 className="text-primary fw-semibold mt-3 ms-3 my-3">
                                                    Custom tax
                                                </h4>
                                                <div
                                                    className="mb-3 py-2"
                                                    style={{
                                                        maxHeight: "50vh",
                                                        overflowY: "scroll",
                                                        overflowX: "hidden",
                                                    }}
                                                >
                                                    <Row className="w-100 mx-2">
                                                        <Col sm={6} className="m-0 p-0 ps-2 fw-semibold">
                                                            Name
                                                        </Col>
                                                        <Col sm={3} className="text-center fw-semibold">
                                                            Rate (%)
                                                        </Col>
                                                    </Row>
                                                    <form onSubmit={handleSubmitGlobalTaxes}>
                                                        {/* {
                                              custom.map((data, i) => ( */}
                                                        <Row className="w-100 my-2 mx-2">
                                                            <Col
                                                                sm={6}
                                                                className=" px-2 mx-2"
                                                                style={{
                                                                    border: "1px solid #c8c9ca",
                                                                    borderRadius: "5px",
                                                                }}
                                                            >
                                                                <input
                                                                    type="text"
                                                                    className="w-100 my-2 ps-2"
                                                                    placeholder="Name"
                                                                    required
                                                                    onChange={(e) => setCustomTax(e.target.value)}
                                                                    autoComplete="off"
                                                                    style={{
                                                                        border: "none",
                                                                        borderBottom: "1px solid #c8c9ca",
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col
                                                                sm={2}
                                                                className=" px-2 mx-2"
                                                                style={{
                                                                    border: "1px solid #c8c9ca",
                                                                    borderRadius: "5px",
                                                                }}
                                                            >
                                                                <input
                                                                    type="number"
                                                                    className="w-100 my-2 ps-2"
                                                                    name="rate"
                                                                    placeholder="%"
                                                                    onChange={(e) => setCustomRate(e.target.value)}
                                                                    required
                                                                    style={{
                                                                        border: "none",
                                                                        borderBottom: "1px solid #c8c9ca",
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="w-100 ps-3 mt-3">
                                                            <Col sm={6} className=" mt-2">
                                                                <button type="submit" className="m-0 py-1 px-3 rounded-5 border border-none bg-primary text-white fs-5 cursor-pointer">
                                                                    Add tax
                                                                </button>
                                                            </Col>
                                                        </Row>
                                                    </form>
                                                </div>
                                            </div>
                                        )
                                    }
                                    <Row className="w-100 my-2 mx-2">
                                    </Row>
                                </div>
                            )
                        }
                    </Col>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AddtaxModal
