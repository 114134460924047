import React, { useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { cubicleFull } from '../../../assets/images'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { updateUser } from '../app/Hook/authHooks';
import { ErrorToast, SuccessToast } from '../components/toast';

const AcceptInvitation = () => {

    const [showDeclienInvitation, setShowDeclineInvitation] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const location = useHistory();

    const orgId = localStorage.getItem('orgID');
    const orgName = localStorage.getItem('orgName');
    const userId = localStorage.getItem('userId');

    const acceptInvitation = () => {
        setBtnDisabled(true);
        updateUser(orgId, userId).then((response) => {
            if (response.status === 200) {
                SuccessToast("Invitation accepted");
                location.push(`/${orgName}/dashboard`);
                setBtnDisabled(false);
            } else {
                ErrorToast("Unable to accept invitation");
                setBtnDisabled(false);
            }
        }).catch(() => {
            ErrorToast("Unable to accept invitation");
            setBtnDisabled(false);
        })
    };

    return (
        <>
            <Row className='m-0 p-2 w-100 bg-white' style={{ boxShadow: "0 0 10px #c8c9ca", position: "sticky", top: 0 }}>
                <Col sm={3}>
                    <Row className='m-0 p-0'>
                        <img src={cubicleFull} alt='' style={{ width: "200px", height: "50px" }} />
                    </Row>
                </Col>
            </Row>
            <div className='d-flex justify-content-center align-items-start w-100 bg-white py-5 row' style={{ height: "100vh" }}>
                <div className='w-25 bg-white rounded p-2 text-center py-5' style={{ boxShadow: "rgb(8 8 8) 0px 0px 35px -19px" }}>
                    <h2 className='text-primary text-center w-100'>You are invited to join</h2>
                    <ul className='py-2' style={{ listStyleType: "none" }}>
                        <li className='mb-2'>Click on the <span className='text-success m-0 p-0 fw-bold'>[Accept Invitation]</span> button below.</li>
                        <li>Start experiencing the transformative power of our platform.</li>
                    </ul>
                    <div className='d-flex justify-content-center align-items-center w-100 mt-3'>
                        <div className='p-0 d-flex justify-content-between align-items-center'>
                            <Button variant='outline-success border border-success me-2' disabled={btnDisabled} onClick={() => acceptInvitation()}>Accept Invitation</Button>
                            <Button variant='outline-danger border border-danger' onClick={() => setShowDeclineInvitation(true)}>Decline</Button>
                        </div>
                    </div>
                </div>



                {/* <div style={{ width: '100%', padding: "2rem", paddingTop: "0", marginTop: 0, background: "#fff", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
                    <div style={{ width: "40%", border: "1px solid #c8c9ca", padding: "2rem" }}>
                        <div style={{ textAlign: "center" }}>
                            <h1 style={{ textAlign: "center", color: "#000", fontWeight: "900" }}>CRM</h1>
                            <p style={{ fontSize: "18px", padding: "1rem", borderBottom: "1px solid #c8c9ca" }}>@Cubicle web has invited you to join <span style={{ fontSize: "20px", margin: "0", padding: "0", fontWeight: "600" }}>CRM</span> </p>
                            <p style={{ margin: "1rem auto", marginTop: "2rem", fontSize: "13px" }}> We're thrilled to invite you to join our new <span style={{ margin: "0", padding: "0", color: "blue" }}>CRM</span>, designed to supercharge our team collaboration and streamline our workflow.</p>
                            <a href="" style={{ display: "inline-block", color: "#fff", fontSize: "14px", fontWeight: "600", background: "blue", width: "auto !important", textAlign: "centeer", borderRadius: "5px", margin: "1rem auto", padding: "6px 12px", }}>View Invitation</a>
                            <p style={{ margin: "1rem auto", fontSize: "13px", paddingBottom: '2rem', borderBottom: "1px solid #c8c9ca" }}> We believe that by embracing our platform, we can take our collaboration and efficiency to new heights. This is an exciting step forward for our team, and we're eager to have you on board.</p>
                            <div style={{ fontSize: "12px", width: "100%", textAlign: "left" }}>
                                <span style={{ margin: 0, padding: 0, color: "#000", fontWeight: 600 }}>Note: </span>
                                <p style={{ display: "inline" }}>
                                    This invitation was intended for <span style={{ margin: "0", padding: "0", color: "blue", fontWeight: "600" }}>niteshgairola02@gmail.com</span>. If you were not expecting this invitation, you can ignore this email.
                                </p>
                            </div>
                            <div style={{ fontSize: "12px", width: "100%", textAlign: "left", marginTop: "1.5rem" }}>
                                <span style={{ margin: 0, padding: 0, color: "gray", fontWeight: 600 }}>Button not working? : </span>
                                <p style={{ display: "inline", color: "gray" }}>
                                    Copy and paste this link to your browser:
                                </p>
                                <p style={{color: "blue"}}>
                                    <a href='http://localhost:3000/123456789'>http://localhost:3000/123456789</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>


            <Modal show={showDeclienInvitation} onHide={() => setShowDeclineInvitation(false)}>
                <Modal.Body className="my-2">
                    <Row className="mb-2 ms-1">
                        <Col sm={12} className=" text-center px-2 m-0 p-0 fs-3">Decline Invitation</Col>
                        <Col sm={12} className="text-secondary text-center px-2 m-0 p-0">You want to decline the invitation ?</Col>
                    </Row>
                    <div className="ms-2 w-100" style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }} >
                        <div
                            className="py-1 px-5 me-2 bg-danger text-white text-center text-p border border-danger"
                            // onClick={() => deleteInvoice()}
                            onClick={() => location.push('/')}
                            style={{ borderRadius: "4px", cursor: "pointer" }}
                        >
                            Yes
                        </div>
                        <div className="bg-white text-primary py-1 px-5 border border-primary text-center"
                            onClick={() => setShowDeclineInvitation(false)}
                            style={{ borderRadius: "5px", cursor: "pointer" }}
                        >
                            No
                        </div>
                    </div>
                </Modal.Body>
                {/* {loader} */}
            </Modal >
        </>
    )
}

export default AcceptInvitation
