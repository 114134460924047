
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import Card from "../../../../components/Card";
import { Modal } from "react-bootstrap";
import { MdDeleteOutline } from "react-icons/md";
import { toast, Toaster } from "react-hot-toast";
import Cancel from "@mui/icons-material/Cancel";
import { Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import { API } from "../../../../backend";
import { getCity, getCountries, getState } from "../Hook/CustomHooks";
import "../btnstyle.css";
import SubHeader from "../../../../components/partials/dashboard/HeaderStyle/sub-header";
import 'react-phone-number-input/style.css'
import { ErrorToast, SuccessToast, WarningToast } from "../../components/toast";
import { getClientById, updateClient } from "../Hook/clientHooks";

const EditClient = () => {
    const { id } = useParams();
    const [formData, setFormData] = useState({});

    const [address, setAddress] = useState({});
    const [clientAddress1, setClientAddress1] = useState('');
    const [clientAddress2, setClientAddress2] = useState('');
    const [clientpinCode, setClientPincode] = useState('');

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const [singleClientAdd, setSingleClientAdd] = useState({});
    const [clientCountry, setClientCountry] = useState('');
    const [isOpenCountry, setIsOpenCountry] = useState(false);
    const [clientState, setClientState] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [clientCity, setClientCity] = useState('');
    const [isOpenClientCity, setIsOpenClientCity] = useState(false);

    const [personCountries, setPersonCountries] = useState([]);
    const [personStates, setPersonStates] = useState([]);
    const [personCities, setPersonCities] = useState([]);

    const [loading, setLoading] = useState(false);
    const [singleClient, setSingleClient] = useState({});
    const [showMsg, setShowMsg] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const [contactPersonDetails, setContactPersonDetails] = useState({});
    const [contactPerson, setContactPerson] = useState(false);
    const [person, setPerson] = useState({});
    // const [value, setValue] = useState();
    const [selectedCountry, setSelectedtCountry] = useState('');
    const [selectedPersonCountry, setSelectedtPersonCountry] = useState('');

    const orgId = localStorage.getItem("orgID");
    const orgName = localStorage.getItem("orgName");
    const history = useHistory();
    // const navigate = useNavigate();

    const clientDetailsById = async () => {
        getClientById(id).then((response) => {
            if (response.status === 200) {
                setSingleClient(response.data);
                setSingleClientAdd(response.data.add);
                setContactPersonDetails(response.data.contactPerson)
                setClientAddress1(response.data.add.address1 ? response.data.add.address1 : '');
                setClientAddress2(response.data.add.address2 ? response.data.add.address2 : '');
                setClientPincode(response.data.add.pinCode ? response.data.add.pinCode : '');

                setClientCountry(response.data.add.country ? response.data.add.country : '');
                setClientState(response.data.add.state ? response.data.add.state : '');
                setClientCity(response.data.add.city ? response.data.add.city : '');
            } else {
                setSingleClient({});
                setSingleClientAdd({});
                setContactPersonDetails({})
                setClientAddress1('');
                setClientAddress2('');
                setClientPincode('');

                setClientCountry('');
                setClientState('');
                setClientCity('');
            }
        }).catch(() => {
            setSingleClient({});
            setSingleClientAdd({});
            setContactPersonDetails({})
            setClientAddress1('');
            setClientAddress2('');
            setClientPincode('');

            setClientCountry('');
            setClientState('');
            setClientCity('');
        });
        // fetch(`${API}/client/singleUser/${id}`, {
        //     method: "get",
        //     mode: "cors",
        //     headers: {
        //         "content-type": "application/json",
        //         "access-control-allow-origin": "*",
        //     },
        // })
        //     .then((res) => res.json())
        //     .then((response) => {
        //         // setSingleFirm(response.data)
        //         if (response.code === 200) {
        //             setSingleClient(response.data);
        //             setSingleClientAdd(response.data.add);
        //             setContactPersonDetails(response.data.contactPerson)
        //             setClientAddress1(response.data.add.address1 ? response.data.add.address1 : '');
        //             setClientAddress2(response.data.add.address2 ? response.data.add.address2 : '');
        //             setClientPincode(response.data.add.pinCode ? response.data.add.pinCode : '');

        //             setClientCountry(response.data.add.country ? response.data.add.country : '');
        //             setClientState(response.data.add.state ? response.data.add.state : '');
        //             setClientCity(response.data.add.city ? response.data.add.city : '');
        //         }
        //     })
        //     .catch(() => {
        //         setSingleClient([])
        //     });
    }

    useEffect(() => {
        clientDetailsById();
    }, []);

    const handleChange = (e) => {

        if (e.target.name === 'phone') {
            e.target.value = e.target.value.slice(0, 10);
            setFormData({
                ...formData,
                phone: e.target.value
            })
        }

        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
            orgId: orgId,
            add: {
                address1: clientAddress1,
                address2: clientAddress2,
                country: clientCountry,
                state: clientState,
                city: clientCity,
                pinCode: clientpinCode
            }
        });
    };

    const handlePerson = (e) => {
        e.preventDefault();
        setPerson({
            ...person,
            [e.target.name]: e.target.value,
            // phone: contactPersonValue,
            // altPhone: contactPersonAlterValue,
            // mobile: contactPersonMobile,
            // altMobile: contactPersonAlterMobile
        });
        
        if (e.target.name === "pinCode") {
            if (e.target.value > selectedPersonCountry === "United States" ? 7 : 6) {
                e.target.value = e.target.value.slice(0, selectedPersonCountry === "United States" ? 7 : 6)
                setPerson({
                    ...person,
                    pinCode: e.target.value
                })
            }
        }

        if (e.target.name === 'phone' || e.target.name === 'altPhone' || e.target.name === 'mobile' || e.target.name === 'altMobile') {
            e.target.value = e.target.value.slice(0, 10);
            setPerson({
                ...person,
                [e.target.name]: e.target.value
            })
        }

        setFormData({
            ...formData,
            // phone: value,
            // add: { ...address },
            contectPerson: person
        });
    };

    const handleAddress = (e) => {
        setAddress({
            ...address,
            [e.target.name]: e.target.value,
        });
        if (e.target.name === "pinCode") {
            if (e.target.value > selectedCountry === "United States" ? 7 : 6) {
                e.target.value = e.target.value.slice(0, selectedCountry === "United States" ? 7 : 6)
                setAddress({
                    ...address,
                    pinCode: e.target.value
                })
            }
        }
        setFormData({
            ...formData,
            add: { ...address }
        });
    };
    const deleteClient = async () => {
        await fetch(`${API}/client/delete/${id}`, {
            method: "DELETE",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(),
        })
            .then((res) => res.json())
            .then((response) => {
                SuccessToast("Client Deleted");
                setShowDelete(false);
                setTimeout(() => {
                    history.push(`/${orgName}/dashboard/app/client/client-list`)
                }, 1000);
            })
            .catch((err) => ErrorToast(err.message));
    };

    useEffect(() => {
        getCountries().then((res) => {
            setCountries(res);
        });
    }, []);

    useEffect(() => {
        if (address.country) {
            getState(address.country).then((res) => {
                setStates(res);
            });
        }
    }, [address.country]);

    useEffect(() => {
        if (address.state) {
            getCity(address.state).then((res) => {
                setCities(res);
            });
        }
    }, [address.state]);

    useEffect(() => {
        getCountries().then((res) => {
            setPersonCountries(res);
        });
    }, []);

    useEffect(() => {
        if (person.country) {
            getState(person.country).then((res) => {
                setPersonStates(res);
            });
        }
    }, [person.country]);

    useEffect(() => {
        if (person.state) {
            getCity(person.state).then((res) => {
                setPersonCities(res);
            });
        }
    }, [person.state]);

    const handleInputChangeCountry = (e) => {
        setClientCountry(e.target.value);

        setIsOpenCountry(true);
        setAddress({
            ...address,
            country: e.target.value
        })
    };

    const handleOptionClickCountry = (i) => {
        ;
        setClientCountry(i)
        setAddress({
            ...address,
            country: i
        })
        setIsOpenCountry(false);
    };

    const handleInputChange = (e) => {
        setClientState(e.target.value);

        setIsOpen(true);
        setAddress({
            ...address,
            state: e.target.value
        })
    };

    const handleOptionClick1 = (i) => {
        ;
        setClientState(i)
        setAddress({
            ...address,
            state: i
        })
        setIsOpen(false);
    };

    const handleInputChangeCity = (e) => {
        setClientCity(e.target.value);

        setIsOpenClientCity(true);
        setAddress({
            ...address,
            city: e.target.value
        })
    };

    const handleOptionClickCity = (i) => {
        ;
        setClientCity(i)
        setAddress({
            ...address,
            city: i
        })
        setIsOpenClientCity(false);
    };

    const filteredCountryOptions = countries.filter((option) =>
        (option.country_name).toLowerCase().includes(clientCountry.toLowerCase())
    );

    const filteredOptions = states.filter((option) =>
        (option.state_name).toLowerCase().includes(clientState.toLowerCase())
    );

    const filteredCityOptions = cities.filter((option) =>
        (option.city_name).toLowerCase().includes(clientCity.toLowerCase())
    );

    const handleUpdate = async (e) => {
        e.preventDefault();
        updateClient(formData, id).then((response) => {
            if (response.status === 200) {
                SuccessToast("Client Update Successfully");
                setTimeout(() => {
                    history.push(`/${orgName}/dashboard/client/client-list`)
                }, 1000);
            } else {
                ErrorToast("Unable to update toast");
            }
        }).catch((err) => {
            err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast("Something went wrong");
        })
    };

    useEffect(() => {
        const handleResize = setScreenWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    });

    return (
        <>
            <Toaster />
            <SubHeader title="Update Client" />
            <div className="text-dark">
                <Row
                    className="mx-3"
                    style={{ marginTop: screenWidth >= 1200 ? "-2%" : "-3%" }}
                >
                    <Col>
                        <Card className="px-5 mt-3">
                            <Card.Header className="bg-white d-flex justify-content-between">
                                <div className="header-title">
                                    <h4
                                        className="card-title text-primary fw-bold"
                                        style={{ fontSize: "1.3rem" }}
                                    >Update Client</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <div className="new-user-info">
                                    <Form
                                        onSubmit={handleUpdate}
                                        method="POST"
                                    >
                                        <div className="row text-dark">
                                            <Form.Group className="col-md-6 form-group">
                                                <Form.Label htmlFor="name">First Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="firstName"
                                                    name="firstName"
                                                    defaultValue={
                                                        singleClient.firstName
                                                            ? singleClient.firstName
                                                            : ""
                                                    }
                                                    placeholder="First Name"
                                                    onChange={handleChange}
                                                    className="text-dark border-primary"
                                                    // required={!singleClient.firstName && true}
                                                    style={{ background: "#fff" }}
                                                />
                                            </Form.Group>
                                            <Form.Group className="col-md-6 form-group">
                                                <Form.Label htmlFor="name">Last Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="lastName"
                                                    name="lastName"
                                                    defaultValue={
                                                        singleClient.lastName
                                                            ? singleClient.lastName
                                                            : "Last Name"
                                                    }
                                                    placeholder="Last Name"
                                                    onChange={handleChange}
                                                    className="text-dark border-primary"
                                                    // required={!singleClient.lastName && true}
                                                    style={{ background: "#fff" }}
                                                />
                                            </Form.Group>
                                            <Form.Group className="col-md-6 form-group">
                                                <Form.Label htmlFor="name">Firm Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="firmName"
                                                    name="clientFirmName"
                                                    defaultValue={
                                                        singleClient.clientFirmName
                                                            ? singleClient.clientFirmName
                                                            : ""
                                                    }
                                                    placeholder="Firm Name"
                                                    onChange={handleChange}
                                                    className="text-dark border-primary"
                                                    // required={!singleClient.clientFirmName && true}
                                                    style={{ background: "#fff" }}
                                                />
                                            </Form.Group>
                                            <Form.Group className="col-md-6 form-group">
                                                <Form.Label htmlFor="name">Website</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="website"
                                                    name="website"
                                                    defaultValue={
                                                        singleClient.website
                                                            ? singleClient.website
                                                            : ""
                                                    }
                                                    placeholder="Website"
                                                    onChange={handleChange}
                                                    className="text-dark border-primary"
                                                    style={{ background: "#fff" }}
                                                />
                                            </Form.Group>
                                            <Form.Group className="col-md-6 form-group">
                                                <Form.Label htmlFor="email">Email</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                                    defaultValue={
                                                        singleClient.email
                                                            ? singleClient.email
                                                            : ""
                                                    }
                                                    placeholder="Client Email"
                                                    onChange={handleChange}
                                                    className="text-dark border-primary"
                                                    style={{ background: "#fff" }}
                                                />
                                            </Form.Group>
                                            <Form.Group className="col-md-6 form-group">
                                                <Form.Label htmlFor="phone">Phone</Form.Label>

                                                <Form.Control
                                                    type="number"
                                                    id="phone"
                                                    name="phone"
                                                    pattern="\d{10}"
                                                    maxLength="10"
                                                    defaultValue={
                                                        singleClient.phone
                                                            ? singleClient.phone
                                                            : ""
                                                    }
                                                    placeholder="Client Phone"
                                                    onChange={handleChange}
                                                    className="text-dark border-primary"
                                                    style={{ background: "#fff" }}
                                                />
                                                {/* <PhoneInput
                          // placeholder="Phone Number"
                          // country="US"
                          international
                          // name="phone"
                          defaultCountry="IN"
                          placeholder={
                            singleClient.phone
                              ? singleClient.phone
                              : "Phone Number"
                          }
                          value={value}
                          onChange={setValue}
                          className="text-dark px-2 py-2 border-primary" required
                          style={{ border: "1px solid", borderRadius: "5px" }}
                          limitMaxLength={10}
                        /> */}
                                            </Form.Group>

                                            <Form.Group className="col-md-6 form-group">
                                                <Form.Label htmlFor="address">
                                                    Address Line 1
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="address"
                                                    name="address1"
                                                    defaultValue={clientAddress1}
                                                    placeholder="Address Line `"
                                                    onChange={(e) => setClientAddress1(e.target.value)}
                                                    className=" border-primary"
                                                    // required={!singleClientAdd.address1 && true}
                                                    style={{ background: "#fff" }}
                                                />
                                            </Form.Group>

                                            <Form.Group className="col-md-6 form-group">
                                                <Form.Label htmlFor="address">
                                                    Address Line 2
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="address"
                                                    name="address2"
                                                    defaultValue={clientAddress2}
                                                    placeholder="Address Line 2"
                                                    onChange={(e) => setClientAddress2(e.target.value)}
                                                    className=" border-primary"
                                                    // required={true}
                                                    // validate={required}
                                                    style={{ background: "#fff" }}
                                                />
                                            </Form.Group>

                                            <Form.Group style={{ position: 'relative' }} className="col-sm-6 form-group">
                                                <Form.Label>Country</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="autocomplete-state"
                                                    className="border border-primary"
                                                    // defaultValue={address.country}
                                                    value={clientCountry}
                                                    name="state"
                                                    placeholder="Select Country"
                                                    onChange={handleInputChangeCountry}
                                                    onClick={() => setIsOpenCountry(true)}
                                                />

                                                {isOpenCountry && (
                                                    <>
                                                        <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                                            {
                                                                countries.length > 0 ? (
                                                                    filteredCountryOptions.length > 0 ? (
                                                                        filteredCountryOptions.map((option, i) => (
                                                                            <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleOptionClickCountry(option.country_name)}>
                                                                                {option.country_name}
                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        <div className="mt-2">No match found</div>
                                                                    )
                                                                ) : (
                                                                    <div className="mt-2">Loading . . . . </div>
                                                                )
                                                            }
                                                        </div>
                                                    </>
                                                )}
                                            </Form.Group>

                                            <Form.Group style={{ position: 'relative' }} className="col-sm-6 form-group">
                                                <Form.Label>State *</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="autocomplete-state"
                                                    className="border border-primary"
                                                    value={clientState}
                                                    name="state"
                                                    placeholder="Select State"
                                                    onChange={handleInputChange}
                                                    onClick={() => setIsOpen(true)}
                                                />

                                                {isOpen && (
                                                    <>
                                                        <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                                            {
                                                                states.length > 0 ? (
                                                                    filteredOptions.length > 0 ? (
                                                                        filteredOptions.map((option, i) => (
                                                                            <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleOptionClick1(option.state_name)}>
                                                                                {option.state_name}
                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        <div className="mt-2">No match found</div>
                                                                    )
                                                                ) : (
                                                                    <div className="mt-2">Select country first </div>
                                                                )
                                                            }
                                                        </div>
                                                    </>
                                                )}
                                            </Form.Group>

                                            <Form.Group style={{ position: 'relative' }} className="col-sm-6 form-group">
                                                <Form.Label>City *</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="autocomplete-state"
                                                    className="border border-primary"
                                                    value={clientCity}
                                                    name="city"
                                                    placeholder="Select City"
                                                    onChange={handleInputChangeCity}
                                                    onClick={() => setIsOpenClientCity(true)}
                                                />

                                                {isOpenClientCity && (
                                                    <>
                                                        <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                                            {
                                                                cities.length > 0 ? (
                                                                    filteredCityOptions.length > 0 ? (
                                                                        filteredCityOptions.map((option, i) => (
                                                                            <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleOptionClickCity(option.city_name)}>
                                                                                {option.city_name}
                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        <div className="mt-2">No match found</div>
                                                                    )
                                                                ) : (
                                                                    <div className="mt-2">Select state first </div>
                                                                )
                                                            }
                                                        </div>
                                                    </>
                                                )}
                                            </Form.Group>


                                            <Form.Group className="col-md-6 form-group">
                                                <Form.Label htmlFor="address">Pin Code</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    id="address"
                                                    name="pinCode"
                                                    defaultValue={clientpinCode}
                                                    // pattern="\d{6}"
                                                    maxLength="6"
                                                    onChange={(e) => setClientPincode(e.target.value)}
                                                    className=" border-primary"
                                                    // required={true}
                                                    style={{ background: "#fff" }}
                                                // validate={required}
                                                />
                                            </Form.Group>
                                            <Form.Group className="col-md-6 form-group">
                                                <Form.Label htmlFor="address">GST / Tax Id</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="taxId"
                                                    name="taxId"
                                                    placeholder={
                                                        singleClient.taxId
                                                            ? singleClient.taxId
                                                            : "GST / Tax Id"
                                                    }
                                                    // pattern="\d{6}"
                                                    onChange={handleChange}
                                                    className=" border-primary"
                                                    // required={true}
                                                    style={{ background: "#fff" }}
                                                // validate={required}
                                                />
                                            </Form.Group>
                                            <div className="my-3">
                                                <hr />
                                                <h4 className="text-dark">Contact Person</h4>
                                                <Form.Check.Input
                                                    type="radio"
                                                    name="contactperson"
                                                    className="border border-primary"
                                                    // value="link"
                                                    onClick={() => setContactPerson(true)}
                                                    style={{ cursor: "pointer" }}
                                                    defaultChecked
                                                    checked={contactPerson === true}
                                                />
                                                <label className="fs-5 text-primary ms-1" onClick={() => setContactPerson(true)} style={{ cursor: "pointer", fontSize: "0.7rem" }}>
                                                    Yes
                                                </label>

                                                <Form.Check.Input
                                                    type="radio"
                                                    name="contactperson"
                                                    className="border border-primary ms-5"
                                                    // value="link"
                                                    onClick={() => setContactPerson(false)}
                                                    style={{ cursor: "pointer" }}
                                                    checked={contactPerson === false}
                                                />
                                                <label className="fs-5 text-primary ms-1" onClick={() => setContactPerson(false)} style={{ cursor: "pointer", fontSize: "0.7rem" }}>
                                                    No
                                                </label>
                                                {
                                                    contactPerson && (
                                                        <Row>
                                                            <Col sm={6}>
                                                                <Form.Group>
                                                                    <Form.Label>Name</Form.Label>
                                                                    <Form.Control type="text" className="border-primary" name="name" onChange={handlePerson}
                                                                        placeholder={contactPersonDetails === undefined || contactPersonDetails.name === undefined ? 'Name' : contactPersonDetails.name}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={6}>
                                                                <Form.Group>
                                                                    <Form.Label>Email</Form.Label>
                                                                    <Form.Control type="email" className="border-primary" name="email" onChange={handlePerson}
                                                                        placeholder={contactPersonDetails === undefined || contactPersonDetails.email === undefined ? 'Email' : contactPersonDetails.email}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={6} className="mt-2">
                                                                <Form.Group>
                                                                    <Form.Label>Phone</Form.Label>
                                                                    <Form.Control type="number" className="border border-primary" pattern="\d{10}" maxLength={10} name="phone" onChange={handlePerson} placeholder="Phone No." />
                                                                    {/* <PhoneInput
                                    placeholder={contactPersonDetails === undefined || contactPersonDetails.phone === undefined ? 'Phone' : contactPersonDetails.phone}
                                    // country="US"
                                    internationalhandleChange
                                    defaultCountry="IN"
                                    value={contactPersonValue}
                                    onChange={setContactPersonValue}
                                    className="text-dark px-2 py-2 border-primary" required
                                    style={{ border: "1px solid", borderRadius: "5px" }}
                                    limitMaxLength={10}
                                  /> */}
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={6} className="mt-2">
                                                                <Form.Group>
                                                                    <Form.Label>Alternative Phone</Form.Label>
                                                                    <Form.Control type="number" className="border border-primary" pattern="\d{10}" maxLength="10" name="altPhone" onChange={handlePerson} placeholder="Alternative Phone No." />
                                                                    {/* <PhoneInput
                                    placeholder={contactPersonDetails === undefined || contactPersonDetails.altPhone === undefined ? 'Alternative Phone' : contactPersonDetails.altPhone}
                                    // country="US"
                                    international
                                    defaultCountry="IN"
                                    value={contactPersonAlterValue}
                                    onChange={setContactPersonAlterValue}
                                    className="text-dark px-2 py-2 border-primary" required
                                    style={{ border: "1px solid", borderRadius: "5px" }}
                                    limitMaxLength={10}
                                  /> */}
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={6} className="mt-2">
                                                                <Form.Group>
                                                                    <Form.Label>Mobile</Form.Label>
                                                                    <Form.Control type="number" className="border border-primary" pattern="\d{10}" maxLength={10} name="mobile" onChange={handlePerson} placeholder="Mobile No." />
                                                                    {/* <PhoneInput
                                    placeholder={contactPersonDetails === undefined || contactPersonDetails.mobile === undefined ? 'mobile' : contactPersonDetails.mobile}
                                    // country="US"
                                    international
                                    defaultCountry="IN"
                                    value={contactPersonMobile}
                                    onChange={setContactPersonMobile}
                                    className="text-dark px-2 py-2 border-primary" required
                                    style={{ border: "1px solid", borderRadius: "5px" }}
                                    limitMaxLength={10}
                                  /> */}
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={6} className="mt-2">
                                                                <Form.Group>
                                                                    <Form.Label>Alternative Mobile</Form.Label>
                                                                    <Form.Control type="number" className="border border-primary" pattern="\d{10}" maxLength="10" name="altMobile" onChange={handlePerson} placeholder="Alternative Mobile No." />
                                                                    {/* <PhoneInput
                                    placeholder={contactPersonDetails === undefined || contactPersonDetails.altMobile === undefined ? 'Alternative Mobile' : contactPersonDetails.altMobile}
                                    // country="US"
                                    international
                                    defaultCountry="IN"
                                    value={contactPersonAlterMobile}
                                    onChange={setContactPersonAlterMobile}
                                    className="text-dark px-2 py-2 border-primary" required
                                    style={{ border: "1px solid", borderRadius: "5px" }}
                                    limitMaxLength={10}
                                  /> */}
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={6} className="mt-2">
                                                                <Form.Group>
                                                                    <Form.Label>Address 1</Form.Label>
                                                                    <Form.Control type="text" className="border-primary" name="address1" onChange={handlePerson}
                                                                        placeholder={contactPersonDetails === undefined || contactPersonDetails.address1 === undefined ? 'Address 1' : contactPersonDetails.address1}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={6} className="mt-2">
                                                                <Form.Group>
                                                                    <Form.Label>Address 2</Form.Label>
                                                                    <Form.Control type="text" className="border-primary" name="address2" onChange={handlePerson}
                                                                        placeholder={contactPersonDetails === undefined || contactPersonDetails.address2 === undefined ? 'Address 2' : contactPersonDetails.address2}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={6} className="mt-3">
                                                                <Form.Group>
                                                                    <Form.Label>Country</Form.Label>
                                                                    <div>
                                                                        <Form.Select type="text" name="country" onInput={(e) => setSelectedtPersonCountry(e.target.value)} onChange={handlePerson}
                                                                            placeholder={contactPersonDetails === undefined || contactPersonDetails.country === undefined ? 'Country' : contactPersonDetails.country}
                                                                            value={person.country}
                                                                            className="border-primary rounded py-2 w-100"
                                                                        >
                                                                            {personCountries.length > 0 && loading === false ? (
                                                                                personCountries.map((country, index) => (
                                                                                    <option value={country.country_name} key={index}>{country.country_name}</option>
                                                                                ))
                                                                            ) : (
                                                                                <option className="text-green-500 text-xs">Loading....</option>
                                                                            )}
                                                                        </Form.Select>
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={6} className="mt-3">
                                                                <Form.Label htmlFor="address">State</Form.Label>
                                                                <div>
                                                                    <Form.Select type="text" name="state"
                                                                        placeholder={contactPersonDetails === undefined || contactPersonDetails.state === undefined ? 'State' : contactPersonDetails.state}
                                                                        value={person.state}
                                                                        onChange={handlePerson} className="rounded bg-none border-primary py-2 w-100"
                                                                    >
                                                                        {personStates.length > 0 ? (personStates.map((state, index) => (
                                                                            <option value={state.state_name} key={index}>{state.state_name}</option>
                                                                        ))
                                                                        ) : loading ? (
                                                                            <option value="">Loading...</option>
                                                                        ) : (
                                                                            <option value="">{"Select Country First"}</option>
                                                                        )}
                                                                    </Form.Select>
                                                                </div>
                                                            </Col>
                                                            <Col sm={6} className="mt-3">
                                                                <Form.Label htmlFor="address">City</Form.Label>
                                                                <div>
                                                                    <Form.Select type="text" name="city"
                                                                        placeholder={contactPersonDetails === undefined || contactPersonDetails.city === undefined ? 'City' : contactPersonDetails.city}
                                                                        value={person.city} onChange={handlePerson}
                                                                        className="border-primary py-2 w-100">
                                                                        {personCities.length > 0 ? (
                                                                            personCities.map((city, index) => (<option value={city.city_name} key={index}>{city.city_name}</option>))
                                                                        ) : loading ? (
                                                                            <option value="">Loading...</option>
                                                                        ) : (
                                                                            <option value="">{"Select State First"}</option>
                                                                        )}
                                                                    </Form.Select>
                                                                </div>
                                                            </Col>
                                                            <Col sm={6} className="mt-3">
                                                                <Form.Label htmlFor="address">Pin Code</Form.Label>
                                                                <Form.Control type="number" name="pinCode"
                                                                    placeholder={contactPersonDetails === undefined || contactPersonDetails.pinCode === undefined ? 'Pincode' : contactPersonDetails.pinCode}
                                                                    onChange={handlePerson} className="border-primary"
                                                                />
                                                            </Col>
                                                        </Row>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="bg-white mb-2 mx-0 px-0 py-2 rounded text-dark" style={{ minHeight: "3rem" }}>
                                            <div className="w-100 d-flex justify-content-start align-items-center">
                                                <button type="submit" className="bg-primary rounded-5 cursor-pointer text-white px-4 py-2">Update Client</button>
                                            </div>
                                            <Row className="mb-3 m-0 p-0">
                                                {/* <Col sm={2} className="m-0 p-0 mt-4">
                                                    <button type="submit" className="m-0 p-0 w-100 bg-white border-0">
                                                        <span className="m-0 p-0 w-100 bg-primary py-2 px-4 text-white" onMouseEnter={handleChange} style={{ borderRadius: "5px" }}>
                                                            {singleClient.email ? "Update Client" : "Add Client"}
                                                        </span>
                                                    </button>
                                                </Col> */}
                                                {/* {singleFirm.email && ( */}
                                                {
                                                    singleClient.email && (
                                                        <Col>
                                                            <>
                                                                {showMsg === true ? (
                                                                    <div className="m-0 p-0 mb-2 ms-3">
                                                                        <p className="text-danger" style={{ display: "inline" }}>You want to delete the firm ?</p>
                                                                        <span className="yesbtn" style={{ cursor: "pointer" }} onClick={() => setShowDelete(true)}>Yes</span>
                                                                        <span className="nobtn" style={{ cursor: "pointer" }} onClick={() => setShowMsg(false)}>No</span>
                                                                    </div>
                                                                ) : (
                                                                    <div className="m-0 p-0 mb-2">
                                                                        <p className="text-danger" style={{ display: "inline" }}></p>
                                                                        <span style={{ cursor: "pointer" }}></span>
                                                                        <span style={{ cursor: "pointer" }}></span>
                                                                    </div>
                                                                )

                                                                }
                                                                <span className="p-0 mt-5 bg-danger py-2 px-4 text-white" onClick={() => setShowMsg(!showMsg)} style={{ borderRadius: "5px", cursor: "pointer" }}>Delete</span>
                                                            </>
                                                        </Col>
                                                    )
                                                }
                                                {/* )} */}
                                            </Row>
                                        </div>
                                    </Form>
                                </div>
                            </Card.Body>
                        </Card>
                        <Modal centered show={showDelete} onHide={() => setShowDelete(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Delete Client</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div
                                    className="d-flex w-100 text-dark"
                                    style={{ justifyContent: "space-between" }}
                                >
                                    <p className="d-inline">You want to delete the client ? </p>
                                    <div>
                                        <span>
                                            <Tooltip title="Delete">
                                                <Link>
                                                    <MdDeleteOutline
                                                        className="fs-2 text-danger"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => deleteClient()}
                                                    />
                                                </Link>
                                            </Tooltip>
                                        </span>
                                        <span>
                                            <Tooltip title="Close">
                                                <Link>
                                                    <Cancel
                                                        className="fs-2 text-primary"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => setShowDelete(false)}
                                                    />
                                                </Link>
                                            </Tooltip>
                                        </span>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default EditClient;
