import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import withBreadcrumbs from "react-router-breadcrumbs-hoc";
import { Breadcrumb, Nav } from 'react-bootstrap';
const orgName = localStorage.getItem('orgName');

const allRoutes = [
    {
        path: '/invoice/invoice-list',
        breadcrumb: 'Invoice'
    },
    {
        path: '/invoice/invoice-list',
        breadcrumb: 'Invoice List'
    },
    {
        path: '/invoice/invoice-details/:hash',
        breadcrumb: 'Invoice Details'
    },
    {
        path: '/invoice/edit/:hash',
        breadcrumb: 'Edit Invoice'
    },
    {
        path: '/invoice/copiedInvoice/:hash',
        breadcrumb: 'Add Invoice'
    },
    {
        path: '/invoice/invoice-details/:hash',
        breadcrumb: 'Create Invoice'
    },
    {
        path: '/invoice/add-invoice',
        breadcrumb: 'Create Invoice'
    },
];

const Breadcrumbs = ({ breadcrumbs }) => {

    return (
        <div className='d-flex justify-content-start align-items-center'>
            <Nav aria-label="breadcrumb">
                <Breadcrumb as="ol">
                    {
                        breadcrumbs.map((data, index) => (
                            <div className={`fs-5 text-primary`}>
                                {
                                    data?.breadcrumb?.props?.children !== 'Dashboard' && (
                                        <span className='m-0 p-0 px-2'>{`>`}</span>
                                    )
                                }
                                <Link to={index === 3 ? data?.location?.pathname : data?.match?.url}>
                                    {data?.breadcrumb?.props?.children}
                                </Link>
                            </div>
                        ))
                    }
                </Breadcrumb>
            </Nav>
        </div>
    )
};

export default withBreadcrumbs(allRoutes, { excludePaths: ['/', `/${orgName}`] })(Breadcrumbs);
