import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { Col, Form, Row } from "react-bootstrap";
import SubHeader from "../../../../components/partials/dashboard/HeaderStyle/sub-header";
import axios from "axios";
import { FiEye } from 'react-icons/fi';
import { getAllOrgTaxes } from "../Hook/taxHooks";
import { API } from "../../../../backend";
import ActivityBar from "../../../../components/partials/components/activitybar";
import Pagination from "../pagination/pagination";
import AddtaxModal from "./addTax-modal";
import { Tooltip } from "@material-ui/core";
import useFullPageLoader from "../Hook/useFullPageLoader";
import { WarningToast } from "../../components/toast";
import { getAllInvoices } from "../Hook/invoiceHooks";
import ContactAdmin from "../../components/contact-admin";

const orgId = localStorage.getItem("orgID");

const Addtax = () => {
  const [allDataofOrgTaxes, setAllDataofOrgTaxes] = useState([]);
  const [firmDetails, setFirmDetails] = useState({});
  const [taxDetails, setTaxDetails] = useState([]);
  const [singleTaxDetails, setSingleTaxDetails] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [showContactAdmin, setShowContactAdmin] = useState(false);
  const [invoiceData, setInvoiceData] = useState([]);
  const [collectedGlobaleTax, setCollectedGlobaleTax] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [loader, showLoader, hideLoader] = useFullPageLoader();


  const showPerPage = 10;
  const breakPoint = 970;
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  const orgName = localStorage.getItem('orgName');
  const history = useHistory();

  const alltaxes = () => {
    showLoader();
    getAllOrgTaxes().then((response) => {
      if (response.status === 200) {
        setAllDataofOrgTaxes(response.data.data);
        hideLoader();
      } else {
        setAllDataofOrgTaxes([]);
        hideLoader();
      }
    }).catch((err) => {
      err?.response?.data?.message && setShowContactAdmin(true);
      // err?.response?.data?.message && WarningToast(err?.response?.data?.message);
      setAllDataofOrgTaxes([]);
      hideLoader();
    })
  };

  const allInvoicesList = async () => {
    getAllInvoices().then((response) => {
      if (response.status === 200) {
        setInvoiceData(response?.data?.data)
      } else {
        setInvoiceData([]);
      }
    }).catch(() => {
      setInvoiceData([]);
    });
  }

  useEffect(() => {
    alltaxes();
  }, []);

  useEffect(() => {
    allInvoicesList();
  }, []);


  // useEffect(() => {
  //   sessionStorage.setItem('mainId', mainId);
  //   sessionStorage.setItem('taxId', taxId);
  // }, [mainId, taxId]);

  useEffect(() => {
    const handleResize = setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  // useEffect(() => {
  //   GetSingleFirmDetails(mainId, taxId)
  // }, [allDataofOrgTaxes]);

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  const taxOptions = () => {
    return <></>
  };

  const handleShowDetails = (value, index) => {
    sessionStorage.setItem('mainId', parseInt(value));
    sessionStorage.setItem('taxId', parseInt(index));
    history.push(`/${orgName}/dashboard/tax/tax-details/1234`);
  }

  const GetSingleFirmDetails = (value, taxIndex) => {
    {
      value ? allDataofOrgTaxes.filter((data, index) => index === value).map((data) => setFirmDetails(data.firmId), (data) => setTaxDetails(data.taxRates))
        : allDataofOrgTaxes.filter((data, index) => index === 0).map((data) => setFirmDetails(data.firmId), (data) => setTaxDetails(data.taxRates))
    }
    allDataofOrgTaxes.filter((data, index) => index === value).map((data) => setFirmDetails(data.firmId));
    allDataofOrgTaxes.filter((data, index) => index === value).map((data) => setTaxDetails(data.taxRates));

    const newArr = [];
    allDataofOrgTaxes.filter((data, index) => index === value).map((data) => newArr.push(data.taxRates));
    setTimeout(() => {
      newArr[0].filter((data, id) => id === taxIndex).map((data) => setSingleTaxDetails(data));
    }, 100)
    const testArr = [];
    setTimeout(() => {
      newArr[0].filter((data, id) => id === taxIndex).map((data) => testArr.push(data));
    }, 100);

    const totalPaid = invoiceData.filter((data) => data.status === 'Paid' || data.status === 'Partial Paid');
    const subtotal = totalPaid.reduce((prev, curr) => prev + parseFloat(curr.total), 0);

    setTimeout(() => {
      setCollectedGlobaleTax((Object.values(testArr[0]) / 100) * subtotal);
    }, 100);
  }

  return (
    <div className="m-0 p-0">
      <ContactAdmin show={showContactAdmin} setShow={setShowContactAdmin} />
      <SubHeader title="Taxes" btnTitle="Add Taxes" btnLink={() => setShowAdd(true)} setShowAdd={setShowAdd} />
      <div className="d-flex text-dark" style={{ marginTop: screenWidth >= 1200 ? "-2%" : "-3%", flexDirection: "row" }}>
        <Row sm={3} className="w-25 rounded mx-2" style={{ height: "100%", marginRight: "10%", fontSize: "0.8rem" }}>
          <Col sm={12} className="bg-white rounded">
            <div>
              <Row className="py-2">
                <Col sm={8}>
                  <h5 className="text-primary" style={{ fontWeight: "550" }}>Tax Details</h5>
                </Col>
              </Row>
            </div>
            <div className="my-3">
              {
                firmDetails != undefined ? (
                  <Row>
                    <Col style={{ fontWeight: 550 }} sm={5}>
                      Firm Name:
                    </Col>
                    <Col>{firmDetails === undefined || firmDetails.name === undefined ? " " : firmDetails.name}</Col>
                  </Row>
                ) : (
                  <Row>
                    <Col style={{ fontWeight: 550 }} sm={5}>
                      Global Tax
                    </Col>
                  </Row>
                )
              }
              <Row className="my-2">
                <Col style={{ fontWeight: 550 }} sm={5}>
                  Tax Name:
                </Col>
                <Col>{singleTaxDetails === undefined ? "" : Object.keys(singleTaxDetails)}</Col>
              </Row>
              <Row>
                <Col style={{ fontWeight: 550 }} sm={5}>
                  Rate (%):
                </Col>
                <Col>{singleTaxDetails === undefined ? "" : Object.values(singleTaxDetails)}</Col>
              </Row>
              <Row className="my-2">
                <Col style={{ fontWeight: 550 }} sm={5}>
                  Collected Tax:
                </Col>
                <Col sm> ₹ {collectedGlobaleTax.toFixed(2)}</Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row sm={7} className={`h-100 mx-2 ps-3 mb-5`}
          style={{ minWidth: screenWidth >= breakPoint ? "50%" : "50%", width: "70%", maxWidth: "80%", }}
        >
          <Row className="bg-white rounded">
            <hr />
            <div
              className="table-responsive mt-2"
              style={{ minHeight: "50vh" }}
            >
              <table className="" style={{ width: "100%" }}>
                <thead>
                  <tr className="h-20 text-primary" style={{ borderBottom: "1px solid #a09fa3" }}>
                    <th className="fw-bold" style={{ fontSize: "0.9rem" }}></th>
                    <th className="fw-bold text-start" style={{ fontSize: "0.9rem" }}>Tax Name</th>
                    <th className="fw-bold text-center" style={{ fontSize: "0.9rem" }}>Rate (%)</th>
                    <th className="fw-bold text-center" style={{ fontSize: "0.9rem" }}>Status</th>
                    <th className="fw-bold text-center" style={{ fontSize: "0.9rem" }}>View</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    allDataofOrgTaxes
                      .slice(pagination.start, pagination.end)
                      .map((data, value) => (
                        <tr key={value}>
                          <td>{data.taxRates ? (
                            <>
                              <table className="w-100 text-start">
                                <tbody>
                                  {data.taxRates.map((val, taxIndex) => (
                                    <tr style={{ borderBottom: "1px solid #c8c9ca" }} key={taxIndex}>
                                      <td className="py-2">
                                        <Form.Check.Input type="radio" name="tax" style={{ cursor: "pointer" }}
                                          onChange={() => GetSingleFirmDetails(value, taxIndex)}
                                          defaultChecked={value === 0}
                                        />
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </>
                          ) : null}
                          </td>
                          <td>{data.taxRates ? (
                            <>
                              <table className="w-100 text-start" style={{ fontSize: "0.8rem" }}>
                                <tbody>
                                  {data.taxRates.map((val, index) => (
                                    <tr style={{ borderBottom: "1px solid #c8c9ca" }} key={index}>
                                      <td className="py-2">{Object.keys(val)}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </>
                          ) : "0"}
                          </td>
                          <td>{data.taxRates ? (
                            <>
                              <table className="w-100 text-center" style={{ fontSize: "0.8rem" }}>
                                <tbody>
                                  {data.taxRates.map((val, index) => (
                                    <tr style={{ borderBottom: "1px solid #c8c9ca" }} key={index}>
                                      <td className="py-2">{Object.values(val)}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </>
                          ) : "0"}
                          </td>
                          <td>{data.taxRates ? (
                            <>
                              <table className="w-100" style={{ fontSize: "0.8rem" }}>
                                <tbody>
                                  {data.taxRates.map((val, index) => (
                                    <tr style={{ borderBottom: "1px solid #c8c9ca" }} key={index}>
                                      <td className="py-2 d-flex justify-content-center">
                                        <p
                                          className={`${data.global ? 'bg-success' : 'bg-primary'} m-0 p-0 text-white rounded-pill text-center`}
                                          style={{ width: "40%" }}
                                        >
                                          {data.global ? 'Global' : 'Firm'}
                                        </p>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </>
                          ) : "0"}
                          </td>
                          <td>{data.taxRates ? (
                            <>
                              <table className="w-100" style={{ fontSize: "0.8rem" }}>
                                <tbody>
                                  {data.taxRates.map((val, index) => (
                                    <tr style={{ borderBottom: "1px solid #c8c9ca" }} key={index}>
                                      <td className="py-2 text-center">
                                        <Tooltip title="view" key="view">
                                          {/* <Link to={`/${orgName}/dashboard/tax/tax-details/1234`} > */}
                                          <FiEye className="text-primary"
                                            onClick={() => handleShowDetails(value, index)}
                                            style={{ fontSize: "1rem", cursor: "pointer" }} />
                                          {/* </Link> */}
                                        </Tooltip>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </>
                          ) : "0"}
                          </td>
                        </tr>
                      ))
                  }
                </tbody>
              </table>
              {
                allDataofOrgTaxes.length === 0 && (
                  <div className='mt-5 fs-4 text-center text-secondary'>
                    No Taxes To Show
                  </div>
                )
              }
            </div>
            <div className="my-5">
              <Pagination
                showPerPage={showPerPage}
                onPaginationChange={onPaginationChange}
                total={allDataofOrgTaxes.length}
              />
            </div>
          </Row>
        </Row>
        {/* <TaxDetails
          showDetails={showDetails} setShowDetails={setShowDetails} mainId={mainId} taxId={taxId} GetSingleFirmDetails={GetSingleFirmDetails}
          allDataofOrgTaxes={allDataofOrgTaxes} invoiceData={invoiceData}
        /> */}
        <AddtaxModal showAdd={showAdd} setShowAdd={setShowAdd} />
        <ActivityBar options={taxOptions} list="list" />
      </div>
      {loader}
    </div>
  );
};

export default Addtax;
