import React, { useEffect, useRef, useState } from "react";
import { Link } from 'react-router-dom';
import { Button } from "react-bootstrap";
import { addLeadActivity } from "../../../Hook/leadHooks";
import { ErrorToast, SuccessToast, WarningToast } from "../../../../components/toast";
import { BsEmojiSmileUpsideDown, BsImage } from 'react-icons/bs';
import { BiNotepad } from 'react-icons/bi';
import Picker from "emoji-picker-react";
import { Tooltip } from "@material-ui/core";
import { createReminder } from "../../../Hook/reminderHooks";
import { AiOutlineClose } from "react-icons/ai";

const ActivityForm = ({ setShow, leadId, leadActivitiesByType }) => {

  const today = new Date();
  const formattedDate = today.toISOString().split('T')[0];
  const hours = String(today.getHours()).padStart(2, '0');
  const minutes = String(today.getMinutes()).padStart(2, '0');
  const formattedTime = `${hours}:${minutes}`;

  const [activityDate, setActivityDate] = useState(formattedDate);
  const [activityTime, setActivityTime] = useState(formattedTime);

  const initialObj = {
    activityDate: formattedDate,
    activityTime: formattedTime
  };

  const [form, setForm] = useState(initialObj);
  const [type, setType] = useState('Note');
  const [details, setDetails] = useState('');
  const [totalLetters, setTotalLetters] = useState("");
  const [selectedActivityType, setSelectedActivityType] = useState('');
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [emojiPickerActive, setEmojiPickerActive] = useState(true);
  const [letterLimitReached, setLetterLimitReached] = useState(false);
  const [showDetailsField, setShowDetailsField] = useState(false);
  const [showactiveOptions, setShowActivityOptions] = useState(false);

  const [about, setAbout] = useState('');
  const [reminderDate, setReminderDate] = useState('');

  const [fileName, setFileName] = useState("");
  const [img, setImg] = useState("");
  const [selectedFile, setSelectedFile] = useState('');
  const [chosenEmojis, setChosenEmojis] = useState([]);

  const activityOptions = ['Note', 'Call', 'Meeting'];

  console.log("picker", showEmojiPicker);
  const handleEmojiClick = (emoji) => {
    setAbout((prevValue) => prevValue + emoji.emoji);
    setShowEmojiPicker(true);
    setChosenEmojis((prevEmojis) => [...prevEmojis, emoji.emoji]);
  };

  const handleFile = (file) => {
    setFileName(file.name);
  };

  const hiddenFileInput = useRef(null);

  const handleChangeImg = (event) => {
    const fileUploaded = event.target.files[0];
    handleFile(fileUploaded);
    setSelectedFile(fileUploaded);
    setImg(URL.createObjectURL(event.target.files[0]));
  };

  const [selectedType, setSelectedType] = useState('Note');
  const [reminderDesc, setReminderDesc] = useState('');

  useEffect(() => {
    if (type === "Note") {
      setImg('');
    } else if (type === "Attachment") {
      setAbout('');
      setReminderDate('');
    } else {
      setImg('');
    }
  }, [type])

  useEffect(() => {
    setForm({
      ...form,
      activityDate: activityDate,
      activityTime: activityTime
    });
  }, []);

  const orgId = localStorage.getItem("orgID");
  const formData = new FormData();

  const addActivity = async (e) => {
    e.preventDefault();
    setDisabledBtn(true);
    if (type === 'Note') {
      addLeadActivity(form).then((response) => {
        if (response.status === 201) {
          SuccessToast('Activity Added');
          leadActivitiesByType('All');
          setType('');
          setAbout('');
          setDisabledBtn(false);
          setShow(false);
        } else {
          ErrorToast("Unable to add activity");
          setDisabledBtn(false);
        }
      }).catch((err) => {
        err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Something went wrong');
        setDisabledBtn(false);
      })
    } else if (type === 'Attachment') {
      formData.append("leadId", leadId);
      formData.append("activityDate", activityDate);
      formData.append("activityTime", activityTime);
      formData.append('orgId', orgId);
      formData.append("type", type);
      fileName !== undefined && formData.append("image", selectedFile);
      formData.append('details', details);

      addLeadActivity(formData).then((response) => {
        if (response.status === 201) {
          SuccessToast('Activity Added');
          setFileName('');
          setDetails('')
          setDisabledBtn(false);
          setShow(false);
        } else {
          ErrorToast("Unable to add activity");
          setDisabledBtn(false);
        }
      }).catch((err) => {
        err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Something went wrong');
        setDisabledBtn(false);
      })
    } else {
      const form = {
        text: about,
        date: reminderDate.split('T')[0],
        time: reminderDate.split('T')[1],
        details: details,
      };

      const activityForm = {
        leadId,
        type: 'Reminder',
        desc: about,
        details: details,
        activityDate,
        activityTime,
        orgId
      };

      addLeadActivity(activityForm).then((response) => {
        if (response.status === 201) {
          SuccessToast('Activity Added');
          leadActivitiesByType('All');
          setType('');
          setAbout('');
          setDisabledBtn(false);
          setShow(false);
        } else {
          ErrorToast("Unable to add activity");
          setDisabledBtn(false);
        }
      }).catch((err) => {
        err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Something went wrong');
        setDisabledBtn(false);
      });

      createReminder(form).then((response) => {
        if (response.status === 201) {
          SuccessToast("Reminder created successfully");
          setReminderDesc('');
          setDetails('');
          setShow(false);
        } else {
          ErrorToast("Unable to set reminder");
        }
      }).catch(() => {
        ErrorToast("Unable to set reminder")
      })
    }
  };
  useEffect(() => {
    about && setType('Note');
    reminderDate && setType('Reminder');
    img && setType("Attachment");
  }, [about, reminderDate, img]);

  useEffect(() => {
    setForm({
      ...form,
      desc: about,
      details: details
    })
  }, [about, details])

  const handleChange = (e) => {
    if (e.target.name === 'description') {
      let testTotalLetters = e.target.value;

      const limitReached = () => {
        setLetterLimitReached(true);
        ErrorToast("Letter limit exceeded");
        const test = testTotalLetters.slice(0, 200);
        setAbout(test);
      }

      const inLimit = () => {
        setLetterLimitReached(false)
        setAbout(e.target.value)
      }

      testTotalLetters.length <= 200 ? inLimit() : limitReached();
      setTotalLetters(e.target.value.slice(0, 200));
      setForm({
        ...form,
        [e.target.name]: e.target.value,
        type: type,
        leadId: leadId,
        orgId: orgId,
      });
    } else {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
        leadId: leadId,
        orgId: orgId,
      });
    }
  };

  const handleCloseDetialsField = () => {
    setShowDetailsField(false);
    setDetails('');
    setForm({ ...form, details: '' });
  };

  const handlechangeActivityType = (type) => {
    setSelectedActivityType(type);
    setForm({ ...form, type })
  };

  const handleShowEmojiPicker = () => {
    setEmojiPickerActive(true);
    setShowEmojiPicker(!showEmojiPicker)
  };

  const handleCloseAllSelectors = () => {
    !emojiPickerActive && setShowEmojiPicker(false);
    setEmojiPickerActive(true);
    showactiveOptions && setShowActivityOptions(false);
  }

  return (
    <form className="bg-white p-4 light-modal-content-card"
      onClick={() => handleCloseAllSelectors()}
      onSubmit={addActivity} style={{ marginTop: "-10%", minHeight: "27vh", position: "relative" }}>
      <div className="w-100">
        <div className="d-flex justify-content-between align-items-between">
          <div className="d-flex justify-content-start align-items-start flex-column">
            <label className="d-block">Select Activity Type</label>
            <div className="px-3 fs-5 fw-lighter text-dark rounded-1 bg-soft-success cursor-pointer position-relative" onClick={() => setShowActivityOptions(!showactiveOptions)}>
              {selectedActivityType.length === 0 ? "Select" : selectedActivityType}
            </div>
            {
              showactiveOptions ? (
                <ul className="mt-5 position-absolute bg-white ps-4 pe-3 py-2 rounded-5" style={{ zIndex: "70", boxShadow: "0px 0px 40px -18px #4b4b4bbf" }}>
                  {
                    activityOptions.map((data, index) => (
                      <li className="text-dark fw-lighter cursor-pointer fs-5 py-1" onClick={() => handlechangeActivityType(data)} key={index}>{data}</li>
                    ))
                  }
                </ul>
              ) : null
            }
          </div>

          <div className="d-flex justify-content-start align-items-start flex-column">
            <label className="d-block">Reminder Date and time</label>
            <input type="datetime-local" onChange={(e) => setReminderDate(e.target.value)} className="border border-0 px-1 bg-soft-success text-dark" style={{ background: "#091E420F", borderRadius: "5px" }} />
          </div>

          <div className="d-flex justify-content-start align-items-start flex-column">
            <label className="d-block">Template</label>
            <select className="border border-none px-2 fs-5 bg-soft-success text-dark" style={{ background: "#091E420F", borderRadius: "5px" }}>
              <option className="fs-5 bg-white text-dark">Select</option>
              <option className="fs-5 bg-white text-dark">Tamplate 1</option>
              <option className="fs-5 bg-white text-dark">Tamplate 2</option>
            </select>
          </div>
        </div>
        {
          fileName.length > 0 ? (
            <div className="py-3 w-100 d-flex justify-content-center align-items-start" style={{ border: "0px 0px 2px 0px", borderBottom: "1px solid #00000030" }}>
              <img src={img} style={{ width: "20%", height: "20%" }} />
              <div onClick={() => setFileName('')} className="ms-5 w-100 h-100 text-start" style={{ cursor: "pointer" }}>X</div>
            </div>
          ) : (
            <>
              <textarea rows="5" placeholder="Activity Description" className="mt-3 form-control w-100" name="description"
                value={about} onChange={handleChange} style={{ position: "relative", border: "0px 0px 2px 0px", borderBottom: "1px solid #00000030" }}
              ></textarea>
              <span
                className={`${letterLimitReached ? 'text-danger' : 'text-dark'} d-flex justify-content-center align-items-center`}
                style={{ fontSize: "1rem", fontWeight: "400", position: "absolute", bottom: !showDetailsField && 70, right: 25 }}
              >
                {`${totalLetters.length}/200`}
              </span>
            </>
          )
        }
        {
          showDetailsField ? (
            <div className="mt-5">
              <div className="d-flex justify-content-between align-items-center border border-dark p-2">
                <div className="text-dark">
                  <BiNotepad />
                  <span style={{ fontSize: "1rem", fontWeight: "500" }}>More Details</span>
                </div>
                <AiOutlineClose onClick={() => handleCloseDetialsField()} className="fs-2 text-danger cursor-pointer" />
              </div>
              <textarea rows="5" placeholder="More details about activity" className="form-control w-100" name="details"
                onChange={(e) => setDetails(e.target.value)} style={{ border: "0px 0px 2px 0px", borderBottom: "1px solid #00000030" }}
              ></textarea>
            </div>
          ) : null
        }
        <div className="w-100 d-flex justify-content-between align-items-center">
          <div className="text-dark fs-3 pt-4 d-flex justify-content-between align-items-center" style={{ width: "15%" }}>
            <Tooltip title="image" key="image">
              <Link className="text-dark">
                <BsImage onClick={() => hiddenFileInput.current.click()} style={{ cursor: "pointer" }} />
              </Link>
            </Tooltip>
            <input type="file" onChange={handleChangeImg} ref={hiddenFileInput} className="d-none" />
            <Tooltip title="emoji" key="emoji">
              <Link className="text-dark">
                <BsEmojiSmileUpsideDown onClick={() => handleShowEmojiPicker()} style={{ cursor: "pointer" }} />
              </Link>
            </Tooltip>
            {
              showEmojiPicker ? (
                <div className="w-100" style={{ position: "absolute", top: 150, left: 150 }}
                  onMouseOver={() => setEmojiPickerActive(true)} onMouseLeave={() => setEmojiPickerActive(false)}
                >
                  <Picker onEmojiClick={handleEmojiClick} />
                </div>
              ) : null
            }
            <Tooltip title="More details" key="moreDetails">
              <Link className="text-dark">
                <BiNotepad onClick={() => setShowDetailsField(!showDetailsField)} style={{ cursor: "pointer" }} />
              </Link>
            </Tooltip>
          </div>
        </div>
        <div className="w-100 d-flex justify-content-end align-items-end pt-2 ">
          <Button type="submit">{type === 'Attachment' ? "Add Attachment" : type === 'Reminder' ? "Set Reminder" : "Add Activity"}</Button>
        </div>
      </div>
    </form>
  );
};

export default ActivityForm;
