import axios from "axios";
import { API } from "../../../../backend";
import { ErrorToast } from "../../components/toast";

const orgid = localStorage.getItem("orgID");
const userToken = localStorage.getItem('user_token')

export const getAllFirms = async (setAllFirms) => {
    let url = `${API}/firm/getAllFirm/${orgid}`;
    const config = {
        method: "GET",
        url: url,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== "undefined" && JSON.parse(JSON.stringify(localStorage.getItem("user_token")))}`,
        },
    }
    try {
        const response = await axios(config);
        if (response.status === 200) {
            setAllFirms(response.data.data)
            // setFirmAdd(response.data.data[0].add)
            // console.log("all firms", response.data.data[0].add);
        }
    }
    catch (err) {
        setAllFirms([]);
    }
}

export const getAllFirmsList = async () => {
    let url = `${API}/firm/getAllFirm/${orgid}`;
    const config = {
        method: "GET",
        url: url,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== "undefined" && JSON.parse(JSON.stringify(localStorage.getItem("user_token")))}`,
        },
    }
    const response = await axios(config);
    return response
}

export const getFirmList = async () => {
    let url = `${API}/firm/getFirmList/${orgid}`;
    const config = {
        method: "GET",
        url: url,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== "undefined" && JSON.parse(JSON.stringify(localStorage.getItem("user_token")))}`,
        },
    }
    try {
        const response = await axios(config);
        return response;
    }
    catch (err) {
        console.log(err.message);
    }
}

export const getFirmById = async (id) => {
    let url = `${API}/firm/getFirm/${orgid}/${id}`;
    const config = {
        method: "GET",
        url: url,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== "undefined" && JSON.parse(JSON.stringify(localStorage.getItem("user_token")))}`,
        },
    }
    const response = await axios(config);
    return response;
}

export const getSingleFirm = async (firmId) => {
    let url = `${API}/firm/getFirm/${orgid}/${firmId}`;
    const config = {
        method: "GET",
        url: url,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== "undefined" && JSON.parse(JSON.stringify(localStorage.getItem("user_token")))}`,
        },
    }
    const response = await axios(config);
    return response;
}

export const addNewFirm = async (form) => {
    const url = `${API}/firm/create`;
    const config = {
        method: "POST",
        url: url,
        data: form,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== "undefined" && JSON.parse(JSON.stringify(userToken))}`,
        }
    }
    const response = await axios(config);
    return response;
}

export const deleteFirm = async (id) => {
    const url = `${API}/firm/delete/${id}`;
    const config = {
        method: "DELETE",
        url: url,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== "undefined" && JSON.parse(JSON.stringify(localStorage.getItem("user_token")))}`,
        }
    }
    const response = await axios(config);
    return response;
}

export const getFirmInvoices = async (id, setinvoiceData, setDetails, getDeletedInvoices, selected, getAllDrafts) => {
    const url = `${API}/invoice/getInvoiceByFirm`;
    const config = {
        method: "POST",
        url: url,
        body: {
            firmID: id,
            orgId: orgid,
            delete: false
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== "undefined" && JSON.parse(JSON.stringify(localStorage.getItem("user_token")))}`,
        },
    }
    try {
        const response = await axios(config);
        if (response.status === 200) {
            setinvoiceData(response.data.data)
            if (selected === "All") {
                setDetails(response.data.data);
            } else if (selected === "Pending") {
                const paid = response.data.data;
                setDetails(paid.filter((data, i) => data.status === "Pending"));
            } else if (selected === "Paid") {
                const paid = response.data.data;
                setDetails(paid.filter((data, i) => data.status === "Paid"));
            } else if (selected === "Partial Paid") {
                const paid = response.data.data;
                setDetails(paid.filter((data, i) => data.status === "Partial Paid"));
            } else if (selected === "Overdue") {
                const paid = response.data.data;
                setDetails(paid.filter((data, i) => data.status === "Overdue"));
            } else if (selected === "Deleted") {
                getDeletedInvoices(setDetails);
            } else if (selected === "Draft") {
                getAllDrafts();
            }
        }
        else {
            setinvoiceData([]);
        }
    }
    catch {
        setinvoiceData([]);
    }
}

export const createNewProduct = async (form, id) => {
    let url = `${API}/firm/createProduct/${orgid}/${id}`;
    let config = {
        method: "POST",
        url: url,
        data: form,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== "undefined" && JSON.parse(JSON.stringify(localStorage.getItem("user_token")))}`,
        },
    }
    try {
        const response = await axios(config);
        return response;
    }
    catch (err) {
        ErrorToast("Something went wrong");
    }
}

export const updateFirm = async (form, id) => {
    const url = `${API}/firm/update/${id}`;
    const config = {
        method: 'PATCH',
        url: url,
        data: form,
        headers: {
            "Contet-Type": "application/json",
            "authorization": `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        },
    };

    const response = await axios(config);
    return response;
}

export const updateFirmLogo = async (formData, id) => {
    const url = `${API}/firm/insertlogo/${id}`;
    const config = {
        method: "PATCH",
        url: url,
        data: formData,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`,
        },
    };

    const response = await axios(config);
    return response;
}
