import axios from "axios";
import { API } from "../../../../backend"
import { ErrorToast, SuccessToast } from "../../components/toast";

const orgId = localStorage.getItem("orgID");
const userToken = localStorage.getItem('user_token')

export const addFirmTax = async (form) => {
    const url = `${API}/taxRates/addTaxInFirm`;
    const config = {
        method: "POST",
        url: url,
        data: form,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        }
    };

    const response = await axios(config);
    return response;
};

export const addGlobalTax = async (form) => {
    const url = `${API}/taxRates/postGlobalTax`;
    const config = {
        method: "POST",
        url: url,
        data: form,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        }
    };

    const response = await axios(config);
    return response;
};

export const getAllOrgTaxes = async () => {
    const url = `${API}/taxRates/getAllTaxes/${orgId}`;
    const config = {
        method: "get",
        url: url,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        },
    }
    const response = await axios(config);
    return response;
};

export const getAllTaxes = async (firmId) => {
    const url = `${API}/taxRates/gettaxRates/${firmId}`;
    const config = {
        method: "get",
        url: url,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        },
    };
    const response = await axios(config);
    return response;
};

export const getClientTax = async (taxAmount) => {
    const url = `${API}/taxRates/clientByTax`;
    const config = {
        method: "POST",
        url: url,
        data: taxAmount,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        },
    }
    const response = await axios(config);
    return response;
};

export const getInvoiceByTax = async (invoiceTaxAmount) => {
    const url = `${API}/taxRates/invoiceByTax`;
    const config = {
        method: "POST",
        url: url,
        data: invoiceTaxAmount,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        },
    };

    const response = await axios(config);
    return response;
};

export const getGlobalTaxes = async (setAllGlobalTaxes) => {
    const url = `${API}/taxRates/getGlobalTaxs/${orgId}`;
    const config = {
        method: "get",
        url: url,
        headers: {
            "Content-Type": "application/json",
        },
    }
    try {
        const response = await axios(config);
        if (response.status === 200) {
            if (response.data.data.length > 0) {
                setAllGlobalTaxes(response.data.data[0].taxRates)
            }
            else {
                setAllGlobalTaxes([])
            }
        }
        else {
            setAllGlobalTaxes([]);
        }
    }
    catch (err) {
        setAllGlobalTaxes([]);
    }
};

export const addTaxes = async (data) => {
    const url = `${API}/`;
    const config = {
        method: "post",
        url: url,
        data: data,
        headers: {
            "Content-Type": "application/json"
        },
    }
    try {
        const response = await axios(config);
        if (response.status === 200) {
            SuccessToast('Tax Added')
        }
        else {
            ErrorToast('Unabel to add tax');
        }
    }
    catch (err) {
        ErrorToast('Something went wrong');
    }
};

export const updateTax = async (form, id) => {
    const url = `${API}/taxRates/updateRates/${id}`;
    const config = {
        method: "PATCH",
        url: url,
        data: form,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        }
    };

    const response = await axios(config);
    return response;
}

export const deleteTax = async () => {
    const url = `${API}/taxRates/id/${orgId}`;
    const config = {
        method: "delete",
        url: url,
        headers: {
            "Content-Type": "application/json",
        }
    }
    try {
        const resposne = await axios(config);
        if (resposne.status === 200) {
            SuccessToast("Tax Deleted Successfully");
        }
        else {
            ErrorToast("Unable to delete tax");
        }
    }
    catch (err) {
        ErrorToast("Unable to delete tax");
    }
};