import React, { useEffect } from "react";
import topHeader from "../../../../../assets/images/dashboard/top-header.png";
// import { orgLog } from "../../../../assets/images/imgs/orgLog.jpg";
import Card from "../../../../../components/Card";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { useState } from "react";
import { API } from "../../../../../backend";
import toast, { Toaster } from "react-hot-toast";
import { ErrorToast, SuccessToast } from "../../../components/toast";

const AddOrg = () => {
  const [formData, setFormData] = useState([]);

  const createNewOrg = async (e) => {
    e.preventDefault();
    await fetch(`${API}/org/addOrg`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 200) {
          setFormData({
            ...formData,
            [e.target.name]: e.target.value,
          });
          SuccessToast("Organization Added");
        } else {
          ErrorToast("Something went wrong ! ! !");
        }
      })
      .catch((err) => ErrorToast(err.message));
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Toaster />
      <div
        className="iq-navbar-header"
        style={{
          height: "180px",
          backgroundSize: "cover",
          backgroundImage: `url(${topHeader})`,
        }}
      >
        <Container fluid className=" iq-container">
          <Row>
            <Col md="12">
              <div className="d-flex justify-content-between flex-wrap">
                <div>
                  <h1>Add Organization</h1>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Row
        className="text-dark"
        style={{
          width: "70%",
          marginLeft: "15%",
          height: "100%",
          minHeight: "70vh",
          marginTop: "-3%",
        }}
      >
        <Col>
          <form onSubmit={createNewOrg}>
            <Col className="w-100">
              <div
                className="bg-white mb-2 px-3 rounded text-dark w-100"
                style={{ minHeight: "3rem" }}
              >
                <Card.Header style={{ width: "100%" }}>
                  <div className="w-100">
                    <h4 className=" text-primary fw-bold">Add</h4>
                  </div>
                </Card.Header>
                <div>
                  <Row className="pb-3 px-4">
                    <Col className="mt-3" sm={6}>
                      <Form.Label htmlFor="firstname">
                        Organization Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="border border-primary"
                        placeholder="Organization Name"
                        name="orgName"
                        onChange={handleChange}
                        required
                      />
                    </Col>
                    <Col className="mt-3" sm={6}>
                      <Form.Label htmlFor="password">Password</Form.Label>
                      <Form.Control
                        type="text"
                        className="border border-primary"
                        placeholder="Password"
                        name="password"
                        onChange={handleChange}
                        required
                      />
                    </Col>
                    <Col className="mt-3" sm={6}>
                      <Form.Label htmlFor="email">Email</Form.Label>
                      <Form.Control
                        type="email"
                        className="border border-primary"
                        name="orgEmail"
                        placeholder="Organization Email"
                        onChange={handleChange}
                      />
                    </Col>
                    <Col className="mt-3" sm={6}>
                      <Form.Label htmlFor="title">Alternative Email</Form.Label>
                      <Form.Control
                        type="email"
                        className="border border-primary"
                        name="orgAlterEmail"
                        placeholder="Alternative Email"
                        onChange={handleChange}
                      />
                    </Col>
                    <Col className="mt-3" sm={6}>
                      <Form.Label htmlFor="title">Phone</Form.Label>
                      <Form.Control
                        type="text"
                        className="border border-primary"
                        pattern="\d{10}"
                        name="orgPhone"
                        placeholder="Organization Phone"
                        onChange={handleChange}
                      />
                    </Col>
                    <Col className="mt-3" sm={6}>
                      <Form.Label htmlFor="title">Alternative Phone</Form.Label>
                      <Form.Control
                        type="text"
                        className="border border-primary"
                        pattern="\d{10}"
                        name="orgAlterPhone"
                        placeholder="Alternative Phone"
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="ms-4 mb-5">
                  <Button type="submit" className="mb-3">
                    Add
                  </Button>
                </div>
              </div>
            </Col>
          </form>
        </Col>
      </Row>
    </>
  );
};

export default AddOrg;
