import axios from "axios";
import { API } from "../../../../backend"

const orgid = localStorage.getItem("orgID");
const userToken = localStorage.getItem("user_token");


// Get APIs

export const getAllInvoices = async () => {
    const url = `${API}/invoice/all/${orgid}`;
    const config = {
        method: "GET",
        url: url,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        }
    }
    const response = await axios(config);
    return response;
};

export const getAllDrafts = async () => {
    const url = `${API}/invoice/getAllDrafts/${orgid}`;
    const config = {
        method: "GET",
        url: url,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        }
    };

    const response = await axios(config);
    return response;
}

export const getInvoiceById = async (id) => {
    const url = `${API}/invoice/singleInvoice/${orgid}/${id}`;
    const config = {
        method: "GET",
        url: url,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        }
    };

    const response = await axios(config);
    return response;
};

export const getDeletedInvoices = async () => {
    const url = `${API}/invoice/getAllDeletedInvoices/${orgid}`;
    const config = {
        method: "GET",
        url: url,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        },
    };
    const response = await axios(config);
    return response
};

export const getCancelledInvoices = async () => {
    const url = `${API}/invoice/cancel/${orgid}`;
    const config = {
        method: "GET",
        url: url,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        },
    };
    const response = await axios(config);
    return response
};

export const getAllReminders = async () => {
    const url = `${API}/invoice`;
    const config = {
        method: "GET",
        url: url,
        headers: {
            'Content-Type': "application/json",
        }
    }
    const response = await axios(config);
    return response;
};


// POST APIs

export const createInvoice = async (form) => {
    const url = `${API}/invoice/create`;
    const config = {
        method: "POST",
        url: url,
        data: form,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        },
    }
    const response = await axios(config);
    return response;
};

export const getInvoiceByDate = async (form) => {
    const url = `${API}/invoice`;
    const config = {
        method: 'POST',
        url: url,
        data: form,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        }
    }
    const response = await axios(config);
    return response;
};

export const getInvoicesByClient = async (clientObj) => {
    const url = `${API}/invoice/getInvoiceByClient`;
    const config = {
        method: 'POST',
        url: url,
        data: clientObj,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        }
    };

    const response = await axios(config);
    return response;
};

export const getInvoicesByFirm = async (id) => {
    const url = `${API}/invoice/getInvoiceByFirm`;
    const config = {
        method: "POST",
        url: url,
        data: {
            firmID: id,
            orgId: orgid
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        }
    };

    const response = await axios(config);
    return response;
};

export const getLastInvoiceNumber = async (firmId) => {
    const url = `${API}/invoice/listInvoiceNo`;
    const config = {
        method: "POST",
        url: url,
        data: {
            "orgId": orgid,
            "firmId": firmId
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        },
    }
    const response = await axios(config);
    return response;
};


// PATCH APIs

export const updateDraft = async (editId, form) => {
    const url = `${API}/invoice/updateDraft/${editId}`;
    const config = {
        method: "PATCH",
        url: url,
        data: form,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        },
    }
    const response = await axios(config);
    return response;
};

export const updateInvoice = async (editId, form) => {
    const url = `${API}/invoice/updateInvoice/${editId}`;
    const config = {
        method: "PATCH",
        url: url,
        data: form,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        },
    }
    const response = await axios(config);
    return response;
};

export const draftToInvoice = async (id) => {
    const url = `${API}/invoice/drafttoinvoice/${id}`;
    const config = {
        method: "PATCH",
        url: url,
        data: {
            draft: false,
            status: "Pending"
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        },
    }
    const response = await axios(config);
    return response;
};

export const recordPayment = async (form, id) => {
    const url = `${API}/invoice/payment/${id}`;
    const config = {
        method: "PATCH",
        url: url,
        data: form,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        }
    }
    const response = await axios(config);
    return response;
};

export const softDeleteInvoice = async (id) => {
    const url = `${API}/invoice/softDeleteInvoice/${id}`;
    const config = {
        method: "PATCH",
        url: url,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        }
    };

    const response = await axios(config);
    return response;
};

export const restoreDeletedInvoice = async (id) => {
    const url = `${API}/invoice/restoreInvoice/${id}`;
    const config = {
        method: "PATCH",
        url: url,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        }
    };

    const response = await axios(config);
    return response;
};

export const cancelInvoice = async (id) => {
    const url = `${API}/invoice/cancelInvoice/${id}`;
    const config = {
        method: "PATCH",
        url: url,
        data: {
            cancel: true,
            status: "Cancelled"
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        },
    }
    const response = await axios(config);
    return response;
};

export const restoreCancelledInvoice = async (id) => {
    const url = `${API}/invoice/restoreCancelInvoice/${id}`;
    const config = {
        method: "PATCH",
        url: url,
        data: {
            cancel: false,
            status: "Pending"
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        },
    }
    const response = await axios(config);
    return response;
};


// Delete APIs

export const hardDeleteInvoice = async (id) => {
    const url = `${API}/invoice/deleteInvoice/${id}`;
    const config = {
        method: "DELETE",
        url: url,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        }
    };
    const response = await axios(config);
    return response;
};