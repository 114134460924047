import React, { useEffect } from "react";

//router
import { Switch, Route } from "react-router";
//layoutpages
import Index from "../views/index";
import Default from "../layouts/dashboard/default";
import Horizontal from "../layouts/dashboard/horizontal";
import Boxed from "../layouts/dashboard/boxed";
import DualHorizontal from "../layouts/dashboard/dual-horizontal";
import DualCompact from "../layouts/dashboard/dual-compact";
import BoxedFancy from "../layouts/dashboard/boxed-fancy";
import Simple from "../layouts/dashboard/simple";
import SignIn from "../views/dashboard/auth/sign-in";
import SignUp from "../views/dashboard/auth/sign-up";
import dashboard from "../views/dashboard";
import InvoiceAdd from "../views/dashboard/app/invoice/add-invoice";
import InvoiceList from "../views/dashboard/app/invoice/invoice-list";
import InvoiceDetails from "../views/dashboard/app/invoice/invoice-details";
import AddFirm from "../views/dashboard/app/firm/add-firm";
// import FirmList from '../views/dashboard/app/firm/firm-list'
import FirmList from "../views/dashboard/app/firm/firm-list";
import AddClient from "../views/dashboard/app/client/add-client";
import ClientList from "../views/dashboard/app/client/client-list";
import ShareInvoice from "../views/dashboard/app/invoice/share-invoice";
import Autologout from "../views/dashboard/auth/autologout";
import orgResigter from "../views/dashboard/app/org/org-register";
import ErrorPage from "../views/dashboard/app/purchase/ErrorPage";
import SharePurchase from "../views/dashboard/app/purchase/Share-Purchase";
import { useHistory } from "react-router-dom";
import AcceptInvitation from "../views/dashboard/auth/acceptInvitation";
import ForgotPassword from "../views/dashboard/auth/forgetPassword";

const IndexRouters = () => {

  const orgName = localStorage.getItem("orgName");
  const history = useHistory();

  // useEffect(() => {
  //   orgName && history.push(`/${orgName}/dashboard`)
  // }, []);

  return (
    <>
      <Switch>
        {/* <Route exact path="/autologout" component={Autologout}></Route> */}
        {/* {
          (!orgName) && ( */}
        <Route exact path="/" component={SignIn}></Route>
        <Route exact path="/login/:id" component={SignIn}></Route>
        <Route exact path="/forgot" component={ForgotPassword}></Route>
       <Route exact path="/acceptInvitation" component={AcceptInvitation}></Route>
        {/* )
        } */}
        <Route exact path="/signup" component={SignUp}></Route>
        <Route exact path="/admin-register" component={orgResigter}></Route>
        <Route exact path="/invoice/:id" component={ShareInvoice}></Route>
        <Route exact path="/purchase/:id" component={SharePurchase}></Route>
        <Route path={`/${orgName}/dashboard`} component={Default}></Route>
        <Route path="/boxed" component={Boxed}></Route>
        <Route path="/horizontal" component={Horizontal}></Route>
        <Route path="/dual-horizontal" component={DualHorizontal}></Route>
        <Route path="/dual-compact" component={DualCompact}></Route>
        <Route path="/boxedFancy" component={BoxedFancy}></Route>
        <Route path="/auth" component={Simple}></Route>
        <Route path="/errors" component={Simple}></Route>
        <Route path="/addinvoice" component={InvoiceAdd}></Route>
        <Route path="/invoicelist" component={InvoiceList}></Route>
        <Route path="/invoice-details/:id" component={InvoiceDetails}></Route>
        <Route path="/firm/addfirm" component={AddFirm}></Route>
        <Route path="/firm/firm-list" component={FirmList}></Route>
        <Route path="/client/add-client" component={AddClient}></Route>
        <Route path="/client/client-list" component={ClientList}></Route>
        {/* <Route path='*' exact component={ErrorPage} /> */}
      </Switch>
    </>
  );
};

export default IndexRouters;
