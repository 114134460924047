import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import Card from "../../../../components/Card";
import { API } from "../../../../backend";
import { MdDeleteOutline } from "react-icons/md";
import Cancel from "@mui/icons-material/Cancel";
import { Modal } from "react-bootstrap";
import { getCity, getCountries, getState } from "../Hook/CustomHooks";
import { Link, useHistory, useParams } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import { toast, Toaster } from "react-hot-toast";
import "../btnstyle.css";
import SubHeader from "../../../../components/partials/dashboard/HeaderStyle/sub-header";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import axios from "axios";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { ErrorToast, SuccessToast, WarningToast } from "../../components/toast";
import { deleteFirm, getSingleFirm, updateFirm, updateFirmLogo } from "../Hook/firmHooks";

const EditFirm = () => {
    const [form, setForm] = useState({});
    //   const [id, setid] = useState("");
    const [firmDetails, setFirmDetails] = useState({});
    const [address, setAddress] = useState({});
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const [clientCountry, setClientCountry] = useState('');
    const [isOpenCountry, setIsOpenCountry] = useState(false);
    const [clientState, setClientState] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [clientCity, setClientCity] = useState('');
    const [isOpenClientCity, setIsOpenClientCity] = useState(false);

    const [personCity, setPersonCity] = useState('');
    const [isOpenPersonCity, setIsOpenPersonCity] = useState(false);
    const [personState, setPersonState] = useState('');
    const [isOpenPersonState, setIsOpenPersonState] = useState(false);
    const [personCountry, setPersonCountry] = useState('');
    const [isOpenPersonCountry, setIsOpenPersonCountry] = useState(false);

    const [personCountries, setPersonCountries] = useState([]);
    const [personStates, setPersonStates] = useState([]);
    const [personCities, setPersonCities] = useState([]);

    const [contactPersonDetails, setContactPersonDetails] = useState({});

    const { id } = useParams();
    const [singleFirm, setSingleFirm] = useState({});
    const [singleFirmAdd, setFirmAdd] = useState({});
    const [showMsg, setShowMsg] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const [showBasic, setShowBasic] = useState(true);
    const [showContact, setShowContact] = useState(false);
    const [contactPerson, setContactPerson] = useState(false);
    const [showContactPerson, setShowContactPerson] = useState(false);
    const [logo, setLogo] = useState("");
    const [person, setPerson] = useState({});
    const [value, setValue] = useState();
    const [selectedCountry, setSelectedtCountry] = useState('');
    const [selectedPersonCountry, setSelectedtPersonCountry] = useState('');

    const orgId = localStorage.getItem("orgID");
    const orgName = localStorage.getItem("orgName");
    const history = useHistory();
    // const navigate = useNavigate();

    const formData = new FormData();

    const firmDetailById = async () => {
        getSingleFirm(id).then((response) => {
            if (response.status === 200) {
                if (response?.data?.data?.length > 0) {
                    setSingleFirm(response?.data?.data[0]);
                    setFirmAdd(response?.data?.data[0]?.add);
                    setFirmDetails({
                        name: response?.data?.data[0]?.name,
                        phone: response?.data?.data[0]?.phone,
                        email: response?.data?.data[0]?.email,
                        address: response?.data?.data[0]?.add,
                        logo: response?.data?.data[0]?.logo,
                    });

                    setClientCountry(response?.data?.data[0]?.add?.country ? response?.data?.data[0]?.add?.country : '');
                    setClientState(response?.data?.data[0]?.add?.state ? response?.data?.data[0]?.add?.state : '');
                    setClientCity(response?.data?.data[0]?.add?.city ? response?.data?.data[0]?.add?.city : '');

                    setContactPersonDetails(response?.data?.data[0]?.contectPerson)
                    setPersonCountry(response?.data?.data[0]?.contectPerson?.country ? response?.data?.data[0]?.contectPerson?.country : '');
                    setPersonState(response?.data?.data[0]?.contectPerson?.state ? response?.data?.data[0]?.contectPerson?.state : '');
                    setPersonCity(response?.data?.data[0]?.contectPerson?.city ? response?.data?.data[0]?.contectPerson?.city : '');
                } else {
                    setSingleFirm({});
                    setFirmAdd({});
                    setFirmDetails({
                        name: "",
                        phone: "",
                        email: "",
                        address: "",
                        logo: "",
                    })
                    setContactPersonDetails({})
                }

            } else {
                setSingleFirm({});
                setFirmAdd({});
                setFirmDetails({
                    name: "",
                    phone: "",
                    email: "",
                    address: "",
                    logo: "",
                })
                setContactPersonDetails({})
            }
        }).catch(() => {
            setSingleFirm({});
            setFirmAdd({});
            setFirmDetails({
                name: "",
                phone: "",
                email: "",
                address: "",
                logo: "",
            })
            setContactPersonDetails({})
        });
    };

    useEffect(() => {
        firmDetailById();
    }, []);

    const handleDeleteFirm = async () => {
        deleteFirm(id).then((response) => {
            if (response.status === 400) {
                ErrorToast("Unable to delete the firm");
            } else {
                SuccessToast("Firm deleted successfully");
                setShowDelete(false);
                setTimeout(() => {
                    history.push(`/${orgName}/dashboard/app/firm/firm-list`)
                }, 1000);
            }
        }).catch((error) => {
            if (error?.response?.data?.message) {
                WarningToast(error?.response?.data?.message);
                setShowDelete(false);
                setShowMsg(false);
            } else {
                ErrorToast("Something went wrong");
            }
        });
    };

    const handlePerson = (e) => {
        e.preventDefault();
        setPerson({
            ...person,
            [e.target.name]: e.target.value,
            // phone: contactPersonValue,
            // altPhone: contactPersonAlterValue,
            // mobile: contactPersonMobile,
            // altMobile: contactPersonAlterMobile
        });
        if (e.target.name === "pinCode") {
            if (e.target.value > selectedPersonCountry === "United States" ? 7 : 6) {
                e.target.value = e.target.value.slice(0, selectedPersonCountry === "United States" ? 7 : 6)
                setPerson({
                    ...person,
                    pinCode: e.target.value
                })
            }
        }


        if (e.target.name === 'phone' || e.target.name === 'altPhone' || e.target.name === 'mobile' || e.target.name === 'altMobile') {
            e.target.value = e.target.value.slice(0, 10);
            setPerson({
                ...person,
                [e.target.name]: e.target.value
            })
        }

        setForm({
            ...form,
            // phone: value,
            add: { ...address },
            contectPerson: { ...person }
        });
    };

    const handleChange = (e) => {

        if (e.target.name === 'phone') {
            e.target.value = e.target.value.slice(0, 10);
            setForm({
                ...form,
                phone: e.target.value
            })
        }

        setForm({
            ...form,
            [e.target.name]: e.target.value,
            phone: value,
            orgId: orgId
        });
    };

    const handleLogo = (e) => {
        setLogo(e.target.files[0]);
    };

    const handleAddress = (e) => {
        setAddress({
            ...address,
            [e.target.name]: e.target.value,
        });
        if (e.target.name === "pinCode") {
            if (e.target.value > selectedCountry === "United States" ? 7 : 6) {
                e.target.value = e.target.value.slice(0, selectedCountry === "United States" ? 7 : 6)
                setAddress({
                    ...address,
                    pinCode: e.target.value
                })
            }
        }
        setForm({
            ...form,
            add: { ...address }
        });
    };

    useEffect(() => {
        getCountries().then((res) => {
            setCountries(res);
        });
    }, []);

    useEffect(() => {
        if (address.country) {
            getState(address.country).then((res) => {
                setStates(res);
            });
        }
    }, [address.country]);

    useEffect(() => {
        if (address.state) {
            getCity(address.state).then((res) => {
                setCities(res);
            });
        }
    }, [address.state]);

    useEffect(() => {
        getCountries().then((res) => {
            setPersonCountries(res);
        });
    }, []);

    useEffect(() => {
        if (person.country) {
            getState(person.country).then((res) => {
                setPersonStates(res);
            });
        }
    }, [person.country]);

    useEffect(() => {
        if (person.state) {
            getCity(person.state).then((res) => {
                setPersonCities(res);
            });
        }
    }, [person.state]);


    const handleInputChangeCountry = (e) => {
        setClientCountry(e.target.value);

        setIsOpenCountry(true);
        setAddress({
            ...address,
            country: e.target.value
        })
    };

    const handleOptionClickCountry = (i) => {
        setClientCountry(i)
        setAddress({
            ...address,
            country: i
        })
        setIsOpenCountry(false);
    };

    const handleInputChange = (e) => {
        setClientState(e.target.value);

        setIsOpen(true);
        setAddress({
            ...address,
            state: e.target.value
        })
    };

    const handleOptionClick1 = (i) => {
        setClientState(i)
        setAddress({
            ...address,
            state: i
        })
        setIsOpen(false);
    };

    const handleInputChangeCity = (e) => {
        setClientCity(e.target.value);

        setIsOpenClientCity(true);
        setAddress({
            ...address,
            city: e.target.value
        })
    };

    const handleOptionClickCity = (i) => {
        setClientCity(i)
        setAddress({
            ...address,
            city: i
        })
        setIsOpenClientCity(false);
    };

    const filteredCountryOptions = countries.filter((option) =>
        (option.country_name).toLowerCase().includes(clientCountry.toLowerCase())
    );

    const filteredOptions = states.filter((option) =>
        (option.state_name).toLowerCase().includes(clientState.toLowerCase())
    );

    const filteredCityOptions = cities.filter((option) =>
        (option.city_name).toLowerCase().includes(clientCity.toLowerCase())
    );

    const handleChangePersonCountry = (e) => {
        setPersonCountry(e.target.value);

        setIsOpenPersonCountry(true);
        setPerson({
            ...person,
            country: e.target.value
        })
    };

    const handleClickPersonCountry = (i) => {
        setPersonCountry(i);
        setPerson({
            ...person,
            country: i
        })
        setIsOpenPersonCountry(false);
    };

    const handleChangePersonState = (e) => {
        setPersonState(e.target.value);

        setIsOpenPersonState(true);
        setPerson({
            ...person,
            state: e.target.value
        })
    };

    const handleClickPersonState = (i) => {
        setPersonState(i)
        setPerson({
            ...person,
            state: i
        })
        setIsOpenPersonState(false);
    };

    const handlechangePersonCity = (e) => {
        setPersonCity(e.target.value);

        setIsOpenPersonCity(true);
        setPerson({
            ...person,
            city: e.target.value
        })
    };

    const handleClickPersonCity = (i) => {
        setPersonCity(i)
        setPerson({
            ...person,
            city: i
        })
        setIsOpenPersonCity(false);
    };

    const filteredPersonCountries = personCountries.filter((option) =>
        (option.country_name).toLowerCase().includes(personCountry.toLowerCase())
    );

    const filteredPersonStates = personStates.filter((option) =>
        (option.state_name).toLowerCase().includes(personState.toLowerCase())
    );

    const filteredPersonCities = personCities.filter((option) =>
        (option.city_name).toLowerCase().includes(personCity.toLowerCase())
    );

    const handleSubmitLogo = async () => {
        formData.append("logo", logo);
        updateFirmLogo(formData, id).then((response) => {
            if (response.status === 201) {
                SuccessToast("Firm updated successfully");
            } else {
                ErrorToast("Unable to update firm");
            }
        }).catch((error) => {
            error?.message ? WarningToast(error?.response?.data?.message) : ErrorToast("Something went wrong");
        })
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        if (logo.length > 0) {
            handleSubmitLogo();
        }
        updateFirm(form, id).then((response) => {
            if (response.status === 201) {
                firmDetailById();
                SuccessToast("Firm Updated Successfully");
            } else if (response.status === 403) {
                ErrorToast(response.message);
            } else {
                ErrorToast("Unable to update firm");
            }
        }).catch((error) => {
            error.message ? WarningToast(error?.response?.data?.message) : ErrorToast("Something went wrong")
        })
    };

    useEffect(() => {
        const handleResize = setScreenWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    });

    const url = singleFirm.logo;
    const handleContactPerson = () => {
        setContactPerson(true);
        setShowContactPerson(true);
    };

    return (
        <div
            onClick={() =>
                isOpen && setIsOpen(false) || isOpenClientCity && setIsOpenClientCity(false) || isOpenCountry && setIsOpenCountry(false) ||
                isOpenPersonCity && setIsOpenPersonCity(false) || isOpenPersonState && setIsOpenPersonState(false) || isOpenCountry && setIsOpenCountry(false)
            }
        >
            <Toaster />
            <SubHeader title="Update Firm" />
            <Row className="text-dark mx-3 mb-5" style={{ height: "100%", minHeight: "70vh", marginTop: screenWidth >= 1200 ? "-1%" : "-3%", }}>
                <form onSubmit={handleUpdate}>
                    <div className="bg-white mb-2 px-3 rounded text-dark" style={{ minHeight: "3rem" }}>
                        <Card.Header className="bg-white" style={{ width: "100%" }}>
                            <Row className="w-100" onClick={() => setShowBasic(!showBasic)}>
                                <Col sm={11} style={{ cursor: "pointer" }}>
                                    <h4 className=" text-primary">Basic Details</h4>
                                </Col>
                                <Col sm={1} className="text-end">
                                    {showBasic === false ? (
                                        <BsChevronDown className="fs-4 fw-bold text-dark" onClick={() => setShowBasic(true)} style={{ cursor: "pointer" }} />
                                    ) : (
                                        <BsChevronUp className="fs-4 fw-bold text-dark" onClick={() => setShowBasic(false)} style={{ cursor: "pointer" }} />
                                    )}
                                </Col>
                            </Row>
                        </Card.Header>
                        {
                            showBasic && (
                                <div>
                                    <Row className="pb-3 px-4">
                                        <Col className="mt-3" sm={6}>
                                            <Form.Label htmlFor="name">Firm Name</Form.Label>
                                            <Form.Control type="text" name="name" defaultValue={singleFirm.name === undefined ? '' : singleFirm.name} onChange={handleChange} className="border-primary" />
                                        </Col>
                                        <Col className="mt-3" sm={6}>
                                            <Form.Group>
                                                <Form.Label htmlFor="name">Registered Firm Name</Form.Label>
                                                <Form.Control type="text" defaultValue={singleFirm.registeredFirmName === undefined ? '' : singleFirm.registeredFirmName} name="registeredFirmName" placeholder={"Registered Firm Name"} onChange={handleChange} className="border-primary" required />
                                                <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col className="mt-3" sm={6}>
                                            <Form.Label>Website</Form.Label>
                                            <input type="url" name="website"
                                                placeholder={"Website"}
                                                onChange={handleChange} className="w-100 p-2 border border-primary"
                                                style={{ borderRadius: "5px" }}
                                                required
                                            />
                                        </Col>
                                        <Col className="" sm={6}>
                                            <lable className="d-block w-100 m-0 mt-2 mb-0">Logo</lable>
                                            <input type="file" name="logo" onChange={handleLogo} className="w-75 m-0 p-2 border border-primary" style={{ borderRadius: "5px" }} />
                                            <img src={singleFirm.logo} alt="logo" width="70px" height="70px" className="ms-5 mt-0 rounded-circle" />
                                        </Col>
                                        <Col className="mt-3" sm={6}>
                                            <Form.Label>GST / Tax Id</Form.Label>
                                            <Form.Control type="text" name="gst_no" defaultValue={singleFirm.gst_no === undefined ? 'GST / Tax Id' : singleFirm.gst_no} placeholder="GST / Tax Id" onChange={handleChange} className="border-primary" />
                                        </Col>
                                        <Col className="mt-3" sm={6}>
                                            <Form.Label>Invoice Prefix</Form.Label>
                                            <Form.Control type="text" name="gst_no" defaultValue={singleFirm.gst_no === undefined ? 'Invoice Prefix' : singleFirm.invoicePrefix} placeholder="Invoice Prefix" onChange={handleChange} className="border-primary" />
                                        </Col>
                                    </Row>
                                </div>
                            )
                        }
                    </div>
                    <div className="bg-white my-3 px-3 rounded text-dark">
                        <Card.Header className="bg-white" style={{ width: "100%" }}>
                            <Row className="w-100" onClick={() => setShowContact(!showContact)}>
                                <Col sm={11} style={{ cursor: "pointer" }}>
                                    <h4 className=" text-primary">Contact</h4>
                                </Col>
                                <Col sm={1} className="text-end">
                                    {showContact === false ? (
                                        <BsChevronDown className="fs-4 fw-bold text-dark" onClick={() => setShowContact(true)} style={{ cursor: "pointer" }} />
                                    ) : (
                                        <BsChevronUp className="fs-4 fw-bold text-dark" onClick={() => setShowContact(false)} style={{ cursor: "pointer" }} />
                                    )}
                                </Col>
                            </Row>
                        </Card.Header>
                        {
                            showContact && (
                                <Row className="pb-3 px-4">
                                    <Col className="mt-3" sm={6}>
                                        <Form.Label htmlFor="phone">Phone</Form.Label>
                                        <Form.Control type="number" name="phone" pattern="\d{10}" maxLength="10" placeholder="Phone" defaultValue={singleFirm.phone ? singleFirm.phone : 'Phone'}
                                            onChange={handleChange} className="text-dark border-primary"
                                        />
                                        {/* <PhoneInput
                                            placeholder="Phone Number"
                                            // country="US"
                                            international
                                            defaultCountry="IN"
                                            value={value}
                                            onChange={setValue}
                                            className="text-dark px-2 py-2 border-primary"
                                            style={{ border: "1px solid", borderRadius: "5px" }}
                                            limitMaxLength={10}
                                        /> */}
                                    </Col>
                                    <Col sm={6} className="mt-3">
                                        <Form.Label htmlFor="phone">Email</Form.Label>
                                        <Form.Control type="email" name="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                            defaultValue={singleFirm.email}
                                            placeholder="Email"
                                            onChange={handleChange} className="border-primary"
                                        />
                                    </Col>
                                    <Col sm={6} className="mt-3">
                                        <Form.Label htmlFor="address">Address 1 *</Form.Label>
                                        <Form.Control type="text" name="address1" onChange={handleAddress}
                                            defaultValue={singleFirmAdd.address1}
                                            placeholder="Address 1"
                                            className="border-primary"
                                        />
                                    </Col>
                                    <Col sm={6} className="mt-3">
                                        <Form.Label htmlFor="address">Address 2</Form.Label>
                                        <Form.Control type="text" name="address2" onChange={handleAddress}
                                            defaultValue={singleFirmAdd.address2}
                                            placeholder="Address 2"
                                            className="border-primary"
                                        />
                                    </Col>
                                    <Form.Group style={{ position: 'relative' }} className="col-sm-6 mt-2 form-group">
                                        <Form.Label>Country *</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="autocomplete-state"
                                            className="border border-primary"
                                            // defaultValue={address.country}
                                            value={clientCountry}
                                            name="state"
                                            placeholder="Select Country"
                                            onChange={handleInputChangeCountry}
                                            onClick={() => setIsOpenCountry(true)}
                                            // onBlur={() => setIsOpen(false)}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>

                                        {isOpenCountry && (
                                            <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                                {
                                                    countries.length > 0 ? (
                                                        filteredCountryOptions.length > 0 ? (
                                                            filteredCountryOptions.map((option, i) => (
                                                                <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleOptionClickCountry(option.country_name)}>
                                                                    {option.country_name}
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <div className="mt-2">No match found</div>
                                                        )
                                                    ) : (
                                                        <div className="mt-2">Loading . . . . </div>
                                                    )
                                                }
                                            </div>
                                        )}
                                    </Form.Group>

                                    <Form.Group style={{ position: 'relative' }} className="col-sm-6 mt-2 form-group">
                                        <Form.Label>State *</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="autocomplete-state"
                                            className="border border-primary"
                                            value={clientState}
                                            name="state"
                                            placeholder="Select State"
                                            onChange={handleInputChange}
                                            onClick={() => setIsOpen(true)}
                                            // onBlur={() => setIsOpen(false)}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>

                                        {isOpen && (
                                            <>
                                                <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                                    {
                                                        states.length > 0 ? (
                                                            filteredOptions.length > 0 ? (
                                                                filteredOptions.map((option, i) => (
                                                                    <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleOptionClick1(option.state_name)}>
                                                                        {option.state_name}
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <div className="mt-2">No match found</div>
                                                            )
                                                        ) : (
                                                            <div className="mt-2">Select country first </div>
                                                        )
                                                    }
                                                </div>
                                            </>
                                        )}
                                    </Form.Group>

                                    <Form.Group style={{ position: 'relative' }} className="col-sm-6 form-group">
                                        <Form.Label>City *</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="autocomplete-state"
                                            className="border border-primary"
                                            value={clientCity}
                                            name="city"
                                            placeholder="Select City"
                                            onChange={handleInputChangeCity}
                                            onClick={() => setIsOpenClientCity(true)}
                                            // onBlur={() => setIsOpen(false)}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>

                                        {isOpenClientCity && (
                                            <>
                                                <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                                    {
                                                        cities.length > 0 ? (
                                                            filteredCityOptions.length > 0 ? (
                                                                filteredCityOptions.map((option, i) => (
                                                                    <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleOptionClickCity(option.city_name)}>
                                                                        {option.city_name}
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <div className="mt-2">No match found</div>
                                                            )
                                                        ) : (
                                                            <div className="mt-2">Select state first </div>
                                                        )
                                                    }
                                                </div>
                                            </>
                                        )}
                                    </Form.Group>

                                    <Col sm={6} className="mt-2">
                                        <Form.Label htmlFor="address">Pincode/Zipcode</Form.Label>
                                        <Form.Control type="number" name="pinCode"
                                            placeholder={singleFirmAdd.pinCode ? singleFirmAdd.pinCode : 'Pincode/Zipcode'} onChange={handleAddress} className="border-primary"
                                        />
                                    </Col>
                                    <div className="my-3">
                                        <hr />
                                        <h4 className="text-dark">Contact Person</h4>
                                        <input
                                            type="radio"
                                            name="contactperson"
                                            className="border border-primary"
                                            // value="link"
                                            onClick={() => setContactPerson(true)}
                                            style={{ cursor: "pointer" }}
                                            defaultChecked
                                            checked={contactPerson === true}
                                        />
                                        <label className="fs-5 text-primary ms-1" onClick={() => setContactPerson(true)} style={{ cursor: "pointer", fontSize: "0.7rem" }}>
                                            Yes
                                        </label>

                                        <input
                                            type="radio"
                                            name="contactperson"
                                            className="border border-primary ms-5"
                                            // value="link"
                                            onClick={() => setContactPerson(false)}
                                            style={{ cursor: "pointer" }}
                                            checked={contactPerson === false}
                                        />
                                        <label className="fs-5 text-primary ms-1" onClick={() => setContactPerson(false)} style={{ cursor: "pointer", fontSize: "0.7rem" }}>
                                            No
                                        </label>
                                        {
                                            contactPerson && (
                                                <Row>
                                                    <Col sm={6}>
                                                        <Form.Group>
                                                            <Form.Label>Name</Form.Label>
                                                            <Form.Control type="text" className="border-primary" name="name" onChange={handlePerson}
                                                                placeholder={contactPersonDetails === undefined || contactPersonDetails.name === undefined ? '' : contactPersonDetails.name}
                                                            // placeholder="Name"
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <Form.Group>
                                                            <Form.Label>Email</Form.Label>
                                                            <Form.Control type="email" className="border-primary" name="email" onChange={handlePerson}
                                                                placeholder={contactPersonDetails === undefined || contactPersonDetails.email === undefined ? '' : contactPersonDetails.email}
                                                            // placeholder="Email"
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={6} className="mt-2">
                                                        <Form.Group>
                                                            <Form.Label>Phone</Form.Label>
                                                            <Form.Control type="number" className='border border-primary' pattern="\d{10}" maxLength={10} name="phone"
                                                                onChange={handlePerson}
                                                                placeholder={contactPersonDetails === undefined || contactPersonDetails.phone === undefined ? '' : contactPersonDetails.phone}
                                                            // placeholder="Phone No."
                                                            />
                                                            {/* <PhoneInput
                                                                placeholder={contactPersonDetails === undefined || contactPersonDetails.phone === undefined ? '' : contactPersonDetails.phone}
                                                                // country="US"
                                                                international
                                                                defaultCountry="IN"
                                                                value={contactPersonValue}
                                                                onChange={setContactPersonValue}
                                                                className="text-dark px-2 py-2 border-primary"
                                                                style={{ border: "1px solid", borderRadius: "5px" }}
                                                                limitMaxLength={10}
                                                            /> */}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={6} className="mt-2">
                                                        <Form.Group>
                                                            <Form.Label>Alternative Phone</Form.Label>
                                                            <Form.Control type="number" className='border border-primary' pattern="\d{10}" maxLength="10" name="altPhone" onChange={handlePerson}
                                                                placeholder={contactPersonDetails === undefined || contactPersonDetails.altPhone === undefined ? '' : contactPersonDetails.altPhone}
                                                            // placeholder="Alternative Phone No."
                                                            />
                                                            {/* <PhoneInput
                                                                placeholder={contactPersonDetails === undefined || contactPersonDetails.altPhone === undefined ? '' : contactPersonDetails.altPhone}
                                                                // country="US"
                                                                international
                                                                defaultCountry="IN"
                                                                value={contactPersonAlterValue}
                                                                onChange={setContactPersonAlterValue}
                                                                className="text-dark px-2 py-2 border-primary"
                                                                style={{ border: "1px solid", borderRadius: "5px" }}
                                                                limitMaxLength={10}
                                                            /> */}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={6} className="mt-2">
                                                        <Form.Group>
                                                            <Form.Label>Mobile</Form.Label>
                                                            <Form.Control type="number" className='border border-primary' pattern="\d{10}" maxLength={10} name="mobile" onChange={handlePerson}
                                                                placeholder={contactPersonDetails === undefined || contactPersonDetails.mobile === undefined ? '' : contactPersonDetails.mobile}
                                                            // placeholder="Mobile No."
                                                            />
                                                            {/* <PhoneInput
                                                                placeholder={contactPersonDetails === undefined || contactPersonDetails.mobile === undefined ? '' : contactPersonDetails.mobile}
                                                                // country="US"
                                                                international
                                                                defaultCountry="IN"
                                                                value={contactPersonMobile}
                                                                onChange={setContactPersonMobile}
                                                                className="text-dark px-2 py-2 border-primary"
                                                                style={{ border: "1px solid", borderRadius: "5px" }}
                                                                limitMaxLength={10}
                                                            /> */}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={6} className="mt-2">
                                                        <Form.Group>
                                                            <Form.Label>Alternative Mobile</Form.Label>
                                                            <Form.Control type="number" className='border border-primary' pattern="\d{10}" maxLength="10" name="altMobile" onChange={handlePerson}
                                                                placeholder={contactPersonDetails === undefined || contactPersonDetails.altMobile === undefined ? '' : contactPersonDetails.altMobile}
                                                            // placeholder="Alternative Mobile No."
                                                            />
                                                            {/* <PhoneInput
                                                                placeholder={contactPersonDetails === undefined || contactPersonDetails.altMobile === undefined ? '' : contactPersonDetails.altMobile}
                                                                // country="US"
                                                                international
                                                                defaultCountry="IN"
                                                                value={contactPersonAlterMobile}
                                                                onChange={setContactPersonAlterMobile}
                                                                className="text-dark px-2 py-2 border-primary"
                                                                style={{ border: "1px solid", borderRadius: "5px" }}
                                                                limitMaxLength={10}
                                                            /> */}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={6} className="mt-2">
                                                        <Form.Group>
                                                            <Form.Label>Address 1</Form.Label>
                                                            <Form.Control type="text" className="border-primary" name="address1" onChange={handlePerson}
                                                                placeholder={contactPersonDetails === undefined || contactPersonDetails.address1 === undefined ? '' : contactPersonDetails.address1}
                                                            // placeholder="Address 1"
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={6} className="mt-2">
                                                        <Form.Group>
                                                            <Form.Label>Address 2</Form.Label>
                                                            <Form.Control type="text" className="border-primary" name="address2" onChange={handlePerson}
                                                                placeholder={contactPersonDetails === undefined || contactPersonDetails.address2 === undefined ? '' : contactPersonDetails.address2}
                                                            // placeholder="Address 2"
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={6} className="mt-3">
                                                        <Form.Group style={{ position: 'relative' }}>
                                                            <Form.Label>Select Country</Form.Label>
                                                            <input
                                                                type="text"
                                                                id="autocomplete-state"
                                                                className="w-100 p-2 border border-primary"
                                                                value={personCountry}
                                                                name="personCountry"
                                                                placeholder="Select Country"
                                                                onChange={handleChangePersonCountry}
                                                                onClick={() => setIsOpenPersonCountry(true)}
                                                                style={{ borderRadius: "5px" }}
                                                            />

                                                            {isOpenPersonCountry && (
                                                                <>
                                                                    <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                                                        {
                                                                            personCountries.length > 0 ? (
                                                                                filteredPersonCountries.length > 0 ? (
                                                                                    filteredPersonCountries.map((option, i) => (
                                                                                        <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleClickPersonCountry(option.country_name)}>
                                                                                            {option.country_name}
                                                                                        </div>
                                                                                    ))
                                                                                ) : (
                                                                                    <div className="mt-2">No match found</div>
                                                                                )
                                                                            ) : (
                                                                                <div className="mt-2">Loading . . . . </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </>
                                                            )}
                                                        </Form.Group>
                                                    </Col>

                                                    <Col sm={6} className="mt-3">
                                                        <Form.Group style={{ position: 'relative' }}>
                                                            <Form.Label>Select State</Form.Label>
                                                            <input
                                                                type="text"
                                                                id="autocomplete-state"
                                                                className="w-100 m-0 p-2 border border-primary"
                                                                value={personState}
                                                                name="personState"
                                                                placeholder="Select State"
                                                                onChange={handleChangePersonState}
                                                                onClick={() => setIsOpenPersonState(true)}
                                                                style={{ borderRadius: "5px" }}
                                                            />

                                                            {isOpenPersonState && (
                                                                <>
                                                                    <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                                                        {
                                                                            personStates.length > 0 ? (
                                                                                filteredPersonStates.length > 0 ? (
                                                                                    filteredPersonStates.map((option, i) => (
                                                                                        <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleClickPersonState(option.state_name)}>
                                                                                            {option.state_name}
                                                                                        </div>
                                                                                    ))
                                                                                ) : (
                                                                                    <div className="mt-2">No match found</div>
                                                                                )
                                                                            ) : (
                                                                                <div className="mt-2">Select country first </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </>
                                                            )}
                                                        </Form.Group>
                                                    </Col>

                                                    <Col sm={6} className="mt-3">
                                                        <Form.Group style={{ position: 'relative' }}>
                                                            <Form.Label>Select City</Form.Label>
                                                            <input
                                                                type="text"
                                                                id="autocomplete-state"
                                                                className="w-100  p-2 border border-primary"
                                                                value={personCity}
                                                                name="personCountry"
                                                                placeholder="Select City"
                                                                onChange={handlechangePersonCity}
                                                                onClick={() => setIsOpenPersonCity(true)}
                                                                style={{ borderRadius: "5px" }}
                                                            />

                                                            {isOpenPersonCity && (
                                                                <>
                                                                    <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                                                        {
                                                                            personCities.length > 0 ? (
                                                                                filteredPersonCities.length > 0 ? (
                                                                                    filteredPersonCities.map((option, i) => (
                                                                                        <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleClickPersonCity(option.city_name)}>
                                                                                            {option.city_name}
                                                                                        </div>
                                                                                    ))
                                                                                ) : (
                                                                                    <div className="mt-2">No match found</div>
                                                                                )
                                                                            ) : (
                                                                                <div className="mt-2">Select state first </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={6} className="mt-3">
                                                        <Form.Label htmlFor="address">Pincode/Zipcode</Form.Label>
                                                        <Form.Control type="number" name="pinCode"
                                                            placeholder={contactPersonDetails === undefined || contactPersonDetails.pinCode === undefined ? 'Pincode/zipcode' : contactPersonDetails.pinCode}
                                                            // placeholder="Pincode/Zipcode"
                                                            onChange={handlePerson} className="border-primary"
                                                        />
                                                    </Col>
                                                </Row>
                                            )
                                        }
                                    </div>
                                </Row>
                            )
                        }
                    </div>
                    <div className="bg-white mb-2 px-3 py-2 rounded text-dark" style={{ minHeight: "3rem" }}>
                        <Row className="mb-3">
                            <Col sm={1} className="px-4 mt-4">
                                <button type="submit" className="m-0 p-0 bg-none border-0">
                                    <span className="m-0 p-0 bg-primary py-2 px-4 text-white" style={{ borderRadius: "5px" }}>Update</span>
                                </button>
                            </Col>
                            {/* {singleFirm.email && ( */}
                            <Col>
                                <>
                                    {showMsg === true ? (
                                        <div className="m-0 p-0 mb-2 ms-3">
                                            <p className="text-danger" style={{ display: "inline" }}>You want to delete the firm ?</p>
                                            <span className="yesbtn" style={{ cursor: "pointer" }} onClick={() => setShowDelete(true)}>Yes</span>
                                            <span className="nobtn" style={{ cursor: "pointer" }} onClick={() => setShowMsg(false)}>No</span>
                                        </div>
                                    ) : (
                                        <div className="m-0 p-0 mb-2">
                                            <p className="text-danger" style={{ display: "inline" }}></p>
                                            <span style={{ cursor: "pointer" }}></span>
                                            <span style={{ cursor: "pointer" }}></span>
                                        </div>
                                    )

                                    }
                                    <span className="p-0 mt-5 bg-danger py-2 px-4 text-white" onClick={() => setShowMsg(true)} style={{ borderRadius: "5px", cursor: "pointer" }}>Delete</span>
                                </>
                            </Col>
                            {/* )} */}
                        </Row>
                    </div>
                    <Modal centered show={showDelete} onHide={() => setShowDelete(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Delete</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="d-flex w-100 text-dark" style={{ justifyContent: "space-between" }}>
                                <p className="d-inline">You want to delete the firm ?</p>
                                <div>
                                    <span>
                                        <Tooltip title="Delete">
                                            <Link>
                                                <MdDeleteOutline className="fs-2 text-danger" style={{ cursor: "pointer" }} onClick={() => handleDeleteFirm()} />
                                            </Link>
                                        </Tooltip>
                                    </span>
                                    <span>
                                        <Tooltip title="Close">
                                            <Link>
                                                <Cancel className="fs-2 text-primary" style={{ cursor: "pointer" }} onClick={() => setShowDelete(false)} />
                                            </Link>
                                        </Tooltip>
                                    </span>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </form>
            </Row>
        </div>
    );
};

export default EditFirm;
