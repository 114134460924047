import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "../pagination/pagination";
import { FiEye, FiFilter } from "react-icons/fi";
import { BsFillShareFill } from "react-icons/bs";
import { Tooltip } from "@material-ui/core";
import { API } from "../../../../backend";
import SubHeader from "../../../../components/partials/dashboard/HeaderStyle/sub-header";
import ActivityBar from "../../../../components/partials/components/activitybar";
import "./styles/invoice.css";
import axios from "axios";
import { MdDeleteOutline, MdOutlineRefresh } from "react-icons/md";
import { getAllDrafts, getAllInvoices, getCancelledInvoices, getDeletedInvoices, getInvoiceByDate, getInvoiceById, restoreDeletedInvoice, updateInvoice } from "../Hook/invoiceHooks";
import DeleteInvoice from "./delete-invoice";
import ShareModal from "./share-modal";
import useFullPageLoader from "../Hook/useFullPageLoader";
import { RWebShare } from "react-web-share";
import { ErrorToast, SuccessToast, WarningToast } from "../../components/toast";
import { addRecentActivity, getRecentActivities } from "../Hook/recentActivityHooks";
import { getUserById } from "../Hook/userHooks";
import ContactAdmin from "../../components/contact-admin";

const InvoiceList = () => {
  const [details, setDetails] = useState([]);
  const [allRecentActivities, setAllRecentActivities] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]);
  const [createPermission, setCreatePermission] = useState(true);
  const [deletePermission, setDeletePermission] = useState(true);
  const [allInvoices, setAllInvoices] = useState([]);
  const [showPerPage, setShowPerPage] = useState(10);
  const [invoiceData, setinvoiceData] = useState([]);
  const [selected, setSelected] = useState("All");
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });
  const [arr, setArr] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const breakPoint = 970;
  const [invoiceId, setInvoiceId] = useState("");
  const [showFilter, setShowFilter] = useState(false);

  const [clientFistName, setClientFirstName] = useState("");
  const [clientFirmName, setClientFirmName] = useState("");
  const [clientLastName, setClientLastName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientAddress, setClientAddress] = useState({});
  const [deleteId, setDeleteId] = useState('');
  const [showDelete, setShowDelete] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [showContactAdmin, setShowContactAdmin] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const orgId = localStorage.getItem("orgID");
  const orgName = localStorage.getItem("orgName");
  const userId = localStorage.getItem('userId');

  const datetimeString = new Date();
  const dateObject = new Date(datetimeString);

  const formattedDate = `${dateObject.getDate()}/${dateObject.getMonth() + 1}/${dateObject.getFullYear()}`;
  const formattedTime = dateObject.toLocaleTimeString();

  var date = new Date();
  date.setDate(date.getDate() - 7);

  var today = new Date();
  today.setDate(today.getDate());

  var month = new Date();
  month.setDate(month.getDate() - 30);

  var currentDate = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();
  var finalDate = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
  var lastMonthDate = month.getDate() + '/' + (month.getMonth() + 1) + '/' + month.getFullYear();

  useEffect(() => {
    getUserById(userId).then((response) => {
      if (response.status === 200) setUserPermissions(response?.data?.data?.permissions);
      else setUserPermissions([]);
    }).catch(() => setUserPermissions([]));
  }, [userId]);

  useEffect(() => {
    const create = userPermissions.length > 0 && userPermissions.some((permission) => permission.module === 'invoice' && permission.action.includes('Create'));
    const deletePermit = userPermissions.length > 0 && userPermissions.some((permission) => permission.module === 'invoice' && permission.action.includes('Delete'));

    setCreatePermission(create);
    setDeletePermission(deletePermit);
  }, [userPermissions]);

  const handleFilter = (name) => {
    showLoader();
    setSelected(name);

    const weekForm = {
      from: currentDate,
      to: finalDate
    }

    const monthForm = {
      from: currentDate,
      to: lastMonthDate
    }

    if (selected === name) {
      getAllInvoices().then((response) => {
        if (response.status === 200) {
          if (selected === "All") {
            setDetails(response.data.data);
          } else if (selected === "Pending") {
            const paid = response.data.data;
            setDetails(paid.filter(data => data.status === "Pending"));
          } else if (selected === "Paid") {
            const paid = response.data.data;
            setDetails(paid.filter(data => data.status === "Paid"));
          } else if (selected === "Partial Paid") {
            const paid = response.data.data;
            setDetails(paid.filter(data => data.status === "Partial Paid"));
          }
          setinvoiceData(response.data.data);
        } else {
          setDetails([]);
          setinvoiceData({});
        }
      }).catch(() => {
        setDetails([]);
        setinvoiceData({});
      })
    }
    hideLoader();
  };

  const allDraftList = async () => {
    getAllDrafts().then((response) => {
      if (response.status === 200) {
        const list = response.data.data;
        list.length > 0 ? setInvoiceId(list[0]._id) : setInvoiceId('')
        list.length > 0 ? setDetails(list) : setDetails([]);
      } else {
        setInvoiceId('');
        setDetails([]);
      }
    }).catch(() => {
      setInvoiceId('');
      setDetails([]);
    });
  };

  useEffect(() => {
    handleFilter(selected);
  }, [selected]);

  const invoiceListByDate = (form) => {
    getInvoiceByDate(form).then((response) => {
      if (response.status === 201) {
        const list = response.data.data;
        setInvoiceId(list[0]._id);
        setDetails(response.data.data);
      } else {
        setInvoiceId('');
      }
    }).catch(() => {
      setInvoiceId('');
      setDetails([]);
    })
  }

  const allCanceledInvoices = () => {
    getCancelledInvoices().then((response) => {
      if (response.status === 200) {
        const list = response.data.data;
        list.length > 0 ? setInvoiceId(list[0]._id) : setInvoiceId('')
        list.length > 0 ? setDetails(list) : setDetails([]);
      } else {
        setInvoiceId('');
        setDetails([]);
      }
    }).catch(() => {
      setInvoiceId('');
      setDetails([]);
    });
  };

  const getAllDeletedInvoices = () => {
    getDeletedInvoices().then((response) => {
      if (response.status === 200) {
        const list = response.data.data;
        list.length > 0 ? setInvoiceId(list[0]._id) : setInvoiceId('')
        list.length > 0 ? setDetails(list) : setDetails([]);
      } else {
        setInvoiceId('');
        setDetails([]);
      }
    }).catch(() => {
      setInvoiceId('');
      setDetails([]);
    });
  }

  const allInoicesList = async () => {
    showLoader();
    getAllInvoices().then((response) => {
      if (response.status === 200) {
        setAllInvoices(response.data.data);
        if (response.data.data[0].client) {
          if (selected === "All") {
            const paid = response.data.data;
            setInvoiceId(paid[0]._id);
            setDetails(response.data.data);
            hideLoader();
            setShowFilter(false);

          } else if (selected === "Pending") {
            const paid = response.data.data;
            const newArr = paid.filter((data, i) => data.status === "Pending");
            newArr.length > 0 ? setDetails(newArr) : setDetails([]);
            newArr.length > 0 && setInvoiceId(newArr[0]._id);
            hideLoader();
            setShowFilter(false);

          } else if (selected === "Paid") {
            const paid = response.data.data;
            const newArr = paid.filter((data, i) => data.status === "Paid");
            newArr.length > 0 && setInvoiceId(newArr[0]._id);
            newArr.length > 0 ? setDetails(newArr) : setDetails([]);
            hideLoader();
            setShowFilter(false);

          } else if (selected === "Partial Paid") {
            const paid = response.data.data;
            const newArr = paid.filter((data, i) => data.status === "Partial Paid");
            newArr.length > 0 && setInvoiceId(newArr[0]._id);
            newArr.length > 0 ? setDetails(newArr) : setDetails([]);
            hideLoader();
            setShowFilter(false);

          } else if (selected === "Overdue") {
            const paid = response.data.data;
            const newArr = paid.filter((data, i) => data.status === "Overdue");
            newArr.length > 0 && setInvoiceId(newArr[0]._id);
            newArr.length > 0 ? setDetails(newArr) : setDetails([]);
            hideLoader();
            setShowFilter(false);

          } else if (selected === "Disputed") {
            const dispute = response.data.data;
            const newArr = dispute.filter((data, i) => data.dispute !== undefined);
            newArr.length > 0 && setInvoiceId(newArr[0]._id);
            newArr.length > 0 ? setDetails(newArr) : setDetails([]);
            hideLoader();
            setShowFilter(false);

          } else if (selected === "Deleted") {
            getAllDeletedInvoices();
            hideLoader();
            setShowFilter(false);

          } else if (selected === "Canceled") {
            allCanceledInvoices();
            hideLoader();
            setShowFilter(false);
          } else if (selected === "Draft") {
            allDraftList();
          } else if (selected === 'Last 7 Days') {
            const form = {
              from: currentDate,
              to: finalDate
            }
            invoiceListByDate(form);

          } else if (selected === 'Last 7 Days') {
            const form = {
              from: currentDate,
              to: lastMonthDate
            }
            invoiceListByDate(form)
          }
        }
        setinvoiceData(response.data.data);
      } else {
        hideLoader();
        setAllInvoices([]);
        setinvoiceData({});
        setInvoiceId('');
      }
    }).catch((err) => {
      err?.response?.data?.message && setShowContactAdmin(true)
      hideLoader();
      setAllInvoices([]);
      setinvoiceData({});
      setInvoiceId('');
    })
  };

  useEffect(() => {
    allInoicesList();
  }, [selected]);

  const currentDay = new Date();

  useEffect(() => {
    allInvoices.map((data) => {
      let givenDate = new Date(data.dueDate);
      const editId = data._id;
      const form = {
        orgId: orgId,
        status: 'Overdue'
      }

      if (data.status === 'Pending' && currentDay > givenDate) {
        updateInvoice(editId, form).then((response) => {
          if (response.status === 200) {
            allInoicesList();
          } else {
            ErrorToast("Unable to update");
          }
        }).catch((err) => {
          err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast("Something went wrong");
        });
      }
    });
  }, [allInvoices]);

  const getAllRecentActivities = () => {
    getRecentActivities("invoice").then((response) => {
      response.status === 200 ? setAllRecentActivities(response?.data?.data) : setAllRecentActivities([]);
    }).catch(() => setAllRecentActivities([]));
  }

  useEffect(() => {
    getAllRecentActivities();
  }, []);

  const getData = async () => {
    getInvoiceByDate(invoiceId).then((response) => {
      if (response.status == 200) {
        setArr(response.data[0]);
        setClientFirstName(response.data[0].client.firstName);
        setClientFirmName(response.data[0].client.clientFirmName);
        setClientLastName(response.data[0].client.lastName);
        setClientEmail(response.data[0].client.email);
        setClientAddress(response.data[0].client.address);
      } else {
        setArr([]);
      }
    }).catch(() => {
      setArr([]);
    });

    getInvoiceById(invoiceId).then((response) => {
      if (response.status == 200) {
        setArr(response.data.data[0]);
        setClientFirstName(response.data.data[0].client.firstName);
        setClientFirmName(response.data.data[0].client.clientFirmName);
        setClientLastName(response.data.data[0].client.lastName);
        setClientEmail(response.data.data[0].client.email);
        setClientAddress(response.data.data[0].client.address);
      } else {
        setArr([]);
      }
    }).catch(() => {
      setArr([]);
    });
  };

  useEffect(() => {
    getData();
  }, [invoiceId])

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const format = (dateString) => {
    const options = { day: "numeric", month: "numeric", year: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  const options = () => {
    return (
      <div style={{ position: "relative", zIndex: 1 }}>
        <div className="mx-3 text-dark fs-5">Filters</div>
        <Row className="text-dark mx-3 py-2 ps-2 pe-3 my-2" onClick={() => setShowFilter(!showFilter)} style={{ cursor: "pointer", border: "1px solid #c8c9ca", borderRadius: "5px" }}>

          <Col sm>
            {
              selected === "All" ? 'All' : `${selected}`
            }
          </Col>
          <Col sm={1}>
            <Tooltip title="filter">
              <Link to="#">
                <FiFilter className="text-dark fs-4" />
              </Link>
            </Tooltip>
          </Col>
        </Row>
        {
          showFilter && (
            <ul className="m-0 ms-3 p-0 bg-white rounded" style={{ width: "91%", zIndex: 9, listStyle: "none", position: "absolute", boxShadow: "0px 0px 10px #c8c9ca" }}>
              <li className={`${"border-white text-dark"} py-2 px-3 rounded`}
                style={{ cursor: "pointer", borderBottom: "3px solid" }}
              // key={index}
              >
                <input type="date" />
              </li>
              {filters.map((data, index) => (
                <>
                  <li onClick={() => handleFilter(data)}
                    className={`${data === selected ? " bg-primary border-primary text-white" : "border-white text-dark"} py-2 px-3 rounded`}
                    style={{ cursor: "pointer", borderBottom: "3px solid" }}
                    key={index}
                  >
                    {data}
                  </li>
                </>
              ))}
            </ul>
          )
        }
      </div>
    );
  };

  const filters = ['All', 'Last 7 Days', 'Last 30 Days', 'Pending', 'Partial Paid', 'Paid', 'Overdue', 'Draft', 'Deleted', 'Cenceled', 'Disputed']
  const mainfilters = ['All', 'Pendig', 'Paid', 'Overdue'];

  const path = `${window.location.protocol}/${window.location.hostname}/invoice/${invoiceId}`;

  const handleDeleteInvoice = (invoId) => {
    setDeleteId(invoId);
    setShowDelete(true);
  };

  const handleRestoreDeletedInvoice = (data) => {
    restoreDeletedInvoice(data?._id).then((response) => {
      if (response.status === 200) {

        const activityForm = {
          module: ['invoice', 'firm', 'client'],
          entityId: [data?._id, data?.firm?.firmID, data?.client?.client_id],
          activity: 'Deleted Invoice Restored',
          activityDesc: `Invoice ${data?.invoiceNumber} restored`,
          createdDate: formattedDate,
          createdTime: formattedTime,
          userId: localStorage.getItem('userId')
        };

        addRecentActivity(activityForm).then((response) => response.status === 201 && getAllRecentActivities());

        allInoicesList();
        SuccessToast("Invoice Restored SuccessFully");
      }
      else {
        ErrorToast("Unable to restore the invoice");
      }
    }).catch((err) => {
      err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Somethign went wrong');
    });
  }

  const styleHandle = (data) => {
    if (data.status === "Pending") {
      return {
        backgroundColor: "#FFB802",
        textAlign: "center",
        fontSize: "90%",
      };
    } else if (data.status === "Paid") {
      return {
        backgroundColor: "green",
        textAlign: "center",
        fontSize: "90%",
      };
    } else if (data.status === "Partial Paid") {
      return {
        backgroundColor: "blue",
        textAlign: "center",
        fontSize: "90%",
      };
    } else if (data.status === "Overdue") {
      return {
        backgroundColor: "#ff0000",
        textAlign: "center",
        fontSize: "90%",
      };
    } else if (data.status === "Canceled" || data.status === "Cancel") {
      return {
        backgroundColor: "#a9083d",
        textAlign: "center",
        fontSize: "90%",
      };
    } else if (data.status === "Draft") {
      return {
        backgroundColor: "#374550",
        textAlign: "center",
        fontSize: "90%",
      };
    }
  };

  const styleHandleText = (data) => {
    if (data.status === "Pending") {
      return {
        color: "#FFB802",
        textAlign: "right",
      };
    } else if (data.status === "Paid") {
      return {
        color: "green",
        textAlign: "right",
      };
    } else if (data.status === "Partial Paid") {
      return {
        color: "blue",
        textAlign: "right",
      };
    } else if (data.status === "Overdue") {
      return {
        color: "#ff0000",
        textAlign: "right",
      };
    } else if (data.status === "Draft") {
      return {
        color: "black",
        textAlign: "right",
      };
    }
  };

  return (
    <div
    // onClick={() => { showFilter === true && setShowFilter(false) }}
    >
      <ContactAdmin show={showContactAdmin} setShow={setShowContactAdmin} />
      {
        createPermission ? (
          <SubHeader title="Invoices" btnTitle="Create Invoice" btnLink={`/${orgName}/dashboard/invoice/add-invoice`} />
        ) : (
          <SubHeader title="Invoices" />
        )
      }
      <div>
        <div className="d-flex text-dark" style={{ marginTop: screenWidth >= 1200 ? "-2%" : "-3%", flexDirection: "row" }}>
          <Row sm={3} className="w-25 rounded mx-2" style={{ height: "100%", marginRight: "10%", fontSize: "0.8rem" }}>
            <Col sm={12} className="bg-white rounded">
              <div>
                <Row className="py-2" style={{ borderBottom: "1px solid rgb(125 125 125)" }}>
                  <Col sm={8}>
                    <h5 className="text-primary" style={{ fontWeight: "550" }}>Preview</h5>
                  </Col>
                  <Col sm={2} className="text-end">
                    <Link to={`/${orgName}/dashboard/invoice/invoice-details/${invoiceId}`}>
                      <FiEye className="text-success" style={{ fontSize: "1rem" }} />
                    </Link>
                  </Col>
                  <Col sm={2}>
                    <RWebShare data={{ text: "Share Invoice", url: path, title: "invoice" }}>
                      <BsFillShareFill className="text-primary" style={{ fontSize: "1rem", cursor: "pointer" }} />
                    </RWebShare>
                  </Col>
                </Row>
              </div>
              <div className="my-3">
                <Row className="mt-2">
                  <h5 className="text-primary" style={{ textAlign: "right" }}>Invoice</h5>
                  <span className="m-0 p-0 pe-3 w-100 text-end text-primary">{arr.invoiceNumber === undefined ? "" : arr.invoiceNumber}</span>
                </Row>
                <Row className="mt-2">
                  <h5 style={styleHandleText(arr)}>{arr.status}</h5>
                </Row>
                <Row className="my-3">
                  <Col>
                    <Row>
                      <Col sm={12} className="text-dark" style={{ fontWeight: "550" }}>Invoice Date:</Col>
                      <Col sm={12} className="text-dark">{format(arr.invoiceDate)}</Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <Col sm={12} className="text-dark text-end" style={{ fontWeight: "550" }}>Due Date:</Col>
                      <Col sm={12} className="text-dark text-end">{format(arr.dueDate)}</Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="my-3 text-dark">
                  <Col>Bill To:</Col>
                  <Col className="w-100 mb-1" sm={12}>
                    <Row>
                      <Col style={{ fontWeight: 550 }} sm={3}>Name:</Col>
                      {
                        clientFirmName ? (
                          <Col>{`${clientFirmName === undefined ? "" : clientFirmName}`}</Col>
                        ) : (
                          <Col>{`${clientFistName === undefined ? "" : clientFistName} ${clientLastName === undefined ? "" : clientLastName}`}</Col>
                        )
                      }
                    </Row>
                  </Col>
                  <Col sm={12} className="mb-1">
                    <Row>
                      <Col style={{ fontWeight: 550 }} sm={3}>
                        Email:
                      </Col>
                      <Col>{clientEmail === undefined ? "" : clientEmail}</Col>
                    </Row>
                  </Col>
                  <Col sm={12}>
                    <Row>
                      <Col style={{ fontWeight: 550 }} sm={3}>Address:</Col>
                      <Col sm={8}>
                        <div>
                          {`${(clientAddress === undefined || clientAddress.address1 === undefined) ? " " : `${clientAddress.address1},`}`}
                        </div>
                        <div>
                          {`${(clientAddress === undefined || clientAddress.address2 === undefined) ? " " : `${clientAddress.address2},`}`}
                        </div>
                        <div>
                          {`${(clientAddress === undefined || clientAddress.country === undefined) ? " " : `${clientAddress.country},`}`}
                          {` ${(clientAddress === undefined || clientAddress.state === undefined) ? " " : `${clientAddress.state},`}`}
                          {` ${(clientAddress === undefined || clientAddress.city === undefined) ? " " : `${clientAddress.city},`}`}
                        </div>
                        <div>
                          {`${(clientAddress === undefined || clientAddress.pinCode === undefined) ? " " : `${clientAddress.pinCode}`}`}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row style={{ textAlign: "right" }}>
                  <div className="text-dark" style={{ fontWeight: "550" }}>Total:</div>
                  <div className="text-dark">
                    {arr.currency === undefined ? "" : arr.currency} {arr.total === undefined ? " " : (arr.total).toFixed(2)}
                  </div>
                </Row>
              </div>
            </Col>
          </Row>
          <Row sm={7} className={`h-100 mx-2 ps-3 mb-5`} style={{ minWidth: screenWidth >= breakPoint ? "50%" : "50%", width: "70%", maxWidth: "80%", }}>
            <Row sm={12} className="bg-white rounded pt-2 mb-3 text-center fs-4">
              {mainfilters.map((data, index) => (
                <Col onClick={() => handleFilter(data)} className={`${data === selected ? "border-primary text-primary" : "border-white text-dark"}`}
                  style={{ cursor: "pointer", borderBottom: "3px solid" }} key={index}
                >
                  {data}
                </Col>
              ))}
            </Row>
            <Row className="bg-white rounded">
              <div className="table-responsive mt-2" style={{ minHeight: "50vh" }}>
                <table className="" style={{ width: "100%" }}>
                  <thead>
                    <tr className="h-20 text-primary" style={{ borderBottom: "1px solid #a09fa3" }}>
                      <th className="fw-bold" style={{ fontSize: "0.9rem" }}></th>
                      <th className="fw-bold" style={{ fontSize: "0.9rem" }}>Invoice No.</th>
                      <th className="fw-bold" style={{ fontSize: "0.9rem" }}>Firm Name</th>
                      <th className="fw-bold" style={{ fontSize: "0.9rem" }}>Client Name</th>
                      <th className="fw-bold" style={{ fontSize: "0.9rem" }}>Status</th>
                      <th className="fw-bold" style={{ fontSize: "0.9rem" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {details.slice(pagination.start, pagination.end).map((data, index) => (
                      <tr key={index}>
                        <td className="py-3" style={{ height: "30px", borderBottom: "1px solid rgb(227 227 227)", }} key={index}>
                          <Form.Check.Input type="radio" name="radiobtn" className="" value={data._id} onChange={() => setInvoiceId(data._id)}
                            checked={invoiceId === data._id} style={{ cursor: "pointer" }}
                          />
                        </td>
                        <td className="w-5 py-3" style={{ width: "12%", borderBottom: "1px solid rgb(227 227 227)" }}>
                          <span className="ms-0 text-dark">{data.invoiceNumber}</span>
                        </td>
                        <td className="py-3" style={{ width: "25%", borderBottom: "1px solid rgb(227 227 227)" }}>
                          {`${(data.firm === undefined || data.firm.name === undefined) ? "" : data.firm.name}`}
                        </td>
                        {
                          data.client.clientFirmName ? (
                            <td className="py-3" style={{ width: "25%", borderBottom: "1px solid rgb(227 227 227)" }}>
                              {`${(data.client === undefined || data.client.clientFirmName === undefined) ? "" : data.client.clientFirmName} `}
                            </td>
                          ) : (
                            <td className="py-3" style={{ width: "25%", borderBottom: "1px solid rgb(227 227 227)" }}>
                              {`${(data.client === undefined || data.client.firstName === undefined) ? "" : data.client.firstName} `}
                              {`${(data.client === undefined || data.client.lastName === undefined) ? "" : data.client.lastName}`}
                            </td>
                          )
                        }
                        <td className="pt-3" style={{ borderBottom: "1px solid rgb(227 227 227)", }}>
                          <p className="text-white rounded-pill w-50 text-center" style={styleHandle(data)}>{data.status}</p>
                        </td>
                        <td style={{ borderBottom: "1px solid rgb(227 227 227)" }}>
                          <div className="flex text-start list-user-action">
                            <Tooltip title="View" key="view">
                              <Link to={`/${orgName}/dashboard/invoice/invoice-details/${data._id}`}>
                                <FiEye className="text-primary" style={{ fontSize: "1.2rem", cursor: "pointer" }} />
                              </Link>
                            </Tooltip>
                            {
                              deletePermission ? (
                                <Tooltip title={`${selected === "Deleted" ? 'Delete Permanently' : 'Delete'}`} key="view">
                                  <Link to={`#`}>
                                    <MdDeleteOutline onClick={() => handleDeleteInvoice(data._id)} className="text-danger mx-2" style={{ fontSize: "1.2rem", cursor: "pointer", }} />
                                  </Link>
                                </Tooltip>
                              ) : null
                            }
                            {
                              data.delete === true && (
                                <Tooltip title="Restore Invoice" key="delete">
                                  <Link to={`#`}>
                                    <MdOutlineRefresh onClick={() => handleRestoreDeletedInvoice(data)} className="text-success" style={{ fontSize: "1.2rem", cursor: "pointer", }} />
                                  </Link>
                                </Tooltip>
                              )
                            }
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {
                  details.length === 0 && (
                    <div className='mt-5 fs-4 text-center text-secondary'>
                      No Invoices To Show
                    </div>
                  )
                }
              </div>
              <div className="my-5">
                <Pagination
                  showPerPage={showPerPage}
                  onPaginationChange={onPaginationChange}
                  total={details.length}
                />
              </div>
            </Row>
          </Row>
          <ShareModal showShare={showShare} setShowShare={setShowShare} invoiceId={invoiceId} />
          <ActivityBar options={options} allRecentActivities={allRecentActivities} />
          <DeleteInvoice getAllRecentActivities={getAllRecentActivities} showDelete={showDelete} setShowDelete={setShowDelete} id={deleteId} selected={selected} getAllInvoices={allInoicesList} setDetails={setDetails} />
        </div>
      </div>
      {loader}
    </div >
  );
};

export default InvoiceList;