import React, { useState } from "react";
import topHeader from "../../../../assets/images/dashboard/top-header.png";
import {
  Row,
  Col,
  Container,
  Form,
  Button,
  Modal,
  Toast,
} from "react-bootstrap";
import "./payroll.css";
import { API } from "../../../../backend";
import { toast } from "react-hot-toast";
import SubHeader from "../../../../components/partials/dashboard/HeaderStyle/sub-header";
import { useEffect } from "react";
import { ErrorToast, SuccessToast } from "../../components/toast";

const Form16 = () => {
  const [formData, setFormData] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const requestAdvance = async (e) => {
    e.preventDefault();
    await fetch(`${API}/`, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then((response) => {
        console.log("Request sent");
        if (response.status === 200) {
          setFormData({
            ...formData,
            [e.target.name]: e.target.reset(),
          });
          SuccessToast("Request sent");
        } else {
          ErrorToast("Something went wrong");
        }
      })
      .catch((err) => ErrorToast(err.message));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const handleResize = setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  console.log("form data", formData);

  return (
    <>
      <SubHeader title="Salary Advance" />
      <div className="h-100 text-dark" style={{ minHeight: "90vh" }}>
        <div
          className="d-flex text-dark ms-5"
          style={{
            marginTop: screenWidth >= 1200 ? "-1%" : "-3%",
            flexDirection: "row",
          }}
        >
          <Row
            sm={7}
            className={`h-100 bg-white rounded mx-2 mb-5`}
            style={{
              minWidth: "50%",
              width: "75%",
              maxWidth: "80%",
            }}
          >
            <div
              className="table-responsive mt-2 mb-2"
              style={{ minHeight: "50vh" }}
            >
              <p className="fs-5 fw-bold text-primary">
                Request for a salary advance
              </p>
              <form onSubmit={requestAdvance}>
                <Form.Group>
                  <Form.Label>Amount</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Amount"
                    className="text-dark border border-primary w-50"
                    name="amount"
                    onChange={handleChange}
                    required
                    autoComplete="off"
                  />
                </Form.Group>
                <Form.Group className="mt-2">
                  <Form.Label>EMI</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="EMI"
                    className="text-dark border border-primary w-50"
                    name="emi"
                    onChange={handleChange}
                    required
                    autoComplete="off"
                  />
                </Form.Group>
                <Form.Group className="mt-2">
                  <Form.Label>Reason</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="6"
                    className="text-dark border border-primary w-50"
                    name="reason"
                    onChange={handleChange}
                    placeholder="Reason"
                  />
                </Form.Group>
                <Button type="submit" className="mt-2">
                  Request Advance
                </Button>
              </form>
            </div>
          </Row>
          <Row
            sm={2}
            className="ms-2 mb-"
            style={{
              width: "25%",
              height: "100%",
              maxHeight: "90vh",
            }}
          >
            <Col className="rounded m-0 w-75 bg-white h-10 pt-2">
              <p className="fs-5 fw-bold text-primary">Current Status</p>
              <div>
                <div>
                  <span className="m-0 d-block" style={{ fontWeight: "550" }}>
                    Advanced Salary
                  </span>
                  <span className="m-0 d-block">Rs. 0</span>
                </div>
                <div className="my-2">
                  <span className="m-0 d-block" style={{ fontWeight: "550" }}>
                    Current EMI
                  </span>
                  <span className="m-0 d-block">Rs. 0</span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Form16;
