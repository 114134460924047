import axios from "axios";
import { API } from "../../../../backend";
import { toast } from "react-hot-toast";
import { ErrorToast } from "../../components/toast";

const orgName = localStorage.getItem('orgName');
const orgId = localStorage.getItem('orgID');
const userToken = localStorage.getItem('user_token')

export const createProduct = async (form) => {
    const url = `${API}/product/createProduct`;
    const config = {
        method: "POST",
        url: url,
        data: form,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        },
    };

    const response = await axios(config);
    return response;
};

export const getAllProducts = async (firmId) => {
    const url = `${API}/product/getAllProducts/${firmId}`;
    const config = {
        method: "GET",
        url: url,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        },
    }
    const response = await axios(config);
    return response;
};

export const getProductDetails = async (productId) => {
    const url = `${API}/product/getProductDetails/${productId}`;
    const config = {
        method: "GET",
        url: url,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        },
    };

    const response = await axios(config);
    return response;
};

export const updateProduct = async (form, productId) => {
    const url = `${API}/product/updateProduct/${productId}`;
    const config = {
        method: "PATCH",
        url: url,
        data: form,
        headers: {
            'Content-Type': "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        },
    };

    const response = await axios(config);
    return response;
};

export const softDeleteProduct = async (productId) => {
    const url = `${API}/product/softDeleteProduct/${productId}`;
    const config = {
        method: "PATCH",
        url: url,
        headers: {
            'Content-Type': "application/json",
        },
    }
    try {
        const response = await axios(config);
        return response;
    } catch (err) {
        ErrorToast("Unable to delete product")
    }
};

export const deleteProduct = async (productId) => {
    const url = `${API}/product/deleteProduct/${productId}`;
    const config = {
        method: "DELETE",
        url: url,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        }
    };

    const response = await axios(config);
    return response;
};