import React, { useEffect, useRef, useState } from "react";
import topHeader from "../../../../assets/images/dashboard/top-header.png";
import { Row, Col, Container, Form, Modal, Button } from "react-bootstrap";
import { BsDownload } from "react-icons/bs";
import "./payroll.css";
import { Link } from "react-router-dom";
import SubHeader from "../../../../components/partials/dashboard/HeaderStyle/sub-header";
import ReactToPrint from "react-to-print";

const Payslips = () => {
  const [formData, setFormData] = useState([]);
  const [show, setShow] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [selectedMonth, setSelectedMonth] = useState("January");

  const componentRef = useRef();

  const handleMonth = (e) => {
    setSelectedMonth({
      [e.target.name]: e.target.value
    })
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  console.log("form data", formData);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const handleResize = setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const months = [
    {
      id: 1,
      name: "January",
    },
    {
      id: 2,
      name: "February",
    },
    {
      id: 3,
      name: "March",
    },
    {
      id: 4,
      name: "April",
    },
    {
      id: 5,
      name: "May",
    },
    {
      id: 6,
      name: "June",
    },
    {
      id: 7,
      name: "July",
    },
    {
      id: 8,
      name: "August",
    },
    {
      id: 9,
      name: "September",
    },
    {
      id: 10,
      name: "October",
    },
    {
      id: 11,
      name: "Novermber",
    },
    {
      id: 12,
      name: "December",
    },
  ];

  return (
    <>
      <SubHeader title="Pay Slips" />
      <div className="h-100" style={{ minHeight: "90vh" }}>
        <div
          className="d-flex text-dark ms-5"
          style={{
            marginTop: screenWidth >= 1200 ? "-1%" : "-3%",
            flexDirection: "row",
          }}
        >
          <Row
            sm={7}
            className={`h-100 bg-white rounded mx-2 mb-5`}
            style={{
              minWidth: "50%",
              width: "75%",
              maxWidth: "80%",
            }}
          >
            <div
              className="table-responsive mt-2"
              style={{ minHeight: "50vh" }}
            >
              <form onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Label className="text-primary fs-5 fw-bold">
                    Select Month
                  </Form.Label>
                  <Form.Select
                    name="month"
                    className="border border-primary text-dark w-50"
                    style={{ cursor: "pointer" }}
                    onChange={handleChange}
                  >
                    <option>Select Month</option>
                    {months.map((data, index) => (
                      <option key={index}>{data.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </form>
              <ReactToPrint
                trigger={() => (
                  <div
                    className={`my-2 py-3 rounded border border-primary`}
                    style={{ maxWidth: "40%", cursor: "pointer" }}
                    // onClick={() => setShow(true)}
                    type="submit"
                  >
                    <span className=" ">
                      <BsDownload className="fs-4 text-primary mx-2" />
                      Download Pay Slips
                    </span>
                  </div>
                )}
                content={() => componentRef.current}
              />
            </div>
          </Row>
          <Row
            sm={2}
            className="ms-2 mb-"
            style={{
              width: "25%",
              height: "100%",
              maxHeight: "90vh",
            }}
          >
            <Col
              className="bg-white rounded p-0"
              sm={10}
              style={{ cursor: "pointer" }}
            >
              <Link
                to="/dashboard/app/payroll/payslips/form-16"
                className="text-dark"
              >
                <div className="rounded py-2 px-3">
                  <p className="text-primary fs-5 fw-bold">Form 16</p>
                  <p className="text fw-bold">View / download your form 16</p>
                </div>
              </Link>
            </Col>
            <Col
              className="bg-white rounded p-0 mt-2"
              sm={10}
              style={{ cursor: "pointer" }}
            >
              <Link
                to="/dashboard/app/payroll/payslips/salary-advance"
                className="text-dark"
              >
                <div className="rounded py-2 px-3">
                  <p className="text-primary fs-5 fw-bold">Salary Advance</p>
                  <p className="text fw-bold">
                    View / request a salary advance
                  </p>
                </div>
              </Link>
            </Col>
          </Row>
        </div>
        <form>
          <Modal
            className="text-dark"
            show={show}
            onHide={() => setShow(false)}
          >
            <Modal.Header closeButton>
              <h4 className="text-primary">Download Pay Slips</h4>
            </Modal.Header>
            <Modal.Body>
              <Form.Group>
                <Form.Label className="fw-bold">Date Range</Form.Label>
                <Form.Select className="border border-primary text-dark">
                  <option>2022-2023</option>
                  <option>2021-2022</option>
                  <option>Last 3 Moths</option>
                  <option>Last 6 Months</option>
                </Form.Select>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <ReactToPrint
                trigger={() => <Button type="submit">Download</Button>}
                content={() => componentRef.current}
              />
            </Modal.Footer>
          </Modal>
        </form>
        <div className="m-0 d-none">
          <Row
            className="h-100 ms-2 bg-white mb-5"
            style={{ width: "100vw", width: "95vw" }}
            ref={componentRef}
          >
            <Col
              sm={12}
              className=" bg-soft-primary text-center py-2 border-primary"
              style={{ borderBottom: "1px solid" }}
            >
              <h4>Fixl Solutions</h4>
            </Col>
            <Col sm={12}>
              <table className="w-100">
                <thead
                  className="border-primary"
                  style={{ borderBottom: "1px solid" }}
                >
                  <th></th>
                  <th className="fw-bold">Salary Slip</th>
                  <th></th>
                  <th
                    className="fw-bold w-5 ps-2 border-primary"
                    style={{ borderLeft: "1px solid", borderRight: "1px solid" }}
                  >
                    Month
                  </th>
                  <th className="w-5">Jan</th>
                </thead>
                <tbody style={{ fontSize: "0.8rem" }}>
                  <tr>
                    <td>Employee Name</td>
                    <td>Test Employee</td>
                    <td
                      className="border-primary ps-2"
                      style={{ borderLeft: "1px solid" }}
                    >
                      Date of Joining
                    </td>
                    <td className="m-0 p-0 ps-2">12/12/2022</td>
                    <td style={{ width: "10%", maxWidth: "10%" }}></td>
                  </tr>
                  <tr>
                    <td>Employee Code</td>
                    <td>12345</td>
                    <td
                      className="border-primary ps-2"
                      style={{ borderLeft: "1px solid" }}
                    >
                      total Working Days
                    </td>
                    <td className="m-0 p-0 ps-2">20</td>
                    <td style={{ width: "10%", maxWidth: "10%" }}></td>
                  </tr>
                  <tr>
                    <td>Designation</td>
                    <td>Developer</td>
                    <td
                      className="border-primary ps-2"
                      style={{ borderLeft: "1px solid" }}
                    >
                      No of working days attended
                    </td>
                    <td className="m-0 p-0 ps-2">12/12/2022</td>
                    <td style={{ width: "10%", maxWidth: "10%" }}></td>
                  </tr>
                  <tr>
                    <td>PAN</td>
                    <td>ABDCE1234A</td>
                    <td
                      className="border-primary ps-2"
                      style={{ borderLeft: "1px solid" }}
                    >
                      Leaves
                    </td>
                    <td
                      className="m-0 p-0 ps-2 border-primary fw-bold"
                      style={{ borderRight: "1px solid", borderTop: "1px solid", borderBottom: "1px solid" }}
                    >
                      P
                    </td>
                    <td
                      className="ps-2 border-primary fw-bold"
                      style={{
                        width: "10%",
                        maxWidth: "10%",
                        borderTop: "1px solid",
                        borderBottom: "1px solid",
                      }}
                    >
                      S
                    </td>
                  </tr>
                  <tr>
                    <td>Bank Accound Number</td>
                    <td>123456789876</td>
                    <td
                      className="border-primary ps-2"
                      style={{ borderLeft: "1px solid" }}
                    >
                      Leaves Taken
                    </td>
                    <td
                      className="m-0 p-0 ps-2 border-primary"
                      style={{ borderRight: "1px solid" }}
                    >
                      2
                    </td>
                    <td className="ps-2" style={{ width: "10%", maxWidth: "10%" }}>2</td>
                  </tr>
                  <tr
                    className="border-primary"
                    style={{ borderBottom: "1px solid" }}
                  >
                    <td>Bank Name</td>
                    <td>SBI</td>
                    <td
                      className="border-primary ps-2"
                      style={{ borderLeft: "1px solid" }}
                    >
                      Balance Leaves
                    </td>
                    <td
                      className="m-0 p-0 ps-2 border-primary"
                      style={{ borderRight: "1px solid" }}
                    >
                      2
                    </td>
                    <td style={{ width: "10%", maxWidth: "10%" }}></td>
                  </tr>
                </tbody>
              </table>
              <table className="w-100 mt-3 fs-5">
                <thead
                  className="border-primary text-dark bg-soft-primary"
                  style={{ borderTop: "1px solid", borderBottom: "1px solid" }}
                >
                  <th></th>
                  <th className="fw-bold" style={{ width: "20%", maxWidth: "20%", borderRight: "1px solid" }}>
                    Income
                  </th>
                  <th></th>
                  <th
                    className="fw-bold w-5 ps-2 border-primary"
                  // style={{ borderLeft: "1px solid" }}
                  >
                    Deductions
                  </th>
                  <th className="w-5"></th>
                </thead>
                <tbody style={{ fontSize: "0.8rem" }}>
                  <tr className="fw-bold border-primary">
                    <td>Particulars</td>
                    <td className="ps-5">Amount</td>
                    <td
                      className="border-primary ps-2"
                      style={{ borderLeft: "1px solid" }}
                    >
                      Particulars
                    </td>
                    <td className="m-0 p-0 ps-2">Amount</td>
                    <td style={{ width: "10%", maxWidth: "10%" }}></td>
                  </tr>
                  <tr>
                    <td>Basic Salary</td>
                    <td className="ps-5">10000</td>
                    <td
                      className="border-primary ps-2"
                      style={{ borderLeft: "1px solid" }}
                    >
                      PF
                    </td>
                    <td className="m-0 p-0 ps-2">100</td>
                    <td style={{ width: "10%", maxWidth: "10%" }}></td>
                  </tr>
                  <tr>
                    <td>Deamess Allowance</td>
                    <td className="ps-5">100</td>
                    <td
                      className="border-primary ps-2"
                      style={{ borderLeft: "1px solid" }}
                    >
                      Professional Tax
                    </td>
                    <td className="m-0 p-0 ps-2">500</td>
                    <td style={{ width: "10%", maxWidth: "10%" }}></td>
                  </tr>
                  <tr>
                    <td>Assistanct Allowance</td>
                    <td className="ps-5">120</td>
                    <td
                      className="border-primary ps-2"
                      style={{ borderLeft: "1px solid" }}
                    >
                      No of working days attended
                    </td>
                    <td className="m-0 p-0 ps-2"></td>
                    <td style={{ width: "10%", maxWidth: "10%" }}></td>
                  </tr>
                  <tr
                    className="border-primary"
                    style={{ borderBottom: "1px solid" }}
                  >
                    <td>Total</td>
                    <td className="ps-5">10100</td>
                    <td
                      className="border-primary ps-2"
                      style={{ borderLeft: "1px solid" }}
                    >
                      Total
                    </td>
                    <td className="m-0 p-0 ps-2">500</td>
                    <td className="ps-3" style={{ width: "10%", maxWidth: "10%" }}>S</td>
                  </tr>
                </tbody>
              </table>
              <Row sm={12} className="m-0 w-100" style={{ marginLeft: "0%" }}>
                <Col
                  sm={3}
                  className="w-75 bg-soft-primary text-dark fw-bold border-primary text-center"
                  style={{ borderRight: "1px solid", borderBottom: "1px solid" }}
                >
                  {" "}
                  Net Salary
                </Col>
                <Col
                  sm={3}
                  className="w-25 border-primary ps-4"
                  style={{ borderBottom: "1px solid" }}
                >
                  12000
                </Col>
              </Row>
              <Row
                sm={12}
                className=" mt-3 w-100"
                style={{ marginLeft: "0%", height: "50px" }}
              >
                <Col
                  sm={3}
                  className="w-75 border-primary"
                  style={{ borderBottom: "1px solid" }}
                >
                  {" "}
                </Col>
                <Col
                  sm={3}
                  className="w-25 border-primary"
                  style={{ borderBottom: "1px solid" }}
                ></Col>
              </Row>
              <Row sm={12} className="m-0 w-100" style={{ marginLeft: "0%" }}>
                <Col
                  sm={3}
                  className="border-primary py-1 text-center"
                  style={{
                    width: "43%",
                    borderRight: "1px solid",
                    borderBottom: "1px solid",
                  }}
                >
                  {" "}
                  Employee Signature
                </Col>
                <Col
                  sm={3}
                  className="border-primary py-1 text-center"
                  style={{ width: "57%", borderBottom: "1px solid" }}
                >
                  Employer Signature
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Payslips;
