import React from 'react'

const PipelineList = () => {
  return (
    <>
        
    </>
  )
}

export default PipelineList