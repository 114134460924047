import { Button, Card, Popover, Table } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
// import PageTitle from "../page-header/PageHeader";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";
// import DepartmentEditPopup from "../UI/PopUp/DepartmentEditPopup";
import ShifDelete from "./EmploymentStatusDelete";
// import ShiftEditPopup from "../UI/PopUp/ShiftEditPopup";
// import { loadSingelEmploymentStatus } from "../../redux/rtk/features/employemntStatus/employmentStatusSlice";
// import EmploymentStatusEditPopup from "../UI/PopUp/EmploymentStatusEditPopup";
// import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import EmploymentStatusDelete from "./EmploymentStatusDelete";
import AntLoader from "../../../components/loader/AntLoader";
import { CsvLinkBtn } from "../../../components/UI/Button/CsvLinkBtn";
import ViewBtn from "../../../components/Buttons/ViewBtn";
import ColVisibilityDropdown from "../../../components/Shared/ColVisibilityDropdown";
import EmploymentStatusEditPopup from "../../../components/UI/PopUp/EmploymentStatusEditPopup";
import { getAllEmploymentStatusById } from "../../Hook/employmentHooks";

//PopUp

const CustomTable = ({ list }) => {
	const [columnsToShow, setColumnsToShow] = useState([]);

	const columns = [
		{
			id: 1,
			title: "ID",
			dataIndex: "id",
			key: "id",
		},

		{
			id: 2,
			title: " Name",
			key: "firstName",
			render: ({ firstName, lastName }) => firstName + " " + lastName,
		},

		{
			id: 6,
			title: "User Name",
			dataIndex: "userName",
			key: "userName",
		},
		{
			id: 7,
			title: "Start Time",
			dataIndex: "startTime",
			key: "startTime",
			render: (startTime) => dayjs(startTime).format("hh:mm A"),
		},
		{
			id: 8,
			title: "End Time",
			dataIndex: "endTime",
			key: "endTime",
			render: (endTime) => dayjs(endTime).format("hh:mm A"),
		},
		{
			id: 4,
			title: "Action",
			dataIndex: "id",
			key: "action",
			render: (id) => (
				// <UserPrivateComponent permission={"readSingle-user"}>
				<ViewBtn path={`/admin/hr/staffs/${id}/`} />
				// </UserPrivateComponent>
			),
		},
	];

	useEffect(() => {
		setColumnsToShow(columns);
	}, []);

	const columnsToShowHandler = (val) => {
		setColumnsToShow(val);
	};

	const addKeys = (arr) => arr.map((i) => ({ ...i, key: i.id }));

	return (
		<div>
			<div className='text-center my-2 d-flex justify-content-between align-items-center'>
				<h5 className='department-list-title text-color-2 text-xl mb-2'>
					Employee List
				</h5>

				{list && (
					// <div>
					<CsvLinkBtn>
						<CSVLink className='btn btn-dark btn-sm fs-5 fw-bold f' data={list} filename='user_department'>
							Download CSV
						</CSVLink>
					</CsvLinkBtn>
					// </div>
				)}
			</div>
			{list && (
				<div style={{ marginBottom: "30px" }}>
					<ColVisibilityDropdown
						options={columns}
						columns={columns}
						columnsToShowHandler={columnsToShowHandler}
					/>
				</div>
			)}
			<Table
				loading={!list}
				columns={columnsToShow}
				dataSource={list}
				pagination={{ pageSize: 5 }}
				scroll={{ x: 720 }}
			/>
		</div>
	);
};

const DetailEmploymentStatus = () => {
	const { id } = useParams();

	//dispatch
	const dispatch = useDispatch();
	// const employmentStatus = useSelector(
	// 	(state) => state.employmentStatus.employmentStatus
	// );
	const [employmentStatus, setEmploymentStatus] = useState({});

	useEffect(() => {
		getAllEmploymentStatusById(id).then((response) => {
			if (response.status === 200) {
				setEmploymentStatus(response.data)
			} else {
				setEmploymentStatus({});
			}
		}).catch(() => {
			setEmploymentStatus({});
		})
	}, []);

	// const employmentStatus = ""
	//Delete Supplier

	// useEffect(() => {
	// 	dispatch(loadSingelEmploymentStatus(id));
	// }, []);

	const isLogged = Boolean(localStorage.getItem("isLogged"));

	// if (!isLogged) {
	// 	return <Navigate to={"/admin/auth/login"} replace={true} />;
	// }

	return (
		<div className="mx-4">
			{/* <PageTitle title=' Back  ' /> */}
			{/* <UserPrivateComponent permission={"readSingle-employmentStatus"}> */}
			<Card className='mr-top mt-5'>
				{employmentStatus ? (
					<Fragment key={employmentStatus.id}>
						<div>
							<div className='d-flex justify-content-between align-items-center '>
								<h3 className={"text-xl"}>
									ID : {employmentStatus.id} | {employmentStatus.name}
								</h3>
								<div className='d-flex justify-content-end align-items-center'>
									{/* <UserPrivateComponent 1permission={"update-employmentStatus"}> */}
									<EmploymentStatusEditPopup data={employmentStatus} />
									{/* </UserPrivateComponent> */}

									{/* <UserPrivateComponent permission={"delete-employmentStatus"}> */}
									{/* <EmploymentStatusDelete id={id} /> */}
									{/* </UserPrivateComponent> */}
								</div>
							</div>
							<CustomTable list={employmentStatus.user} />
						</div>
					</Fragment>
				) : (
					<AntLoader />
					// <Loader />
				)}
			</Card>
			{/* </UserPrivateComponent> */}
		</div>
	);
};

export default DetailEmploymentStatus;
