import React, { useEffect, useState } from "react";
import topHeader from "../../../../../assets/images/dashboard/top-header.png";
import { Row, Col, Container, Button, Form } from "react-bootstrap";
import Card from "../../../../../components/Card";
import { Link } from "react-router-dom";
import { API } from "../../../../../backend";
import { ToastContainer, toast } from "react-toastify";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { GrAd, GrAdd } from "react-icons/gr";
import { AiOutlineMinus } from "react-icons/ai";
import SubHeader from "../../../../../components/partials/dashboard/HeaderStyle/sub-header";
import { createUser } from "../../Hook/userHooks";
import { getAllFirms } from "../../Hook/firmHooks";
import { getOrgDept } from "../../Hook/orgHooks";
import { ErrorToast, SuccessToast } from "../../../components/toast";
import { Checkbox } from "antd";

const AddUser = () => {
  const [form, setForm] = useState({});
  const [profilePhoto, setProfilePhoto] = useState("");
  const [showBasic, setShowBasic] = useState(true);
  const [showContact, setShowContact] = useState(false);
  const [showPipeline, setShowPipeline] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [allFirms, setAllFirms] = useState([]);
  const [orgDept, setOrgDept] = useState([]);
  const formData = new FormData();

  useEffect(() => {
    getAllFirms(setAllFirms);
  }, []);

  useEffect(() => {
    getOrgDept().then((response) => {
      if (response.status === 200) {
        setOrgDept(response.data.data);
      }
    })
  }, []);

  const submitForm = async (e) => {
    e.preventDefault();
    await fetch(`${API}`, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({ form }),
    })
      .then((res) => res.json)
      .then((response) => {
        if (response.status === 200) {
          console.log("user added successfully");
          SuccessToast("User Added successfully");
        } else {
          console.log("Something went wrong");
          toast.warn("Something went wrong", {
            theme: "colored",
            hideProgressBar: true,
          });
        }
        setForm({
          ...form,
          [e.target.name]: e.target.reset(),
        });
      })
      .catch((err) => {
        console.log(err.message);
        ErrorToast("Something went wrong");
      });
  };

  const addUser = async (e) => {
    e.preventDefault();
    formData.append("firstName", form.firstName);
    formData.append("lastName", form.lastName);
    formData.append("profilePhoto", profilePhoto);
    formData.append("password", form.password);
    formData.append("confirmPassword", form.confirmPassword);
    formData.append("email", form.email);
    formData.append("phone", form.phone);
    formData.append("department", form.department);
    formData.append("role", form.role);

  };

  useEffect(() => {
    const handleResize = setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleProfilePhoto = (e) => {
    setProfilePhoto(e.target.files[0]);
  };
  console.log("profile image", profilePhoto);

  const handleSubmit = (e) => {
    e.preventDefault();
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    console.log("Form submitted");
    SuccessToast("User added successfully", {
      theme: "colored",
      hideProgressBar: true,
      autoClose: 500,
    });
  };

  return (
    <>
      <SubHeader title="Add User" />
      <Row
        className="text-dark"
        style={{
          width: "70%",
          marginLeft: "15%",
          height: "100%",
          minHeight: "70vh",
          marginTop: screenWidth >= 1200 ? "-1%" : "-3%",
        }}
      >
        <Col>
          <form onSubmit={addUser}>
            <Col>
              <div
                className="bg-white mb-2 px-3 rounded text-dark"
                style={{ minHeight: "3rem" }}
              >
                <Card.Header className="bg-white" style={{ width: "100%" }}>
                  <div className="w-100 d-flex flex-row">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowBasic(!showBasic)}
                      className="col col-sm-10 w-100"
                    >
                      <h4 className=" text-primary fw-bold">Basic Details</h4>
                    </div>
                    <div className="col">
                      {showBasic === false ? (
                        <BsChevronDown
                          className="fs-4 fw-bold"
                          onClick={() => setShowBasic(true)}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <BsChevronUp
                          className="fs-4 fw-bold"
                          onClick={() => setShowBasic(false)}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </div>
                  </div>
                </Card.Header>
                <div>
                  <Row
                    className="pb-3 px-4"
                    style={{
                      display: showBasic === false && "none",
                    }}
                  >
                    <Col className="mt-3" sm={6}>
                      <Form.Label htmlFor="title">Fist Name</Form.Label>
                      <Form.Control
                        type="text"
                        className="border border-primary"
                        placeholder="First Name"
                        name="firstName"
                        onChange={handleChange}
                        required
                      />
                    </Col>
                    <Col className="mt-3" sm={6}>
                      <Form.Label htmlFor="closureDate">Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        className="border border-primary"
                        placeholder="Last Name"
                        name="lastName"
                        onChange={handleChange}
                        required
                      />
                    </Col>
                    <Col className="mt-3" sm={6}>
                      <Form.Label>Profile Image</Form.Label>
                      <Form.Control
                        type="file"
                        className="border border-primary"
                        name="profilePhoto"
                        onChange={handleProfilePhoto}
                        required
                      />
                    </Col>
                    <Col className="mt-4" sm={6}>
                      <img
                        src={
                          profilePhoto
                            ? profilePhoto
                            : "https://www.meme-arsenal.com/memes/b6a18f0ffd345b22cd219ef0e73ea5fe.jpg"
                        }
                        alt=""
                        className="rounded-circle"
                        style={{ width: "50px", height: "50px" }}
                      />
                    </Col>
                    <Col className="mt-3" sm={6}>
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        className="border border-primary"
                        name="password"
                        placeholder="Enter Password"
                        onChange={handleChange}
                        required
                      />
                    </Col>
                    <Col className="mt-3" sm={6}>
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type="password"
                        className="border border-primary"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        onChange={handleChange}
                        required
                      />
                    </Col>
                  </Row>
                </div>
              </div>

              <div
                className="bg-white mb-2 px-3 rounded text-dark"
                style={{ minHeight: "3rem" }}
              >
                <Card.Header className="bg-white" style={{ width: "100%" }}>
                  <div className="w-100 d-flex flex-row">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowContact(!showContact)}
                      className="col col-sm-10 w-100"
                    >
                      <h4 className=" text-primary fw-bold">Contact</h4>
                    </div>
                    <div className="col">
                      {showContact === false ? (
                        <BsChevronDown
                          className="fs-4 fw-bold"
                          onClick={() => setShowContact(true)}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <BsChevronUp
                          className="fs-4 fw-bold"
                          onClick={() => setShowContact(false)}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </div>
                  </div>
                </Card.Header>
                <div>
                  <Row
                    className="pb-3 px-4"
                    style={{
                      display: showContact === false && "none",
                    }}
                  >
                    <Col className="mt-3" sm={6}>
                      <Form.Label htmlFor="title">Email</Form.Label>
                      <Form.Control
                        type="email"
                        className="border border-primary"
                        placeholder="Email"
                        name="email"
                        onChange={handleChange}
                        required
                      />
                    </Col>
                    <Col className="mt-3" sm={6}>
                      <Form.Label htmlFor="closureDate">Phone No.</Form.Label>
                      <Form.Control
                        type="text"
                        className="border border-primary"
                        placeholder="Phone No."
                        name="phone"
                        pattern="\d{10}"
                        onChange={handleChange}
                        required
                      />
                    </Col>
                    <Col className="mt-3" sm={6}>
                      <Form.Label htmlFor="closureDate">Address 1</Form.Label>
                      <Form.Control
                        type="text"
                        className="border border-primary"
                        placeholder="Address 1"
                        name="address1"
                        onChange={handleChange}
                        required
                      />
                    </Col>
                    <Col className="mt-3" sm={6}>
                      <Form.Label htmlFor="closureDate">Address 2</Form.Label>
                      <Form.Control
                        type="text"
                        className="border border-primary"
                        placeholder="Address 2"
                        name="address2"
                        onChange={handleChange}
                        required
                      />
                    </Col>
                    <Col className="mt-3" sm={6}>
                      <Form.Label htmlFor="closureDate">Address 3</Form.Label>
                      <Form.Control
                        type="text"
                        className="border border-primary"
                        placeholder="Address 2"
                        name="address2"
                        onChange={handleChange}
                        required
                      />
                    </Col>
                    <Col className="mt-3" sm={6}>
                      <Form.Label htmlFor="closureDate">City</Form.Label>
                      <Form.Control
                        type="text"
                        className="border border-primary"
                        placeholder="City"
                        name="city"
                        onChange={handleChange}
                        required
                      />
                    </Col>
                    <Col className="mt-3" sm={6}>
                      <Form.Label htmlFor="closureDate">Pincode</Form.Label>
                      <Form.Control
                        type="text"
                        className="border border-primary"
                        placeholder="Pincode"
                        name="pincode"
                        onChange={handleChange}
                      // required
                      />
                    </Col>
                  </Row>
                </div>
              </div>

              <div
                className="bg-white mb-2 px-3 rounded text-dark"
                style={{ minHeight: "3rem" }}
              >
                <Card.Header className="bg-white" style={{ width: "100%" }}>
                  <div className="w-100 d-flex flex-row">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowPipeline(!showPipeline)}
                      className="col col-sm-10 w-100"
                    >
                      <h4 className=" text-primary fw-bold">Role & Permissions</h4>
                    </div>
                    <div className="col">
                      {showPipeline === false ? (
                        <BsChevronDown
                          className="fs-4 fw-bold"
                          onClick={() => setShowPipeline(true)}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <BsChevronUp
                          className="fs-4 fw-bold"
                          onClick={() => setShowPipeline(false)}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </div>
                  </div>
                </Card.Header>
                <div>
                  <Row
                    className="pb-3 px-4"
                    style={{
                      display: showPipeline === false && "none",
                      transition: "all 0.3s ease-in-out",
                    }}
                  >
                    <Row className="m-0 p-0">
                      <Col className="mx-0 mt-3" sm={6}>
                        <Form.Label htmlFor="title">Firm</Form.Label>
                        <Form.Select name="firmId" onChange={handleChange} className="w-100 border border-primary">
                          <option>Select Firm</option>
                          {
                            allFirms.map((data, index) => (
                              <option value={data._id} key={index}>{data.name}</option>
                            ))
                          }
                        </Form.Select>
                      </Col>
                    </Row>
                    <Col className="mt-3" sm={6}>
                      <Form.Label htmlFor="title">Pipeline</Form.Label>
                      <Form.Select className="border border-primary w-100">
                        <option>Select Pipeline</option>
                        {
                          orgDept.length > 0 ? orgDept.map((data, index) =>
                            data.length > 0 ? (
                              <option>{data}</option>
                            ) : null
                          ) : (
                            <option>No Pipeline Found</option>
                          )
                        }
                      </Form.Select>
                    </Col>
                    <Col className="mt-3" sm={6}>
                      <Form.Label htmlFor="closureDate">Role</Form.Label>
                      <Form.Control
                        type="text"
                        className="border border-primary"
                        placeholder="Role"
                        name="role"
                        onChange={handleChange}
                        required
                      />
                    </Col>


                    <Col className="mt-3" sm={12}>
                      <Form.Label htmlFor="closureDate">Permissions</Form.Label>
                      <div className="w-75 py-2 d-flex justify-content-between align-items-center">
                        <div className="">
                          {/* <Checkbox>Checkbox</Checkbox> */}
                          <label>Create</label>
                          <Form.Check.Input type="checkbox" className="bg-danger" id="create" name="create" style={{ display: "block !important" }} />
                        </div>
                        <div className="">
                          <label>Read</label>
                          <Form.Check.Input type="checkbox" className="bg-danger" id="read" name="read" />
                        </div>
                        <div className="">
                          <label>Update</label>
                          <Form.Check.Input type="checkbox" className="bg-danger" id="update" name="update" />
                        </div>
                        <div className="">
                          <label>Delete</label>
                          <Form.Check.Input type="checkbox" className="bg-danger" id="delete" name="delete" />
                        </div>
                      </div>
                      {/* <Form.Control
                        type="text"
                        className="border border-primary"
                        placeholder="Role"
                        name="role"
                        onChange={handleChange}
                        required
                      /> */}
                    </Col>
                  </Row>
                </div>
              </div>

              <div
                className="bg-white mb-2 px-3 rounded text-dark"
                style={{ minHeight: "3rem" }}
              >
                <div>
                  <Col className="py-2 px-4">
                    <Button type="submit">Add User</Button>
                  </Col>
                </div>
              </div>
            </Col>
          </form>
        </Col>
        <ToastContainer />
      </Row>
    </>
  );
};

export default AddUser;
