// import { PageHeader } from "antd";
import React from "react";
import "./page-header.css";

function PageTitle({ title, subtitle }) {
  return (
    <>
      {/* <PageHeader
        className="site-page-header d-none d-lg-block"
        onBack={() => window.history.back()}
        title={title}
        subTitle={subtitle}
      /> */}
    </>
  );
}

export default PageTitle;
