import axios from "axios";
import { API } from "../../../../backend";
import { ErrorToast, SuccessToast } from "../../components/toast";
const orgId = localStorage.getItem("orgID");
const userToken = localStorage.getItem('user_token');

// Lead APIs

export const getLeadListByOrg = async (status) => {
    const url = `${API}/lead/getListByOrg`;
    const config = {
        method: "POST",
        url: url,
        data: {
            orgId: orgId,
            deleted: status === 'Deleted' ? true : false
        },
        headers: {
            'Content-Type': "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        },
    };
    const response = await axios(config);
    return response;
};

export const getLeadListByUser = async (id) => {
    const url = `${API}/lead/leadsByUser/${id}`;
    const config = {
        method: "GET",
        url: url,
        headers: {
            'Content-Type': "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        },
    };
    const response = await axios(config);
    return response;
};

export const getLeadListByManager = async (id) => {
    const url = `${API}/lead/leadsByManager/${id}`;
    const config = {
        method: "GET",
        url: url,
        headers: {
            'Content-Type': "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        },
    };
    const response = await axios(config);
    return response;
};

export const getOrgLeadByStatus = async (status) => {
    const url = `${API}/lead/getByStatusByOrg`;
    const config = {
        method: "POST",
        url: url,
        data: {
            orgId: orgId,
            delete: false,
            status: status
        },
        headers: {
            'Content-Type': "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        }
    };
    const response = await axios(config);
    return response;
};

export const getUserLeadsByStatus = async (userId, status) => {
    const url = `${API}/lead/getUserLeadsByStatus`;
    const config = {
        method: "POST",
        url: url,
        data: {
            userId: userId,
            delete: false,
            status: status
        },
        headers: {
            'Content-Type': "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        }
    };
    const response = await axios(config);
    return response;
};

export const getManagerLeadsByStatus = async (userId, status) => {
    const url = `${API}/lead/getManagerLeadsByStatus`;
    const config = {
        method: "POST",
        url: url,
        data: {
            id: userId,
            delete: false,
            status: status
        },
        headers: {
            'Content-Type': "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        }
    };
    const response = await axios(config);
    return response;
};

export const createLead = async (formData) => {
    const url = `${API}/lead/add-lead`;
    const config = {
        method: "POST",
        url: url,
        data: formData,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        },
    };
    const response = await axios(config);
    return response;
}

export const bulkAddLead = async (excelData) => {
    const url = `${API}/lead/add-leadbyExcel`;
    const config = {
        url: url,
        method: "POST",
        data: excelData,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        }
    };

    const response = await axios(config);
    return response;
};

export const getLeadById = async (id) => {
    const url = `${API}/lead/leadById/${id}`;
    const config = {
        method: "GET",
        url: url,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        },
    };
    const response = await axios(config);
    return response;
}

export const updateLead = async (id, form) => {
    const url = `${API}/lead/update-lead/${id}`;
    const config = {
        method: 'PATCH',
        url: url,
        data: form,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        }
    };

    const response = await axios(config);
    return response;
};

export const updateLeadStage = async (id, form) => {
    const url = `${API}/lead/update-stage/${id}`;
    const config = {
        method: 'PATCH',
        url: url,
        data: form,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        }
    };

    const response = await axios(config);
    return response;
};

export const deleteLead = async (data, getLeadByStatus, setShowDelete) => {
    const url = `${API}/lead/delete-lead/${data}`;
    const config = {
        method: "PATCH",
        url: url,
        data: { delete: true },
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify * userToken)}`
        }
    }
    try {
        const response = await axios(config);
        if (response.status === 200) {
            SuccessToast("Lead Deleted");
            setShowDelete(false);
            getLeadByStatus();
        }
        else {
            ErrorToast("Unable to delete lead");
        }
    }
    catch (err) {
        ErrorToast(err.message);
    }
};

export const searchLead = async (search) => {
    const url = `${API}/lead/leadSearch`;
    const config = {
        method: "POST",
        url: url,
        data: {
            search: search,
            orgId: orgId
        },
        headers: {
            'Content-Type': "application/json"
        }
    }
    try {
        const response = await axios(config);
        return response;
    } catch (err) {
        ErrorToast("Somethinf went wrong");
    }
};

export const bulkDeleteLeads = async (selectedValues) => {
    const url = `${API}/lead/bulkDelete`;
    const config = {
        method: "POST",
        url: url,
        data: { leadIds: selectedValues },
        headers: {
            'Content-Type': "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        }
    };

    const response = await axios(config);
    return response;
};








// Lead activity APIs

// GET

export const getAllActivities = async (id) => {
    const url = `${API}/leadActivity/getAllActivities/${id}`;
    const config = {
        method: 'GET',
        url: url,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        },
    };

    const response = await axios(config);
    return response;
};

export const getActivitiesByType = async (type, id) => {
    const url = `${API}/leadActivity/getActivitiesByType/${type}/${id}`;
    const config = {
        method: 'GET',
        url: url,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        },
    }
    const response = await axios(config);
    return response;
};

export const getActivityById = async (id) => {
    const url = `${API}/leadActivity/getLeadActivityById/${id}`;
    const config = {
        method: 'GET',
        url: url,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        },
    }
    const response = await axios(config);
    return response;
};

export const getLeadActivityComments = async (id) => {
    const url = `${API}/leadActivity/getLeadActivityComment/${id}`;
    const config = {
        method: 'GET',
        url: url,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        },
    };

    const response = await axios(config);
    return response;
};

export const getAllReminders = async () => {
    const url = `${API}/Reminder/getAllReminders`;
    const config = {
        method: "GET",
        url: url,
        headers: {
            "Content-Type": "application/json"
        }
    };


    const response = await axios(config);
    return response;
};


// POST

export const addLeadActivity = async (formData) => {
    const url = `${API}/leadActivity/addLeadActivity`;
    const config = {
        method: "POST",
        url: url,
        data: formData,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        }
    };

    const response = await axios(config);
    return response;
};

export const addLeadActivityComment = async (data) => {
    const url = `${API}/leadActivity/addLeadActivityComment`;
    const config = {
        method: "POST",
        url: url,
        data: data,
        headers: {
            'Content-Type': "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        },
    };

    const response = await axios(config);
    return response;
};


// PATCH

export const updateActivity = async (id, form) => {
    const url = `${API}/leadActivity/updateLeadActivity/${id}`;
    const config = {
        method: "PATCH",
        url: url,
        data: form,
        headers: {
            'Content-Type': "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        },
    };

    const response = await axios(config);
    return response;
};

export const updateAttachment = async (id, formData) => {
    const url = `${API}/leadActivity/updateAttachment/${id}`;
    const config = {
        method: "PATCH",
        url: url,
        data: formData,
        headers: {
            'Content-Type': "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        },
    };

    const response = await axios(config);
    return response;
};


// DELETE

export const deleteLeadActivity = async (id) => {
    const url = `${API}/leadActivity/deleteLeadActivity/${id}`;
    const config = {
        method: 'DELETE',
        url: url,
        headers: {
            'Content-Type': "application/json",
            Authorization: `Bearer ${typeof window !== 'undefined' && JSON.parse(JSON.stringify(userToken))}`
        },
    };

    const response = await axios(config);
    return response;
};
