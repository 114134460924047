import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Link } from 'react-router-dom'
import { Row, Col, Image, Tab } from 'react-bootstrap'

import { userLogo, icon1, icon2, icon3, icon4, icon5, icon6, icon7, icon8 } from '../../../../../assets/images';

import SubHeader from '../../../../../components/partials/dashboard/HeaderStyle/sub-header';
import Card from '../../../../../components/Card';
import { Leaderboard } from '@mui/icons-material';
import { getUserById } from '../../Hook/userHooks';
import { getLeadListByUser } from '../../Hook/leadHooks';
import { getUserRecentActivities } from '../../Hook/recentActivityHooks';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import { Tooltip } from '@material-ui/core';

const UserProfile = () => {
    const [userPermissions, setUserPermissions] = useState([]);
    const [updatePermission, setUpdatePermission] = useState(false);
    const [userDetails, setUserDetails] = useState({});
    const [allActivities, setAllActivities] = useState([]);
    const [pipelines, setPipelines] = useState([]);
    const [assignedLeads, setAssignedLeads] = useState([]);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [activeLead, setActiveLead] = useState('');
    const breakPoint = 1000;
    const { id } = useParams();
    const history = useHistory();
    const orgName = localStorage.getItem('orgName');
    const loggedInUserId = localStorage.getItem('userId');

    useEffect(() => {
        getUserById(loggedInUserId).then((response) => {
            if (response.status === 200) setUserPermissions(response?.data?.data?.permissions);
            else setUserPermissions([]);
        }).catch(() => setUserPermissions([]));
    }, [loggedInUserId]);


    useEffect(() => {
        const update = userPermissions.length > 0 ? userPermissions.some((permission) => permission.module === 'user' && permission.action.includes('Update')) : false;
        setUpdatePermission(update);
    }, [userPermissions]);

    useEffect(() => {
        getUserById(id).then((response) => {
            if (response.status === 200) {
                setUserDetails(response?.data?.data);
                setPipelines(response?.data?.data?.department);
            } else {
                setUserDetails({});
                setPipelines([]);
            }
        }).catch(() => {
            setUserDetails({});
            setPipelines([]);
        });
    }, [id]);

    useEffect(() => {
        getLeadListByUser(id).then((response) => {
            (response.status === 200) ? setAssignedLeads(response.data.data) : setAssignedLeads([]);
        }).catch(() => setAssignedLeads([]));
    }, [id]);

    useEffect(() => {
        getUserRecentActivities(id).then((response) => {
            (response.status === 200) ? setAllActivities(response?.data?.data) : setAllActivities([]);
        }).catch(() => setAllActivities([]));
    }, [id]);

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });

    const styleHandle = (status) => {
        if (status === "Hold") return { backgroundColor: "#FFB802", textAlign: "center" }
        else if (status === "Won") return { backgroundColor: "#10813B", textAlign: "center" }
        else if (status === "Lost") return { backgroundColor: "#A70200", textAlign: "center" }
        else if (status === "In Process") return { backgroundColor: "blue", textAlign: "center" };
    };

    const classes = ['border-primary text-primary', 'border-danger text-danger', 'border-success text-success', 'border-warning text-warning', 'border-info text-info'];

    const allSuggetions = [
        { name: "Paul Molive", mutuals: "4", icon: icon5 },
        { name: "Robert Fox", mutuals: "4", icon: icon3 },
        { name: "Jenny Wilson", mutuals: "6", icon: icon6 },
        { name: "Cody Fisher", mutuals: "8", icon: icon7 },
        { name: "Bessie Cooper", mutuals: "1", icon: icon4 },
        { name: "Wade Warrent", mutuals: "3", icon: icon2 },
        { name: "Guy Hawkins", mutuals: "12", icon: icon1 },
        { name: "Floyd Miles", mutuals: "2", icon: icon8 }
    ];

    return (
        <div className=''>
            <SubHeader />
            <div className='mx-5' style={{ marginTop: "-2%" }}>
                <Tab.Container defaultActiveKey="fourth">
                    <Row>
                        <Col lg="12">
                            <Card>
                                <Card.Body>
                                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                                        <div className="d-flex flex-wrap align-items-center">
                                            <div className="profile-img position-relative me-3 mb-3 mb-lg-0 profile-logo profile-logo1">
                                                <Image className="img-fluid rounded-pill avatar-100" src={userLogo} alt="profile-pic" />
                                            </div>
                                            <div className="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                                                <h4 className="me-2 h4">{userDetails?.firstName} {userDetails?.lastName}</h4>
                                                <span> - {userDetails?.subRole !== undefined ? userDetails?.subRole : userDetails?.role}</span>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="3">
                            <Tab.Content className="profile-content">
                                <Tab.Pane eventKey="fourth" id="profile-profile">
                                    <Card>
                                        <Card.Header className="bg-white">
                                            <div className="header-title w-100 d-flex justify-content-between align-items-center">
                                                <h4 className="card-title">Profile</h4>
                                                {
                                                    updatePermission ? (
                                                        <Tooltip title="Update" key="update ">
                                                            <Link to={`/${orgName}/dashboard/user/update-profile/${id}`}>
                                                                <HiOutlinePencilAlt className='text-success cursor-pointer fs-4' />
                                                            </Link>
                                                        </Tooltip>
                                                    ) : null
                                                }
                                            </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <div className="text-center">
                                                <div className="user-profile">
                                                    <Image className="rounded-pill avatar-130 img-fluid" src={userLogo} alt="profile-pic" />
                                                </div>
                                                <div className="mt-3">
                                                    <h3 className="d-inline-block">{userDetails?.firstName} {userDetails?.lastName}</h3>
                                                    <p className="d-inline-block pl-3"> - {userDetails?.subRole !== undefined ? userDetails?.subRole : userDetails?.role}</p>
                                                    <p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Header className="bg-white">
                                            <div className="header-title">
                                                <h4 className="card-title">About User</h4>
                                            </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <div className="mt-2">
                                                <h6 className="mb-1 fw-bold">Email:</h6>
                                                <p><Link to="#" className="text-body">{userDetails?.email}</Link></p>
                                            </div>
                                            <div className="mt-2">
                                                <h6 className="mb-1 fw-bold">Phone:</h6>
                                                <p><Link to="#" className="text-body" target="_blank">{userDetails?.phone}</Link></p>
                                            </div>

                                            <div className="mt-2">
                                                <h6 className="mb-1 fw-bold">Pipelines:</h6>
                                                {
                                                    pipelines.map((data, index) => (
                                                        <span className='m-0 p-0 me-2' key={index}>{data} {`${index !== (pipelines.length - 1) ? ',' : ''}`}</span>
                                                    ))
                                                }
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Tab.Pane >
                            </Tab.Content>
                        </Col>
                        <Col lg="6" className='mb-3' style={{ maxHeight: "60vh" }}>
                            <Card className="h-100">
                                <Card.Header className="bg-white d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Activity</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <div style={{ maxHeight: "50vh", overflowY: "scroll" }}>
                                        {
                                            allActivities.length > 0 ? (
                                                <div className="iq-timeline0 m-0 d-flex align-items-center justify-content-between position-relative">
                                                    <ul className="list-inline p-0 m-0">
                                                        {
                                                            allActivities.map((data, index) => {
                                                                const randomClass = allActivities.length <= classes.length ? classes[index] : classes[Math.floor(Math.random() * classes.length)];

                                                                return (
                                                                    <li key={index}>
                                                                        <div className={`timeline-dots timeline-dot1 ${randomClass}`}></div>
                                                                        <h6 className="float-left mb-1 text-primary fw-bold">{data?.activity}</h6>
                                                                        <div className='m-0 p-0 float-right mt-1'>
                                                                            <span className='m-0 p-0 me-1'>{data?.createdDate}</span>
                                                                            <span className='m-0 p-0 text-secondary'>{data?.createdTime ? `(${data?.createdTime})` : ''}</span>
                                                                        </div>
                                                                        <div className="d-inline-block w-100">
                                                                            <p>{data?.activityDesc}</p>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            ) : (
                                                <div className='my-2 w-100 text-center text-secondary fs-3'>
                                                    No activity found . . . . .
                                                </div>
                                            )
                                        }
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="3">
                            <Card>
                                <Card.Header className="bg-white">
                                    <div className="header-title">
                                        <h4 className="card-title">Assigned Leads</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <ul className="m-0 p-0 overflow-scroll" style={{ maxHeight: "16rem" }}>
                                        {
                                            assignedLeads.length > 0 ? (
                                                <>
                                                    {
                                                        assignedLeads.map((data, index) => (
                                                            <li className="mb-4 d-flex justify-content-start align-items-center" key={index}>
                                                                <div className={`p-2 rounded-circle cursor-pointer ${activeLead === data?._id ? 'bg-primary' : 'bg-soft-primary'}`} onMouseOver={() => setActiveLead(data?._id)} onMouseOut={() => setActiveLead('')} onClick={() => history.push(`/${orgName}/dashboard/lead/lead-details/${data?._id}`)}>
                                                                    <Leaderboard className='fs-3' style={{ color: activeLead === data?._id ? '#fff' : '#00000036' }} />
                                                                </div>
                                                                <div className="ms-3 w-100">
                                                                    <div className={`w-100 d-flex justify-content-start align-items-start ${screenWidth < breakPoint && 'flex-column'}`}>
                                                                        <h5 className='cursor-pointer' style={{ fontSize: "0.8rem" }} onClick={() => history.push(`/${orgName}/dashboard/lead/lead-details/${data?._id}`)}>{data?.title}</h5>
                                                                        <p className={`mb-0 rounded-pill px-3 text-white ${screenWidth > breakPoint && 'ms-3'}`} style={styleHandle(data?.status)}>{data?.status}</p>
                                                                    </div>
                                                                    <p className="mb-0">{data?.firm?.firmName}</p>
                                                                </div>
                                                            </li>
                                                        ))
                                                    }
                                                </>
                                            ) : (
                                                <div className='w-100 text-center py-2 text-secondary fs-5'>
                                                    No Lead found . . . .
                                                </div>
                                            )
                                        }
                                    </ul>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header className="bg-white">
                                    <div className="header-title">
                                        <h4 className="card-title">Suggestions</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <ul className="list-inline m-0 p-0">
                                        {
                                            allSuggetions.map((data, index) => (
                                                <li className="d-flex mb-4 align-items-center" key={index}>
                                                    <div className="img-fluid bg-soft-primary rounded-pill"><Image src={data?.icon} alt="story-img" className="rounded-pill avatar-40" /></div>
                                                    <div className="ms-3 flex-grow-1">
                                                        <h6>{data?.name}</h6>
                                                        <p className="mb-0">{data?.mutuals} mutual friends</p>
                                                    </div>
                                                    <div className='rounded-circle border border-primary p-1 text-primary cursor-pointer'>
                                                        <svg width="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" currentcolor="#3a57e8">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M9.87651 15.2063C6.03251 15.2063 2.74951 15.7873 2.74951 18.1153C2.74951 20.4433 6.01251 21.0453 9.87651 21.0453C13.7215 21.0453 17.0035 20.4633 17.0035 18.1363C17.0035 15.8093 13.7415 15.2063 9.87651 15.2063Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M9.8766 11.886C12.3996 11.886 14.4446 9.841 14.4446 7.318C14.4446 4.795 12.3996 2.75 9.8766 2.75C7.3546 2.75 5.3096 4.795 5.3096 7.318C5.3006 9.832 7.3306 11.877 9.8456 11.886H9.8766Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                            <path d="M19.2036 8.66919V12.6792" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                            <path d="M21.2497 10.6741H17.1597" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        </svg>
                                                    </div>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </div >
    )
}

export default UserProfile;