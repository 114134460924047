import { Card } from "antd";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
// import PageTitle from "../page-header/PageHeader";
import dayjs from "dayjs";
import tw from "tailwind-styled-components";

import {
	clearPublicHoliday,
	deletePublicHoliday,
	loadSinglePublicHoliday,
} from "../../../redux/rtk/features/publicHoliday/publicHolidaySlice";
// import PublicHolidayEdit from "../UI/PopUp/PublicHolidayEditPopup";
import BtnLoader from "../../../components/loader/BtnLoader";
import AntLoader from "../../../components/loader/AntLoader";
import BtnDeleteSvg from "../../../components/UI/Button/btnDeleteSvg";
import PublicHolidayEdit from "../../../components/UI/PopUp/PublicHolidayEditPopup";
// import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";

const DetailPublicHoliday = () => {
	const { id } = useParams();
	let navigate = useHistory();
	const { publicHoliday, loading } = useSelector(
		(state) => state.publicHoliday
	);

	//dispatch
	const dispatch = useDispatch();

	//Delete Supplier
	const onDelete = async () => {
		try {
			const resp = await dispatch(deletePublicHoliday(id));
			if (resp.payload.message === "success") {
				return navigate(-1);
			}
		} catch (error) {
			console.log(error.message);
		}
	};

	useEffect(() => {
		dispatch(loadSinglePublicHoliday(id));

		return () => {
			dispatch(clearPublicHoliday());
		};
	}, []);

	const isLogged = Boolean(localStorage.getItem("isLogged"));

	// if (!isLogged) {
	// 	return <Navigate to={"/admin/auth/login"} replace={true} />;
	// }

	const holidayDetails = [
		{
			title: "Name",
			value: publicHoliday?.name
		},
		{
			title: "Date",
			value: dayjs(publicHoliday?.date).format("DD/MM/YYYY")
		},
		{
			title: "Created At",
			value: dayjs(publicHoliday?.createdAt).format("DD/MM/YYYY")
		},

		{
			title: "Updated At",
			value: dayjs(publicHoliday?.updatedAt).format("DD/MM/YYYY")
		},
	];

	return (
		<div className="px-4">
			{/* <PageTitle title=' Back  ' /> */}
			{/* <UserPrivateComponent permission={"readSingle-publicHoliday"}> */}
			<Card className='mr-top mt-5'>
				{publicHoliday ? (
					<Fragment key={publicHoliday.id}>
						<div>
							<div className=''>
								<h3 className={"text-xl"}>{publicHoliday?.name}</h3>
							</div>
							<div className='d-flex justify-content-center align-items-center mt-5 mb-4 '>
								<Card
									style={{ width: 500 }}
									title='Details of Public Holiday'
									extra={
										<div className='d-flex justify-content-end align-items-center '>
											<PublicHolidayEdit data={publicHoliday} />
											{!loading ? (
												<button className='ml-2' onClick={onDelete}>
													<BtnDeleteSvg size={20} />
												</button>
											) : (
												<BtnLoader />
											)}
										</div>
									}>
									<div className='d-flex justify-content-center align-items-center'>
										<ul className=' w-100 border-2 border-inherit rounded p-3 rounded border border-secondary' style={{ listStyle: "none" }}>
											{
												holidayDetails.map((data, index) => (
													<ListItem className="d-flex justify-content-center fw-bold align-items-center text-dark bg-soft-primary mb-2" key={index}>
														{data.title} :
														<span className="ms-2 fw-bold text-dark">{data.value}</span>
													</ListItem>
												))
											}
										</ul>
									</div>
								</Card>
							</div>
						</div>
					</Fragment>
				) : (
					<AntLoader />
				)}
			</Card>
			{/* </UserPrivateComponent> */}
		</div>
	);
};

const ListItem = tw.li`
text-sm
text-gray-600
font-semibold
py-2
px-4
bg-gray-100
mb-1.5
rounded
w-96
flex
justify-start
`;

const TextInside = tw.p`
ml-2
text-sm
text-gray-900
`;

export default DetailPublicHoliday;
