import axios from "axios";
import { HRMAPI } from "../../../../backend";

export const getAllAccount = async () => {
    const data = await axios.get(`${HRMAPI}/account?query=sa`);
    const allAccount = data.data;
    return allAccount;
};

export const getTrailBalance = async () => {
    console.log("trial balance api");
    try {
        const { data } = await axios({
            method: "get",
            url: `${HRMAPI}/account?query=tb`,
        });
        return data;
    } catch (error) {
        console.log(error.message);
    }
};

export const getBalanceSheet = async () => {
    try {
        const { data } = await axios({
            method: "get",
            url: `${HRMAPI}/account?query=bs`,
        });
        return data;
    } catch (error) {
        console.log(error.message);
    }
};

export const getIncomeStatement = async () => {
    try {
        const { data } = await axios({
            method: "get",
            url: `${HRMAPI}/account?query=is`,
        });
        return data;
    } catch (error) {
        console.log(error.message);
    }
};

export const getMainAccount = async () => {
    try {
        const { data } = await axios({
            method: "get",
            url: `${HRMAPI}/account?query=ma`,
        });

        return data;
    } catch (error) {
        console.log(error.message);
    }
};

export const getSubAccount = async (id) => {
    try {
        const { data } = await axios({
            method: "get",
            url: `${HRMAPI}/account/${id}`,
        });

        return data;
    } catch (error) {
        console.log(error.message);
    }
};

export const updateAccount = async (id, values) => {
    try {
        await axios({
            method: "put",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            },
            url: `${HRMAPI}/account/${id}`,
            data: {
                ...values,
            },
        });
        return "success";
    } catch (error) {
        console.log(error.message);
    }
};
