import React, { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { BiCommentDetail } from "react-icons/bi";
import { VscAdd } from "react-icons/vsc";
import Activitymodal from "./activity-modal";
import { Link, useParams } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { MdDeleteOutline } from "react-icons/md";
import { addLeadActivityComment, deleteLeadActivity, getActivityById, getAllActivities, getLeadActivityComments } from "../Hook/leadHooks";
import UpdateActivity from "./lead-main/update-activity";
import { ErrorToast, SuccessToast, WarningToast } from "../../components/toast";
import './lead.css';

const LeadActivities = ({ allActivities, setAllActivities, leadActivitiesByType, type }) => {
  const [selected, setSelected] = useState([]);
  const [activityDetails, setActivityDetails] = useState({});
  const [currentActivityId, setCurrentActivityId] = useState('');
  const [comment, setComment] = useState('');
  const [currentComment, setCurrentComment] = useState('');
  const [currentCommentId, setCurrentCommentId] = useState('');
  const [showEditComment, setShowEditComment] = useState(false);
  const [allComments, setAllComments] = useState([]);
  const [form, setForm] = useState({});
  const [showDetails, setShowDetails] = useState(false);
  const [showDeleteActivity, setShowDeleteActivity] = useState(false);
  const [showCommentSubmit, setShowCommentSubmit] = useState(false)
  const [activityId, setActivityId] = useState('');
  const [totalLetters, setTotalLetters] = useState("");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [showUpdateActivity, setShowUpdateActivity] = useState(false);

  const { id } = useParams();
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    getAllActivities(id).then((response) => {
      (response.status === 200) ? setAllActivities(response.data.data) : setAllActivities([])
    }).catch(() => setAllActivities([]));
  }, [id, setAllActivities]);

  const getComments = (currentId) => {
    getLeadActivityComments(currentId).then((response) => {
      (response.status === 200) ? setAllComments(response.data.data[0].comment) : setAllComments([]);
    }).catch(() => setAllComments([]));
  }

  const getSingleActivityDetials = (currentId) => {
    getActivityById(currentId).then((response) => {
      (response.status === 200) ? setActivityDetails(response.data.data) : setActivityDetails({})
    }).catch(() => setActivityDetails({}))
  }

  const [selectedActivityId, setSelectedActivityId] = useState('');
  const [showComments, setShowComments] = useState(false);
  const getAllData = (activityId) => {
    // setShowDetails(true);
    setShowComments(true);
    setAllComments([]);
    setSelectedActivityId(activityId);
    getSingleActivityDetials(activityId);
    getComments(activityId);
  };

  // const hideComments = (id) => {

  // }

  const showDeleteActivityModal = (currentId) => {
    setShowDeleteActivity(true);
    setActivityId(currentId);
  };

  const deleteActivity = () => {
    deleteLeadActivity(activityId).then((response) => {
      if (response.status === 202) {
        SuccessToast('Activity Deleted Successfully');
        setShowDeleteActivity(false);
        leadActivitiesByType(type);
      } else {
        ErrorToast('Unable to delete activity');
      }
    }).catch((err) => {
      err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Something went wrong');
    })
  };

  const handleEditComment = (data) => {
    setCurrentCommentId(data._id);
    // if ((showEditComment || showCommentSubmit) && (data._id === currentActivityId)) {
    setCurrentComment(data.comment);
    // }
    setShowEditComment(true);
  }

  const handleCommentChange = (e, dataId) => {
    setCurrentActivityId(dataId);
    setComment(e.target.value);
    setForm({
      ...form,
      id: dataId,
      comment: [{
        comment: e.target.value,
        userID: userId
      }]
    });
    setTotalLetters(e.target.value.slice(0, 100));
  };

  const handleShowCommentPost = (aId) => {
    setActivityId(aId);
    setShowCommentSubmit(true);
  }

  const handleComments = async (e) => {
    e.preventDefault();
    if (comment.length > 0) {
      addLeadActivityComment(form).then((response) => {
        if (response.status === 200) {
          SuccessToast('Comment added');
          setComment('');
          leadActivitiesByType(type);
          setTotalLetters('');
        } else {
          ErrorToast('Unable to add comment');
        }
      }).catch((err) => {
        err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Something went wrong');
      })
    } else {
      ErrorToast('Add a comment');
    }
  };

  const handleRemove = (index) => {
    const newList = [...selected];
    newList.splice(index, 1);
    setSelected(newList);
  };

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const format = (dateString) => {
    const options = { day: "numeric", month: "numeric", year: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const formatTime = (timeString) => {
    const hour = parseInt(timeString.slice(0, 2));
    let time = hour > 11 ? 'PM' : 'AM';
    return `${timeString} ${time}`;
  }

  const handleShowUpdateActivity = (currentId) => {
    setShowUpdateActivity(true);
    getSingleActivityDetials(currentId);
    setActivityId(currentId);
  }

  const handleClick = (mailto, label) => {
    return (
      <link
        to='#'
        onclick={(e) => {
          window.location.href = mailto;
          e.preventdefault();
        }}
      >
        {label}
      </link>
    );
  }

  return (
    <>
      <Row className="m-0 p-0 mb-1 mt-4 ">
        <Col sm={3} className="pe-2 ps-0">
          <div className={`border-primary text-center rounded m-0 py-2 px-2 ${type === "All" ? "bg-primary text-white" : "bg-white text-dark"}`}
            onClick={(e) => leadActivitiesByType("All")}
            style={{ cursor: "pointer", border: "1px solid" }}>
            All
          </div>
        </Col>
        <Col
          sm={3} className="pe-2 ps-0"
        >
          <div className={`border-primary text-center rounded m-0 py-2 px-2 ${type === "Note" ? "bg-primary text-white" : "bg-white text-dark"}`}
            onClick={(e) => leadActivitiesByType("Note")}
            style={{ cursor: "pointer", border: "1px solid" }}>
            Note
          </div>
        </Col>
        <Col
          sm={3} className="pe-2 ps-0"
        >
          <div className={`border-primary text-center rounded m-0 py-2 px-2 ${type === "Attachment" ? "bg-primary text-white" : "bg-white text-dark"}`}
            onClick={() => leadActivitiesByType("Attachment")}
            style={{ cursor: "pointer", border: "1px solid" }}>
            Attachment
          </div>
        </Col>
        <Col
          sm={3} className="pe-2 ps-0"
        >
          <div className={`border-primary text-center rounded m-0 py-2 px-2 ${type === "Reminder" ? "bg-primary text-white" : "bg-white text-dark"}`}
            onClick={() => leadActivitiesByType("Reminder")}
            style={{ cursor: "pointer", border: "1px solid" }}>
            Reminder
          </div>
        </Col>
      </Row>
      <div
        className="mt-2"
        style={{ overflowX: "hidden", maxHeight: "60vh", height: "100%", overflowY: "scroll", }}
      >
        {allActivities.length === 0 && (
          <div sm={12} className="bg-white w-100 m-0 border-primary rounded w-100 py-2 px-3 text-center fs-5 text-primary">
            No activity found
          </div>
        )}
        {allActivities.map((data, index) => (
          <div
            className="p-3 my-3"
            style={{ borderBottom: "1px solid #00000050", borderTop: "1px solid #00000010", borderLeft: "1px solid #00000010", borderRight: "1px solid #00000010", borderRadius: "5px", }}
          >
            <div>
              <h5>{data?.type}</h5>
              <span className="m-0 p-0 text-secondary" style={{ fontWeight: "550" }}>{data.activityDate === undefined ? '' : format(data.activityDate)}</span>
              <span className="m-0 p-0 ms-2 text-secondary">{data.activityTime === undefined ? '' : formatTime(data.activityTime)}</span>
            </div>
            <div>
              {data.title && (
                <div className="mt-2">
                  <Col sm={3} className="m-0 p-0" style={{ fontWeight: "550" }}>Activity Title:</Col>
                  <Col sm>{data.title === undefined ? '' : data.title}</Col>
                </div>
              )}
              <div className="mt-2">
                <Col sm={3} className="m-0 p-0" style={{ fontWeight: "550" }}>Description:</Col>
                <Col>{data.desc === undefined || data.desc === 'undefined' ? '' : data.desc}</Col>
              </div>
            </div>
            <div className="comment-section w-100">
              <form onSubmit={handleComments} className="w-100">
                <div className={`w-100 p-2 my-3 comment-field ${((showCommentSubmit) && (data._id === activityId) && (totalLetters.length === 100)) && 'border-danger'} ${((showEditComment || showCommentSubmit) && (data._id === currentActivityId)) ? 'active-comment-field' : 'inactive-comment-field'} `}>
                  <input type="text" className="m-0 p-0" name="comment" placeholder="Add a comment "
                    defaultValue={((data._id === currentActivityId) && (currentComment.length > 0)) ? currentComment : ''}
                    // value={data._id === currentActivityId ? comment : ''}
                    onChange={(e) => handleCommentChange(e, data._id)}
                    onClick={() => handleShowCommentPost(data._id)}
                    style={{ border: "none", background: "none", width: "90%" }}
                    maxLength={100} required autoComplete="off"
                  />
                  {
                    ((showEditComment || showCommentSubmit) && (data._id === currentActivityId)) && (
                      <>
                        {
                          ((showEditComment) || (data._id === currentActivityId)) ? (
                            <span
                              className={`${totalLetters.length === 100 ? 'text-danger fw-bold' : 'text-dark'} p-0 me-2`}
                              style={{ position: "absolute", right: 0 }}
                            >
                              {`${totalLetters.length}/100`}
                            </span>
                          ) : (
                            <span className="text-dark p-0 me-2" style={{ position: "absolute", right: 0 }}>{`0/100`}</span>
                          )
                        }
                      </>
                    )
                  }
                </div>
                {
                  ((showEditComment || showCommentSubmit) && (data._id === currentActivityId)) ? (
                    <div className="d-flex justify-content-start align-tiems-center">
                      <Button variant="primary" type="submit" className="py-1 px-2">POST</Button>
                      <Button variant="outline-secondary" type="button" className="ms-3 py-1 px-2" onClick={() => {
                        setShowCommentSubmit(false);
                        setShowEditComment(false);
                        setComment('');
                        setCurrentComment('');
                        setTotalLetters('');
                        setCurrentActivityId('');
                      }}>Cancel</Button>
                    </div>
                  ) : null
                }
              </form>

              <div className="allComments mt-2">
                <div className="singleCommentDetail">
                  {
                    showComments && selectedActivityId === data._id ? (
                      <>
                        <span className="m-0 mb-3 p-0 text-primary" onClick={() => setShowComments(false)} style={{ cursor: "pointer" }}>Hide Comments</span>
                        {
                          allComments.length > 0 ? (
                            <>
                              <Col sm={12} className=" text-secondary">All comments</Col>
                              <div style={{ maxHeight: "18rem", overflowY: "scroll", scrollBehavior: "smooth" }}>
                                {
                                  allComments.map((data, index) => (
                                    <div className="py-3 mx-2 d-flex justify-content-start align-items-start" style={{ borderBottom: allComments.length > 1 && "1px solid #d1d1d187" }} key={index}>
                                      <div>
                                        <img src="https://tse4.mm.bing.net/th?id=OIP.urs9CLtRNkSZtaP2K1uOIAHaHa&pid=Api&P=0" alt="" width="30px" height="30px" className="rounded-circle" />
                                      </div>
                                      <div>
                                        <span className="text-dark fw-bold">{data?.userID?.firstName}</span>
                                        <span className="text-secondary ps-0 ms-2">12/10/2023</span>
                                        <button label="test"></button>
                                        <p className="text-dark ms-3 my-1 pb-0">{data === undefined || data.comment === undefined ? '' : data.comment}</p>
                                        <div className="d-flex justify-content-between align-items-center w-25 mt-2">
                                          <span className="fw-bold text-secondary cursor-pointer" onClick={() => handleEditComment(data)}>Edit</span>
                                          <span className="fw-bold text-secondary cursor-pointer rounded bg-secondary mx-2 p-0" style={{ minWidth: "4px", minHeight: "4px" }}></span>
                                          <span className="fw-bold text-secondary cursor-pointer m-0 p-0">Delete</span>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                }
                              </div>
                            </>
                          ) : (
                            <div className="my-2 pb-2 text-secondary text-center" style={{ borderBottom: "1px solid #c8c9ca" }}>
                              No comments yet
                            </div>
                          )
                        }
                      </>
                    ) : (
                      <>
                        {
                          data?.comment?.length > 0 ? (
                            <span className="m-0 mb-3 p-0 text-primary" onClick={() => getAllData(data._id)} style={{ cursor: "pointer" }}>Show Comments</span>
                          ) : null
                        }
                      </>
                    )
                  }
                </div>
              </div>
            </div>
          </div >
        ))}
      </div >
      <Activitymodal show={showDetails} setShow={setShowDetails} allComments={allComments} details={activityDetails} desc={activityDetails?.desc} title={activityDetails?.title} type={activityDetails?.type} image={activityDetails?.image} />
      <Modal centered show={showDeleteActivity} onHide={() => setShowDeleteActivity(false)}>
        <Modal.Body className="my-2">
          <Row className="mb-2 ms-1">
            <Col sm={12} className=" text-center px-2 m-0 p-0 fs-3">Delete Activity</Col>
            <Col sm={12} className="text-secondary text-center px-2 m-0 p-0">You want to delete the activity ?</Col>
          </Row>
          <div className="ms-2 w-100" style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }} >
            <div className="py-1 px-5 me-2 bg-danger text-white text-center text-p border border-danger" onClick={() => deleteActivity()} style={{ borderRadius: "4px", cursor: "pointer" }}>
              Yes
            </div>
            <div className="bg-white text-primary py-1 px-5 border border-primary text-center" onClick={() => setShowDeleteActivity(false)} style={{ borderRadius: "5px", cursor: "pointer" }}>
              No
            </div>
          </div>
        </Modal.Body>
      </Modal >
      <UpdateActivity show={showUpdateActivity} setShow={setShowUpdateActivity} activityId={activityId} activityDetails={activityDetails} getAllActivities={leadActivitiesByType} />
    </>
  );
};

export default LeadActivities;
