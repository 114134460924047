import React, { useState, useEffect } from "react";
import '../../../styles/stepProgressBar.css'

const LeadSubheader = ({ details, updateStatus, updateStage, setActiveStep, activeStep }) => {

  const [totalDays, setTotalDays] = useState(10);
  const [stageHistory, setStageHistory] = useState([]);
  const [stageTimeline, setStageTimeLine] = useState([]);
  const [selectedStage, setSelectedStage] = useState('');

  useEffect(() => {
    const startDate = new Date(details?.createdDate);
    const endDate = new Date(details?.closureDate);

    if (startDate !== undefined && endDate !== undefined) {
      const timeDifference = endDate.getTime() - startDate.getTime();
      const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
      setTotalDays(daysDifference);
    } else {
      setTotalDays(5);
    }
  }, [details]);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const breakPoint = 1200;

  useEffect(() => {
    setTimeout(() => {
      const history = details?.stageHistory;
      setStageHistory(details?.stageHistory);

      if (history !== undefined && history.length > 0 && history[0].stageName === details?.stage) {
        setStageTimeLine(details?.stageHistory)
      } else {
        const newArr = history.filter((data, i) => i !== 0);
        setStageTimeLine(newArr);
      }
    }, 1000);
  }, [stageHistory]);

  // navbarstylemode
  // useEffect(() => {
  //   const navbarstyleMode = sessionStorage.getItem("Navbarstyle-mode");
  //   NavbarstyleAction(navbarstyleMode);
  // });

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    stageHistory !== undefined && stageHistory.map((data, index) => (data.stageName === details?.stage) && setActiveStep(index + 1))
  }, [stageHistory]);

  const calculateProgress = () => {

    // const totalWidth = selectedStage === "" ? 100 : totalDays;

    let width = 0;
    if (stageHistory !== undefined && stageHistory.length > 0) {
      if (stageHistory[0]?.stageName === details?.stage) {
        width = 50;
      } else {
        // width = activeStep + 1;
        width = ((activeStep - 1) / ((stageHistory ? stageHistory.length : 1) - 2)) * 100;
      }
    }

    return width;
  };

  const calculateDateWidth = () => {

  }

  const calculateWidth = (stage, stageIndex) => {

    const currentIndex = stageHistory.findIndex((data) => data.stageName === details.stage);
    const totalWidth = selectedStage === "" ? 100 : totalDays;

    let width = 0;
    if (stageHistory !== undefined && stageHistory.length > 0) {
      if (stageHistory[0]?.stageName === details?.stage) {
        width = currentIndex === stageIndex ? totalWidth / 2 : (totalWidth / 2) / (stageHistory.length - 1);
      } else {
        if (stage.days > 0) {
          width = ((stage.days * 100) / totalDays)
        } else {
          let occupiedDays = stageHistory.reduce((prev, curr) => prev + curr.days, 0);
          let remainingDays = totalDays - occupiedDays;

          const newArr = stageHistory.filter(data => data.days === 0);

          const totalRemaingwidth = remainingDays * 100 / totalDays;
          width = totalRemaingwidth / newArr.length
        }
      }
    }
    return `${width}%`;
  };

  const handleStageClick = (step, index) => {
    updateStage(step, index);
    setActiveStep(index);
    setSelectedStage(step.stageName);
  };

  const handleFormatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const formattedDate = dateObject.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
    });

    return formattedDate
  };

  return (
    <>
      <div className="">
        <div className="m-0 p-0 me-3">
          {
            (!stageHistory || stageHistory.length === 0) ? (
              <p className="placeholder-glow">
                <span className="placeholder col-3"></span>
                <span className="placeholder col-3"></span>
                <span className="placeholder col-3"></span>
              </p>
            ) : (
              <>
                <div className="step-progress-bar">
                  <div className="progressDuration d-flex justify-content-around align-items-center text-center pt-2" style={{ width: `90%` }}>
                    {
                      stageTimeline.map((data, index) => (
                        <div>
                          {
                            data.startDate.length > 0 ? (
                              <div key={index}>
                                <span className="m-0 p-0 text-secondary">{handleFormatDate(data?.startDate)}</span>
                                {
                                  data.endDate.length > 0 ? (
                                    <span className="m-0 p-0 text-secondary"> - {handleFormatDate(data?.endDate)}</span>
                                  ) : (
                                    <span className="m-0 p-0 text-secondary"> - {handleFormatDate(new Date())}</span>
                                  )
                                }
                              </div>
                            ) : null
                          }
                        </div>
                      ))
                    }
                  </div>

                  <div className="progressBar" style={{ width: `${calculateProgress()}%` }}></div>
                  <div className="steps m-0 p-0" style={{ width: "100%" }}>
                    {
                      stageHistory !== undefined ? (
                        <>
                          {
                            stageHistory.map((step, index) => (
                              <div className={`${(index > 0 && step.days === 0) && 'd-flex justify-content-start align-items-center'} m-0 p-0 text-center`} key={index} style={{ width: calculateWidth(step, index) }}>
                                <div
                                  className={` ${details?.stage === step?.stageName ? 'cursor-not-allowed' : 'cursor-pointer'} step ${index + 1 <= activeStep ? 'active' : 'bg-white'}`}
                                  onClick={() => handleStageClick(step, index)}
                                >
                                  {step != "" ? index + 1 : null}
                                </div>
                              </div>
                            ))
                          }
                        </>
                      ) : null
                    }

                    {/* {details?.stageHistory.map((step, index) => (
                <div className="m-0 p-0 row" key={index}>
                  <Col sm={12} className="m-0 p-0 text-center">
                    <div
                      className={` ${details?.stage === step?.stageName ? 'cursor-not-allowed' : 'cursor-pointer'} step ${index + 1 <= activeStep ? 'active' : 'bg-white'}`}
                      onClick={() => handleStageClick(step, index)}
                    >
                      {step != "" ? index + 1 : null}
                    </div>
                  </Col>
                </div>
              ))} */}
                  </div>
                </div>
                <div className="w-100 m-0 p-0 d-flex justify-content-between align-items-start">
                  {
                    stageHistory !== undefined ? (
                      <>
                        {
                          stageHistory.map((step, index) => (
                            <span className={`m-0 p-0 ${(index > 0 && step.days === 0) ? 'text-start' : 'text-start'}`} style={{ width: calculateWidth(step, index) }} key={index}>{step?.stageName}</span>
                          ))
                        }
                      </>
                    ) : null
                  }
                </div>
              </>
            )
          }
        </div>
      </div>
    </>
  );
};

export default LeadSubheader;





