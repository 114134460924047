import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { getUserById, updateUserProfileImage } from "../../Hook/userHooks";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { FiUpload } from 'react-icons/fi';
import { ErrorToast, SuccessToast } from "../../../components/toast";
import { updateUser } from "../../Hook/authHooks";
import { getOrgDetails } from "../../Hook/orgHooks";
import { MdOutlineCancel } from "react-icons/md";
import { userLogo } from "../../../../../assets/images";

const UpdateUserProfile = () => {
  const [modulePermissions, setModulePermissions] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]);
  const [form, setForm] = useState({});
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [userDetails, setUserDetails] = useState({});
  const [userPipelines, setUserPipelines] = useState([]);
  const [selectedRole, setSelectedRole] = useState('User');
  const [orgPipelines, setOrgPipelines] = useState([]);
  const [selectedFile, setSelectedFile] = useState('');
  const [selectedImage, setSelectedImage] = useState('');
  const [showPipelineSelector, setShowPipelineSelector] = useState(false);
  const [isSelectingPipeline, setIsSelectingPipeline] = useState(true);
  const [showImageUploader, setShowImageUploader] = useState(false);
  const [showUpdatImage, setShowUpdateImage] = useState(false);
  const [showCustomRoleField, setShowCustomRoleField] = useState(false);
  // const [disableImgUploadBtn, setDisableImageUploadBtn] = useState(false);
  const [disableUpdateBtn, setDisableUpdateBtn] = useState(false);

  const hiddenImageField = useRef(null);
  const { id } = useParams();
  const location = useHistory();
  const orgName = localStorage.getItem('orgName');
  const orgId = localStorage.getItem('orgId');

  const formData = new FormData();

  const allRoles = [
    { role: 'User', value: 'User' },
    { role: 'Admin', value: 'Admin' },
    { role: 'Custom', value: 'Custom' },
  ];

  const userbasicDetails = [
    { tilte: 'Email', desc: userDetails?.email },
    { tilte: 'Mobile', desc: userDetails?.phone },
    { tilte: 'Role', desc: userDetails?.subRole !== undefined && userDetails?.subRole ? userDetails?.subRole : userDetails?.role }
  ];

  const allPermissions = [
    {
      module: "invoice",
      action: [
        { title: 'Create', value: 'Create' },
        { title: 'Read', value: 'Read' },
        { title: 'Update', value: 'Update' },
        { title: 'Delete', value: 'Delete' }
      ]
    },
    {
      module: "firm",
      action: [
        { title: 'Create', value: 'Create' },
        { title: 'Read', value: 'Read' },
        { title: 'Update', value: 'Update' },
        { title: 'Delete', value: 'Delete' }
      ]
    },
    {
      module: "client",
      action: [
        { title: 'Create', value: 'Create' },
        { title: 'Read', value: 'Read' },
        { title: 'Update', value: 'Update' },
        { title: 'Delete', value: 'Delete' }
      ]
    },
    {
      module: "lead",
      action: [
        { title: 'Create', value: 'Create' },
        { title: 'Read', value: 'Read' },
        { title: 'Update', value: 'Update' },
        { title: 'Delete', value: 'Delete' }
      ]
    },
    {
      module: "vendor",
      action: [
        { title: 'Create', value: 'Create' },
        { title: 'Read', value: 'Read' },
        { title: 'Update', value: 'Update' },
        { title: 'Delete', value: 'Delete' }
      ]
    },
    {
      module: "purchase",
      action: [
        { title: 'Create', value: 'Create' },
        { title: 'Read', value: 'Read' },
        { title: 'Update', value: 'Update' },
        { title: 'Delete', value: 'Delete' }
      ]
    },
    {
      module: "tax",
      action: [
        { title: 'Create', value: 'Create' },
        { title: 'Read', value: 'Read' },
        { title: 'Update', value: 'Update' },
        { title: 'Delete', value: 'Delete' }
      ]
    },
    {
      module: "procduct",
      action: [
        { title: 'Create', value: 'Create' },
        { title: 'Read', value: 'Read' },
        { title: 'Update', value: 'Update' },
        { title: 'Delete', value: 'Delete' }
      ]
    },
    {
      module: "user",
      action: [
        { title: 'Create', value: 'Create' },
        { title: 'Read', value: 'Read' },
        { title: 'Update', value: 'Update' },
        { title: 'Delete', value: 'Delete' }
      ]
    },
  ];

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setForm({
      ...form,
      department: userPipelines
    });
  }, [userPipelines]);

  const getUserDetails = () => {
    getUserById(id).then((response) => {
      if (response.status === 200) {
        const permissions = response?.data?.data?.permissions === undefined ? [] : response?.data?.data?.permissions;
        response?.data?.data?.role === 'Custom' && setShowCustomRoleField(true)

        setModulePermissions(permissions);
        setUserPermissions(permissions)
        setUserPipelines(response?.data?.data?.department);
        setUserDetails(response.data.data);
        setSelectedRole(response.data.data.role);
        setSelectedImage(response?.data?.data?.profilePhoto?.length > 0 ? response?.data?.data?.profilePhoto : userLogo);
      } else setUserDetails({})
    }).catch(() => setUserDetails({}));
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  useEffect(() => {
    if (selectedRole === 'Admin') {
      const formattedPermissions = allPermissions.map(permission => ({
        module: permission.module,
        action: permission.action.map(action => action.value)
      }));

      setModulePermissions(formattedPermissions);
    } else if (selectedRole === 'User') {
      setModulePermissions([]);
    } else if (selectedRole === 'Custom') {
      setModulePermissions(userPermissions)
    }
  }, [selectedRole]);

  useEffect(() => {
    getOrgDetails(orgId).then((response) => {
      if (response.status === 200) setOrgPipelines(response?.data?.data?.orgDept);
      else setOrgPipelines([]);
    }).catch(() => setOrgPipelines([]));
  }, [orgId]);

  useEffect(() => {
    handleChangeRole(selectedRole)
  }, []);

  useEffect(() => {
    setForm({
      ...form,
      permissions: modulePermissions
    })
  }, [modulePermissions]);

  useEffect(() => {
    const handleResize = setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const handleAddUserPipeline = (pipeline) => {
    !userPipelines.includes(pipeline) && setUserPipelines([...userPipelines, pipeline])
  };

  const handleRemoveUserPipeline = (pipeline) => {
    setUserPipelines(userPipelines.filter(data => data !== pipeline));
  }

  const handleImageChange = (e) => {
    setShowUpdateImage(true);
    setSelectedFile(e.target.files[0]);
    setSelectedImage(URL.createObjectURL(e.target.files[0]));
  };

  const handleChangeRole = (role) => {
    setSelectedRole(role);
    setShowCustomRoleField(false);
    setForm({
      ...form,
      role: role
    })
    if (role === 'Custom') {
      setShowCustomRoleField(true);
    }
  };

  const handleChange = (e) => {
    if (e.target.name === 'phone') {
      if (e.target.value.length <= 10) {
        e.target.value = e.target.value.slice(0, 10);
        setForm({
          ...form,
          phone: e.target.value
        })
      } else {
        e.target.value = e.target.value.slice(0, 10);
        ErrorToast("Mobile No. length can't be more than 10");
      }
    }

    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  };

  const test = [];

  const handleCustomRolePermissions = (moduleName, permission) => {
    const existingModuleIndex = modulePermissions.findIndex(
      (module) => module.module === moduleName
    );

    if (existingModuleIndex !== -1) {
      const updatedModules = [...modulePermissions];
      const permissions = updatedModules[existingModuleIndex].action;

      if (permissions.includes(permission)) {
        const updatedPermissions = permissions.filter((p) => p !== permission);
        if (updatedPermissions.length === 0) {
          updatedModules.splice(existingModuleIndex, 1);
        } else {
          updatedModules[existingModuleIndex].action = updatedPermissions;
        }
      } else (permission === 'Update') ? permissions.push('Read', permission) : permissions.push(permission);

      setModulePermissions(updatedModules);
    } else {
      if (permission === 'Update') {
        test.push({ module: moduleName, action: ['Read', permission] });

        setModulePermissions(prevModules => [
          ...prevModules,
          { module: moduleName, action: ['Read', permission], },
        ]);
      } else {
        test.push({ module: moduleName, action: [permission] });

        setModulePermissions(prevModules => [
          ...prevModules,
          { module: moduleName, action: [permission], },
        ]);
      }
    }
  };

  const handleUpdateProfile = (e) => {
    e.preventDefault();
    setDisableUpdateBtn(true);
    updateUser(form, id).then((response) => {
      if (response.status === 200) {
        setDisableUpdateBtn(false);
        SuccessToast('User updated successfully');
        location.push(`/${orgName}/dashboard/user/all-users`);
      } else {
        ErrorToast("Unable to update user");
        setDisableUpdateBtn(false);
      }
    }).catch(() => {
      ErrorToast("Something went wrong");
      setDisableUpdateBtn(false);
    })
  };

  const handleUpdateProfileImage = () => {
    // setDisableImageUploadBtn(true);
    formData.append('profilePhoto', selectedFile);
    updateUserProfileImage(formData, id).then((response) => {
      if (response.status === 201) {
        // setDisableImageUploadBtn(false);
        SuccessToast("Profile image updated successfully");
        getUserDetails();
        setSelectedFile('');
        setSelectedImage('');
        setShowUpdateImage(false);
      } else {
        ErrorToast("Unable to update profile image");
        setSelectedFile('');
        setSelectedImage('');
        setShowUpdateImage(false);
        // setDisableImageUploadBtn(false);
      }
    }).catch(() => {
      ErrorToast("Something went wrong");
      setSelectedFile('');
      setSelectedImage('');
      setShowUpdateImage(false);
      // setDisableImageUploadBtn(false);
    })
  };

  const cancelUpdateProfileImage = () => {
    setSelectedFile('');
    setSelectedImage(userDetails?.profilePhoto?.length > 0 ? userDetails?.profilePhoto : userLogo);
    setShowImageUploader(false);
    setShowUpdateImage(false);
  };

  const handleShowOptions = () => {
    !isSelectingPipeline && setShowPipelineSelector(false);
  }

  return (
    <div className="m-0 p-0" onClick={() => handleShowOptions()}>
      <h2 className="my-5 mx-3 bg-secondary text-white p-2" style={{ width: "98%" }}>Update User</h2>
      <Row
        className="mx-2 mb-3"
        style={{ marginTop: screenWidth >= 1200 ? "-1%" : "-3%" }}
      >
        <Col sm={3} className="px-2">
          <div className="bg-white p-3 rounded">
            <div className="d-flex justify-content-center align-items-center flex-column">
              <div>
                <div className="rounded-circle relative cursor-pointer" onMouseEnter={() => setShowImageUploader(true)} onMouseLeave={() => setShowImageUploader(false)}>
                  <img src={selectedImage.length === 0 ? userLogo : selectedImage} alt="" className="rounded-circle" style={{ width: "5.5rem", height: "5.5rem" }} />
                  {
                    showImageUploader ? (
                      <div className="rounded-circle d-flex justify-content-center align-items-center" onClick={() => hiddenImageField.current.click()} style={{ position: "absolute", top: '21%', background: "#00000030", width: "5.5rem", height: "5.5rem" }}>
                        <FiUpload className="text-white primary text-center" style={{ fontSize: "2rem" }} />
                      </div>
                    ) : null
                  }
                </div>
                <Form.Check type="file" className="d-none" onChange={handleImageChange} ref={hiddenImageField} />
              </div>
              {
                showUpdatImage ? (
                  <div className="w-100 d-flex justify-content-center align-items-center">
                    <div className="mt-3 w-50 d-flex justify-content-center align-items-center">
                      {/* <FiUpload className=" cursor-pointer text-success fs-2" />
                      <MdCancel className="cursor-pointer text-danger fs-2" onClick={() => setSelectedImage('')} /> */}
                      <span className="px-3 py-1 rounded-5 cursor-pointer bg-success border border-success text-white" onClick={handleUpdateProfileImage}>Upload</span>
                      <span className="px-3 py-1 rounded-5 cursor-pointer bg-white border border-danger" onClick={() => cancelUpdateProfileImage()}>Cancel</span>
                    </div>
                  </div>
                ) : null
              }
              <h3 className="pt-2">{userDetails?.firstName} {userDetails?.lastName}</h3>
            </div>
            <div className="mt-2">
              {
                userbasicDetails.map((data, index) => (
                  <Row key={index}>
                    <Col sm={2} style={{ fontWeight: 550 }}>{data?.tilte}: </Col>
                    <Col sm>{data?.desc}</Col>
                  </Row>
                ))
              }
            </div>
          </div>
        </Col>
        <Col sm={9} className="px-2">
          <div className="bg-white p-3 rounded">
            <Form onSubmit={handleUpdateProfile}>
              <h4>Edit Info</h4>
              <div>
                <h5 className="p-2 bg-soft-primary text-dark mt-3">Personal Info</h5>
                <div className="row mt-2">
                  <Form.Group className="col-sm-6 pe-2 pt-2">
                    <Form.Label className="mb-1 text-secondary fw-semibold" style={{ fontSize: "0.8rem" }}>First Name</Form.Label>
                    <Form.Control type="text" name="firstName" defaultValue={userDetails?.firstName} onChange={handleChange} placeholder="Enter First Name" autoComplete="off" style={{ border: "1px solid #00000030" }} />
                  </Form.Group>

                  <Form.Group className="col-sm-6 pe-2 pt-2">
                    <Form.Label className="mb-1 text-secondary fw-semibold" style={{ fontSize: "0.8rem" }}>Last Name</Form.Label>
                    <Form.Control type="text" name="lastName" defaultValue={userDetails?.lastName} onChange={handleChange} placeholder="Enter Last Name" autoComplete="off" style={{ border: "1px solid #00000030" }} />
                  </Form.Group>

                  <Form.Group className="col-sm-6 pe-2 pt-2">
                    <Form.Label className="mb-1 text-secondary fw-semibold" style={{ fontSize: "0.8rem" }}>Email</Form.Label>
                    <Form.Control type="email" name="email" defaultValue={userDetails?.email} onChange={handleChange} placeholder="Enter Email" autoComplete="off" style={{ border: "1px solid #00000030" }} />
                  </Form.Group>

                  <Form.Group className="col-sm-6 pe-2 pt-2">
                    <Form.Label className="mb-1 text-secondary fw-semibold" style={{ fontSize: "0.8rem" }}>Mobile</Form.Label>
                    <Form.Control type="number" name="phone" defaultValue={userDetails?.phone} onChange={handleChange} placeholder="Enter Mobile No." autoComplete="off" style={{ border: "1px solid #00000030" }} />
                  </Form.Group>

                  <Form.Group className="col-sm-6 pe-2 pt-2 position-relative">
                    <Form.Label className="mb-1 text-secondary fw-semibold" style={{ fontSize: "0.8rem" }}>Pipeline</Form.Label>

                    <div className="px-3 py-2 d-flex justify-content-start align-items-center cursor-pointer"
                      onMouseOver={() => setIsSelectingPipeline(true)}
                      onMouseOut={() => setIsSelectingPipeline(false)}
                      onClick={() => setShowPipelineSelector(true)}
                      style={{ overflowX: 'scroll', fontSize: "0.8rem", border: '1px solid #00000030', borderRadius: "5px" }}
                    >
                      {
                        userPipelines.length > 0 ? (
                          <>
                            {
                              userPipelines.map((data, index) => (
                                <div className="me-2 px-2 border border-primary rounded-5 d-flex justify-content-between align-items-center" key={index} style={{ minWidth: "25%" }}>
                                  <span className="m-0 p-0">{data}</span>
                                  <MdOutlineCancel className="m-0 p-0 text-primary cursor-pointer fs-4" onClick={() => handleRemoveUserPipeline(data)} />
                                </div>
                              ))
                            }
                          </>
                        ) : (
                          <p className="text-secondary m-0 p-0 w-100 cursor-pointer">Select Pipeline . . . . </p>
                        )
                      }
                    </div>
                    {
                      showPipelineSelector ? (
                        <div className="bg-white w-25 rounded"
                          onMouseOver={() => setIsSelectingPipeline(true)}
                          onMouseOut={() => setIsSelectingPipeline(false)}
                          style={{ position: 'absolute', top: 80, boxShadow: "0px 0px 10px 0px #c8c9ca" }}
                        >
                          {
                            orgPipelines.map((data, index) => (
                              <div className={`${userPipelines.includes(data) && 'bg-primary text-white'} ${index === 0 && 'rounded-top'} ${(index === orgPipelines.length - 1) && 'rounded-bottom'} py-2 pe-5 ps-3 cursor-pointer border-bottom border-white `}
                                onClick={() => handleAddUserPipeline(data)}
                                key={index}
                              >{data}</div>
                            ))
                          }
                        </div>
                      ) : null
                    }
                  </Form.Group>
                </div>
              </div>
              <div className="mt-5">
                <h5 className="p-2 bg-soft-primary text-dark mt-3">Role & Permissions</h5>
                <div className="row mt-2">
                  <Form.Group className="col-sm-3 pe-2 pt-2">
                    <Form.Label className="mb-1 text-secondary fw-semibold" style={{ fontSize: "0.8rem" }}>Role</Form.Label>
                    <Form.Select onChange={(e) => handleChangeRole(e.target.value)} style={{ fontSize: "0.8rem", border: '1px solid #00000030', borderRadius: "5px" }}>
                      <option defaultValue={userDetails?.value}>{userDetails?.role}</option>
                      {
                        allRoles.map((data, index) => (
                          <option
                            className={`${data.role === userDetails?.role && 'd-none'}`}
                            key={index} value={data.value}>{data.role === userDetails?.value ? userDetails?.value : data.role}</option>
                        ))
                      }
                    </Form.Select>
                  </Form.Group>
                  {
                    showCustomRoleField ? (
                      <Form.Group className="col-sm-3 ms-2 pe-2 pt-2">
                        <Form.Label className="mb-1 text-secondary fw-semibold" style={{ fontSize: "0.8rem" }}>Custom Role</Form.Label>
                        <Form.Control type="text" name="subRole" defaultValue={userDetails?.subRole} onChange={(e) => setForm({ ...form, subRole: e.target.value })} placeholder="Enter Role" autoComplete="off" style={{ border: "1px solid #00000030" }} />
                      </Form.Group>
                    ) : null
                  }

                  <div className="">
                    <Form.Label className="my-1 text-secondary fw-semibold" style={{ fontSize: "0.8rem" }}>Permissions</Form.Label>
                    {
                      allPermissions.map((data, i) => (
                        <Row key={i} className="mb-2">
                          <Col sm={1}>
                            <Form.Label className="my-1 fw-semibold" style={{ fontSize: "0.8rem" }}>{data.module}:</Form.Label>
                          </Col>
                          <Col sm>
                            <Row>
                              {
                                data.action.map((per, index) => (
                                  <Col sm={2} className="pt-2" key={index}>
                                    <div className="w-50 d-flex justify-content-around align-item-center cursor-pointer">
                                      <Form.Check type="checkbox"
                                        className="cursor-pointer"
                                        value={per.value}
                                        onChange={() => handleCustomRolePermissions(data.module, per.value)}
                                        checked={modulePermissions.some((module) => module.module === data.module && module.action.includes(per.value))}
                                      />
                                      <Form.Label
                                        onClick={() => handleCustomRolePermissions(data.module, per.value)}
                                        className="cursor-pointer" style={{ fontSize: "0.8rem" }}>{per.title}</Form.Label>
                                    </div>
                                  </Col>
                                ))
                              }
                            </Row>
                          </Col>
                        </Row>
                      ))
                    }
                  </div>

                </div>
              </div>
              <div className="d-flex justify-content-end align-items-center">
                <Button type="submit cursor-pointer w-5" disabled={disableUpdateBtn}>Update</Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default UpdateUserProfile;
