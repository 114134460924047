import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import SubHeader from '../../../../components/partials/dashboard/HeaderStyle/sub-header';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { getCity, getCountries, getState } from '../Hook/CustomHooks';
import Card from '../../../../components/Card';
import { getAllFirms, getSingleFirm } from '../Hook/firmHooks';
import { createVendor } from '../Hook/vendorHooks';
import { useHistory } from 'react-router-dom';
// import { ErrorToast, SuccessToast } from '../../components/toast';
import useFullPageLoader from '../Hook/useFullPageLoader';
import { Toaster, toast } from 'react-hot-toast';
import { ErrorToast, SuccessToast, WarningToast } from '../../components/toast';
import { addRecentActivity } from '../Hook/recentActivityHooks';

const CreateVendor = () => {

    const [form, setForm] = useState({});
    const [allFirms, setAllFirms] = useState([]);
    const [firmId, setFirmId] = useState('');

    const [person, setPerson] = useState({});
    const [showBasic, setShowBasic] = useState(true);
    const [showContact, setShowContact] = useState(false);
    const [showContactPerson, setShowContactPerson] = useState(false);

    const initAddressData = Object.freeze({
        country: " ",
        state: " ",
        city: " "
    })
    const [address, setAddress] = useState(initAddressData);

    const [cities, setCities] = useState([]);
    const [states, setStates] = useState([]);
    const [countries, setCountries] = useState([]);
    const [personCities, setPersonCities] = useState([]);
    const [personStates, setPersonStates] = useState([]);
    const [personCountries, setPersonCountries] = useState([]);

    const [vendorCity, setVendorCity] = useState('');
    const [vendorState, setVendorState] = useState('');
    const [vendorCountry, setVendorCountry] = useState('');

    const [personCity, setPersonCity] = useState('');
    const [personState, setPersonState] = useState('');
    const [personCountry, setPersonCountry] = useState('');


    const [isOpenVendorCity, setIsOpenVendorCity] = useState(false);
    const [isOpenVendorState, setIsOpenVendorState] = useState(false);
    const [isOpenVendorCountry, setIsOpenVendorCountry] = useState(false);

    const [isOpenPersonCity, setIsOpenPersonCity] = useState(false);
    const [isOpenPersonState, setIsOpenPersonState] = useState(false);
    const [isOpenPersonCountry, setIsOpenPersonCountry] = useState(false);

    const [validated, setValidated] = useState(false);

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [loader, showLoader, hideLoader] = useFullPageLoader();

    const orgName = localStorage.getItem('orgName');
    const orgId = localStorage.getItem('orgID');
    const history = useHistory();

    const datetimeString = new Date();
    const dateObject = new Date(datetimeString);

    const formattedDate = `${dateObject.getDate()}/${dateObject.getMonth() + 1}/${dateObject.getFullYear()}`;
    const formattedTime = dateObject.toLocaleTimeString();

    useEffect(() => {
        getAllFirms(setAllFirms);
    }, [])

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    useEffect(() => {
        getCountries().then((res) => {
            setCountries(res);
        });
    }, []);

    useEffect(() => {
        if (address.country) {
            getState(address.country).then((res) => {
                setStates(res);
            });
        }
    }, [address.country]);

    useEffect(() => {
        if (address.state) {
            getCity(address.state).then((res) => {
                setCities(res);
            });
        }
    }, [address.state]);

    useEffect(() => {
        getCountries().then((res) => {
            setPersonCountries(res);
        });
    }, []);

    useEffect(() => {
        if (person.country) {
            getState(person.country).then((res) => {
                setPersonStates(res);
            });
        }
    }, [person.country]);

    useEffect(() => {
        if (person.state) {
            getCity(person.state).then((res) => {
                setPersonCities(res);
            });
        }
    }, [person.state]);

    const handleCreateVendor = (e) => {
        e.preventDefault();
        setValidated(true);

        if (form.name && form.email && form.phone) {
            showLoader();
            createVendor(form).then((response) => {
                if (response.status === 201) {

                    const activityForm = {
                        module: 'vendor',
                        activity: 'Vendor Added',
                        activityDesc: `New vendor ${form?.name} created`,
                        createdDate: formattedDate,
                        createdTime: formattedTime,
                        userId: localStorage.getItem('userId')
                    };
                    addRecentActivity(activityForm);

                    SuccessToast('Vendor Created Successfully');
                    hideLoader();
                    history.push(`/${orgName}/dashboard/vendor/all-vendors`);
                } else {
                    ErrorToast('Unable to create vendor');
                    hideLoader();
                }
            }).catch((err) => {
                err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Something went wrong');
                hideLoader();
            })
        } else {
            ErrorToast('Fill all the required fields');
        }
    }

    const handleInputChangeCountry = (e) => {
        setVendorCountry(e.target.value);

        setIsOpenVendorCountry(true);
        setAddress({
            ...address,
            country: e.target.value
        })
    };

    const handleOptionClickCountry = (i) => {
        setVendorCountry(i)
        setAddress({
            ...address,
            country: i
        })
        setIsOpenVendorCountry(false);
    };

    const handleInputChange = (e) => {
        setVendorState(e.target.value);

        setIsOpenVendorState(true);
        setAddress({
            ...address,
            state: e.target.value
        })
    };

    const handleOptionClick1 = (i) => {
        setVendorState(i)
        setAddress({
            ...address,
            state: i
        })
        setIsOpenVendorState(false);
    };

    const handleInputChangeCity = (e) => {
        setVendorCity(e.target.value);

        setIsOpenVendorCity(true);
        setAddress({
            ...address,
            city: e.target.value
        })
    };

    const handleOptionClickCity = (i) => {
        setVendorCity(i)
        setAddress({
            ...address,
            city: i
        })
        setIsOpenVendorCity(false);
    };

    const filteredCountryOptions = countries.filter((option) =>
        (option.country_name).toLowerCase().includes(vendorCountry.toLowerCase())
    );

    const filteredOptions = states.filter((option) =>
        (option.state_name).toLowerCase().includes(vendorState.toLowerCase())
    );

    const filteredCityOptions = cities.filter((option) =>
        (option.city_name).toLowerCase().includes(vendorCity.toLowerCase())
    );

    const handleChangePersonCountry = (e) => {
        setPersonCountry(e.target.value);

        setIsOpenPersonCountry(true);
        setPerson({
            ...person,
            country: e.target.value
        })
    };

    const handleClickPersonCountry = (i) => {
        setPersonCountry(i);
        setPerson({
            ...person,
            country: i
        })
        setIsOpenPersonCountry(false);
    };

    const handleChangePersonState = (e) => {
        setPersonState(e.target.value);

        setIsOpenPersonState(true);
        setPerson({
            ...person,
            state: e.target.value
        })
    };

    const handleClickPersonState = (i) => {
        setPersonState(i)
        setPerson({
            ...person,
            state: i
        })
        setIsOpenPersonState(false);
    };

    const handlechangePersonCity = (e) => {
        setPersonCity(e.target.value);

        setIsOpenPersonCity(true);
        setPerson({
            ...person,
            city: e.target.value
        })
    };

    const handleClickPersonCity = (i) => {
        setPersonCity(i)
        setPerson({
            ...person,
            city: i
        })
        setIsOpenPersonCity(false);
    };

    const filteredPersonCountries = personCountries.filter((option) =>
        (option.country_name).toLowerCase().includes(personCountry.toLowerCase())
    );

    const filteredPersonStates = personStates.filter((option) =>
        (option.state_name).toLowerCase().includes(personState.toLowerCase())
    );

    const filteredPersonCities = personCities.filter((option) =>
        (option.city_name).toLowerCase().includes(personCity.toLowerCase())
    );

    const handleAddress = (e) => {
        setAddress({
            ...address,
            [e.target.name]: e.target.value,
        });
        if (e.target.name === "pinCode") {
            if ((e.target.value > vendorCountry) === "United States" ? 7 : 6) {
                e.target.value = e.target.value.slice(0, vendorCountry === "United States" ? 7 : 6)
                setAddress({
                    ...address,
                    pinCode: e.target.value
                })
            }
        }
        setForm({
            ...form,
            add: { ...address }
        });
    };

    const handlePerson = (e) => {
        e.preventDefault();
        setPerson({
            ...person,
            [e.target.name]: e.target.value,
        });
        if (e.target.name === "pinCode") {
            if ((e.target.value > personCountry) === "United States" ? 7 : 6) {
                e.target.value = e.target.value.slice(0, personCountry === "United States" ? 7 : 6)
                setPerson({
                    ...person,
                    pinCode: e.target.value
                })
            }
        }

        if (e.target.name === 'phone' || e.target.name === 'altPhone' || e.target.name === 'mobile' || e.target.name === 'altMobile') {
            e.target.value = e.target.value.slice(0, 10);
            setPerson({
                ...person,
                [e.target.name]: e.target.value
            })
        }

        setForm({
            ...form,
            add: { ...address },
            contectPerson: person
        });
    };

    const handleSelectFirm = (e) => {
        setFirmId(e);
        getSingleFirm(e).then((response) => {
            if (response.status === 200) {
                setForm({
                    ...form,
                    firmName: response.data.data[0].name
                })
            }
        })
    }

    const handleChange = (e) => {

        if (e.target.name === "phone") {
            e.target.value = e.target.value.slice(0, 10);
            setForm({
                ...form,
                [e.target.name]: e.target.value,
                orgId: orgId,
                firmId: firmId
            })
        }

        setForm({
            ...form,
            [e.target.name]: e.target.value,
            orgId: orgId,
            firmId: firmId
        })
    };

    return (
        <div
            onClick={() => {
                (isOpenVendorState && setIsOpenVendorState(false)) || (isOpenVendorCity && setIsOpenVendorCity(false)) || (isOpenVendorCountry && setIsOpenVendorCountry(false)) ||
                    (isOpenPersonCity && setIsOpenPersonCity(false)) || (isOpenPersonState && setIsOpenPersonState(false)) || (isOpenPersonCountry && setIsOpenPersonCountry(false))
            }}
        >
            {/* <Toaster /> */}
            <SubHeader title={"Create Vendor"} />
            <Row className="text-dark mx-3 mb-5" style={{ height: "100%", minHeight: "70vh", marginTop: screenWidth >= 1200 ? "-1%" : "-3%", }}>
                <Form noValidate validated={validated} onSubmit={handleCreateVendor}>
                    <div className="bg-white mb-2 px-3 rounded text-dark" style={{ minHeight: "3rem" }}>
                        <Card.Header className="bg-white" style={{ width: "100%" }}>
                            <Row className="w-100" onClick={() => setShowBasic(!showBasic)}>
                                <Col sm={11} style={{ cursor: "pointer" }}>
                                    <h4 className=" text-primary">Basic Details</h4>
                                </Col>
                                <Col sm={1} className="text-end">
                                    {showBasic === false ? (
                                        <BsChevronDown className="fs-4 fw-bold text-dark" onClick={() => setShowBasic(true)} style={{ cursor: "pointer" }} />
                                    ) : (
                                        <BsChevronUp className="fs-4 fw-bold text-dark" onClick={() => setShowBasic(false)} style={{ cursor: "pointer" }} />
                                    )}
                                </Col>
                            </Row>
                        </Card.Header>
                        {
                            showBasic && (
                                <div>
                                    <Row className="pb-3 px-4">
                                        <Col className="mt-3" sm={6}>
                                            <Form.Group>
                                                <Form.Label htmlFor="name">Vendor Name *</Form.Label>
                                                <Form.Control type="text" name="name" placeholder={"Vendor Name"} onChange={handleChange} className={`${validated ? '' : "border-primary"}`} required />
                                                <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col className="mt-3" sm={6}>
                                            <Form.Group>
                                                <Form.Label htmlFor="firmName">Firm Name *</Form.Label>
                                                <Form.Control as="select" type="text" name="firmName" placeholder={"Firm Name"} onChange={(e) => handleSelectFirm(e.target.value)}
                                                    className={`${validated ? '' : "border-primary"}`} required
                                                >
                                                    <option value="">Select Firm</option>
                                                    {
                                                        allFirms.map((data, index) => (
                                                            <option value={data._id} key={index}>{data.name}</option>
                                                        ))
                                                    }
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        {/* <Col className="mt-3" sm={6}>
                                            <Form.Group>
                                                <Form.Label>Vendor Id</Form.Label>
                                                <input type="text" name="vendorId" placeholder="Vendor Id" onChange={handleChange} className="w-100 p-2 border border-primary" required style={{ borderRadius: "5px" }} />
                                            </Form.Group>
                                        </Col> */}
                                        <Col className="mt-3" sm={6}>
                                            <Form.Group>
                                                <Form.Label>GST / Tax Id</Form.Label>
                                                <input type="text" name="taxId" placeholder="GST / Tax Id" onChange={handleChange} className="w-100 p-2 border border-primary" required style={{ borderRadius: "5px" }} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        }
                    </div>
                    <div className="bg-white my-3 px-3 rounded text-dark">
                        <Card.Header className="bg-white" style={{ width: "100%" }}>
                            <Row className="w-100" onClick={() => setShowContact(!showContact)}>
                                <Col sm={11} style={{ cursor: "pointer" }}>
                                    <h4 className=" text-primary">Contact</h4>
                                </Col>
                                <Col sm={1} className="text-end">
                                    {showContact === false ? (
                                        <BsChevronDown className="fs-4 fw-bold text-dark" onClick={() => setShowContact(true)} style={{ cursor: "pointer" }} />
                                    ) : (
                                        <BsChevronUp className="fs-4 fw-bold text-dark" onClick={() => setShowContact(false)} style={{ cursor: "pointer" }} />
                                    )}
                                </Col>
                            </Row>
                        </Card.Header>
                        {
                            showContact && (
                                <Row className="pb-3 px-4">
                                    <Col className="mt-3" sm={6}>
                                        <Form.Group>
                                            <Form.Label htmlFor="phone">Phone *</Form.Label>
                                            <Form.Control type="number" name="phone" pattern="\d{10}" maxLength="10" placeholder={"Phone"}
                                                onChange={handleChange} className={`${validated ? '' : "border-primary"}`} required
                                            />
                                            <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={6} className="mt-3">
                                        <Form.Group>
                                            <Form.Label htmlFor="phone">Email *</Form.Label>
                                            <Form.Control type="email" name="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                                placeholder={"Email"}
                                                onChange={handleChange} className={`${validated ? '' : "border-primary"}`} required
                                            />
                                            <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={6} className="mt-3">
                                        <Form.Group>
                                            <Form.Label htmlFor="address1">Address Line 1</Form.Label>
                                            <input type="text" name="address1" onChange={handleAddress}
                                                placeholder={"Address Line 1"}
                                                className="w-100 p-2 border border-primary"
                                                style={{ borderRadius: '5px' }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={6} className="mt-3">
                                        <Form.Label htmlFor="address2">Address Line 2</Form.Label>
                                        <input type="text" name="address2" onChange={handleAddress}
                                            placeholder={"Address Line 2"}
                                            className="w-100 p-2 border border-primary"
                                            style={{ borderRadius: "5px" }}
                                        />
                                    </Col>
                                    <Form.Group style={{ position: 'relative' }} className="col-sm-6 form-group mt-3">
                                        <Form.Label>Select Country</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="autocomplete-state"
                                            className="w-100 p-2 border border-primary"
                                            value={vendorCountry}
                                            name="country"
                                            placeholder="Select Country"
                                            onChange={handleInputChangeCountry}
                                            onClick={() => setIsOpenVendorCountry(true)}
                                            style={{ borderRadius: '5px' }}
                                        />
                                        <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>

                                        {isOpenVendorCountry && (
                                            <>
                                                <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                                    {
                                                        countries.length > 0 ? (
                                                            filteredCountryOptions.length > 0 ? (
                                                                filteredCountryOptions.map((option, i) => (
                                                                    <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleOptionClickCountry(option.country_name)}>
                                                                        {option.country_name}
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <div className="mt-2">No match found</div>
                                                            )
                                                        ) : (
                                                            <div className="mt-2">Loading . . . . </div>
                                                        )
                                                    }
                                                </div>
                                            </>
                                        )}
                                    </Form.Group>
                                    <Form.Group style={{ position: 'relative' }} className="col-sm-6 form-group mt-3">
                                        <Form.Label>Select State</Form.Label>
                                        <input type="text" id="autocomplete-state" className="w-100 p-2 border border-primary" style={{ borderRadius: "5px" }} value={vendorState} name="state"
                                            placeholder="Select State" onChange={handleInputChange} onClick={() => setIsOpenVendorState(true)} required
                                        />
                                        {/* <Form.Control.Feedback type="invalid">required</Form.Control.Feedback> */}

                                        {isOpenVendorState && (
                                            <>
                                                <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                                    {
                                                        states.length > 0 ? (
                                                            filteredOptions.length > 0 ? (
                                                                filteredOptions.map((option, i) => (
                                                                    <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleOptionClick1(option.state_name)}>
                                                                        {option.state_name}
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <div className="mt-2">No match found</div>
                                                            )
                                                        ) : (
                                                            <div className="mt-2">Select country first </div>
                                                        )
                                                    }
                                                </div>
                                            </>
                                        )}
                                    </Form.Group>
                                    <Form.Group style={{ position: 'relative' }} className="col-sm-6 form-group">
                                        <Form.Label>Select City</Form.Label>
                                        <input type="text" id="autocomplete-state" className="w-100 p-2 border border-primary" style={{ borderRadius: "5px" }} value={vendorCity} name="city"
                                            placeholder="Select City" onChange={handleInputChangeCity} onClick={() => setIsOpenVendorCity(true)}
                                        />
                                        {isOpenVendorCity && (
                                            <>
                                                <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                                    {
                                                        cities.length > 0 ? (
                                                            filteredCityOptions.length > 0 ? (
                                                                filteredCityOptions.map((option, i) => (
                                                                    <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleOptionClickCity(option.city_name)}>
                                                                        {option.city_name}
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <div className="mt-2">No match found</div>
                                                            )
                                                        ) : (
                                                            <div className="mt-2">Select state first </div>
                                                        )
                                                    }
                                                </div>
                                            </>
                                        )}
                                    </Form.Group>
                                    <Col sm={6} className="">
                                        <Form.Group>
                                            <Form.Label htmlFor="pinCode">Pincode / Zipcode</Form.Label>
                                            <Form.Control type="number" name="pinCode"
                                                placeholder={"Pincode/Zipcode"} onChange={handleAddress} className="border-primary"
                                            />
                                            <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <div className="my-3">
                                        <hr />
                                        <h4 className="text-dark">Contact Person</h4>
                                        <Form.Check.Input type="radio" name="contactperson" className="border border-primary"
                                            onClick={() => setShowContactPerson(true)} defaultChecked checked={showContactPerson === true} style={{ cursor: "pointer" }}
                                        />
                                        <label className="fs-5 text-primary ms-1" onClick={() => setShowContactPerson(true)} style={{ cursor: "pointer", fontSize: "0.7rem" }}>
                                            Yes
                                        </label>

                                        <Form.Check.Input type="radio" name="contactperson" className="border border-primary ms-5"
                                            onClick={() => setShowContactPerson(false)} checked={showContactPerson === false} style={{ cursor: "pointer" }}
                                        />
                                        <label className="fs-5 text-primary ms-1" onClick={() => setShowContactPerson(false)} style={{ cursor: "pointer", fontSize: "0.7rem" }}>
                                            No
                                        </label>
                                        {
                                            showContactPerson && (
                                                <Row>
                                                    <Col sm={6}>
                                                        <Form.Label>Name</Form.Label>
                                                        <input type="text" className="w-100 p-2 border border-primary" name="name" style={{ borderRadius: "5px" }} onChange={handlePerson} placeholder="Name" />
                                                    </Col>
                                                    <Col sm={6}>
                                                        <Form.Label>Email</Form.Label>
                                                        <input type="email" className="w-100 p-2 border border-primary" name="email" onChange={handlePerson} style={{ borderRadius: "5px" }} placeholder="Email" />
                                                    </Col>
                                                    <Col sm={6} className="mt-2">
                                                        <Form.Label>Phone</Form.Label>
                                                        <input type="number" className='w-100 p-2 border border border-primary' pattern="\d{10}" maxLength={10} name="phone" onChange={handlePerson} style={{ borderRadius: "5px" }} placeholder="Phone No." />
                                                    </Col>
                                                    <Col sm={6} className="mt-2">
                                                        <Form.Label>Alternative Phone</Form.Label>
                                                        <input type="number" className='w-100 p-2 border border-primary' pattern="\d{10}" maxLength="10" name="altPhone" onChange={handlePerson} style={{ borderRadius: "5px" }} placeholder="Alternative Phone No." />
                                                    </Col>
                                                    <Col sm={6} className="mt-2">
                                                        <Form.Label>Mobile</Form.Label>
                                                        <input type="number" className='w-100 p-2 border border-primary' pattern="\d{10}" maxLength={10} name="mobile" onChange={handlePerson} style={{ borderRadius: "5px" }} placeholder="Mobile No." />
                                                    </Col>
                                                    <Col sm={6} className="mt-2">
                                                        <Form.Label>Alternative Mobile</Form.Label>
                                                        <input type="number" className='w-100 p-2 border border-primary' pattern="\d{10}" maxLength="10" name="altMobile" onChange={handlePerson} style={{ borderRadius: "5px" }} placeholder="Alternative Mobile No." />
                                                    </Col>
                                                    <Col sm={6} className="mt-2">
                                                        <Form.Label>Address 1</Form.Label>
                                                        <input type="text" className="w-100 p-2 border border-primary" name="address1" onChange={handlePerson} placeholder="Addresss 1" style={{ borderRadius: "5px" }} />
                                                    </Col>
                                                    <Col sm={6} className="mt-2">
                                                        <Form.Label>Address 2</Form.Label>
                                                        <input type="text" className="w-100 p-2 border border-primary" name="address2" onChange={handlePerson} placeholder="Address 2" style={{ borderRadius: "5px" }} />
                                                    </Col>
                                                    <Col sm={6} className="mt-3">
                                                        <Form.Group style={{ position: 'relative' }}>
                                                            <Form.Label>Select Country</Form.Label>
                                                            <input type="text" id="autocomplete-state" className="w-100 p-2 border border-primary" value={personCountry} name="personCountry"
                                                                placeholder="Select Country" onChange={handleChangePersonCountry} onClick={() => setIsOpenPersonCountry(true)} style={{ borderRadius: "5px" }}
                                                            />

                                                            {isOpenPersonCountry && (
                                                                <>
                                                                    <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                                                        {
                                                                            personCountries.length > 0 ? (
                                                                                filteredPersonCountries.length > 0 ? (
                                                                                    filteredPersonCountries.map((option, i) => (
                                                                                        <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleClickPersonCountry(option.country_name)}>
                                                                                            {option.country_name}
                                                                                        </div>
                                                                                    ))
                                                                                ) : (
                                                                                    <div className="mt-2">No match found</div>
                                                                                )
                                                                            ) : (
                                                                                <div className="mt-2">Loading . . . . </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </>
                                                            )}
                                                        </Form.Group>
                                                    </Col>

                                                    <Col sm={6} className="mt-3">
                                                        <Form.Group style={{ position: 'relative' }}>
                                                            <Form.Label>Select State</Form.Label>
                                                            <input type="text" id="autocomplete-state" className="w-100 m-0 p-2 border border-primary" value={personState} name="personState"
                                                                placeholder="Select State" onChange={handleChangePersonState} onClick={() => setIsOpenPersonState(true)} style={{ borderRadius: "5px" }}
                                                            />

                                                            {isOpenPersonState && (
                                                                <>
                                                                    <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                                                        {
                                                                            personStates.length > 0 ? (
                                                                                filteredPersonStates.length > 0 ? (
                                                                                    filteredPersonStates.map((option, i) => (
                                                                                        <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleClickPersonState(option.state_name)}>
                                                                                            {option.state_name}
                                                                                        </div>
                                                                                    ))
                                                                                ) : (
                                                                                    <div className="mt-2">No match found</div>
                                                                                )
                                                                            ) : (
                                                                                <div className="mt-2">Select country first </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </>
                                                            )}
                                                        </Form.Group>
                                                    </Col>

                                                    <Col sm={6} className="mt-3">
                                                        <Form.Group style={{ position: 'relative' }}>
                                                            <Form.Label>Select City</Form.Label>
                                                            <input type="text" id="autocomplete-state" className="w-100  p-2 border border-primary" value={personCity} name="personCountry"
                                                                placeholder="Select City" onChange={handlechangePersonCity} onClick={() => setIsOpenPersonCity(true)} style={{ borderRadius: "5px" }}
                                                            />

                                                            {isOpenPersonCity && (
                                                                <>
                                                                    <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                                                        {
                                                                            personCities.length > 0 ? (
                                                                                filteredPersonCities.length > 0 ? (
                                                                                    filteredPersonCities.map((option, i) => (
                                                                                        <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleClickPersonCity(option.city_name)}>
                                                                                            {option.city_name}
                                                                                        </div>
                                                                                    ))
                                                                                ) : (
                                                                                    <div className="mt-2">No match found</div>
                                                                                )
                                                                            ) : (
                                                                                <div className="mt-2">Select state first </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={6} className="mt-3">
                                                        <Form.Label htmlFor="address">Pinocde/Zipcode</Form.Label>
                                                        <Form.Control type="number" name="pinCode"
                                                            placeholder={"Pincode/Zipcode"} onChange={handlePerson} className="border-primary"
                                                        />
                                                    </Col>
                                                </Row>
                                            )
                                        }
                                    </div>
                                </Row>
                            )
                        }
                    </div>
                    <div className="bg-white mb-2 px-3 py-2 rounded text-dark" style={{ minHeight: "3rem" }}>
                        <Row className="mb-3">
                            <Col sm={1} className="px-4 mt-4">
                                <button type="submit" className="p-0 bg-none border-0 ms-3">
                                    <span className="m-0 p-0 bg-primary py-2 px-4 text-white" onMouseEnter={handleAddress} onMouseOver={handleAddress} style={{ borderRadius: "5px" }}>Create</span>
                                </button>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </Row>
            {loader}
        </div>
    )
}

export default CreateVendor;
