import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import PreviewComponent from "./preview-component";

const PurchasePreview = ({ addedtax, show, setShow, form, firmDetails, firmAdd, clientDetails, clientAdd, items, currency, terms }) => {

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const breakPoint = 3000;
    const componentRef = useRef();

    useEffect(() => {
        const handleResize = setScreenWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        }
    });

    return (
        <>
            <Modal centered size={screenWidth < breakPoint ? 'lg' : 'xl'} show={show} onHide={() => setShow(false)}>
                {/* <Modal.Header>
        <ReactToPrint
          trigger={() => (
            <Row className="bg-primary rounded mx-3 py-2 ps-2 pe-3 my-2 text-white" style={{cursor: "pointer"}}>
              <Col sm={9}>Print Invoice</Col>
              <Col sm={1} className="text-end">
                <Tooltip title="Print">
                  <Link to="#" className="py-1 px-3 text-white rounded me-2">
                    <BsPrinterFill style={{ fontSize: "1rem", cursor: "pointer" }}/>
                  </Link>
                </Tooltip>
              </Col>
            </Row>
          )}
          content={() => componentRef.current}
        />
        </Modal.Header> */}
                <Modal.Body ref={componentRef}>
                    <PreviewComponent addedtax={addedtax} form={form} firmDetails={firmDetails} firmAdd={firmAdd} clientDetails={clientDetails} clientAdd={clientAdd} items={items} currency={currency} terms={terms} />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PurchasePreview;