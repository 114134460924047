import React from 'react';
import { useState } from 'react';
import { Col } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { MdContentCopy } from 'react-icons/md';
import {
    EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, RedditIcon,
    RedditShareButton, TelegramIcon, TelegramShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton
} from 'react-share';
import { ErrorToast, SuccessToast } from '../../components/toast';

const ShareModal = ({ showShare, setShowShare, invoiceId }) => {
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                SuccessToast("Copied to Clipboard");
                setShowShare(false);
            })
            .catch((error) => {
                ErrorToast("Unable to copy clipboard");
            });
    };

    const path = `${window.location.protocol}/${window.location.hostname}/invoice/${invoiceId}`;

    return (
        <div>
            <Modal centered size='md' show={showShare} onHide={() => setShowShare(false)} style={{ height: "100vh" }}>
                <Modal.Body>
                    <Row className='pe-2'>
                        <Col sm={2} className='text-center ms-3 mb-2  py-3' onClick={() => copyToClipboard(path)} style={{ width: "13%", background: "#7f7f7f", cursor: "pointer", borderRadius: "5px" }}>
                            <MdContentCopy className="fs-1 text-white" />
                        </Col>

                        <Col sm={2}>
                            <FacebookShareButton url={path}>
                                <FacebookIcon className='mb-2' borderRadius={5} />
                            </FacebookShareButton>
                        </Col>

                        <Col sm={2}>
                            <WhatsappShareButton url={path}>
                                <WhatsappIcon className='mb-2' borderRadius={5} />
                            </WhatsappShareButton>
                        </Col>

                        <Col sm={2}>
                            <TwitterShareButton url={path}>
                                <TwitterIcon className='mb-2' borderRadius={5} />
                            </TwitterShareButton>
                        </Col>

                        <Col sm={2}>
                            <RedditShareButton url={path}>
                                <RedditIcon className='mb-2' borderRadius={5} />
                            </RedditShareButton>
                        </Col>

                        <Col sm={2}>
                            <TelegramShareButton url={path}>
                                <TelegramIcon className='mb-2' borderRadius={5} />
                            </TelegramShareButton>
                        </Col>

                        <Col sm={2}>
                            <LinkedinShareButton url={path}>
                                <LinkedinIcon className='mb-2' borderRadius={5} />
                            </LinkedinShareButton>
                        </Col>

                        <Col sm={2}>
                            <EmailShareButton url={path}>
                                <EmailIcon className='mb-2' borderRadius={5} />
                            </EmailShareButton>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </div >
    )
}

export default ShareModal
