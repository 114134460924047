import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, Popover } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

// import Loader from "../loader/loader";
// import PageTitle from "../page-header/PageHeader";

import CustomTable from "./CustomTable";
// import { loadSingleRole } from "./roleApis";
import AntLoader from "../../../components/loader/AntLoader";
import { loadSingleRole } from "../../Hook/roleHooks";
// import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";

//PopUp

const DetailRole = () => {
	const { id } = useParams();
	let location = useHistory();

	//dispatch
	const dispatch = useDispatch();
	const [role, setRole] = useState(null);
	const orgName = localStorage.getItem('orgName');
	//Delete Supplier
	const onDelete = () => {
		try {
			setVisible(false);
			toast.warning(`role Name : ${role.rolename} is removed `);
			return location.push("/admin/dashboard");
		} catch (error) {
			console.log(error.message);
		}
	};
	// Delete Supplier PopUp
	const [visible, setVisible] = useState(false);

	const handleVisibleChange = (newVisible) => {
		setVisible(newVisible);
	};

	useEffect(() => {
		loadSingleRole(id).then((d) => setRole(d.data.data));
	}, [id]);

	const isLogged = Boolean(localStorage.getItem("isLogged"));
	console.log("role", role);
	// if (!isLogged) {
	// 	return <Navigate to={"/admin/auth/login"} replace={true} />;
	// }

	return (
		<div className="mx-4">
			{/* <PageTitle title=' Back  ' /> */}

			{/* <UserPrivateComponent permission={"readSingle-role"}> */}
			<div className='mr-top'>
				{role ? (
					<Fragment key={role.id}>
						<Card bordered={false}>
							<div className='d-flex justify-content-between align-items-center mb-5'>
								<h5>
									<i className='bi bi-person-lines-fill'></i>
									<span className='mr-left'>
										Role : {role.name}
									</span>
								</h5>
								<div className='text-end'>
									{/* <UserPrivateComponent permission={"update-role"}> */}
									<Link
										className='m-2'
										to={`/${orgName}/dashboard/role/permit/${role._id}`}
										state={{ data: role }}>
										<Button
											type='primary'
											shape='round'
											icon={<EditOutlined />}>
											{" "}
											New Permission{" "}
										</Button>
									</Link>
									{/* </UserPrivateComponent> */}
									{/* <UserPrivateComponent permission={"delete-role"}> */}
									<Popover
										className='m-2'
										content={
											<a onClick={onDelete}>
												<Button disabled={true} type='primary' danger>
													Yes Please !
												</Button>
											</a>
										}
										title='Are you sure you want to delete ?'
										trigger='click'
										visible={visible}
										onVisibleChange={handleVisibleChange}>
										<Button
											disabled={true}
											type='danger'
											shape='round'
											icon={<DeleteOutlined />}></Button>
									</Popover>
									{/* </UserPrivateComponent> */}
								</div>
							</div>
							<CustomTable role={role?.rolePermission} />
						</Card>
					</Fragment>
				) : (
					<AntLoader />
					// <Loader />
				)}
			</div>
			{/* </UserPrivateComponent> */}
		</div>
	);
};

export default DetailRole;
