import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { getInvoiceById, hardDeleteInvoice, softDeleteInvoice } from '../Hook/invoiceHooks'
import useFullPageLoader from '../Hook/useFullPageLoader'
import { ErrorToast, SuccessToast, WarningToast } from '../../components/toast'
import { addRecentActivity } from '../Hook/recentActivityHooks'

const DeleteInvoice = ({ getAllRecentActivities, showDelete, setShowDelete, id, selected, getAllInvoices }) => {

    const [loader, showLoader, hideLoader] = useFullPageLoader();

    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [invoiceDetails, setInvoiceDetails] = useState({});

    const datetimeString = new Date();
    const dateObject = new Date(datetimeString);

    const formattedDate = `${dateObject.getDate()}/${dateObject.getMonth() + 1}/${dateObject.getFullYear()}`;
    const formattedTime = dateObject.toLocaleTimeString();

    useEffect(() => {
        getInvoiceById(id).then((response) => {
            response.status === 200 ? setInvoiceDetails(response?.data?.data[0]) : setInvoiceDetails({});
        }).catch(() => setInvoiceDetails({}));
    }, [id]);

    // console.log("id", invoiceNumber);

    const handleDeleteInvoice = () => {
        softDeleteInvoice(id).then((response) => {
            if (response.status === 200) {
                SuccessToast("Invoice Deleted SUccessfully");

                const activityForm = {
                    module: ["invoice", 'firm', 'client'],
                    entityId: [invoiceDetails?.firm?.firmID, invoiceDetails?.client?.client_id,],
                    activity: "Invoice Deleted",
                    activityDesc: `Invoice ${invoiceDetails?.invoiceNumber} Deleted successfully`,
                    createdDate: formattedDate,
                    createdTime: formattedTime,
                    userId: localStorage.getItem('userId')
                };

                addRecentActivity(activityForm).then((response) => {
                    if (response.status === 201) getAllRecentActivities()
                })
                getAllInvoices();
            } else {
                ErrorToast('Unable to delete invoice');
            }
        }).catch((err) => {
            err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Something went wrong');
        });
    };

    const handleDeleteInvoicePermanently = () => {
        hardDeleteInvoice(id).then((response) => {
            if (response.status === 200) {

                const activityForm = {
                    module: ["invoice", 'firm', 'client'],
                    entityId: [invoiceDetails?.firm?.firmID, invoiceDetails?.client?.client_id,],
                    activity: "Invoice Deleted Permanently",
                    activityDesc: `Invoice ${invoiceDetails?.invoiceNumber} Deleted Permanently`,
                    createdDate: formattedDate,
                    createdTime: formattedTime,
                    userId: localStorage.getItem('userId')
                };

                addRecentActivity(activityForm).then((response) => response.status === 201 && getAllRecentActivities());

                SuccessToast("Invoice Deleted Permanently");
                getAllInvoices();
            } else {
                ErrorToast('Unable to delete invoice');
            }
        }).catch((err) => {
            err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Something went wrong');
        });
    }

    const deleteInvoice = () => {
        showLoader();
        selected === "Deleted" ? handleDeleteInvoicePermanently() : handleDeleteInvoice();
        setShowDelete(false);
        hideLoader();
    }

    return (
        <Modal centered show={showDelete} onHide={() => setShowDelete(false)}>
            <Modal.Body className="my-2">
                <Row className="mb-2 ms-1">
                    <Col sm={12} className=" text-center px-2 m-0 p-0 fs-3">Delete Invoice</Col>
                    <Col sm={12} className="text-secondary text-center px-2 m-0 p-0">
                        {
                            selected === "Deleted"
                                ? 'You want to delete the invoice permanently ?'
                                : 'You want to delete the invoice ?'
                        }
                    </Col>
                </Row>
                <div className="ms-2 w-100" style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }} >
                    <div
                        className="py-1 px-5 me-2 bg-danger text-white text-center text-p border border-danger"
                        onClick={() => deleteInvoice()}
                        style={{ borderRadius: "4px", cursor: "pointer" }}
                    >
                        Yes
                    </div>
                    <div className="bg-white text-primary py-1 px-5 border border-primary text-center"
                        onClick={() => setShowDelete(false)}
                        style={{ borderRadius: "5px", cursor: "pointer" }}
                    >
                        No
                    </div>
                </div>
            </Modal.Body>
            {loader}
        </Modal >
    )
}

export default DeleteInvoice
