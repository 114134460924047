import React, { useState } from 'react'
import { Col, Modal, Tooltip } from 'react-bootstrap'
import { AiOutlineDelete } from 'react-icons/ai';
import { MdAddCircle, MdClear } from 'react-icons/md';
import { Link } from 'react-router-dom';

const RequestEditPurchase = ({ show, setShow, items }) => {

    const [data, setData] = useState('');

    const handleChange = (e) => {
        setData(...data, e.target.value);
    };

    return (
        <div>
            <Modal centered size="xl" show={show} onHide={() => setShow(false)}>
                <Modal.Header className="bg-primary">
                    <Col sm={11} className="text-white"> <h4 className="text-white">Request to Edit</h4> </Col>
                    <Col sm={1} className="text-end text-white">
                        <MdClear className="fs-4" onClick={() => setShow(false)} style={{ cursor: "pointer" }} />
                    </Col>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <table className="w-100">
                            <thead className="bg-primary p-2 text-white" style={{ borderBottom: "1px solid #c8c9ca", borderRadius: "10px 0 10px 0" }}>
                                <th className="ps-2 w-50" style={{ borderRadius: "10px 0 0 0" }}>Item</th>
                                <th style={{ width: "15%" }}>Quantity</th>
                                <th style={{ width: "15%" }}>Price</th>
                                <th style={{ width: "15%" }}>Amount</th>
                                <th className="pe-2" style={{ borderRadius: "0 10px 0 0" }}>Actions</th>
                            </thead>
                            <tbody>
                                {
                                    items.map((data, index) => (
                                        <>
                                            <tr key={index}>
                                                <td>
                                                    <input type="text" className="w-75 my-2" value="value" onChange={(e) => setData(e.target.value)} style={{ border: "none", borderBottom: "none" }} required />
                                                </td>
                                                <td>
                                                    <input type="number" name="quantity" className="w-75 my-2" value={data.quantity} placeholder="Quantity" style={{ border: "none", borderBottom: "1px solid #fff" }} required />
                                                </td>
                                                <td>
                                                    <input type="number" name="unitPrice" className="w-75 my-2" value={data.unitPrice} placeholder="Price" style={{ border: "none", borderBottom: "1px solid #fff" }} required />
                                                </td>
                                                <td>
                                                    <span type="number" name="amount" className="w-75 my-2" placeholder="Amount" style={{ border: "none", borderBottom: "1px solid #fff" }}>
                                                        12
                                                    </span>
                                                </td>
                                                <td className=" text-center"><AiOutlineDelete className="fs-3 text-danger" style={{ cursor: "pointer" }} /></td>
                                            </tr>
                                            <tr key={index} className="pb-2" style={{ borderBottom: "1px solid #c8c9ca" }}>
                                                <input type="text" className="mb-2 w-75" value={data.description} placeholder="Item Description" style={{ border: "none", borderBottom: "1px solid #fff" }} />
                                            </tr>
                                        </>
                                    ))
                                }
                                <div>
                                    <Tooltip title="Add new row">
                                        <Link to="#">
                                            <MdAddCircle className="fs-3 mt-1 text-primary" style={{ cursor: "pointer" }} />
                                        </Link>
                                    </Tooltip>
                                </div>
                            </tbody>
                        </table>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default RequestEditPurchase