import React, { useEffect, useState } from "react";
import Index from "../views/dashboard/index";
import { Switch, Route } from "react-router-dom";

// invoice
import InvoiceAdd from "../views/dashboard/app/invoice/add-invoice";
import InvoiceList from "../views/dashboard/app/invoice/invoice-list";
import InvoiceDetails from "../views/dashboard/app/invoice/invoice-details";

// firm
import AddFirm from "../views/dashboard/app/firm/add-firm";
import FirmList from "../views/dashboard/app/firm/firm-list";

//client
import AddClient from "../views/dashboard/app/client/add-client";
import AboutClient from "../views/dashboard/app/client/about-client";

// Lead
import AddLead from "../views/dashboard/app/lead/add-lead";
import LeadDetails from "../views/dashboard/app/lead/lead-details";
import Newleadlist from "../views/dashboard/app/lead/newleadlist";

//User
import AddUser from "../views/dashboard/app/settings/user/add-user";
import UpdateUserProfile from "../views/dashboard/app/settings/user/update-user-profile";
// import UserProfile from "../views/dashboard/app/user-profile";

// import AllUsers from "../views/dashboard/app/settings/user/all-users";

// organization
import AddOrg from "../views/dashboard/app/settings/organization/add-org";
import UpdateOrg from "../views/dashboard/app/settings/organization/updateOrg";
import OrganizationProfile from "../views/dashboard/app/settings/organization/organization-profile";
//
import AddPipeline from "../views/dashboard/app/settings/pipeline/add-pipeline";
import PipelineList from "../views/dashboard/app/settings/pipeline/pipeline-list";

//Tax
import Addtax from "../views/dashboard/app/tax/add-tax";

// Payroll
import Payslips from "../views/dashboard/app/payroll/payslips";
// import Attendance from "../views/dashboard/app/payroll/attendance";
import Form16 from "../views/dashboard/app/payroll/form16";
import SalaryAdavance from "../views/dashboard/app/payroll/salary-adavance";
import Reimbursment from "../views/dashboard/app/payroll/reimbursment/reimbursment";
import PastReimburs from "../views/dashboard/app/payroll/reimbursment/pastReimburs";

import { TransitionGroup, CSSTransition } from "react-transition-group";
import ClientList from "../views/dashboard/app/client/client-list";
import UpdateLead from "../views/dashboard/app/lead/updateLead";
import Addroles from "../views/dashboard/app/settings/roles/addroles";
import AllEpmloyees from "../views/dashboard/app/payroll/employees/allEpmloyees";
import Editemployee from "../views/dashboard/app/payroll/employees/edit-employee";
import EditFirm from "../views/dashboard/app/firm/edit-firm";
import AboutFirm from "../views/dashboard/app/firm/about-firm";
import EditInvoice from "../views/dashboard/app/invoice/edit-invoice";
import FirmInvoice from "../views/dashboard/app/firm/firm-invoice";
import EditClient from "../views/dashboard/app/client/edit-client";
import EditCopiedInvoice from "../views/dashboard/app/invoice/edit-copied-invoice";
import AllVendors from "../views/dashboard/app/vendor/all-vendors";
import CreateVendor from "../views/dashboard/app/vendor/create-vendor";
import UpdateVendor from "../views/dashboard/app/vendor/update-vendor";
import AboutVendor from "../views/dashboard/app/vendor/about-vendor";
import AllPurchase from "../views/dashboard/app/purchase/all-purchase";
import CreatePurchase from "../views/dashboard/app/purchase/create-purchase";
import PurchaseDetails from "../views/dashboard/app/purchase/purchase-details";
import ErrorPage from "../views/dashboard/app/purchase/ErrorPage";
import TaxDetails from "../views/dashboard/app/tax/tax-details";
import widgetcard from "../views/dashboard/widget/widgetcard";
import widgetchart from "../views/dashboard/widget/widgetchart";
import Widgetbasic from "../views/dashboard/widget/widgetbasic";
import EditPurchase from "../views/dashboard/app/purchase/update-purchase";
import UserPrivateRoute from "./private/UserPrivateRoute";
import UserList from "../views/dashboard/app/hr/user/user";
import GetAllUsers from "../views/dashboard/app/hr/user/GetAllUser";
import Department from "../views/dashboard/app/hr/department/Department";
import DetailDepartment from "../views/dashboard/app/hr/department/DetailsDepartment";
import Designation from "../views/dashboard/app/hr/designation/designation";
import DetailDesignation from "../views/dashboard/app/hr/designation/detailDesignation";
import UpdateDesignation from "../views/dashboard/app/hr/designation/updateDesignation";
import RoleList from "../views/dashboard/app/hr/role/role";
import DetailRole from "../views/dashboard/app/hr/role/DetailsRole";
import AddPermission from "../views/dashboard/app/hr/role/AddPermission";
import AddAnnouncement from "../views/dashboard/app/hrm/announcement/AddAnnouncement";
import LeavePolicy from "../views/dashboard/app/hrm/leavePolicy/LeavePolicy";
import DetailLeavePolicy from "../views/dashboard/app/hrm/leavePolicy/DetailsLeavePolicy";
import EmploymentStatus from "../views/dashboard/app/hrm/employmentStatus/EmploymentStatus";
import DetailEmploymentStatus from "../views/dashboard/app/hrm/employmentStatus/EmploymentStatusDetails";
import DetailStaff from "../views/dashboard/app/hr/user/detailsStaff";
import Attendance from "../views/dashboard/app/hrm/attendance/AddAttendance";
import PayslipList from "../views/dashboard/app/hrm/payroll/PayslipList";
import CalculatePayroll from "../views/dashboard/app/hrm/payroll/CalculatePayroll";
import AddShift from "../views/dashboard/app/hrm/shift/AddShift";
import DetailShift from "../views/dashboard/app/hrm/shift/ShiftDetails";
import Leave from "../views/dashboard/app/hrm/leave/Leave";
import DetailLeave from "../views/dashboard/app/hrm/leave/DetailLeave";
import GetAllLeaves from "../views/dashboard/app/hrm/leave/GetAllLeaves";
import UserLeave from "../views/dashboard/app/hrm/leave/UserLeave";
import Account from "../views/dashboard/app/hrm/account/account";
import DetailAccount from "../views/dashboard/app/hrm/account/detailAccount";
import TrialBalance from "../views/dashboard/app/hrm/account/trialBalance";
import BalanceSheet from "../views/dashboard/app/hrm/account/balanceSheet";
import IncomeStatement from "../views/dashboard/app/hrm/account/incomeStatement";
import Transaction from "../views/dashboard/app/hrm/transaction/transaction";
import DetailTransaction from "../views/dashboard/app/hrm/transaction/detailTransaction";
import AddTransaction from "../views/dashboard/app/hrm/transaction/AddTransaction";
import Project from "../views/dashboard/app/hrm/project/project";
import AddProject from "../views/dashboard/app/hrm/project/AddProject";
import UpdateProject from "../views/dashboard/app/hrm/project/UpdateProject";
import UpdateStatus from "../views/dashboard/app/hrm/project/UpdateStatus";
import UserAttendance from "../views/dashboard/app/hrm/attendance/UserAttendance";
import Announcement from "../views/dashboard/app/hrm/announcement/Announcement";
import Dashboard from "../views/dashboard/app/hrm/Dashboard/Graph/Dashboard";
import WeeklyHoliday from "../views/dashboard/app/hrm/weeklyHoliday/WeeklyHoliday";
import DetailWeeklyHoliday from "../views/dashboard/app/hrm/weeklyHoliday/DetailsWeeklyHoliday";
import PublicHoliday from "../views/dashboard/app/hrm/publicHoliday/PublicHoliday";
import DetailPublicHoliday from "../views/dashboard/app/hrm/publicHoliday/DetailsPublicHoliday";
import AllUsers from "../views/dashboard/app/settings/user/all-users";
import { getUserById } from "../views/dashboard/app/Hook/userHooks";
import UserProfile from "../views/dashboard/app/settings/user/user-profile";
// import UserProfile from "../views/dashboard/app/user-profile";
// import jwt_decode from "jwt-decode";

const DefaultRouter = () => {

  const orgName = localStorage.getItem("orgName");
  const userId = localStorage.getItem('userId');
  const [userPermissions, setUserPermissions] = useState([]);
  const token = localStorage.getItem("access-token");
  const permissions = ['create-user', 'getAll-users']

  useEffect(() => {
    getUserById(userId).then((response) => {
      if (response.status === 200) setUserPermissions(response?.data?.data?.permissions);
      else setUserPermissions([]);
    }).catch(() => setUserPermissions([]));
  }, [userId]);

  const hasPermissionData = (data) => {
    // const permissions = jwt_decode(token)?.permissions;
    const MatchPermission = permissions.filter((item) => item === data);
    return MatchPermission.length > 0 ? true : false;
  };

  const peremitted = (module, action) => {
    const permitted = userPermissions.some((permission) => permission.module === module && permission.action.includes(action));
    return permitted;
  };

  return (
    <TransitionGroup>
      <CSSTransition classNames="fadein" timeout={300}>
        <Switch>
          <Route path={`/${orgName}/dashboard`} exact component={Index} />

          {/* Invoice */}
          <Route path={`/${orgName}/dashboard/invoice/add-invoice`} exact component={InvoiceAdd} />
          <Route path={`/${orgName}/dashboard/Invoice/invoice-list`} exact component={InvoiceList} />
          <Route path={`/${orgName}/dashboard/invoice/invoice-details/:id`} exact component={InvoiceDetails} />
          <Route path={`/${orgName}/dashboard/invoice/add-invoice/:id`} exact component={InvoiceAdd} />
          <Route path={`/${orgName}/dashboard/invoice/edit/:editId`} exact component={EditInvoice} />
          <Route path={`/${orgName}/dashboard/invoice/copiedInvoice`} exact component={EditCopiedInvoice} />

          {/* Firm */}
          <Route path={`/${orgName}/dashboard/firm/add-firm`} exact component={AddFirm} />
          <Route path={`/${orgName}/dashboard/firm/firm-list`} exact component={FirmList} />
          <Route path={`/${orgName}/dashboard/firm/add-firm`} exact component={AddFirm} />
          <Route path={`/${orgName}/dashboard/firm/edit-firm/:id`} exact component={EditFirm} />
          <Route path={`/${orgName}/dashboard/firm/about-firm/:id`} exact component={AboutFirm} />
          <Route path={`/${orgName}/dashboard/firm/add-invoice/:id`} exact component={FirmInvoice} />

          {/* Client */}
          <Route path={`/${orgName}/dashboard/client/add-client`} exact component={AddClient} />
          <Route path={`/${orgName}/dashboard/client/client-list`} exact component={ClientList} />
          <Route path={`/${orgName}/dashboard/client/add-client`} exact component={AddClient} />
          <Route path={`/${orgName}/dashboard/client/update-client/:id`} exact component={EditClient} />
          <Route path={`/${orgName}/dashboard/client/about-client/:id`} exact component={AboutClient} />
          <Route path={`/${orgName}/dashboard/client/add-invoice/:id`} exact component={InvoiceAdd} />

          {/* Tax */}
          <Route path={`/${orgName}/dashboard/tax/add-tax`} exact component={Addtax} />
          <Route path={`/${orgName}/dashboard/tax/tax-details/:id`} exact component={TaxDetails} />

          {/* Vendor */}
          <Route path={`/${orgName}/dashboard/vendor/all-vendors`} exact component={AllVendors} />
          <Route path={`/${orgName}/dashboard/vendor/create-vendor`} exact component={CreateVendor} />
          <Route path={`/${orgName}/dashboard/vendor/update-vendor/:id`} exact component={UpdateVendor} />
          <Route path={`/${orgName}/dashboard/vendor/about-vendor/:id`} exact component={AboutVendor} />

          {/* purchase  */}
          <Route path={`/${orgName}/dashboard/purchase/all-purchase`} exact component={AllPurchase} />
          <Route path={`/${orgName}/dashboard/purchase/create-purchase`} exact component={CreatePurchase} />
          <Route path={`/${orgName}/dashboard/purchase/update-purchase/:editId`} exact component={EditPurchase} />
          <Route path={`/${orgName}/dashboard/purchase/purchase-details/:id`} exact component={PurchaseDetails} />
          <Route path={`/${orgName}/dashboard/purchase/copiedPurchase/`} exact component={PurchaseDetails} />

          {/* Lead */}
          <Route path={`/${orgName}/dashboard/lead/add-lead`} exact component={AddLead} />
          <Route path={`/${orgName}/dashboard/lead/lead-details/:id`} exact component={LeadDetails} />
          <Route path={`/${orgName}/dashboard/lead/update-lead/:id`} exact component={UpdateLead} />
          <Route path={`/${orgName}/dashboard/lead/lead-list`} exact component={Newleadlist} />

          {/* User */}
          <Route path={`/${orgName}/dashboard/user/add-user`} exact component={AddUser} />
          <Route path={`/${orgName}/dashboard/user/all-users`} exact component={AllUsers} />
          <Route path={`/${orgName}/dashboard/user/update-profile/:id`} exact component={UpdateUserProfile} />
          <Route path={`/${orgName}/dashboard/user/profile/:id`} exact component={UserProfile} />

          {/* Pipeline */}
          <Route path={`/${orgName}/dashboard/settings/pipeline/add-pipeline`} exact component={AddPipeline} />
          <Route path={`/${orgName}/dashboard/settings/pipeline/pipeline-list`} exact component={PipelineList} />

          {/* Settings */}
          <Route path={`/${orgName}/dashboard/settings/organization/add-org/`} exact component={AddOrg} />
          <Route path={`/${orgName}/dashboard/settings/organization/update-org/:id`} exact component={UpdateOrg} />
          <Route path={`/${orgName}/dashboard/settings/organization/organization-profile/:id`} exact component={OrganizationProfile} />
          <Route path={`/${orgName}/dashboard/settings/roles/roles`} excat component={Addroles} />

          {/* Payroll */}
          <Route path={`/${orgName}/dashboard/payroll/payslips`} exact component={Payslips} />
          <Route path={`/${orgName}/dashboard/payroll/attendance`} exact component={Attendance} />
          <Route path={`/${orgName}/dashboard/payroll/payslips/form-16`} exact component={Form16} />
          <Route path={`/${orgName}/dashboard/payroll/payslips/salary-advance`} exact component={SalaryAdavance} />
          <Route path={`/${orgName}/dashboard/payroll/reimbursment`} exact component={Reimbursment} />
          <Route path={`/${orgName}/dashboard/payroll/reimbursment/all-reimbursments`} exact component={PastReimburs} />

          {/* HR */}
          <Route path={`/${orgName}/dashboard/employees/all-employees`} exact component={UserList} />
          <Route path={`/${orgName}/dashboard/employees/edit-employee/:id`} exact component={Editemployee} />





          {/* HRMS */}
          {/* <Route path='/' component={UserPrivateRoute}>
            <Route path={`/${orgName}/dashboard/hr/user/new`} exact component={UserList} />
          </Route> */}

          {hasPermissionData("create-user") && <Route path={`/${orgName}/dashboard/hrm`} exact component={Dashboard} />}

          {hasPermissionData("create-user") && <Route path={`/${orgName}/dashboard/hr/user/new`} exact component={UserList} />}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/hr/users`} exact component={GetAllUsers} />}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/hr/users/:id`} exact component={DetailStaff} />}

          {/* Employement status */}

          {hasPermissionData("create-user") && <Route path={`/${orgName}/dashboard/employment-status`} exact component={EmploymentStatus} />}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/employment-status/:id`} exact component={DetailEmploymentStatus} />}

          {/* Department */}

          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/department`} exact component={Department} />}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/department/details`} exact component={DetailDepartment} />}

          {/* Degsignation */}

          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/designation`} exact component={Designation} />}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/designation/:id`} exact component={DetailDesignation} />}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/designation/update/:id`} exact component={UpdateDesignation} />}

          {/* Role & Permissions */}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/role`} exact component={RoleList} />}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/role/:id`} exact component={DetailRole} />}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/role/update/:id`} exact component={UpdateDesignation} />}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/role/permit/:id`} exact component={AddPermission} />}

          {/* Attendance */}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/attendance`} exact component={Attendance} />}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/attendance/user/:id`} exact component={UserAttendance} />}

          {/* Payroll */}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/payroll`} exact component={PayslipList} />}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/payroll/new`} exact component={CalculatePayroll} />}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/payroll/:id`} exact component={DetailRole} />}

          {/* Shift */}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/shift`} exact component={AddShift} />}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/shift/:id`} exact component={DetailShift} />}
          {/* {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/role/update/:id`} exact component={UpdateDesignation} />} */}


          {/* Announcements */}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/announcements`} exact component={Announcement} />}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/announcements/:id`} exact component={DetailRole} />}

          {/* Leave Policy */}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/leave-policy`} exact component={LeavePolicy} />}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/leave-policy/:id`} exact component={DetailLeavePolicy} />}

          {/* Leave */}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/leave/new`} exact component={Leave} />}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/leave/:id`} exact component={DetailLeave} />}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/leave`} exact component={GetAllLeaves} />}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/leave/user/:id`} exact component={UserLeave} />}

          {/* Holiday  */}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/holiday/week`} exact component={WeeklyHoliday} />}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/holiday/week/:id`} exact component={DetailWeeklyHoliday} />}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/holiday/public`} exact component={PublicHoliday} />}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/holiday/public/:id`} exact component={DetailPublicHoliday} />}

          {/* Accounts */}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/account`} exact component={Account} />}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/account/:id`} exact component={DetailAccount} />}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/trial-balance`} exact component={TrialBalance} />}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/balance-sheet`} exact component={BalanceSheet} />}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/income`} exact component={IncomeStatement} />}

          {/* Transaction */}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/transaction`} exact component={Transaction} />}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/transaction/:id`} exact component={DetailTransaction} />}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/add-Transaction`} exact component={AddTransaction} />}

          {/* =============== Project Management ================== */}

          {/* Project */}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/project`} exact component={Project} />}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/projet/new`} exact component={AddProject} />}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/project/:id`} exact component={UpdateProject} />}
          {hasPermissionData("getAll-users") && <Route path={`/${orgName}/dashboard/project/:id/status`} exact component={UpdateStatus} />}




          {/* <Route path='*' exact component={ErrorPage} /> */}
          {/* widget */}
          <Route path={`/${orgName}/dashboard/widget/widgetbasic`} exact component={Widgetbasic} />
          <Route path={`/${orgName}/dashboard/widget/widgetcard`} exact component={widgetcard} />
          <Route path={`/${orgName}/dashboard/widget/widgetchart`} exact component={widgetchart} />

          {/* icon */}
          {/* <Route  path={`/${orgName}/dashboard/icon/solid"`} exact  component={Solid} />
          <Route  path={`/${orgName}/dashboard/icon/outline"`} exact  component={Outline} />
          <Route  path={`/${orgName}/dashboard/icon/dual-tone"`} exact  component={DualTone} /> */}
          {/* From */}
          {/* <Route path={`/${orgName}/dashboard/form/form-element`} exact component={FormElement}/>
          <Route path={`/${orgName}/dashboard/form/form-validation`} exact component={FormValidation}/>
          <Route path={`/${orgName}/dashboard/form/form-wizard`} exact component={FormWizard}/> */}
          {/* table */}
          {/* <Route path={`/${orgName}/dashboard/table/bootstrap-table`} exact component={BootstrapTable}/>
          <Route path={`/${orgName}/dashboard/table/table-data`} exact component={TableData}/> */}
          {/*special pages */}
          {/* <Route path={`/${orgName}/dashboard/special-pages/billing`} exact component={Billing}/>
          <Route path={`/${orgName}/dashboard/special-pages/kanban`} exact component={Kanban}/>
          <Route path={`/${orgName}/dashboard/special-pages/pricing`} exact component={Pricing}/>
          <Route path={`/${orgName}/dashboard/special-pages/timeline`} exact component={Timeline}/>
          <Route path={`/${orgName}/dashboard/special-pages/calender`} exact component={Calender}/> */}
          {/* map */}
          {/* <Route  path={`/${orgName}/dashboard/map/vector"`} exact  component={Vector} />
          <Route  path={`/${orgName}/dashboard/map/google"`} exact  component={Google} /> */}
          {/* extra */}
          {/* <Route path={`/${orgName}/dashboard/extra/privacy-policy`} exact component={PrivacyPolicy}/>
          <Route path={`/${orgName}/dashboard/extra/terms-of-service`} exact component={TermsofService}/> */}
          {/*admin*/}
          {/* <Route  path={`/${orgName}/dashboard/admin/admin"`} exact  component={Admin} /> */}
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default DefaultRouter;
