import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { MdClear } from "react-icons/md";
import { getCity, getCountries, getState } from "../Hook/CustomHooks";
import "../btnstyle.css";
import 'react-phone-number-input/style.css'
import { addClient } from '../Hook/clientHooks';
import { getFirmList } from '../Hook/firmHooks';
import { ErrorToast, SuccessToast } from '../../components/toast';
import useFullPageLoader from '../Hook/useFullPageLoader';
import { addRecentActivity } from '../Hook/recentActivityHooks';

const Addclientmodal = ({ getClients, showClient, setShowClient }) => {
    const [formData, setFormData] = useState({});

    const [address, setAddress] = useState({});
    const [allFirms, setAllFirms] = useState([]);

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const [clientCountry, setClientCountry] = useState('');
    const [isOpenCountry, setIsOpenCountry] = useState(false);
    const [clientState, setClientState] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [clientCity, setClientCity] = useState('');
    const [isOpenClientCity, setIsOpenClientCity] = useState(false);

    const [personCity, setPersonCity] = useState('');
    const [isOpenPersonCity, setIsOpenPersonCity] = useState(false);
    const [personState, setPersonState] = useState('');
    const [isOpenPersonState, setIsOpenPersonState] = useState(false);
    const [personCountry, setPersonCountry] = useState('');
    const [isOpenPersonCountry, setIsOpenPersonCountry] = useState(false);

    const [personCountries, setPersonCountries] = useState([]);
    const [personStates, setPersonStates] = useState([]);
    const [personCities, setPersonCities] = useState([]);

    const [contactPerson, setContactPerson] = useState(false);
    const [person, setPerson] = useState({});
    const [selectedCountry, setSelectedtCountry] = useState('');
    const [selectedPersonCountry, setSelectedtPersonCountry] = useState('');
    const [validated, setValidated] = useState(false);

    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const orgId = localStorage.getItem("orgID");

    const datetimeString = new Date();
    const dateObject = new Date(datetimeString);

    const formattedDate = `${dateObject.getDate()}/${dateObject.getMonth() + 1}/${dateObject.getFullYear()}`;
    const formattedTime = dateObject.toLocaleTimeString();

    const firmList = () => {
        getFirmList().then((response) => {
            if (response.status === 200) {
                if (response.data.data.length > 0) {
                    setAllFirms(response.data.data);
                } else {
                    setAllFirms([]);
                }
            }
        })
    };

    useEffect(() => {
        firmList();
    }, []);

    const handleChange = (e) => {

        if (e.target.name === 'phone') {
            e.target.value = e.target.value.slice(0, 10);
            setFormData({
                ...formData,
                phone: e.target.value
            })
        }

        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
            orgId: orgId
        });
    };

    const handlePerson = (e) => {
        e.preventDefault();
        setPerson({
            ...person,
            [e.target.name]: e.target.value,
        });
        
        if (e.target.name === "pinCode") {
            if (e.target.value > selectedPersonCountry === "United States" ? 7 : 6) {
                e.target.value = e.target.value.slice(0, selectedPersonCountry === "United States" ? 7 : 6)
                setPerson({
                    ...person,
                    pinCode: e.target.value
                })
            }
        }

        if (e.target.name === 'phone' || e.target.name === 'altPhone' || e.target.name === 'mobile' || e.target.name === 'altMobile') {
            e.target.value = e.target.value.slice(0, 10);
            setPerson({
                ...person,
                [e.target.name]: e.target.value
            })
        }

        setFormData({
            ...formData,
            add: { ...address },
            contectPerson: person
        });
    };

    const handleAddress = (e) => {
        setAddress({
            ...address,
            [e.target.name]: e.target.value,
        });
        if (e.target.name === "pinCode") {
            if (e.target.value > selectedCountry === "United States" ? 7 : 6) {
                e.target.value = e.target.value.slice(0, selectedCountry === "United States" ? 7 : 6)
                setAddress({
                    ...address,
                    pinCode: e.target.value
                })
            }
        }
        setFormData({
            ...formData,
            add: { ...address }
        });
    };

    useEffect(() => {
        getCountries().then((res) => {
            setCountries(res);
        });
    }, []);

    useEffect(() => {
        if (address.country) {
            getState(address.country).then((res) => {
                setStates(res);
            });
        }
    }, [address.country]);

    useEffect(() => {
        if (address.state) {
            getCity(address.state).then((res) => {
                setCities(res);
            });
        }
    }, [address.state]);

    useEffect(() => {
        getCountries().then((res) => {
            setPersonCountries(res);
        });
    }, []);

    useEffect(() => {
        if (person.country) {
            getState(person.country).then((res) => {
                setPersonStates(res);
            });
        }
    }, [person.country]);

    useEffect(() => {
        if (person.state) {
            getCity(person.state).then((res) => {
                setPersonCities(res);
            });
        }
    }, [person.state]);

    const handleInputChangeCountry = (e) => {
        setClientCountry(e.target.value);

        setIsOpenCountry(true);
        setAddress({
            ...address,
            country: e.target.value
        })
    };

    const handleOptionClickCountry = (i) => {
        setClientCountry(i)
        setAddress({
            ...address,
            country: i
        })
        setIsOpenCountry(false);
    };

    const handleInputChange = (e) => {
        setClientState(e.target.value);

        setIsOpen(true);
        setAddress({
            ...address,
            state: e.target.value
        })
    };

    const handleOptionClick1 = (i) => {
        setClientState(i)
        setAddress({
            ...address,
            state: i
        })
        setIsOpen(false);
    };

    const handleInputChangeCity = (e) => {
        setClientCity(e.target.value);

        setIsOpenClientCity(true);
        setAddress({
            ...address,
            city: e.target.value
        })
    };

    const handleOptionClickCity = (i) => {
        setClientCity(i)
        setAddress({
            ...address,
            city: i
        })
        setIsOpenClientCity(false);
    };

    const filteredCountryOptions = countries.filter((option) =>
        (option.country_name).toLowerCase().includes(clientCountry.toLowerCase())
    );

    const filteredOptions = states.filter((option) =>
        (option.state_name).toLowerCase().includes(clientState.toLowerCase())
    );

    const filteredCityOptions = cities.filter((option) =>
        (option.city_name).toLowerCase().includes(clientCity.toLowerCase())
    );

    const handleChangePersonCountry = (e) => {
        setPersonCountry(e.target.value);

        setIsOpenPersonCountry(true);
        setPerson({
            ...person,
            country: e.target.value
        })
    };

    const handleClickPersonCountry = (i) => {
        setPersonCountry(i);
        setPerson({
            ...person,
            country: i
        })
        setIsOpenPersonCountry(false);
    };

    const handleChangePersonState = (e) => {
        setPersonState(e.target.value);

        setIsOpenPersonState(true);
        setPerson({
            ...person,
            state: e.target.value
        })
    };

    const handleClickPersonState = (i) => {
        setPersonState(i)
        setPerson({
            ...person,
            state: i
        })
        setIsOpenPersonState(false);
    };

    const handlechangePersonCity = (e) => {
        setPersonCity(e.target.value);

        setIsOpenPersonCity(true);
        setPerson({
            ...person,
            city: e.target.value
        })
    };

    const handleClickPersonCity = (i) => {
        setPersonCity(i)
        setPerson({
            ...person,
            city: i
        })
        setIsOpenPersonCity(false);
    };

    const filteredPersonCountries = personCountries.filter((option) =>
        (option.country_name).toLowerCase().includes(personCountry.toLowerCase())
    );

    const filteredPersonStates = personStates.filter((option) =>
        (option.state_name).toLowerCase().includes(personState.toLowerCase())
    );

    const filteredPersonCities = personCities.filter((option) =>
        (option.city_name).toLowerCase().includes(personCity.toLowerCase())
    );


    const handleSubmit = async (e) => {
        e.preventDefault();
        const testForm = e.currentTarget;
        if (testForm.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }

        setValidated(true);
        showLoader();

        if (formData.firstName && formData.add) {
            addClient(formData).then((response) => {
                if (response.status === 201) {
                    const clientName = formData?.clientFirmName ? formData?.clientFirmName : `${formData?.firstName} ${formData?.lastName}`;

                    const activityForm = {
                        module: 'client',
                        activity: 'Client Added',
                        activityDesc: `New client ${clientName} added`,
                        createdDate: formattedDate,
                        createdTime: formattedTime,
                        userId: localStorage.getItem('userId')
                    };

                    addRecentActivity(activityForm);

                    SuccessToast("Client added");
                    hideLoader();
                    setTimeout(() => {
                        setShowClient(false);
                        getClients();
                    }, 1000);
                }
                else if (response.status === 400) {
                    ErrorToast(response.message);
                    hideLoader();
                }
            }).catch(() => {
                ErrorToast('Something went wrong');
                hideLoader();
            })
        } else {
            window.scrollTo(0, 0);
            ErrorToast("Fill reqiured fields")
        }
    };

    return (
        <div
            onClick={() =>
                isOpen && setIsOpen(false) || isOpenClientCity && setIsOpenClientCity(false) || isOpenCountry && setIsOpenCountry(false) ||
                isOpenPersonCity && setIsOpenPersonCity(false) || isOpenPersonState && setIsOpenPersonState(false) || isOpenCountry && setIsOpenCountry(false)
            }
        >
            <Modal centered size="lg" show={showClient} onHide={() => setShowClient(false)}>
                <Modal.Header className="bg-primary">
                    <Col sm={11} className="text-white">
                        <h4 className="text-white">Add Client</h4>
                    </Col>
                    <Col sm={1} className="text-end text-white">
                        <MdClear className="fs-4" onClick={() => setShowClient(false)} style={{ cursor: "pointer" }} />
                    </Col>
                </Modal.Header>
                <Modal.Body>
                    <div className="new-user-info">
                        <Form
                            noValidate validated={validated}
                            onSubmit={handleSubmit}
                            method="POST"
                        >
                            <div className="row text-dark">
                                <Col sm={6}>
                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor="name">First Name *</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="firstName"
                                            name="firstName"
                                            placeholder="First Name"
                                            onChange={handleChange}
                                            className="w-100 p-2 text-dark border border-primary"
                                            required
                                            style={{ background: "#fff", borderRadius: "5px" }}
                                        />
                                        <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Label htmlFor="name">Last Name</Form.Label>
                                    <input
                                        type="text"
                                        id="lastName"
                                        name="lastName"
                                        placeholder="Last Name"
                                        onChange={handleChange}
                                        className="w-100 p-2 text-dark border border-primary"
                                        style={{ background: "#fff", borderRadius: "5px" }}
                                    />
                                </Col>
                                <Col sm={6} className="mt-2 ">
                                    <Form.Label htmlFor="name">Firm Name</Form.Label>
                                    <input
                                        type="text"
                                        id="firmName"
                                        name="clientFirmName"
                                        placeholder="Firm Name"
                                        onChange={handleChange}
                                        className="w-100 p-2 text-dark border border-primary"
                                        style={{ background: "#fff", borderRadius: "5px" }}
                                    />
                                </Col>
                                <Col sm={6}>
                                    <Form.Label htmlFor="name">Website</Form.Label>
                                    <input
                                        type="text"
                                        id="website"
                                        name="website"
                                        placeholder="Webiste"
                                        onChange={handleChange}
                                        className="p-2 w-100 text-dark border border-primary"
                                        style={{ background: "#fff", borderRadius: "5px" }}
                                    />
                                </Col>
                                <Col sm={6}>
                                    <Form.Label htmlFor="name">Email</Form.Label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                        placeholder="Client Email"
                                        onChange={handleChange}
                                        className="p-2 w-100 text-dark border border-primary"
                                        style={{ background: "#fff", borderRadius: "5px" }}
                                    />
                                </Col>
                                <Col sm={6}>
                                    <Form.Label htmlFor="phone">Phone</Form.Label>
                                    <input
                                        type="number"
                                        id="phone"
                                        name="phone"
                                        pattern="\d{10}"
                                        maxLength="10"
                                        placeholder="Client Phone"
                                        onChange={handleChange}
                                        className="p-2 w-100 text-dark border border-primary"
                                        style={{ background: "#fff", borderRadius: "5px" }}
                                    />
                                </Col>
                                <Col sm={6} className="mt-2">
                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor="address">
                                            Address Line 1 *
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="address"
                                            name="address1"
                                            placeholder="Address Line 1"
                                            onChange={handleAddress}
                                            className=" border-primary"
                                            required
                                            style={{ background: "#fff" }}
                                        />
                                        <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col sm={6} className="mt-2">
                                    <Form.Label htmlFor="address">
                                        Address Line 2
                                    </Form.Label>
                                    <input
                                        type="text"
                                        id="address"
                                        name="address2"
                                        placeholder="Address Line 2"
                                        onChange={handleAddress}
                                        className="w-100 p-2 border border-primary"
                                        style={{ background: "#fff" }}
                                    />
                                </Col>

                                <Form.Group style={{ position: 'relative' }} className="col-sm-6 form-group">
                                    <Form.Label>Select Country *</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="autocomplete-state"
                                        className="border border-primary"
                                        value={clientCountry}
                                        name="state"
                                        placeholder="Select Country"
                                        onChange={handleInputChangeCountry}
                                        onClick={() => setIsOpenCountry(true)}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>

                                    {isOpenCountry && (
                                        <>
                                            <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                                {
                                                    countries.length > 0 ? (
                                                        filteredCountryOptions.length > 0 ? (
                                                            filteredCountryOptions.map((option, i) => (
                                                                <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleOptionClickCountry(option.country_name)}>
                                                                    {option.country_name}
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <div className="mt-2">No match found</div>
                                                        )
                                                    ) : (
                                                        <div className="mt-2">Loading . . . . </div>
                                                    )
                                                }
                                            </div>
                                        </>
                                    )}
                                </Form.Group>

                                <Form.Group style={{ position: 'relative' }} className="col-sm-6 form-group">
                                    <Form.Label>Select State *</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="autocomplete-state"
                                        className="border border-primary"
                                        value={clientState}
                                        name="state"
                                        placeholder="Select State"
                                        onChange={handleInputChange}
                                        onClick={() => setIsOpen(true)}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>

                                    {isOpen && (
                                        <>
                                            <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                                {
                                                    states.length > 0 ? (
                                                        filteredOptions.length > 0 ? (
                                                            filteredOptions.map((option, i) => (
                                                                <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleOptionClick1(option.state_name)}>
                                                                    {option.state_name}
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <div className="mt-2">No match found</div>
                                                        )
                                                    ) : (
                                                        <div className="mt-2">Select country first </div>
                                                    )
                                                }
                                            </div>
                                        </>
                                    )}
                                </Form.Group>

                                <Form.Group style={{ position: 'relative' }} className="col-sm-6 form-group">
                                    <Form.Label>City *</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="autocomplete-state"
                                        className="border border-primary"
                                        value={clientCity}
                                        name="city"
                                        placeholder="Select City"
                                        onChange={handleInputChangeCity}
                                        onClick={() => setIsOpenClientCity(true)}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>

                                    {isOpenClientCity && (
                                        <>
                                            <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                                {
                                                    cities.length > 0 ? (
                                                        filteredCityOptions.length > 0 ? (
                                                            filteredCityOptions.map((option, i) => (
                                                                <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleOptionClickCity(option.city_name)}>
                                                                    {option.city_name}
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <div className="mt-2">No match found</div>
                                                        )
                                                    ) : (
                                                        <div className="mt-2">Select state first </div>
                                                    )
                                                }
                                            </div>
                                        </>
                                    )}
                                </Form.Group>

                                <Col sm={6}>
                                    <Form.Label htmlFor="address">Pincode / Zipcode</Form.Label>
                                    <input
                                        type="number"
                                        id="address"
                                        name="pinCode"
                                        placeholder="Pincode / Zipcode"
                                        maxLength="6"
                                        onChange={handleAddress}
                                        className="p-2 w-100 text-dark border border-primary"
                                        style={{ background: "#fff", borderRadius: "5px" }}
                                    />
                                </Col>
                                <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="address">GST / Tax Id</Form.Label>
                                    <input
                                        type="text"
                                        id="taxId"
                                        name="taxId"
                                        placeholder="GST / Tax Id"
                                        onChange={handleChange}
                                        className="w-100 p-2 border border-primary border-primary"
                                        style={{ background: "#fff", borderRadius: "5px" }}
                                    />
                                </Form.Group>
                                <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="address">Our Firm</Form.Label>
                                    <select name="firmId" onChange={handleChange}
                                        className="p-2 w-100 text-dark border border-primary"
                                        style={{ background: "#fff", borderRadius: "5px" }}
                                    >
                                        <option>Select Firm</option>
                                        {
                                            allFirms.map((data, index) => (
                                                <option value={data._id} key={index}>{data.name}</option>
                                            ))
                                        }
                                    </select>
                                </Form.Group>
                                <div className="my-3">
                                    <hr />
                                    <h4 className="text-dark">Contact Person</h4>
                                    <input
                                        type="radio"
                                        name="contactperson"
                                        className="border border-primary"
                                        onClick={() => setContactPerson(true)}
                                        style={{ cursor: "pointer" }}
                                        defaultChecked
                                        checked={contactPerson === true}
                                    />
                                    <label className="fs-5 text-primary ms-1" onClick={() => setContactPerson(true)} style={{ cursor: "pointer", fontSize: "0.7rem" }}>
                                        Yes
                                    </label>

                                    <input
                                        type="radio"
                                        name="contactperson"
                                        className="border border-primary ms-5"
                                        onClick={() => setContactPerson(false)}
                                        style={{ cursor: "pointer" }}
                                        checked={contactPerson === false}
                                    />
                                    <label className="fs-5 text-primary ms-1" onClick={() => setContactPerson(false)} style={{ cursor: "pointer", fontSize: "0.7rem" }}>
                                        No
                                    </label>
                                    {
                                        contactPerson && (
                                            <Row>
                                                <Col sm={6}>
                                                    <Form.Label>Name</Form.Label>
                                                    <input type="text" className="w-100 p-2 border border-primary" name="name" onChange={handlePerson}
                                                        placeholder="Name"
                                                        style={{ borderRadius: "5px" }}
                                                    />
                                                </Col>
                                                <Col sm={6}>
                                                    <Form.Label>Email</Form.Label>
                                                    <input type="email" className="w-100 p-2 border border-primary" name="email" onChange={handlePerson}
                                                        placeholder="Email"
                                                        style={{ borderRadius: "5px" }}
                                                    />
                                                </Col>
                                                <Col sm={6} className="mt-2">
                                                    <Form.Label>Phone</Form.Label>
                                                    <input type="number" className="border w-100 p-2 border border-primary" pattern="\d{10}" maxLength={10} name="phone" onChange={handlePerson} placeholder="Phone No." style={{ borderRadius: "5px" }} />
                                                </Col>
                                                <Col sm={6} className="mt-2">
                                                    <Form.Label>Alternative Phone</Form.Label>
                                                    <input type="number" className="border w-100 p-2 border border-primary" pattern="\d{10}" maxLength="10" name="altPhone" onChange={handlePerson} placeholder="Alternative Phone No." />
                                                </Col>
                                                <Col sm={6} className="mt-2">
                                                    <Form.Label>Mobile</Form.Label>
                                                    <input type="number" className="border w-100 p-2 border border-primary" pattern="\d{10}" maxLength={10} name="mobile" onChange={handlePerson} placeholder="Mobile No." style={{ borderRadius: "5px" }} />
                                                </Col>
                                                <Col sm={6} className="mt-2">
                                                    <Form.Label>Alternative Mobile</Form.Label>
                                                    <input type="number" className="border w-100 p-2 border border-primary" pattern="\d{10}" maxLength="10" name="altMobile" onChange={handlePerson} placeholder="Alternative Mobile No." style={{ borderRadius: "5px" }} />
                                                </Col>
                                                <Col sm={6} className="mt-2">
                                                    <Form.Label>Address 1</Form.Label>
                                                    <input type="text" className="w-100 p-2 border border-primary" name="address1" onChange={handlePerson}
                                                        placeholder="Address Line 1"
                                                        style={{ borderRadius: "5px" }}
                                                    />
                                                </Col>
                                                <Col sm={6} className="mt-2">
                                                    <Form.Label>Address 2</Form.Label>
                                                    <input type="text" className="w-100 p-2 border border-primary" name="address2" onChange={handlePerson}
                                                        placeholder="Address Line 2"
                                                        style={{ borderRadius: "5px" }}
                                                    />
                                                </Col>

                                                <Col sm={6} className="mt-3">
                                                    <Form.Group style={{ position: 'relative' }}>
                                                        <Form.Label>Select Country</Form.Label>
                                                        <input
                                                            type="text"
                                                            id="autocomplete-state"
                                                            className="w-100 p-2 border border-primary"
                                                            value={personCountry}
                                                            name="personCountry"
                                                            placeholder="Select Country"
                                                            onChange={handleChangePersonCountry}
                                                            onClick={() => setIsOpenPersonCountry(true)}
                                                            style={{ borderRadius: "5px" }}
                                                        />

                                                        {isOpenPersonCountry && (
                                                            <>
                                                                <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                                                    {
                                                                        personCountries.length > 0 ? (
                                                                            filteredPersonCountries.length > 0 ? (
                                                                                filteredPersonCountries.map((option, i) => (
                                                                                    <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleClickPersonCountry(option.country_name)}>
                                                                                        {option.country_name}
                                                                                    </div>
                                                                                ))
                                                                            ) : (
                                                                                <div className="mt-2">No match found</div>
                                                                            )
                                                                        ) : (
                                                                            <div className="mt-2">Loading . . . . </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            </>
                                                        )}
                                                    </Form.Group>
                                                </Col>

                                                <Col sm={6} className="mt-3">
                                                    <Form.Group style={{ position: 'relative' }}>
                                                        <Form.Label>Select State</Form.Label>
                                                        <input
                                                            type="text"
                                                            id="autocomplete-state"
                                                            className="w-100 m-0 p-2 border border-primary"
                                                            value={personState}
                                                            name="personState"
                                                            placeholder="Select State"
                                                            onChange={handleChangePersonState}
                                                            onClick={() => setIsOpenPersonState(true)}
                                                            style={{ borderRadius: "5px" }}
                                                        />

                                                        {isOpenPersonState && (
                                                            <>
                                                                <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                                                    {
                                                                        personStates.length > 0 ? (
                                                                            filteredPersonStates.length > 0 ? (
                                                                                filteredPersonStates.map((option, i) => (
                                                                                    <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleClickPersonState(option.state_name)}>
                                                                                        {option.state_name}
                                                                                    </div>
                                                                                ))
                                                                            ) : (
                                                                                <div className="mt-2">No match found</div>
                                                                            )
                                                                        ) : (
                                                                            <div className="mt-2">Select country first </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            </>
                                                        )}
                                                    </Form.Group>
                                                </Col>

                                                <Col sm={6} className="mt-3">
                                                    <Form.Group style={{ position: 'relative' }}>
                                                        <Form.Label>Select City</Form.Label>
                                                        <input
                                                            type="text"
                                                            id="autocomplete-state"
                                                            className="w-100  p-2 border border-primary"
                                                            value={personCity}
                                                            name="personCountry"
                                                            placeholder="Select City"
                                                            onChange={handlechangePersonCity}
                                                            onClick={() => setIsOpenPersonCity(true)}
                                                            style={{ borderRadius: "5px" }}
                                                        />

                                                        {isOpenPersonCity && (
                                                            <>
                                                                <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                                                    {
                                                                        personCities.length > 0 ? (
                                                                            filteredPersonCities.length > 0 ? (
                                                                                filteredPersonCities.map((option, i) => (
                                                                                    <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleClickPersonCity(option.city_name)}>
                                                                                        {option.city_name}
                                                                                    </div>
                                                                                ))
                                                                            ) : (
                                                                                <div className="mt-2">No match found</div>
                                                                            )
                                                                        ) : (
                                                                            <div className="mt-2">Select state first </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            </>
                                                        )}
                                                    </Form.Group>
                                                </Col>

                                                <Col sm={6} className="mt-3">
                                                    <Form.Label htmlFor="address">Pincode / Zipcode</Form.Label>
                                                    <input type="number" name="pinCode"
                                                        placeholder="Pincode / Zipcode"
                                                        onChange={handlePerson} className="w-100 p-2 border border-primary"
                                                        style={{ borderRadius: "5px" }}
                                                    />
                                                </Col>
                                            </Row>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="bg-white mb-2 mx-0 px-0 py-2 rounded text-dark" style={{ minHeight: "3rem" }}>
                                <Row className="mb-3 m-0 p-0">
                                    <Col sm={2} className="m-0 p-0 mt-4">
                                        <button type="submit" className="m-0 p-0 w-100 bg-white border-0">
                                            <span className="m-0 p-0 w-100 bg-primary py-2 px-4 text-white" onMouseEnter={handleChange} onMouseOver={handlePerson} style={{ borderRadius: "5px" }}>
                                                Add Client
                                            </span>
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
            {loader}
        </div>
    )
}

export default Addclientmodal
