import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { MdClear } from "react-icons/md";

const RecurringModal = ({ setRecurring, form, setForm, setDueDate, invoiceDate, showRecurring, setShowRecurring }) => {

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [invoiceDueDate, setInvoiceDueDate] = useState('');

    const [selectedFreq, setSelectedFreq] = useState('Every Week');
    const [selectedDueDate, setSelectedDueDate] = useState('');

    const [customRepeat, setCustomRepeat] = useState(1);
    const [customFreq, setCustomFreq] = useState('Days');
    const [showCustomFreq, setShowCustomFreq] = useState(false);

    useEffect(() => {
        showCustomFreq ? setSelectedFreq('Custom') : setSelectedFreq('Every Week');
    }, [showCustomFreq]);

    // useEffect(() => {
    //     handleSelectFrequency('Every Week');
    //     handleChangeDueDate('In 10 days');
    // }, [invoiceDate]);

    const handleSelectFrequency = (date) => {
        setSelectedFreq(date);
        if (date === 'Every Week') {
            const selectedDateObj = new Date(invoiceDate);
            const sevenDaysLaterObj = new Date(selectedDateObj);
            sevenDaysLaterObj.setDate(selectedDateObj.getDate() + 7);
            const sevenDaysLaterStr = sevenDaysLaterObj.toISOString().split('T')[0];

            setStartDate(invoiceDate);
            setEndDate(sevenDaysLaterStr);

        } else if (date === 'Every Month') {
            const selectedDateObj = new Date(invoiceDate);
            const sevenDaysLaterObj = new Date(selectedDateObj);
            sevenDaysLaterObj.setDate(selectedDateObj.getDate() + 30);
            const sevenDaysLaterStr = sevenDaysLaterObj.toISOString().split('T')[0];

            setStartDate(invoiceDate);
            setEndDate(sevenDaysLaterStr);

        } else if (date === 'Every Quarter') {
            const selectedDateObj = new Date(invoiceDate);
            const sevenDaysLaterObj = new Date(selectedDateObj);
            sevenDaysLaterObj.setDate(selectedDateObj.getDate() + 90);
            const sevenDaysLaterStr = sevenDaysLaterObj.toISOString().split('T')[0];

            setStartDate(invoiceDate);
            setEndDate(sevenDaysLaterStr);

        } else if (date === 'Every Year') {
            const selectedDateObj = new Date(invoiceDate);
            const sevenDaysLaterObj = new Date(selectedDateObj);
            sevenDaysLaterObj.setDate(selectedDateObj.getDate() + 365);
            const sevenDaysLaterStr = sevenDaysLaterObj.toISOString().split('T')[0];

            setStartDate(invoiceDate);
            setEndDate(sevenDaysLaterStr);
        }
    };

    const handleChangeDueDate = (e) => {
        if (e === 'In 10 days') {
            const selectedDateObj = new Date(invoiceDate);
            const sevenDaysLaterObj = new Date(selectedDateObj);
            sevenDaysLaterObj.setDate(selectedDateObj.getDate() + 10);
            const sevenDaysLaterStr = sevenDaysLaterObj.toISOString().split('T')[0];

            setDueDate(sevenDaysLaterStr);
            setInvoiceDueDate(sevenDaysLaterStr);

        } else {
            const selectedDateObj = new Date(invoiceDate);
            const sevenDaysLaterObj = new Date(selectedDateObj);
            sevenDaysLaterObj.setDate(selectedDateObj.getDate() + 30);
            const sevenDaysLaterStr = sevenDaysLaterObj.toISOString().split('T')[0];

            setDueDate(sevenDaysLaterStr);
            setInvoiceDueDate(sevenDaysLaterStr);

        }
    };

    const handleSaveChanges = () => {
        setForm({
            ...form,
            recurringInvoiceObj: {
                start_date: startDate,
                end_date: endDate
            },
            dueDate: invoiceDueDate
        });
        setShowRecurring(false);
    };

    useEffect(() => {
        if (selectedFreq === 'Custom') {
            const selectedDateObj = new Date(invoiceDate);
            const endDateObj = new Date(selectedDateObj);
            let endDateStr;

            customFreq === 'Days' ? endDateObj.setDate(selectedDateObj.getDate() + parseInt(customRepeat))
                : customFreq === 'Weeks' ? endDateObj.setDate(selectedDateObj.getDate() + parseInt(customRepeat * 7))
                    : customFreq === 'Months' ? endDateObj.setDate(selectedDateObj.getDate() + parseInt(customRepeat * 30))
                        : endDateObj.setDate(selectedDateObj.getDate() + parseInt(customRepeat * 365))

            customRepeat ? endDateStr = endDateObj.toISOString().split('T')[0] : endDateStr = ''
            setStartDate(invoiceDate);
            setEndDate(endDateStr);
        }
    }, [selectedFreq, customRepeat, customFreq]);

    // const handleChange = (e) => {
    //     setForm({
    //         ...form,
    //         [e.target.name]: e.target.value,
    //     })
    // };

    const allFrequencyOptions = ['Every Week', 'Every Month', 'Eventy Quarter', 'Every Year'];
    const allDueDates = ['In 10 days', 'In 30 days'];

    const handleHidePopup = () => {
        setForm({
            ...form,
            recurringInvoice: false,
            recurringInvoiceObj: {}
        });
        setShowRecurring(false);
        setRecurring(false);
    }

    return (
        <>
            <Modal centered show={showRecurring} onHide={handleHidePopup}>
                <Modal.Header className="bg-primary">
                    <Col sm={11} className="text-white">
                        <h4 className="text-white">Recurring Invoice</h4>
                    </Col>
                    <Col sm={1} className="text-end text-white">
                        <MdClear className="fs-4" onClick={handleHidePopup} style={{ cursor: "pointer" }} />
                    </Col>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <Row className="mx-1">
                            <Col sm={5} className="me-2 p-0">
                                <span className="m-0 p-0">Frequency</span>
                                <Form.Select name="selectedFreq" onChange={(e) => handleSelectFrequency(e.target.value)} style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                                    <option className="text-white bg-primary" value="">Select</option>
                                    {
                                        allFrequencyOptions.map((data, index) => (
                                            <option key={index}>{data}</option>
                                        ))
                                    }
                                    {/* <option value="Custom">Custom</option> */}
                                </Form.Select>
                            </Col>
                            <Col sm={5} className="m-0 p-0">
                                <span className="m-0 p-0">Invoice Due</span>
                                <Form.Select name="due" onChange={(e) => handleChangeDueDate(e.target.value)} style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }}>
                                    <option className="text-white bg-primary" value="">Select</option>
                                    {
                                        allDueDates.map((data, index) => (
                                            <option key={index}>{data}</option>
                                        ))
                                    }
                                    {/* <option value="Custom">Custom</option> */}
                                </Form.Select>
                            </Col>
                        </Row>
                        <div className="my-2 w-100 mx-2">
                            <Form.Check.Input type="checkbox" onChange={() => setShowCustomFreq(!showCustomFreq)} checked={showCustomFreq} className="mt-1 cursor-pointer" />
                            <Form.Label className="ms-2 cursor-pointer" onClick={() => setShowCustomFreq(!showCustomFreq)}>Custom</Form.Label>
                        </div>
                        {
                            selectedDueDate === 'Custom' && (
                                <Row className="mx-1 my-2">
                                    <Col sm={5} className="m-0 p-0">
                                        <span className="m-0 p-0">Custom Due Date</span>
                                        <Form.Control type="date" name="dueDate" style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }} />
                                    </Col>
                                </Row>
                            )
                        }
                        {
                            selectedFreq === "Custom" && (
                                <Row className="mx-1 mt-3 p-0">
                                    <hr />
                                    <div className="row m-0 p-0">
                                        <Col sm={3} className="p-0">
                                            <span className="m-0 p-0">Repeat Every</span>
                                            <Form.Control type="number" placeholder="1" name="repeat" onChange={(e) => setCustomRepeat(e.target.value)} style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }} />
                                        </Col>
                                        <Col sm={8}>
                                            <span className="m-0 p-0">Frequency</span>
                                            <Form.Select name="repeatFreq" onChange={(e) => setCustomFreq(e.target.value)} style={{ border: "1px solid #c8c9ca", borderRadius: "5px", cursor: "pointer" }}>
                                                {/* <option value="">Select</option> */}
                                                <option value="Days">Days</option>
                                                <option value="Weeks">Weeks</option>
                                                <option value="Months">Months</option>
                                                <option value="Years">Years</option>
                                            </Form.Select>
                                        </Col>
                                        {/* <Col sm={12} className="m-0 p-0 mt-2">
                                            <Col sm={12}>
                                                <span className="m-0 p-0">Ending</span>
                                            </Col>
                                            <Col sm={12}>
                                                <input type="radio" name="ending" onChange={() => setEnding(false)} style={{ cursor: "pointer" }} defaultChecked defaultValue checked={!ending} />
                                                <label className="ms-1 me-2" onClick={() => setEnding(false)} style={{ cursor: "pointer" }}>Never</label>
                                                <input type="radio" name="ending" onChange={() => setEnding(true)} style={{ cursor: "pointer" }} checked={ending} />
                                                <label className="ms-1" onClick={() => setEnding(true)} style={{ cursor: "pointer" }}>After</label>
                                                {
                                                    ending && (
                                                        <>
                                                            <input type="number" name="endOccur" onChange={handleChange} className="py-1 px-2 mx-2 w-25" style={{ border: "1px solid #c8c9ca", borderRadius: "5px" }} placeholder="eg: 1" />
                                                            <lablel>Occurrence</lablel>
                                                        </>
                                                    )
                                                }
                                            </Col>
                                        </Col> */}
                                    </div>
                                </Row>
                            )
                        }
                        <Row className="mt-4 ms-1">
                            <Col sm={3} className="m-0 p-0">
                                <span className="m-0 p-0 py-1 px-4 bg-primary text-white" onClick={handleSaveChanges} style={{ borderRadius: "5px", cursor: "pointer" }}>Save</span>
                            </Col>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
};

export default RecurringModal;
