import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import Card from "../../../../components/Card";
import SubHeader from "../../../../components/partials/dashboard/HeaderStyle/sub-header";
import { createLead } from "../Hook/leadHooks";
import TimezoneSelect from 'react-timezone-select'
import { getCity, getCountries, getState } from "../Hook/CustomHooks";
import { getOrgDetails } from "../Hook/orgHooks";
import { getAllUsers } from "../Hook/userHooks";
import { ErrorToast, SuccessToast, WarningToast } from "../../components/toast";
import { getAllFirmsList } from "../Hook/firmHooks";
import { addRecentActivity } from "../Hook/recentActivityHooks";

const AddLead = () => {
  const initData = Object.freeze({
    status: "Hold",
    title: '',
    clientFName: '',
    clientLName: '',
    clientEmail: '',
    clientPhone: '',
    timezone: '',
    stage: '',
    estimatedWorth: '',
    createdDate: '',
    closureDate: '',
    pipeline: { department: '', userType: '' },
    orgDetails: {
      orgName: '',
      orgEmail: '',
      orgPhone: '',
      orgAddress: { orgLineOne: '', orgLineTwo: '', orgCountry: '', orgState: '', orgCity: '', orgCode: '' },
    },
    description: '',
  });
  const [form, setForm] = useState(initData);
  const [validated, setValidated] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

  const [allFirms, setAllFirms] = useState([]);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [Orgcountries, setOrgCountries] = useState([]);
  const [orgStates, setOrgStates] = useState([]);
  const [orgCities, setOrgCities] = useState([]);

  const [clientCountry, setClientCountry] = useState('');
  const [isOpenCountry, setIsOpenCountry] = useState(false);
  const [clientState, setClientState] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [clientCity, setClientCity] = useState('');
  const [isOpenClientCity, setIsOpenClientCity] = useState(false);
  // const [orgDept, setOrgDept] = useState([]);

  const initialClientAddress = Object.freeze({ lineOne: '', lineTwo: '', country: '', state: '', city: '', code: '' });
  const [clientAddress, setClientAddress] = useState(initialClientAddress);

  const [orgCountry, setOrgCountry] = useState('');
  const [isOpenOrgCountry, setIsOpenOrgCountry] = useState(false);
  const [orgState, setOrgState] = useState('');
  const [isOpenOrgState, setIsOpenOrgState] = useState(false);
  const [orgCity, setOrgCity] = useState('');
  const [isOpenOrgCity, setIsOpenOrgCity] = useState(false);
  const initialOrgAddress = Object.freeze({ orgLineOne: '', orgLineTwo: '', orgCountry: "", orgState: "", orgDistrict: "" });

  const initialOrgDetails = Object.freeze({ orgName: '', orgEmail: '', orgPhone: '' })

  const [orgAddress, setOrgAddress] = useState(initialOrgAddress);
  const [orgDetails, setOrgDetails] = useState(initialOrgDetails);

  const [allAssignees, setAllAssignees] = useState([]);
  const [assignedTo, setAssignedTo] = useState("");
  const [isOpenAssigneeList, setIsOpenAssigneeList] = useState(false);

  const [leadStage, setLeadStage] = useState('Lead In');
  const [allStages, setAllStages] = useState([]);
  const [allPipelines, setAllPipelines] = useState([]);
  const [formStep, setFormStep] = useState(1);

  const datetimeString = new Date();
  const dateObject = new Date(datetimeString);

  const formattedDate = `${dateObject.getDate()}/${dateObject.getMonth() + 1}/${dateObject.getFullYear()}`;
  const formattedTime = dateObject.toLocaleTimeString();

  const allCurrencies = [
    { id: 0, name: "INR", value: "₹" },
    { id: 1, name: "USD", value: "$" },
    { id: 2, name: "EUR", value: "€" },
    { id: 3, name: "AUD", value: "$" },
    { id: 4, name: "CAD", value: "$" }
  ];

  const orgId = localStorage.getItem("orgID");
  const orgName = localStorage.getItem("orgName");
  const userName = localStorage.getItem("userName");
  const userId = localStorage.getItem("userId");

  const history = useHistory();

  const departmenRef = useRef('');
  const userTypeRef = useRef('');

  let pipelineObj = { department: '', userType: '' };

  const getAllFirms = async () => {
    getAllFirmsList().then((response) => (response.status === 200) ? setAllFirms(response.data.data) : setAllFirms([])).catch(() => setAllFirms([]));
  };

  useEffect(() => {
    setForm({
      ...form,
      leadManager: {
        id: userId,
        managerName: userName
      }
    })
  }, [userId, userName]);

  useEffect(() => {
    getAllFirms();
  }, []);

  useEffect(() => {
    getOrgDetails().then((response) => {
      if (response.status === 200) {
        setAllStages(response.data.data.orgLeadStages);
        setAllPipelines(response.data.data.orgDept);
      }
    })
  }, []);

  // useEffect(() => {
  //   getOrgDept().then((response) => {
  //     if (response.status === 200) {
  //       // setOrgDept(response.data.data);
  //     } else {
  //       console.log("unable to fetch data");
  //     }
  //   })
  // }, []);

  useEffect(() => {
    getAllUsers().then((response) => {
      if (response.status === 200) {
        const allUsers = response?.data?.data
        const permittedUsers = allUsers.filter((data) => data?.permissions.some(permission => permission.module === 'lead'));

        setAllAssignees(permittedUsers);
      } else setAllAssignees([]);
    }).catch(() => setAllAssignees([]));
  }, []);

  useEffect(() => {
    getCountries().then((res) => setCountries(res));
  }, []);

  useEffect(() => {
    (clientAddress.country) && getState(clientAddress.country).then((res) => setStates(res));
  }, [clientAddress.country]);

  useEffect(() => {
    (clientAddress.state) && getCity(clientAddress.state).then((res) => setCities(res));
  }, [clientAddress.state]);

  useEffect(() => {
    getCountries().then((res) => setOrgCountries(res));
  }, []);

  useEffect(() => {
    (orgAddress.orgCountry) && getState(orgAddress.orgCountry).then((res) => setOrgStates(res));
  }, [orgAddress.orgCountry]);

  useEffect(() => {
    (orgAddress.orgState) && getCity(orgAddress.orgState).then((res) => setOrgCities(res));
  }, [orgAddress.orgState]);


  const handleInputChangeCountry = (e) => {
    setClientCountry(e.target.value);

    setIsOpenCountry(true);
    setClientAddress({ ...clientAddress, country: e.target.value });
  };

  const handleOptionClickCountry = (i) => {
    setClientCountry(i)
    setClientAddress({ ...clientAddress, country: i });
    setIsOpenCountry(false);
  };

  const handleInputChange = (e) => {
    setClientState(e.target.value);

    setIsOpen(true);
    setClientAddress({ ...clientAddress, state: e.target.value });
  };

  const handleOptionClick1 = (i) => {
    setClientState(i)
    setClientAddress({ ...clientAddress, state: i });
    setIsOpen(false);
  };

  const handleInputChangeCity = (e) => {
    setClientCity(e.target.value);

    setIsOpenClientCity(true);
    setClientAddress({ ...clientAddress, city: e.target.value });
  };

  const handleOptionClickCity = (i) => {
    setClientCity(i)
    setClientAddress({ ...clientAddress, city: i });
    setIsOpenClientCity(false);
  };

  const filteredCountryOptions = countries.filter((option) => (option.country_name).toLowerCase().includes(clientCountry.toLowerCase()));

  const filteredOptions = states.filter((option) => (option.state_name).toLowerCase().includes(clientState.toLowerCase()));

  const filteredCityOptions = cities.filter((option) => (option.city_name).toLowerCase().includes(clientCity.toLowerCase()));

  const handleInputChangeOrgCountry = (e) => {
    setOrgCountry(e.target.value);

    setIsOpenOrgCountry(true);
    setOrgAddress({ ...orgAddress, orgCountry: e.target.value });
  };

  const handleOptionClickOrgCountry = (i) => {
    setOrgCountry(i)
    setOrgAddress({ ...orgAddress, orgCountry: i });
    setIsOpenOrgCountry(false);
  };

  const handleInputChangeOrgState = (e) => {
    setOrgState(e.target.value);

    setIsOpenOrgState(true);
    setOrgAddress({ ...orgAddress, orgState: e.target.value });
  };

  const handleOptionClickOrgState = (i) => {
    setOrgState(i)
    setOrgAddress({ ...orgAddress, orgState: i });
    setIsOpenOrgState(false);
  };

  const handleInputChangeOrgCity = (e) => {
    setOrgCity(e.target.value);

    setIsOpenOrgCity(true);
    setOrgAddress({ ...orgAddress, orgCity: e.target.value });
  };

  const handleOptionClickOrgCity = (i) => {
    setOrgCity(i)
    setOrgAddress({ ...orgAddress, orgDistrict: i });
    setIsOpenOrgCity(false);
  };

  const filteredOrgCountryOptions = Orgcountries.filter((option) => (option.country_name).toLowerCase().includes(orgCountry.toLowerCase()));

  const filteredStateOptions = orgStates.filter((option) => (option.state_name).toLowerCase().includes(orgState.toLowerCase()));

  const filteredOrgCityOptions = orgCities.filter((option) => (option.city_name).toLowerCase().includes(orgCity.toLowerCase()));

  const handleInputChangeAssignee = (e) => {
    setAssignedTo(e.target.value);
    setIsOpenAssigneeList(true);
    setForm({ ...form, assignTo: e.target.value });
  };

  const handleOptionClickAssignee = (data) => {
    setAssignedTo(`${data?.firstName} ${data?.lastName}`);
    setForm({
      ...form,
      assignedTo: {
        userId: data._id,
        userName: `${data?.firstName} ${data?.lastName}`
      }
    })
    setIsOpenAssigneeList(false);
  };

  const filteredAssigneeOptions = allAssignees.filter((option) => (`${option.firstName} ${option.lastName}`).toLowerCase().includes(assignedTo.toLowerCase()));

  const handleClientAddress = (e) => {
    if (e.target.name === 'code') {
      if ((e.target.value > clientAddress.clientCountry) === 'United States' ? 7 : 6) {
        e.target.value = e.target.value.slice(0, clientAddress.clientCountry === 'United States' ? 7 : 6)
        setClientAddress({ ...clientAddress, code: e.target.value });
      }
    }

    setClientAddress({
      ...clientAddress,
      [e.target.name]: e.target.value
    })
  };

  const handleChangeOrgAddress = (e) => {
    e.target.name === 'orgCountry' ? setOrgAddress({ ...orgAddress, orgCountry: orgCountry }) :
      e.target.name === 'orgState' ? setOrgAddress({ ...orgAddress, orgState: orgState }) :
        e.target.name === 'orgCity' && setOrgAddress({ ...orgAddress, orgCity: orgCity });

    setOrgAddress({ ...orgAddress, [e.target.name]: e.target.value })
  };

  const handleChangeOrgDetails = (e) => {

    if (e.target.name === 'orgPhone') {
      e.target.value = e.target.value.slice(0, 10);
      setOrgDetails({
        ...orgDetails,
        orgPhone: e.target.value,
        orgAddress: orgAddress
      })
    }

    setOrgDetails({
      ...orgDetails,
      [e.target.name]: e.target.value,
      orgAddress: orgAddress
    })
  }

  const handleChangePipeline = () => {
    pipelineObj.department = departmenRef.current.value;
    pipelineObj.userType = userTypeRef.current.value;
  };

  const handleAddFirm = (e) => {
    const data = JSON.parse((e.target.value));

    setForm({
      ...form,
      firm: {
        firmName: data?.registeredFirmName ? data?.registeredFirmName : data?.name,
        firmId: data?._id
      }
    });
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.name === "pinCode") {
      if ((e.target.value > form.orgCountry) === "United States" ? 7 : 6) {
        e.target.value = e.target.value.slice(0, form.orgCountry === "United States" ? 7 : 6)
        setForm({
          ...form,
          pinCode: e.target.value
        })
      }
    }

    if (e.target.name === 'clientPhone') {
      e.target.value = e.target.value.slice(0, 10);
      setForm({
        ...form,
        clientPhone: e.target.value
      })
    }

    setForm({
      ...form,
      [e.target.name]: e.target.value,
      status: 'In Process',
      // pipeline: {
      //   department: department,
      //   assignTo: assignedTo,
      //   userType: userType,
      // },
      clientAddress: clientAddress,
      orgDetails: orgDetails,
      stage: leadStage,
      timezone: selectedTimezone.label,
      orgId: orgId,
      delete: false,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    (!form.title || form.title === '' || !form.createdDate || form.createdDate === '') ? setFormStep(1)
      : ((!form.clientFName || !form.orgName) && !form.clientEmail) && setFormStep(2)

    setValidated(true);
    if (form.title && form.createdDate && form.clientEmail && (form.clientFName || form.orgName)) {
      createLead(form).then((response) => {
        if (response.status === 200) {

          const activityForm = {
            module: ['lead'],
            activity: 'Lead created',
            activityDesc: `New Lead ${form?.title} created`,
            createdDate: formattedDate,
            createdTime: formattedTime,
            userId: localStorage.getItem('userId')
          };

          addRecentActivity(activityForm);

          SuccessToast('Lead Created Successfully');
          history.push(`/${orgName}/dashboard/lead/lead-list`)
        } else if (response.status === 400) {
          ErrorToast('Unable to create lead');
        }
      }).catch((err) => err?.response?.data?.message ? WarningToast(err?.response?.data?.message) : ErrorToast('Something went wrong'))
    } else ErrorToast("Fill all the required fields");
  };


  return (
    <div onClick={() =>
      (isOpen && setIsOpen(false)) || (isOpenClientCity && setIsOpenClientCity(false)) || (isOpenCountry && setIsOpenCountry(false)) ||
      (isOpenOrgState && setIsOpenOrgState(false)) || (isOpenOrgCity && setIsOpenOrgCity(false)) || (isOpenOrgCountry && setIsOpenOrgCountry(false)) ||
      (isOpenAssigneeList && setIsOpenAssigneeList(false))
    }
    >
      <SubHeader title="Add Lead" />
      <Row style={{ marginTop: "-3%", width: "100vw", maxWidth: "90vw" }}>

        <Col className="text-dark mx-3 d-flex justify-content-center align-items-center w-100">
          <Form noValidate validated={validated}>
            <div className="w-100 bg-white rounded" style={{ minWidth: "75vw" }}>
              {
                formStep === 1 ? (
                  <Card className="mb-0">
                    <Card.Header className="bg-white">
                      <div className="header-title">
                        <h4 className="card-title text-primary fw-bold">About Lead</h4>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <Row className="">
                        <Col className="mt-3" sm={12} md={6} lg={4}>
                          <Form.Group>
                            <Form.Label htmlFor="title">Title *</Form.Label>
                            <Form.Control type="text" className="border border-primary" value={form.title} placeholder="Lead Title" name="title" onChange={handleChange} required />
                            <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col className="mt-3" sm={12} md={6} lg={4}>
                          <Form.Group>
                            <Form.Label htmlFor="firm">Firm</Form.Label>
                            <select onChange={(e) => handleAddFirm(e)} className="w-100 p-2 bg-white border border-primary" style={{ borderRadius: "5px" }}>
                              <option>Select Firm</option>
                              {
                                allFirms.map((data, index) => (
                                  <option value={JSON.stringify(data)} key={index}>{data?.registeredFirmName ? data?.registeredFirmName : data?.name}</option>
                                ))
                              }
                            </select>
                          </Form.Group>
                        </Col>
                        <Col className="mt-3" sm={12} md={6} lg={4}>
                          <Form.Group>
                            <Form.Label htmlFor="department">Pipeline</Form.Label>
                            <select ref={departmenRef} onChange={handleChangePipeline} className="w-100 p-2 bg-white border border-primary" style={{ borderRadius: "5px" }}>
                              <option value="Test">Select Pipeline</option>
                              {
                                allPipelines.length > 0 ? allPipelines.map((data, index) =>
                                  data.length > 0 ? (
                                    <option key={index} value={data}>{data}</option>
                                  ) : null
                                ) : (
                                  <option value="Test2">No Pipeline Found</option>
                                )
                              }
                            </select>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mt-3" sm={12} md={6} lg={4}>
                          <Form.Group style={{ position: "relative" }}>
                            <Form.Label htmlFor="assignTo">Assign To</Form.Label>
                            <input type="text" id="autocomplete-state" className="mt-1 p-2 w-100 border border-primary" value={assignedTo} name="assignTo" placeholder="Select Assignee"
                              onChange={handleInputChangeAssignee} onClick={() => setIsOpenAssigneeList(true)} autoComplete='off' style={{ borderRadius: '5px' }} required
                            />
                            {isOpenAssigneeList && (
                              <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                {
                                  allAssignees.length > 0 ? (
                                    filteredAssigneeOptions.length > 0 ? (
                                      filteredAssigneeOptions.map((option, i) => (
                                        <div className='mt-2 cursor-pointer' onClick={() => handleOptionClickAssignee(option)} key={i}>
                                          {option?.firstName} {option?.lastName}
                                        </div>
                                      ))
                                    ) : (
                                      <div className="mt-2">No match found</div>
                                    )
                                  ) : (
                                    <div className="mt-2">No assignees found </div>
                                  )
                                }
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col className="mt-3" sm={12} md={6} lg={4}>
                          <Form.Group>
                            <Form.Label htmlFor="stage">Stage</Form.Label>
                            <select name="stage" defaultValue={allStages[0]} onInput={(e) => setLeadStage(e.target.value)} onChange={handleChange} className="w-100 p-2 bg-white border border-primary" style={{ borderRadius: "5px" }}>
                              {
                                allStages.map((data, index) => (
                                  <option key={index} style={{ display: data?.stageName === "" && 'none' }}>{data?.stageName}</option>
                                ))
                              }
                            </select>
                          </Form.Group>
                        </Col>
                        <Col className="mt-3" sm={12} md={6} lg={4}>
                          <Form.Group>
                            <Form.Label htmlFor="estimatedWorth">
                              Currency
                            </Form.Label>
                            <Form.Select name="currency" onChange={handleChange} className="border border-primary">
                              <option>Select Currency</option>
                              {
                                allCurrencies.map((data, index) => (
                                  <option value={data.value} key={index}>{data.name}</option>
                                ))
                              }
                            </Form.Select>
                          </Form.Group>
                        </Col>

                        <Col className="mt-3" sm={12} md={6} lg={4}>
                          <Form.Group>
                            <Form.Label htmlFor="estimatedWorth">
                              Estimated Worth
                            </Form.Label>
                            <input type="text" name="estimatedWorth" value={form.estimatedWorth} onChange={handleChange} className="border border-primary w-100 p-2"
                              placeholder="Estimated Worth" style={{ borderRadius: "5px" }}
                            />
                          </Form.Group>
                        </Col>

                        <Col className="mt-3" sm={12} md={6} lg={4}>
                          <Form.Group>
                            <Form.Label htmlFor="createdDate">
                              Created Date
                            </Form.Label>
                            <Form.Control type="date" name="createdDate" value={form.createdDate} className="border border-primary" onChange={handleChange} required />
                            <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col className="mt-3" sm={12} md={6} lg={4}>
                          <Form.Group>
                            <Form.Label htmlFor="closureDate">
                              Closure Date
                            </Form.Label>
                            <input type="date" name="closureDate" value={form.closureDate} onChange={handleChange} className="border border-primary w-100 p-2" style={{ borderRadius: "5px" }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mt-3 ">
                        <Form.Group>
                          <Form.Label>Description</Form.Label>
                          <textarea rows={5} name="description" placeholder="Description" className="w-100 px-3 border border-primary"
                            value={form.description} onChange={handleChange} style={{ borderRadius: "5px" }}
                          ></textarea>
                        </Form.Group>
                      </Row>
                    </Card.Body>
                  </Card>
                ) : formStep === 2 ? (
                  <Card className="mb-0">
                    <Card.Header className="bg-white">
                      <div className="header-title">
                        <h4 className="card-title text-primary fw-bold">Client</h4>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <Row className="">
                        <Col className="mt-3" sm={12} md={6} lg={3}>
                          <Form.Group>
                            <Form.Label htmlFor="clientFName">Client First Name *</Form.Label>
                            <Form.Control type="text" name="clientFName" value={form.clientFName} onChange={handleChange} placeholder="Client first name" className="border border-primary" required />
                            <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col className="mt-3" sm={12} md={6} lg={3}>
                          <Form.Group>
                            <Form.Label htmlFor="clientLName">Client Last Name</Form.Label><br />
                            <input type="text" name="clientLName" value={form.clientLName} onChange={handleChange} className="p-2 border border-primary w-100"
                              placeholder="Client last name" style={{ borderRadius: "5px" }}
                            />
                          </Form.Group>
                        </Col>
                        <Col className="mt-3" sm={12} md={6} lg={3}>
                          <Form.Group>
                            <Form.Label htmlFor="clientEmail">Client Email *
                            </Form.Label>
                            <Form.Control type="email" name="clientEmail" value={form.clientEmail} onChange={handleChange} placeholder="Client Email" className="border border-primary" required />
                            <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col className="mt-3" sm={12} md={6} lg={3}>
                          <Form.Group>
                            <Form.Label htmlFor="clientPhone">Client Phone</Form.Label>
                            <input type="text" name="clientPhone" value={form.clientPhone} className="p-2 border border-primary w-100" onChange={handleChange}
                              placeholder="Client Phone" style={{ borderRadius: "5px" }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row sm={12}>
                        <Col className="mt-3" sm={12} md={6} lg={4}>
                          <Form.Group>
                            <Form.Label htmlFor="timezone">Time Zone</Form.Label>
                            <TimezoneSelect value={selectedTimezone} onChange={setSelectedTimezone} className="border border-primary rounded" />
                          </Form.Group>
                        </Col>

                        <Col className="mt-3" sm={12} md={6} lg={4}>
                          <Form.Group>
                            <Form.Label>Address Line 1</Form.Label>
                            <input type="text" name="lineOne" value={clientAddress.lineOne} onChange={handleClientAddress} className="border border-primary p-2 w-100" placeholder="Address Line 1" style={{ borderRadius: '5px' }} />
                          </Form.Group>
                        </Col>

                        <Col className="mt-3" sm={12} md={6} lg={4}>
                          <Form.Group>
                            <Form.Label>Address Line 2</Form.Label>
                            <input type="text" name="lineTwo" value={clientAddress.lineTwo} onChange={handleClientAddress} className="border border-primary p-2 w-100" placeholder="Address Line 2" style={{ borderRadius: '5px' }} />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mt-3" sm={12} md={6} lg={3}>
                          <Form.Group style={{ position: 'relative' }}>
                            <Form.Label htmlFor="clientCountry">Select Country</Form.Label>
                            <input type="text" id="autocomplete-state" className="p-2 w-100 border border-primary" value={clientCountry} name="clientCountry" placeholder="Select Country"
                              onChange={handleInputChangeCountry} onClick={() => setIsOpenCountry(true)} autoComplete='off' style={{ borderRadius: '5px' }}
                            />
                            {isOpenCountry && (
                              <>
                                <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                  {
                                    countries.length > 0 ? (
                                      filteredCountryOptions.length > 0 ? (
                                        filteredCountryOptions.map((option, i) => (
                                          <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleOptionClickCountry(option.country_name)}>
                                            {option.country_name}
                                          </div>
                                        ))
                                      ) : (
                                        <div className="mt-2">No match found</div>
                                      )
                                    ) : (
                                      <div className="mt-2">Loading . . . . </div>
                                    )
                                  }
                                </div>
                              </>
                            )}
                          </Form.Group>
                        </Col>

                        <Col className="mt-3" sm={12} md={6} lg={3}>
                          <Form.Group style={{ position: 'relative' }}>
                            <Form.Label htmlFor="clientState">Select State</Form.Label>
                            <input type="text" id="autocomplete-state" className="w-100 p-2 border border-primary" value={clientState} name="clientState" placeholder="Select State"
                              onChange={handleInputChange} onClick={() => setIsOpen(true)} autoComplete='off' style={{ borderRadius: "5px" }}
                            />
                            {isOpen && (
                              <>
                                <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                  {
                                    states.length > 0 ? (
                                      filteredOptions.length > 0 ? (
                                        filteredOptions.map((option, i) => (
                                          <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleOptionClick1(option.state_name)}>
                                            {option.state_name}
                                          </div>
                                        ))
                                      ) : (
                                        <div className="mt-2">No match found</div>
                                      )
                                    ) : (
                                      <div className="mt-2">Select country first </div>
                                    )
                                  }
                                </div>
                              </>
                            )}
                          </Form.Group>
                        </Col>

                        <Col className="mt-3" sm={12} md={6} lg={3}>
                          <Form.Group style={{ position: 'relative' }}>
                            <Form.Label htmlFor="clientCity">Select City</Form.Label>
                            <input type="text" id="autocomplete-state" className="w-100 p-2 border border-primary" value={clientCity} name="clientCity" placeholder="Select City"
                              onChange={handleInputChangeCity} onClick={() => setIsOpenClientCity(true)} autoComplete='off' style={{ borderRadius: "5px" }}
                            />
                            {isOpenClientCity && (
                              <>
                                <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                  {
                                    cities.length > 0 ? (
                                      filteredCityOptions.length > 0 ? (
                                        filteredCityOptions.map((option, i) => (
                                          <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleOptionClickCity(option.city_name)}>
                                            {option.city_name}
                                          </div>
                                        ))
                                      ) : (
                                        <div className="mt-2">No match found</div>
                                      )
                                    ) : (
                                      <div className="mt-2">Select state first </div>
                                    )
                                  }
                                </div>
                              </>
                            )}
                          </Form.Group>
                        </Col>

                        <Col className="mt-3" sm={12} md={6} lg={3}>
                          <Form.Group>
                            <Form.Label>Pincode / Zipcode</Form.Label>
                            <input type="number" name="code" onChange={handleClientAddress} className="border border-primary p-2 w-100" placeholder="Pincode / Zipcode" style={{ borderRadius: '5px' }} />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                ) : (
                  <Card className="mb-0">
                    <Card.Header className="bg-white">
                      <div className="header-title">
                        <h4 className="card-title text-primary fw-bold">Organization</h4>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <Row className="">
                        <Col className="mt-3" sm={12} md={6} lg={4}>
                          <Form.Group>
                            <Form.Label htmlFor="orgName">
                              Organization Name
                            </Form.Label>
                            <input type="text" name="orgName" value={orgDetails.orgName} onChange={handleChangeOrgDetails} placeholder="Organization Name" className="border border-primary w-100 p-2"
                              style={{ borderRadius: "5px" }}
                            />
                          </Form.Group>
                        </Col>
                        <Col className="mt-3" sm={12} md={6} lg={4}>
                          <Form.Group>
                            <Form.Label htmlFor="orgEmail">Email</Form.Label>
                            <input type="text" name="orgEmail" value={orgDetails.orgEmail} onChange={handleChangeOrgDetails} className="border border-primary w-100 p-2"
                              placeholder="Email" style={{ borderRadius: "5px" }}
                            />
                          </Form.Group>
                        </Col>
                        <Col className="mt-3" sm={12} md={6} lg={4}>
                          <Form.Group>
                            <Form.Label htmlFor="orgPhone">Phone No.</Form.Label>
                            <input type="number" name="orgPhone" value={orgDetails.orgPhone} onChange={handleChangeOrgDetails} className="border border-primary w-100 p-2"
                              placeholder="Phone No." style={{ borderRadius: "5px" }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="mt-3" sm={12} md={6} lg={4}>
                          <Form.Group>
                            <Form.Label htmlFor="orgLineOne">Address Line 1</Form.Label>
                            <input type="text" name="orgLineOne" value={orgAddress.orgLineOne} onInput={handleChangeOrgAddress} onChange={handleChangeOrgDetails} className="border border-primary w-100 p-2"
                              placeholder="Address Line 1" style={{ borderRadius: "5px" }}
                            />
                          </Form.Group>
                        </Col>
                        <Col className="mt-3" sm={12} md={6} lg={4}>
                          <Form.Group>
                            <Form.Label htmlFor="orgLineTwo">Address Line 2</Form.Label>
                            <input type="text" name="orgLineTwo" value={orgAddress.orgLineTwo} onInput={handleChangeOrgAddress} onChange={handleChangeOrgDetails} className="border border-primary w-100 p-2"
                              placeholder="Address Line 2" style={{ borderRadius: "5px" }}
                            />
                          </Form.Group>
                        </Col>
                        <Col className="mt-3" sm={12} md={6} lg={4}>
                          <Form.Group style={{ position: 'relative' }}>
                            <Form.Label htmlFor="clientCountry">Select Country</Form.Label>
                            <input type="text" id="autocomplete-state" className="w-100 p-2 border border-primary" value={orgCountry} placeholder="Select Country"
                              onChange={handleInputChangeOrgCountry} onInput={handleChangeOrgAddress} onKeyUp={handleChangeOrgDetails} onClick={() => setIsOpenOrgCountry(true)} autoComplete='off' style={{ borderRadius: '5px' }}
                            />

                            {isOpenOrgCountry && (
                              <>
                                <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                  {
                                    Orgcountries.length > 0 ? (
                                      filteredOrgCountryOptions.length > 0 ? (
                                        filteredOrgCountryOptions.map((option, i) => (
                                          <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleOptionClickOrgCountry(option.country_name)}>
                                            {option.country_name}
                                          </div>
                                        ))
                                      ) : (
                                        <div className="mt-2">No match found</div>
                                      )
                                    ) : (
                                      <div className="mt-2">Loading . . . . </div>
                                    )
                                  }
                                </div>
                              </>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="mt-3" sm={12} md={6} lg={4}>
                          <Form.Group style={{ position: 'relative' }}>
                            <Form.Label htmlFor="clientState">Select State</Form.Label>
                            <input type="text" id="autocomplete-state" className="w-100 p-2 border border-primary" value={orgState} name="clientCountry" placeholder="Select State"
                              onChange={handleInputChangeOrgState} onInput={handleChangeOrgAddress} onKeyUp={handleChangeOrgDetails} onClick={() => setIsOpenOrgState(true)} autoComplete='off' style={{ borderRadius: '5px' }}
                            />

                            {isOpenOrgState && (
                              <>
                                <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                  {
                                    orgStates.length > 0 ? (
                                      filteredStateOptions.length > 0 ? (
                                        filteredStateOptions.map((option, i) => (
                                          <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleOptionClickOrgState(option.state_name)}>
                                            {option.state_name}
                                          </div>
                                        ))
                                      ) : (
                                        <div className="mt-2">No match found</div>
                                      )
                                    ) : (
                                      <div className="mt-2">Select country first </div>
                                    )
                                  }
                                </div>
                              </>
                            )}
                          </Form.Group>
                        </Col>
                        <Col className="mt-3" sm={12} md={6} lg={4}>
                          <Form.Group style={{ position: 'relative' }}>
                            <Form.Label htmlFor="clientCity">Select City</Form.Label>
                            <input type="text" id="autocomplete-state" className="w-100 p-2 border border-primary" value={orgCity} placeholder="Select City"
                              onChange={handleInputChangeOrgCity} onInput={handleChangeOrgAddress} onKeyUp={handleChangeOrgDetails} onClick={() => setIsOpenOrgCity(true)} autoComplete='off' style={{ borderRadius: '5px' }}
                            />

                            {isOpenOrgCity && (
                              <>
                                <div style={{ maxHeight: "15rem", position: 'absolute', top: '4.5rem', width: '100%', zIndex: '999', border: "black", background: 'white', overflowY: "scroll" }} className="border px-3 py-2 border-2 d-flex flex-column">
                                  {
                                    orgCities.length > 0 ? (
                                      filteredOrgCityOptions.length > 0 ? (
                                        filteredOrgCityOptions.map((option, i) => (
                                          <div className='mt-2' style={{ cursor: 'pointer' }} key={i} onClick={() => handleOptionClickOrgCity(option.city_name)}>
                                            {option.city_name}
                                          </div>
                                        ))
                                      ) : (
                                        <div className="mt-2">No match found</div>
                                      )
                                    ) : (
                                      <div className="mt-2">Select state first </div>
                                    )
                                  }
                                </div>
                              </>
                            )}
                          </Form.Group>
                        </Col>
                        <Col className="mt-3" sm={12} md={6} lg={4}>
                          <Form.Group>
                            <Form.Label htmlFor="pinCode">Pincode / Zipcode</Form.Label>
                            <input type="number" name="pinCode" value={orgAddress.code} onChange={handleChange} className="border border-primary w-100 p-2"
                              placeholder="Pincode / Zipcode" style={{ borderRadius: "5px" }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                )
              }

              <div className="pt-5 d-flex justify-content-center align-items-center bg-white rounded p-2">
                {
                  formStep !== 1 ? (
                    <span className="bg-primary text-white text-center fs-5 w-5 p-2 rounded cursor-pointer" onClick={() => setFormStep(formStep - 1)} style={{ minWidth: "100px" }}>
                      Previous
                    </span>
                  ) : null
                }
                {
                  formStep === 3 ? (
                    <span className="bg-primary text-white text-center fs-5 w-5 p-2 rounded cursor-pointer" onClick={handleSubmit} style={{ minWidth: "100px" }}>Add Lead</span>
                  ) : (
                    <span className="bg-primary text-white text-center fs-5 w-5 p-2 rounded cursor-pointer" onClick={() => setFormStep(formStep + 1)} onMouseOver={handleChange} style={{ minWidth: "100px" }}>
                      Next
                    </span>
                  )
                }
              </div>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default AddLead;
