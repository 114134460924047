import React from "react";

const DesignationTimelineSvg = ({}) => {
	return (
		<div>
			<div class='bg-teal-600 w-6 h-6 d-flex justify-content-center align-items-center rounded-full -ml-3'>
				<svg
					fill='#ffffff'
					width='15px'
					height='15px'
					viewBox='0 0 256 256'
					id='Flat'
					xmlns='http://www.w3.org/2000/svg'
					stroke='#ffffff'>
					<g id='SVGRepo_bgCarrier' stroke-width='0'></g>
					<g
						id='SVGRepo_tracerCarrier'
						stroke-linecap='round'
						stroke-linejoin='round'></g>
					<g id='SVGRepo_iconCarrier'>
						{" "}
						<g opacity='0.2'>
							{" "}
							<path d='M73.87573,111.39355l-.009-.03369L42.51636,148.98047a7.99958,7.99958,0,0,0-1.66358,6.85693l12.3628,55.63281a8,8,0,0,0,12.80713,4.51172L96,192C78.36523,161.44922,72.74268,134.47656,73.87573,111.39355Z'></path>{" "}
						</g>{" "}
						<g opacity='0.2'>
							{" "}
							<path d='M181.52239,110.6714l.009-.03369,31.35034,37.6206a7.99958,7.99958,0,0,1,1.66358,6.85693l-12.3628,55.63282a8,8,0,0,1-12.80712,4.51172l-29.9773-23.98194C177.03289,160.72706,182.65544,133.75441,181.52239,110.6714Z'></path>{" "}
						</g>{" "}
						<g>
							{" "}
							<path d='M144,216H112a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16Z'></path>{" "}
							<circle cx='128' cy='96' r='12'></circle>{" "}
							<path d='M219.62891,143.85889l-30.23658-36.28418a124.52267,124.52267,0,0,0-7.98022-34.8833c-11.61719-30.21192-32.15625-49.999-43.36523-59.10547a16.07708,16.07708,0,0,0-20.07813-.0669C106.61328,22.61621,85.81055,42.38867,74.07617,72.605A122.76188,122.76188,0,0,0,65.979,108.32861L36.37109,143.85889A16.10066,16.10066,0,0,0,33.043,157.57275l12.36328,55.63282a15.98523,15.98523,0,0,0,25.61328,9.023L98.80566,200h58.38868l27.78515,22.22852a15.91184,15.91184,0,0,0,9.96485,3.51709,16.0905,16.0905,0,0,0,5.34668-.91944,15.90581,15.90581,0,0,0,10.30273-11.62012L222.957,157.57373A16.102,16.102,0,0,0,219.62891,143.85889ZM127.9668,26.01074C198.25391,83.126,169.43359,157.01074,155.25,184H100.67871C52.6709,96.27734,109.22559,41.02637,127.9668,26.01074ZM61.02539,209.73486l-12.36328-55.6333,17.92651-21.5122q3.40906,28.0371,19.14038,57.38281Zm133.94922,0-24.74976-19.7998q15.53064-29.55175,18.74341-57.87695l18.36963,22.04345Z'></path>{" "}
						</g>{" "}
					</g>
				</svg>
			</div>
		</div>
	);
};

export default DesignationTimelineSvg;
